import React,{useState, useEffect} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
import WarmAppFooter from '../WarmAppFooter';
import PropertyInfoSelected from './PropertyInfo2';
import PropertySearch from './PropertyInfo3';
import { useLoanInfo } from '../../utils/loanInfoContext';
import { useUserType } from '../../utils/UserTypeContext';
import WarmAppLocationSearch from '../WarmAppLocationSearch';
import { getallUsers} from "../../utils/reducer";
import { useSelector} from 'react-redux';

const PropertyInfo = () => {
    const { useType, setUseType } = useUserType();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    useEffect(() => {
      const userTypeFromUrl = urlParams.get('usertype');
      if (userTypeFromUrl) {
        setUseType(userTypeFromUrl);
      }
    }, [urlParams, setUseType]);
  let formElementsbrockerJson = [
    {
      "type": "buttonGroup",
      //"label": "IS THE BORROWER PURCHASING OR REFINANCING?",
      "label": "Are you purchasing or refinancing?",
      "options": [
        { "value": "Purchase", "label": "Purchase" },
        { "value": "Refinance", "label": "Refinance" }
      ],
      "name": "loanfor",
      "id" : "purchaseRefinanceBroker"
    },
    {
      "type": "buttonGroup",
      "label": "What step in the process are you at?",
      "options": [
  
        { "value": "Borrower submitted an offer", "label":"Offer Submitted" ,"id" : "submitOfferBroker" },
        { "value": "Borrower has property under contract", "label": "Signed Contract" ,"id" : "purchaseContractBroker" },
        { "value": "Doing research for borrower", "label": "Researching" ,"id" : "researchBroker" },
      ],
      "name": "stepIntheProcess",
      
    },
    {
      "type": "locationsearch",
      "label": "In what area are you looking?",
      "placeholder": "Search City and Zip Code",
      "name": "propertyPlace",
      "id" : "locationBorrower"
    },
    {
      "type": "dropdown",
      "label": "Where did you hear about us?",
      "name": "referredBy",
      "options": [
        { "value": "", "label": "Please select" },
        { "value": "CIX", "label": "CIX" },
        { "value": "Conference", "label": "Conference" },
        { "value": "Events", "label": "Events" },
        { "value": "Organic Website Search", "label": "Organic Website Search" },
        { "value": "Other", "label": "Other" },
        { "value": "Referral", "label": "Referral" },
        { "value": "Social Media - Bigger Pockets", "label": "Social Media - Bigger Pockets" },
        { "value": "Social Media - Facebook", "label": "Social Media - Facebook" },
        { "value": "Social Media - Instagram", "label": "Social Media - Instagram" },
        { "value": "Social Media - Linkedin", "label": "Social Media - Linkedin" },
        { "value": "Social Media - X", "label": "Social Media - X" },
      ],
      "id" : "brokerReferredBy"
    },
    {
      "type": "dropdown",
      "label": "Are you currently working with an Easy Street account executive?",
      "name": "accountexecutive",
      // "options": [
      //   { "value": "", "label": "Please Select Account Executive" },
      //   { "value": "Not working with anyone currently", "label": "Not working with anyone currently" },
      //   { "value": "AJ Weed", "label": "AJ Weed" },
      //   { "value": "Alex Fernandez", "label": "Alex Fernandez" },
      //   { "value": "Allison Stuart", "label": "Allison Stuart" },
      //   { "value": "Amanda Hart", "label": "Amanda Hart" },
      //   { "value": "Antony Riveros", "label": "Antony Riveros" },
      //   { "value": "Austin Walker", "label": "Austin Walker" },
      //   { "value": "Barbara Taylor", "label": "Barbara Taylor" },
      //   { "value": "Bill Fay", "label": "Bill Fay" },
      //   { "value": "Brad Nichols", "label": "Brad Nichols" },
      //   { "value": "Brittany Mullen", "label": "Brittany Mullen" },
      //   { "value": "Casey Denton", "label": "Casey Denton" },
      //   { "value": "Cat Rauh", "label": "Cat Rauh" },
      //   { "value": "Collin Duffy", "label": "Collin Duffy" },
      //   { "value": "Dan Gamache", "label": "Dan Gamache" },
      //   { "value": "Dustin Gayer", "label": "Dustin Gayer" },
      //   { "value": "Gabe Correll", "label": "Gabe Correll" },
      //   { "value": "Gray Cato", "label": "Gray Cato" },
      //   { "value": "Jack Mawer", "label": "Jack Mawer" },
      //   { "value": "Gabe Correll", "label": "Gabe Correll" },
      //   { "value": "Jack Tulloch", "label": "Jack Tulloch" },
      //   { "value": "Jamie Talley", "label": "Jamie Talley" },
      //   { "value": "Jarell Keys", "label": "Jarell Keysl" },
      //   { "value": "Jason Strom", "label": "Jason Strom" },
      //   { "value": "John Chong", "label": "John Chong" },
      //   { "value": "Jordin  Mitton", "label": "Jordin  Mitton" },
      //   { "value": "Josh Sweat", "label": "Josh Sweat" },
      //   { "value": "Joshua Brandt", "label": "Joshua Brandt" },
      //   { "value": "Kara Manie", "label": "Kara Manie" },
      //   { "value": "Kelly Smith", "label": "Kelly Smith" },
      //   { "value": "Kevin Beaver", "label": "Kevin Beaver" },
      //   { "value": "Luis Florentino", "label": "Luis Florentino" },
      //   { "value": "Luis Morales", "label": "Luis Morales" },
      //   { "value": "Marlon Wilson", "label": "Marlon Wilson" },
      //   { "value": "Matthew Boggs", "label": "Matthew Boggs" },
      //   { "value": "Matthew  Maiorelli", "label": "Matthew  Maiorelli" },
      //   { "value": "Michael Armona", "label": "Michael Armona" },
      //   { "value": "Nick Venuto", "label": "Nick Venuto" },
      //   { "value": "Raphael Junqueira", "label": "Raphael Junqueiras" },
      //   { "value": "Reed Morley", "label": "Reed Morley" },
      //   { "value": "Roy Dilekoglu", "label": "Roy Dilekoglu" },
      //   { "value": "Scott Townes", "label": "Scott Townes" },
      //   { "value": "Sean Waller", "label": "Sean Waller" },
      //   { "value": "Stephanie Vega", "label": "Stephanie Vega" },
      //   { "value": "Stephen Hagerman", "label": "Stephen Hagerman" },
      //   { "value": "Stephen King", "label": "Stephen King" },
      //   { "value": "Tanner Lewis", "label": "Tanner Lewis" },
      //   { "value": "Tanner Macklin", "label": "Tanner Macklin" },
      //   { "value": "Taylor Florentino", "label": "Taylor Florentino" },
      //   { "value": "Todd Russell", "label": "Todd Russell" },
      //   { "value": "Tony Gamboa", "label": "Tony Gamboa" },
      //   { "value": "Tracey Autry", "label": "Tracey Autry" },
      //   { "value": "Tyler Ingel", "label": "Tyler Ingel" },
      //   { "value": "Zach Edelman", "label": "Zach Edelman" },
      //   { "value": "Zachary  Deal", "label": "Zachary  Deal" }
      // ],
      "id" : "brokerAccountExecutive"
    },
  ]
  let formElementsborrowerJson = [
    {
      "type": "buttonGroup",
      "label": "Are you purchasing or refinancing?",
      "options": [
        { "value": "Purchase", "label": "Purchase" },
        { "value": "Refinance", "label": "Refinance" }
      ],
      "name": "loanfor",
      "id" : "purchaseRefinanceBorrower"
    },
    {
      "type": "buttonGroup",
      "label": "What step in the process are you at?",
      "options": [
        { "value": "I submitted an offer I feel good about", "label":"Offer Submitted" ,"id" : "submitOfferBorrower" },
        { "value": "I have signed a purchase contract", "label": "Signed Contract","id" : "purchaseContractBorrower" },
        { "value": "I'm doing research", "label": "Researching","id" : "researchBorrower" },
      ],
      "name": "stepIntheProcess",
     
    },
    {
      "type": "locationsearch",
      "label": "In what area are you looking?",
      "placeholder": "Search City and Zip Code",
      "name": "propertyPlace",
      "id" : "locationBorrower"
    },
    {
      "type": "dropdown",
      "label": "Where did you hear about us?",
      "name": "referredBy",
      "options": [
        { "value": "", "label": "Please select" },
        { "value": "CIX", "label": "CIX" },
        { "value": "Conference", "label": "Conference" },
        { "value": "Events", "label": "Events" },
        { "value": "Organic Website Search", "label": "Organic Website Search" },
        { "value": "Other", "label": "Other" },
        { "value": "Referral", "label": "Referral" },
        { "value": "Social Media - Bigger Pockets", "label": "Social Media - Bigger Pockets" },
        { "value": "Social Media - Facebook", "label": "Social Media - Facebook" },
        { "value": "Social Media - Instagram", "label": "Social Media - Instagram" },
        { "value": "Social Media - Linkedin", "label": "Social Media - Linkedin" },
        { "value": "Social Media - X", "label": "Social Media - X" },
      ],
      "id" : "borrowerReferredBy"
    },
    {
      "type": "dropdown",
      "label": "Are you currently working with an Easy Street account executive?",
      "name": "accountexecutive",
      // "options": [
      //   { "value": "", "label": "Please Select Account Executive" },
      //   { "value": "Not working with anyone currently", "label": "Not working with anyone currently" },
      //   { "value": "AJ Weed", "label": "AJ Weed" },
      //   { "value": "Alex Fernandez", "label": "Alex Fernandez" },
      //   { "value": "Allison Stuart", "label": "Allison Stuart" },
      //   { "value": "Amanda Hart", "label": "Amanda Hart" },
      //   { "value": "Antony Riveros", "label": "Antony Riveros" },
      //   { "value": "Austin Walker", "label": "Austin Walker" },
      //   { "value": "Barbara Taylor", "label": "Barbara Taylor" },
      //   { "value": "Bill Fay", "label": "Bill Fay" },
      //   { "value": "Brad Nichols", "label": "Brad Nichols" },
      //   { "value": "Brittany Mullen", "label": "Brittany Mullen" },
      //   { "value": "Casey Denton", "label": "Casey Denton" },
      //   { "value": "Cat Rauh", "label": "Cat Rauh" },
      //   { "value": "Collin Duffy", "label": "Collin Duffy" },
      //   { "value": "Dan Gamache", "label": "Dan Gamache" },
      //   { "value": "Dustin Gayer", "label": "Dustin Gayer" },
      //   { "value": "Gabe Correll", "label": "Gabe Correll" },
      //   { "value": "Gray Cato", "label": "Gray Cato" },
      //   { "value": "Jack Mawer", "label": "Jack Mawer" },
      //   { "value": "Gabe Correll", "label": "Gabe Correll" },
      //   { "value": "Jack Tulloch", "label": "Jack Tulloch" },
      //   { "value": "Jamie Talley", "label": "Jamie Talley" },
      //   { "value": "Jarell Keys", "label": "Jarell Keysl" },
      //   { "value": "Jason Strom", "label": "Jason Strom" },
      //   { "value": "John Chong", "label": "John Chong" },
      //   { "value": "Jordin  Mitton", "label": "Jordin  Mitton" },
      //   { "value": "Josh Sweat", "label": "Josh Sweat" },
      //   { "value": "Joshua Brandt", "label": "Joshua Brandt" },
      //   { "value": "Kara Manie", "label": "Kara Manie" },
      //   { "value": "Kelly Smith", "label": "Kelly Smith" },
      //   { "value": "Kevin Beaver", "label": "Kevin Beaver" },
      //   { "value": "Luis Florentino", "label": "Luis Florentino" },
      //   { "value": "Luis Morales", "label": "Luis Morales" },
      //   { "value": "Marlon Wilson", "label": "Marlon Wilson" },
      //   { "value": "Matthew Boggs", "label": "Matthew Boggs" },
      //   { "value": "Matthew  Maiorelli", "label": "Matthew  Maiorelli" },
      //   { "value": "Michael Armona", "label": "Michael Armona" },
      //   { "value": "Nick Venuto", "label": "Nick Venuto" },
      //   { "value": "Raphael Junqueira", "label": "Raphael Junqueiras" },
      //   { "value": "Reed Morley", "label": "Reed Morley" },
      //   { "value": "Roy Dilekoglu", "label": "Roy Dilekoglu" },
      //   { "value": "Scott Townes", "label": "Scott Townes" },
      //   { "value": "Sean Waller", "label": "Sean Waller" },
      //   { "value": "Stephanie Vega", "label": "Stephanie Vega" },
      //   { "value": "Stephen Hagerman", "label": "Stephen Hagerman" },
      //   { "value": "Stephen King", "label": "Stephen King" },
      //   { "value": "Tanner Lewis", "label": "Tanner Lewis" },
      //   { "value": "Tanner Macklin", "label": "Tanner Macklin" },
      //   { "value": "Taylor Florentino", "label": "Taylor Florentino" },
      //   { "value": "Todd Russell", "label": "Todd Russell" },
      //   { "value": "Tony Gamboa", "label": "Tony Gamboa" },
      //   { "value": "Tracey Autry", "label": "Tracey Autry" },
      //   { "value": "Tyler Ingel", "label": "Tyler Ingel" },
      //   { "value": "Zach Edelman", "label": "Zach Edelman" },
      //   { "value": "Zachary  Deal", "label": "Zachary  Deal" }
      // ],
      "id" : "borrowerAccountExecutive"
    },
  ]

  let formElementsbrockerJson2 = [

   
    {
      "type": "dropdown",
      "label": "Where did you hear about us?",
      "name": "referredBy",
      "options": [
        { "value": "", "label": "Please select" },
        { "value": "CIX", "label": "CIX" },
        { "value": "Conference", "label": "Conference" },
        { "value": "Events", "label": "Events" },
        { "value": "Organic Website Search", "label": "Organic Website Search" },
        { "value": "Other", "label": "Other" },
        { "value": "Referral", "label": "Referral" },
        { "value": "Social Media - Bigger Pockets", "label": "Social Media - Bigger Pockets" },
        { "value": "Social Media - Facebook", "label": "Social Media - Facebook" },
        { "value": "Social Media - Instagram", "label": "Social Media - Instagram" },
        { "value": "Social Media - Linkedin", "label": "Social Media - Linkedin" },
        { "value": "Social Media - X", "label": "Social Media - X" },
      ],
      "id" : "brokerReferredBy"
    },
    {
      "type": "dropdown",
      "label": "Are you currently working with an Easy Street account executive?",
      "name": "accountexecutive",
      // "options": [
      //   { "value": "", "label": "Please Select Account Executive" },
      //   { "value": "Not working with anyone currently", "label": "Not working with anyone currently" },
      //   { "value": "AJ Weed", "label": "AJ Weed" },
      //   { "value": "Alex Fernandez", "label": "Alex Fernandez" },
      //   { "value": "Allison Stuart", "label": "Allison Stuart" },
      //   { "value": "Amanda Hart", "label": "Amanda Hart" },
      //   { "value": "Antony Riveros", "label": "Antony Riveros" },
      //   { "value": "Austin Walker", "label": "Austin Walker" },
      //   { "value": "Barbara Taylor", "label": "Barbara Taylor" },
      //   { "value": "Bill Fay", "label": "Bill Fay" },
      //   { "value": "Brad Nichols", "label": "Brad Nichols" },
      //   { "value": "Brittany Mullen", "label": "Brittany Mullen" },
      //   { "value": "Casey Denton", "label": "Casey Denton" },
      //   { "value": "Cat Rauh", "label": "Cat Rauh" },
      //   { "value": "Collin Duffy", "label": "Collin Duffy" },
      //   { "value": "Dan Gamache", "label": "Dan Gamache" },
      //   { "value": "Dustin Gayer", "label": "Dustin Gayer" },
      //   { "value": "Gabe Correll", "label": "Gabe Correll" },
      //   { "value": "Gray Cato", "label": "Gray Cato" },
      //   { "value": "Jack Mawer", "label": "Jack Mawer" },
      //   { "value": "Gabe Correll", "label": "Gabe Correll" },
      //   { "value": "Jack Tulloch", "label": "Jack Tulloch" },
      //   { "value": "Jamie Talley", "label": "Jamie Talley" },
      //   { "value": "Jarell Keys", "label": "Jarell Keysl" },
      //   { "value": "Jason Strom", "label": "Jason Strom" },
      //   { "value": "John Chong", "label": "John Chong" },
      //   { "value": "Jordin  Mitton", "label": "Jordin  Mitton" },
      //   { "value": "Josh Sweat", "label": "Josh Sweat" },
      //   { "value": "Joshua Brandt", "label": "Joshua Brandt" },
      //   { "value": "Kara Manie", "label": "Kara Manie" },
      //   { "value": "Kelly Smith", "label": "Kelly Smith" },
      //   { "value": "Kevin Beaver", "label": "Kevin Beaver" },
      //   { "value": "Luis Florentino", "label": "Luis Florentino" },
      //   { "value": "Luis Morales", "label": "Luis Morales" },
      //   { "value": "Marlon Wilson", "label": "Marlon Wilson" },
      //   { "value": "Matthew Boggs", "label": "Matthew Boggs" },
      //   { "value": "Matthew  Maiorelli", "label": "Matthew  Maiorelli" },
      //   { "value": "Michael Armona", "label": "Michael Armona" },
      //   { "value": "Nick Venuto", "label": "Nick Venuto" },
      //   { "value": "Raphael Junqueira", "label": "Raphael Junqueiras" },
      //   { "value": "Reed Morley", "label": "Reed Morley" },
      //   { "value": "Roy Dilekoglu", "label": "Roy Dilekoglu" },
      //   { "value": "Scott Townes", "label": "Scott Townes" },
      //   { "value": "Sean Waller", "label": "Sean Waller" },
      //   { "value": "Stephanie Vega", "label": "Stephanie Vega" },
      //   { "value": "Stephen Hagerman", "label": "Stephen Hagerman" },
      //   { "value": "Stephen King", "label": "Stephen King" },
      //   { "value": "Tanner Lewis", "label": "Tanner Lewis" },
      //   { "value": "Tanner Macklin", "label": "Tanner Macklin" },
      //   { "value": "Taylor Florentino", "label": "Taylor Florentino" },
      //   { "value": "Todd Russell", "label": "Todd Russell" },
      //   { "value": "Tony Gamboa", "label": "Tony Gamboa" },
      //   { "value": "Tracey Autry", "label": "Tracey Autry" },
      //   { "value": "Tyler Ingel", "label": "Tyler Ingel" },
      //   { "value": "Zach Edelman", "label": "Zach Edelman" },
      //   { "value": "Zachary  Deal", "label": "Zachary  Deal" }
      // ],
      "id" : "brokerAccountExecutive"
    },
  ]
  let formElementsborrowerJson2 = [

    {
      "type": "dropdown",
      "label": "Where did you hear about us?",
      "name": "referredBy",
      "options": [
        { "value": "", "label": "Please select" },
        { "value": "CIX", "label": "CIX" },
        { "value": "Conference", "label": "Conference" },
        { "value": "Events", "label": "Events" },
        { "value": "Organic Website Search", "label": "Organic Website Search" },
        { "value": "Other", "label": "Other" },
        { "value": "Referral", "label": "Referral" },
        { "value": "Social Media - Bigger Pockets", "label": "Social Media - Bigger Pockets" },
        { "value": "Social Media - Facebook", "label": "Social Media - Facebook" },
        { "value": "Social Media - Instagram", "label": "Social Media - Instagram" },
        { "value": "Social Media - Linkedin", "label": "Social Media - Linkedin" },
        { "value": "Social Media - X", "label": "Social Media - X" },
      ],
      "id" : "borrowerReferredBy"
    },
    {
      "type": "dropdown",
      "label": "Are you currently working with an Easy Street account executive?",
      "name": "accountexecutive",
      // "options": [
      //   { "value": "", "label": "Please Select Account Executive" },
      //   { "value": "Not working with anyone currently", "label": "Not working with anyone currently" },
      //   { "value": "AJ Weed", "label": "AJ Weed" },
      //   { "value": "Alex Fernandez", "label": "Alex Fernandez" },
      //   { "value": "Allison Stuart", "label": "Allison Stuart" },
      //   { "value": "Amanda Hart", "label": "Amanda Hart" },
      //   { "value": "Antony Riveros", "label": "Antony Riveros" },
      //   { "value": "Austin Walker", "label": "Austin Walker" },
      //   { "value": "Barbara Taylor", "label": "Barbara Taylor" },
      //   { "value": "Bill Fay", "label": "Bill Fay" },
      //   { "value": "Brad Nichols", "label": "Brad Nichols" },
      //   { "value": "Brittany Mullen", "label": "Brittany Mullen" },
      //   { "value": "Casey Denton", "label": "Casey Denton" },
      //   { "value": "Cat Rauh", "label": "Cat Rauh" },
      //   { "value": "Collin Duffy", "label": "Collin Duffy" },
      //   { "value": "Dan Gamache", "label": "Dan Gamache" },
      //   { "value": "Dustin Gayer", "label": "Dustin Gayer" },
      //   { "value": "Gabe Correll", "label": "Gabe Correll" },
      //   { "value": "Gray Cato", "label": "Gray Cato" },
      //   { "value": "Jack Mawer", "label": "Jack Mawer" },
      //   { "value": "Gabe Correll", "label": "Gabe Correll" },
      //   { "value": "Jack Tulloch", "label": "Jack Tulloch" },
      //   { "value": "Jamie Talley", "label": "Jamie Talley" },
      //   { "value": "Jarell Keys", "label": "Jarell Keysl" },
      //   { "value": "Jason Strom", "label": "Jason Strom" },
      //   { "value": "John Chong", "label": "John Chong" },
      //   { "value": "Jordin  Mitton", "label": "Jordin  Mitton" },
      //   { "value": "Josh Sweat", "label": "Josh Sweat" },
      //   { "value": "Joshua Brandt", "label": "Joshua Brandt" },
      //   { "value": "Kara Manie", "label": "Kara Manie" },
      //   { "value": "Kelly Smith", "label": "Kelly Smith" },
      //   { "value": "Kevin Beaver", "label": "Kevin Beaver" },
      //   { "value": "Luis Florentino", "label": "Luis Florentino" },
      //   { "value": "Luis Morales", "label": "Luis Morales" },
      //   { "value": "Marlon Wilson", "label": "Marlon Wilson" },
      //   { "value": "Matthew Boggs", "label": "Matthew Boggs" },
      //   { "value": "Matthew  Maiorelli", "label": "Matthew  Maiorelli" },
      //   { "value": "Michael Armona", "label": "Michael Armona" },
      //   { "value": "Nick Venuto", "label": "Nick Venuto" },
      //   { "value": "Raphael Junqueira", "label": "Raphael Junqueiras" },
      //   { "value": "Reed Morley", "label": "Reed Morley" },
      //   { "value": "Roy Dilekoglu", "label": "Roy Dilekoglu" },
      //   { "value": "Scott Townes", "label": "Scott Townes" },
      //   { "value": "Sean Waller", "label": "Sean Waller" },
      //   { "value": "Stephanie Vega", "label": "Stephanie Vega" },
      //   { "value": "Stephen Hagerman", "label": "Stephen Hagerman" },
      //   { "value": "Stephen King", "label": "Stephen King" },
      //   { "value": "Tanner Lewis", "label": "Tanner Lewis" },
      //   { "value": "Tanner Macklin", "label": "Tanner Macklin" },
      //   { "value": "Taylor Florentino", "label": "Taylor Florentino" },
      //   { "value": "Todd Russell", "label": "Todd Russell" },
      //   { "value": "Tony Gamboa", "label": "Tony Gamboa" },
      //   { "value": "Tracey Autry", "label": "Tracey Autry" },
      //   { "value": "Tyler Ingel", "label": "Tyler Ingel" },
      //   { "value": "Zach Edelman", "label": "Zach Edelman" },
      //   { "value": "Zachary  Deal", "label": "Zachary  Deal" }
      // ],
      "id" : "borrowerAccountExecutive"
    },
  ]
const { loanInfo, setLoanInfo } = useLoanInfo();
const [currentCategory, setCurrentCategory] = useState('infoSelection');
const [properyInfo, setProperyInfo] = useState(loanInfo?.propertyinfo ? loanInfo?.propertyinfo : {});
const [formJSON, setformjson] = useState(useType == 'broker' ? formElementsbrockerJson : formElementsborrowerJson);
const [formJSON2, setformjson2] = useState(useType == 'broker' ? formElementsbrockerJson2 : formElementsborrowerJson2);
const [prevMenu, setPrevMenu] = useState('infoSelection');
const navigate = useNavigate();



const configdata = useSelector(state => state.configdata);
const mapsKey = configdata?.common?.googleMapsKey;
const [addressData,setAddressData] = useState({});
const [selectedPlace, setSelectedPlace] = useState(null);
const [usersOptions, setUsers] = useState([]);//account executive options
const [propertyErrors,setPropertyErrors] = useState({});

// console.log('formJSONhereer',formJSON);
  useEffect(() => {
    console.log('properyInfo',loanInfo);
    setLoanInfo((prevLoanInfo) => ({
      ...prevLoanInfo,
      propertyinfo: properyInfo,
    }));
  }, [properyInfo]);
  useEffect(() => {
    if (properyInfo.hasOwnProperty('PropertyPlace')) {
      setCurrentCategory('propertyPlace');
    } 
  }, []);
  useEffect(() => {
    if (useType && useType === 'broker') {
      setformjson(formElementsbrockerJson);
    
      if(Object.keys(properyInfo).length === 0){
        setProperyInfo((prevData) => ({
          ...prevData,
          loanfor: "Purchase",
          stepIntheProcess: "Borrower submitted an offer"
            
        }));
      }
        
      
    }else{
      setformjson(formElementsborrowerJson);
     
      if(Object.keys(properyInfo).length === 0){
        setProperyInfo((prevData) => ({
          ...prevData,
          loanfor: "Purchase",
          stepIntheProcess: "I submitted an offer I feel good about"
        }));
      }
        
      
    } 
    setLoanInfo((prevLoanInfo) => ({
      ...prevLoanInfo,
      requestor: useType || 'borrower',
    }));
  }, [useType]);

  // useEffect(() => {
  //   setProperyInfo((prevData) => {
  //     if (!prevData?.loanfor) {  
  //       return {
  //         ...prevData,  
  //         loanfor: 'Purchase'  
  //       };
  //     }
  //     return prevData; // If loanfor exists, return the previous state unchanged
  //   });
  // }, [useType]);


  
  
const PropertyInfoSelection = (props) => {
    return (
     
      <div className="question_block no-toppad">
        <label className="label">{props?.formJSON[0]?.label}</label>
        <div className="radios-wrapper mt20">
            
            <button 
                id="purchasing" 
                className={`block_radiobtn ${properyInfo?.loanfor === 'Purchase' ? 'active' : ''}`}
                name="purchasing"
                //style={properyInfo?.loanfor === 'Purchase' ? { background: 'rgba(149, 201, 113, 0.20)' } : {}} 
                onClick={(e) => {setPrevMenu('Purchase'); handleInputChange('loanfor', 'Purchase'); 
                nextForm('Purchase');}}>
                    <div className="icon-check">
                      <img src="assets/images/new-deal/check_circle.svg" className="checked"/>
                    </div>
                    <img src="assets/images/new-deal/Purchasing_active.svg" className="default_img"/>
                    {/* <img src="assets/images/new-deal/Purchasing_active.svg" className="active_img"/> */}
                    Purchasing
            </button>
            <button id="refinancing" 
            name="refinancing"
            className={`block_radiobtn ${properyInfo?.loanfor === 'Refinance' ? "active" : ''}`}
                //style={properyInfo?.loanfor === 'Refinance' ? { background: 'rgba(149, 201, 113, 0.20)' } : {}} 
                onClick={(e) => {setPrevMenu('Refinance'); handleInputChange('loanfor', 'Refinance'); nextForm('Refinance');}}>
                <div class="icon-check">
                  <img src="assets/images/new-deal/check_circle.svg" class="checked"/>
                </div>
                <img src="assets/images/new-deal/Refinancing_active.svg" class="default_img"/>
                {/* <img src="assets/images/new-deal/Refinancing_active.svg" class="active_img"/> */}
                Refinancing
            </button>
        </div>
      </div>
    
    )
}
const navBack = (e) => {
  e.preventDefault()
  // console.log('currentCategory',currentCategory);
     if (currentCategory === 'Purchase' || currentCategory === 'Refinance') {
        setCurrentCategory('infoSelection')
    } if (currentCategory === 'propertySearch') {
        setCurrentCategory(prevMenu)
        if(properyInfo?.loanfor == 'Refinance'){
          setCurrentCategory('infoSelection')
        }
    }else if (currentCategory === 'propertyPlace' ) {
        setCurrentCategory('propertySearch')
    }
}

const nextForm = (page) => {
    setCurrentCategory(page)
    if(page == 'Refinance'){
      setCurrentCategory('propertySearch')
    }
}

const handleInputChange = (key, value) => {
    setProperyInfo((prevData) => {
        let updatedData = { ...prevData };

        if (key === 'accountexecutive') {
            const matchedUser = usersOptions?.find(user => user.value === value);
            updatedData = {
                ...updatedData,
                [key]: value,
                accountexecutiveid: matchedUser?.accountexecutiveid || '',
            };
        } else {
            if (key === 'loanfor' && value === "Refinance") {
                delete updatedData.stepIntheProcess;
            }

            // if (key === 'loanfor') {
            //   if (value === "Refinance") {
            //       delete updatedData.stepIntheProcess;
            //   } else if (value === "Purchase") {
            //       updatedData.stepIntheProcess = 
            //           useType === "broker"
            //           ? "Borrower submitted an offer"
            //           : "I submitted an offer I feel good about";
            //   }
            // }

            updatedData = {
                ...updatedData,
                [key]: value,
            };
        }

        return updatedData;
    })

} 

  //
  // nav next get here from property2
  //const navNext = () => {
    // let selPlace = props?.properyInfo?.propertyaddress || selectedPlace;
    // console.log('selPlaceselPlace',props?.properyInfo, selectedPlace);
    // if(selPlace){
    //     navigate('/loanProgram')
    // }else{
    //     const inputElement = document.querySelector(`[name='search_block']`);
    //     if (inputElement) {
    //         inputElement.classList.add('invalid-input');
    //     }
    // }

    // if (!props?.properyInfo?.propertyaddress || selectedPlace?.trim() === "") {
     
    //     const searchWrpElement = document.querySelector(`[name='search_block']`);
    
    //     if (searchWrpElement) {
    //         //isValid = false;
    //       searchWrpElement.classList.add("invalid-input");
    //     }
    // }else{
        //navigate('/loanProgram');
    //}


  //}

 /* purchase flow --> start */
     const addErrorClass = (field) => {
            const inputElement = document.querySelector(`[name=${field}]`);
            if (inputElement) {
              inputElement.classList.add('invalid-input');
            }
        };
    
        const removeError = (field) => {
            const inputElement = document.querySelector(`[name=${field}]`);
            if (inputElement) {
              inputElement.classList.remove('invalid-input');
            }
        };
    
        const handleValidation = () => {
            let propErrors = {}; //
            // Fields that must be filled (excluding propertyaddress, which is checked separately)
            // console.log('props?.propertyInfo?',properyInfo)
            const requiredFields = [
                //"loanfor",
                "stepIntheProcess",
                "referredBy",
                "accountexecutive"
            ];
        
            requiredFields?.map((field) => {
                const value = properyInfo?.[field];
                if (!value) {
                    propErrors[field] = `${field} is required`;
                }
                
            })
        
            // Check property address separately
            if (!properyInfo?.propertyaddress || selectedPlace?.trim() === "") {
                propErrors["propertyaddress"] = "Property address is required";
        
                // Add invalid class to input field
                const searchWrpElement = document.querySelector(`[name='propertyPlace']`);
                if (searchWrpElement) {
                    searchWrpElement.classList.add("invalid-input");
                }
            }
    
            if(!properyInfo?.loanfor){
                addErrorClass("purchasing");
                addErrorClass("refinancing");
            }else{
                removeError("purchasing");
                removeError("refinancing");
            }
        
            // Update state with errors
            setPropertyErrors(propErrors);
        
            return Object?.keys(propErrors)?.length === 0; // Return errors to use in navNext()
        };
        const handleValidationRefinancing = () => {
          let propErrors = {}; //
          // Fields that must be filled (excluding propertyaddress, which is checked separately)
          // console.log('props?.propertyInfo?',properyInfo)
          const requiredFields = [
              "referredBy",
              "accountexecutive"
          ];
      
          requiredFields?.map((field) => {
              const value = properyInfo?.[field];
              if (!value) {
                  propErrors[field] = `${field} is required`;
              }
              
          })
      

          if(!properyInfo?.loanfor){
              addErrorClass("purchasing");
              addErrorClass("refinancing");
          }else{
              removeError("purchasing");
              removeError("refinancing");
          }
      // console.log('propErrors',propErrors);
          // Update state with errors
          setPropertyErrors(propErrors);
      
          return Object?.keys(propErrors)?.length === 0; // Return errors to use in navNext()
      };
        const handleValidation2 = () => {
          let selPlace = properyInfo?.propertyaddress || selectedPlace;
          // console.log('selPlaceselPlace',properyInfo, selectedPlace);
          if(selPlace){
              navigate('/loanProgram')
          }else{
              const inputElement = document.querySelector(`[name='search_block']`);
              if (inputElement) {
                  inputElement.classList.add('invalid-input');
              }
          }
  
          if (!properyInfo?.propertyaddress || selectedPlace?.trim() === "") {
           
              const searchWrpElement = document.querySelector(`[name='search_block']`);
          
              if (searchWrpElement) {
                  //isValid = false;
                searchWrpElement.classList.add("invalid-input");
              }
          }else{
              navigate('/loanProgram');
          }
        }
        
        const navNext = () => {
            // console.log("property info", properyInfo);
            // console.log("propertyErrors",propertyErrors);
           
            
            const isValid = handleValidation(); // Get validation errors
      
            if (isValid && properyInfo?.loanfor == "Purchase") {
                navigate("/loanProgram");
            }
            
            
        };

        const navNext2 = () => {
          let selPlace = properyInfo?.propertyaddress || selectedPlace;
          // console.log('selPlaceselPlace',properyInfo, selectedPlace);
          if(selPlace){
              // navigate('/loanProgram')
          }else{
              const inputElement = document.querySelector(`[name='search_wrp']`);
              if (inputElement) {
                  inputElement.classList.add('invalid-input');
              }
          }
          const isValid = handleValidationRefinancing();
          
  
          if (!properyInfo?.propertyaddress || selectedPlace?.trim() === "") {
           
              const searchWrpElement = document.querySelector(`[name='search_wrp']`);
          
              if (searchWrpElement) {
                  //isValid = false;
                searchWrpElement.classList.add("invalid-input");
              }
              return;
          }
          // console.log('isValid',isValid)
          if(isValid){
            navigate('/loanProgram');
          }
          
          
        }

        
    
        const handleFocus = (e) => {
            setPropertyErrors({...propertyErrors,[e.target.name]: null});
        };
    
        const handleButtonFocus = (name) => {
            setPropertyErrors({...propertyErrors,[name]: null});
        };
        
        useEffect(() => {
            if(properyInfo?.loanfor){
                removeError("purchasing");
                removeError("refinancing");
            }
        },[properyInfo?.loanfor])
        
    
        useEffect(() => {
                if(selectedPlace){
                    handleInputChange('propertyaddress', addressData?.address?.trim() || addressData?.fullAddress);
                    handleInputChange('propertycity', addressData?.city);
                    handleInputChange('propertyareaaddress', addressData?.area);
                    handleInputChange('propertyfulladdress', addressData?.fullAddress);
                    handleInputChange('propertystate', addressData?.state);
                    handleInputChange('propertyzipcode', addressData?.zipcode);
                    handleInputChange('propertygeolocation',  addressData?.geolocation);
                    handleInputChange('propertygooglemaplink', addressData?.url);
                    handleInputChange('propertyplaceimage', addressData?.image);
                    handleInputChange('propertystateshort', addressData?.stateShortName);
                    handleInputChange('propertycounty', addressData?.county);
                }
              }, [selectedPlace, addressData]); 
        
        
              const removeErrorClass = (field) => {
                    const inputElement = document.querySelector(`[name=${field}]`);
                    if (inputElement) {
                    inputElement.classList.remove('invalid-input');
                    }
            };
        
            useEffect(() => {
                if(selectedPlace){
                    removeErrorClass('propertyPlace');
                }
                
            },[selectedPlace])
    
            
            
    
            useEffect(() => {
                getallUsers('loselectable').then(function (response) {
                    // console.log('alert',response?.result?.data);
                    if (response?.result?.data) {
                    const activeUsers = response?.result?.data.filter(user => user.securityInfo && user.securityInfo.status === "ACTIVE");
                    // Map activeUsers to options array
                    const options = activeUsers.map(user => ({
                        value: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
                        label: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
                        accountexecutiveid : user?.userid
                    }));
                    options.unshift({ value: "Not working with anyone currently", label: "Not working with anyone currently" });
                    
                    options.unshift({ value: "", label: "Please select" });
                    // console.log('sdfsfdoptions',options);
                    // console.log("all users",response?.result?.data)
                    setUsers(options);
                    }
                })
                .catch((err) => {
                    console.error.bind("getallUsers error", err);
                })
                //loandropdown(10013)
            }, []);

 /*purchase flow end */

  

  return (
    // <div className="create_process">
    //    <Header />
    //     <div className="content_wrp">
    //         <InfoBar currentStage={'propertyinfo'} properyInfo={properyInfo} progress={(currentCategory === 'Purchase' || currentCategory === 'Refinance') ? '30%':currentCategory == 'propertySearch' ? '75%' :currentCategory == 'propertyPlace' ? '100%' : '0%'}/>
    //         <div className="content_blk">
    //             <div className="hed">
                    
    //            {currentCategory != 'infoSelection' && <a href="#" className="back material-icons" onClick={(e) => {navBack(e)}}> keyboard_backspace </a>}
    //                 <h2 className="title">Property Info </h2>
    //             </div>
    //             <PropertyInfoSelection setCurrentCategory={setCurrentCategory} formJSON={formJSON}/>
    //             {properyInfo?.loanfor == 'Purchase' && <PropertyInfoSelected properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory} formJSON={formJSON} useType = {useType}/>}
    //             {properyInfo?.loanfor && ((properyInfo?.loanfor == 'Purchase' && properyInfo?.stepIntheProcess ) || properyInfo?.loanfor == "Refinance") && <PropertySearch properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} prevMenu={prevMenu} setCurrentCategory={setCurrentCategory} formJSON={formJSON} useType = {useType}/>}
    //             {/* {currentCategory == 'infoSelection' && <PropertyInfoSelection setCurrentCategory={setCurrentCategory}/>}
    //             {(currentCategory === 'Purchasing' || currentCategory === 'Refinancing') &&  <PropertyInfoSelected properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory} />}
    //             {currentCategory == 'propertySearch' && <PropertySearch properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} prevMenu={prevMenu} setCurrentCategory={setCurrentCategory} />}
    //             {currentCategory == 'propertyPlace' && <PropertyPlace properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} prevMenu={prevMenu} setCurrentCategory={setCurrentCategory} />} */}
    //         </div>
    //     </div>
    //    <Footer />
    // </div>
      <div className='warmapp_body'>
          <Header />

          <div className="wizard-body">
              <div className="wizard-container full-height">
                  <div className="wizard-group">
                      <InfoBar currentStage={'propertyinfo'} properyInfo={properyInfo} progress={(currentCategory === 'Purchase' || currentCategory === 'Refinance') ? '30%':currentCategory == 'propertySearch' ? '75%' :currentCategory == 'propertyPlace' ? '100%' : '0%'}/>
                        <div className="wizard-canvas">
                            <div className="wizard-canvas-header">
                                {/* {currentCategory != 'infoSelection' && <a href="#" className="back material-icons" onClick={(e) => {navBack(e)}}> keyboard_backspace </a>} */}
                                  <h3>Deal Intro </h3>
                            </div>
                            <div className="wizard-canvas-body wizard-overflow">
                                <PropertyInfoSelection setCurrentCategory={setCurrentCategory} formJSON={formJSON}/>
                               
                                 {/* {properyInfo?.loanfor && properyInfo?.loanfor == 'Purchase'
                                  && <PropertyInfoSelected properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} setCurrentCategory={setCurrentCategory} formJSON={formJSON} useType = {useType}/>
                                 }
                                 {properyInfo?.loanfor && (properyInfo?.loanfor == "Refinance") && <PropertySearch properyInfo={properyInfo} handleInputChange={handleInputChange} currentCategory={currentCategory} prevMenu={prevMenu} setCurrentCategory={setCurrentCategory} formJSON={formJSON} useType = {useType}/>} */}
                                 
                                  {properyInfo?.loanfor == "Purchase" && 
                                    formJSON?.map((field, index) => 
                                        field?.name !== "loanfor" ? (
                                            <div className="question_block horz_questions" key={index}>
                                          {(field.type === "locationsearch" &&
                                            (properyInfo?.stepIntheProcess === 'Borrower submitted an offer' ||
                                              properyInfo?.stepIntheProcess === 'Borrower has property under contract' || properyInfo?.stepIntheProcess === 'I submitted an offer I feel good about'
                                              || properyInfo?.stepIntheProcess === 'I have signed a purchase contract')) ? (
                                              <>
                                                <label className="label">What is the address?</label>
                                              </>
                                            ) : <label className="label">{field.label}</label>
                                          }
                                                
                                                
                                                <div className="ques_rightBlock">
                                                    {/* Render Button Group */}
                                                    {field.type === "buttonGroup" && 
                                                        <div className="radio-group">
                                                            {field.options.map((option, idx) => (
                                                                <button
                                                                    key={idx}
                                                                    id={option.id}
                                                                    className={`radio-box ${properyInfo?.[field.name] == option.value ? "active" : ""} ${propertyErrors?.[field.name] && 'invalid-input'}`}
                                                                    onClick={() => handleInputChange(field.name, option.value)}
                                                                    onFocus={() => handleButtonFocus(field.name)}
                                                                >
                                                                    <span className="material-symbols-outlined">radio_button_checked</span>
                                                                    {option.label}
                                                                </button>
                                                            ))}
                                                        </div>
                                                    }

                                                    {/* Render Location Search */}
                                                    {field.type === "locationsearch" && (
                                                        <div className="form-block search_block">
                                                            <div className="form-icon">
                                                                <span className="material-symbols-outlined">location_on</span>
                                                                {mapsKey && (
                                                                    <WarmAppLocationSearch
                                                                        className="form-field"
                                                                        setSelectedPlace={setSelectedPlace}
                                                                        selectedPlace={properyInfo?.["propertyfulladdress"]}
                                                                        setAddressData={setAddressData}
                                                                        name={field.name}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* Render Dropdown */}
                                                    {field.type === "dropdown" && (
                                                        <div className="ques_rightBlock">
                                                            <select
                                                                id={field.id}
                                                                name={field.name}
                                                                className={`wizard-input ${propertyErrors?.[field.name] && 'invalid-input'}`}
                                                                onFocus={handleFocus}
                                                                value={properyInfo?.[field.name] || ""}
                                                                onChange={(e) => handleInputChange(field.name, e.target.value)}
                                                            >
                                                                {field.name === "accountexecutive"
                                                                    ? usersOptions?.map((option, idx) => (
                                                                        <option key={idx} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))
                                                                    : field?.options?.map((option, idx) => (
                                                                        <option key={idx} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    
                                                    )}
                                                </div>
                                            </div>
                                        ) : null 
                                    )
                                  }
                {properyInfo?.loanfor == "Refinance" && (
                  <>
                    <div className="question_block horz_questions">
                      <label className="label">
                      What is the address?
                      </label>
                      <div className="ques_rightBlock" name="search_block">
                        <div className="search_wrp" name='search_wrp'>
                          <span className="material-symbols-outlined icon-style">
                            location_on
                          </span>
                          {mapsKey &&
                            <WarmAppLocationSearch
                              name="location"
                              setSelectedPlace={setSelectedPlace}
                              addressData={addressData}
                              setAddressData={setAddressData}
                              selectedPlace={properyInfo?.propertyfulladdress || selectedPlace}
                            />
                          }
                          <span className="material-symbols-outlined icon-style">
                            search
                          </span>
                        </div>
                      </div>
                    </div>

                    {formJSON2?.map((field, index) =>
                      field?.name !== "loanfor" ? (
                        <div className="question_block horz_questions" key={index}>
                          <label className="label">{field.label}</label>
                          <div className="ques_rightBlock">
                            {/* Render Button Group */}


                            {/* Render Dropdown */}
                            {field.type === "dropdown" && (
                              <div className="ques_rightBlock">
                                <select
                                  id={field.id}
                                  name={field.name}
                                  className={`wizard-input ${propertyErrors?.[field.name] && 'invalid-input'}`}
                                  onFocus={handleFocus}
                                  value={properyInfo?.[field.name] || ""}
                                  onChange={(e) => handleInputChange(field.name, e.target.value)}
                                >
                                  {field.name === "accountexecutive"
                                    ? usersOptions?.map((option, idx) => (
                                      <option key={idx} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))
                                    : field?.options?.map((option, idx) => (
                                      <option key={idx} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null
                    )}
                  </>
                )}


                                
                            </div>
                            
                            {/* <div className='wizard-canvas-footer'>
                              <button id="borrowerBrokerNext" type="button" className="save_next"  onClick={(e) => {navNext()}}>
                                  Save & Next<span class="material-icons"> chevron_right </span>
                              </button>

                            </div> */}
                            <div className='wizard-canvas-footer'>
                              <button id="borrowerBrokerNext" type="button" className="save_next"  
                                 onClick={() => (properyInfo?.loanfor === "Purchase" ? navNext() : navNext2())} >
                                  SAVE & NEXT<span class="material-icons"> chevron_right </span>
                              </button>

                            </div>

                        </div>
                            
                        
                  
                  
                     
                  </div>
              </div>
    
          </div>
          <WarmAppFooter />
      </div>
   
  );

};
export default PropertyInfo;