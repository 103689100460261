

export const formatPhoneNumber = (value) => {
    if (!value) return value;
    

    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  export const formatCurrency = (number) => {
    return number?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });
  };

  export const isValidPercentage = (value) => {
    // Regex to accept values between 0 and 100, including decimals
    const regex = /^(100(\.0+)?|([0-9]{1,2}(\.[0-9]*)?))?$/;
    return value === "" || regex.test(value); // Allow empty or valid percentage values
  };

  // adding commas automatically and should not allow float . eg - 1,234
  export const formatNumericInput = (value) => {
    // Remove any non-numeric characters
    value = value?.replace(/[^0-9]/g, '');

    // Add commas to the numeric value
    if (!value) return ''; // Handle edge case for empty input
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  export const formatCurrencyDisplay = (value) => {
    const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    }).format(value);
    return formatted;
  };


  export const transformData = (data) => {
    const transformedData = {};

    // Helper function to format the label
    function formatLabel(label) {
        // Split the label into words based on spaces
        const words = label.split(' ');

        // Capitalize the first letter of each word and join with a space
        return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }

    data.forEach(item => {
        // Check if "Our Field" exists and is a string
        if (item["Our Field"] && typeof item["Our Field"] === 'string') {
            const fieldParts = item["Our Field"].split('.');
            const labelKey = fieldParts[0]; // e.g., applicant[0] or cleartoclose

            // Initialize the label if it doesn't exist
            if (!transformedData[labelKey]) {
                transformedData[labelKey] = {
                    label: formatLabel(labelKey),
                    subOptions: []
                };
            }

            // Add the sub-option
            transformedData[labelKey].subOptions.push({
                label: formatLabel(item["Display Name"]),
                value: `{${item["Display Name"]}}`
            });
        } else {
            console.warn(`"Our Field" is undefined or not a string for item:`, item);
        }
    });

    // Convert the transformedData object into an array
    return Object.values(transformedData);
  };

  // sectionPermissionsUtils.js
  export const handleSectionVisibility = (sectionPermissions) => {
    if (!sectionPermissions) return;

    sectionPermissions.forEach((section) => {
      const sectionId = section?.name?.replace(/\s+/g, "").toLowerCase(); // Generate id from name
      const element = document.getElementById(sectionId);

      if (element) {
        // Show or hide the element based on the `view` property
        element.style.display = section.view ? "" : "none";
      } else {
        console.warn(`Element with id ${sectionId} not found`);
      }
    });
  };

  //section underwriting permissions
  // export const moduleSectionPermissions = (arrData,sectionName) => {
  //     // Check if the section exists and has `view` set to true
  //     // return arrData?.some(item => item?.name === sectionName && item?.view === true);

  //     // Check if any item's `name` includes `sectionName` and has `view` set to true
  //     return arrData?.some(item => item?.name?.toLowerCase().includes(sectionName.toLowerCase()) && item?.view === true);
  // }
