import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import Select from 'react-select';
import {getUnderwritingstates, updateTermSheets, LoanTermDropdown , getSingleTermSheetPrefill} from "../utils/reducer";
import { useLocation, useNavigate} from 'react-router-dom';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import { formatCurrency } from '../utils/commonUtils.js';
import CurrencyInput from 'react-currency-input-field';
import { type } from '@testing-library/user-event/dist/type/index.js';
let easyfix = {
    'state':'',
    'interestReserve': 'No',
    'interestType':'',
    "underwritingSheet": {
        "points": 3.000,
        "maxLTV": 70.000 ,
        "maxLTC": 85.000,
        "interestRate": 10.900,
        "selectFund": "Easy Street Capital, LLC",
        "loanTerm": 6,
        "insurancePercent": 1.00
      },
    "termSheet": {
        "docFee": "$1,495.00",
        "title": 0.500,
        "escrow": "$395.00",
        "originationFee": "$2,500.00",
        "propertyInsurance": "$2,000.00",
        "drawFee": "$295.00"
      },
    "originatorInstructions": {
        'cashReservesRequired': "*Insufficient stated reserves, please confirm with borrower additional liquidity is available before moving forward.",
        'floodInsuranceRequired': "*Subject property is located in floodplain. Flood insurance will be a requirement of closing.",
        'yearBuiltBefore1940': '*Age of home will require previous experience with historic/vintage remodel. Please discuss with borrower and provide evidence of similar execution or other mitigating factors to offset increased risk profile.',
    }
  }
  let easybuild = {
    'state':'',
    'interestReserve': 'No',
    'interestType':'',
    "underwritingSheet": {
        "points": 3.000,
        "maxLTV": 65.000,
        "maxLTC": 85.000,
        "interestRate": 11.900,
        "selectFund": "Easy Street Capital, LLC",
        "loanTerm": 9,
        "insurancePercent": 1.00
      },
    "termSheet": {
        "docFee": "$1,495.00",
        "title": 0.500,
        "escrow": "$395.00",
        "originationFee": "$2,500.00",
        "propertyInsurance": "$2,000.00",
        "drawFee": "$295.00"
      },
    "originatorInstructions": {
        'cashReservesRequired': "*Insufficient stated reserves, please confirm with borrower additional liquidity is available before moving forward.",
        'floodInsuranceRequired': "*Subject property is located in floodplain. Flood insurance will be a requirement of closing.",
        'yearBuiltBefore1940': '',
    }
  }
  let easyrent = {
    'state':'',
    'interestReserve': 'No',
    'interestType':'',
    "underwritingSheet": {
        "points": 3.000,
        "maxLTV": 65.000,
        "maxLTC": 90.000,
        "interestRate": 9.900,
        "selectFund": "Easy Street Capital, LLC",
        "loanTerm": 6,
        "insurancePercent": 1.00
      },
    "termSheet": {
        "docFee": "$995.00",
        "title": 0.500,
        "escrow": "$395.00",
        "originationFee": "$2,500.00",
        "propertyInsurance": "$2,000.00",
        "drawFee": "$150.00"
      },
    "originatorInstructions": {
        'cashReservesRequired': '',
        'floodInsuranceRequired': '',
        'yearBuiltBefore1940': '',
    }
  }
const CreateTermSheets = () => {
    const navigate = useNavigate();
      const programData = {
        easyfix,
        easybuild,
        easyrent,
    };
    const location = useLocation();
    const data = location.state?.selectedsheet;
    const term_sheet_id = location.state?.termId;
    console.log("data",data);
    const getMode = location.state?.mode;
    console.log(getMode);
    const termsSheetRefils = location.state?.termsSheetRefils;
    const [singleTermSheet,setSingleTermSheet] = useState({});
    // const [formData, setFormData] = useState({programtype:'', interestReserve:'No', underwritingSheet: {loanTerm:''}});
    const [formData, setFormData] = useState({programtype:'', underwritingSheet: {loanTerm:''}});
    const [initialSelectedOptions, setInitialSelectedOptions] = useState([]);
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('Form successfully submitted.');
    const [isSelectValid, setIsSelectValid] = useState(true);
    const [states, setStates] = useState(null);    
    const [termErrors,settermErrors] = useState({});
    const [termSheetOld,setTermSheetOld] = useState({});
    
    const [programCheck,setProgramCheck] = useState(false);
    const [loanTerm,setLoanTerm] = useState([]);
    const [compareBool,setCompareBool] = useState(false);

    let errorSheets = {};
 
    useEffect(() => {
        getUnderwritingstates().then(function (response) {
          console.log('getStates',response);
          if (response?.result) {
              const convertedArray = response?.result
                  .filter(state => state.underwriting === true) // Filter states where underwriting is true
                  .map(state => ({
                      value: state.regionname, // Convert state to lowercase for the value
                      label: state.regionname // Capitalize the first letter for the label
                  }));
            
            console.log('convertedArrayconvertedArray',convertedArray);
             let initialSelectedOption = []; 
              if (Array.isArray(formData?.state)) {
                // Directly comparing with state names as strings
                initialSelectedOption = convertedArray?.filter(option =>
                  formData.state.includes(option.value)
                );
                setInitialSelectedOptions(initialSelectedOption);
              } else if (formData?.state) {
                // Comparing with state names nested under regionname
                initialSelectedOption = convertedArray?.filter(option =>
                  formData.state.includes(option.label)
                );
                setInitialSelectedOptions(initialSelectedOption);
              } else {
                // Fallback case, if formData.state does not match expected structures
                initialSelectedOption = [];
                setInitialSelectedOptions(initialSelectedOption);
              }
                
            console.log('initialSelectedOptioninitialSelectedOption',convertedArray);
             setStates(convertedArray)
          }
          }).catch((err) => {
            console.error.bind("my account error", err);
          })
          LoanTermDropdown().then(function (response) {
            if (response?.result) {
                console.log("categories from lookups --->",response?.result?.data[0]?.lookupOptions);
                let data = response?.result?.data[0]?.lookupOptions
                setLoanTerm(data);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    }, [formData])



    function compareTermSheets(){
        let keys1 = Object.keys(termSheetOld);
        let keys2 = Object.keys(formData);

        for (let key of keys2) {
            if (keys1?.includes(key)) {
                if (typeof formData[key] === 'object' && !Array.isArray(formData[key])) {
                    // Compare nested objects
                    const nestedKeys1 = Object.keys(termSheetOld[key]);
                    const nestedKeys2 = Object.keys(formData[key]);
    
                    if (nestedKeys1?.length !== nestedKeys2.length) {
                        return false; // Different number of keys in nested objects
                    }
    
                    for (let innerKey of nestedKeys2) {
                        
                        if (!nestedKeys1?.includes(innerKey) || termSheetOld[key][innerKey] != formData[key][innerKey]) {
                            return false; // Key not found or values don't match
                        }
                    }
                } else if (Array.isArray(formData[key])) {
                    // Compare arrays
                    if (JSON.stringify(termSheetOld[key]?.sort()) !== JSON.stringify(formData[key]?.sort())) {
                        return false; // Arrays are not equal
                    }
                } else {
                    // Compare primitive values
                    if (termSheetOld[key] !== formData[key]) {
                        return false; // Values don't match
                    }
                    }
            } 
            // else if(!keys1?.includes(key) && typeof formData[key] === 'object'){
            //     //const nestedKeys1 = Object.keys(termSheetOld[key]);
            //     //const nestedKeys2 = Object.keys(formData[key]);
            //     const hasValues = Object.values(formData[key]).every(value => value);
            //     if(hasValues){
            //         return false;
            //     }
            // }
            else {
                return false; // Key not found in termSheetOld
            }
        
        }
    
        return true; // All keys and values match
    

       
            
    }

    useEffect(() => {
        console.log("term sheet old",termSheetOld);
        console.log("term sheet new",formData);
        if(getMode == "Edit"){
            const compareValue = compareTermSheets();
            console.log("compare value",compareValue);
            setCompareBool(compareValue);
        }
        
    },[formData])

    // calling get single term sheet API

    useEffect(() => {
        if (singleTermSheet) {
          setFormData(singleTermSheet);
        }
    }, [singleTermSheet]);

    useEffect(() => {
        
        callGetSingleTermSheet();
    },[getMode]); 

    const callGetSingleTermSheet = () => {
        dispatch(showLoader());
        getSingleTermSheetPrefill(term_sheet_id).then(function (response) {
            if(response?.result){
                setFormData({
                    ...formData,
                    state: response?.result?.data[0]?.state,
                });
                console.log('get single term sheet ',response?.result);
                setSingleTermSheet(response?.result?.data[0]);
                setTermSheetOld(response?.result?.data[0]);
                
                //setInitialSelectedOptions(response?.result?.data[0]?.state);
            }
            dispatch(hideLoader());
            
            
        }).catch((err) => {
            console.error.bind("get single term sheet", err);
            dispatch(hideLoader());
        })
    }

    useEffect(() => {
        // Use the current programtype to get the corresponding data
        const program = formData?.programtype;
        const selectedProgramData = termsSheetRefils?.[program];

        if (selectedProgramData) {
            setFormData(currentFormData => ({
                ...currentFormData, // Preserve other data
                ...selectedProgramData, // Update with selected program data
                programtype: program, // Ensure the programtype is set correctly
            }));
        }
    }, [formData?.programtype]);



    const handleLoanProgramChange = (e) => {
        console.log(formData);
        // if(e.target.value == "EASY BUILD"){
        //     setFormData({...formData,
        //         programtype : e.target.value,
        //         interestType : "Interest on Full Balance"
        //     });
        // }else{
        //     setFormData({...formData,
        //         programtype : e.target.value,
        //         interestType : "Interest as Disbursed"
        //     })
        // }
        setFormData({...formData,
            programtype : e.target.value,
            
        });
        
    };
    
      const handleChange = (e, section = null) => {
        
        console.log('e',e)
        if (e.target) {
        let { name, value } = e.target;
        // if(!getMode && name === "interestReserve"){
        //     setFormData({
        //         ...formData,
        //         interestType:"",
        //       });
        // }
        settermErrors({...termErrors,[name]:''});
        let numericValue = parseFloat(value);

        if (isNaN(numericValue) || numericValue < 0) {
            // Reset the value to 0 if it's not a valid number or less than 0
            numericValue = 0;
        } else if(numericValue > 100) {
            // Reset the value to 100 if it's greater than 100
            numericValue = 100;
        }
        if (section) {
            const allowedKeys = ['escrow', 'docFee', 'originationFee', 'propertyInsurance', 'drawFee', ];

            if (allowedKeys.includes(name) && value !== '') {
                  const numberAndFloatRegex = /^[0-9]*\.?[0-9]*$/;
                  if(value.startsWith('$')){
                    let replace = value.replace(/\$/g,'');
                    value = (numberAndFloatRegex.test(replace)) ?  Number(replace) : '';
                  }else{
                    value = (numberAndFloatRegex.test(value)) ?   Number(value) : '';
                  }
             }

            // Handling changes in nested objects
            setFormData({
                ...formData,
                [section]: {
                    ...formData?.[section],
                    [name]: value
                }
            });
        } else {
            // Handling changes in non-nested fields
            // if(name === "interestReserve" && value === "no"){
               
            //     setFormData({
            //         ...formData,
            //         interestType: "",
            //         interestReserve: value
            //     });
                
                
            // }else{
            //     // console.log(name , value);
            //     setFormData({
            //         ...formData,
            //         [name]: value
            //     });
            // }
           
            setFormData({
                ...formData,
                [name]: value
            });
            
        }
    }else{
        setIsSelectValid(true); 
        console.log('sdfsf',e);
        // let initialSelectedOption = convertedArray?.filter(option =>
        //     formData?.state?.includes(option?.label)
        //   );
        setInitialSelectedOptions(e)
        const values = e.map(option => option.value);
        
        setFormData({
            ...formData,
            ['state']: values
        });
    }
    };
    const handleCurrencyInput = (name,value)=>{
        let section = 'termSheet'
        let updatedValue = isNaN(value) ? '' : (value);
        setFormData({
            ...formData,
            [section]: {
                ...formData?.[section],
                [name]: updatedValue
            }
        });
    }

    const termValid = () => {
        console.log("err",errorSheets);
        console.log(formData?.interestType);
        if(formData?.interestReserve === "Yes" && (formData?.interestType === "" || formData?.interestType === undefined)){
                errorSheets.interestType = "please select";
               
        }
        settermErrors(errorSheets);
       
    }

    const saveTermsSheet = () => {
        console.log('formData',formData);
        
        //termValid();
        let hasError = false;
        if (formData?.programtype === '') {
            setProgramCheck(true);
            hasError = true;
        }
        if (!formData.state || formData.state.length === 0) {
            setIsSelectValid(false);
            hasError = true;
        }
        
        if (hasError) {
            return;
        }
        if(Object.keys(errorSheets).length === 0){
            // if(formData.state && formData.state.length > 0){
                console.log(formData?.interestType);
                dispatch(showLoader());
                //delete formData._id;
                formData.status = 'ACTIVE';
                console.log('formDataformData',formData);
                let apiData = { ...formData };
                // if(apiData?.interestReserve == 'no'){
                //     apiData.interestType = "";
                // }
                // // Update the formData state with the same logic
                // setFormData(prevFormData => ({
                //     ...prevFormData,
                //     interestType: apiData.interestType
                // }));
                            const numericInputs = ['docFee', 'title','escrow', 'originationFee', 'propertyInsurance', 'drawFee'];

                    numericInputs.forEach(key => {
                        if (apiData?.termSheet?.hasOwnProperty(key) && !isNaN(parseFloat(apiData?.termSheet[key]))) {
                            apiData.termSheet[key] = parseFloat(apiData?.termSheet[key]);
                        }
                    });
                    const underwritingnumberic = ["insurancePercent","interestRate", "loanTerm", "maxLTC","maxLTV","points"];
                    underwritingnumberic.forEach(key => {
                        if (apiData?.underwritingSheet?.hasOwnProperty(key) && !isNaN(parseFloat(apiData?.underwritingSheet[key]))) {
                            apiData.underwritingSheet[key] = parseFloat(apiData.underwritingSheet[key]);
                        }
                    });
                updateTermSheets(apiData).then(function (response) {
                  if(response?.result == "Please verify the states and resend"){
                      setmessage("This loan program and state combination already exists.")
                  }else{
                      setmessage(getMode ? "Updated successfully." : "Term sheet created successfully")
                  }
                  dispatch(hideLoader());
                  setPopupOpen(true);
                  console.log('responseresponse',response);
                }).catch((err) => {
                  console.error.bind("Login error", err);
                  dispatch(hideLoader());
                })
            // }else{
            //     setIsSelectValid(false); 
            // }
        }
        
        

    }
   
      console.log('initialSelectedOptions',initialSelectedOptions);

  
      const closePopup = () => {
        setPopupOpen(false);
            if(popupMessage === 'Term sheet created successfully' || popupMessage ==='Changes updated successfully.'){
            navigate(-1);
            }
      };

      const handleBlur = (e, section = null) => {
        const { name, value } = e.target;
        let numericValue = parseFloat(value);
      
        if (isNaN(numericValue) || numericValue < 0) {
            // Reset the value to 0 if it's not a valid number or less than 0
            numericValue = 0;
          } else if (numericValue > 100) {
            // Reset the value to 100 if it's greater than 100
            numericValue = 100;
          }
      
        // Update your formData state here, similar to your handleChange logic,
        // but using numericValue to ensure the field is corrected upon losing focus
        if (section) {
          setFormData(prevFormData => ({
            ...prevFormData,
            [section]: {
              ...prevFormData[section],
              [name]: numericValue.toString(),
            },
          }));
        } else {
          setFormData(prevFormData => ({
            ...prevFormData,
            [name]: numericValue.toString(),
          }));
        }
      };
  
    return (
        <div id="layout-wrapper">

        {loading ? <Loader/> : null} 
        {isPopupOpen && (
            <PopupMessage
             type={popupMessage ==  "This loan program and state combination already exists." ? 'warning' : 'success'}
             message={popupMessage}
             onClose={closePopup}
            />
        )}
           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">{getMode ? getMode : "Create"} Term Sheet</h4>
                                <a href="#" id="navigatePrefillBack" onClick={(e) => {e.preventDefault(); navigate(-1);}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                <div className="form_section">
                                                    <div className='row'>
                                                         <div className='col-md-3'>
                                                         <h4 className="title mt_10"> PROGRAM DEFAULTS</h4>
                                                         </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Select Loan Program</label>
                                                              
                                                                <select id="prefillLoanProgram" name="programtype" disabled={getMode==='Edit' ? true : false } onChange={handleLoanProgramChange} value= {getMode && formData?.programtype} onFocus={()=>{setProgramCheck(false)}} className={`form-control ${programCheck && 'invalid-input'}`}  tabIndex={1}>
                                                                    <option value="">Select</option>
                                                                    <option value="EASY BUILD">Easy Build</option>
                                                                    <option value="EASY FIX">Easy Fix</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                   
                                                        {/* <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Interest Reserve</label>
                                                                <select name="interestReserve" value = {formData?.interestReserve} onChange={handleChange} className="form-control" tabIndex={2}>
                                                                    <option value="">Select</option>
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                        {/* {formData?.interestReserve == 'yes' && */}
                                                        {/* <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Draw Interest Type</label>
                                                                <select value={formData?.interestType} className={`form-control ${termErrors?.interestType && 'invalid-input'}`} name="interestType"  onChange={handleChange} >
                                                                    <option value="">Select the interest type</option>
                                                                    <option value="Interest as Disbursed">Interest as Disbursed</option>
                                                                    <option value="Interest on Full Balance">Interest on Full Balance</option>
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                        {/* } */}
                                                        {/* <div className="col-md-3"></div> */}
                                                        <div className="col-md-6">
                                                            <div className="form-group custombase">
                                                                <label>State</label>
                                                                    <Select
                                                                        name="state"
                                                                        isMulti={true}
                                                                        options={states}
                                                                        onChange={handleChange}
                                                                        value={initialSelectedOptions}
                                                                        className={isSelectValid ? '' : 'invalid-input'}
                                                                        tabIndex={3}
                                                                        inputId="prefillStates"
                                                                    />

                                                            </div>
                                                        </div>
                                                  
                                                        <div className="col-md-12">
                                                            <h3 className="title mt_10">underwriting sheet</h3>
                                                        </div>

                                                        <div className="col-md-3">

                                                            <div className="form-group">
                                                                <label>Points</label>
                                                                <input id="prefillPoints" type="number" value={formData?.['underwritingSheet']?.points || ''} name="points" onChange={(e) => {handleChange(e, 'underwritingSheet')}} className="form-control" step="0.01" tabIndex={4}/>
                                                            </div>
                                                         
                                                            <div className="form-group">
                                                                <label>Interest Rate (%)</label>
                                                                    <input tabIndex={7}
                                                                        name={'interestRate'}
                                                                        value={formData?.['underwritingSheet']?.interestRate || ''}
                                                                        onChange={(e) => handleChange(e, 'underwritingSheet')}
                                                                        onBlur={(e) => handleBlur(e, 'underwritingSheet')}
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="prefillInterestRate"
                                                                    />
                                                            </div>

                                                       

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Max LTV (%)</label>
                                                                <input id="prefillMaxLtv" tabIndex={5} type="text" value={formData['underwritingSheet']?.maxLTV || ''} 
                                                                    className="form-control" name={'maxLTV'}  onChange={(e) =>{handleChange(e, 'underwritingSheet')}}  onBlur={(e) => handleBlur(e, 'underwritingSheet')}/>
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Loan Term</label>
                                                                <select id="prefillLoanTerm" name={'loanTerm'} tabIndex={8} value = {formData['underwritingSheet']?.loanTerm || ''} onChange={(e) =>{handleChange(e, 'underwritingSheet')}}  className="form-control">
                                                                    <option value="">Select</option>
                                                                    {loanTerm.map(option => {
                                                                        const numericValue = parseInt(option.option);
                                                                        return (<option value={numericValue}>{option.option}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Max LTC (%)</label>
                                                                <input id="prefillMaxLtc" tabIndex={6} name={'maxLTC'} value={formData?.['underwritingSheet']?.maxLTC || ''}  onChange={(e) =>{handleChange(e, 'underwritingSheet')}} type="text"
                                                                    className="form-control"  onBlur={(e) => handleBlur(e, 'underwritingSheet')}/>
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Insurance Percent (%)</label>
                                                                {/* <input name={'insurancePercent'} value={formData?.['underwritingSheet']?.insurancePercent || ''}   onChange={(e) =>{handleChange(e, 'underwritingSheet')}} type="text" placeholder="1.00%"
                                                                    className="form-control" /> */}
                                                                 <input tabIndex={9}
                                                                        name={'insurancePercent'}
                                                                        value={formData?.['underwritingSheet']?.insurancePercent || ''}
                                                                        onChange={(e) => handleChange(e, 'underwritingSheet')}
                                                                        onBlur={(e) => handleBlur(e, 'underwritingSheet')}
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="prefillInsurance"
                                                                    />
                                                            </div>


                                                        </div>
                                                       
                                                    </div>
                                                    <hr className="hr" />
                                                    <h3 className="title">TERM sheet</h3>
                                                    <div className="row ">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Doc Fee</label>
                                                                <CurrencyInput
                                                                    name={'docFee'}
                                                                    decimalsLimit={2}
                                                                    className={`form-control`}
                                                                    // onFocus={handleFocus}
                                                                    placeholder="$0.00"
                                                                    prefix="$"
                                                                    groupSeparator=","
                                                                    value={formData?.['termSheet']?.docFee && formData?.['termSheet']?.docFee || ''}
                                                                    onValueChange={(value, name, values) => {
                                                                        console.log(values);
                                                                        handleCurrencyInput(name, value);
                                                                    }}
                                                                    tabIndex={10}
                                                                    id="prefillDocFee"
                                                                />
                                                                {/* <input type="text" tabIndex={10}
                                                                  placeholder='$0.00'  className="form-control" name={'docFee'} value={formData?.['termSheet']?.docFee && '$'+formData?.['termSheet']?.docFee || ''} onChange={(e) =>{handleChange(e, 'termSheet')}}/> */}
                                                            </div>
                                                            <div className="form-group">
                                                                <label>Min Origination Fee</label>
                                                                <CurrencyInput
                                                                    name={'originationFee'}
                                                                    decimalsLimit={2}
                                                                    className={`form-control`}
                                                                    // onFocus={handleFocus}
                                                                    placeholder="$0.00"
                                                                    prefix="$"
                                                                    groupSeparator=","
                                                                    value={formData?.['termSheet']?.originationFee && formData?.['termSheet']?.originationFee || ''}
                                                                    onValueChange={(value, name, values) => {
                                                                        console.log(values);
                                                                        handleCurrencyInput(name, value);
                                                                    }}
                                                                    tabIndex={13}
                                                                    id="prefillOriginationFee"
                                                                />
                                                                {/* <input value={formData?.['termSheet']?.originationFee && '$'+formData?.['termSheet']?.originationFee || ''} name={'originationFee'}  onChange={(e) =>{handleChange(e, 'termSheet')}}   type="text"
                                                                 placeholder='$0.00'     className="form-control" tabIndex={13} /> */}
                                                            </div>
                                                       

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Title (%)</label>
                                                                <input id="prefillTitle" value={formData?.['termSheet']?.title || ''} name={'title'}  onChange={(e) =>{handleChange(e, 'termSheet')}} type="text" onBlur={(e) => handleBlur(e, 'termSheet')}
                                                                    className="form-control" tabIndex={11}/>
                                                            </div>
                                                            {/* <div className="form-group">
                                                                <label>Property Insurance </label>
                                                                <CurrencyInput
                                                                    name={'propertyInsurance'}
                                                                    decimalsLimit={2}
                                                                    className={`form-control`}
                                                                    // onFocus={handleFocus}
                                                                    placeholder="$0.00"
                                                                    prefix="$"
                                                                    groupSeparator=","
                                                                    value={formData?.['termSheet']?.propertyInsurance && formData?.['termSheet']?.propertyInsurance || ''}
                                                                    onValueChange={(value, name, values) => {
                                                                        console.log(values);
                                                                        handleCurrencyInput(name, value);
                                                                    }}
                                                                    tabIndex={14}
                                                                    id="prefillPropertyInsurance"
                                                                />
                                                            </div> */}

                                                            <div className="form-group">
                                                                <label>Draw Fee</label>
                                                                <CurrencyInput
                                                                    name={'drawFee'}
                                                                    decimalsLimit={2}
                                                                    className={`form-control`}
                                                                    // onFocus={handleFocus}
                                                                    placeholder="$0.00"
                                                                    prefix="$"
                                                                    groupSeparator=","
                                                                    value={formData?.['termSheet']?.drawFee && formData?.['termSheet']?.drawFee || ''}
                                                                    onValueChange={(value, name, values) => {
                                                                        console.log(values);
                                                                        handleCurrencyInput(name, value);
                                                                    }}
                                                                    tabIndex={15}
                                                                    id="prefillDrawFee"
                                                                />
                                                                {/* <input value={formData?.['termSheet']?.drawFee && '$'+formData?.['termSheet']?.drawFee || ''} name={'drawFee'}  onChange={(e) =>{handleChange(e, 'termSheet')}}  type="text" 
                                                                  placeholder='$0.00'    className="form-control" tabIndex={15}/> */}
                                                            </div>
                                                         
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Escrow</label>
                                                                <CurrencyInput
                                                                    name={'escrow'}
                                                                    decimalsLimit={2}
                                                                    className={`form-control`}
                                                                    // onFocus={handleFocus}
                                                                    placeholder="$0.00"
                                                                    prefix="$"
                                                                    groupSeparator=","
                                                                    value={formData?.['termSheet']?.escrow && formData?.['termSheet']?.escrow || ''}
                                                                    onValueChange={(value, name, values) => {
                                                                        console.log(values);
                                                                        handleCurrencyInput(name, value);
                                                                    }}
                                                                    tabIndex={12}
                                                                    id="prefillEscrow"
                                                                />
                                                                {/* <input tabIndex={12} value={formData?.['termSheet']?.escrow && '$'+formData?.['termSheet']?.escrow || ''} name={'escrow'}  onChange={(e) =>{handleChange(e, 'termSheet')}}  type="text" 
                                                                placeholder='$0.00'  className="form-control" /> */}
                                                            </div>
                                                        </div>
                                                     
                                                    </div>
                                                    <hr className="hr" />
                                                    <h3 className="title">Originator instructions</h3>
                                                    <div className="row ">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Cash Reserves 
                                                                    {/* <span className="rf">*</span>  */}
                                                                    </label>
                                                                <textarea id="prefillCashReserves" value={formData?.['originatorInstructions']?.cashReservesRequired || ''}  name={'cashReservesRequired'}  onChange={(e) =>{handleChange(e, 'originatorInstructions')}} rows="4"
                                                                    cols="50" className="form-control"
                                                                    spellcheck="false" tabIndex={16}> </textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Flood Insurance Required
                                                                    {/* <span
                                                                        className="rf">*</span> */}
                                                                        </label>
                                                                <textarea id="prefillFloodInsurance" value={formData?.['originatorInstructions']?.floodInsuranceRequired || ''} name={'floodInsuranceRequired'}  onChange={(e) =>{handleChange(e, 'originatorInstructions')}}  rows="4"
                                                                    cols="50" className="form-control"
                                                                    spellcheck="false" tabIndex={17}> </textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Year Built Before 1940
                                                                    {/* <span
                                                                        className="rf">*</span> */}
                                                                        </label>
                                                                <textarea id="prefillYearBuilt" value={formData?.['originatorInstructions']?.yearBuiltBefore1940 || ''}  name={'yearBuiltBefore1940'}  onChange={(e) =>{handleChange(e, 'originatorInstructions')}}  rows="4"
                                                                    cols="50" className="form-control"
                                                                    spellcheck="false" tabIndex={18}> </textarea>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="btn-group mt_10">
                                                        <button id="submitPrefillForm" onClick={(e) => {e.preventDefault(); saveTermsSheet()}} className="fs_btn" disabled = { getMode && compareBool} style = {{ 'opacity' : (getMode && compareBool === true) ? '0.6' : '1'}}> {getMode ? "Update" : "Create"} </button>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default CreateTermSheets;