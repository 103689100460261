import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import { getNotes ,addNotes} from '../utils/reducer';
import { useDispatch, useSelector} from 'react-redux';
import { useLoanInfo } from '../utils/loanInfoContext';
import Loader from './Loader';
import moment from 'moment';
import PopupMessage from './PopupMessage';
import DataTable from 'react-data-table-component';
import EditorEdit from './EditorEdit';
import { getUserNoteTemplates ,getTemplateInfo , sendEmail} from '../utils/reducer';
import { getUserDataFromLocalStorage } from '../utils/local';
import EmailLogs from './EmailLogs';

const Notes = () => {
  const dispatch = useDispatch();
  const [notesData,setNotesData] = useState({});
  const [other,setOther] = useState('');
  const [noteErrors,setNoteErrors] = useState({});
  const [allNotes,setAllNotes] = useState([]);
  const loading = useSelector((state) => state.loaderVisible);
  const [popUp,setPopUp] = useState(false)
  const [ viewNote, setViewNote] = useState('');
  const { loanInfo, setLoanInfo} = useLoanInfo();
  const [loanid,setLoanid] = useState(loanInfo?.loanid);
  const [message,setMessage] = useState('Added Successfully');
  const [content, setContent] = useState("");
  const [editorValidate,setEditorValidate] = useState(false);
  const getUserData = getUserDataFromLocalStorage();
  const user_id = getUserData?.userId;
  const user_email = getUserData?.emailid;
  const [allNotesTemplates,setAllNotesTemplates] = useState([]);
  const [notesEmailData,setNotesEmailData] = useState({
        "pagename" : "notes",
        // "from" : user_email  || "",
        "from" : "develop@sanchaninfo.com",
        "to" : "",
        "name" : "",
        "subject" : "",
        "template" : content,
    });
    const [hidePrint,setHidePrint] = useState('yes');
    const [templateId,setTemplateId] = useState(null);
    const [emailErrors,setEmailErrors] = useState({});
    const [emailToError , setEmailToError] = useState('');
    let errEmail = {};
    // for email logs
    const [activeTab, setActiveTab] = useState('Notes');

    const handleTabChange = (tabId) => {
      setActiveTab(tabId);
    };
 useEffect(() => {
    if(loanid){
        getNotesApi();
    }
  }, [loanid]);
  useEffect(() => {
    setLoanid(loanInfo?.loanid)
  }, [loanInfo?.loanid]);


 const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('Name:', name);
   
        setNotesData((prev) => ({
            ...prev,
            [name]: value,
        }));
   
};
 
  const getNotesApi = ()=>{
    dispatch(showLoader());
    getNotes(loanid)
    .then(function(response) {
        if (response) {
            console.log('response', response);
            setAllNotes(response?.result?.data);
            dispatch(hideLoader());
        }
    })
    .catch((err) => {
        console.error("error", err); 
        dispatch(hideLoader());
    });
};

  const handleAdd = () => {
    const fieldsToCheck = {
    //   'contact' : 'contact', 'reason' : 'reason',
      'description' : 'description',
      'communicationtype' : 'communicationtype'
    //   'typeofcontact' :'typeofcontact'
    };
    console.log('ran');
    const errors = {};

    for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if (!notesData[fieldName]?.trim()) {
                errors[fieldName] = `Please Enter ${fieldLabel} `;
            }
    };
    if(notesData?.reason === 'Other'){
        if (!other?.trim()) {
            errors["other"] = `Please Enter Other `;
        }
    }

    setNoteErrors(errors);
    if (Object.keys(errors).length === 0) {
        dispatch(showLoader());
        let currentDate = new Date();
        console.log('ready to api call -----> ', notesData);
        let apiData = {...notesData, 'created': currentDate, loanid:loanid}
        if(notesData?.reason === 'Other'){
            apiData['reason'] = other
        }
        
        addNotes(apiData).then(function (response) {
            if (response) {
               console.log(response?.result);
               dispatch(hideLoader());
               getNotesApi();
               setNotesData({});
               setOther();
               setPopUp(true);
            }
            }).catch((err) => {
              console.error.bind("error", err);
              dispatch(hideLoader());
            })
    }
}
const closePopup =()=>{
    setPopUp(false)
}
const columns = [
    {
        name: 'Communication Type',
        selector: (row) => <span>{row?.communicationtype || ''}</span> || '',
        minWidth: '12%',
        maxWidth: '12%',
    },
    {
        name: 'Outreach By',
        cell: (row) => 
            row?.contact ? (
                <span className="icon-text">
                    <span className="material-symbols-outlined icon">
                        {row?.contact === 'Email' ? 'email' : 'call'}
                    </span>
                    {row?.contact}
                </span>
            ) : '',
            minWidth: '10%',
            maxWidth: '10%',
    },
    {
        name: 'REASON FOR',
        selector: (row) => <span>{row?.reason || ''}</span> || '',
        minWidth: '10%',
        maxWidth: '10%',
    },
    // {
    //     name: 'Type of Contact',
    //     selector: (row) => <span>{row?.typeofcontact || ''}</span> || '',
    //     minWidth: '10%',
    //     maxWidth: '10%',
    // },
    
    {
        name: 'Created On',
        selector: (row) => <span>{moment.utc(row?.created).format("MM-DD-YYYY")}</span> || '',
        // sortable: true,
        // sortFunction: (a, b) => {
        //     const nameA = a?.description?.toLowerCase();
        //     const nameB = b?.description?.toLowerCase();

        //     if (nameA < nameB) {
        //         return -1;
        //     }
        //     if (nameA > nameB) {
        //         return 1;
        //     }
        //     return 0;
        // },
        minWidth: '10%',
        maxWidth: '10%',
    },
    {
        name: 'SUBMITTED BY',
        selector: (row) => row?.username || '',
        minWidth: '15%',
        maxWidth: '15%',
    },
    {
        name: 'Note',
        selector: (row) =>  <span className='notes-clmn'>{row?.description || ''}</span> || '',
        minWidth: '43%',
        maxWidth: '43%',
    },
   
    // {
    //     name: 'Note',
    //     selector: (row) =>  <a href="#" data-bs-toggle="modal" onClick={() => { setViewNote(row?.description) }}
    //     data-bs-target="#viewnote" data-bs-whatever="@mdo">
    //         <button type="button" className="tbl_btn">     View Note</button>
    // </a> || '',
    //     minWidth: '12%',
    //     maxWidth: '12%',
    // },
   
];

const customStyles = {
    //   headRow: {
    // 	style: {
    //         fontWeight: 'bold',
    //         backgroundColor: '#E9F0E6',
    // 	},
    // },
    headCells: {
        style: {
            fontWeight: 'bold',
            backgroundColor: '#E9F0E6',

        },
    },
    table: {
        style: {
            border: '1px solid #CCD8F6',
        },
    },
};

    //Emails Tab
    useEffect(() => {
        setNotesEmailData((prev) => ({
            ...prev,
            ['template']: content,
        }));
    },[content])

    const callNotesApi = () => {
        dispatch(showLoader());
        getUserNoteTemplates(user_id).then(function (response) {
            console.log('get note template response', response);
            if(response?.statusCode === 200){
                setAllNotesTemplates(response?.result);
            }
            dispatch(hideLoader());
            
        }).catch((err) => {
            console.error.bind("get note templates  error", err);
            dispatch(hideLoader());
        })
    }

    useEffect(() => {

        callNotesApi();

    },[]);

    const handleChangeEmailNotes = (e) => {
        let name = e.target.name;
        let value = e.target.value;
       
        setNotesEmailData({...notesEmailData,[name] : value}); 
             
    }

    useEffect(() => {

        if(notesEmailData?.name){

            getTemplateInfo(user_id,notesEmailData?.name).then(function (response) {
                console.log('get specific note template info response', response);
                if(response?.statusCode === 200){
                    const tempRes = response?.result[0];
                    
                    if(notesEmailData['subject'] && errEmail['subject']){
                        errEmail['subject'] = '';    
                    }
                    
                    if(notesEmailData['to'] && (errEmail['to'] || emailToError)){
                        errEmail['to'] = '';
                        setEmailToError('');
                    }
    
                    setEmailErrors(errEmail);
                    setNotesEmailData({...notesEmailData,subject : tempRes?.notesubject});
                    //setQuillValue(tempRes.notedescription);
                    setContent(tempRes?.notedescription);
    
                }
                
            }).catch((err) => {
                console.error.bind("get specific note template info error", err);
               
            })
        }
       
    },[notesEmailData?.name])

    const addErrorClass = (fieldName) => {
        const inputElement = document.querySelector(`.${fieldName}`);
        if (inputElement) {
          inputElement.classList.add('invalid-input');
          //console.log("Error class added to element:", inputElement);
        }
    };
    
      // Remove error classes on all inputs
    const removeErrorClass = (fieldName) => {
        
        const searchWrpElement = document.querySelector(`.${fieldName}`);
        if (searchWrpElement) {
                searchWrpElement.classList.remove("invalid-input");
        }
    }

    useEffect(() => {
        const inputElement = document.querySelector(`.jodit-react-container`);
        if(inputElement.classList.contains('invalid-input')){
            removeErrorClass("jodit-react-container");
        }
    },[notesEmailData?.template])

    const isValidEmail = (email) => {
        // Implement your email validation logic here
        // Example: Check if the email follows a standard format
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const emailRegex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
        // const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
        let emailtest = emailRegex.test(email);
        return emailtest;
      };

    const validateEmailInputs = () => {
        errEmail = {};

        let mandatoryFields = ['subject','to','template'];

        //const isWhitespace = /^<p>(&nbsp;|\s)*<br>\s*<\/p>$/;
        const isWhitespace = /^(<p>(\s|&nbsp;|<br>)*<\/p>)*$/;
        const contentIsEmpty = !content || content === "<p><br></p>" || isWhitespace.test(content);

        mandatoryFields?.map((field) => {
            if(field != 'template' && !notesEmailData[field]?.trim()){
                errEmail[field] = 'required';
            }
            if(field == 'template' && contentIsEmpty){
                addErrorClass("jodit-react-container"); 
            }
            if(field == 'to' &&  notesEmailData[field] && !isValidEmail(notesEmailData[field])){
                errEmail[field] = 'required';
                setEmailToError("Invalid Email!");
            }
           
        })

        setEmailErrors(errEmail);
        
    }

    const handleSendEmail = () => {
        validateEmailInputs();
        const inputElement = document.querySelector(`.jodit-react-container`);

        if(Object.keys(errEmail)?.length === 0 && !inputElement.classList.contains('invalid-input')){
            // const {name , ...emaildataWithoutName} = notesEmailData;
            const {name , ...rest} = notesEmailData;// Destructure to remove the template key
            const updatedEmailData = {...rest , loanid : loanid};
            console.log("Send email data",updatedEmailData);
            // calling api
            dispatch(showLoader());
            sendEmail(updatedEmailData).then(function (response) {
                if (response?.result) {
                    console.log("Notes Send email response",response?.result);
                    setContent('');
                    setNotesEmailData({...notesEmailData,
                        
                        to : '',
                        name : '',
                        subject : '',
                        
                    });
                    setPopUp(true);
                    setMessage(response?.result);
                    
                }
                dispatch(hideLoader());
            }).catch((err) => {
                dispatch(hideLoader());
                console.error.bind("Notes email error", err);
            }) 
        
        }
    }

    const handleResetEmail = () => {
        setContent('');
        setNotesEmailData({...notesEmailData,
            
            to : '',
            name : '',
            subject : '',
            
        });
    }

    const handleEmailFocus = (e) => {
        if(e.target.name == 'to'){
           setEmailToError('') ;
        }
        setEmailErrors({...emailErrors,[e.target.name]:''});
    }

    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader/> : null} 
            {popUp && (
            <PopupMessage
             type={ message == 'Email sent successfully' || message == 'Added Successfully' ? 'success' : 'warning'}
             message={message}
             onClose={closePopup}
            />
        )}
             <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title"> <span className="sub">Notes</span> </h2>
                <div className="card task_block notes">
                    <div className="card-body services_form user_management my_account">
                    
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active"  onClick={() => handleTabChange('Notes')} data-bs-toggle="tab" href="#Notes" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">Notes</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-bs-toggle="tab" onClick={() => handleTabChange('Emails')} href="#INDIVIDUALFORMS" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">Emails</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " data-bs-toggle="tab" onClick={() => handleTabChange('EmailLogs')} href="#EMAILLOGS" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">Email Logs</span>
                            </a>
                        </li>
                    </ul>
                     <div className="tab-content pt-15 text-muted">
                        <div className="tab-pane active" id="Notes" role="tabpanel">
                     
                        <div className=" form_section ">
                                    <div className="row">
                                        <div className='col-md-6 form-group-flex'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Communication Type</label>
                                                        <select name='communicationtype' onChange={handleChange} value={notesData?.communicationtype || ''} className={`form-control ${noteErrors.communicationtype && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, communicationtype: null }) }}>
                                                            <option value=''>Select</option>
                                                            <option value='Inbound'>Inbound</option>
                                                            <option value='Outbound'>Outbound</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                <div className="form-group">
                                                    {/* <label>Type Of Outreach</label> */}
                                                    <label>Communication Medium</label>
                                                    <select name='contact' onChange={handleChange}value={notesData?.contact || ''} className={`form-control ${noteErrors.contact && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, contact: null }) }}>
                                                        <option value="" selected="">Select</option>
                                                        <option value="Call">Call</option>
                                                        <option value="Email">Email</option>
                                                        <option value="Email">Mail</option>
                                                        <option value="Email">Text</option>
                                                    </select>
                                                </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>What Contact Was For</label>
                                                        <select name='reason' onChange={handleChange} value={notesData?.reason || ''} className={`form-control ${noteErrors.reason && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, reason: null }) }}>
                                                        <option value="" selected="">Select</option>
                                                            <option value="Draw">Draw</option>
                                                            <option value="New business">New business</option>
                                                            <option value="Payoff">Payoff</option>
                                                            <option value="Payment">Payment</option>
                                                            <option value="Updates">Updates</option>
                                                            <option value="Verification of mortgage">Verification of mortgage</option>

                                                            <option value='Other'>Other</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-6'>
                                                    <div className="form-group">
                                                        <label>Type of Contact</label>
                                                        <select name='typeofcontact' onChange={handleChange} value={notesData?.typeofcontact || ''} className={`form-control ${noteErrors.typeofcontact && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, typeofcontact: null }) }}>
                                                            <option value='' selected="">Select</option>
                                                            <option value='First Attempt'>First Attempt</option>
                                                            <option value='Second Attempt'>Second Attempt</option>
                                                            <option value='Third Attempt'>Third Attempt</option>
                                                            <option value='Contacted'>Contacted</option>
                                                            <option value='Bad Contact Info'>Bad Contact Info</option>
                                                            <option value='Contact Made'>Contact Made</option>
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className='col-md-6'>
                                                            
                                   {notesData?.reason === 'Other' && 
                                   <div className="form-group">
                                   <label>Other Please Specify</label>
                                   <input tabIndex={2} type="text" value={other} placeholder="Please Specify" onChange={(e)=>{setOther(e.target.value)}} name='other' className={`form-control ${noteErrors.other && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, other: null }) }}/>
                               </div>}
                                            
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-5'>
                                         
                                                    <div className="form-group d-flex">
                                                        <label className='mr_10'>Notes</label>
                                                        <textarea rows="5" value={notesData?.description || ''} cols="40" name='description' onChange={handleChange} className={`form-control ${noteErrors.description && 'invalid-input'}`} onFocus={() => { setNoteErrors({ ...noteErrors, description: null }) }}> </textarea>
                                                    </div>
                                        </div>
                                        <div className='col-md-1'>
                               
                                    
                                    <div className="form-group d-flex">
                                    <label>&nbsp;</label>
                                        <a href="#" onClick={(e)=>{e.preventDefault();handleAdd();}} className="fs_btn">Add</a>
                                    </div>
                                        </div>
                                    </div>
                        
                                 
                              
                                  
                            
                                        
                                
                             
                          
                         
                                {allNotes?.length > 0 && <DataTable className=' notes_table'
                                    columns={columns}
                                    data={allNotes}
                                    pagination
                                    customStyles={customStyles}
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                    paginationComponentOptions={{
                                    rowsPerPageText: 'Per Page',
                                }}
                            />}
                            
                                
                          
                        </div>
                            

                        </div>
                        <div className="tab-pane" id="INDIVIDUALFORMS" role="tabpanel">
                          
                        <div class='account_form user_info'>
                                                {/* <h3 class="mt_20">NOTE TEMPLATES</h3> */}
                                                <div class="form_section">
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                        <h3 class="mb_15">&nbsp;</h3>
                                                            <div class='form'>
                                                                <div class="form-group">
                                                                    <label>Select Template</label>
                                                                    <select id="selectEmailTemplate" name="name" value={notesEmailData?.name} className="form-control select" onChange={handleChangeEmailNotes}>
                                                                        <option value="">Select a Template</option>
                                                                        {allNotesTemplates?.map((option)=>{
                                                                            return(
                                                                                <option value={option._id}>{option.notename}</option>
                                                                            )
                                                                        })}
                                                                            
                                                                    </select>
                                                                </div>



                                                            </div>
                                                        </div>
                                                        <div class="col-md-9">
                                                        <h3 class="mb_15">Send Email</h3>
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <div class='form form1'>

                                                                <div class="form-group">
                                                                    <label>Subject</label>
                                                                    <input id="emailSubject" name="subject" type="text" className={`form-control ${emailErrors?.subject && 'invalid-input'}`}
                                                                        placeholder='Enter the Subject' value={notesEmailData?.subject} onChange={handleChangeEmailNotes} onFocus={handleEmailFocus}/>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class='form'>
                                                                <div class="form-group">
                                                                    <label>From</label>
                                                                    <input id="emailFrom" type="text" name="from" className={`form-control`}
                                                                        placeholder='example@gmail.com' value={notesEmailData?.from} style={{'backgroundColor' : '#e9ecef'}} readOnly/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div class='form'>
                                                                <div class="form-group">
                                                                    <label>To</label>
                                                                    <input id="emailTo" type="text" name="to" className={`form-control ${emailErrors?.to && 'invalid-input'}`}
                                                                        placeholder='recipient@example.com' value={notesEmailData?.to} onChange={handleChangeEmailNotes} onFocus={handleEmailFocus}/>
                                                                    {emailToError && <span style={{'color' : 'red'}}>{emailToError}</span>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                        <div class="col-md-12">
                                                            <div class='form'>

                                                                <div class="form-group">
                                                                    <EditorEdit content={content} setContent={setContent} editorValidate = {editorValidate} setEditorValidate = {setEditorValidate} hidePrint={hidePrint}/>
                                                                </div>
                                                                <div class="btn-group mt_20 mb-30">
                                                                    <button class="border_btn mr_10" onClick={handleResetEmail}>reset</button>
                                                                    <button class="btn solid_btn" onClick={handleSendEmail}>Send</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                            </div>
                                    
                        </div>
                        {/* email logs tab  */}
                        <div className="tab-pane" id="EMAILLOGS" role="tabpanel">
                                {activeTab === 'EmailLogs' && loanid && (
                                    <div className="account_form user_info">
                                        <EmailLogs display="pipeline" loanId={loanid} />
                                    </div>
                                )}
                        </div>
                    </div>
                    
                    </div>
                </div>
                <div className="modal popup new_popup small fade" id="viewnote" data-bs-backdrop="static" tabindex="-1" data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">VIEW NOTE</h5>
                                <button type="button" id='viewClose' className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body add_note">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        <p>{viewNote}</p>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <div className="btn-group mt_20 mb_10"><button onClick={()=>{document?.getElementById('viewClose').click()}} className="fs_btn">okay, got it</button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal popup new_popup fade" id="exampleModal" tabindex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add tasks</h5>
                                <button type="button" className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Assign to User</label>
                                            <select className="form-control">
                                                <option selected="">Please Select</option>
                                                <option>NA</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Default Assignment</label>
                                            <select className="form-control">
                                                <option selected="">Please Select</option>
                                                <option>NA</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Created Date</label>
                                            <input type="date" placeholder="Enter" className="form-control"
                                                 />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Due Date</label>
                                            <input type="date" placeholder="Enter" className="form-control"
                                                 />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email On Task Completion</label>
                                            <input type="text" placeholder="Enter" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="mb_20">&nbsp;</label>
                                            <label className="toggle mb-0">
                                                Complete
                                                <input className="toggle-checkbox" type="checkbox" />
                                                <div className="toggle-switch ml_5"></div>

                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <h3 className="title mt_10 mb_10">Task</h3>
                                        <div className="form-group">
                                            <img src="assets/images/texteditor.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="btn-group mt_20 mb_10"><a href="#"
                                                className="border_btn mr_10">Cancel</a><button className="fs_btn">Add to
                                                task</button></div>
                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default Notes;