import React from "react";
import { useNavigate } from "react-router-dom";
import { useLoanInfo } from "../utils/loanInfoContext";

const InfoBar = (props) => {
  const navigate = useNavigate();
  const { loanInfo } = useLoanInfo();

  const steps = [
    { key: "propertyinfo", title: "Deal Intro", description: "Enter your property Info", icon: "Deal_intro", route: "/propertyinfo" },
    { key: "programtype", title: "Loan Product", description: "Select a Loan Program", icon: "Loan_product", route: "/loanProgram" },
    {
      key: "dealinfo",
      title: "Property Info",
      description: "Tell us more about the property",
      icon: "Property_info",
      route: "/dealinfo",
      condition: loanInfo?.propertyinfo?.loanfor === "Refinance" ||
        (loanInfo?.propertyinfo?.stepIntheProcess !== "I'm doing research" &&
        loanInfo?.propertyinfo?.stepIntheProcess !== "Doing research for borrower"),
    },
    { key: "strategyinfo", title: "Strategy", description: "Lorem ipsum", icon: "Strategy", route: "/strategyinfo" },
    { key: "borrowerinfo", title: "Borrower Info", description: "Enter your property Info", icon: "Borrower_info", route: "/BorrowerInfo" },
    { key: "submitapp", title: "Submit App", description: "Final Step and Ready to go!", icon: "Submit_app", route: "/submitapp" },
  ];

  // Filter out steps based on conditions
  const filteredSteps = steps.filter((step) => step.condition === undefined || step.condition);

  // Find the index of the current step
  const currentStepIndex = filteredSteps.findIndex((step) => step.key === props.currentStage);

  // Function to handle navigation
  const handleNavigation = (index, route) => {
    if (index <= currentStepIndex) {
      navigate(route);
    }
  };

  return (
    <div className="wizard-nav">
      <ul>
        {filteredSteps.map((step, index) => {
          let stepClass = "";
          if (index < currentStepIndex) {
            stepClass = "visited";
          } else if (index === currentStepIndex) {
            stepClass = "active";
          }

          return (
            <li key={step.key} className={stepClass} onClick={() => handleNavigation(index, step.route)}>
              <div className="nav-titles">
                <p>
                  Step {index + 1}
                </p>
                <h5>{step.title}</h5>
                
              </div>
              <div className="nav-icon">
                <img src={`assets/images/wizard-nav/${step.icon}.svg`} className="nav-icon-svg" alt={step.title} />
                <img src={`assets/images/wizard-nav/${step.icon}_active.svg`} className="nav-icon-active" alt={`${step.title} Active`} />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default InfoBar;
