import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import { getallUsers ,emaillogs} from "./../utils/reducer";
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import HomeFooter from './HomeFooter.js';
import { getUserDataFromLocalStorage } from '../utils/local';
import EditorEdit from './EditorEdit.js';
import moment from 'moment';
import Select from 'react-select';

const EmailLogs = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loaderVisible);
  const [formSection,setFormSection] = useState(false)
  const [content, setContent] = useState('');
  const [emailLogs,setEmailLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [formData , setFormData] = useState({});
  const [status,setStatus] = useState('');
  const pageType = props?.display;
  
  useEffect(() => {
    getAllEmailLogs(page,perPage);
  }, [props.loanId]);

  const getAllEmailLogs= (page,perPage) => { 
    dispatch(showLoader());
let loanId = props.loanId || ''
    emaillogs(page,perPage,loanId).then(function (response) {
        console.log('response--->',response);
        dispatch(hideLoader());
    if (response?.result) {
        setEmailLogs(response?.result?.data);
        setTotalRows(response?.result?.totalContent || response?.result?.totalCount || 0);
    }
    }).catch((err) => {
      console.error.bind("error", err);
      dispatch(hideLoader());
    })
};

// table columns
  const columns = [
    
    {
        name: 'From',
        selector: (row) => row?.fromEmailid || row?.from,
        minWidth: '22%',
        maxWidth: '22%',
      },
      {
        name: 'To',
        selector: (row) => {
          const toEmailid = row?.toEmailid;
          if (Array.isArray(toEmailid)) {
            return toEmailid.join(', '); 
          }
          return toEmailid; 
        },
        minWidth: '22%',
        maxWidth: '22%',
      },
      {
        name: 'Subject',
        selector: (row) => row?.subject || row?.emailSubject || '',
        minWidth: '17%',
        maxWidth: '17%',
      },
      {
        name: 'Email Type',
        selector: (row) => row?.emailType,
        minWidth: '15%',
        maxWidth: '15%',
      },
     
    {
      name: 'STATUS',
      selector: (row) => <span className={`badge ${ row?.successRes ? '' : 'inactive'}`}>{row?.successRes ? 'Success' : 'Pending'}</span> ,
      minWidth: '7%',
        maxWidth: '7%',
    },
    {
        name: 'Sent On',
        selector: (row) => row.created ? moment.utc(row.created).format("MM-DD-YYYY"):"",
        minWidth: '10%',
        maxWidth: '10%',
      },
    {
        name: 'ACTIONS',
        cell: (row,index) => (
          <button id = {`view-${index}`} onClick={(e) => { viewLog(row); }} className="icon_btn edit">
            <span style={{ color: '#444', fontSize: '16px' }} className="material-icons icon tooltip-container"><span className="tooltip">view</span>visibility</span>
          </button>
        ),
        minWidth: '7%',
        maxWidth: '7%',
      },
  ];
  const customStyles = {
    headCells: {
		style: {
            fontWeight: 'bold',
            backgroundColor: '#E9F0E6',
		
		},
	},
        table: {
      style: {
        border: '1px solid #CCD8F6', 
      },
    },
  };
  const viewLog = (data) => {
    setFormData({
      ...data,
      toEmailid: Array.isArray(data?.toEmailid)
          ? data.toEmailid.map(email => ({ value: email, label: email })) // Convert array to dropdown format
          : data?.toEmailid
          ? [{ value: data.toEmailid, label: data.toEmailid }] // Handle single email as an array
          : []
  });
    setContent(data?.emailTemplate || '');
    setFormSection(true);
    let status = data?.successRes ? 'Success' : 'Pending'
    setStatus(status);
  };
  const handlePageChange = (page) => {
    // if (!isSorting.current) {
        getAllEmailLogs(page,perPage)
        setPage(page);
    // }
};

const handlePerRowsChange = (newPerPage, page) => {
    // if (!isSorting.current) {
        getAllEmailLogs(page,newPerPage)
        setPage(page);
        setPerPage(newPerPage);
    // }
};


  return (
    <>
    {/* // <div id="layout-wrapper"> */}
{loading && pageType !='pipeline' ? <Loader/> : null}

     {/* {pageType != 'pipeline' && <><MainHeader />
      <SideMenu /></>}

      <div className="main-content">
        <div className="page-content user_management email-logs"> */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">EMAIL LOGS</h4>
                  {formSection && 
                   <a href="#" id="userInfoBack" onClick={(e) => {e.preventDefault(); setFormSection(false)}} className="back_btn">
                   <span className="material-icons icon"> arrow_back</span>BACK
                 </a>
                  }
                 
                </div>
                <div className="card">
                
                                  {pageType !='pipeline' ? <div className="card-body"> 
                                  {!formSection ? (
                                          emailLogs.length > 0 ? (
                                              <DataTable
                                                  columns={columns}
                                                  data={emailLogs}
                                                  pagination
                                                  customStyles={customStyles}
                                                  paginationTotalRows={totalRows}
                                                  paginationServer
                                                  paginationPerPage={perPage}
                                                  paginationDefaultPage={page}
                                                  onChangeRowsPerPage={handlePerRowsChange}
                                                  onChangePage={handlePageChange}
                                                //   onSort={handleSort}
                                                  paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                                  paginationComponentOptions={{
                                                      rowsPerPageText: 'Per Page',
                                                  }}
                                              />
                                          ) : (
                                            emailLogs?.length === 0 && (
                                                  <div className="inprogress_page">
                                                      <span className="material-symbols-outlined icon">content_paste_search</span>
                                                      <p>No data found</p>
                                                  </div>
                                              )
                                          )
                ) : <>
                  <div className="form_section">
                    <div className='row'>

                      <div className="col-md-3">
                        <div className="form-group"><label>From</label>
                          <input id="emailFrom" tabindex="4" type="text" name="from" className={`form-control`}
                            placeholder="example@gmail.com"
                            value={formData?.fromEmailid || formData?.from  ||''} disabled={true}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <div className="form-group"><label>To</label>
                          <input id="emailTo" tabindex="4" type="text" name="to" disabled={true}
                            className={`form-control`} placeholder="recipient@example.com" value={
                              Array.isArray(formData?.toEmailid)
                                ? formData?.toEmailid.join(', ') // Convert array to comma-separated string
                                : formData?.toEmailid || '' // If it's a string, use it as is, or fallback to an empty string
                            } />
                        </div>
                      </div> */}
                        <div className="col-md-3">
                          <div className="form-group">
                            <label>To</label>
                            <Select
                              id="emailTo"
                              tabIndex="4"
                              name="to"
                              isMulti
                              disabled
                              // isDisabled={true} // Read-only mode
                              className="form-control"
                              placeholder="No recipients"
                              value={formData?.toEmailid || []} // Ensure dropdown works correctly
                              isClearable={false}
                            />
                          </div>
                        </div>

                      <div className="col-md-3">
                        <div className="form-group"><label>Subject</label>
                          <input id="emailSubject" tabindex="4" type="text" name="subject" disabled={true} className={`form-control`} placeholder="Subject" value={formData?.emailSubject ||formData?.subject || ''} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group"><label>Email Type</label>
                          <input id="emailtype" tabindex="4" type="text" name="emailtype" disabled={true} className={`form-control`} placeholder="Subject" value={formData?.emailType || ''} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group"><label>Status</label>
                          <input id="emailtype" tabindex="4" type="text" name="emailtype" disabled={true} className={`form-control`} placeholder="Subject" value={status || ''} />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Message</label>
                          {/* <textarea className="form-control" rows="4"></textarea> */}

                          <EditorEdit content={content} setContent={setContent} name="template" placeholder="message" hidePrint={'no'} ReadOnlyflag={true} />
                        </div>
                      </div>

                    </div>
                  </div>


                </>}
              </div> :
                !formSection ? (
                  emailLogs.length > 0 ? (
                    <DataTable
                      columns={columns}
                      data={emailLogs}
                      pagination
                      customStyles={customStyles}
                      paginationTotalRows={totalRows}
                      paginationServer
                      paginationPerPage={perPage}
                      paginationDefaultPage={page}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      //   onSort={handleSort}
                      paginationRowsPerPageOptions={[10, 20, 30, 40]}
                      paginationComponentOptions={{
                        rowsPerPageText: 'Per Page',
                      }}
                    />
                  ) : (
                    emailLogs?.length === 0 && (
                      <div className="inprogress_page">
                        <span className="material-symbols-outlined icon">content_paste_search</span>
                        <p>No data found</p>
                      </div>
                    )
                  )
                ) : <>
                  <div className="form_section">
                    <div className='row'>

                      <div className="col-md-3">
                        <div className="form-group"><label>From</label>
                          <input id="emailFrom" tabindex="4" type="text" name="from" className={`form-control`}
                            placeholder="example@gmail.com"
                            value={formData?.fromEmailid || formData?.from|| ''} disabled={true}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <div className="form-group"><label>To</label>
                          <input id="emailTo" tabindex="4" type="text" name="to" disabled={true}
                            className={`form-control`} placeholder="recipient@example.com" value={
                              Array.isArray(formData?.toEmailid)
                                ? formData?.toEmailid.join(', ') // Convert array to comma-separated string
                                : formData?.toEmailid || '' // If it's a string, use it as is, or fallback to an empty string
                            } />
                        </div>
                      </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>To</label>
                            <Select
                              id="emailTo"
                              tabIndex="4"
                              name="to"
                              isMulti
                              disabled
                              // isDisabled={true} // Read-only mode
                              className="form-control"
                              placeholder="No recipients"
                              value={formData?.toEmailid || []} // Ensure dropdown works correctly
                              isClearable={false}
                            />
                          </div>
                        </div>

                      <div className="col-md-3">
                        <div className="form-group"><label>Subject</label>
                          <input id="emailSubject" tabindex="4" type="text" name="subject" disabled={true} className={`form-control`} placeholder="Subject" value={formData?.emailSubject || formData?.subject|| ''} />
                        </div>
                      </div>
                     
                      <div className="col-md-3">
                        <div className="form-group"><label>Status</label>
                          <input id="emailtype" tabindex="4" type="text" name="emailtype" disabled={true} className={`form-control`} placeholder="Subject" value={status || ''} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group"><label>Email Type</label>
                          <input id="emailtype" tabindex="4" type="text" name="emailtype" disabled={true} className={`form-control`} placeholder="Subject" value={formData?.emailType || ''} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Message</label>
                          {/* <textarea className="form-control" rows="4"></textarea> */}

                          <EditorEdit content={content} setContent={setContent} name="template" placeholder="message" hidePrint={'no'} ReadOnlyflag={true} />
                        </div>
                      </div>

                    </div>
                  </div>


                </>

                                  }
                                      
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}

        {/* {pageType != 'pipeline' &&  <HomeFooter />}
      </div>
    </div> */}
    </>
  );
};

export default EmailLogs;