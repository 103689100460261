import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import Header from './Header';
import { useLoanInfo } from '../utils/loanInfoContext';
import InfoBar from './InfoBar';
import {footerSiteUrl, getLoanprograms} from "../utils/reducer";
import { useSelector} from 'react-redux';
import Footer from './Footer';
import WarmAppFooter from './WarmAppFooter';
import PropertyInfo from './PropertyInfo/PropertyInfo';
const LoanProgram = () => {
  const configdata = useSelector(state => state.configdata);
  const replaceEasyStreetCapital = configdata?.admin?.rights?.replace(/Easy Street Capital LLC/g,"easystreetcap.com");
  const siteUrl = footerSiteUrl
    const navigate = useNavigate();
    const { loanInfo, setLoanInfo } = useLoanInfo();
    
    const [properyInfo, setProperyInfo] = useState(loanInfo?.propertyinfo ? loanInfo?.propertyinfo : {});
    console.log('statestate',properyInfo);
   
      const [loanprograms, setLoanprograms] = useState([]);
      useEffect(() => {
       
        getLoanprograms().then(function (response) {
          // console.log('privacymenunewwe',response);
          if (response?.result) {
            setLoanprograms(response?.result);
           
          }
          }).catch((err) => {
            console.error.bind("my account error", err);
            
          })
      }, []);
    useEffect(() => {
      const isPropertyInfoEmpty = Object.keys(loanInfo.propertyinfo).length === 0;
        if (isPropertyInfoEmpty) {
            navigate('/propertyinfo');
        }
    }, [loanInfo]);

    const handleInputChange = (key, value) => {
        // Update the formData with the new key-value pair
       if(value ==  "EASY RENT"){
        window.open('https://www.easystreetcap.com/', '_blank');
        return
       }
        setProperyInfo((prevData) => {
          // Create a copy of the previous data
          let updatedData = { ...prevData };
    
          
      
          // Update the copied data with the new key-value pair
          updatedData = {
            ...updatedData,
            [key]: value,
          };
          console.log('loanInfoloanInfoloanInfo',updatedData);
          setLoanInfo((prevLoanInfo) => ({
            ...prevLoanInfo,
            propertyinfo: updatedData,
          }));
          // if(updatedData?.loanfor == "Refinance" || (updatedData?.stepIntheProcess != "I'm doing research" && updatedData?.stepIntheProcess != "Doing research for borrower")){
          //   navigate('/dealinfo');
          // }else{
          // navigate('/strategyinfo');
          // }
      console.log('updateddddata', updatedData);
          return updatedData;
        });
    };

    // Function to convert a string to camel case
    const toCamelCase = (str) => {
      return str
          .toLowerCase()
          .replace(/(?:^\w|[A-Z]|\b\w|\s+|\W+)/g, (match, index) => {
              if (/[\s\W]/.test(match)) {
                  return ''; // Remove spaces and non-word characters
              }
              return index === 0 ? match.toLowerCase() : match.toUpperCase();
          });
    };

    const addErrorClass = (field) => {
      const inputElement = document.getElementById(field); // Get element by ID
      if (inputElement) {
        inputElement.classList.add('invalid-input');
      }
    };

    const removeError = (field) => {
      const inputElement = document.getElementById(field); // Get element by ID
        if (inputElement) {
          inputElement.classList.remove('invalid-input');
        }
    };

    const handleValidation= () => {
     
      // Fields that must be filled (excluding propertyaddress, which is checked separately)
      console.log('props?.propertyInfo?',properyInfo)
      const requiredFields = [
          //"loanfor",
          "programtype",
         
      ];
      let isValid = true; 
  
      requiredFields?.map((field) => {
          const value = properyInfo?.[field];
          if (!value) {
            addErrorClass("easyBuildLoanProgram");
            addErrorClass("easyFixLoanProgram");
            addErrorClass("easyRentLoanProgram");
            isValid = false; // Found an error
          }else{
            removeError("easyBuildLoanProgram");
            removeError("easyFixLoanProgram");
            removeError("easyRentLoanProgram");
          }
          
      })

      return isValid;
    }

    useEffect(() => {
        if(properyInfo?.programtype){
          removeError("easyBuildLoanProgram");
          removeError("easyFixLoanProgram");
          removeError("easyRentLoanProgram");
        }
    },[properyInfo?.programtype])

    const navNext = (e) => {
      
      //const isValid = handleValidation; // check exist
      
      //if(properyInfo?.loanfor && properyInfo?.loanfor !== ""){
        
        if(properyInfo?.loanfor == "Refinance" || (properyInfo?.stepIntheProcess != "I'm doing research" && properyInfo?.stepIntheProcess != "Doing research for borrower")){
          navigate('/dealinfo');
        }else if(properyInfo?.loanfor == "Purchase"){
          navigate('/strategyinfo');
        }else{
          
        }
      //}
      
      
    };

    return (
      <div className='warmapp_body'>
        

        <Header />

        <div className="wizard-body">
          <div className="wizard-container full-height">
            <div className="wizard-group">
            <InfoBar currentStage={'programtype'} properyInfo={properyInfo} progress={'100%'} />
            {/* <a href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); navigate('/propertyinfo');}}> keyboard_backspace </a> */}
                  <div className="wizard-canvas">
                    <div className="wizard-canvas-header">
                      <h3>Loan Product</h3>
                    </div>
                    <div class="wizard-canvas-body wizard-overflow">
                          <h3 className="text-center mb-3">Choose your loan product</h3>
                          <div className="choose_loan">
                            {loanprograms?.map((key, index) => (
                              <div className={`loan_product ${key.loanProgramType === properyInfo?.programtype ? 'selected' : ''}`}
                                  key={index} 
                                  id={toCamelCase(key?.loanProgramType) + "LoanProgram"} 
                                  onClick={(e) => {handleInputChange('programtype', key.loanProgramType); }}>
                                <div className="loan_product_header">
                                  <h2>{key.loanProgramType.split(' ')[0]}<span>{key?.loanProgramType?.split(' ').slice(1).join(' ')}</span></h2>
                                  <p>{key.description}</p>
                                </div>
                                <div className="loan_product_body">
                                    <ul>
                                    {key?.features.map((item, i) => (<li key={i}> <span class="material-icons"> check_circle</span>{item}
                                                            </li>))}
                                    </ul>
                                </div>
                                <div class="loan_product_footer">
                                    <button type="button" className="select_btn" id="collateralPoolYes">SELECT</button>
                                </div>
                              </div>
                            ))}
                    
                    
                          </div>
                          

                    </div>
                    <div className='wizard-canvas-footer'>
                      
                        <button id="loanProgramNext" type="button" className="save_next"  
                            onClick={(e) => {navNext(e)}}
                            style = {{ opacity : !properyInfo?.programtype ? '0.6' : 1 ,
                              cursor : !properyInfo?.programtype ? 'auto' : 'pointer'
                            }}
                            disabled={!properyInfo?.programtype} // Disable if programtype is empty
                        >
                            SAVE & NEXT<span class="material-icons"> chevron_right </span>
                        </button>
                      
                      
                    </div> 
                    
                


                  </div>
            </div>
          </div>
          
        </div>
        <WarmAppFooter/>                   

      </div>

    );
};

export default LoanProgram;