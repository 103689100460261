import React from 'react';
import { useSelector} from 'react-redux';
import { footerSiteUrl } from '../utils/reducer';
const WarmAppFooter = () => {
  const configdata = useSelector(state => state.configdata);
  const siteUrl = footerSiteUrl
  const replaceEasyStreetCapital = configdata?.admin?.rights?.replace(/Easy Street Capital LLC/g,"easystreetcap.com");

  return (

    // <div className="cp_footer">
    //     <div className="wrapper">
    //     <a href={siteUrl || '#'} target='_blank' className='footer-anchor' rel="noopener noreferrer"> 
    //         <p>{replaceEasyStreetCapital}</p>
    //         </a>
    //         <ul>
    //             <li><a href="privacy">Privacy </a></li>
    //             <li><a href=""> | </a></li>
    //             <li><a href="terms">Terms of Use</a></li>
    //         </ul>
    //     </div>
    // </div>
    <div class="wizard-footer">
        <div class="wizard-container">
            <div class="flex-full">
                <a href={siteUrl || '#'} target="_blank" class="footer-anchor" rel="noopener noreferrer">
                <p>{replaceEasyStreetCapital}</p>
                </a>
                <ul>
                    <li>
                        <a href="privacy">Privacy </a>
                    </li>
                    <li>
                        <a href=""> | </a>
                    </li>
                    <li>
                        <a href="terms">Terms of Use</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  );
};

export default WarmAppFooter;