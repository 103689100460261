import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoanInfo } from '../utils/loanInfoContext';
import { getUserDataFromLocalStorage } from '../utils/local';
import { useSelector, useDispatch } from 'react-redux';

const Header = () => {

  const navigate = useNavigate();

  const { loanInfo, setLoanInfo } = useLoanInfo();
  const close = () => {
    const token = localStorage.getItem('accessToken');

    if (!token) {
      window.location.href = '/';
    }else{
      setLoanInfo({})
      navigate('/pipeline')
    }
};

  const handleExit = (e) => {
    e.preventDefault(); 
    
    
    close();
  }

  return (
    // <div className="header">
    //     <div className="wrapper">
    //         <div className="logo">
    //             <img src="assets/images/brand/logo-light.png" className="img" alt="" />
    //         </div>
    //         {/* <a href="#" id ="exitButton" onClick={(e) => {e.preventDefault(); close(); }} className="btn"> <span className="material-icons"> close </span>EXIT</a> */}
    //         <a href="#" id ="exitButton" onClick={(e) => {handleExit(e) }} className="btn"> <span className="material-icons"> close </span>EXIT</a>
    //     </div>

    // </div>
    <>
      <img src="assets/images/topGraphic.svg" class="topGraphic"/>
      <img src="assets/images/bottomGraphic.svg" class="bottomGraphic"/>
      <div className="wizard-header">
        <div className="wizard-container">
            <div className="flex-full">
                <img src="assets/images/brand/logo-light.png" className="wizard-logo" alt="" />
                <button id ="exitButton" onClick={(e) => {e.preventDefault(); close(); }} className="wizard-closeBtn"> 
                  <span className="material-symbols-outlined"> close </span>
                </button>
            </div>
            {/* <a href="#" id ="exitButton" onClick={(e) => {e.preventDefault(); close(); }} className="btn"> <span className="material-icons"> close </span>EXIT</a> */}
            
        </div>

      </div>
    </>
    
  );
};

export default Header;