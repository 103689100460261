import React, { useState, useEffect} from 'react';
// import { useLocation } from 'react-router-dom';
import MainHeader from '../MainHeader';
import SideMenu from '../SideMenu';
import { createUser } from "../../utils/reducer";
import { useSelector, useDispatch } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/reducers/Actions.js';
import {getStates} from "../../utils/reducer";
import PopupMessage from '../PopupMessage';
import Loader from '../Loader';
import { useNavigate } from 'react-router-dom';
import { getallUsersDropdown } from "../../utils/reducer";
import HomeFooter from '../HomeFooter.js';
import { Appname, APIUrl , footerSiteUrl} from '../../utils/reducer';

const CreateUserLoanAssignments = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [popupMessage, setmessage] = useState('Created user successfully.');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [states, setStates] = useState([]);
    const loading = useSelector((state) => state.loaderVisible);
    const [users, setUsers] = useState([]);
    const configdata = useSelector(state => state.configdata);

    const [warmAppLinkData,setWarmAppLinkData] = useState({
            'warmAppLink' : ''
        });
    const [tooltipText, setTooltipText] = useState("Copy");
    const [generatedUserId,setGeneratedUserId] = useState(null);

    useEffect(() => {
        getStates().then(function (response) {
          console.log('getStates',response);
          if (response?.result) {
             setStates(response?.result)
          }
          }).catch((err) => {
            console.error.bind("my account error", err);
          })
          getallUsersDropdown().then(function (response) {
            // console.log('privacymenunewwegetallUsers',response?.result?.data);
            if (response?.result?.data) {
                const activeUsers = response?.result?.data.filter(user => user.securityInfo && user.securityInfo.status === "ACTIVE");
                // const mappedUsernames = response?.result?.data.map(item => item.userInfo.username);
                const options = activeUsers.map(user => ({
                    value: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
                    label: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
                    userid : user?.userid
                  }));
              setUsers(options);
              dispatch(hideLoader());
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
      }, []);
      const handleNavigateCancel = ()=>{
        navigate('/usermanagement');
      }
    
    // const location = useLocation();
    // const [toggle,setToggle] = useState(false);
    // const previousCreateUser = location.state?.userData;
    // console.log(previousCreateUser);
    // const [arrLoan,setArrLoan] = useState([]);
    // const [loanInputs,setLoanInputs] = useState({
    //     pickloselectable : false,
    //     roundrobin: false,
    //     loanofficer : "",
    //     defaultcloser : "",
    //     defaultcloser2 : "",
    //     selectstate : "",
    //     loanprocessor : "",
    //     loanfunder : "",
    //     loanfunder2 : "",
    //     selectcountry : "",
    //     defaultopener : "",
    //     defaultpostclosure : "",
    //     defaultunderwriter : "",
    //     defaultshipper: "",
    //     defaultservicer2: "",
    //     defaultproccessor2: "",
    //     defaultaccounting: "",
    //     defaultaccounting2: "",
    //     defaulttreasury: "",
    //     defaultsecondary: "",
    // });
    
    const [errors,setErrors] = useState({});
    // const errorMsgs = {};
    const arrLoan = [];

    const handleChangeToggle = (e) => {
        console.log(e.target.checked);
        if(e.target.checked === true){
            props?.setLoanInputs({...props?.loanInputs,[e.target.name] : true });
        }else{
            props?.setLoanInputs({...props?.loanInputs,[e.target.name] : false}); 
        }
        
    }

    const closePopup = () => {
        setPopupOpen(false);
        navigate('/usermanagement')
    }; 
    const handleChangeLoanInputs = (e) => {
        // if (Object.values(errors).some(value => value === '')) {
        //     // Set error for each empty field
        //     errorMsgs[e.target.name] = false;
        //     // setErrors({errors[e.target.name]:e.target.name ? true : false});
        //     return;
        // }

        const selectedUser = users.find(user => user.value === e.target.value);
       
        if(errors[e.target.name]){
            setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: false }));
        }
        props?.setLoanInputs({...props?.loanInputs,[e.target.name] : {
            name : e.target.value,
            userid :selectedUser?.userid
        }});
        console.log('name ',e.target.value)
    }

    const handleCreate = () => {
       arrLoan.push(props?.loanInputs);
        // const validateForm = validate();
        const validateForm = true;
        console.log('validateForm--->',validateForm);
        
        if(validateForm === false){
            return false;
        }else{
            if(props?.checkData === props?.loanInputs){
                console.log('trueeeee');
                setmessage("No Changes To Update");
                setPopupOpen(true);
            }else{
                console.log('loanAssignMenthere',props?.loanInputs,)
                props.setFinalData(prevState => ({...prevState, loanAssignMent: props?.loanInputs,}));
                let mainData = props.finalData;
                mainData.loanAssignMent = props?.loanInputs;
                console.log("loan inputs are valid", props?.finalData);
                dispatch(showLoader());
                createUser(mainData).then(function (response) {
                    if(response?.result ){
                        if(props.isEdit){
                            setmessage("Updated user successfully.")
                        }else{
                            setmessage("Created user successfully.")
                        }
                    }
                    dispatch(hideLoader());
                    setPopupOpen(true);
                    console.log('responseresponse',response);
                  }).catch((err) => {
                    console.log('err--->',err);
                    dispatch(hideLoader());
                  })
            }
           
             // navigate to next page
        }
    }

    const validate = () => {
        let errorMsgs = {};
        const textregex = /^[A-Za-z\s]+$/;
        arrLoan?.map((item,index) => {
            Object.keys(item).map((propName) => {
                // console.log(item[propName])
                if(item[propName] === "" && (item[propName] !== "pickloselectable")
                // || !textregex.test(item[propName])
                ){
                    errorMsgs[propName] = true;
                    return false;
                }else{
                    return true;
                }
                
            })
        })
        setErrors(errorMsgs);

        if (Object.keys(errorMsgs).length === 0) {
            return true;
        }else{
            console.log('rannnn_-------hvbcdybcusdbcfkb');
            return false;
        }
        
    }

    const ReusableSelect = ({ className, name, value, onChange, options, error,id, readonly}) => {
        return (
          <select
            className={className}
            name={name}
            value={value}
            onChange={onChange}
            id={id}
            disabled={readonly || false}
          >
            <option value="">Select</option>
            {/* {options.map((option, index) => (
              <option key={index} value={option?.userInfo?.username}>
                {option?.userInfo?.firstname}
              </option>
            ))} */}
            {options.map(option => (
                                                            <option key={option} value={option?.value}>{option?.label}</option>
                                                        ))}
          </select>
        );
      };

      const generateWarmAppLink = () => {
        if(props?.finalData?.userid){
            //console.log("App name ",Appname);
            //console.log("API url",APIUrl);
            console.log('props',props);
            console.log('config data',configdata)
            setWarmAppLinkData((prevState) => ({
                ...prevState,
                warmAppLink : `${configdata?.admin?.siteUrl}/${props?.finalData?.userid}`,
                //userId : props?.finalData?.userid
            }))
        }
       
    }

    const copyUrlLink = () => {
        if(warmAppLinkData?.warmAppLink){
            navigator.clipboard.writeText(warmAppLinkData?.warmAppLink)
            .then(() => {
                setTooltipText("Copied!");
                setTimeout(() => setTooltipText("Copy"), 2000); // Reset tooltip text after 2 seconds
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
        }
       
    }

    return (
        <div id="layout-wrapper">
{loading ? <Loader/> : null} 
     {isPopupOpen && (
        <PopupMessage
          type={(popupMessage == 'Created user successfully.' || popupMessage ==  "Updated user successfully." || "No Changes to Update") ? 'success' : 'warning'}
          message={popupMessage}
          onClose={closePopup}
        />
      )}
        <MainHeader />
        <SideMenu />
            

            <div className="main-content">

                <div className="page-content user_management">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">{props?.isEdit ? 'Update' : 'Create'} User</h4>
                                    <a id="loanAssignmentsBack" href="#" onClick={(e) => {e.preventDefault(); props.handleBack()}} className="back_btn">
                    <span className="material-icons icon"> arrow_back</span>BACK
                  </a>
                                </div>
                                <div className="steps">
                                    <div className="box bx_progress" onClick={(e) => {props.setCurrentPage('userinfo')}}>
                                        <div className="fill" style={{width: "100%"}}></div>
                                        <span className="text">User
                                            Info</span>
                                        <img src="assets/images/icons/User-Info.svg" className="img" alt="" />
                                    </div>
                                    <div className="box bx_progress " onClick={(e) => {props.setCurrentPage('securityInfo')}}>
                                    <div className="fill" style={{width: "100%"}}></div>
                                        <span className="text">Security
                                            Info</span>
                                        <img src="assets/images/icons/Security-Info.svg" className="img" alt="" />
                                    </div>
                                    <div className="box bx_progress" onClick={(e) => {props.setCurrentPage('stateLicenses')}}>
                                    <div className="fill" style={{width: "100%"}}></div>
                                        <span className="text">State
                                            Licenses</span>
                                        <img src="assets/images/icons/StateLicenses.svg" className="img" alt="" />
                                    </div>
                                    <div className="box bx_progress active" onClick={(e) => {props.setCurrentPage('loanAssignMent')}}>
                                        <span className="text">My Default Loan
                                            Assignments</span>
                                        <img src="assets/images/icons/LoanAssignments.svg" className="img" alt="" />
                                    </div>
                            </div>
                                <div className="card">
                                    <div className="card-body ">
                                        <div className="form_section">
                                            <h3 className="title">MY DEFAULT LOAN ASSIGNMENTS</h3>
                                            <div className="row">
                                                <div className="col-md-3">
                                                <div className="form-group ">
                                                    <p className='mb_15'>&nbsp;</p>
                                                <label className="toggle">
                                                        <input id="pickLoSelectable" className="toggle-checkbox" type="checkbox" name="pickloselectable" checked={props?.loanInputs?.pickloselectable} onChange={handleChangeToggle} />
                                                        <div className="toggle-switch" id="pickSelectable"></div>
                                                        <span className="toggle-label">Pick My AE Selectable</span>
                                                    </label>

                                                </div>
                                                    <div className="form-group ">
                                                        <label>Change Underwriter To</label>
                                                        <ReusableSelect
                                                            className={`form-control ${errors.changeunderwriterto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changeunderwriterto"
                                                            value={props?.loanInputs?.changeunderwriterto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id='changeunderwriterto'
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Change Processor To</label>
                                                        <ReusableSelect
                                                            className={`form-control ${errors.changeprocessorto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changeprocessorto"
                                                            value={props?.loanInputs?.changeprocessorto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="changeprocessorto"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Change Servicer 2 To</label>
                                                        <ReusableSelect
                                                            className={`form-control ${errors.changeservicer2to === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changeservicer2to"
                                                            value={props?.loanInputs?.changeservicer2to?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="changeservicer2to"
                                                        />
                                                    </div>
                                             
                                                  
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Admin To</label>
                                                       

                                                        <ReusableSelect
                                                            className={`form-control ${errors.adminto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="adminto"
                                                            value={props?.loanInputs?.adminto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="adminto"
                                                        />
                                                    </div>
                                               
                                                    <div className="form-group">
                                                        <label>Change Closer To</label>

                                                        <ReusableSelect
                                                            className={`form-control ${errors.changecloserto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changecloserto"
                                                            value={props?.loanInputs?.changecloserto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="changecloserto"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Change Processor 2 To</label>

                                                        <ReusableSelect
                                                            className={`form-control ${errors.changeprocesser2to === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changeprocesser2to"
                                                            value={props?.loanInputs?.changeprocesser2to?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="changeprocesser2to"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Change Draw Management To</label>

                                                        <ReusableSelect
                                                            className={`form-control ${errors.changedrawmanagementto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changedrawmanagementto"
                                                            value={props?.loanInputs?.changedrawmanagementto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="changedrawmanagementto"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                <div className="form-group">
                                                        <label>Change Account Executive Officer To</label>

                                                            <ReusableSelect
                                                            className={`form-control ${errors.accountexecutive === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="accountexecutive"
                                                            value={props?.loanInputs?.accountexecutive?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="accountexecutive"
                                                            readonly={props.finalData?.securityInfo?.role == "accountexecutive" ? true : false}
                                                        />
                                                    </div>
                   
                                                    <div className="form-group">
                                                        <label>Change Funder To</label>
                                                        
                                                        <ReusableSelect
                                                            className={`form-control ${errors.changefunderto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changefunderto"
                                                            value={props?.loanInputs?.changefunderto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="changefunderto"
                                                        />
                                                    </div>
                                                
                                                    <div className="form-group">
                                                        <label>Change Accounting To</label>
                                                        <ReusableSelect
                                                            className={`form-control ${errors.changeaccountingto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changeaccountingto"
                                                            value={props?.loanInputs?.changeaccountingto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id='changeaccountingto'
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Change QC To</label>
                                                        <ReusableSelect
                                                            className={`form-control ${errors.changeqcloanto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changeqcloanto"
                                                            value={props?.loanInputs?.changeqcloanto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id='changeqcloanto'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                <div className="form-group">
                                                        <label>Change Opener/Setup To</label>

                                                        <ReusableSelect
                                                            className={`form-control ${errors.changeopenerto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changeopenerto"
                                                            value={props?.loanInputs?.changeopenerto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="changeopenerto"
                                                        />
                                                    </div>
                                                
                                                    <div className="form-group">
                                                        <label>Change Account Executive Assistant To</label>

                                                        <ReusableSelect
                                                            className={`form-control ${errors.changeaccountexceutiveto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changeaccountexceutiveto"
                                                            value={props?.loanInputs?.changeaccountexceutiveto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="changeaccountexceutiveto"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Change Servicer To</label>

                                                        <ReusableSelect
                                                            className={`form-control ${errors.changeservicerto === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changeservicerto"
                                                            value={props?.loanInputs?.changeservicerto?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="changeservicerto"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Change Account Executive Assistant 2 To</label>
                                                        <ReusableSelect
                                                            className={`form-control ${errors.changeaccountexceutiveto2 === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="changeaccountexceutiveto2"
                                                            value={props?.loanInputs?.changeaccountexceutiveto2?.name}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="changeaccountexceutiveto2"
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                            


                                            {/* <h3 className="title">MY DEFAULT LOAN ASSIGNMENTS</h3>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <label className="toggle">
                                                        <input id="pickLoSelectable" className="toggle-checkbox" type="checkbox" name="pickloselectable" checked={props?.loanInputs?.pickloselectable} onChange={handleChangeToggle} />
                                                        <div className="toggle-switch"></div>
                                                        <span className="toggle-label">Pick My AE Selectable</span>
                                                    </label>
                                                    <div className="form-group mt_40">
                                                        <label>My Default Closer2</label>
                                                        <ReusableSelect
                                                            className={`form-control ${errors.defaultcloser2 === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultcloser2"
                                                            value={props?.loanInputs?.defaultcloser2}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id='defaultCloser2'
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>My Default Loan Post-Closer</label>
                                                        <ReusableSelect
                                                            className={`form-control ${errors.defaultpostcloser === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultpostcloser"
                                                            value={props?.loanInputs?.defaultpostcloser}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="defaultPostCloser"
                                                        />
                                                    </div>
                                             
                                                  
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>My Default Account Executive</label>
                                                       

                                                        <ReusableSelect
                                                            className={`form-control ${errors.accountexecutive === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="accountexecutive"
                                                            value={props?.loanInputs?.accountexecutive}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="accountExecutive"
                                                        />
                                                    </div>
                                               
                                                    <div className="form-group">
                                                        <label>My Default Opener/ Setup</label>

                                                        <ReusableSelect
                                                            className={`form-control ${errors.defaultopener === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultopener"
                                                            value={props?.loanInputs?.defaultopener}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="defaultOpener"
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>My Default Underwriter</label>

                                                        <ReusableSelect
                                                            className={`form-control ${errors.defaultunderwriter === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultunderwriter"
                                                            value={props?.loanInputs?.defaultunderwriter}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="defaultUnderwriter"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                <div className="form-group">
                                                        <label>My Default Loan Processor</label>

                                                            <ReusableSelect
                                                            className={`form-control ${errors.loanprocessor === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="loanprocessor"
                                                            value={props?.loanInputs?.loanprocessor}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="loanProcessor"
                                                        />
                                                    </div>
                   
                                                    <div className="form-group">
                                                        <label>My Default Loan Funder</label>
                                                        
                                                        <ReusableSelect
                                                            className={`form-control ${errors.loanfunder === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="loanfunder"
                                                            value={props?.loanInputs?.loanfunder}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="loanFunder"
                                                        />
                                                    </div>
                                                
                                                    <div className="form-group">
                                                        <label>My Default Shipper</label>
                                                        <ReusableSelect
                                                            className={`form-control ${errors.defaultshipper === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultshipper"
                                                            value={props?.loanInputs?.defaultshipper}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id='defaultShipper'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                <div className="form-group">
                                                        <label>My Default Closer</label>

                                                        <ReusableSelect
                                                            className={`form-control ${errors.defaultcloser === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultcloser"
                                                            value={props?.loanInputs?.defaultcloser}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="defaultCloser"
                                                        />
                                                    </div>
                                                
                                                    <div className="form-group">
                                                        <label>My Default Loan Funder2</label>

                                                        <ReusableSelect
                                                            className={`form-control ${errors.loanfunder2 === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="loanfunder2"
                                                            value={props?.loanInputs?.loanfunder2}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="loanFunder2"
                                                        />
                                                    </div>

                                                </div>
                                            </div> */}
                                            {/* <hr className="hr" /> */}
                                                {/* <h3 className="title">ASSISTANT</h3> */}
                                                {/* <div className="row ">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>My Default Servicer2</label>
                                                        <ReusableSelect
                                                            className={`form-control ${errors.defaultservicer2 === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultservicer2"
                                                            value={props?.loanInputs?.defaultservicer2}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="defaultServicer2"
                                                        />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>My Default Treasury</label>
                                                            <ReusableSelect
                                                            className={`form-control ${errors.defaulttreasury === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaulttreasury"
                                                            value={props?.loanInputs?.defaulttreasury}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="defaultTreasury"
                                                        />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>My Default Processor 2</label>
                                                            <ReusableSelect
                                                            className={`form-control ${errors.defaultproccessor2 === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultproccessor2"
                                                            value={props?.loanInputs?.defaultproccessor2}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="defaultProccessor2"
                                                        />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>My Default Secondary</label>
                                                            <ReusableSelect
                                                            className={`form-control ${errors.defaultsecondary === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultsecondary"
                                                            value={props?.loanInputs?.defaultsecondary}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id='defaultSecondary'
                                                        />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>My Default Accounting</label>
                                                            <ReusableSelect
                                                            className={`form-control ${errors.defaultaccounting === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultaccounting"
                                                            value={props?.loanInputs?.defaultaccounting}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="defaultAccounting"
                                                        />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>My Default Accounting2</label>
                                                            <ReusableSelect
                                                            className={`form-control ${errors.defaultaccounting2 === true && 'invalid-input'}`} // Add any specific class names you might need
                                                            name="defaultaccounting2"
                                                            value={props?.loanInputs?.defaultaccounting2}
                                                            onChange={handleChangeLoanInputs}
                                                            options={users} // Assuming `users` is available in props
                                                            id="defaultAccounting2"
                                                        />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <hr className="hr" />
                                                {((props?.finalData?.securityInfo?.role === "Account Executive") || (props?.loanInputs?.pickloselectable)) && 
                                                    <div className='row'>
                                                        
                                                        <div className='col-md-6'>
                                                            <div className="form-group warmapp_link">
                                                                {/* <label> &nbsp; </label> */}
                                                                <input id="generateLinkUrl" value={warmAppLinkData?.warmAppLink} className="form-control" name='warmAppLink' type="text" tabIndex={7} readOnly/>
                                                                <button id="copyLink" type="button" className="btn btn-outline-secondary mt-4" style={{'border' : 'none'}}
                                                                onClick={copyUrlLink}
                                                                disabled={!warmAppLinkData?.warmAppLink}
                                                                >
                                                                    <span className="material-symbols-outlined icon tooltip-container">
                                                                        <span className="tooltip">{tooltipText}</span>
                                                                        content_copy 
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <div className="form-group">
                                                                {/* <label> &nbsp; </label> */}
                                                                <button id="generateWarmappLink" type="button" onClick={generateWarmAppLink} className="generate_password fs_btn generate_link">
                                                                    Generate Warmapp Link
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                    <div className="btn-group mt_20">
                                                        <button id="cancelLoanAssignment" onClick={(e) => {e.preventDefault();handleNavigateCancel() }} className="border_btn mr_10">Cancel</button>
                                                        <button id="submitLoanAssignment" className="fs_btn" onClick={handleCreate}>{props.isEdit ? 'Update' : 'Save'}</button>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
<HomeFooter/>
                </div>

            </div>
            );
};

            export default CreateUserLoanAssignments;