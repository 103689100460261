import React , {useState , useEffect } from 'react';
import MainHeader from './MainHeader';
import SideMenu from './SideMenu';
import HomeFooter from './HomeFooter';
import { useNavigate,useLocation } from 'react-router-dom';
import { useDispatch, useSelector} from 'react-redux';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { getAllFundManagmentAdmin , getAllInvestors , fundDistribution , payDistribution, updateApplicant} from '../utils/reducer';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import * as XLSX from "xlsx";
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';   
import PopupMessage from './PopupMessage.js';
import {isValidPercentage , formatCurrencyDisplay} from './../utils/commonUtils';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
import CurrencyInput from 'react-currency-input-field';


const AdminFundDistribution = () => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const navigate = useNavigate();
    const [fundsDropdown,setFundsDropdown] = useState([]);
    const [distributionData,setDistributionData] = useState({
        actualRate : false
    });
    const [selectAllbox,setSelectAllBox] = useState(false);
    const [defaultFund,setDefaultFund] = useState([]);
    const [investorFundDropdown,setInvestorFundDropdown] = useState([]);
    const [defaultInvestorDropdown,setDefaultInvestorDropdown] = useState([]);
    const [totalcount,setTotalCount] = useState(null);
    const [currentPage,setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [fundIdsArr,setFundIdsArr] = useState([]);
    const [show,setShow] = useState(false);
    const [distributionErrors,setDistributionErrors] = useState({});
    const [distributionTableOldData,setDistributionTableOldData] = useState([]);
    const [distributionTableData,setDistributionTableData] = useState([]);//new 
    const [changedId,setChangedId] = useState(null);//investor id
    const [isEqual,setIsEqual] = useState(false);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message , setMessage] = useState('Saved Successfully');
    const [rowChanges, setRowChanges] = useState({}); // Track individual row changes
    let userData = getUserDataFromLocalStorage();
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const location = useLocation();

    let errors = {}


    const columnWidth = '7%'; // Adjust the width for each column as needed

    const columns = [
        {
            name: 'FUND NAME',
            selector: (row) => row.fundname,
            sortable: true,
            minWidth: '17%',
            maxWidth: '17%',
        },
        {
            name: 'INCOME / GROWTH',
            selector: (row) => row.typeofreturn,
            sortable: true,
            minWidth: '10%',
            maxWidth: '10%',
        },
        {
            name: 'INVESTOR NAME',
            selector: (row) => row.investorname,
            sortable: true,
            minWidth: '17%',
            maxWidth: '17%',
        },
        {
            name: `${moment.utc(distributionData?.startDate).format("MM-DD-YYYY")} BALANCE`,
            selector: (row) => row.initialbalance,
            sortable: true,
            //cell: (row) => `$${row.initialbalance.toFixed(2)}`, // Format as currency
            cell: (row) => formatCurrencyDisplay(row.initialbalance), // Format as currency
            minWidth: '12%',
            maxWidth: '12%',
        },
        {
            name: `${moment.utc(distributionData?.endDate).format("MM-DD-YYYY")} BALANCE`,
            selector: (row) => row.finalbalance,
            sortable: true,
            //cell: (row) => `$${row.finalbalance.toFixed(2)}`, // Format as currency
            cell: (row) => formatCurrencyDisplay(row.finalbalance), // Format as currency
            minWidth: '12%',
            maxWidth: '12%',
        },
        {
            name: 'PERCENTAGE',
            selector: (row) => row.percentage,
            sortable: true,
            //cell: (row) => `${row.percentage}%`, // Add percentage sign
            cell: (row) => (
                <input
                    id = {`percentage-${row?.investorid}`}
                    type="text"
                    className='form-control'
                     value={row?.percentage}
                    onChange={(e) => handleRowChange(row.investorid, e.target.value,'percentage')}
                    style={{ width: '70px', textAlign: 'right' }}
                    onBlur={(e) => handleBlur(row.investorid, e.target.value, 'percentage',row)} // Format on blur
                />
            ),
            minWidth: '10%',
            maxWidth: '10%',
        },
        {
            name: 'DISBURSEMENT',
            selector: (row) => row.disbursementamount,
            sortable: true,
            cell: (row) => (
                <CurrencyInput
                    id={`disbursement-${row?.investorid}`}
                    className="form-control"
                    value={row?.disbursementamount || ""}
                    decimalsLimit={2}
                    prefix='$'
                    placeholder='$0.00'
                    decimalScale={2}
                    allowNegativeValue={false} // Adjust based on your needs
                    onValueChange={(value) =>
                        handleRowChange(row.investorid, value, 'disbursementamount', row)
                    }
                    style={{ width: '100%', textAlign: 'right' }}
                />
            ),
            minWidth: '12%',
            maxWidth: '12%',
        },
        
        
        
        // {
        //     name: 'INCOME',
        //     selector: (row) => row.income,
        //     sortable: true,
        // },
        {
            name: 'INCOME WITHDRAW',
            selector: (row) => row?.withdrawamount ? formatCurrencyDisplay(row?.withdrawamount) : '$0.00',
            sortable: true,
            // cell: (row) => row?.withdrawamount ? formatCurrencyDisplay(row?.withdrawamount) : '', // Format as currency
            minWidth: '10%',
            maxWidth: '10%',
        },
        
        // {
        //     name: 'ACTIONS',
        //     cell: (row) => {
                
        //         let isDisabled = true;
        //         // distributionTableOldData?.forEach(rowOld => {
        //         //     if(Object.keys(rowChanges)?.length !== 0){
        //         //         Object.entries(rowChanges)?.forEach(([key,value]) => {
        //         //             if(rowOld?.investorid == key){
        //         //                 //isDisabled = (parseFloat(row?.percentage) === parseFloat(rowOld?.percentage));
        //         //                 isDisabled = (
        //         //                     parseFloat(value.percentage) === parseFloat(rowOld.percentage) &&
        //         //                     parseFloat(value.disbursementamount) === parseFloat(rowOld.disbursementamount)
        //         //                 );
        //         //             }
        //         //         })
        //         //     }else{
        //         //         if(rowOld?.investorid === row?.investorid){
        //         //             //isDisabled = (parseFloat(row?.percentage) === parseFloat(rowOld?.percentage));
        //         //             isDisabled = (
        //         //                 parseFloat(row.percentage) === parseFloat(rowOld.percentage) &&
        //         //                 parseFloat(row.disbursementamount) === parseFloat(rowOld.disbursementamount)
        //         //             );
        //         //         }
        //         //     }
                    
                    
        //         // })

        //         // Disable the button if rowChanges for the current investor ID is empty
        //         if (rowChanges[row?.investorid]) {
        //             distributionTableOldData?.forEach((rowOld) => {
        //                 if (rowOld?.investorid === row?.investorid) {
        //                     isDisabled = !(
        //                         parseFloat(row.percentage) !== parseFloat(rowOld.percentage) 
        //                         //||
        //                         //parseFloat(row.disbursementamount) !== parseFloat(rowOld.disbursementamount)
        //                     );
        //                 }
        //             });
        //         } else {
        //             isDisabled = true; // Disable if no changes
        //         }
        //         // const isDisabled = !rowChanges[row.investorid] || 
        //         //         (rowChanges[row.investorid] && (parseFloat(rowChanges[row.investorid].percentage) !== parseFloat(row?.percentage))) 
        //         //             // || !rowChanges[row.investorid].disbursementamount === row.disbursementamount);

        //         return (
        //             <button
        //                 id = {`save-${row?.investorid}`}
        //                 className="border_btn"
        //                 style={{
        //                     opacity: isDisabled ? '0.5' : '1',
        //                     pointerEvents: isDisabled ? 'none' : 'auto',
        //                     backgroundColor: isDisabled ? '#F0F0F0' : '#fff',
        //                 }}
        //                 disabled={isDisabled}
        //                 onClick={() => handleUpdateChange(row)}
        //             >
        //                 Save
        //             </button>
        //         );
        //     },
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        //     minWidth: '85px',
        //     maxWidth: '85px',
        // }
        
        
    ];
    

    const getFundsName =()=>{
        dispatch(showLoader());
        let type = 'fundDropdown'
        getAllFundManagmentAdmin('','',type,'')
        .then(function(response) {
            console.log('funds name',response)
            if (response?.result?.data) {
                //setFundsDropdown(response?.result?.data)
                const defaultFund = response.result.data.find(fund => fund.default === true);
                const defaultUuid = defaultFund ? defaultFund.uuid : null;
                const dropdownOptions = response.result.data.map((fund) => ({
                    uuid : fund?.uuid,
                    value: fund?.name, // Use fund name as value
                    label: fund?.name  // Use fund name as label
                }));
                setFundsDropdown([ ...dropdownOptions]);
                setDistributionData((prevState) => ({
                    ...prevState,
                    ['funds']: [{
                       uuid: defaultFund?.uuid,
                       value: defaultFund?.name, // Use fund name as value
                       label: defaultFund?.name 
                    }], 
                  }));
                //   const idOptions = defaultFund?.map((funds) => funds?.uuid);
                  console.log('defaultFund',defaultFund);
                  setDefaultFund([{
                    uuid: defaultFund?.uuid,
                    value: defaultFund?.name, // Use fund name as value
                    label: defaultFund?.name 
                 }]);
                  getInvestorFunds([defaultUuid]);
               
                // dispatch(hideLoader());
            }else{
                setFundsDropdown([]);
                //setFundIdsArr([]);
                dispatch(hideLoader())
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
        
    }
   
    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.permission
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }
    
            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.permission
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

    useEffect(() => {
        getFundsName()
    }, []);

  
     //call API and set value in investor fund dropdown
     const getInvestorFunds = (fundIdsArr) => {
        
        console.log("fundIdsArr",fundIdsArr);
        if (!Array.isArray(fundIdsArr) || fundIdsArr.length === 0) {
            setInvestorFundDropdown([]); // Reset the dropdown if no options are selected
            setDistributionData((prevState) => ({
                ...prevState,
                ['investorid']: [], 
              }));
            return; // Exit early
        }
        let updatedData = {
            "filterid" : fundIdsArr
        }
        dispatch(showLoader());
        getAllInvestors(0,0,'','',updatedData)
            .then(function(response) {
            console.log('funds name',response)
            if (response?.result?.data?.length > 0) {
                // setInvestorFundDropdown(response?.result?.data);
                const easyStreetInvestors = response?.result?.data?.filter(
                    (fund) => fund?.fundname === 'Easy Street Capital LLC'
                );
                const defaultDropdownOptions = easyStreetInvestors?.map((fund) => ({
                    investorid: `${fund?.investorid}`,
                    value: `${fund?.firstname} ${fund?.lastname}`, // Use full name as value
                    label: `${fund?.firstname} ${fund?.lastname}`  // Use full name as label
                }));
                setDefaultInvestorDropdown(defaultDropdownOptions);
                const dropdownOptions = response?.result?.data?.map((fund) => ({
                    investorid : `${fund?.investorid}`,
                    value: `${fund?.firstname} ${fund?.lastname}`, // Use fund name as value
                    label: `${fund?.firstname} ${fund?.lastname}`  // Use fund name as label
                }));
                const validInvestorIds = dropdownOptions?.map(option => option?.investorid);

                // Filter `distributionData?.investorid` to remove invalid entries
                const updatedInvestorIds = distributionData?.investorid?.filter((item) =>
                    validInvestorIds?.includes(item?.investorid)
                ) || [];
                setDistributionData((prev) => ({
                    ...prev,
                    investorid: updatedInvestorIds
                  }));
                setInvestorFundDropdown([ ...dropdownOptions]);
            }else{
                setInvestorFundDropdown([]);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error("error", err); 
                dispatch(hideLoader());
        });
       
       
    }

    // useEffect(() => {
    //     if (Array.isArray(fundIdsArr) && fundIdsArr?.length > 0) {
    //         console.log("calling get investor funds api");
    //         getInvestorFunds();
    //     } else {
    //         setInvestorFundDropdown([]); // Reset the dropdown if no options are selected
    //     }
    // }, [distributionData?.funds]); // Runs when fundname changes
    

    const handlePerPageChange = (newperPage,page) => {
        setPerPage(newperPage);
        setCurrentPage(page); // Reset to first page
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const customStyles = {
        //   headRow: {
      // 	style: {
        //         fontWeight: 'bold',
        //         backgroundColor: '#E9F0E6',
      // 	},
      // },
        headCells: {
        style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',
        
        },
      },
            table: {
          style: {
            border: '1px solid #CCD8F6', 
          },
        },
    };

    
    const editSheet = (data) => {
        console.log('data',data)
        //navigate('/createfundmanagement', { state: { fundId : data?.uuid,selectedfund: data, fundManagementData:fundManagementData , mode : "Edit" ,fundNames } });
    };
    
    const deleteSheet = (data) => {
        // setShowConfirmation(true);
        // setSelectedOption(data?.uuid);
        // console.log('data',data);
    };
    const selectAll =(e)=>{
        let checkedData = e.target.checked;
        setSelectAllBox(checkedData);
        if(checkedData){
            setDistributionData((prevState) => ({
                ...prevState,
                ['investorid']: investorFundDropdown, // Update the key with the selected options
              }));
        }else{
            setDistributionData((prevState) => ({
                ...prevState,
                ['investorid']: [], // Update the key with the selected options
              }));
        }
    }
    useEffect(() => {
        console.log('checkedData', investorFundDropdown);
        if (distributionData?.investorid?.length === investorFundDropdown.length && investorFundDropdown.length > 0) {
            setSelectAllBox(true);
        } else {
            setSelectAllBox(false);
        }
    }, [distributionData, investorFundDropdown]);
console.log(distributionData);
    const handleMultiSelect = (selectedOptions , name) => {
       
        // Update the `distributionData` state with the selected options
        setDistributionData((prevState) => ({
          ...prevState,
          [name]: selectedOptions, // Update the key with the selected options
        }));
console.log(selectedOptions,name);
        // Dynamically clear the error for the field
        setDistributionErrors((prevErrors) => ({
            ...prevErrors,
            [name]: null, // Remove the error for this field
        }));
      
        // If you need to extract values for further processing
        const selectedValues = selectedOptions ? selectedOptions.map(option => option?.value) : [];
       
        if(name === 'funds'){
            const idOptions = selectedOptions?.map((funds) => funds?.uuid);
            setFundIdsArr(idOptions);
            getInvestorFunds(idOptions);
            console.log('Selected idOptions:', idOptions);
        }
        
        console.log('Selected Values:', selectedValues);
    };

    // const handleDates = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     console.log("date",value);
    //     // Validate if the input matches the `yyyy-mm-dd` format
    //     const dateParts = value.split("-");
    //     if (dateParts.length === 3) {
    //         const [year, month, day] = dateParts;
    
    //         // Ensure the year part has at most 4 digits
    //         if (year.length > 4) return;
    
    //         // Allow only valid values for month and day
    //         if (month && (month > 12 || month < 1)) return;
    //         if (day && (day > 31 || day < 1)) return;
    
    //         // Update the state if the input is valid
    //         setDistributionData((prevState) => ({
    //             ...prevState,
    //             [name]: value,
    //         }));
    //     }
    // };
    
    
    // const handleDates = (e) => {
    //     const { name, value } = e.target;
    
    //     // Current date
    //     const currentDate = moment().format('YYYY-MM-DD');
    
    //     setDistributionData((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    
    //     // Handle custom logic for each date field
    //     if (name === 'startDate' && value) {
    //         // Ensure end date is greater than start date and below current date
    //         dispatch((prevState) => ({
    //             ...prevState,
    //             endDate: moment(prevState.endDate).isAfter(value) && moment(prevState.endDate).isSameOrBefore(currentDate)
    //                 ? prevState.endDate
    //                 : '',
    //         }));
    //     }
    
    //     if (name === 'endDate' && value) {
    //         // Ensure start date is less than end date and below current date
    //         dispatch((prevState) => ({
    //             ...prevState,
    //             startDate: moment(prevState.startDate).isBefore(value) && moment(prevState.startDate).isSameOrBefore(currentDate)
    //                 ? prevState.startDate
    //                 : '',
    //         }));
    //     }
    
    //     if (name === 'disbursementDate' && value) {
    //         // Ensure disbursement date is greater than both start and end dates
    //         const startDate = distributionData.startDate;
    //         const endDate = distributionData.endDate;
    
    //         const isValidDisbursementDate =
    //             startDate && endDate && moment(value).isAfter(startDate) && moment(value).isAfter(endDate);
    
    //         if (!isValidDisbursementDate) {
    //             alert('Disbursement date must be after both start and end dates.');
    //         }
    //     }
    // };
    
    const handleDates = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        console.log("date", value);
    
        const dateParts = value.split("-");
        // const currentDate = moment().utc.format('YYYY-MM-DD');
    
        if (!value) {
            setDistributionData((prevState) => ({
                ...prevState,
                [name]: "",
            }));
            return;
        }
    
        if (dateParts.length === 3) {
            const [year, month, day] = dateParts;
    
            if (year.length > 4) return;
            if (month && (month > 12 || month < 1)) return;
            if (day && (day > 31 || day < 1)) return;
    
            setDistributionData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    
        if (name === 'startDate' && value) {
            // Calculate the last day of the selected month
            const endOfMonth = moment.utc(value).endOf('month').format('YYYY-MM-DD');
    
            setDistributionData((prevState) => ({
                ...prevState,
                startDate: value,
                endDate: endOfMonth, // Automatically set End Date
            }));
        }
    
        if (name === 'endDate' && value) {
            setDistributionData((prevState) => ({
                ...prevState,
                disbursementDate: moment(prevState.disbursementDate).isAfter(value) 
                    ? prevState.disbursementDate 
                    : '', // Reset if invalid
            }));
        }
    };
    
    
 

    // const handleText = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;
    //     // Regex to accept values between 0 and 100, including decimals
    //     const regex = /^(100(\.0+)?|([0-9]{1,2}(\.[0-9]+)?))$/;
    //     if(name === "percentage"){
    //        // Only update the state if the input matches the regex or is empty
    //        if (isValidPercentage(value)) {
    //             setDistributionData((prevState) => ({
    //                 ...prevState,
    //                 [name]: value,
    //             }));
    //         }
    //     }else{
    //         setDistributionData((prevState) => ({
    //             ...prevState,
    //             [name]: value,
    //         }));
    //     }
       
    // }

    const handleText = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const cursorPosition = e.target.selectionStart; // Get the current cursor position
    
        // Handle percentage input with validation
        if (name === "percentage") {
            // Allow only numbers and at most one decimal point
            let validNumber = value.replace(/[^0-9.]/g, "");
    
            // Ensure no multiple decimal points
            const decimalCount = (validNumber.match(/\./g) || []).length;
            if (decimalCount > 1) {
                return; // Exit if multiple decimals
            }
    
            // Prevent leading zero issue (avoid "00" or "01")
            if (validNumber.startsWith("0") && validNumber.length > 1 && validNumber[1] !== ".") {
                validNumber = validNumber.replace(/^0+/, "0");
            }
    
            // Ensure valid number format (allow empty string for dynamic deletion)
            const regex = /^(?:\d+|\d+\.\d{0,2})?$/;
            if (!regex.test(validNumber) && validNumber !== "") {
                return; // Exit if input is invalid
            }
    
            // Convert to number for range validation
            const numValue = validNumber ? parseFloat(validNumber) : "";
    
            // Ensure percentage is within the range 0-100
            if (numValue !== "" && (numValue < 0 || numValue > 100)) {
                return; // Prevent values outside 0-100
            }
    
            value = validNumber; // Assign formatted value
        }
    
        // Update state
        setDistributionData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    
        // Prevent cursor from jumping to the end
        setTimeout(() => {
            e.target.selectionStart = cursorPosition;
            e.target.selectionEnd = cursorPosition;
        }, 0);
    };
    
        
    const handleCheckBoxes = (e) => {
       let { name , value , checked } = e.target;
        setDistributionData((prevState) => ({
            ...prevState,
            [name]: checked,
          }));
    }

    const handleValidation = (data) => {
        errors = {};
        const distributionFields = ["funds","startDate","endDate","disbursementDate"];
        
        distributionFields?.forEach((field) => {
            if(!Array.isArray(data[field]) && !data[field]){
                errors[field] = "Required";
            }else if(Array.isArray(data[field]) && data[field]?.length === 0){
                errors[field] = "Required";
            }
        })
        setDistributionErrors(errors);
        return Object.keys(errors)?.length === 0;
    
       
    }

    useEffect(() => {
        if(distributionData?.startDate){
            //errors = {};
            if(distributionData?.endDate){
                errors['endDate'] = "";
            }
            setDistributionErrors(errors);
        }
        
    },[distributionData?.startDate])

    const handleCalculate = () => {
        let updatedInvestorIds = null;
        if(distributionData?.investorid && distributionData?.investorid.length > 0){
            updatedInvestorIds = distributionData?.investorid?.map((investor) => parseInt(investor.investorid));
        }
        console.log('distributionData',distributionData);
        const updatedFundNames = distributionData?.funds?.map((fund) => fund.uuid);
            // Update distributionData with the transformed investorid array
        let updatedData = {...distributionData};
        updatedData.funds = updatedFundNames;

        // if(distributionData?.investorid){
        //     updatedData.investorid = updatedInvestorIds;
        // }

        if (updatedInvestorIds) {
            updatedData.investorid = updatedInvestorIds;
        } else {
            // Remove investorid if it's empty or null
            delete updatedData.investorid;
        }
        if(distributionData?.percentage){
            updatedData.percentage = parseFloat(distributionData?.percentage);
        }
        
        console.log("final data",updatedData);
        const valid = handleValidation(updatedData);

        if(valid){
            // Map over the investorid array to extract the investorid values as integers
            
            dispatch(showLoader());
            fundDistribution(updatedData)
            .then(function(response) {
            
                // if (response?.result) {
                //     setDistributionTableData(response?.result)
                //     console.log("calculate result",response?.result);
                //     if(distributionTableOldData?.length === 0){
                //         setDistributionTableOldData(response?.result);
                //     }
                //     dispatch(hideLoader());
                // }

                if (response?.result) {
                    // Update the percentage field to 2 decimal places
                    const updatedResult = response.result.map(item => ({
                        ...item,
                        //percentage: (item.percentage).toFixed(2)
                         
                        percentage: (Math.round(item.percentage * 100) / 100).toFixed(2)
                    }));
                
                    setDistributionTableData(updatedResult);
                    console.log("calculate result", updatedResult);
                
                    if (distributionTableOldData?.length === 0) {
                        setDistributionTableOldData(updatedResult);
                    }
                    //dispatch(hideLoader());
                    setRowChanges({});
                }
                
                dispatch(hideLoader())
                
            })
            .catch((err) => {
                console.error("error", err); 
                dispatch(hideLoader());
            });

            setShow(!show);
        }
        
    }

    const handleFocus = (e) => {
        let name = e.target.name;
       
        setDistributionErrors((prevErrors) => ({
            ...prevErrors,
            [name] : null
        }));
      
    }

    // const handleRowChange = (id, newValue, key) => {
    //     // Allow only valid decimal numbers or empty input
    //     const isValidDecimal = (value) => /^(\d+(\.\d{0,2})?)?$/.test(value);

    //     //if(key === "percentage"){
            
            
    //     //}
        
    //     setDistributionTableData((prevData) =>
    //         prevData.map((row) =>
    //             row.investorid === id
    //                 ? {
    //                       ...row,
    //                       [key]:
    //                           key === "disbursementamount" || key === "percentage"
    //                               ? newValue === "" // Allow clearing the input
    //                                   ? ""
    //                                   : isValidDecimal(newValue) // Validate input
    //                                   ? newValue // Temporarily store as a string for user typing
    //                                   : row[key] // Retain original value if input is invalid
    //                               : newValue,
    //                   }
    //                 : row
    //         )
    //     );
    //     setChangedId(id);
    //     setCount(count + 1);
    // };

    // const handleRowChange = (id, newValue, key) => {
    //     setDistributionTableData((prevData) => {
    //         return prevData.map((row) => {
    //             if (row.investorid === id) {
    //                 const originalValue = key === "percentage" ? row.percentage : row.disbursementamount;
    //                  // Regex to accept values between 0 and 100, including decimals
    //                  const regex = /^(100(\.0+)?|([0-9]{1,2}(\.[0-9]+)?))$/;
    
    //                 // Check if value has changed
    //                 const hasChanged = originalValue !== newValue;
    
    //                 // Set row change tracking
    //                 setRowChanges((prev) => ({
    //                     ...prev,
    //                     [id]: hasChanged
    //                 }));
                    
    //                 newValue = (newValue <= 100) ? newValue : '';
    //                 return {
    //                     ...row,
    //                     [key]: newValue
    //                 };
    //             }
    //             return row;
    //         });
    //     });
    // };

    // const handleRowChange = (id, newValue, key) => {
    //     setRowChanges(prevState => ({
    //         ...prevState,
    //         ['investorid']: id // Add/Update the change for the specific row ID
    //     }));
    //     setDistributionTableData((prevData) => {
    //         return prevData.map((row) => {
    //             if (row.investorid === id) {
    //                 const originalValue = row[key];
    
    //                 // Handle percentage formatting and validation
    //                 if (key === "percentage") {
    //                     // Allow only numbers and a single dot
    //                     const regex = /^\d{0,2}(\.\d{0,2})?$/;
    
    //                     // Skip invalid inputs
    //                     if (!regex.test(newValue)) {
    //                         return row;
    //                     }
    
    //                     // Update state with valid value
    //                     return {
    //                         ...row,
    //                         percentage: newValue, // Allow the user to type freely
    //                     };
                        
    //                 }
    
    //                 return {
    //                     ...row,
    //                     [key]: newValue,
    //                 };
    //             }
    //             return row;
    //         });
    //     });
       
    // };
    
    // // Format percentage to two decimal places on blur
    // const handleBlur = (id, value, key) => {
    //     setRowChanges(prevState => ({
    //         ...prevState,
    //         ['investorid']: id // Add/Update the change for the specific row ID
    //     }));
    //     setDistributionTableData((prevData) => {
    //         return prevData.map((row) => {
    //             if (row.investorid === id) {
    //                 const formattedValue = parseFloat(value || 0).toFixed(2); // Default to 0.00 if empty
    //                 return {
    //                     ...row,
    //                     [key]: formattedValue,
    //                 };
    //             }
    //             return row;
    //         });
    //     });
    //     setRowChanges(id);
    // };
    
    
    // const handleRowChange = (id, newValue, key) => {

    //     console.log("value ---->",typeof newValue);
    //     // Update rowChanges to track changes (if needed for other purposes)
    //     setRowChanges(prevState => ({
    //         ...prevState,
    //         [id]: {
    //             ...prevState[id], // Preserve previous changes for this row
    //             [key]: newValue,  // Update the specific key (e.g., 'percentage')
    //         }
    //     }));
        
    //     // Update the table data with the new value
    //     setDistributionTableData((prevData) => {
    //         return prevData.map((row) => {
    //             if (row.investorid === id) {
    //                 // Handle percentage formatting
    //                 if (key === "percentage") {
    //                     // Allow only valid numeric inputs (number and decimal up to two places)
    //                     //const regex = /^\d{0,2}(\.\d{0,2})?$/;
    //                     const regex = /^100(\.0{1,2})?$|^\d{0,2}(\.\d{1,2})?$/;
    //                     if (!regex.test(newValue)) {
    //                         return row; // Invalid input, return the original row
    //                     }
    
    //                     // Update with the valid percentage value
    //                     return {
    //                         ...row,
    //                         percentage: newValue,
    //                     };
    //                 }
    
    //                 // Handle other keys
    //                 return {
    //                     ...row,
    //                     [key]: newValue,
    //                 };
    //             }
    //             return row;
    //         });
    //     });
    // };

    const handleRowChange = (id, newValue, key,row) => {
        console.log("value ---->", typeof newValue, newValue);
    
        if (key === "percentage") {
            // Allow only numbers and at most one decimal point
            let validNumber = newValue.replace(/[^0-9.]/g, "");
    console.log("validNumber",validNumber)
            // Ensure no multiple decimal points
            const decimalCount = (validNumber.match(/\./g) || []).length;
            console.log("decimalCount",decimalCount)
            if (decimalCount > 1) {
                return; // Exit if multiple decimals
            }
    
            // Prevent leading zero issue (avoid "00" or "01")
            if (validNumber.startsWith("0") && validNumber.length > 1 && validNumber[1] !== ".") {
                validNumber = validNumber.replace(/^0+/, "0");
                console.log("validNumber.replac",validNumber)
            }
    
            // Ensure valid number format (allow empty string for dynamic deletion)
            const regex = /^(?:\d+|\d+\.\d{0,2})?$/;
            if (!regex.test(validNumber) && validNumber !== "") {
                return; // Exit if input is invalid
            }
    
            // Convert to number for range validation
            const numValue = validNumber ? parseFloat(validNumber) : "";
    
            if (key === "percentage" && numValue !== "") {
                if (numValue < 0 || numValue > 100) {
                    return; // Prevent values outside 0-100
                }
            }
    
            newValue = validNumber;
        }
    
        if (key === 'disbursementamount') {
            console.log('newValue', typeof newValue);
        
            // Ensure withdrawamount is a float if it's a string
            // const parsedValue = typeof newValue === 'string' ? parseFloat(newValue) || 0 : newValue;
            const parsedValue = newValue
            // Prepare the updated row data
            let updatedRow = { };
        
            if (row?.typeofreturn === 'Income') {
                updatedRow['withdrawamount'] = parsedValue;
            }
        
            // Update rowChanges
            setRowChanges(prevState => ({
                ...prevState,
                [id]: {
                    ...prevState[id],
                    [key]: parsedValue,
                    ...updatedRow,
                }
            }));
        
            
            setDistributionTableData(prevData => {
                return prevData.map(row => {
                    if (row.investorid === id) {
                        return { ...row, [key]: parsedValue ,...updatedRow};
                    }
                    return row;
                });
            });
        }else{
        
        // Update rowChanges to track changes
        setRowChanges(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                [key]: newValue,
            }
        }));


        // Update the table data
        setDistributionTableData(prevData => {
            return prevData.map(row => {
                if (row.investorid === id) {
                    return { ...row, [key]: newValue };
                }
                return row;
            });
        });
    }
    };
    
    
    
    
    
    
    
    // Format percentage to two decimal places on blur
    const handleBlur = (id, value, key,row) => {
        setRowChanges(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id], // Preserve previous changes for this row
                [key]: value,
            }
        }));
        handleUpdateChange(row);
        // Format the percentage value before updating the data
        setDistributionTableData((prevData) => {
            return prevData.map((row) => {
                if (row.investorid === id) {
                    const formattedValue = parseFloat(value || 0).toFixed(2); // Ensure two decimal places
    
                    return {
                        ...row,
                        [key]: formattedValue,
                    };
                }
                return row;
            });
        });
    };
    
    
    const handleRowBlur = (id, key) => {
        setDistributionTableData((prevData) =>
            prevData.map((row) =>
                row.investorid === id
                    ? {
                          ...row,
                          [key]:
                              isNaN(parseFloat(row[key])) || row[key]?.trim() === ""
                                  ? 0 // Default to 0 if invalid or empty
                                  : parseFloat(row[key]).toFixed(2), // Format valid input to 2 decimals
                      }
                    : row
            )
        );
    };
    
    

    const handleUpdateChange = (row) => {
        
        console.log("Updating row:", row);

        //if(changed === "percentage"){
            const updatedData = {
                // ...row,
                 percentage: parseFloat(row?.percentage).toFixed(2) || 0, // Default to 0 if invalid
                 investorid: [parseFloat(row?.investorid)] || [0], // Default to 0 in array if invalid
                 disbursementamount: parseFloat(row?.disbursementamount) || 0, // Default to 0 if invalid
                 startDate: distributionData?.startDate,
                 endDate: distributionData?.endDate,
                 disbursementDate: distributionData?.disbursementDate,
                 fundname : row?.fundname
             };
     
            
     
             // const updatedData = {
             //     investorid: [parseFloat(row.investorid)] || [0], // Always include investorid
             //     ...(changed === "percentage" && { percentage: parseFloat(row.percentage) || 0 }),
             //     startDate: distributionData?.startDate,
             //     endDate: distributionData?.endDate,
             //     disbursementDate: distributionData?.disbursementDate,
             //     fundname: row.fundname,
             // };
     
             console.log("Payload sent to API:", updatedData);
              // Dispatch loader before API call
            dispatch(showLoader());
     
         //     //Send data to API
             fundDistribution(updatedData)
                 .then((response) => {
                     if (response?.result) {
                         // Update state with the result from the API
                         const updatedRow = response?.result[0]; // Assume the API returns an updated row
                         // setDistributionTableData((prevData) =>
                         //     prevData.map((row) =>
                         //         row.investorid === updatedRow?.investorid
                         //             ? {
                         //                 ...row,
                         //                 percentage:
                         //                     isNaN(parseFloat(updatedRow?.percentage)) ||
                         //                     (typeof updatedRow?.percentage === "string" && updatedRow?.percentage.trim() === "") // Ensure it's a string before trimming
                         //                         ? 0
                         //                         : parseFloat(updatedRow?.percentage),
                         //             }
                         //             : row
                         //     )
                         // );
                        //  setPopupOpen(true);
                        console.log("updatedRow",updatedRow);
                         setDistributionTableData((prevData) =>
                             prevData.map((row) =>
                                 row.investorid === updatedRow?.investorid
                                     ? {
                                         ...row,
                                         ...updatedRow,
                                         disbursementamount: updatedRow?.disbursementamount
                                             ? parseFloat(updatedRow.disbursementamount).toFixed(2)
                                             : 0
                                     }
                                     : row
                             )
                         );
                            // setMessage("Saved Successfully");
                            // Remove the saved investorid from rowChanges
                            setRowChanges((prevRowChanges) => {
                                const updatedRowChanges = { ...prevRowChanges };
                                delete updatedRowChanges[row?.investorid];
                                return updatedRowChanges;
                            });
                         
                         console.log("API response result:", response?.result);
                     }
                 })
                 .catch((err) => {
                     console.error("Error during API call:", err);
                 })
                 .finally(() => {
                     // Hide loader after API call completes
                     dispatch(hideLoader());
                 });
        //}
       
        
        
    };
    
    
   
    
    const handlePayDistribution = () => {
        // Extract investor ids from distributionData and map them to an array of ids
        let investorIds = [];
        let updatedData = {};
        
        const fundIds = distributionData?.funds?.map(item => item.uuid);

        console.log("distributionTableData",distributionTableData);

        const updatedDataArray = distributionTableData?.map(item => ({
            ...item,
            percentage : parseFloat(item?.percentage) || 0,
        })) || [];
        let apiData = [ ...updatedDataArray ];
        const numericInputs = ['disbursementamount','withdrawamount','percentage'];
        apiData = apiData.map(item => {
            numericInputs.forEach(key => {
                if (item.hasOwnProperty(key) && item[key] !== "" && item[key] !== null) {
                    item[key] = parseFloat(item[key]) || 0;
                }
            });
            return item;
        });
        console.log("updatedDataArray",apiData);
        updatedData = {
            data : apiData,
            ...distributionData,
            percentage : parseFloat(distributionData?.percentage) || 0,
            funds : fundIds,
           
           
        }
        if(distributionData?.investorid){
            investorIds = distributionData?.investorid?.map(item => parseInt(item.investorid));
            updatedData.investorid = investorIds;
        }
        console.log("final pay distribution data",updatedData);
        console.log("row changed data",rowChanges);
        const hasRowData = Object.keys(rowChanges)?.length !== 0 ;
        // if(hasRowData){
        //     dispatch(showLoader());
        //     setPopupOpen(true);
        //     setMessage("You have unsaved changes in the table. Please save them.");
        //     dispatch(hideLoader());
        // }else{
            dispatch(showLoader());
            payDistribution(updatedData)
                .then(function(response) {
                
                    if (response?.result) {
                        //setDistributionTableData(response?.result)
                        console.log("payDistribution",response?.result);
                        setPopupOpen(true);
                        setMessage('Data saved successfully!');
                        dispatch(hideLoader());
                    }
                        dispatch(hideLoader())
                    
                })
                .catch((err) => {
                    console.error("error", err); 
                    dispatch(hideLoader());
                })
        //}
       
            
    }

    const handleBack = () =>{
        setShow(!show);
        setDistributionTableOldData([]);
        setDistributionTableData([]);
        //setIsEqual(!isEqual);
    }

    const closePopup = () => {
        if(message === "Data saved successfully!"){
            setDistributionData((prevData) => ({
                ...prevData,
                funds: defaultFund || [],
                investorid : [],
                startDate : "",
                endDate : "",
                disbursementDate : "",
                actualRate : false,
                percentage : ''
            }));
            setInvestorFundDropdown([...defaultInvestorDropdown]);
            // getFundsName();
            setShow(!show);
        }
        setPopupOpen(false);
        
        //getLoanModificationDetails();
    };

    const getMatchingData = (oldDataArray, newDataArray) => {
        return oldDataArray?.find(
            (item) => String(item.investorid) === String(newDataArray.investorid)
        ) || null;
    };
    
    // const areEqual = (oldDataArray, newDataArray) => {
    //     const keysToCompare = ["percentage", "disbursementamount"];
        
       
    //     // Ensure both arrays have the same length and matching values for the keys
    //     return oldDataArray.every((oldItem) => {
    //         const newItem = newDataArray.find(
    //             (newItem) => String(newItem.investorid) === String(oldItem.investorid)
    //         );
    //         // If no matching new item found or keys don't match, return false
    //         return (
    //             newItem &&
    //             keysToCompare.every((key) => parseFloat(oldItem[key]) === parseFloat(newItem[key]))
    //         );
    //     });
    // };

    const areEqual = (oldDataArray, newDataArray) => {
        const keysToCompare = ["percentage", "disbursementamount"];
        
       
        // Ensure both arrays have the same length and matching values for the keys
        return oldDataArray.every((oldItem) => {
            const newItem = newDataArray.find(
                (newItem) => String(newItem.investorid) === String(oldItem.investorid)
            );
            // // If no matching new item found or keys don't match, return false
            // return (
            //     newItem &&
            //     keysToCompare.every((key) => parseFloat(oldItem[key]) === parseFloat(newItem[key]))
            // );
            // Compare the specified keys for equality
            return keysToCompare.every((key) => {
                
                return newItem && parseFloat(oldItem[key]) === parseFloat(newItem[key]);
            });
            
        });
    };

    const callEqual = () => {
        console.log("ar equal called -------");
        console.log("old",distributionTableOldData);
        console.log("new",distributionTableData);
        console.log('row changes',rowChanges);
        const dataEqual = areEqual(distributionTableOldData, distributionTableData);
        // Set the state based on whether the data is equal or not
        setIsEqual(!dataEqual); 
    }
    
    useEffect(() => {
        //console.log("ar equal called -------");
        //console.log("old",distributionTableOldData);
        //console.log("new",distributionTableData)
        // Check if the current and previous data arrays are the same
        //const dataEqual = areEqual(distributionTableOldData, distributionTableData);
        // Set the state based on whether the data is equal or not
        //setIsEqual(!dataEqual); // If they are equal, set isEqual to false to disable the button
        callEqual();
    }, [distributionTableData]); // Run when distributionTableData changes
    
        
  
    return (
        <div id="layout-wrapper">
        {loading ? <Loader/> : null} 
        {isPopupOpen && (
                <PopupMessage
                    type={message === "Either New Rate or New Date are required." || 
                            message === "You have unsaved changes in the table. Please save them."
                        ? 'warning' : 'success'}
                    message={message}
                    onClose={closePopup}
                />
            )}

          <MainHeader />
          <SideMenu />

            <div className="main-content">

            <div className="page-content user_management fund_summary investor_statement detailed_investor">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Generate Fund Distribution</h4>
                      
                            </div>
                            <div className="card" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                                    <div className="card-body my_account">
                                        {!show && <div className='form_section mb_20'>
                                            <div className='row'>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label>Investment Fund</label>
                                                        <Select
                                                            inputId="fundname"
                                                            isMulti
                                                            name="funds"
                                                            value={distributionData?.funds}
                                                            onChange={(fundsDropdown) => handleMultiSelect(fundsDropdown, 'funds')} // Pass name manually
                                                            options={fundsDropdown}
                                                            className={`${distributionErrors?.funds && 'invalid-input'}`}
                                                            onFocus={(e) => 
                                                            setDistributionErrors((prevErrors) => ({
                                                                ...prevErrors,
                                                                ['funds'] : null
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                              
                                                {/* <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Start Date</label>
                                                        <input type="date" name="startDate" placeholder="mm-dd-yyy" class="form-control " onChange={handleDates}
                                                        value={distributionData?.startDate} 
                                                        max={distributionData?.endDate || undefined} 
                                                        className={`form-control ${distributionErrors?.startDate && 'invalid-input'}`}
                                                        onFocus={(e) => handleFocus(e)}/>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>End Date (Until Date: 10.30.2024):</label>
                                                        <input type="date" name="endDate" placeholder="mm-dd-yyy" class="form-control " 
                                                            value={distributionData?.endDate} onChange={handleDates}
                                                            min={distributionData?.startDate || undefined}
                                                            className={`form-control ${distributionErrors?.endDate && 'invalid-input'}`}
                                                            onFocus={(e) => handleFocus(e)}
                                                            />
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label>Apply Disbursment Date:</label>
                                                        <input type="date" name="disbursementDate" placeholder="mm-dd-yyy" class="form-control " 
                                                            onChange={handleDates} value={distributionData?.disbursementDate} 
                                                            className={`form-control ${distributionErrors?.disbursementDate && 'invalid-input'}`}
                                                            onFocus={(e) => handleFocus(e)}/>
                                                    </div>
                                                </div> */}
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label>Start Date</label>
                                                        <input
                                                            type="date"
                                                            name="startDate"
                                                            placeholder="mm-dd-yyyy"
                                                            //max={moment().format('YYYY-MM-DD')}
                                                            value={distributionData?.startDate || ''}
                                                            className={`form-control ${distributionErrors?.startDate && 'invalid-input'}`}
                                                            onFocus={handleFocus}
                                                            onChange={handleDates}
                                                            id="distribution-startdate"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label>End Date </label>
                                                        <input
                                                            id="distribution-enddate"
                                                            type="date"
                                                            name="endDate"
                                                            placeholder="mm-dd-yyyy"
                                                            //min={distributionData.startDate ? moment(distributionData.startDate).add(1, 'days').format('YYYY-MM-DD') : ''}
                                                            //max={moment().format('YYYY-MM-DD')}
                                                            value={distributionData?.endDate || ''}
                                                            className={`form-control ${distributionErrors?.endDate && 'invalid-input'}`}
                                                            onFocus={handleFocus}
                                                            onChange={handleDates}
                                                            min={distributionData?.startDate || ""} // Enable only dates greater than start date
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label>Apply Disbursement Date:</label>
                                                        <input
                                                            id="distribution-disbursementdate"
                                                            type="date"
                                                            name="disbursementDate"
                                                            placeholder="mm-dd-yyyy"
                                                            // min={
                                                            //     distributionData.startDate && distributionData.endDate
                                                            //         ? moment.max(
                                                            //             moment(distributionData.startDate).add(1, 'days'),
                                                            //             moment(distributionData.endDate).add(1, 'days')
                                                            //         ).format('YYYY-MM-DD')
                                                            //         : ''
                                                            // }
                                                            className={`form-control ${distributionErrors?.disbursementDate && 'invalid-input'}`}
                                                            onFocus={handleFocus}
                                                            onChange={handleDates}
                                                            value={distributionData?.disbursementDate}
                                                            // min={distributionData?.endDate || ""} // Enable only dates greater than end date
                                                        />
                                                    </div>
                                                </div>

                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label class="tool-form">Percentage
                                                            {/* (Leave this field blank to use the investors agreed upon percentage) */}
                                                            : <span class="what">? <span class="what-text">(Leave this field blank to use the investors agreed upon percentage)</span></span> </label>
                                                        <input type="text" class="form-control" name="percentage" placeholder="Enter the %" value={distributionData?.percentage}
                                                        // onInput={(e) => {
                                                        //     let value = e.target.value;
                                                        
                                                        //     // Restrict the value to 100
                                                        //     if (parseFloat(value) > 100) {
                                                        //       value = "100";
                                                        //     }
                                                        
                                                        //     // Allow only up to 2 decimal places
                                                        //     if (value.includes(".")) {
                                                        //       const [integer, decimal] = value.split(".");
                                                        //       if (decimal?.length > 2) {
                                                        //         value = `${integer}.${decimal.slice(0, 2)}`;
                                                        //       }
                                                        //     }
                                                        
                                                            // Update the state
                                                            // handleText({ target: { name: e.target.name, value } });
                                                            onChange={handleText}
                                                          id="distribution-percentage"/>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group sdf toggle mb-0  less_than" style={{ pointerEvents: 'auto', userSelect: 'auto', opacity: 1 }}>
                                                        <label class="toggle-label mx-0"><span class="label me-1">Select Investor</span>
                                                        <input name="selectAll" class="toggle-checkbox" checked={selectAllbox} onChange={selectAll} type="checkbox"/>
                                                            <div class="toggle-switch"></div><span class="label_text mx-1"> (Select All)</span>
                                                            </label>
                                                            <Select
                                                            inputId ='investor'
                                                            isMulti
                                                            name="investorid"
                                                            // className="w-100"
                                                            menuPlacement="bottom" // Forces dropdown to always open below
                                                            menuPosition="absolute" // Prevents it from being positioned relative to overflow-hidden containers
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    height: "auto",
                                                                    flexWrap: "wrap",
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    zIndex: 1050, // Ensures the dropdown appears above other elements
                                                                }),
                                                            }}
                                                            value={distributionData?.investorid}
                                                            onChange={(investorFundDropdown) => handleMultiSelect(investorFundDropdown, 'investorid')}
                                                            options={investorFundDropdown}
                                                            className={`${distributionErrors?.investorid && 'invalid-input'}`}
                                                            //onFocus={(e) => handleFocus(e)}
                                                        />
                                                            </div>
                                                            </div>
                                                {/* <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Select Investor</label>
                                                        <Select
                                                            inputId ='investor'
                                                            isMulti
                                                            name="investorid"
                                                            // className="w-100"
                                                            menuPlacement="bottom" // Forces dropdown to always open below
                                                            menuPosition="absolute" // Prevents it from being positioned relative to overflow-hidden containers
                                                            styles={{
                                                                control: (provided) => ({
                                                                    ...provided,
                                                                    height: "auto",
                                                                    flexWrap: "wrap",
                                                                }),
                                                                menu: (provided) => ({
                                                                    ...provided,
                                                                    zIndex: 1050, // Ensures the dropdown appears above other elements
                                                                }),
                                                            }}
                                                            value={distributionData?.investorid}
                                                            onChange={(investorFundDropdown) => handleMultiSelect(investorFundDropdown, 'investorid')}
                                                            options={investorFundDropdown}
                                                            className={`${distributionErrors?.investorid && 'invalid-input'}`}
                                                            //onFocus={(e) => handleFocus(e)}
                                                        />
                                                    </div>
                                                </div> */}
                                                {/* <div className='col-md-3'></div>
                                                <div className='col-md-3'>
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" name="actualRate" checked={distributionData?.actualRate} onChange={handleCheckBoxes}/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">Daily Rate to Actual</span>
                                                    </label>
                                                </div> */}
                                            </div>
                                            <div class="btn-group mt_10">
                                                <button class="fs_btn mr_10" id="calculate" onClick={handleCalculate}> Calculate Distributions</button>
                                            </div>
                                        </div>}

                                        {show && <div className='funddistribution_summary'>
                                            <div class="search">
                                                <button id="payDistribution" type="button" class="create_btn" style={{ 'opacity': distributionTableData?.length == 0 ? '0.6' : '1', }} disabled={distributionTableData?.length == 0} onClick={handlePayDistribution}>Pay Distributions</button>
                                                <a href="#" id="back-btn" class="back_btn" onClick={(e) => {e.preventDefault();handleBack()}}><span class="material-icons icon"> chevron_left</span>GO BACK</a></div>
                                                <DataTable 
                                                    className='termsheetprefills_table funds_dis_table'
                                                    columns={columns}
                                                    data={distributionTableData}
                                                    pagination
                                                    paginationServer
                                                    // paginationTotalRows={(!searchTerm) ? totalcount : filteredFundData?.length}
                                                    paginationTotalRows={totalcount}
                                                    customStyles={customStyles}
                                                    paginationPerPage={perPage}
                                                    paginationRowsPerPageOptions={[15, 30, 45, 60,75,90]}
                                                    paginationComponentOptions={{
                                                        rowsPerPageText: 'Per Page',
                                                    }}
                                                    persistTableHead
                                                    onChangePage={handlePageChange}
                                                    onChangeRowsPerPage={handlePerPageChange}
                                                    id="prefillPagination"
                                                        // sortServer={false}  // If you're handling sorting on the server-side, use this prop
                                                        // onSort={handleSort}
                                                /> 
                                        </div>}
                                    </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AdminFundDistribution;