import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate,useLocation } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { getallFees } from "../utils/reducer";
import { useLoanInfo } from '../utils/loanInfoContext';
import { getWireInstructions,addWireInstructions,updateFee ,getStates} from '../utils/reducer'; 
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from './Loader';
import Select from 'react-select';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
import { formatCurrency } from '../utils/commonUtils.js';


const Funding = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { loanInfo } = useLoanInfo();
    console.log('loanInfoloanInfo',loanInfo);
    const loading = useSelector((state) => state.loaderVisible);
    const [popupMessage, setmessage] = useState('Saved Successfully');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const dispatch = useDispatch();
    const loanData = loanInfo;
    const [errors,SetErrors] = useState({});
    const [updateData,setUpdateData] = useState({});
    const [showConfirmation,setShowConfirmation] = useState(false);
    const [states, setStates] = useState([]);
    const [totalFee, setTotalFee] = useState(0);
    let userData = getUserDataFromLocalStorage();
    // console.log("userData",userData)
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const stateOptions = states?.map((state) => ({
        value: state,
        label: state,
    }));
    const accountType = [{
        value : 'Checking',
        label : 'Checking'
    },{
        value : 'Saving',
        label : 'Saving'
    }
     ]


    let jsonFormat = {
        wireinstructions : {
            estimatedfundingdate: "",
            actualwire_funddate: "",
            // includeinfundingtotal: "",
            bankname : "",
            bankcity : "",
            bankstate : "",
            bankzipcode : "",
            bankaccountname : "",
            bankaccountnumber : "",
            routingnumber : "",
            bankaddress : "",
            accounttype : "",
        }
    };
    const [wireInstructions,setWireInstructions] = useState({ ...jsonFormat})
    const [dataCheck,setDataCheck] = useState({...jsonFormat});
    const [isChange,setIsChange] = useState(false);
    const [fees, setFees] = useState([]);
    useEffect(() => {
        
        getstates();
    }, []);
    useEffect(() => {
        if(loanInfo?.loanid){
            getWiringData();
            fetchFees();
            }
    }, [loanInfo?.loanid]);
    const getstates = async () => {
        getStates().then(function (response) {
            if (response?.result) {
               setStates(response?.result)
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.pipelinePermissions
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);
    const getWiringData = ()=>{
        dispatch(showLoader());
        getWireInstructions(loanInfo?.loanid)
        .then(function(response) {
            if (response?.result?.length!==0) {
                setWireInstructions(prev => ({...prev, ...response?.result?.[0]}));
                setDataCheck(prev => ({...prev, ...response?.result?.[0]}));
                dispatch(hideLoader());
                SetErrors({})
            }else{
                setWireInstructions({ ...jsonFormat});
                setDataCheck({ ...jsonFormat});
                dispatch(hideLoader());
                SetErrors({})
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const addApiCall =()=>{
        dispatch(showLoader());
        let apiData = {...wireInstructions, loanid : loanInfo?.loanid}
        // const numericInputs = ['includeinfundingtotal'];
        // apiData?.wireinstructions?.['includeinfundingtotal'] = parseFloat(apiData?.wireinstructions?.['includeinfundingtotal']);
        // numericInputs.forEach(fieldName => {
        //     if (apiData?.wireinstructions?.hasOwnProperty(fieldName)) {
        //         apiData.wireinstructions[fieldName] = parseFloat(apiData.wireinstructions[fieldName]);
        //     }
        // });
    
        console.log(apiData);
        addWireInstructions(apiData)
        .then(function(response) {
            if (response?.result) {
                console.log('add closing --- > ', response?.result);
                setPopupOpen(true);
                getWiringData();
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    useEffect(() => {
        const isFormChanged =
            JSON.stringify(wireInstructions) !== JSON.stringify(dataCheck);
            setIsChange(isFormChanged);
        console.log(isFormChanged);
    }, [wireInstructions, dataCheck]);
    const handleCheckbox =(e,data)=>{
        setUpdateData({
            ...data,
            "fundingtotal" : e.target.checked
        });
        let apiData = {...data,"fundingtotal" : e.target.checked,loanid : loanInfo?.loanid};
        delete apiData['feename']
        // setShowConfirmation(true)
        updateFunding(apiData);
    }
    const updateFunding = (apiData)=>{
        setShowConfirmation(false);
        dispatch(showLoader());
        // let apiData = {...updateData,loanid : loanid}
        updateFee(apiData)
        .then(function(response) {
            if (response) {
                setmessage('Saved Successfully');
                // setPopupOpen(true);
                fetchFees();
                // dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const handleSave = () => {
        let fieldsToCheck = {'bankname': 'bankname',
        // 'bankaddress':'bankaddress',
    // 'bankcity': 'bankcity' ,
    'bankstate': 'bankstate',
    // 'bankzipcode': 'bankzipcode',
    'bankaccountname': 'bankaccountname',
'bankaccountnumber' : 'bankaccountnumber','accounttype' : 'accounttype','routingnumber' : 'routingnumber'};
        let errorsCheck = {};
        // inputFields.forEach(field => {
        //     fieldsToCheck[field.name] = field.name;
        // });
        
        console.log(fieldsToCheck);
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            const fieldValue = wireInstructions?.wireinstructions?.[fieldName];
            if (typeof fieldValue === 'string' && !fieldValue.trim()) {
                errorsCheck[fieldName] = `Please Enter ${fieldLabel} `;
            } else if (typeof fieldValue === 'number') {
                const stringValue = fieldValue.toString();
                if (!stringValue.trim()) {
                    errorsCheck[fieldName] = `Please Enter a valid ${fieldLabel} `;
                }
            }
        };
        if(wireInstructions?.wireinstructions?.routingnumber){
            const stringValue = wireInstructions?.wireinstructions?.routingnumber.toString();
            if(stringValue?.length < 9){
                errorsCheck['routingnumber'] = `Number should be 9 digit`
            }
        }
        console.log(wireInstructions?.wireinstructions)
        console.log("errorsCheck",errorsCheck);
        SetErrors(errorsCheck);
        if(Object.keys(errorsCheck).length === 0){
            addApiCall();
        }
    };

    const handleChange=(e)=>{
        let {name,value,type} = e.target;
        let updatedValue = value;
        setWireInstructions((prev) => ({
                ...prev,
                wireinstructions : {
                    ...prev?.wireinstructions,
                    [name] : updatedValue,
                }
            }));
        
    };
    const handleCurrencyInputsLoan = (name,value)=>{
        console.log(value)
        let updatedValue = isNaN(value) ? '' : (value);
        setWireInstructions((prev) => ({
            ...prev,
            wireinstructions : {
                ...prev?.wireinstructions,
                [name] : updatedValue,
            }
        }));
    }
    const handleStateChange = (name,value)=>{
        setWireInstructions((prev) => ({
            ...prev,
            wireinstructions : {
                ...prev?.wireinstructions,
                [name] : value?.value,
            }
        }));
    }
    const handleKeyDown = (event) => {
        if (event.key === '.') {
          event.preventDefault();
        }
      };
       
    const inputFields = [
        {label : "Bank Name", type : 'text', name : 'bankname', placeholder : 'Enter Bank Name'},
        {label : "Bank Address", type : 'text', name : 'bankaddress', placeholder : 'Enter Bank Address'},
        {label : "Bank City", type : 'text', name : 'bankcity', placeholder : 'Enter Bank City'},
        {label : "Bank State", type : 'dropdown', options : stateOptions, name : 'bankstate', placeholder : 'Enter Bank State'},
        {label : "Bank Zip Code", type : 'number', name : 'bankzipcode', placeholder : 'Enter Bank Zip Code'},
        {label : "Account Name", type : 'text', name : 'bankaccountname', placeholder : 'Enter Bank Account Name'},
        {label : "Account Number", type : 'number', name : 'bankaccountnumber', placeholder : 'Enter Bank Account Number'},
        {label : "Account type", type : 'dropdown', options : accountType, name : 'accounttype', placeholder : 'Enter Account Type'},
        {label : "Routing Number", type : 'number', name : 'routingnumber', placeholder : 'Enter Routing Number'},
        // { label: "Estimated Funding Date", type : 'date',name: "estimatedfundingdate"},
        { label: "Actual Wire/Fund Date" , type : 'date', name: "actualwire_funddate" },
        // { label: "Include in Funding Total" , type : 'currency', name: "includeinfundingtotal", },
    ];
    const handleFocus = (e) => {
        const {name} = e.target;
        SetErrors({...errors,[name] : ""});
    };

    const fetchFees = () => {
        dispatch(showLoader());
        getallFees(loanInfo?.loanid).then(function (response) {
            if (response?.result) {
                setFees(response?.result);
                const fees = response.result;
                let totalFee = 0;
                fees.forEach(fee => {
                    if(fee?.fundingtotal){
                    if (fee?.feein === 'Dollar') {
                        totalFee += fee?.fee;
                    } else if (fee?.feein === 'Percent') {
                        totalFee += fee.amount;
                    }}
                });
                setTotalFee(totalFee);
                console.log('total feeeeeee----->',totalFee)
                dispatch(hideLoader());
            }
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    };

    const closePopup = () => {
        setPopupOpen(false);
        setShowConfirmation(false);
    };

    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader/> : null} 
            {isPopupOpen && (
                <PopupMessage
                type={'success'}
                message={popupMessage}
                onClose={closePopup}
                />
            )}
            {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={updateFunding}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'update-funding-yes',
                        className: 'btn btn-primary',
                        onClick: updateFunding,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'update-funding-no',
                        className: 'btn btn-light',
                        onClick: closePopup,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                    <p>Do you really want to include this fee in funding total?</p>
            </SweetAlert>}
        <PipelineHeader />
        <div className="dashboard_block">
        <PipelineStatus />
        <h2 className="form_title">Closing and Funding <span className="space">&#10095;</span> <span className="sub">Wire Instructions</span> </h2>
            <div className="card user_management wi">
                <div className="card-body my_account ">
                    <div className="package">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Loan Amount</label>
                                    <p> {loanData?.loanamount ? formatCurrency(loanData?.loanamount) : '$0.00'}</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Holdback</label>
                                    <p> {loanData?.rehabfunding ? ('$'+loanData?.rehabfunding.toLocaleString('en-US')): '$0.00'}</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Total Fees</label>
                                    <p>{totalFee ? ('$'+totalFee.toLocaleString('en-US')): '$0.00'}</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Interest Reserve Amount</label>
                                    <p>{(totalFee && loanData?.interestreserveamount) ? ('$'+loanData?.interestreserveamount?.toLocaleString('en-US')): '$0.00'}</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Funding Total</label>
                                    <p>
                                        {
                                            isNaN((loanData.loanamount ?? 0) - (loanData.rehabfunding ?? 0)  - totalFee - loanData?.interestreserveamount)
                                            ? '$0'
                                            : formatCurrency((loanData.loanamount ?? 0) - (loanData.rehabfunding ?? 0)  - totalFee - loanData?.interestreserveamount)
                                        }
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-2">
                                <div className="form-group">
                                    <label>Purchase Price</label>
                                    <p> {loanData?.propertyinfo?.[0]?.purchaseprice ? formatCurrency(loanData?.propertyinfo?.[0]?.purchaseprice) : '$0.00'}</p>
                                    
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                         

                        </div>
                    </div>
                    <div className="form_section" style={{
                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
              }}>
                        <hr className="hr" />
                        <div className="row">
    {inputFields.map((field, index) => (
        <div key={index} className="col-md-3">
            <div className="form-group">
                <label>{field.label}</label>
                {field.type === 'currency' ? (
                    <CurrencyInput
                        id={`input-${index}`} 
                        name={field.name}
                        decimalsLimit={2}
                        className={`form-control ${errors[field.name] && 'invalid-input'}`}
                        onFocus={handleFocus}
                        placeholder="$0.00"
                        prefix="$"
                        groupSeparator=","
                        value={wireInstructions?.wireinstructions?.[field.name]}
                        onValueChange={(value, name, values) => {
                            console.log(values);
                            handleCurrencyInputsLoan(name, value);
                        }}
                    />
                ) : field.type === 'date' ? (
                    <input
                        type="date"
                        name={field.name}
                        className={`form-control ${errors[field.name] && 'invalid-input'}`}
                        onChange={handleChange}
                        value={wireInstructions?.wireinstructions?.[field.name] ? (new Date(wireInstructions?.wireinstructions?.[field.name]).toISOString().split('T')[0]) : ''}
                        onFocus={handleFocus}
                        // min={new Date().toISOString().split('T')[0]}
                        id={field?.name}
                    />
                ) : field.type === 'dropdown' ? (
                    <Select
                        name={field.name}
                        options={field.options}
                        className={`react-select-container ${errors[field.name] && 'invalid-input'}`}
                        classNamePrefix="react-select"
                        value={field?.options.find(option => option.value === wireInstructions?.wireinstructions?.[field.name])}
                        onFocus={()=>{SetErrors({...errors,[field.name] : ""})}}
                        onChange={(selectedOption) => handleStateChange(field.name, selectedOption)}
                        inputId ={field?.name}
                    />
                ) : field.type === 'number' ? (
                    <input
                        type="number"
                        name={field.name}
                        className={`form-control ${errors[field.name] && 'invalid-input'}`}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onInput={ (e) => { 
                            if(field?.name === 'routingnumber'){
                                e.target.value = e.target.value.slice(0, 9)
                            }else if(field?.name === 'bankzipcode'){
                                e.target.value = e.target.value.slice(0, 6) 
                            }
                             }}
                        value={wireInstructions?.wireinstructions?.[field.name] || ''}
                        onFocus={handleFocus}
                        placeholder={field.placeholder}
                        id={field?.name}
                    />
                ) :
                
                (
                    // Default to text input
                    <input
                        type="text"
                        name={field.name}
                        className={`form-control ${errors[field.name] && 'invalid-input'}`}
                        onChange={handleChange}
                        value={wireInstructions?.wireinstructions?.[field.name] || ''}
                        onFocus={handleFocus}
                        placeholder={field.placeholder}
                        id={field?.name}
                    />
                )}
            </div>
        </div>
    ))}
</div>


                        <div className="btn-group">
                            <button id="saveWireInstructions" className="fs_btn" disabled={!isChange} style={{'opacity' : isChange ? 1 : 0.6}} onClick={(e)=>{handleSave();}}>Save</button>
                        </div>
                        
                    </div>
                    <div className="table-responsive mt_30" style={{
                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
              }}>
                        <table className="table db_table">

                            <thead className="table-light">
                                <tr>
                                    <th width="21%">Fee Name</th>
                                    <th width="21%">Fee Type</th>
                                    <th width="20%" className="text-center">Fee Amount</th>
                                    <th width="8%">Include in Funding Total</th>
                                </tr>
                            </thead>
                            <tbody>
                            {fees.map((data,index)=>(
                               <tr key={index}>
                               <td>{data?.feename}</td>
                               <td>{data?.feetype}</td>
                               <td className="text-center"> {data?.feein === 'Dollar' ? data?.fee?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) :
                                    data?.amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                </td>
                               <td className="justify-content-center d-flex"><label className="toggle mb-0">
                                       <input id={`fundTotal-${index}`} onChange={(e)=>{handleCheckbox(e,data)}} className="toggle-checkbox" checked={data?.fundingtotal ? true : false} type="checkbox" />
                                       <div className="toggle-switch"></div>

                                   </label></td>
                           </tr>))}
                                
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
        <PipelineFooter />
    </div>
    );
};

export default Funding;