import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import Select from 'react-select';
import { useLocation, useNavigate} from 'react-router-dom';
import { getNeedGroups, addDefaultNeed, Appname, APIUrl} from "../utils/reducer";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import { getUserDataFromLocalStorage } from '../utils/local.js';

const AddDefaultNeed = () => {
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const loading = useSelector((state) => state.loaderVisible); 
    const navigate = useNavigate();
    const location = useLocation();
    const configdata = useSelector(state => state.configdata);
    const data = location.state?.type;
    const defaultNeed = location.state?.defaultNeed;
    const mode = location.state?.mode;
    const [errors , setErrors] = useState({});
    const [unique_id, setunique_id] = useState(uuidv4());
    var  ObjectID2= require("bson-objectid");
    var uniqueId = new ObjectID2().toString(); 
    let userData = getUserDataFromLocalStorage();
    const [needButton , setNeedButton] = useState(false);
    const needOldData= location.state?.defaultNeed;
    const [defaultNeedGroup, setDefaultNeedGroup] = useState(
        defaultNeed ? { ...defaultNeed, type: 'default' ,mode : 'update' } : {createdby : userData?.firstname + " " + userData?.lastname, createdbyid : userData?.userId,type: 'default', needid: uniqueId ,'needgroups': [],'needdescription' : '','needtype' : '', 'internaldescription' : '','documentname' : '',checklist : []}
      );
      const [popupMessage, setmessage] = useState(defaultNeed ? 'Default Need Updated' : 'Default Need Added');
    const [groupDropDownOptions , setgroupDropDownOptions] = useState([
        { value: 'Items Needed From Borrower', label: 'Items Needed From Borrower' },
        { value: 'Term Sheet Issued', label: 'Term Sheet Issued' },
        { value: 'Processing', label: 'Processing' },
        { value: 'Processing - Rehab', label: 'Processing - Rehab' },
        { value: 'Processing - New Construction', label: 'Processing - New Construction' },
        { value: 'Processing - Refinance', label: 'Processing - Refinance' },
        { value: 'CA Lead', label: 'CA Lead' },
        { value: 'ServiceLink Needs Group', label: 'ServiceLink Needs Group' },
        { value: 'Florida', label: 'Florida' },
        { value: 'TitleEQ Needs Group', label: 'TitleEQ Needs Group' },
      ]);

    const needtypeOptions = [
        { value: '', label: 'Please Select' },
        { value: 'Financial', label: 'Financial' },
        { value: 'Property', label: 'Property' },
        { value: 'Market', label: 'Market' },
        { value: 'Borrower', label: 'Borrower' },
        { value: 'Summary', label: 'Summary' },
      ];

      useEffect(() => {
        let projection = 'groupneed'
          getNeedGroups(projection).then(function (response) {
         
            if (response?.result) {
                const dropdownOptions = response?.result?.map(item => ({
                    value: item?.needid,
                    label: item?.groupName
                  }));
                  
                  console.log(dropdownOptions);
                setgroupDropDownOptions(dropdownOptions)
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
      }, []);

        const allowedKeys = [ 'needdescription', 'needOrder', 'documentname', 'internaldescription', 'needtype', 'photoid', 'internalNeed','needgroups'];
        function compareNeeds() {
            return allowedKeys.every(key => {
                if (!Array.isArray(key) && needOldData?.hasOwnProperty(key) && defaultNeedGroup?.hasOwnProperty(key)) {
                    return needOldData[key] === defaultNeedGroup[key];
                }else if(!Array.isArray(key) && !needOldData?.hasOwnProperty(key) && (defaultNeedGroup?.hasOwnProperty(key) && defaultNeedGroup[key] !== "")){
                    return false;
                }
                // else if(Array.isArray(key) && needOldData.hasOwnProperty(key) && defaultNeedGroup.hasOwnProperty(key) && defaultNeedGroup[key] && needOldData[key]){
                //     return needOldData.every((element, index) => element === defaultNeedGroup[index]);
                // }
                else if(Array.isArray(key) && needOldData?.hasOwnProperty(key) && defaultNeedGroup?.hasOwnProperty(key) && defaultNeedGroup[key] && needOldData[key]){
                    // return needOldData.every((element, index) => element === defaultNeedGroup[index]);
                    // if(needOldData[key].length !== defaultNeedGroup[key].length){
                    //     return true;
                    // }
                    if(needOldData[key].length === defaultNeedGroup[key].length){
                        return defaultNeedGroup.every((element, index) => element === needOldData[index]);
                    }
                    
                }
                // If key does not exist in both objects, return true to continue the loop
                return true;
            });
        }

      useEffect(() => {
            console.log("need old",needOldData);
            console.log("need new",defaultNeedGroup);
            const equalNeed = compareNeeds();
            console.log(equalNeed)
          if (needOldData?.imgFile !== defaultNeedGroup?.imgFile) {
              setNeedButton(false);
          } else {
              setNeedButton(equalNeed);
          }
      },[defaultNeedGroup])

    const handleSelectChange =(selectedOptions) => {
        setDefaultNeedGroup({...defaultNeedGroup, needgroups: selectedOptions.map(option => option.value) });
      };

      const handleChange = (e) => {
        setDefaultNeedGroup((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCheckBox = (e) =>{
        console.log(e.target.checked);
        setDefaultNeedGroup({...defaultNeedGroup,internalNeed: e.target.checked });
    }

    const closePopup = () => {
        setPopupOpen(false);
        navigate('/needslist', { state: { from: 'adddefaultneed' } })
    };

    const handleFileChange =(e)=>{
        console.log('rannnnnnn');
        const file = e.target.files[0];
        const timestamp = Date.now();
        var bucket;
        bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
        let format = file?.name?.split(".");
        let fileName = file?.name
        var fileformat = format[format.length - 1];
        const imgUrl = URL.createObjectURL(file);
        setDefaultNeedGroup({...defaultNeedGroup, imgFile : {'imgurl' : imgUrl,'imgFileData' :  file}})
        let category = 'defaultNeedImage';
        console.log('datadacategory',category);
        var  ObjectID2= require("bson-objectid");
        var uniqueId = new ObjectID2().toString(); 
        let uploadFilePath = Appname + "/" + defaultNeedGroup?.needid + "/"+ category + "/" + timestamp + "." + fileformat;
        console.log('datadauploadFilePath',uploadFilePath);
        var data = { source_bucket: bucket, sourcepath: uploadFilePath }
        console.log('datadatadata',data);
        axios.post(APIUrl + 'uploadURL?appname=' + Appname, data, { type: 'application/json' })
        .then((response) => {
            console.log('ressss',response);
            if (response?.data && response?.data?.result) {
                console.log('ressss2',response.data);
                var url = response?.data?.result;
                console.log('ressss3',response.data.result);

                console.log("url", url);
                axios.put(url, file, {
                    "headers": {
                        "Content-Type": "multipart/form-data",
                        "Accept": "/",
                        "Cache-Control": "no-cache",
                        "Accept-Encoding": "gzip, deflate",
                        "Connection": "keep-alive",
                        "cache-control": "no-cache"
                    }
                })
                    .then((response) => {
                        if (response && response?.status === 200) {
                            console.log('responseherererer',uploadFilePath);
                      
                            setDefaultNeedGroup({...defaultNeedGroup,files : [{file : uploadFilePath , filecode : defaultNeedGroup?.needid, filename :fileName  }], imgFile : {'imgurl' : uploadFilePath}})
                            setNeedButton(false);
                        }
                    });
                }
            
        
        });
    };

    const handleSubmit =()=>{

        const error = {};
        const fieldsToCheck =   {'needtype' : 'needtype', "needgroups":'needgroups','documentname':'documentname',}
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            console.log('defaultNeedGroup',defaultNeedGroup, fieldName)
            if (typeof defaultNeedGroup?.[fieldName] === 'string' && !defaultNeedGroup?.[fieldName].trim()) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
              } else if (Array.isArray(defaultNeedGroup?.[fieldName]) && defaultNeedGroup?.[fieldName].length === 0) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
              }
        };

        if(!defaultNeedGroup?.needOrder){
            error['needOrder'] = 'needOrder'
        }
        // if(!defaultNeedGroup?.dueDateOffset){
        //     error['dueDateOffset'] = 'dueDateOffset'
        // }

        setErrors(error);

        if (Object.keys(error).length === 0) {
            if(data==='add'){
                console.log('defaultNeedGroup for add ', defaultNeedGroup)
            }else if(data==='update'){
                console.log('defaultNeedGroup for update ', defaultNeedGroup)
            }
        }else{
            return
        }
        dispatch(showLoader());
        let apiData = {...defaultNeedGroup, needstatus : "Outstanding" };
            delete apiData.created;

        addDefaultNeed(apiData).then(function (response) {
            dispatch(hideLoader());
            if (response?.result) {
                if(mode === 'edit'){
                    setmessage('Form Updated Successfully.')
                }
                setPopupOpen(true);
            }
            }).catch((err) => {
              dispatch(hideLoader());
              console.error.bind("my account error", err);
            })
    }

    const handleKeyDown = (event) => {
        if (event.key === '.') {
          event.preventDefault();
        }
      };
      const handleInput = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]*/g, '');
      };

     

    return (
        <div id="layout-wrapper">

{loading ? <Loader/> : null} 
        {isPopupOpen && (
            <PopupMessage
             type={popupMessage ==  'Please try with new reset link' ? 'warning' : 'success'}
             message = { mode === "edit" ? "Default Need Updated" : "Default Need Added" }
            //  message={popupMessage}
             onClose={closePopup}
            />
        )}
           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management add_default add-default-need">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">{mode === "edit" ? "Update" : "Add" } Default Need</h4>
                                <a href="#" onClick={(e) => {e.preventDefault(); navigate('/needslist', { state: { from: 'adddefaultneed' } })}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section">

                                <div className="row">

                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* Need Groups */}
                                            <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Need Groups</label>
                                                                <Select
                                                                    options={groupDropDownOptions}
                                                                    isMulti
                                                                    value={
                                                                        defaultNeedGroup?.needgroups?.map((value) => {
                                                                            const matchedOption = groupDropDownOptions.find(option => option.value === value);
                                                                            return matchedOption ? matchedOption : { value, label: value };
                                                                        }) || []
                                                                    }
                                                                    className={`w-100 ${errors.needgroups && 'invalid-input'}`}
                                                                    onFocus={() => { setErrors({ ...errors, needgroups: null }) }}
                                                                    id="selectNeedGroup"
                                                                    onChange={handleSelectChange}
                                                                    inputId='selectNeedGroups'
                                                                />

                                                                {/* <img src="assets/images/tem/tem.png" className="w-100" alt="" /> */}
                                                            </div>
                                            </div>

                                            {/* Document Tag */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Document Name</label>
                                                    <input type="text"
                                                    name='documentname'
                                                    className={`form-control ${errors.documentname && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, documentname: null }) }}
                                                    onChange={handleChange}
                                                    value={defaultNeedGroup?.documentname || ''}
                                                        placeholder="Please enter document name"
                                                         />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                           {/* Internal Description */}
                                            <div className="col-md-12">
                                                <div className="form-group text-area-style">
                                                    <label>Internal Description</label>
                                                    <textarea name='internaldescription'  onChange={handleChange} value={defaultNeedGroup?.internaldescription || ''} className={`form-control ${errors.internaldescription && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, internaldescription: null }) }} id="w3review"  rows="4" cols="50"
                                                        spellcheck="false"> </textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                             {/* Need Type */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Need Type</label>
                                                    <select inputId="selectneedtype" name='needtype' onChange={handleChange} value={defaultNeedGroup?.needtype || ''} className={`form-control ${errors.needtype && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, needtype: null }) }} placeholder="Please Select Option">
                                                        {needtypeOptions.map((data,index)=>(
                                                            <option value={data.value}>{data.label}</option>
                                                        ))}
                                                        
                                                    </select>
                                                </div>
                                            </div>

                                            {/* Need Order */}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Need Order</label>
                                                    <input name='needOrder' onKeyDown={handleKeyDown}
                                                                onInput={handleInput}  onChange={handleChange} value={defaultNeedGroup?.needOrder || ''} type="text" placeholder="Please enter need order" className={`form-control ${errors.needOrder && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, needOrder: null }) }}  />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            {/* Need Description */}
                                        <div className="col-md-12">
                                                <div className="form-group text-area-style">
                                                    <label>Need Description</label>
                                                    <textarea name='needdescription' onChange={handleChange} value={defaultNeedGroup?.needdescription || ''} className={`form-control ${errors.needdescription && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, needdescription: null }) }} id="w3review" rows="4" cols="50"
                                                         spellcheck="false"> </textarea>
                                                </div>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="row internal-need-center">
                                        <div className="col-md-6">
                                        {/* toggle */}
                                            <div className="col-md-3">
                                                <label className="toggle-label me-4">Internal Need</label>
                                                <label className="toggle mb_10">
                                                    <input checked={defaultNeedGroup?.internalNeed} onChange={(e)=>{handleCheckBox(e)}} className="toggle-checkbox" type="checkbox" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            {/* Upload a File */}
                                            {/* <div className="col-md-6">
                                                <div className="form-group mb-0">
                                                    <label>Upload a File</label>
                                                    <div className="uploads">
                                                        <div className="item">
                                                            <div className="left">
                                                                <img 
                                                                src={defaultNeedGroup?.files?.[0]?.file ? configdata?.common?.awsImgix+defaultNeedGroup?.files?.[0]?.file  : 'assets/images/icons/mode-portrait.svg'}
                                                                    className="img" />
                                                                <div>
                                                                    <h4>File Picture</h4>

                                                                </div>
                                                            </div>
                                                                    <label htmlFor="file-input" className="upload">
                                                    <span className="material-icons icon">file_upload</span>
                                                      Upload
                                                     </label>
                                                    <input type="file" accept="image/*,.heic" id="file-input" name={'photoid'}  onChange={handleFileChange} style={{ display: 'none' }} />
                                                   
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-md-6">
    <div className="form-group mb-0">
        <label>Upload a File</label>
        <div className="uploads">
            <div className="item">
                <div className="left">
                    {(() => {
                        const filePath = defaultNeedGroup?.files?.[0]?.file || "";
                        const isHeic = filePath.match(/\.(heic|HEIC)$/i);
                        const isImage = filePath.match(/\.(jpeg|jpg|gif|png|bmp)$/i);
                        const isPDF = filePath.match(/\.pdf$/i);
                        const isDoc = filePath.match(/\.(doc|docx)$/i);
                        const isVideo = filePath.match(/\.(mp4|mov|avi|wmv)$/i);
                        const isExcel = filePath.match(/\.(xls|xlsx)$/i);

                        let iconSrc = "assets/images/icons/mode-portrait.svg";

                        if (isImage) iconSrc = configdata?.common?.awsImgix + filePath;
                        else if (isPDF) iconSrc = "assets/images/icons/pdf_image.png";
                        else if (isDoc) iconSrc = "assets/images/icons/word_image.png";
                        else if (isVideo) iconSrc = "assets/images/icons/videoclip_image.png";
                        else if (isExcel) iconSrc = "assets/images/icons/excel_image.png";
                        else if (isHeic) iconSrc = "assets/images/icons/heic_image.png";

                        return (
                            <img src={iconSrc} className="img" alt="Uploaded file" />
                        );
                    })()}
                    <div>
                        <h4>File Picture</h4>
                    </div>
                </div>

                <label htmlFor="file-input" className="upload">
                    <span className="material-icons icon">file_upload</span>
                    Upload
                </label>
                <input 
                    type="file" 
                    accept=".doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png, .zip,.heic"  
                    id="file-input" 
                    name="photoid"  
                    onChange={handleFileChange} 
                    style={{ display: 'none' }} 
                />
            </div>
        </div>
    </div>
</div>

                                        </div>

                                    </div>

                                        {/* button */}
                                        <div className="btn-group mt_10">
                                            <button  onClick={(e)=>{e.preventDefault();handleSubmit()}} className="fs_btn" style = {{'opacity' : (mode === "edit" && needButton === true) ? '0.6' : '1'}} disabled = {mode ==="edit" && needButton}>{mode === "edit" ? "Update" : "Submit" }</button>
                                        </div>

{/* <div className="col-md-6">
        <div className="form-group">
            <label>Due Date Offset</label>
            <input name='dueDateOffset' onChange={handleChange} value={defaultNeedGroup?.dueDateOffset || ''} type="text" placeholder="0" className={`form-control ${errors.dueDateOffset && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, dueDateOffset: null }) }} />
        </div>
</div> */}
                                    </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal popup fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Checklist</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <div className="form-group">
                                        <label>Line Item name</label>
                                        <div className="dsp_flx">
                                            <input type="text" placeholder="Enter" className="form-control" />
                                            <button type="button" className="fs_btn ml_20">Add</button>
                                        </div>
                                    </div>

                                    <p><b>Checklist</b> <br />
                                        Begin creating your checklist by typing the <span className="color">Line item name
                                        </span> and clicking<span className="color"> Add</span> button.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal message_popup popup fade" id="messagepopup" tabindex="-1"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-body">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                    <span className="material-symbols-outlined icon"> delete </span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone. <br />
                                        Do you want to continue?</p>
                                    <button type="button" className="fs_btn">Yes</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AddDefaultNeed;