import React, { useState ,useRef ,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoanInfo } from '../utils/loanInfoContext';
import CurrencyInput from 'react-currency-input-field';
import LocationSearch from './LocationsSeachBox';
import { useSelector} from 'react-redux';
import { formatNumericInput} from '../utils/commonUtils';
// import WarmAppLocationSearch from '../WarmAppLocationSearch';

let formElementsJsoneasybuild = [
  {
    "type": "text",
    "label": "What was the purchase price?",
    "placeholder": "Enter price",
    "name": "purchaseprice",
    "id" : "purchasePriceRefinanceBuild"
  },
  {
    "type": "date",

    "label": "When did you purchase the property?",
    "placeholder": "mm/dd/yyyy",
    "name": "purchasedate",
    "id" : "purchaseDateRefinanceBuild"
  },
  {
    "type": "text",
    "label": "What is the total square footage of the property?",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaRefinanceBuild"
  },
  {
    "type": "number",
    "label": "How many bedrooms?",
    "name": "totalbedrooms",
    "id" : "totalBedroomsRefinanceBuild"
  },
  {
    "type": "number",
    "label": "How many bathrooms?",
    "name": "totalbathrooms",
    "id" : "totalBathroomsRefinanceBuild"
  },
  {
    "type": "buttonGroup",
    "label": "Does property has a pool?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "poolYesRefinanceBuild"},
      { "value": "No", "label": "No" ,"id" : "poolNoRefinanceBuild"},
    
    ],
    "name": "haspool"
  },
  {
    "type": "text",
    "label": "What is the Area of the Lot?",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaRefinanceBuild"
  },
  {
    "type": "buttonGroup",
    "label": "Did you finish building the property?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "finishedBuildingYesRefinanceBuild"},
      { "value": "No", "label": "No" ,"id" : "finishedBuildingNoRefinanceBuild"},
     
    ],
    "name": "finishedbuilding",
    
  },
  {
    "type": "buttonGroup",
    "label": "Do you want the New Refinance Loan to provide construction funding?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "constructionFundingYesRefinanceBuild"},
      { "value": "No", "label": "No" ,"id" : "constructionFundingNoRefinanceBuild"},
  
    ],
    "name": "constructionfunding",
   
  },
  {
    "type": "text",
    "label": "How much is the existing loan?",
    "placeholder": "Enter price",
    "name": "existingloan",
    "id" : "existingLoanRefinanceBuild"
  },
  {
    "type": "text",
    "label": "What is the current value?",
    "placeholder": "Enter price",
    "name": "currentvalue",
    "id" : "currentValueRefinanceBuild"
  },
  {
    "type": "text",
    "label": "What is the completed value?",
    "placeholder": "Enter price",
    "name": "completedvalue",
    "id" : "completedRefinanceBuild"
  },
  {
    "type": "date",
    "label": "What is the target closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionCompletedRefinanceBuild"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Occupancy Status of the Property?",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select Occupancy Status Of The Property" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ],
    
  },
  {
    "type": "buttonGroup",
    "label": "Will there be an additional property used as collateral?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "collateralpropertyYesRefinanceBuild"},
      { "value": "No", "label": "No" ,"id" : "collateralpropertyNoRefinanceBuild"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]
let formElementsJsoneasyfix = [
  {
    "type": "chooseOption",
    "label": "What property type would this be?",
    "options": [
      { "value": "Single Family Residence (SFR - 1 Unit)", "label": "Single Family Residence (SFR - 1 Unit)","img":'assets/images/icons/easy-fix1.png' , "id" : "singleRefinancingFix" },
      { "value": "Duplex", "label": "Duplex", "img":'assets/images/icons/easy-fix2.png', "id" : "duplexRefinancingFix" },
      { "value": "Triplex", "label": "Triplex", "img":'assets/images/icons/easy-fix3.png' , "id" : "triplexRefinancingFix" },
      { "value": "Fourplex", "label": "Fourplex", "img":'assets/images/icons/easy-fix4.png' , "id" : "fourplexRefinancingFix"},
      { "value": "5 Units+", "label": "5 Units+", "img":'assets/images/icons/easy-fix5.png' , "id" : "unitsRefinancingFix"},
      { "value": "Townhouse or Condo", "label": "Townhouse or Condo", "img":'assets/images/icons/easy-fix6.png' , "id" : "townRefinancingFix"},
    ],
    "name": "propertytype",
   
  },
  {
    "type": "text",
    "label": "What was the purchase price?",
    "placeholder": "Enter price",
    "name": "purchaseprice",
    "id" : "purchasePriceRefinanceFix"
  },
  {
    "type": "date",
    "label": "When did you purchase the property?",
    "placeholder": "mm/dd/yyyy",
    "name": "purchasedate",
    "id" : "purchaseDateRefinanceFix"
  },
  {
    "type": "text",
    "label": "What is the total square footage of the property?",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaRefinanceFix"
  },
  {
    "type": "number",
    "label": "How many bedrooms?",
    "name": "totalbedrooms",
    "id" : "totalBedroomsRefinanceFix"
  },
  {
    "type": "number",
    "label": "How many bathrooms?",
    "name": "totalbathrooms",
    "id" : "totalBathroomsRefinanceFix"
  },
  {
    "type": "number",
   
    "label": "Year built?",
    "name": "yearbuilt",
    "id" : "yearBuiltRefinanceFix"
  },
  {
    "type": "buttonGroup",
    "label": "Does property has a pool?",
    "options": [
         { "value": "Yes", "label": "Yes" ,"id" : "poolYesRefinanceFix"},
      { "value": "No", "label": "No" ,"id" : "poolNoRefinanceFix"},
   
    ],
    "name": "haspool"
  },
  {
    "type": "text",
    "label": "What is the Area of the Lot?",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaRefinanceFix"
  },
  {
    "type": "buttonGroup",
    "label": "Did you renovate the property?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "renovatedYesRefinanceFix"},
      { "value": "No", "label": "No" ,"id" : "renovatedNoRefinanceFix"},
  
    ],
    "name": "renovatedproperty"
  },
  {
    "type": "text",
    "label": "What was the cost of the renovation completed?",
    "placeholder": "Renovation cost",
    "name": "renovationcost",
    "id" : "renovationCostRefinanceFix"
  },
  {
    "type": "buttonGroup",
    "label": "Do you want the new refinance loan to provide rehab funding?",
    "options": [
      { "value": "Yes", "label": "Yes" , "id" : "rehabFundingYesRefinanceFix"},
      { "value": "No", "label": "No" , "id" : "rehabFundingNoRefinanceFix"},
   
    ],
    "name": "refinanceloanrehabfunding" 
  },
  {
    "type": "text",
    "label": "How much is the remaining rehab budget?",
    "placeholder": "Remaining rehab budget",
    "name": "remainingrehabbudget",
    "id" : "rehabBudgetRefinanceFix"
  },
  {
    "type": "text",
    "label": "How much is the existing loan?",
    "placeholder": "Enter price",
    "name": "existingloan",
    "id" : "existingLoanRefinanceFix"
  },
  {
    "type": "text",
    "label": "What is the current value?",
    "placeholder": "Enter price",
    "name": "currentvalue",
    "id" : "currentValueRefinanceFix"
  },
  {
    "type": "text",
    "label": "What is the completed value?",
    "placeholder": "Enter price",
    "name": "completedvalue",
    "id" : "completedRefinanceFix"
  },
  {
    "type": "date",
    "label": "What is the target closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionCompletedRefinanceFix"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Occupancy Status of the Property?",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select Occupancy Status Of The Property" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ]
  },
  {
    "type": "buttonGroup",
    "label": "Will there be an additional property used as collateral?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "collateralpropertyYesRefinanceFix"},
      { "value": "No", "label": "No" ,"id" : "collateralpropertyNoRefinanceFix"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]
let formElementsJsoneasybuildpurchase = [
  {
    "type": "chooseOption",
    "label": "What property type would this be?",
    // "options": [
    //   { "value": "Single Family Residence (SFR - 1 Unit)", "label": "Single Family Residence (SFR - 1 Unit)","img":'assets/images/Property Info/single_family_residence.svg' , "id" : "singlePurchasingBuild" },
    //   { "value": "Duplex", "label": "Duplex", "img":'assets/images/Property Info/Duplex.svg' , "id" : "duplexPurchasingBuild"},
    //   { "value": "Triplex", "label": "Triplex", "img":'assets/images/Property Info/Triplex.svg'  , "id" : "triplexPurchasingBuild" },
    //   { "value": "Fourplex", "label": "Fourplex", "img":'assets/images/Property Info/Fourplix.svg' , "id" : "fourplexPurchasingBuild"},
    //   { "value": "5 Units+", "label": "5 Units+", "img":'assets/images/Property Info/5_units.svg' , "id" : "unitsPurchasingBuild"},
    //   { "value": "Townhouse or Condo", "label": "Townhouse or Condo", "img":'assets/images/Property Info/Town_house.svg' , "id" : "townPurchasingBuild"},
    // ],
    "options": [
        {
            "value": "Single Family Residence (SFR - 1 Unit)",
            "label": "Single Family Residence (SFR - 1 Unit)",
            "img": "assets/images/Property Info/single_family_residence.svg",
            "active_img": "assets/images/Property Info/single_family_residence_active.svg",
            "id": "singlePurchasingBuild"
        },
        {
            "value": "Duplex",
            "label": "Duplex",
            "img": "assets/images/Property Info/Duplex.svg",
            "active_img": "assets/images/Property Info/Duplex_active.svg",
            "id": "duplexPurchasingBuild"
        },
        {
            "value": "Triplex",
            "label": "Triplex",
            "img": "assets/images/Property Info/Triplex.svg",
            "active_img": "assets/images/Property Info/Triplex_active.svg",
            "id": "triplexPurchasingBuild"
        },
        {
            "value": "Fourplex",
            "label": "Fourplex",
            "img": "assets/images/Property Info/Fourplix.svg",
            "active_img": "assets/images/Property Info/Fourplix_active.svg",
            "id": "fourplexPurchasingBuild"
        },
        {
            "value": "5 Units+",
            "label": "5 Units+",
            "img": "assets/images/Property Info/5_units.svg",
            "active_img": "assets/images/Property Info/5_units_active.svg",
            "id": "unitsPurchasingBuild"
        },
        {
            "value": "Townhouse or Condo",
            "label": "Townhouse or Condo",
            "img": "assets/images/Property Info/Town_house.svg",
            "active_img": "assets/images/Property Info/Town_house_active.svg",
            "id": "townPurchasingBuild"
        }
    ],
    "name": "propertytype"
  },
  {
    "type": "text",
    "label": "What was the purchase price (LOT/PROPERTY)?",
    "placeholder": "Enter price",
    "name": "purchaseprice",
    "id" : "purchasePricePurchaseBuild"
  },
  {
    "type": "text",
    "label": "What is the construction budget?",
    "placeholder": "Enter price",
    "name": "constructionbudget",
    "id" : "constructionBudgetPurchaseBuild"
  },
  
  {
    "type": "text",
    "label": "What is the total square footage of the new property?",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaPurchaseBuild"
  },
  {
    "type": "number",
    "label": "How many bedrooms?",
    "name": "totalbedrooms",
    "id" : "totalBedroomsPurchaseBuild"
  },
  {
    "type": "number",
    "label": "How many bathrooms?",
    "name": "totalbathrooms",
    "id" : "totalBathroomsPurchaseBuild"
  },
  {
    "type": "dropdown",
    "label": "Pool?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "poolYesPurchaseBuild"},
      { "value": "No", "label": "No" ,"id" : "poolNoPurchaseBuild"},
    ],
    "name": "haspool"
  },
  {
    "type": "text",
    "label": "What is the square footage of the lot?",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaPurchaseBuild"
  },
  {
    "type": "text",
    "label": "What is the completed value?",
    "placeholder": "Enter price",
    "name": "completedvalue",
    "id" : "completedPurchaseBuild"
  },
  {
    "type": "date",
    "label": "What is the target closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionPurchaseBuild"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Occupancy Status of the Property?",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select Occupancy Status Of The Property" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ]
  },
  {
    "type": "buttonGroup",
    "label": "Will there be an additional property used as collateral?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "collateralpropertyYesPurchaseBuild"},
      { "value": "No", "label": "No" ,"id" : "collateralpropertyNoPurchaseBuild"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]
let formElementsJsoneasyfixpurchase = [
  {
    "type": "chooseOption",
    "label": "What property type would this be?",
    "options": [
      { "value": "Single Family Residence (SFR - 1 Unit)", "label": "Single Family Residence (SFR - 1 Unit)","img":'assets/images/icons/easy-fix1.png', "id" : "singlePurchasingFix"  },
      { "value": "Duplex", "label": "Duplex", "img":'assets/images/icons/easy-fix2.png' , "id" : "duplexPurchasingFix"},
      { "value": "Triplex", "label": "Triplex", "img":'assets/images/icons/easy-fix3.png' , "id" : "triplexPurchasingFix"},
      { "value": "Fourplex", "label": "Fourplex", "img":'assets/images/icons/easy-fix4.png' , "id" : "fourplexPurchasingFix"},
      { "value": "5 Units+", "label": "5 Units+", "img":'assets/images/icons/easy-fix5.png' , "id" : "unitsPurchasingFix"},
      { "value": "Townhouse or Condo", "label": "Townhouse or Condo", "img":'assets/images/icons/easy-fix6.png' , "id" : "townPurchasingFix"},
    ],
    "name": "propertytype"
  },
  {
    "type": "text",
    "label": "What was the purchase price?",
    "placeholder": "Enter price",
    "name": "purchaseprice",
    "id" : "purchasePricePurchaseFix"
  },
  {
    "type": "text",
    "label": "What is the rehab budget?",
    "placeholder": "Enter price",
    "name": "construction_rehab_budget",
    "id" : "rehabBudgetPurchaseFix"
  },
  {
    "type": "buttonGroup",
    "label": "Do you want the loan to provide rehab funding?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "rehabFundYesPurchaseBuild"},
      { "value": "No", "label": "No" ,"id" : "rehabFundNoPurchaseBuild"},
     
    ],
    "name": "refinanceloanrehabfunding"
  },
  {
    "type": "text",
    "label": "What is the total square footage of the property?",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaPurchaseFix"
  },
  {
    "type": "number",
    "label": "How many bedrooms?",
    "name": "totalbedrooms",
    "id" : "totalBedroomsPurchaseFix"
  },
  {
    "type": "number",
    "label": "How many bathrooms?",
    "name": "totalbathrooms",
    "id" : "totalBathroomsPurchaseFix"
  },
  {
    "type": "number",
    "label": "Year built?",
    "name": "yearbuilt",
    "id" : "yearBuiltPurchaseFix"
  },
  {
    "type": "buttonGroup",
    "label": "Does property has a pool?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "poolYesPurchaseFix"},
      { "value": "No", "label": "No" ,"id" : "poolNoPurchaseFix"},

    ],
    "name": "haspool"
  },
  {
    "type": "text",
    "label": "What is the square footage of the lot?",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaPurchaseFix"
  },
  {
    "type": "text",
    "label": "What is the after-renovated value?",
    "placeholder": "Enter price",
    "name": "renovationarv",
    "id" : "renovationArvPurchaseFix"
  },
  {
    "type": "date",
    "label": "What is the target closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionPurchaseFix"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Occupancy Status of the Property?",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select Occupancy Status Of The Property" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ]
  },
  {
    "type": "buttonGroup",
    "label": "Will there be an additional property used as collateral?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "collateralpropertyYesPurchaseFix"},
      { "value": "No", "label": "No" ,"id" : "collateralpropertyNoPurchaseFix"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]




let collateralJSON = [
  {
    "type": "buttonGroup",
    "label": "Is there a current loan on the property?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "collateralCurrentLoanYes"},
      { "value": "No", "label": "No" ,"id" : "collateralCurrentLoanNo"},
      
    ],
    "name": "currentloan"
  },
  {
    "type": "text",
    "label": "What is the Existing Loan Amount?",
    "placeholder": "Enter price",
    "name": "existingloan",
    "id" : "collateralExistingLoan"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Proposed Lien Position?",
    "name" : "proposedlienposition",
    "id" : "collatrealProposedLienPosition",
    "options" : [
      { "value": "", "label": "Select the Proposed Lien Position" },
      { "value": "1st Position", "label": "1st Position" },
      { "value": "2nd Position", "label": "2nd Position" },
    ]
  },
  {
    "type": "text",
    "label": "What is the total square footage of the new property?",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "collateralPropertyArea"
  },
  {
    "type": "number",
    "label": "How many bedrooms?",
    "name": "totalbedrooms",
    "id" : "collateralTotalBedrooms"
  },
  {
    "type": "number",
    "label": "How many bathrooms?",
    "name": "totalbathrooms",
    "id" : "collateralTotalBathrooms"
  },
  {
    "type": "number",
    "label": "Year built?",
    "name": "collateralyearbuilt",
    "id" : "collateralYearBuilt"
  },
  {
    "type": "buttonGroup",
    "label": "Does property has a pool?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "collateralPoolYes"},
      { "value": "No", "label": "No" ,"id" : "collateralpoolNo"},
  
    ],
    "name": "haspool"
  },
  {
    "type": "text",
    "label": "What is the square footage of the lot?",
    "placeholder": "Enter the square footage of the lot",
    "name": "lotarea",
    "id" : "collateralLotArea"
  },
  {
    "type" : "dropdown",
    "label" : "What is the Occupancy Status of the Property?",
    "name" : "occupancy",
    "id" : "collateralOccupancyStatus",
    "options" : [
      { "value": "", "label": "Select Occupancy Status Of The Property" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ],
    
  },
  {
    "type": "text",
    "label": "What is the Estimated Value?",
    "placeholder": "0.00",
    "name": "estimatedvalue",
    "id" : "collateralEstimatedValue"
  },
  {
    "type": "address",
    "label": "Collateral Property Home Address",
    "placeholder": "Enter address",
    "name": "propertyPlace",
    "id" : "collateralCoborrowerAddress"
  },
  
]


const RefinancingBuild = () => {
    const navigate = useNavigate();
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const [dealInfo, setDealInfo] = useState(loanInfo?.dealinfo ? loanInfo?.dealinfo : {});
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    const [formJSON, setformjson] = useState((loanInfo?.propertyinfo?.loanfor == "Refinance" && loanInfo?.propertyinfo?.programtype == 'EASY BUILD') ? formElementsJsoneasybuild : (loanInfo?.propertyinfo?.loanfor == "Refinance" && loanInfo?.propertyinfo?.programtype == 'EASY FIX') ? formElementsJsoneasyfix : (loanInfo?.propertyinfo?.loanfor == "Purchase" && loanInfo?.propertyinfo?.programtype == 'EASY BUILD') ? formElementsJsoneasybuildpurchase :formElementsJsoneasyfixpurchase);
    const dateInputRef = useRef(null);
    const [showCollateral, setShowCollateral] = useState(false);
    const [collateralData, setCollateralData] = useState(loanInfo?.collateralproperty || {});
    const [collateralFlag, setCollateralFlag] = useState(loanInfo?.collateralFlag || false);

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [addressData,setAddressData] = useState({ });
  
    const configdata = useSelector(state => state.configdata);
    const mapsKey = configdata?.common?.googleMapsKey;
    let floatKeys  = [
      'purchaseprice',
      'existingloan',
      'currentvalue',
      'completedvalue',
      'renovationcost',
      'remainingrehabbudget',
      'constructionbudget',
      'construction_rehab_budget',
      'renovationarv'
    ]

    const handleCurrencyInputs = (name,value)=>{
        
        console.log(value)
        floatKeys.forEach(key => {
          if(key === name){
            let updatedValue = isNaN(value) ? '' : value;
            setDealInfo((prevData) => {
              // Create a copy of the previous data
              let updatedData = { ...prevData };
              // Update the copied data with the new key-value pair
              updatedData = {
                ...updatedData,
                [key]: updatedValue,
              };
          
              console.log('valuevaluevalue',loanInfo);
              return updatedData;
            }); 
          }
        })
        
      
    }
    
    const handleInputChange = (e) => {
        e.preventDefault();
        // Update the formData with the new key-value pair
       let key = e.target.name;
       let value = e.target.value;
       console.log(value);
       const allowedKeys = ['purchaseprice', 'existingloan', 'completedvalue', 'currentvalue', 'renovationcost', 'remainingrehabbudget','construction_rehab_budget','constructionbudget', 'renovationarv'];
     
      //  let numberTypeRegex = /^[1-9][0-9]*$/
      //  if (allowedKeys.includes(key) && value !== '') {
      //   // Remove non-numeric characters and prepend '$'
      //   let numericValue = value.replace(/[^0-9]/g, '');
      //   let formattedValue = `$${Number(numericValue).toLocaleString()}`;
  
      //   let finalformatVal = (formattedValue === "$0") ? '' : formattedValue;
      //   value = finalformatVal;
      //  }
      const numberTypeRegex = /^[0-9]*\.?[0-9]*$/;
      if (allowedKeys.includes(key) && value !== '') {
        // Remove non-numeric characters and prepend '$'
        // if(value.startsWith('$')){
        //   let replace = value.replace(/\$/g,'');
        //   // let replace = value;
        //   value = (numberTypeRegex.test(replace)) && (value !== "0")? '$' + formatPrice(replace) : '';
        // }else{
        //   value = (numberTypeRegex.test(value)) && (value !== "0")? '$' + formatPrice(value) : '';
        // }
        let formattedAmount = formatPrice(value);
        value = formattedAmount;
        // if(formattedAmount.startsWith('$')){
        //   let replace = formattedAmount.replace(/\$/g,'');
        //   // let replace = value;
        //   value = (formattedAmount !== "0" )? '$' + replace : '';
        // }else{
        //   value = (formattedAmount !== "0")? '$' + formattedAmount : '';
        // }
      }

       const allowedNumberInputKeys = ['lotarea','propertyarea'];
    
       const numRegex = /^(?!.*(0{2,}|[^0]\b0\b))\d*$/;
       if (allowedNumberInputKeys.includes(key) && value !== '') {
        // Remove non-numeric characters and prepend '$'
        console.log('valuevalue111',value);
        // let numValue =  (value === "0") ? '' : value;
      
        value = formatNumericInput(value);;
       }
       console.log('valuevalue',value);

          //date validation
          const allowedDateInputKeys = ['purchasedate','constructioncompleteddate','yearbuilt'];
          // const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
          const yearRegex = /^[0-9]{4}$/;
          const currentYear = new Date().getFullYear(); 
          // console.log(currentYear); // Output: 2022
          if(key === "constructioncompleteddate" && allowedDateInputKeys.includes(key) && value !== ''){
            // console.log("date ",value);
              // let yearSplit = value.split("-");
              // console.log("date",value.split("-"));
              // console.log(yearRegex.test(yearSplit[0]));
              // let dateValue = !(yearRegex.test(yearSplit[0]) === true) || yearSplit[0] < currentYear ? '' : value;
              // value = dateValue;
              // Check if year and month values are less than or equal to 4 digits
              // Extract year from the input date, ignoring leading zeros
              
              const [year,month,day] = value.split('-');
                // console.log("split date",year?.length);
              if (year?.length > 4) {
                // console.log("Year or month value exceeds 4 digits.");
                value = '';
              }
              
                 
              

              value = value;
          }else if(key === "purchasedate" && allowedDateInputKeys.includes(key) && value !== ''){
            let yearSplit = value.split("-");
            // console.log("date",value.split("-"));
            console.log(yearRegex.test(yearSplit[0]));
            let dateValue = !(yearRegex.test(yearSplit[0]) === true) || yearSplit[0] > currentYear ? '' : value;
            value = dateValue;
          }else if(key === "yearbuilt" && allowedDateInputKeys.includes(key) && value !== ''){
              // const isValidYear = /^\d{4}$/.test(value);
              if (value > currentYear) {
                value = '';
              } else {
                value = value;
              }
            
            
          }

          //type numbervalidation
          // const allowedNumberTypeInputs = ['totalbathrooms','totalbedrooms'];
          // const singleDigitRegex = /^[0-9]$/;
          // if(allowedNumberTypeInputs.includes(key) && value !== ''){
          //     let numVal = !(singleDigitRegex.test(value) === true) ? '' : value;
          //     value = numVal;
          // }


       if (parseFloat(value) < 0) {
        // If it's negative, set the input value to an empty string or handle it as needed
        value = '';
      }
        setDealInfo((prevData) => {
          // Create a copy of the previous data
          let updatedData = { ...prevData };
          // Update the copied data with the new key-value pair
          updatedData = {
            ...updatedData,
            [key]: value,
          };
      
          console.log('valuevaluevalue',loanInfo);
          return updatedData;
        });
    };

    // const handleButtonChange = (key, value) => {

      
    //     // Update the formData with the new key-value pair
    //     setDealInfo((prevData) => {
    //       // Create a copy of the previous data
    //       let updatedData = { ...prevData };
    //       // Update the copied data with the new key-value pair
    //       updatedData = {
    //         ...updatedData,
    //         [key]: value,
    //       };
    //       console.log('updatedDatahere',updatedData);
    //       setLoanInfo((prevDealInfo) => ({
    //         ...prevDealInfo,
    //         dealinfo: updatedData,
    //       }));
      
    //       return updatedData;
    //     });

        

         
        
    //};
    const valueUnits = {
      "Single Family Residence (SFR - 1 Unit)": 1,
      "Duplex": 2,
      "Triplex": 3,
      "Fourplex": 4,
      "5 Units+": 5,
      "Townhouse or Condo": 1,
      "Lot/New Construction": 1
  };
  const handleButtonChange = (key, value) => {
    console.log('key', key, 'value', value)
    setDealInfo((prevData) => {
      // Create a copy of the previous data
      let updatedData = { ...prevData };
      if (key === 'propertytype') {
        console.log(valueUnits[value])
        updatedData = {
          ...updatedData,
          [key]: value,
          numberofunits: valueUnits[value] || ''
        };
      } else {
        updatedData = {
          ...updatedData,
          [key]: value,
        };
      }




      setLoanInfo((prevDealInfo) => ({
        ...prevDealInfo,
        dealinfo: updatedData,
        iscollateralflag: collateralFlag,
        collateralproperty: collateralData
      }));
      return updatedData;
    });
  };
    
    //dropdown inputs
    const handleDropdownChange = (e) =>{

      e.preventDefault();
        // Update the formData with the new key-value pair
       let key = e.target.name;
       let value = e.target.value;
       console.log(value);
      setDealInfo((prevData) => {
        // Create a copy of the previous data
        let updatedData = { ...prevData };
        // Update the copied data with the new key-value pair
        updatedData = {
          ...updatedData,
          [key]: value,
        };
    
        console.log('valuevaluevalue',loanInfo);
        return updatedData;
      });
    }

    const handleDealData = ()=>{
      setLoanInfo((prevDealInfo) => ({
        ...prevDealInfo,
        dealinfo: dealInfo,
        iscollateralflag:collateralFlag,
        collateralproperty : collateralData
      }));
    }

   

    const validateInputs = () => {
        const requiredInputs = document.querySelectorAll('.select_box input');
        const buttonGroups = document.querySelectorAll('.btn-group');
        const chooseBoxes = document.querySelectorAll('.rf_boxs');
        const chooseDropdowns = document.querySelectorAll('.select_box select');
        const searchWrpElement = document.querySelector(".search_wrp");
      
        let isValid = true;
      
        // Function to remove error styles for an input
        const removeErrorStyles = (input) => {
          input.classList.remove('invalid-input');
        };
      
        // Function to remove error styles for a button group
        const removeGroupErrorStyles = (group) => {
          group.classList.remove('invalid-group');
        };

          // Function to remove error styles for a choose option boxes group
          const removeBoxErrorStyles = (box) => {
            box.classList.remove('invalid-box');
          };
      
        // Add event listeners to remove error styles on input focus/change
        requiredInputs.forEach((input) => {
          input.addEventListener('input', () => removeErrorStyles(input));
        });
      
        // Add event listeners to remove error styles on button click
        buttonGroups.forEach((group) => {
          const buttons = group.querySelectorAll('.select-btn');
          buttons.forEach((button) => {
            button.addEventListener('click', () => removeGroupErrorStyles(group));
          });
        });

          // Add event listeners to remove error styles on box click
          chooseBoxes.forEach((rfbox) => {
            const boxes = rfbox.querySelectorAll('.rf_boxs');
            boxes.forEach((bx) => {
              bx.addEventListener('click', () => removeBoxErrorStyles(rfbox));
            });
        });

        // Add event listeners to remove error styles on dropdown
        chooseDropdowns.forEach((selectDropdown) => {
          
          selectDropdown.addEventListener('change', () => removeErrorStyles(selectDropdown));
        });
      
        // Validate inputs and button groups
        const allowedKeys = ['constructioncompleteddate','purchaseprice', 'existingloan', 'completedvalue', 
        'currentvalue', 'renovationcost', 'remainingrehabbudget' , 'purchasedate' ,'collateralyearbuilt' ,'yearbuilt'];
        // const dateInputs = ['constructioncompleteddate'];

        for (const input of requiredInputs) {
          const inputValue = input.value.trim();

          if (input?.name !== "constructioncompleteddate" && input?.name !== "purchasedate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && !inputValue) {
            // Handle empty input case
            input.classList.add('invalid-input');
            isValid = false;
          } else if (input.name !== "constructioncompleteddate" && input?.name !== "purchasedate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && allowedKeys.includes(input.name) && inputValue === '$0') {
            // Handle the case where the value is $0 for allowed keys
            input.classList.add('invalid-input');
            isValid = false;
          }else if(input?.name === "constructioncompleteddate" && input?.name !== "purchasedate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && (!input?.value || input?.value < currentDate)){
            // console.log("date input",input?.value);
            input.classList.add('invalid-input');
            isValid = false;
          }else if(input?.name === "purchasedate" && input?.name !== "constructioncompleteddate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && (!input?.value || input?.value > currentDate)){
            // console.log("date input",input?.value);
            input.classList.add('invalid-input');
            isValid = false;
          }else if((input?.name === "collateralyearbuilt" || input?.name === "yearbuilt")&& (!input?.value || inputValue.length < 4)){
            // console.log("date input",input?.value);
            input.classList.add('invalid-input');
            isValid = false;
          }
        
        
         
        }

       
      
        for (const group of buttonGroups) {
          const buttons = group.querySelectorAll('.cp_btn');
          const selectedButtons = Array.from(buttons).filter(btn => btn.style.background !== '');
      
          if (selectedButtons.length === 0) {
            group.classList.add('invalid-group');
            isValid = false;
          }
        }

          for (const box of chooseBoxes) {
            const boxes = box.querySelectorAll('.box');
            const selectedBoxes = Array.from(boxes).filter(bx => bx.style.background !== '');
        
            if (selectedBoxes.length === 0) {
              box.classList.add('invalid-box');
            isValid = false;
          }
        }

        for (const selectDropdown of chooseDropdowns) {
          if (!selectDropdown.value) {
            selectDropdown.classList.add('invalid-input');
            isValid = false;
          }
        }

       
        if (!collateralData["propertyaddress"] || selectedPlace?.trim() === "") {
         
          const searchWrpElement = document.querySelector(".search_wrp");
      
          if (searchWrpElement) {
            isValid = false;
            searchWrpElement.classList.add("invalid-input");
          }
        }
        
        return isValid;
      };
    const submitDealInfo = () => {
      // const isValid = validateInputs()
      
      // console.log("dealInfohere", isValid, loanInfo);
      // console.log('collateral data',collateralData);
      // if (isValid) {
      //   // setLoanInfo((prevDealInfo) => ({
      //   //   ...prevDealInfo,
      //   //   dealinfo: dealInfo,
      //   // }));
      //   setLoanInfo((prevDealInfo) => ({
      //     ...prevDealInfo,
      //     dealinfo: dealInfo,
      //     iscollateralflag:collateralFlag,
      //     collateralproperty : collateralData
      //   }));
        navigate('/StrategyInfo');
      //   console.log("valuevaluevalue", loanInfo);
      // }
    };
    const getTodayDate = () => {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    };

    // Function to format the price with commas
    const formatPrice = (value) => {
      const valueRep = value.replace(/\D/g, '');
      // return valueRep.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // Format the number with commas
      // Format the number with commas and add a dollar sign
      const formattedValue = `$${Number(valueRep).toLocaleString()}`;

      return formattedValue;
    };


    /* collateral functionality */

    const handleCollateralButton = (key, value ,id) => {

      const buttonId = id; // Construct button ID if needed
      removeError(buttonId); // Remove the error class from the button group
      
      const newValue = (value === 'Yes');
  
      // Clear collateralData if the specific condition is met
      if (key === 'collateraladditionalproperty') {
        if (value === 'No') {
            // Clear all entries except collateraladditionalproperty, set its value to 'No'
            setCollateralData(prevCollateralData => ({
                collateraladditionalproperty: 'No'
            }));
            // setShowCollateral(newValue);
            // setCollateralFlag(newValue);
        } else {
            // Update collateraladditionalproperty with the new value
            const updatedCollateralData = { ...collateralData, [key]: value };
            setCollateralData(updatedCollateralData);
            // setShowCollateral(newValue);
            // setCollateralFlag(newValue);
        }
        setShowCollateral(newValue);
        setCollateralFlag(newValue);
      } else {
          // Update collateralData with the new value for other keys
          const updatedCollateralData = { ...collateralData, [key]: value };
          setCollateralData(updatedCollateralData);
      }
  
      removeError(key);
  
      // Update loanInfo if needed with the new collateral data
      // setLoanInfo((prevLoanInfo) => ({
      //     ...prevLoanInfo,
      //     collateralproperty: collateralData // Make sure to merge correctly
      // }));
    };
  

    const handleCollateralDropdown = (e)=> {
      const {name , value} = e.target;
      // Update the collateralData with the new value
      const updatedCollateralData = { ...collateralData, [name]: value };
  
      setCollateralData(updatedCollateralData); // Update state with new value
  
      // Now pass the updated object to setLoanInfo
      // setLoanInfo((prevLoanInfo) => ({
      //     ...prevLoanInfo,
      //     collateralproperty: updatedCollateralData // Make sure to merge correctly
      // }));
      
      
    }

   
    const handleCollateralChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      //const { name, value } = e.target;
      
      // Log to confirm input handling
      console.log(`Changed ${name}: ${value}`);

      if(name == "collateralyearbuilt"){
        const currentYear = new Date().getFullYear();
        if(value  > currentYear){
            value = '';
        }
      }

      const allowedNumberInputKeys = ['lotarea','propertyarea'];
    
       if (allowedNumberInputKeys.includes(name) && value !== '') {
        // Remove non-numeric characters and prepend '$'
        console.log('valuevalue111',value);
        // let numValue =  (value === "0") ? '' : value;
      
        value = formatNumericInput(value);;
       }
      
      const updatedCollateralData = { ...collateralData, [name]: value };
    
      setCollateralData(updatedCollateralData); // Update state
      
      // Update the loan information state accordingly
      // setLoanInfo((prevLoanInfo) => ({
      //   ...prevLoanInfo,
      //   collateralproperty: updatedCollateralData 
      // }));
    };
    
    const handleCollateralCurrencyInputs = (name,value)=>{
        
      console.log(value)
      //floatKeys.forEach(key => {
        //if(key === name){
          let updatedValue = isNaN(value) ? '' : value;
          // setDealInfo((prevData) => {
          //   // Create a copy of the previous data
          //   let updatedData = { ...prevData };
          //   // Update the copied data with the new key-value pair
          //   updatedData = {
          //     ...updatedData,
          //     [key]: updatedValue,
          //   };
        
          //   console.log('valuevaluevalue',loanInfo);
          //   return updatedData;
          // }); 
          const updatedCollateralData = { ...collateralData, [name]: updatedValue };
    
          setCollateralData(updatedCollateralData);
          
        //}
      //})
      
    
  }

  const removeError = (buttonId) => {
    // Find the button element using the buttonId
    const button = document.getElementById(buttonId);

    if (button) {
        // Find the parent .btn-group container
        const group = button.closest('.btn-group');

        if (group) {
            group.classList.remove('invalid-group');
        } else {
            console.warn('Button group not found for buttonId:', buttonId);
        }
    } else {
        console.warn('Button not found for buttonId:', buttonId);
    }
};


  //collateral address
  useEffect(() => {

    if (collateralData["propertyaddress"] || selectedPlace?.trim() !== "") {
        const searchWrpElement = document.querySelector(".search_wrp");
        if (searchWrpElement) {
          searchWrpElement.classList.remove("invalid-input");
        }
    }

    if (selectedPlace) {
      setCollateralData((prevData) => {
        // Create a copy of the previous data
        let updatedData = { ...prevData };
        // Update the copied data with the new key-value pair
        let selectedAddress = {
          'propertyaddress': addressData?.address?.trim() || addressData?.fullAddress,
          'propertystate': addressData?.state,
          'propertyzipcode': addressData?.zipcode,
          'propertygeolocation': addressData?.geolocation,
          'propertygooglemaplink': addressData?.url,
          'propertyareaaddress': addressData?.area,
          'propertycity': addressData?.city,
          'propertyfulladdress': addressData?.fullAddress,
          'propertystateshort': addressData?.stateShortName,
          'propertycounty': addressData?.county,
        };
        updatedData = {
          ...updatedData,
          ...selectedAddress,
        };
  
        return updatedData;
      });
    }

  }, [selectedPlace]);
  


    return (
      

      <div class="wizard-canvas">
      <div class="wizard-canvas-header">
        <h3>Property Info </h3>
      </div>
      <div class="wizard-canvas-body wizard-overflow">
        <div class="question_block no-toppad">
          <label class="label">What property type would this be?</label>
          <div class="radios-wrapper mt20">
            <button class="block_radiobtn active">
              <div class="icon-check">
                <img src="assets/images/new-deal/check_circle.svg" class="checked"/>
              </div>
              <img src="assets/images/Property Info/single_family_residence.svg" class="default_img"/>
              <img src="assets/images/Property Info/single_family_residence_active.svg" class="active_img"/>
              Single Family Residence
            </button>

            <button class="block_radiobtn">
              <div class="icon-check">
                <img src="assets/images/new-deal/check_circle.svg" class="checked"/>
              </div>
              <img src="assets/images/Property Info/Duplex.svg" class="default_img"/>
              <img src="assets/images/Property Info/Duplex_active.svg" class="active_img"/>
              Duplex
            </button>

            <button class="block_radiobtn">
              <div class="icon-check">
                <img src="assets/images/new-deal/check_circle.svg" class="checked"/>
              </div>
              <img src="assets/images/Property Info/Triplex.svg" class="default_img"/>
              <img src="assets/images/Property Info/Triplex_active.svg" class="active_img"/>
              Triplex
            </button>

            <button class="block_radiobtn">
              <div class="icon-check">
                <img src="assets/images/new-deal/check_circle.svg" class="checked"/>
              </div>
              <img src="assets/images/Property Info/Fourplix.svg" class="default_img"/>
              <img src="assets/images/Property Info/Fourplix_active.svg" class="active_img"/>
              Fourplex
            </button>

            <button class="block_radiobtn">
              <div class="icon-check">
                <img src="assets/images/new-deal/check_circle.svg" class="checked"/>
              </div>
              <img src="assets/images/Property Info/5_units.svg" class="default_img"/>
              <img src="assets/images/Property Info/5_units_active.svg" class="active_img"/>
              5 Units+
            </button>


            <button class="block_radiobtn">
              <div class="icon-check">
                <img src="assets/images/new-deal/check_circle.svg" class="checked"/>
              </div>
              <img src="assets/images/Property Info/Town_house.svg" class="default_img"/>
              <img src="assets/images/Property Info/Town_house_active.svg" class="active_img"/>
              Townhouse or Condo
            </button>

            
          </div>
      
        </div>

        <div class="question_block horz_questions">
          <label class="label">What area are you looking?</label>
          <div class="ques_rightBlock">
            <div class="search_wrp">
              <span class="material-symbols-outlined icon-style">
              location_on
              </span>
              <div>
                <input type="text" placeholder="Search City and Zip Code" class="wizard-search-input"/>
              </div>
              <span class="material-symbols-outlined icon-style">search</span>
            </div>
          </div>
        </div>
        <div class="question_block">
          <div class="form_wrapper">
              <div class="warmappform_box">
                  <label class="label">What was the purchase price?</label>
                  <div className='form-icon-end'>
                  <input type="text" inputmode="decimal" id="" name="" class="form-control" placeholder="$0.00" value=""/>
                  <span class="material-icons">payments</span>
                  </div>
                </div>
                <div class="warmappform_box">
                  <label class="label">When did you purchase the property?</label>
                  <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="startdate" tabindex="2" class="form-control" value=""/>
                  
                </div>
                <div class="warmappform_box">
                  <label class="label">Year built</label>
                  <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="startdate" tabindex="2" class="form-control" value=""/>
                  
                </div>
                <div class="warmappform_box">
                  <label class="label">Total building square footage</label>
                  <div className='form-icon-end'>
                  <input id="lotAreaPurchaseBuild" type="text" placeholder="Sq. Ft" class="form-control" name="lotarea" min="" value=""/>
                  <span class="material-icons">crop</span>
                  </div>
                </div>
                <div class="warmappform_box">
                  <label class="label">Total lot square footage</label>
                  <div className='form-icon-end'>
                  <input id="lotAreaPurchaseBuild" type="text" placeholder="Sq. Ft" class="form-control" name="lotarea" min="" value=""/>
                  <span class="material-icons">crop</span>
                  </div>
                </div>
                <div class="warmappform_box">
                  <label class="label">Number of bedrooms</label>
                  <div className='form-icon-end'>
                  <input id="totalBedroomsPurchaseBuild" type="number" class="form-control" name="totalbedrooms" min="" value=""/>
                  <span class="material-icons">bed</span>
                  </div>
                </div>
                <div class="warmappform_box">
                  <label class="label">Number of bathrooms</label>
                  <div className='form-icon-end'>
                  <input id="totalBedroomsPurchaseBuild" type="number" class="form-control" name="totalbedrooms" min="" value=""/>
                  <span class="material-icons">bathtub</span>
                  </div>
                </div>
                <div class="warmappform_box">
                  <label class="label">Pool</label>
                  <select  tabindex="1" placeholder="Select" class="form-control">
                    <option value="select1">Select</option>
                    <option value="select2">Yes</option>
                    <option value="select3">No</option>
                  </select>
                
                </div>
                <div class="warmappform_box">
                  <label class="label">Occupancy status of the property</label>
                  <select  tabindex="1" placeholder="Select" class="form-control">
                    <option value="select1">Select Occupancy Status Of The Property</option>
                    <option value="select2">Delivered Vacant</option>
                    <option value="select3">COE + 7 days</option>
                    <option value="select3">COE + 30 Days</option>
                    <option value="select3">Tenant Occupied</option>
                    <option value="select3">Unknown</option>
                  </select>
                
                </div>
                <div class="warmappform_box">
                  <label class="label">Closing date</label>
                  <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="startdate" tabindex="2" class="form-control" value=""/>
                  
                </div>
          
          </div>
          <div class="add_property">
                <div class="no">
                  <h3>Add property as collateral</h3>
                  <button class="radio-box">
                      <span class="material-symbols-outlined"> radio_button_checked </span> No
                    </button>
                </div>
                <button class="radio-box">
                    <span class="material-symbols-outlined"> radio_button_checked </span> Yes
                  </button>
          </div>
          <label class="label mr10">Is there an existing loan on the property?</label>
          <div class="btn-group mt10 mb15">
              <button class="radio-box mr10">
                  <span class="material-symbols-outlined"> radio_button_checked </span> Yes
                </button>
                <button class="radio-box">
                    <span class="material-symbols-outlined"> radio_button_checked </span> No
                  </button>
            </div>
        </div>
        <div class="question_block border-bottom-0">
            <div class="form_wrapper">
                <div class="warmappform_box">
                    <label class="label">Existing loan amount</label>
                    <div className='form-icon-end'>
                    <input type="text" inputmode="decimal" id="" name="" class="form-control" placeholder="$0.00" value=""/>
                    <span class="material-icons">payments</span>
                    </div>
                  </div>
                  <div class="warmappform_box">
                      <label class="label"> Proposed lien position</label>
                      <select  tabindex="1" placeholder="Select" class="form-control">
                        <option value="select1">Select</option>
                        <option value="select2">Yes</option>
                        <option value="select3">No</option>
                      </select>
                    
                    </div>
                    <div class="warmappform_box">
                        <label class="label">Estimated value </label>
                        <div className='form-icon-end'>
                        <input type="text" inputmode="decimal" id="" name="" class="form-control" placeholder="$0.00" value=""/>
                        <span class="material-icons">payments</span>
                        </div>
                      </div>
                      <div class="warmappform_box">
                          <label class="label">What was the purchase price?</label>
                          <div className='form-icon-end'>
                          <input type="text" inputmode="decimal" id="" name="" class="form-control" placeholder="$0.00" value=""/>
                          <span class="material-icons">payments</span>
                          </div>
                        </div>
                  <div class="warmappform_box">
                    <label class="label">When did you purchase the property?</label>
                    <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="startdate" tabindex="2" class="form-control" value=""/>
                    
                  </div>
                  <div class="warmappform_box">
                      <label class="label">Year built</label>
                      <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="startdate" tabindex="2" class="form-control" value=""/>
                      
                    </div>
                  <div class="warmappform_box">
                    <label class="label">Total building square footage</label>
                    <div className='form-icon-end'>
                    <input id="lotAreaPurchaseBuild" type="text" placeholder="Sq. Ft" class="form-control" name="lotarea" min="" value=""/>
                    <span class="material-icons">crop</span>
                    </div>
                  </div>
                  <div class="warmappform_box">
                    <label class="label">Total lot square footage</label>
                    <div className='form-icon-end'>
                    <input id="lotAreaPurchaseBuild" type="text" placeholder="Sq. Ft" class="form-control" name="lotarea" min="" value=""/>
                    <span class="material-icons">crop</span>
                    </div>
                  </div>
                  <div class="warmappform_box">
                    <label class="label">Number of bedrooms</label>
                    <div className='form-icon-end'>
                    <input id="totalBedroomsPurchaseBuild" type="number" class="form-control" name="totalbedrooms" min="" value=""/>
                    <span class="material-icons">bed</span>
                    </div>
                  </div>
                  <div class="warmappform_box">
                    <label class="label">Number of bathrooms</label>
                    <div className='form-icon-end'>
                    <input id="totalBedroomsPurchaseBuild" type="number" class="form-control" name="totalbedrooms" min="" value=""/>
                    <span class="material-icons">bathtub</span>
                    </div>
                  </div>
                  <div class="warmappform_box">
                    <label class="label">Pool</label>
                    <select  tabindex="1" placeholder="Select" class="form-control">
                      <option value="select1">Select</option>
                      <option value="select2">Yes</option>
                      <option value="select3">No</option>
                    </select>
                   
                  </div>
                  <div class="warmappform_box">
                    <label class="label">Occupancy status of the property</label>
                    <select  tabindex="1" placeholder="Select" class="form-control">
                      <option value="select1">Select Occupancy Status Of The Property</option>
                      <option value="select2">Delivered Vacant</option>
                      <option value="select3">COE + 7 days</option>
                      <option value="select3">COE + 30 Days</option>
                      <option value="select3">Tenant Occupied</option>
                      <option value="select3">Unknown</option>
                    </select>
                  
                  </div>
                  <div class="warmappform_box mb0">
                    <label class="label">Closing date</label>
                    <input id="actionDateHmda" type="date" placeholder="mm-dd-yyy" name="startdate" tabindex="2" class="form-control" value=""/>
                    
                  </div>
            
            </div>
          
          </div>

      </div>
      <div class="wizard-canvas-footer">
        <button type="button" class="save_next" id="collateralPoolYes" onClick={submitDealInfo} >SAVE & NEXT<span class="material-icons"> chevron_right </span></button>
      </div>


    </div>
                
                
    );
};

export default RefinancingBuild;