import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {createFundAdmin} from "../utils/reducer";
import { useLocation, useNavigate} from 'react-router-dom';
import {getSingleInvestor, addInvestor, updateInvestor,getStates,getAllFundManagmentAdmin} from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import { formatPhoneNumber } from '../utils/commonUtils.js';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';

const CreateInvestor = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // console.log('location',location?.state);
    const [fundsDropdown,setFundsDropdown] = useState([]);
    const getMode = location.state?.mode;
    const investorid = location.state?.investorid;
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [type , setType] = useState(location?.state?.mode ? 'update' : 'add');
    const [message , setMessage] = useState('Updated Successfully');
    const [states, setStates] = useState([]);
    const stateOptions = states?.map((state) => ({
        value: state,
        label: state,
    }));

    //investorinfo
    const [investorData, setInvestorData] = useState({});
    const [investorDataCheck, setInvestorDataCheck] = useState({});
    const [investorChanged, setInvestorChanged] = useState(false);
    const [investorErrors, setInvestorErrors] = useState({});

    //bank info
    const [bankinfoData, setBankinfoData] = useState({});
    const [bankInfoErrors, setBankInfoErrors] = useState({});

    useEffect(() => {
        if(investorid){
            getSingleInvestorsApi(investorid);
        }
        getstates();
        getFundsName();
    },[]);
    useEffect(() => {
        console.log('investorData',investorData)
    },[investorData]);
    // api's 
    const getstates = async () => {
        getStates().then(function (response) {
            if (response?.result) {
               setStates(response?.result)
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };
    const getFundsName = () => {
        let type = 'fundDropdown'
        getAllFundManagmentAdmin('','',type,'')
            .then(function(response) {
                console.log('funds name', response);
                if (response?.result) {
                    setFundsDropdown(response.result?.data.map((fundoption) => ({
                        value: fundoption?.name,
                        label: fundoption?.name,
                        fundid : fundoption?.uuid,
                    })));
                    const defaultFund = response.result.data.find(fund => fund.default === true);
                    if(!investorid){
                        setInvestorData((prev) => ({
                            ...prev,
                            fundname : defaultFund?.name,
                            fundid: defaultFund?.uuid ? defaultFund?.uuid : '',
                            active : true,
                        }));
                    }
                } else {
                    setFundsDropdown([]);
                }
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };
    useEffect(() => {
        const isFormChanged =
            JSON.stringify(investorData) !== JSON.stringify(investorDataCheck);
            setInvestorChanged(isFormChanged);
        console.log(isFormChanged);
    }, [investorData, investorDataCheck]);
    const getSingleInvestorsApi = (uuid) => {
        dispatch(showLoader());
        getSingleInvestor(uuid).then(function (response) {
            if (response?.result) {
               setInvestorData(response?.result?.data?.[0]);
               setInvestorDataCheck(response?.result?.data?.[0]);
               setBankinfoData(response?.result?.data?.[0]?.bankinfo || {})
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    };

    const AddInvestorsApi = (data) => {
        dispatch(showLoader());
        addInvestor(data).then(function (response) {
            if (response?.result === 'Email Already Exists') {
                setMessage('Email Already Exists');
                setPopupOpen(true);
            }else{
                setMessage('Investor Created Successfully');
                setPopupOpen(true);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    };
    const UpdateInvestorApi = (data) => {
        dispatch(showLoader());
        const apiData = { ...data };
        delete apiData._id;
        updateInvestor(apiData,investorData?.uuid).then(function (response) {
            if (response?.result) {
                
                setPopupOpen(true);
            }
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    };


    const handleChange =(e)=>{
        let {name , value ,type} = e.target;
        if(type === 'checkbox'){
            setInvestorData((prev) => ({
                ...prev,
                [name]: e.target.checked,
            }));
        }else if(name === 'typeofreturn'){
            setInvestorData((prev) => ({
                ...prev,
                [name]: value,
                ["autodisburseguarenteedfund"]: value === 'Income' ? true : investorData?.autodisburseguarenteedfund || false,
            }));
        }
        else if(name === 'percentageofgains' || name ==='preferredinvestorreturn' || name === 'zipcode' || name === 'bankrouting' || name === 'accountnumber'){
            const newValue = parseFloat(value) || '';
            setInvestorData((prev) => ({
                ...prev,
                [name]: newValue,
            }));
        } else if (name ==='ssn'){
            const cleanedValue = value.replace(/\D/g, '');
            const formattedValue = cleanedValue.slice(0, 9);
            const formattedSSN = formattedValue.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
            setInvestorData((prev) => ({
                ...prev,
                [name]: formattedSSN,
            }));
            }
        else if(name === 'fundname'){
            const selectedFund = fundsDropdown.find((fund) => fund.value === value);
            console.log(selectedFund)
            setInvestorData((prev) => ({
                ...prev,
                [name]: value,
                fundid: selectedFund ? selectedFund?.fundid : '',
            }));
        }
        else{
            setInvestorData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const handleInvestorFocus = (e) => {
        setInvestorErrors({...investorErrors,[e.target.name] : ''});
      };
      const isValidPhoneNumber = (phoneNumber) => {
        const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        let phonetest = phoneRegex.test(phoneNumber);
        return phonetest;
      };
    const handleInvestorsSave = () => {
        console.log('investorData',investorData);
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const fieldsToCheck = { 
            // 'investorid': 'investorid','accountname': 'accountname' ,
            'firstname' : 'firstname','lastname' : 'lastname','fundname' : 'fundname',
        "email" : "email",
        // ,"ssn" : "ssn",
        //  "phone" :  "phone" ,
          "address" : "address","city": "city","state" : "state","zipcode": "zipcode",
        "typeofreturn" : "typeofreturn",
        // "accountnumber" : "accountnumber",
        // "accounttype" : "accounttype" ,
        // "bankaccount" : "bankaccount", "bankrouting" : "bankrouting",
        // "daytodisburemonthly" : "daytodisburemonthly",
        // "percentageofgains" :  "percentageofgains", 
        "preferredinvestorreturn" : "preferredinvestorreturn"
    };
        let error = {};
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = investorData[fieldName];
            if (typeof value === 'number') {
            }else{
              if (!investorData[fieldName]?.trim()) {
                  error[fieldName] = `Please Enter ${fieldLabel} `;
              }
            }
        };
        if (investorData.email) {
            if (!emailRegex.test(investorData.email)) {
                error.email = 'Email Structure Error';
            }
        }
        if(investorData?.bankrouting){
            const stringValue = investorData?.bankrouting.toString();
            if(stringValue?.length < 9){
                error.bankrouting = `Number should be 9 digit`
            }
        }
        // if (investorData.zipcode) {
        //     const zipcode = investorData.zipcode;
        //     if (!/^\d{6}$/.test(zipcode)) {
        //         error.zipcode = 'Zipcode must be a 6-digit number';
        //     }
        // }
        
        if(investorData.phone){
            if(!isValidPhoneNumber(investorData.phone)){
                error.phone = 'Please Enter Number'
            }
        }
        let apiData = { ...investorData };

        const numericInputs = ['investmentamount']
        numericInputs.forEach(key => {
            if (apiData.hasOwnProperty(key)) {
                apiData[key] = apiData[key] === '' ? '' : parseFloat(apiData[key]);
            }
        });
        setInvestorErrors(error);
        console.log('errors',error)
        if (Object.keys(error).length === 0) {
            if(investorData?.uuid){
                setMessage('Investor Updated Successfully');
                UpdateInvestorApi(apiData)
            }else{
                console.log('investorData',apiData);
                AddInvestorsApi(apiData);
            }
        }
    };

    
    const closePopup = () => {
        setPopupOpen(false);
        if(message === 'Email Already Exists'){

        }else{
            navigate('/investors');
        }
        
    };

    //bankinfo functions
    const handleBankinfoChange = (e, sub) => {
        const { name, value, type } = e.target;
        if(name === 'routingnumber' || name ==='bankrouting' || name === 'routingnumber'){
            const newValue = parseFloat(value) || 0;
            setBankinfoData((prev) => ({
                ...prev,
                [sub]: {
                    ...prev[sub], 
                    [name]: newValue
                }
            }));
        }else{
            setBankinfoData((prev) => ({
                ...prev,
                [sub]: {
                    ...prev[sub], 
                    [name]: type === 'checkbox' ? e.target.checked : value
                }
            }));
        }
       
    };
    
    const handleBankInfo = () => {
        console.log('bankinfoData', bankinfoData);
        const fieldsToValidate = {
            // info: ['bankname', 'account', 'routingnumber', 'accountname'],
            investor: [
                // 'effectivedate', 
            'bankaccount', 'bankrouting', 'accounttype'],
            // lender: ['bankaccount', 'routingnumber', 'achid','accounttype'],
            // withdrawal: ['amount', 'effectivedate']
        };
    
        let error = {
            info: {},
            investor: {},
            lender: {},
            withdrawal: {}
        };
    
        for (const section in fieldsToValidate) {
            fieldsToValidate[section].forEach((field) => {
                if (typeof bankinfoData[section]?.[field] === 'number') {
                }else{
                    if (!bankinfoData[section]?.[field]?.toString().trim()) {
                        error[section][field] = `Please enter ${field.replace(/_/g, ' ')}`;
                    }
                }
                
            });
        }
    
        setBankInfoErrors(error);
        console.log('errors', error);
    
        if (Object.keys(error.info).length === 0 && Object.keys(error.investor).length === 0 && 
            Object.keys(error.lender).length === 0 && Object.keys(error.withdrawal).length === 0) {
            let apiData = {
                bankinfo: {
                    ...bankinfoData
                }
            };
            setMessage('Bank Info Updated Successfully');
            UpdateInvestorApi(apiData);
        }
    };
    const handleBankFocus = (e,sub) => {
        const { name, value, type } = e.target;
        setBankInfoErrors((prev) => ({
            ...prev,
            [sub]: {
                ...prev[sub], 
                [name]: ''
            }
        }));

      }

      const handleKeyDown = (event) => {
        if (event.key === '.') {
          event.preventDefault();
        }
      };
      const handleInput = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]*/g, '');
      };

    return (
        <div id="layout-wrapper">
        {loading ? <Loader/> : null} 

        {isPopupOpen && (
        <PopupMessage
          type={message ==  'Email Already Exists' ? 'warning' : 'success'}
          message={message}
          onClose={closePopup}
        />
      )}

           <MainHeader />
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management create_fundmanagement">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">{investorData?.uuid ? 'Update' : 'Create'} Investor</h4>
                                <a href="#" id="navigateManagerBack" onClick={(e) => {e.preventDefault(); navigate(-1);}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body my_account">
                                <div className="form_section">
                                <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#investor_info" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">Investor Info</span>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link " data-bs-toggle="tab" href="#bank_info" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">Bank Info</span>
                                </a>
                            </li> */}

                        </ul>


                        <div className="tab-content pt-15 text-muted">
                            <div className="tab-pane active investor_info" id="investor_info" role="tabpanel">
                            
                            <div className="row">
                                                    {/* <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Investor Id</label>
                                                            <input id="investorid" name='investorid' onChange={(e)=>{handleChange(e)}} value={investorData?.investorid}  className={`form-control null ${investorErrors.investorid && 'invalid-input'}`} onFocus={handleInvestorFocus}  placeholder="Enter Investor Id" />
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Account Name</label>
                                                            <input id="accountname" name='accountname' onChange={(e)=>{handleChange(e)}} value={investorData?.accountname} className={`form-control null ${investorErrors.accountname && 'invalid-input'}`} onFocus={handleInvestorFocus}  placeholder="Enter Account Name" />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>First Name</label>
                                                            <input id="firstname" name='firstname' onChange={(e)=>{handleChange(e)}} value={investorData?.firstname || ''} className={`form-control capitalize-text  ${investorErrors.firstname && 'invalid-input'}`} onFocus={handleInvestorFocus} placeholder="Enter First Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Last Name</label>
                                                            <input id="lastname" name='lastname' onChange={(e)=>{handleChange(e)}} value={investorData?.lastname || ''} className={`form-control capitalize-text  ${investorErrors.lastname && 'invalid-input'}`} onFocus={handleInvestorFocus} placeholder="Enter Last Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Email</label>
                                                            <input id="email" name='email' onChange={(e)=>{handleChange(e)}} value={investorData?.email || ''} disabled={investorData?.uuid ? true : false} className={`form-control null ${investorErrors.email && 'invalid-input'}`} onFocus={handleInvestorFocus} placeholder="Enter Email" />
                                                        </div>
                                                    </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Fund Name</label>
                                                                <select id="fundname" name="fundname" onChange={(e) => { handleChange(e) }} value={investorData?.fundname || ''} className={`form-control null ${investorErrors.fundname && 'invalid-input'}`} onFocus={handleInvestorFocus}>
                                                                <option value="">Select</option>
                                                                    {fundsDropdown?.map((fundoption, index) => {
                                                                        return (
                                                                            <option value={fundoption?.value}>{fundoption?.label}</option>
                                                                        )

                                                                    })}
                                                                </select>
                                                            </div>
                                                            </div>
                                                    </div>
                                                    <hr className="hr"></hr>
                                                    <div className="row">
                                                    {/* <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>SSN</label>
                                                            <input id="ssn" name='ssn' onChange={(e)=>{handleChange(e)}} value={investorData?.ssn || ''} className={`form-control null ${investorErrors.ssn && 'invalid-input'}`}
                                                            pattern="\\d{4}\- \d{2}-\d{3}" placeholder="xxx-xx-xxxx" onFocus={handleInvestorFocus}  />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Phone</label>
                                                            <input id="phoneno" placeholder="(xxx) xxx-xxxx" onChange={(e)=>{handleChange(e)}} value={investorData?.phone || ''}  onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)} onFocus={handleInvestorFocus}  type='tel' name="phone" className={`form-control null ${investorErrors.phone && 'invalid-input'}`} 
                                                                    pattern="\(\d{3}\) \d{3}-\d{4}" title="Phone number must be in the format: (123) 456-7890"  />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Address</label>
                                                            <input id="address" name='address' onChange={(e)=>{handleChange(e)}} value={investorData?.address || ''} className={`form-control null ${investorErrors.address && 'invalid-input'}`} onFocus={handleInvestorFocus} placeholder="Enter Address" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>City</label>
                                                            <input id="city" name='city' onChange={(e)=>{handleChange(e)}} value={investorData?.city || ''} className={`form-control null ${investorErrors.city && 'invalid-input'}`} onFocus={handleInvestorFocus} placeholder="Enter City" />
                                                        </div>
                                                    </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>State </label>
                                                                <Select
                                                                    inputId="propertyState"
                                                                    options={stateOptions}
                                                                    placeholder="Select"
                                                                    value={investorData?.state ? stateOptions.find(option => option?.value === investorData?.state) : ''}
                                                                    className={`${investorErrors.state && 'invalid-input'}`}
                                                                    onFocus={() => { setInvestorErrors({ ...investorErrors, 'state': '' }); }}
                                                                    onChange={(selectedOption) => {
                                                                        setInvestorData((prev) => ({
                                                                            ...prev,
                                                                            state: selectedOption.value,
                                                                        }));
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Zipcode</label>
                                                            <input id="zipcode" name='zipcode' type='number' onKeyDown={handleKeyDown} onChange={(e)=>{handleChange(e)}}
                                                             onInput={(e) => { 
                                                                if(e.target.value > 100){
                                                                    e.target.value = e.target.value.slice(0, 6);
                                                                }
                                                                else if(e.target.value < 0){
                                                                    e.target.value = '';
                                                                }}}
                                                           value={investorData?.zipcode || ''} className={`form-control null ${investorErrors.zipcode && 'invalid-input'}`}  placeholder="Enter Zipcode" onFocus={handleInvestorFocus}/>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-3 interest_only">
                                                        <div className="form-group">
                                                            <label></label>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                            <label className="toggle mb-0 mt-1"><span className="toggle-label">Auto ACH</span><input id="autoach" name='autoach' onChange={(e)=>{handleChange(e)}} checked={investorData?.autoach} className="toggle-checkbox" type="checkbox" /><div className="toggle-switch"></div></label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    
                                                    </div>
                                                    <hr className="hr"></hr>
                                                    <div className="row">
                                                   <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Investment Date</label>
                                                                <input id="investment_date" type="date" className={`form-control`} placeholder="mm-dd-yyyy" name="investmentdate" onChange={(e) => handleChange(e)}  value={investorData?.investmentdate || ''} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Investment Amount</label>
                                                                <CurrencyInput
                                                         id={'investmentamount'}
                                                         name={'amount'}
                                                         placeholder="Enter Investment Amount"
                                                         value={investorData?.investmentamount}
                                                          className={`form-control`}
                                                         decimalsLimit={2}
                                                         prefix="$"
                                                         onValueChange={(value, name, values) => {
                                                            setInvestorData((prev) => ({
                                                                ...prev,
                                                                ['investmentamount']: values.value
                                                            }));
                                                         }}
                                                       />
                                                            </div>
                                                        </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Preferred Investor Return Rate</label>
                                                            <input id="preferredinvestorreturn" name='preferredinvestorreturn'
                                                            //  onChange={(e)=>{handleChange(e)}}
                                                              type='number'
                                                              onInput={(e) => {
                                                                let value = e.target.value;
                                                          
                                                                // Restrict value between 0 and 100
                                                                if (parseFloat(value) > 100) {
                                                                  value = "100";
                                                                } else if (parseFloat(value) < 0) {
                                                                  value = ""; // Clear invalid negative values
                                                                }
                                                          
                                                                // Restrict to 2 decimal places
                                                                if (value.includes(".")) {
                                                                  const [integer, decimal] = value.split(".");
                                                                  if (decimal?.length > 2) {
                                                                    value = `${integer}.${decimal.slice(0, 2)}`;
                                                                  }
                                                                }
                                                          
                                                                // Update state
                                                                handleChange({ target: { name: e.target.name, value } });
                                                              }} value={investorData?.preferredinvestorreturn} className={`form-control null ${investorErrors.preferredinvestorreturn && 'invalid-input'}`}  placeholder="Enter Investor Return Rate" onFocus={handleInvestorFocus}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Type of Return</label>
                                                            <select id="typeofreturn" name="typeofreturn" onChange={(e)=>{handleChange(e)}} value={investorData?.typeofreturn} className={`form-control null ${investorErrors.typeofreturn && 'invalid-input'}`}  onFocus={handleInvestorFocus}>
                                    <option value="">Select</option>
                                    <option value="Income">Income</option>
                                    <option value="Growth">Growth</option>
                                </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3 interest_only">
                                                        <div className="form-group">
                                                            <label></label>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                            <label className="toggle mb-0 mt-1"><span className="toggle-label">Auto Disburse Preferred Fund Monthly</span><input id="autodisburseguarenteedfund" onChange={(e)=>{handleChange(e)}} checked={investorData?.autodisburseguarenteedfund} className="toggle-checkbox" type="checkbox" name="autodisburseguarenteedfund"/><div className="toggle-switch"></div></label>
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 interest_only active">
                                                        <div className="form-group">
                                                        <label></label>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                            <label className="toggle mb-0 mt-1"><span className="toggle-label">Active</span><input id="active" onChange={(e)=>{handleChange(e)}} checked={investorData?.active} className="toggle-checkbox" type="checkbox" name="active"/><div className="toggle-switch"></div></label>
                                                            
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    </div>
                                                    <hr className="hr"></hr>
                                                    <div className='search mb-4'>
                                                        <h5 className="title mt-0 mb-0">Investor Bank Information</h5>
                                                    </div>
                                                    <div className="row">
                                                       
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                {/* <label>Bank Account</label> */}
                                                                <label>Account Holder Name</label>
                                                                
                                                                <input id="investor_bank_account" name="bankaccount"  onChange={(e)=>{handleChange(e)}} value={investorData?.bankaccount || ''} className={`form-control null ${investorErrors.bankaccount && 'invalid-input'}`} placeholder="Enter Account Holder Name" onFocus={handleInvestorFocus}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Bank Routing</label>
                                                                <input id="investor_bank_routing" name="bankrouting" onChange={(e)=>{handleChange(e)}} type='number' placeholder='Enter Bank Routing' onKeyDown={handleKeyDown} onInput={ (e) => { 
                                                                        if(e.target.value < 0){
                                                                            e.target.value = '';
                                                                           }else{
                                                                              e.target.value = e.target.value.slice(0, 9)
                                                                           }
                                                                     }} value={investorData?.bankrouting || ''} className={`form-control null ${investorErrors.bankrouting && 'invalid-input'}`}  onFocus={handleInvestorFocus}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Account Number</label>
                                                                <input id="investor_bank_number" name="accountnumber" onChange={(e)=>{handleChange(e)}} type='number'  value={investorData?.accountnumber || ''}
                                                                onKeyDown={handleKeyDown}
                                                                onInput={handleInput} className={`form-control null ${investorErrors.accountnumber && 'invalid-input'}`} placeholder="Enter Account Number" onFocus={handleInvestorFocus}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Account Type</label>
                                                            <select id="accounttype" name="accounttype" onChange={(e)=>{handleChange(e)}} value={investorData?.accounttype || ''} className={`form-control null ${investorErrors.accounttype && 'invalid-input'}`}  onFocus={handleInvestorFocus}>
                                                            <option value="">Select</option>
                                                                <option value="Checking">Checking</option>
                                                                <option value="Saving"> Saving </option>
                                                                 </select>
                                                        </div>
                                                    </div>
                                                        
                                                      
                                                       
                                                        
                                                    </div>
                                                    <div className="btn-group mt_10"><button id="createinvestor" onClick={handleInvestorsSave} style={{'opacity' : investorChanged ? 1 : 0.6}} disabled={!investorChanged}  className="fs_btn mr_10"> {investorData?.uuid ? 'Update' : 'Create'}</button></div>
                                                    
                            </div>



                                                <div className="tab-pane bank_info" id="bank_info" role="tabpanel">
                                                    {/* <div className='search mb-4'>
                                                        <h5 className="title mt-0 mb-0">Investor Bank Information</h5>
                                                    </div> */}
                                                    <div className="row">
                                                       
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Bank Account</label>
                                                                <input id="investor_bank_account" name="bankaccount" onChange={(e) => handleBankinfoChange(e, 'investor')} value={bankinfoData?.investor?.bankaccount || ''} className={`form-control  ${bankInfoErrors?.investor?.bankaccount && 'invalid-input'}`} onFocus={(e)=>{handleBankFocus(e,'investor')}} placeholder="Enter Bank Account" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Bank Routing</label>
                                                                <input id="investor_bank_routing" name="bankrouting" onChange={(e) => handleBankinfoChange(e, 'investor')} type='number' onInput={ (e) => { 
                                                                        if(e.target.value < 0){
                                                                            e.target.value = '';
                                                                           }else{
                                                                              e.target.value = e.target.value.slice(0, 9)
                                                                           }
                                                                     }} value={bankinfoData?.investor?.bankrouting || ''} className={`form-control  ${bankInfoErrors?.investor?.bankrouting && 'invalid-input'}`} placeholder="Enter Bank Routing" onFocus={(e)=>{handleBankFocus(e,'investor')}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Bank Routing</label>
                                                                <input id="investor_bank_routing" name="accountnumber" onChange={(e) => handleBankinfoChange(e, 'investor')} type='number' onInput={ (e) => { 
                                                                        if(e.target.value < 0){
                                                                            e.target.value = '';
                                                                           }else{
                                                                              e.target.value = e.target.value.slice(0, 9)
                                                                           }
                                                                     }} value={bankinfoData?.investor?.bankrouting || ''} className={`form-control  ${bankInfoErrors?.investor?.bankrouting && 'invalid-input'}`} placeholder="Enter Bank Routing" onFocus={(e)=>{handleBankFocus(e,'investor')}}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                        <div className="form-group">

                                                            <label>Account Type</label>
                                                            <select id="accounttype" name="accounttype" value={bankinfoData?.investor?.accounttype || ''}  onChange={(e) => handleBankinfoChange(e, 'investor')} className={`form-control  ${bankInfoErrors?.investor?.accounttype && 'invalid-input'}`} onFocus={(e)=>{handleBankFocus(e,'investor')}} >
                                                                <option value="">Select</option>
                                                                <option value="Checking">Checking</option>
                                                                <option value="Saving"> Saving </option>
                                                            </select>
                                                        </div>
                                                        </div>
                                                        {/* <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Effective Date</label>
                                                                <input id="effective_date" type="date" className={`form-control  ${bankInfoErrors?.investor?.effectivedate && 'invalid-input'}`} placeholder="mm-dd-yyyy" name="effectivedate" onChange={(e) => handleBankinfoChange(e, 'investor')} onFocus={(e)=>{handleBankFocus(e,'investor')}} value={bankinfoData?.investor?.effectivedate || ''} />
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Lock Out Date</label>
                                                                <input id="lockoutdate" type="date" className={`form-control  ${bankInfoErrors?.investor?.lockoutdate && 'invalid-input'}`} placeholder="mm-dd-yyyy" name="lockoutdate" onChange={(e) => handleBankinfoChange(e, 'investor')} value={bankinfoData?.investor?.lockoutdate || ''} onFocus={(e)=>{handleBankFocus(e,'investor')}} />
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-md-3 interest_only">
                                                            <div className="form-group">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <label className="toggle mb-0 mt-0">
                                                                        <span className="toggle-label">Auto Ach</span>
                                                                        <input id="autoach" className="toggle-checkbox" type="checkbox" name="autoach" onChange={(e) => handleBankinfoChange(e, 'investor')} checked={bankinfoData?.investor?.autoach || false} />
                                                                        <div className="toggle-switch"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className="col-md-3 interest_only">
                                                            <div className="form-group">
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <label className="toggle mb-0 mt-0">
                                                                        <span className="toggle-label">Add to ACH</span>
                                                                        <input id="add_to_ach" className="toggle-checkbox" type="checkbox" name="addtoach" onChange={(e) => handleBankinfoChange(e, 'investor')} checked={bankinfoData?.investor?.addtoach || false} />
                                                                        <div className="toggle-switch"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    {/* <hr className="hr" />
                                                    <div className='search mb-4'>
                                                        <h5 className="title mt-0 mb-0">Lender Bank Information</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Bank Account</label>
                                                                <input id="lender_bank_account" name="bankaccount" onChange={(e) => handleBankinfoChange(e, 'lender')} value={bankinfoData?.lender?.bankaccount || ''}  onFocus={(e)=>{handleBankFocus(e,'lender')}} className={`form-control  ${bankInfoErrors?.lender?.bankaccount  && 'invalid-input'}`} placeholder="Enter Bank Account" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Routing Number</label>
                                                                <input id="lender_routing_number" name="routingnumber" onChange={(e) => handleBankinfoChange(e, 'lender')} type='number' onInput={ (e) => { 
                                                                        if(e.target.value < 0){
                                                                            e.target.value = '';
                                                                           }else{
                                                                              e.target.value = e.target.value.slice(0, 9)
                                                                           }
                                                                     }} value={bankinfoData?.lender?.routingnumber || ''} onFocus={(e)=>{handleBankFocus(e,'lender')}} className={`form-control  ${bankInfoErrors?.lender?.routingnumber&& 'invalid-input'}`} placeholder="Enter Routing Number" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">

                                                                <label>Account Type</label>
                                                                <select id="accounttype" name="accounttype" value={bankinfoData?.lender?.accounttype || ''} onChange={(e) => handleBankinfoChange(e, 'lender')} className={`form-control  ${bankInfoErrors?.lender?.accounttype && 'invalid-input'}`} onFocus={(e) => { handleBankFocus(e, 'lender') }} >
                                                                    <option value="">Select</option>
                                                                    <option value="Checking">Checking</option>
                                                                    <option value="Saving"> Saving </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                       
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>ACH ID</label>
                                                                <input id="lender_achid" name="achid" onChange={(e) => handleBankinfoChange(e, 'lender')} onFocus={(e)=>{handleBankFocus(e,'lender')}} value={bankinfoData?.lender?.achid || ''} className={`form-control  ${bankInfoErrors?.lender?.achid && 'invalid-input'}`} placeholder="Enter ACH Id" />
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <hr className="hr" />
                                                    {/* <div className='search mb-4'>
                                                        <h5 className="title mt-0 mb-0">Withdrawal Funds From Investment</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Amount</label>
                                                                <CurrencyInput
                                                         id={'withdrawal_amount'}
                                                         name={'amount'}
                                                         placeholder="Enter Amount"
                                                         value={bankinfoData?.withdrawal?.amount}
                                                          className={`form-control  ${bankInfoErrors?.withdrawal?.amount && 'invalid-input'}`}
                                                         decimalsLimit={2}
                                                         prefix="$"
                                                         onFocus={(e)=>{handleBankFocus(e,'withdrawal')}}
                                                         onValueChange={(value, name, values) => {
                                                            setBankinfoData((prev) => ({
                                                                ...prev,
                                                                ['withdrawal']: {
                                                                    ...prev['withdrawal'], 
                                                                    ['amount']: values.value,
                                                                }
                                                            }));
                                                         }}
                                                       />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Effective Date</label>
                                                                <input id="withdrawal_effective_date" type="date" className={`form-control  ${bankInfoErrors?.withdrawal?.effectivedate && 'invalid-input'}`} placeholder="mm-dd-yyyy" name="effectivedate" onChange={(e) => handleBankinfoChange(e, 'withdrawal')}  onFocus={(e)=>{handleBankFocus(e,'withdrawal')}} value={bankinfoData?.withdrawal?.effectivedate || ''} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 interest_only">
                                                            <div className="form-group">
                                                                <label></label>
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <label className="toggle mb-0 mt-1">
                                                                        <span className="toggle-label">Is Final Payout</span>
                                                                        <input id="is_final_payout" className="toggle-checkbox" type="checkbox" name="isfinalpayout" onChange={(e) => handleBankinfoChange(e, 'withdrawal')} checked={bankinfoData?.withdrawal?.isfinalpayout || false} />
                                                                        <div className="toggle-switch"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {investorData?.uuid && 
                                                    <div className="btn-group mt_10"><button id="createbankinfo" disabled={investorData?.uuid ? false : true} onClick={handleBankInfo} className="fs_btn mr_10"> {investorData?.uuid ? 'Update' : 'Create'}</button></div>
                                                    }
                                                    
                                                </div>



                        </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default CreateInvestor;