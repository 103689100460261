import React, { useState, useEffect ,useRef} from 'react';
import DataTable from 'react-data-table-component';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate , useLocation} from 'react-router-dom';
import { getUserPermissionsAdmin , getPipelinemenus , getMenus , getRoleMenus , 
    getSingleUserPermissionsAdmin , addUserPermissionsAdmin ,updateUserPermissionsAdmin} from "./../utils/reducer";
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';   
import PopupMessage from './PopupMessage.js';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import HomeFooter from './HomeFooter';
import data from "../JsonData/data.json";
import newRoleEmptyJson from "../JsonData/newRoleEmptyJson.json";
import User from '../components/CreateUser/User';
import PipelinePermissions from '../components/CreateUser/pipelinePermission';

const UserRolesAdmin = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const propsUserData = location?.state?.data;
    console.log("props edit data",propsUserData);
    const mode = location?.state?.mode;
//    const menusdata = useSelector(state => state.menusdata);
    const[menusdata,setMenus] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const [allemails, setAllEmails] = useState([]);
    const loading = useSelector((state) => state.loaderVisible);
    const [deleteData , setDeleteData] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message , setMessage] = useState('Updated Successfully');
   
    
    const toggleData = data?.roledata[0].toggles;//toggle data
    const [role,setRole] = useState('');//id
    const [roleVal,setRoleVal] = useState('');
    const [userData,setUserData] = useState(props?.data?.permissions || []);//final data
    const [pipelineMenus, setPipelineMenus] = useState([])
    const [userDataPipeline,setUserDataPipeline] = useState(pipelineMenus);//final data
    const [sectionsdata,setsSectionsdata] = useState([]);
    const [rolepermissions,setRolepermissions] = useState([]);
    const [show,setShow] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [newRoleName,setNewRoleName] = useState('');
    const [status,setStatus] = useState('');
    const [roleDropdown,setRoleDropdown] = useState([]);
    let dropDownArr = [];

    const columns = []; 
    let column,column2;

    const trimString = (str) => {
      
        return str?.replace(/\s+/g, '').trim();
    };

    function usePrevious(value) {
        // Use a ref to store the previous value
        const ref = useRef();
      
        useEffect(() => {
          ref.current = value;
        }, [value]); // Only update the ref when the value changes
      
        // Return the current value of the ref (i.e., the previous value of the input)
        return ref.current;
      }
    const prevRole = usePrevious(role);

    useEffect(() => {
        if(propsUserData?.roleid && mode == "edit"){
            console.log('props edit',propsUserData)
            setRole(propsUserData?.roleid);
            setRoleVal(propsUserData?.type);
            setStatus(propsUserData?.status);
            setUserData(propsUserData?.permissions);
            setRolepermissions(propsUserData?.data);
            setUserDataPipeline(propsUserData?.pipelinepermissions);
           
            setIsEdit(true);
        }
        
    },[propsUserData?.roleid])

    useEffect(() => {
        
        if (mode === "add") {
            console.log("Mode in useEffect:", mode);

            dispatch(showLoader());
            setStatus('INACTIVE');
            //setNewRoleName("New Role");
            if(!roleVal){
                setRoleVal("New Role");
            }
            
            console.log("new role empty json",newRoleEmptyJson)
            setUserData(newRoleEmptyJson?.permissions);
            setRolepermissions(newRoleEmptyJson);
            setUserDataPipeline(newRoleEmptyJson?.pipelinepermissions);
            setIsEdit(true);
            dispatch(hideLoader());
        }
    }, [mode]);

  

    useEffect(() => {
        if(menusdata?.length == 0){
            getMenus().then(function (response) {
                if (response?.statusCode == 200) {
                    setMenus(response?.result);
                    
                }
                }).catch((err) => {
                  console.error.bind("my account error", err);
                })
        }
        else if(menusdata?.length > 0){
            const permissions = menusdata.map(menu => {
               
    
                return menu;
            });
            console.log('permissionsheree',permissions);
            setsSectionsdata(permissions);
            if(userData?.length == 0){
                setUserData(permissions)
            }
            
           
        }
        }, [menusdata]);
        useEffect(() => {
            getPipelinemenus().then(function (response) {
                if (response?.statusCode == 200 && response?.result) {
                    setPipelineMenus(response?.result);
                    if(userDataPipeline?.length == 0){
                        //setUserDataPipeline(response?.result)
                    }
                }
                }).catch((err) => {
                  console.error.bind("getPipelinemenus error", err);
            })
        }, []);

        // const getSingleUser= () => {
        //     getSingleUserPermissionsAdmin(role).then(function (response) {
        //         console.log('get single role res',response?.result)
        //         if (response?.statusCode == 200 && response?.result?.length !== 0) {
        //                 let rolePremissions = response?.result?.[0] || [];
        //              //if (role !== prevRole && prevRole) {
        //                 setUserData(rolePremissions?.permissions)
        //                 setRolepermissions(rolePremissions)
        //                 setUserDataPipeline(rolePremissions?.pipelinepermissions);
        //                 setStatus(rolePremissions?.status);
        //                 setIsEdit(true);
        //                 //setRoleVal(rolePremissions?.type);
        //              //}
        //         }else{
        //             setIsEdit(false);
        //         }
        //         }).catch((err) => {
        //           console.error.bind("my account error", err);
        //         })
            
             
         
        // }

        

        useEffect(() => {
            if(menusdata?.length > 0 && role){
                //getSingleUser();
            }
        }, [role]);

    //    useEffect(() => {
    //         dispatch(showLoader());
    //         getUserPermissionsAdmin().then(function (response) {
    //             if (response?.result) {
    //                 console.log('get all User Permissions Admin', response?.result);
    //                 let res = response?.result;
    //                 const transformedResult2 = res?.map(item => ({
    //                     roleid: item.roleid,
    //                     label: item.type,
    //                 }));
    //                 console.log(transformedResult2);
    //                 setRoleDropdown(transformedResult2);
                    
    //                 //setRole(response?.result[4]?.type);
    //                 // setUserData(response?.result[4]?.permission);
    //                 // setUserDataPipeline(response?.result[4]?.pipelinePermissions);
                    
    //                 //setRole(response?.result[4]?.role);
    //                 //setStatus(response?.result[4]?.status);
    //             }
    //             dispatch(hideLoader());
    //         }).catch((err) => {
    //             dispatch(hideLoader());
    //             console.error.bind("getall User Permissions Admin", err);
    //         })
    //    }, [role]);


    // const getSingleUserPermissionsApi = (val) => { 
    //     dispatch(showLoader());
    //     getSingleUserPermissionsAdmin(val).then(function (response) {
    //         if (response?.result) {
    //             console.log('get single User Permissions Admin', response?.result);
    //             // setSecurityInfo(response?.result?.securityinfo);
    //             setUserData(response?.result?.securityinfo?.permission);
    //         }
    //         dispatch(hideLoader());
    //     }).catch((err) => {
    //         dispatch(hideLoader());
    //         console.error.bind("ge single tUserPermissionsAdmin", err);
    //     })
    // }


    //roles functioality

    const handleRole = (e) => {
        const {name , value} = e.target;
        console.log(name , value);
        if(value !== ""){
            setRoleVal(value);//setting role id
            // roleDropdown?.map((item,index) => {
            //     if(item?.roleid === value){
            //         //console.log(''roleid',item.roleid);
            //         setRoleVal(item?.label);
            //     }
            // })
        }else{
            setRoleVal('');
            setRole('');
            setStatus('INACTIVE');
        }
        
        
       
        //setUserData(prevState => ({...prevState, securityInfo: {...prevState.securityInfo, role: e.target.value},}));
    }

    const handleSetCreateUser = (data) => {
        // console.log(data);
        setUserData(data);
    }

    const handleSetCreateUser2 = (data) => {
        // console.log(data);
        setUserDataPipeline(data);
    }

    const handleSaveRole = () => {
        if((mode == "edit" && roleVal == "") || (mode == "add" && !newRoleName)){
            setPopupOpen(true);
            setMessage("Please Enter the Role Name");
            // return true;
        }else if(mode == "edit"){
            // console.log('final user data',userData);
            // console.log('final sections data',sectionsdata);
           
            //let jsonData = (userData?.length !== 0) ? userData : sectionsdata;
                // Create the formatted data
            let formattedData = {
                "type": roleVal,
                "appname": "easystreetcapitaldev",
                "roleid": role,
                "status": status,
                "permissions": userData,
                "pipelinepermissions": userDataPipeline
            };
            console.log('final edit jsonData',formattedData);
            dispatch(showLoader());
            updateUserPermissionsAdmin(formattedData,role).then(function (response) {
                if (response?.result) {
                    console.log('get single User Permissions Admin', response?.result);
                    // setSecurityInfo(response?.result?.securityinfo);
                    //setUserData(response?.result?.securityinfo?.permission);
                    //getSingleUser();
                    // setRole(response?.result);//setting id
                    // setRoleVal(newRoleName);
                    // setUserData(updatedRoleJson?.permissions)
                    // setRolepermissions(updatedRoleJson)
                    // setUserDataPipeline(updatedRoleJson?.pipelinepermissions);
                    setIsEdit(true);
                    setPopupOpen(true);
                    setMessage('Updated Successfully');
                    
                }
                dispatch(hideLoader());
            }).catch((err) => {
                dispatch(hideLoader());
                console.error.bind("ge single tUserPermissionsAdmin", err);
            })
        }else if(mode == "add"){
            if(newRoleName){
                console.log('role drodpwona',roleDropdown);
                //check value is matching tothe above label 
                const isRoleNameExists = roleDropdown.some(role => role.label?.toLowerCase() === newRoleName?.toLowerCase());
    
                // if (isRoleNameExists) {
                //     dispatch(hideLoader());
                //     setPopupOpen(true);
                //     setMessage('Role Name already exists.');
                // }else{
                    // Add the new role name to the JSON object
                    let formattedData = {
                        "type": newRoleName,
                        "appname": "easystreetcapitaldev",
                        //"roleid": role,
                        "status": status,
                        "permissions": userData,
                        "pipelinepermissions": userDataPipeline
                    };
                    console.log('final add jsonData',formattedData);

                    dispatch(showLoader());
                    addUserPermissionsAdmin(formattedData).then(function (response) {
                        if (response?.result != "Role type already exists") {
                            console.log('get single User Permissions Admin', response?.result);
                            // setSecurityInfo(response?.result?.securityinfo);
                            //setUserData(response?.result?.securityinfo?.permission);
                            //getSingleUser();
                            setRole(response?.result);//setting id
                            setRoleVal(newRoleName);
                            // setStatus('ACTIVE');
                            // setUserData(updatedRoleJson?.permissions)
                            // setRolepermissions(updatedRoleJson)
                            // setUserDataPipeline(updatedRoleJson?.pipelinepermissions);
                            setIsEdit(true);
                            setPopupOpen(true);
                            setMessage('New Role Name Added Successfully');
                            //(true);
                        }else{
                            //dispatch(hideLoader());
                            setPopupOpen(true);
                            setMessage('Role Name already exists.');
                        }
                        dispatch(hideLoader());
                    }).catch((err) => {
                        dispatch(hideLoader());
                        console.error.bind("ge single tUserPermissionsAdmin", err);
                    })
                //}

            }
            
            
        }
        
        
    }

    const handleNewRole = (e) =>{
        const {name , value} = e.target;
        setRoleVal(value);
        setNewRoleName(value);
        

    

    }

    // const handleAddNewRole = () => {
    //     if(!newRoleName){
    //         dispatch(hideLoader());
    //         setPopupOpen(true);
    //         setMessage('New Role Name is Required!.');
    //         dispatch(hideLoader());
    //     }else if(status == 'INACTIVE'){
    //         dispatch(hideLoader());
    //         setPopupOpen(true);
    //         setMessage('Select status as ACTIVE.');
    //         dispatch(hideLoader());
    //     }else if(newRoleName){
    //         console.log('role drodpwona',roleDropdown);
    //         //check value is matching tothe above label 
    //         const isRoleNameExists = roleDropdown.some(role => role.label.toLowerCase() === newRoleName.toLowerCase());

    //         if (isRoleNameExists) {
    //             dispatch(hideLoader());
    //             setPopupOpen(true);
    //             setMessage('Role Name already exists.');
    //         }
    //         else{
                                        
    //             dispatch(showLoader());
    //             setPopupOpen(true);
    //             setMessage('New Role Name Added Succesfully!.');
    //             setRoleVal(newRoleName);
    //             // const updatedRoleJson = {
    //             //     ...newRoleEmptyJson,
    //             //     type: newRoleName , // Set the type value to the new role name
    //             //     status : 'ACTIVE'
    //             // };
                
                
              
    //             setStatus('ACTIVE');
    //             setUserData(newRoleEmptyJson?.permissions);
    //             setRolepermissions(newRoleEmptyJson);
    //             setUserDataPipeline(newRoleEmptyJson?.pipelinepermissions);
    //             setIsEdit(true);
    //             dispatch(hideLoader());
               

    //         }
    //     }
       
    // }

    const handleStatus = (e) =>{
        setStatus(e.target.value);
        //props.setFinalData(prevState => ({...prevState, securityInfo: {...prevState.securityInfo, status: e.target.value},}));
    }

   

    const closePopup = () => {
        setPopupOpen(false);
        console.log(message)
        if(message === "Updated Successfully" || message === "New Role Name Added Successfully"){
            navigate('/userroles');
        }
    };

  

  return (
    <div id="layout-wrapper">
      {loading ? <Loader /> : null}

      {isPopupOpen && (
                <PopupMessage
                    type={message == 'New Role Name is Required!.' || message == "Please Select Role" ||
                        message == 'Select status as ACTIVE.' || message == 'Role Name already exists.' ||
                        message == 'Please Enter the Role Name' ? 'warning' : 'success'}
                    message={message}
                    onClose={closePopup}
                />
            )}

       

      
      <MainHeader />
      <SideMenu />

        <div className="main-content">
            <div className="page-content user_management">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">User Roles</h4>
                            <a id="navigateUserRole" href="#" onClick={(e) => {e.preventDefault(); navigate(-1);}} className="back_btn">
                                <span className="material-icons icon"> arrow_back</span>BACK
                            </a>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="form_section">
                                        <div className='row'>
                                            {mode == 'edit' && <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label>Role</label>

                                                    {/* {/* <select id="selectRole" className="form-control" onChange={handleRole} value={role}>
                                                            <option value="">Select</option>
                                                            {roleDropdown?.map((rolesMap,rolesIndex) => (   
                                                                <option key={rolesIndex} value={rolesMap?.roleid}>{rolesMap?.label}</option>
                                                            
                                                                
                                                            ))}
                                                            
                                                    </select> */}
                                                    <input id = "selectRole" style={{ backgroundColor : '#ECF0FA'}} name='type' type="text" placeholder="Enter the Role Name" className={`form-control`} onChange={handleRole} value={roleVal} readOnly/>
                                                </div> 
                                            </div>}
                                            {mode == 'add' && <div className='col-md-4'>
                                                <div className="form-group">
                                                    <label>New Role Name</label>
                                                    <div className='d-flex'>
                                                        <input id = "addRole" name='newrole' type="text" placeholder="Enter the Role Name" className={`form-control`} onChange={handleNewRole} value={newRoleName}/>
                                                        {/* <button id = "addRoleBtn" className="fs_btn ms-4" onClick={handleAddNewRole}>Add</button> */}
                                                    </div>
                                                    
                                                </div>
                                            </div>}
                                            <div className='col-md-2'>
                                            {data?.roledata?.map((rolesMap, rolesIndex) => {
                                                if (rolesMap.status) {
                                                    return (
                                                        <div className="form-group" key={rolesIndex}>
                                                            <label>{rolesMap.status.label}</label>
                                                            <select 
                                                                id="selectStatus" 
                                                                className="form-control form-select" 
                                                                value={status} 
                                                                onChange={handleStatus}
                                                            >
                                                                {rolesMap.status.options.map((statusOptions, optionIndex) => (
                                                                    <option key={optionIndex} value={statusOptions.value}>
                                                                        {statusOptions.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    );
                                                }
                                                return null; // return null if there is no status in the current rolesMap
                                            })}
                                            </div>
                                            
                                        
                                            

                                        </div> 
                                    </div>            
                                    
                                            {/* <div className="form-group mr-0" key={rolesIndex}>
                                                <label>{rolesMap.status.label}</label>
                                                <select id="selectStatus" className="form-control width-inherit" value={status} onChange={handleStatus}>
                                                    {rolesMap.status.options.map((statusOptions,optionIndex) => (
                                                        <option key={optionIndex} value={statusOptions.value}>{statusOptions.label}</option>
                                                    ))}
                                                    
                                                    
                                                </select>
                                            </div> */}
                                        

                                       
                                    <div className='section roles_block'>

                                        <div className='row'>
                                            <div className="roles col-md-6">
                                                <User rolepermissions={rolepermissions} sectiondata={sectionsdata} roleName={roleVal} childUserData={handleSetCreateUser} userData={userData} isEdit={isEdit}/>
                                            
                                            </div>
                                            <div className="roles col-md-6">
                                                    <PipelinePermissions pipelineMenus={pipelineMenus} rolepermissions={rolepermissions} sectiondata={sectionsdata} roleName={roleVal} childUserData={handleSetCreateUser2} userData={userDataPipeline} isEdit={isEdit}/>
                                            
                                            </div>
                                        

                                        </div>
                                        <div className="btn-group mt_30">
                                            {/* <button id="cancelAdmin" onClick={(e) => {props.handleBack()}} className="border_btn mr_10">Cancel</button> */}
                                            <button id="submitAdmin" className="fs_btn" onClick={(e) => {handleSaveRole()}}>{(mode == 'edit') ? 'Update' : 'Save'}</button>
                                        </div>
                                        {/* {alert && <SweetAlert
                                        warning
                                        title="Select Role is Empty"
                                        onConfirm={handleAlertClose}
                                        >
                                        Display your validation error message
                                        Please select role 
                                        </SweetAlert>} */}
                                    </div>
                                    
                                </div>
                            </div>
                        
                        </div>
                    </div>
                  
                    
                </div>
               
            </div>
           

            <HomeFooter />
        </div>
    </div>
  );
};

export default UserRolesAdmin;