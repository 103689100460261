import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import EditorEdit from './EditorEdit.js';
import {Appname, APIUrl,getNeeds,addNeeds,updateNeed,addNeedNote,DeleteNeedNote} from '../utils/reducer';
import PopupMessage from './PopupMessage';
import SweetAlert from 'react-bootstrap-sweetalert';  
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import Loader from './Loader';
import { useLoanInfo } from '../utils/loanInfoContext';
import axios from "axios";
import { useDispatch,useSelector} from 'react-redux';
import { getUserDataFromLocalStorage } from "./../utils/local";
import moment from 'moment';
import FileViewer from './DocViewer';
const Needs = () => {
    const dispatch = useDispatch();
    const { loanInfo } = useLoanInfo();
    const navigate = useNavigate();
    const getUserData = getUserDataFromLocalStorage();
    console.log('getUserData',getUserData.emailid);
    let loanid = loanInfo?.loanid;
    // 
     const visibilityOptions = ['Borrower', 'QC', 'Processor', 'Ops', 'Loan Officer', 'Servicer', 'UW', 'Secondary'];
     const halfIndex = Math.ceil(visibilityOptions.length / 2);
    const [showAddform, setShowAddform] = useState(false);
    const [editorValidate,setEditorValidate] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const configdata = useSelector(state => state.configdata);
    const [allNeeds,setAllNeeds] = useState([]);
    const loading = useSelector((state) => state.loaderVisible);
    const [selectedStatus , setSelectedStatus] = useState({
    });
    const [note , setNote] = useState({
    });
    const [noteError,setNoteError] = useState(false);
    const [ viewNote, setViewNote] = useState('');
    const [message,setMessage] = useState('');
    const [popUpMsg,setPopUpMsg] = useState(false);
    const [statusConfirmation , setStatusConfirmation] = useState(false);
    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const [deleteNote,setDeleteNote] = useState({});
    const [deleteIndex,setDeleteIndex] = useState('');
    const [deleteFileUpload,setDeleteFileUpload] = useState(false);
    const [previouslyUpdated,setPreviouslyUpdated] = useState([]);
    let uploadFilesData = {
      file : '',
      filecode : ''
    };
    const [deleteFileId ,setDeleteFileId] = useState({
    });
    const [deleteFileConfirmation , setDeleteFileConfirmation] = useState(false);
    const [formData, setFormData] = useState({
      duedate: '',
      internaldescription: '',
      needtype: '',
      documentname: '',
    });
    const [showDoc, setshowDoc] = useState(false);
    const [type, setType] = useState(false);
    const [playContent, setPlayContent] = useState({});
    const [fileDocName, setFileDocName] = useState("");
    const [filesData, setFilesData] = useState([]);
    const [selectedNeed , setSelectedNeed] = useState({});
    const [viewDisplay,setViewDisplay] = useState(false);
    const [errors, setErrors] = useState({
      duedate: false,
      internaldescription: false,
      needtype: false,
      documentname: false
    });

    const [textValue,setText] = useState('');
    const [heading, setHeading] = useState('');
    let userData = getUserDataFromLocalStorage();
    console.log('userData',userData);
    useEffect(() => {
      console.log(formData);
    }, [formData])
    
    // useEffect(() => {
    //     setFormData({
    //         ...formData,
    //         ['selectedFiles']: selectedFiles
    //       });
      
    //   }, [selectedFiles]);

  
      
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };
    console.log('loanInfo?.status',loanInfo?.status);
    useEffect(() => {
        GetNeedsData();
    }, [loanInfo?.loanid,loanInfo?.status])
    console.log(uploadFilesData);
   
    const GetNeedsData = ()=>{
        dispatch(showLoader());
        getNeeds(loanInfo?.loanid)
        .then(function(response) {
            if (response) {
                console.log('response', response);
                setAllNeeds(response?.result);
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const AddNeedApi =()=>{
        let data = {...formData,...uploadFilesData ,loanid : loanInfo?.loanid,status : 'Outstanding'}
        // dispatch(showLoader());
        console.log('data------>',data)
        addNeeds(data,loanInfo?.loanid)
        .then(function(response) {
            if (response) {
                console.log('response', response);
                dispatch(hideLoader());
                setMessage('Need Added Successfully');
                GetNeedsData();
                setPopUpMsg(true);
                setShowAddform(!showAddform);
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const updateNeedApi =(dataToApi)=>{
        let data = {...dataToApi ,loanid : loanInfo?.loanid}
        dispatch(showLoader());
        updateNeed(data,loanInfo?.loanid)
        .then(function(response) {
            if (response) {
                console.log('response', response);
                dispatch(hideLoader());
                GetNeedsData();
                setPopUpMsg(true);
                setViewDisplay(false);
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const AddNeedNote =(note)=>{
        let data = {...note,loanid : loanInfo?.loanid}
        dispatch(showLoader());
        addNeedNote(data,loanInfo?.loanid)
        .then(function(response) {
            if (response) {
                console.log('response', response);
                setMessage('Note Added Successfully');
                setNote({});
                setPopUpMsg(true);
                GetNeedsData();
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const deleteNeedNote =()=>{
        dispatch(showLoader());
        DeleteNeedNote(deleteNote,loanInfo?.loanid)
        .then(function(response) {
            if (response) {
                console.log('response', response);
                setMessage('Note Deleted Successfully');
                GetNeedsData();
                setPopUpMsg(true);
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
    };
    const getStatusClass = (status) => {
  switch (status) {
    case 'Completed':
      return 'green';
    case 'Outstanding':
      return 'orange';
    case 'Underreview':
      return 'blue';
    case 'n/a':
      return 'grey';
    case 'In Progress':
      return 'inprogress';
    default:
      return '';
  }
    };
    const addNew = () => {
        setShowAddform(!showAddform);
        uploadFilesData['file'] = '';
        uploadFilesData['filecode']= '';
        setFormData({
      duedate: '',
      internaldescription: '',
      needtype: '',
      documentname: '',
      selectedFiles: [],
      file : '',
      filecode : ''
    });
  setErrors({
      duedate: false,
      internaldescription: false,
      needtype: false,
      documentname: false
    });
    setSelectedFiles([]);
    };
    const handleStatusChange = (e, data) => {
      let status = e.target.value;
      console.log(status);
  
      let updatedStatus = {
          "status": status,
          "needstatus": status,
          "needid": data?.needid,
      };
  
      if (status === "Completed") {
          updatedStatus = {
              ...updatedStatus,
              "completedby": userData?.firstname + " " + userData?.lastname,
              "completedbyid": userData?.userId,
              "completeddate": new Date().toISOString(), // Store current date in ISO format
          };
      }
  
      setSelectedStatus(updatedStatus);
      setStatusConfirmation(true);
  };
    const handleSelectUpdate = ()=>{
        setMessage('Updated Successfully');
        updateNeedApi(selectedStatus);
        setStatusConfirmation(false);
    };
    const handleAddNoteClick = (data)=>{
      setNoteError(false)
        setNote({
        'notes' : '',
         "needid" : data?.needid
        })
    };
    const handleAddNote = ()=>{
     let error = false;
     console.log(note)
    if(!note?.notes?.trim()){
      error = true;
    }
    setNoteError(error)
    if(!error){
        AddNeedNote(note);
        document.getElementById('addClose').click();
    }
    }
    const handleDeleteNoteClick =(data)=>{
        setDeleteConfirmation(true);
        setDeleteNote(data);
        console.log(data);
    }
    const handleDeleteNote =()=>{
        setDeleteConfirmation(false);
        deleteNeedNote();
    }
    const handleDelete = (index) => {
      setDeleteFileUpload(false)
        const newFiles = [...selectedFiles];
        newFiles.splice(deleteFileId, 1);
        setSelectedFiles(newFiles);
    };

     // Function to truncate file name if it exceeds a certain length
  const truncateFileName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '...';
    }
    return name;
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (value.trim() !== '') {
        setErrors({
          ...errors,
          [name]: false
        });
      }
  };

  const handleDeleteFile = (index,file,data)=>{
    console.log('dataaaaa',data,index,file);
    if(!file?.file){
      console.log('fileeeee',file);
    }else{
      let updatedFiles = [...data?.files];
  updatedFiles.splice(index, 1);

  const updatedData = {
    ...data,
    files: updatedFiles,
  };
  console.log('updateddata',updatedData);
      let needid = data?.needid;
      setDeleteFileId({
        ...deleteFileId,
        needid : needid,
        files : updatedFiles,
      });
      setDeleteFileConfirmation(true);
    };

  };
  const handleDeleteUpdate = ()=>{
    setMessage('Deleted Successfully');
    updateNeedApi(deleteFileId);
    setDeleteFileConfirmation(false);
};

  const handleFileChange2 = async (data, event) => {
    setPreviouslyUpdated(data?.files || []);
    setMessage('File Uploaded Successfully');
    const file = event.target.files[0];
    console.log(file);
    if(file){
      dispatch(showLoader());
      handleUpload(file)
                .then(() => {
                    let needid = data?.needid;
                    console.log(uploadFilesData);

                    let filesArray = [...(data?.files || []), uploadFilesData];
                    let apiData = { needid: needid, files : filesArray };
                    updateNeedApi(apiData);
                })
                .catch(error => {
                    // Handle error if upload fails
                    console.error('Error uploading file:', error);
                });
    }
    
  };
  const handleViewOpen = (e, content) => {
    const fileType = content?.file?.split('.').pop();
    let source;
    // let type = content?.filetype?.split("/")
    // if(type[0]==="image"){
    //     source = configdata?.common?.awsImgix  + content.filepath;
    // }else{
    //     source = configdata?.common?.resourcesCloudfront  + content.filepath;
    // };
    source = configdata?.common?.resourcesCloudfront  + content.file;
    setFileDocName(content?.filename)
    setPlayContent(source);
    setshowDoc(true);
    setType(fileType);
    setViewDisplay(false);
    // console.log("playercontent==========>", source)
};

const handleView =(data)=>{
  if(data?.files?.length > 0){
    setViewDisplay(true);
    setSelectedNeed(data);
    setFilesData(data?.files);
  }

}

  const handleSubmit = async(event) => {
    // dispatch(showLoader())
    event.preventDefault();
    // Validation logic for required fields
    const errors = {};
    let hasErrors = false;
    const requiredFields = [ 
      // 'internaldescription',
     'needtype', 'documentname',
    //  'needdescription'
    ];
    requiredFields.forEach(field => {
      if (!formData[field]) {
        errors[field] = true;
        hasErrors = true;
      }
    });
    console.log('errors',errors);
    setErrors(errors);
    if (!hasErrors) {
      // Submit the form data
      console.log('Form data submitted:', formData);
      dispatch(showLoader())
      try {
        await Promise.all(selectedFiles.map((file, index) => handleUpload(file, index)));
        
        // Once all files are uploaded, submit the form data
        AddNeedApi();
        console.log('Form data submitted:', formData);
        // You can add your form submission logic here
    } catch (error) {
        console.error('Error uploading files:', error);
    }

    } 
  };
  const handleUpload = async (file, index) => {
    try {

        var bucket;
        const timestamp = Date.now();
        let format = file?.name?.split(".");
        var fileformat = format[format.length - 1];
        bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
        var ObjectID = require("bson-objectid");
        var filecode = new ObjectID().toString(); 
        let uploadFilePath = Appname + "/needs/" + filecode + "/" + timestamp + "." + fileformat;
        var data = { source_bucket: bucket, sourcepath: uploadFilePath };
        const response = await axios.post(APIUrl + 'uploadURL?appname=' + Appname, data, { type: 'application/json' });
        if (response?.data && response?.data?.result) {
          uploadFilesData['file'] = uploadFilePath;
          uploadFilesData['filecode'] = filecode;
          uploadFilesData['filename'] = file?.name;
            var url = response?.data?.result;
            await axios.put(url, file, {
                "headers": {
                    "Content-Type": "multipart/form-data",
                    "Accept": "/",
                    "Cache-Control": "no-cache",
                    "Accept-Encoding": "gzip, deflate",
                    "Connection": "keep-alive",
                    "cache-control": "no-cache"
                }
            });
            const updatedFiles = [...selectedFiles];
            updatedFiles[index]['path'] = uploadFilePath;
            console.log("updatedFiles",updatedFiles,filecode)
            // setFormData({
            //     ...formData,
            //     selectedFiles: updatedFiles
            // });
        }
    } catch (error) {
        console.error('Error uploading file:', error);
    }
 };
const [noteJSON, setNoteJSON] = useState({ visibleTo: [] });

const updateNoteJSON = (event) => {
    const element = event.target;
    const name = element.getAttribute('name');
    const value = element.type === 'checkbox' ? element.checked : element.value;

    if (name === 'visibleTo') {
        const option = element.value;
        const isChecked = element.checked;
        setNoteJSON(prevNoteJSON => {
            if (isChecked) {
                return { ...prevNoteJSON, [name]: [...prevNoteJSON[name], option] };
            } else {
                return { ...prevNoteJSON, [name]: prevNoteJSON[name].filter(item => item !== option) };
            }
        });
    } else {
        setNoteJSON(prevNoteJSON => ({
            ...prevNoteJSON,
            [name]: value
        }));
    }
};

    const handleReset = (e)=>{
      e.preventDefault();
      setSelectedFiles([]);
      setFormData({
        duedate: '',
        internaldescription: '',
        needtype: '',
        documentname: '',
        selectedFiles: [],
        file : '',
        filecode : ''
      });
      setErrors({
        duedate: false,
        internaldescription: false,
        needtype: false,
        documentname: false
      });
    }

    const addNote = () => {
        console.log(noteJSON);
        // Reset JSON object for next note
       // setNoteJSON({});
    };

    const closePopup = ()=>{
        setStatusConfirmation(false);
        setDeleteConfirmation(false);
        setDeleteFileConfirmation(false);
        setDeleteFileUpload(false);
    };
    const closePopup2  =()=>{
        setPopUpMsg(false);
    }
    let maxLength = 30;

    const ReusableSavedDoc = ({files,deleteType}) => {
      return (
          <>
              {
                  files?.length > 0 ?
                  files?.map((file, index) => {
                      const filePath = file.file || "";
                      const isheic = filePath.match(/\.(heic|HEIC)$/i);
                      const isImage = filePath.match(/\.(jpeg|jpg|gif|png|bmp)$/i);
                      const isPDF = filePath.match(/\.pdf$/i);
                      const isDoc = filePath.match(/\.(doc|docx)$/i);
                      const isVideo = filePath.match(/\.(mp4|mov|avi|wmv)$/i);
                      const isExcel = filePath.match(/\.(xls|xlsx)$/i);
                      
                      let iconSrc = "assets/images/icons/document.png";
                      
                      if (isImage) iconSrc = configdata?.common?.resourcesCloudfront + file.file;
                      else if (isPDF) iconSrc = "assets/images/icons/png.png";
                      else if (isDoc) iconSrc = "assets/images/icons/word.png";
                      else if (isVideo) iconSrc = "assets/images/icons/videoclip.png";
                      else if (isExcel) iconSrc = "assets/images/icons/xls.png";
                      else if(isheic) iconSrc = "assets/images/icons/heic.png";

                          return (
                              <div className="item" key={index}>
                                  <div className="doc">
                                      <img
                                         src={iconSrc}
                                          alt={`Uploaded document ${index}`}
                                          className="img"
                                      />
                                      <div className="action_buttons">
                                     <span id={`view-btn-saved-${index}`} className="material-icons" style={{ cursor: "pointer" }}
                                          onClick={(e) => handleViewOpen(e, file)}> visibility </span>
                                     <span  style={{ cursor: "pointer" }} className="material-icons">
                                          <a id={`download-btn-${index}`} href={configdata?.common?.resourcesCloudfront + file.file} download={file?.filename} style={{ textDecoration: 'none', color: 'inherit' }}>
                                          file_download
                                          </a>
                                      </span>
                                      <span
                                       id={`delete-btn-${index}`}
                                          style={{ cursor: "pointer" }}
                                          className="material-icons"
                                          onClick={(e) => handleDeleteFile(index,file,selectedNeed)}
                                      >
                                          delete
                                      </span>
                                      </div>
                                  </div>
                                  <div className="date">
                                      <p>{file?.filename}</p>
                                  </div>
                              </div>
                          );
                      }) : ''
              }
          </>
      )
  };

    return (
        <div id="layout-wrapper" className="dashboard">
            {popUpMsg && (
            <PopupMessage
             type={'success'}
             message={message}
             onClose={closePopup2}
            />
        )}
        {statusConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes"
                cancelButtonText="No, cancel!"
                title="Are you sure? "
                onConfirm={handleSelectUpdate}
                onCancel={closePopup}
                focusCancel={true}
            >
                    <p>Do you really want to Change Status?</p>
            </SweetAlert>}
            {deleteFileConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes Delete It"
                cancelButtonText="No, cancel!"
                title="Are you sure? "
                onConfirm={handleDeleteUpdate}
                onCancel={closePopup}
                focusCancel={true}
            >
                    <p>Do you really want to Delete This File?</p>
            </SweetAlert>}
            {deleteConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes Delete It"
                cancelButtonText="No, cancel!"
                title="Are you sure? "
                onConfirm={handleDeleteNote}
                onCancel={closePopup}
                focusCancel={true}
            >
                    <p>Do you really want to Delete This Note?</p>
            </SweetAlert>}
            {deleteFileUpload && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes Delete It"
                cancelButtonText="No, cancel!"
                title="Are you sure? "
                onConfirm={handleDelete}
                onCancel={closePopup}
                focusCancel={true}
            >
                    <p>Do you really want to Delete This File?</p>
            </SweetAlert>}
            {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} name={fileDocName} />}
             {loading ? <Loader/> : null} 
             <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
             
                <h2 className="form_title"> <span className="sub">Needs</span> </h2>
                
            
                                   
                               
                <div className="card task_block needs">
                {!showAddform ? 
                
                <div className="card-body services_form">
                        <div className="search ">
                            <h3 className="title">NeedS SUMMARY</h3>
                            <button type="button" className="create_btn" onClick={addNew}>Add<span
                                    className="material-symbols-outlined">Add</span></button>
                        </div>
                        {allNeeds?.length >0 && 
                        <div className="task_hed">
                        <div className="clmn">Need Type</div>
                        <div className="clmn">Document Name </div>
                        <div className="clmn"> Internal dESCRIPTION</div>
                        <div className="clmn">Need dESCRIPTION </div>
                        <div className="clmn">status</div>
                        {/* <div className="clmn">dUE dATE</div> */}
                        <div className="clmn">cOMPLeted bY</div>
                        <div className="clmn">Docs</div>
                       <div className="clmn">nOTES </div>
                      </div> }
                        
                        {allNeeds?.map((data,index)=>{
                        const statusClass = getStatusClass(data?.needstatus  || data?.status);
                        const parser = new DOMParser();
                            return(
                                <>
                               <div className={`task_item ${statusClass}`}>
                            <div className="clmn"><span>{data?.needtype}</span></div>
                            <div className="clmn"><span>{data?.documentname}</span></div>
                                  <div className="clmn">     <span
                                    style={{ cursor: "pointer" }}
                                    data-bs-toggle="modal"
                                    onClick={() => {
                                      setText(data?.internaldescription);
                                      setHeading('INTERNAL DESCRIPTION')
                                    }}
                                    data-bs-target="#viewdesc"
                                    data-bs-whatever="@mdo"
                                  >
                                    {data?.internaldescription?.length > maxLength ? data?.internaldescription?.substring(0, maxLength) + "..." : data?.internaldescription}
                                  </span></div>


                                  <div className="clmn">     <span
                                    style={{ cursor: "pointer" }}
                                    data-bs-toggle="modal"
                                    onClick={() => {
                                      setText(data?.needdescription);
                                      setHeading('NEED DESCRIPTION')
                                    }}
                                    data-bs-target="#viewdesc"
                                    data-bs-whatever="@mdo"
                                  >
                                    {data?.needdescription?.length > maxLength ? data?.needdescription.substring(0, maxLength) + "..." : data?.needdescription}
                                  </span></div>
                            {/* <div className="clmn">{data?.needdescription}</div> */}
                                <div className="clmn"><select value={data?.needstatus || data?.status} onChange={(e)=>{handleStatusChange(e,data)}} name="channel" className="form-control null form-select" tabindex="2">
                                <option value="Completed">Completed</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Outstanding">Outstanding</option>
                                <option value="Underreview">Under Review</option>
                                <option value="n/a">N/A</option>
                                </select>
                                </div>
                            <div className="clmn"><span>
                              {data?.completedby || ''}
                              <br/>
                              {/* need break */}
                              {data?.completeddate ? moment.utc(data?.completeddate).format("MM-DD-YYYY") : ''}</span></div>
                            <div className="clmn">
                            {
                            <>
                            <span className="material-symbols-outlined icon2 btn" style={{'opacity' : data?.files?.length>0 ? 1 : 0.6}}
                            disabled={data?.files?.length == 0}
                            // data-bs-toggle="modal" data-bs-target="#viewattachment" data-bs-whatever="@mdo"
                             onClick={(e) => handleView(data)}> visibility </span> 
                             
                             {/* <a href={configdata?.common?.resourcesCloudfront  + data?.file} type="button" className="btn download_pdf">
                              <span className="material-symbols-outlined icon">download</span></a>  */}
                              
                              </>
                              }
<span  className="material-symbols-outlined icon2 upload-btn-wrapper" > upload_2
                               <input type="file" style={{ cursor: "pointer" }} onChange={(event)=>{handleFileChange2(data,event)}} name="myfile" accept=".doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png, .zip,.heic" />
                               </span>

                            {/* <span className="material-symbols-outlined icon2 btn" onClick={()=>{handleDeleteFile(data)}} style={{'opacity' : data?.file ? 1 : 0.6}}> delete </span> */}
                            
                            
                            </div>

                            <div className="clmn">
                            <div className="d-flex align-items-center">
                                <button className="upload_btn" data-bs-toggle="modal" data-bs-target="#addnote" onClick={()=>{handleAddNoteClick(data)}}
                                    data-bs-whatever="@mdo"> Add <span className="material-icons icon"> add </span></button>
                                <div className="btn-group">
                                    <button className="border_btn" 
                                    disabled={data?.note ? data?.note?.filter(note => note.status === 'ACTIVE').length === 0 : true}
                                     type="button" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        View <i className="mdi mdi-chevron-down"></i>
                                    </button>
                                    <div className="dropdown-menu">
  {data?.note
    ?.filter((note) => note.status === 'ACTIVE') 
    .map((activeNote, activeNoteIndex) => {
      let notenum = '';
      return (
        <div key={activeNoteIndex} className="dropdown-item">
          <a
            href="#"
            data-bs-toggle="modal"
            onClick={() => {
              setViewNote(activeNote.notes);
            }}
            data-bs-target="#viewnote"
            data-bs-whatever="@mdo"
          >
            Note {activeNoteIndex + 1}
          </a>
          <span className="material-symbols-outlined icon">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleDeleteNoteClick(activeNote);
              }}
            >
              delete
            </a>
          </span>
        </div>
      );
    })}
</div>
                                </div>
                            </div>
                            </div>
                        </div>
                                </>
                            )
                        })}
                        
                    </div>
                    :  
                    <div className="card-body user_management needs_description">
                    {/* <div className="search">
                      <h3 className="title">Need Description</h3>
                    </div> */}
                    <div className="form_section">
                  
                      <div className="search"><h3 className="title mb-0">Need Description</h3>
                      {showAddform &&  <a href="#" onClick={(e) => {e.preventDefault();addNew() }} className="back_btn">
                    <span className="material-icons icon"> chevron_left</span>BACK
                  </a>}
                      </div>

    <div className="row">
                <div className="row">
            <div className="col-md-4">
                <div className="col-md-12">
                              <div className={`form-group ${errors.documentname ? 'has-error' : ''}`}>
                                <label>Document Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="documentname"
                                  value={formData.documentname}
                                  onChange={handleInputChange}
                                  placeholder="Enter document name"
                                />
                              </div>
                </div>

                <div className="col-md-12">
                              <div className={`form-group ${errors.needtype ? 'has-error' : ''}`}>
                                <label>Need Type</label>
                                                    <select
                                                        className="form-control"
                                                        name="needtype"
                                                        value={formData.needtype}
                                                        onChange={handleInputChange}
                                                    >
                              <option value="">Please Select</option>
                              <option value="Borrower">Borrower</option>
                              <option value="Financial">Financial</option>
                              <option value="Market">Market</option>
                              <option value="Property">Property</option>
                              <option value="Summary">Summary</option>

                                                    </select>
                              </div>
                </div>
            </div>

            <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
              <div className="col-md-12">
                              <div className={`form-group ${errors.internaldescription ? 'has-error' : ''}`}>
                                <label>Internal Description</label>
                                <textarea id="w3review" className="form-control " value={formData.internaldescription} name="internaldescription"onChange={handleInputChange} rows="4" cols="50" placeholder="Enter internal description" spellcheck="false"></textarea>
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  name="internaldescription"
                                  value={formData.internaldescription}
                                  onChange={handleInputChange}
                                  placeholder="Enter"
                                /> */}
                              </div>
            </div>
              </div>
              <div className="col-md-6">
              <div className="col-md-12">
                              <div className={`form-group ${errors.needdescription ? 'has-error' : ''}`}>
                                <label>Need Description</label>
                                <textarea id="needdescription" className="form-control " value={formData.needdescription} name="needdescription"onChange={handleInputChange} rows="4" cols="50" placeholder="Enter need description" spellcheck="false"></textarea>
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  name="internaldescription"
                                  value={formData.internaldescription}
                                  onChange={handleInputChange}
                                  placeholder="Enter"
                                /> */}
                              </div>
            </div>
              </div>
            </div>
            

            
            </div>
                </div>

                {/* <div className="col-md-3">
                              <h3 className="title mt_20 mb-0">Choose a File</h3>
                              <div className="row files">
                                {selectedFiles.map((file, index) => (
                                  <div key={index} className="col-md-3">
                                    <div className="file_upload">
                                      <div className='file_img'>
                                      {file.type.startsWith('image/') ? (
                                        <img
                                          src={URL.createObjectURL(file)}
                                          alt={file.name}
                                          className="img"
                                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                                        />
                                      ) : (
                                        <img src="assets/images/documentname.svg" alt="documentname" className="img" />
                                      )}
                                      </div>
                                    
                                      <div className="file_title"> <span className='text_wrp'>{truncateFileName(file.name, 15)}</span>  <a onClick={() => {setDeleteIndex(index);setDeleteFileUpload(true);}} className="material-symbols-outlined icon"> delete </a></div>
                                    </div>
                                  </div>
                                ))}
                               {selectedFiles?.length === 0 &&
                                <div className="col-md-5">
                                  <div className="file_upload">
                                    <div className="upload_file">
                                      <span className="material-symbols-outlined">
                                        add
                                      </span>
                                    </div>
                                    <input onChange={handleFileChange} className="custom-file-input" accept=".doc, .docx, .xls, .xlsx, .pdf, .jpg, .jpeg, .png, .zip" type="file" 
                                    // accept="application/pdf"
                                    />
                                  </div>
                                </div>}
                              </div>
                              
                </div> */}

                <div className="form-group">
                                <a href="#" onClick={handleReset} className="border_btn mr_10">RESET</a>
                                <button onClick={handleSubmit} className="fs_btn">add Need</button>
                              </div>
    </div>
                      


                     </div>
                     </div>}
                </div>
                {/* <div className="modal popup new_popup doc fade" id="viewdocumentname" tabindex="-1"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Jan 2023</h5>
                                <button type="button" className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body">
                                <img src="assets/images/documentname.svg" alt="" className="w-100" />
                            </div>

                        </div>
                    </div>
                </div> */}
                <div className="modal popup new_popup small fade" id="addnote" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"  id="staticBackdropLabel">Add Note</h5>
                                <button type="button" id='addClose' className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body add_note">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="title mt_20 mb_10">Add Note</h3>
                                        <div className="form-group">
                                        <textarea placeholder="Comment......" value={note?.notes} className={`form-control ${noteError && 'invalid-input'}`} onChange={(e)=>{setNote({...note , notes : e.target.value})}} rows="4" cols="50" name="noteText"
                                        //  onChange={updateNoteJSON}
                                         ></textarea>

                                        </div>
                                    </div>
                                   
                                    <div className="col-md-12">
                                        <div className="btn-group mt_20 mb_10"><button onClick={handleAddNote} className="fs_btn">Add Note</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal popup new_popup small fade" id="viewnote" data-bs-backdrop="static" tabindex="-1" data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">VIEW NOTE</h5>
                                <button type="button" id='viewClose' className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body add_note">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        <p>{viewNote}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="btn-group mt_20 mb_10"><button onClick={()=>{document?.getElementById('viewClose').click()}} className="fs_btn">okay, got it</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal popup new_popup small fade" id="viewdesc" data-bs-backdrop="static" tabindex="-1" data-bs-keyboard="false"
                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">{heading}</h5>
                                <button type="button" id='viewClose2' className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                            </div>
                            <div className="modal-body add_note">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                        <p>{textValue}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="btn-group mt_20 mb_10"><button onClick={()=>{document?.getElementById('viewClose2').click()}} className="fs_btn">okay, got it</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

{viewDisplay && 
     <div
     className="modal popup new_popup fade pdf_doc file_view show"
     id="pdfdoc"
     tabIndex="-1"
     aria-labelledby="pdfdoc_achLabel"
     aria-hidden="true"
   >
     <div className="modal-dialog">
       <div className="modal-content">
         <div className="modal-header">
           <h5 className="modal-title" id="exampleModalLabel">
             Files View
           </h5>
           <button
             id="fileViewClose"
             type="button"
             className="pop_close"
             onClick={(e) => setViewDisplay(false)}
             data-bs-dismiss="modal"
             aria-label="Close"
           >
             <span className="material-symbols-outlined">close</span>
           </button>
         </div>
         <div
           className="modal-body pdf-popup"
           style={{ height: "100%", overflowY: "auto" }}
         >

            <ReusableSavedDoc files={filesData}/>

           
         </div>
       </div>
     </div>
   </div>
}
           
            </div>
            <PipelineFooter />
        </div>
    );
};

export default Needs;