import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector} from 'react-redux';
import { footerSiteUrl } from '../utils/reducer';
import Footer from './Footer';
const Submit = () => {
    const navigate = useNavigate();
    const configdata = useSelector(state => state.configdata);
    const replaceEasyStreetCapital = configdata?.admin?.rights?.replace(/Easy Street Capital LLC/g,"easystreetcap.com");
    const siteUrl = footerSiteUrl
    return (
        <div className="create_process">
            <div className="header">
                <div className="wrapper">
                    <div className="logo">
                        <img src="assets/images/brand/logo-light.png" className="img" alt="" />
                    </div>
                    <a href="#" className="btn"> <span className="material-icons"> close </span>EXIT</a>
                </div>

            </div>
            <div className="content_wrp">
            <div className="content_blk create_success">
                <div className="block">
                    <h3>Find out what Easy Street Capital can do for you.</h3>
                    <div className="icon">
                        <img src="assets/images/icons/success_icon.svg" className="img" alt="" />
                    </div>
                    <p>Thank You! <br />
                        Your application has been submitted.
                        Our team will follow up with you shortly.</p>
                    <a href="#" className="cp_btn">Back to Home</a>
                </div>


            </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Submit;