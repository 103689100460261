import React,{useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
import WarmAppFooter from '../WarmAppFooter';
import RefinancingBuild from '../RefinancingBuild';
import RefinancingBuildWarmApp from '../RefinancingBuildWarmApp';
import { useLoanInfo } from '../../utils/loanInfoContext';
const DealInfo = () => {
const { loanInfo, setLoanInfo } = useLoanInfo();
const [properyInfo, setProperyInfo] = useState(loanInfo?.propertyinfo ? loanInfo?.propertyinfo : {});
const navigate = useNavigate();

useEffect(() => {
  const isLoanProgramEmpty = !loanInfo.propertyinfo.programtype;
    if (isLoanProgramEmpty) {
        navigate('/loanprogram');
    }
}, [loanInfo]);

  useEffect(() => {
    console.log('properyInfo',loanInfo);
    setLoanInfo((prevLoanInfo) => ({
      ...prevLoanInfo,
      propertyinfo: properyInfo,
    }));
  }, [properyInfo]);
  
const DealInfoPrice = () => {
    return (
      <RefinancingBuild />
      //<RefinancingBuildWarmApp />
    )
}


const handleInputChange = (key, value) => {
    // Update the formData with the new key-value pair
   
    setProperyInfo((prevData) => {
      // Create a copy of the previous data
      let updatedData = { ...prevData };

      // Conditionally delete the key from the copied data
      if (key == 'loanfor' && value === "Refinance") {
        delete updatedData.stepIntheProcess;
      }
  
      // Update the copied data with the new key-value pair
      updatedData = {
        ...updatedData,
        [key]: value,
      };
  
      return updatedData;
    });
  };
  return (
    <div className='warmapp_body'>
       <Header />
        <div className="wizard-body">
            <div className="wizard-container full-height">
              <div className="wizard-group">
                <InfoBar properyInfo={properyInfo} progress={'100%'} infoProgress={'100%'} currentStage={'dealinfo'}/>
                    {/* <div className="wizard-canvas"> */}
                        <DealInfoPrice />
                    {/* </div> */}
              </div>
            </div>
        </div>
       <WarmAppFooter />
    </div>
  );
};

export default DealInfo;