import React, { useState, useEffect} from 'react';
import ReactQuill from "react-quill";
import MainHeader from '../MainHeader';
import SideMenu from '../SideMenu';
import {getStates,getallUsersDropdown} from "../../utils/reducer";
import {formatPhoneNumber} from "../../utils/commonUtils.js";
import { Appname, APIUrl , footerSiteUrl} from '../../utils/reducer';
import axios from "axios";
import { showLoader, hideLoader } from '../../redux/reducers/Actions.js';
import { useSelector, useDispatch} from 'react-redux';
import { getUserDataFromLocalStorage } from "../../utils/local";
import { createUser, resendPassword} from "../../utils/reducer";
import Select from 'react-select';
import PopupMessage from '../PopupMessage';
import HomeFooter from '../HomeFooter';
import { v4 as uuidv4 } from 'uuid';
import newRoleEmptyJson from '../../JsonData/newRoleEmptyJson.json';

const CreateUserUserInfo = (props) => {
    const dispatch = useDispatch();
    const configdata = useSelector(state => state.configdata);
    const [userInfoErrors, setUserInfoErrors] = useState({});
    const [states, setStates] = useState([]);
    const [popupMessage, setmessage] = useState('Created user successfully.');
    const [isPopupOpen, setPopupOpen] = useState(false);
//    const [emailtoResend, setEmailResend] = useState('');

    const [users, setUsers] = useState([]);
    const pipelineLoanOptions = [
        { value: "", label: "Empty" },
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
        { value: "40", label: "40" },
        { value: "50", label: "50" },
        { value: "60", label: "60" },
        { value: "70", label: "70" },
        { value: "80", label: "80" },
        { value: "90", label: "90" },
        { value: "100", label: "100" },
    ];
    
    const handleChange = (e) => {
        const regex = /^[1-9]\d{9}$/;
        console.log('e.target.name',e.target.name, userInfoErrors);
        const { name, value } = e.target;
        let transformedValue = value;
        if(name === 'manager'){
            const selectedUser = users.find(user => user.value === e.target.value);     
            props.setUserInfoDetails((prev) => ({
                ...prev,
                [name]: transformedValue?.trim(),
                manageruserid : selectedUser?.userid
              }));
            console.log('name ',e.target.value)
        }else{
            if (['firstname', 'lastname'].includes(name)) {
                transformedValue = value.replace(/[^a-zA-Z0-9\s]/g, ''); 
            }else
             if (name === 'username') {
                transformedValue = value?.replace(/[^a-zA-Z0-9]/g, ''); // No spaces or special characters
            }
            
            // if (['firstname', 'lastname', 'username']?.includes(name)) {
            //     transformedValue = value?.replace(/[^a-zA-Z0-9]/g, '');
            // }
             else if (name === 'emailid') {
                transformedValue = value?.replace(/\s/g, '');
            }else {
                transformedValue = value?.trim();
            }
            setUserInfoErrors({...userInfoErrors,[e.target.name] : null })
            props.setUserInfoDetails((prev) => ({
              ...prev,
              [name]: transformedValue,
            }));
        }
    };

    // useEffect(() => {
    //     setEditorUpdate(true);
    // },[])
    
    const fieldsToCheck = {
         state : 'state', emailid : 'emailid', phone : 'phone', username : 'username',  password : 'password', firstname:'firstname', lastname:'lastname',pipelineLoanHistoryDays : 'pipelineLoanHistoryDays'
    };

    useEffect(() => {
        getStates().then(function (response) {
          console.log('getStates',response);
          if (response?.result) {
             setStates(response?.result)
          }
          }).catch((err) => {
            console.error.bind("my account error", err);
          })
          getallUsersDropdown().then(function (response) {
            // console.log('privacymenunewwegetallUsersDropdown',response?.result?.data);
            if (response?.result?.data) {
                const activeUsers = response?.result?.data.filter(user => user.securityInfo && user.securityInfo.status === "ACTIVE");
                // const mappedUsernames = response?.result?.data.map(item => item.userInfo.username);
                const options = activeUsers.map(user => ({
                    value: `${user?.userInfo?.username}`,
                    label: `${user?.userInfo?.username}`,
                    userid : user?.userid
                  }));
              setUsers(options);
              dispatch(hideLoader());
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
      }, []);
      const isValidPhoneNumber = (phoneNumber) => {
        const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        let phonetest = phoneRegex.test(phoneNumber);
        return phonetest;
      };

      console.log('props.userInfoDetails',props.finalData?.userid);

    const handleCreateUserInfo = ()=>{
       
        // const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;

        const specialCharsRegex = /[&'"]/.test(props.userInfoDetails.password);
        console.log('ran');
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if (!props.userInfoDetails[fieldName]?.trim() && fieldName !== 'phone') {
                if (props.isEdit == true && fieldName == 'password'){
                    
                }else{
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
           }else if(fieldName === "phone" && (!props.userInfoDetails[fieldName] || props.userInfoDetails[fieldName] === "0")){
                errors[fieldName] = `Please Enter ${fieldLabel} `;
           }
        };
        if(props.userInfoDetails.phone){
            if(!isValidPhoneNumber(props.userInfoDetails.phone)){
                errors['phone'] = 'Please Enter Number'
            }
        }
        if(props.userInfoDetails.password && props.isEdit == false){
            if(props.userInfoDetails.password.length<8 || specialCharsRegex){
                console.log('rannnn')
                errors.password = 'password doest match criteria';
            }
        }

        if(props.userInfoDetails.emailid){
            if(!emailRegex.test(props.userInfoDetails.emailid)){
                errors.emailid = 'Email Structure Error';
            }
        }
        console.log('errorserrors',errors);
        setUserInfoErrors(errors); 
        if (Object.keys(errors).length === 0) {
           // send Final Data
           console.log()
           const userInfoData = {...props.userInfoDetails, 'aboutme' : props.quillValue, 'signature' : props.signaturetext,'imgData' : props.imgData }
            
            props.setFinalData(prevState => {

                console.log('setFinalData', prevState);
            


                const updatedData = {
                    ...prevState,
                    
                    
                    userInfo: userInfoData,
                    ...(props.finalData?.userid
                        ? {}
                        : {
                            securityInfo: {
                                role: 'Account Executive',
                                status: 'ACTIVE',
                            },
                        }),
                };
                  
                console.log('updatedDataupdatedData', updatedData, props.finalData);
                // Now call createUser with the updatedData
                dispatch(showLoader());
                
                createUser(updatedData).then(response => {
                    // Perform actions after successful creation
                    if (!response?.result?.error) {
                        if(response?.result === 'User already exists'){
                            setmessage('Email Already Exists');
                            setPopupOpen(true);
                        }else if(response?.result === 'User Name already exists'){
                            setmessage('Username already exists');
                            setPopupOpen(true);
                        }
                        else if(response?.result ==='user data updated successfully'){
                            props.setFinalData(updatedData);
                            props?.setEmailResend(updatedData?.userInfo?.emailid);
                            props.setCurrentPage('securityInfo');
                        }else{
                            props.setFinalData(response?.result);
                            props.setCurrentPage('securityInfo');
                        }

                    console.log('response henavigated', );
                    }else{
                        setmessage(response?.result.message);
                        setPopupOpen(true);
                    }
                    dispatch(hideLoader());
                }).catch(err => {
                    dispatch(hideLoader());
                    console.error(err);
                    // Handle error
                });

                return updatedData; // Return the new state
            });
            //props.setCurrentPage('securityInfo');

            // send Final Data
            
            
        }
    }
    const navNext = ()=>{
        props.setCurrentPage('securityInfo');
    }

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ],
    }

    const generatePassword = () => {
        console.log("generate password event clicked");
        const validCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^*()_+';
        const passwordLength = 8;
        let password = '';
        for (let i = 0; i < passwordLength; i++) {
          const randomIndex = Math.floor(Math.random() * validCharacters.length);
          password += validCharacters.charAt(randomIndex);
        }
        console.log('generate password--->',password);
        setUserInfoErrors({...userInfoErrors,password : null })
        props.setUserInfoDetails({...props.userInfoDetails ,password : password });
    };

    const handleImgUpload = (e) =>{
        console.log('rannnnnnn');
        const file = e.target.files[0];
        const timestamp = Date.now();
        let userData = getUserDataFromLocalStorage();
        let UserId = userData?.userId
        let format = file?.name?.split(".");
        var fileformat = format[format.length - 1];
        const imgUrl = URL.createObjectURL(file);
        console.log('timestamp---->',timestamp);
        console.log('file---->',file);
        console.log('fileformat---->',fileformat);
        props.setImageData({img: imgUrl,imgFileData : {'file' :file, 'fileformat' : fileformat ,'timestamp' : timestamp } });
        var bucket;
        bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
        console.log('filefilefilebucket', bucket,);
        let category = e.target.name;
        console.log('datadacategory', category);

        let uploadFilePath = Appname + "/" + props.finalData?.id + "/" + timestamp + "profileimage." + fileformat;
        console.log('datadauploadFilePath', uploadFilePath);
        var uploaddata = { source_bucket: bucket, sourcepath: uploadFilePath }
       
        dispatch(showLoader());
        axios.post(APIUrl + 'uploadURL?appname=' + Appname, uploaddata, { type: 'application/json' })
            .then((response) => {
                console.log('ressss', response);
                if (response?.data && response?.data?.result) {
                    console.log('ressss2', response.data);
                    var url = response?.data?.result;
                    console.log('ressss3', response.data.result);

                    console.log("url", url);
                    axios.put(url, file, {
                        "headers": {
                            "Content-Type": "multipart/form-data",
                            "Accept": "/",
                            "Cache-Control": "no-cache",
                            "Accept-Encoding": "gzip, deflate",
                            "Connection": "keep-alive",
                            "cache-control": "no-cache"
                        }
                    })
                        .then((response) => {
                            if (response && response?.status === 200) {
                                console.log('responseherererer', uploadFilePath);
                                dispatch(hideLoader());
                                props.setUserInfoDetails({...props.userInfoDetails ,profileImage : uploadFilePath });

                            }
                        });
                }


            });

    }
    const closePopup = () => {
        setPopupOpen(false);
    }; 
    const handlePasswordMail = () => {
        console.log('status',props.finalData?.securityInfo?.status)
        console.log('sent');
        dispatch(showLoader());
        resendPassword(props?.emailtoResend).then(function (response) {
            if (response.result && response.result != "Client not found") {
                setmessage('An email containing your password has been sent to the registered email address.')
                dispatch(hideLoader());
                setPopupOpen(true)
            }else if(response.result == "Client not found"){
              setmessage(response?.result)
              setPopupOpen(true)
              dispatch(hideLoader());
            }
          }).catch((err) => {
            console.error.bind("Login error", err);
            dispatch(hideLoader());
          })
    }; 



    return (
        <div id="layout-wrapper">

            <MainHeader/>
            <SideMenu />
            {isPopupOpen && (
        <PopupMessage
          type={(popupMessage == 'Created user successfully.' ||  popupMessage == "An email containing your password has been sent to the registered email address.") ? 'success' : 'warning'}
          message={popupMessage}
          onClose={closePopup}
        />
      )}
            <div className="main-content">

                <div className="page-content user_management">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">{props?.isEdit ? 'Update' : 'Create'} User</h4>
                                    <a href="#" id="userInfoBack" onClick={(e) => {e.preventDefault(); props.handleBack()}} className="back_btn">
                    <span className="material-icons icon"> arrow_back</span>BACK
                  </a>
                                </div>
                            <div className="steps">
                                <div className="box bx_progress active" onClick={(e) => {props.checkIdAndNavigate('userinfo')}} >
                                    <span className="text">User
                                        Info</span>
                                    <img src="assets/images/icons/User-Info.svg" className="img" alt="" />
                                </div>
                                <div className="box " onClick={(e) => {props.checkIdAndNavigate('securityInfo')}}>
                                    <span className="text">Security
                                        Info</span>
                                    <img src="assets/images/icons/Security-Info.svg" className="img" alt="" />
                                </div>
                                <div className="box" onClick={(e) => {props.checkIdAndNavigate('stateLicenses')}}>
                                    <span className="text">State
                                        Licenses</span>
                                    <img src="assets/images/icons/StateLicenses.svg" className="img" alt="" />
                                </div>
                                <div className="box" onClick={(e) => {props.checkIdAndNavigate('loanAssignMent')}}>
                                    <span className="text">My Default Loan
                                        Assignments</span>
                                    <img src="assets/images/icons/LoanAssignments.svg" className="img" alt="" />
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="title">USER INFO</h3>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>First name<span className="rf">*</span></label>
                                                            <input id="userFirstName" value={props?.userInfoDetails.firstname} name='firstname' onChange={handleChange} onFocus={()=>{setUserInfoErrors({...userInfoErrors,firstname : null })}} type="text" className={`form-control capitalize-text ${userInfoErrors?.firstname && 'invalid-input'}`} tabIndex={1}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Phone<span className="rf">*</span></label>
                                                            <input id="userPhone" placeholder="(123) 456-7890"  onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)}  onChange={handleChange}  value={props.userInfoDetails.phone} type='tel' name="phone" className={`form-control ${userInfoErrors?.phone && 'invalid-input'}`} 
                                                             onFocus={()=>{setUserInfoErrors({...userInfoErrors,phone : null })}}   pattern="\(\d{3}\) \d{3}-\d{4}" title="Phone number must be in the format: (123) 456-7890" tabIndex={4} />
                                                        </div>
                                                     
                                                        <div className="form-group">
                                                            <label>Username<span className="rf">*</span></label>
                                                            <input id="userName" value={props.userInfoDetails.username} name='username'
                                                            //  disabled={props?.isEdit || props.finalData?.userid || false}
                                                              onChange={handleChange}  type="text" onFocus={()=>{setUserInfoErrors({...userInfoErrors,username : null })}} className={`form-control ${userInfoErrors?.username && 'invalid-input'}`} tabIndex={7}/>
                                                        </div>

                                                        <div className="form-group">
                                                            <label>Manager</label>
                                                                {/* <select
                                                                id="userManager" 
                                                                className={`form-control ${userInfoErrors?.manager && 'invalid-input'}`} 
                                                                name='manager' 
                                                                    value={props.userInfoDetails.manager || ''}
                                                                    onChange={handleChange}
                                                                    onFocus={()=>{setUserInfoErrors({...userInfoErrors,manager : null })}}
                                                                     // readOnly={true}
                                                                    tabIndex={9}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {users.map(option => (
                                                                        <option key={option} value={option?.value}>{option?.label}</option>
                                                                    ))}
                                                                </select> */}
                                                                <Select
                                                                    name="manager"
                                                                    options={users} // Assuming users is an array of { value, label, userid } objects
                                                                    className={`react-select-container ${userInfoErrors?.manager && 'invalid-input'}`}
                                                                    classNamePrefix="react-select"
                                                                    value={users.find(option => option.value === props.userInfoDetails.manager)}
                                                                    onFocus={() => {
                                                                        setUserInfoErrors({ ...userInfoErrors, manager: null });
                                                                    }}
                                                                    onChange={(selectedOption) => {
                                                                        const transformedValue = selectedOption?.value || '';
                                                                        props.setUserInfoDetails((prev) => ({
                                                                            ...prev,
                                                                            manager: transformedValue.trim(),
                                                                            manageruserid: selectedOption?.userid || null,
                                                                        }));
                                                                    }}
                                                                    inputId="userManager"
                                                                    tabIndex={9}
                                                                />

                                                            {/* <input id="userManager" 
                                                            // readOnly={true}
                                                            onFocus={()=>{setUserInfoErrors({...userInfoErrors,manager : null })}} className={`form-control ${userInfoErrors?.manager && 'invalid-input'}`} 
                                                             value={props.userInfoDetails.manager}
                                                              name='manager' onChange={handleChange} type="text"  tabIndex={9}/> */}
                                                        </div>

                                                        {/* <div className="form-group">
                                                            <label>Team Lead</label>
                                                            <input value={props.userInfoDetails.teamlead} name='teamlead' onChange={handleChange} type="text" className='form-control' />
                                                        </div> */}
                                                           
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Last name<span className="rf">*</span></label>
                                                            <input id="userLastName" value={props.userInfoDetails.lastname} name='lastname' onChange={handleChange} type="text" onFocus={()=>{setUserInfoErrors({...userInfoErrors,lastname : null })}} className={`form-control capitalize-text ${userInfoErrors?.lastname && 'invalid-input'}`} tabIndex={2}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>FAX</label>
                                                            <input id="userFax" value={props.userInfoDetails.fax} onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)} name='fax' onChange={handleChange} type="text" className='form-control' tabIndex={5}/>
                                                        </div>

                                                     

                                                        {props.isEdit == false &&<div className="form-group">
                                                            <label>Password<span className="rf">*</span></label>
                                                            <input id="userPassword" readOnly={true} value={props.userInfoDetails.password} name='password' onChange={handleChange} onFocus={()=>{setUserInfoErrors({...userInfoErrors,password : null })}} type="text" className={`form-control ${userInfoErrors?.password && 'invalid-input'}`} tabIndex={8}/>
                                                        </div>}

                                                        
                                                     
                                                            <div className="form-group">
                                                                <label>Default Pipeline Load History Days<span className="rf">*</span></label>
                                                                <select id="loadHistoryDays" value={props?.userInfoDetails?.pipelineLoanHistoryDays} className={`form-control ${userInfoErrors?.pipelineLoanHistoryDays && 'invalid-input'}`} onFocus={()=>{setUserInfoErrors({...userInfoErrors,pipelineLoanHistoryDays : null })}} name='pipelineLoanHistoryDays' onChange={handleChange} tabIndex={10}>
                                                                    {pipelineLoanOptions.map((data,index)=>(
                                                                        <option value={data.value}>{data.label}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            
                                                            
                                                            

                                                        
                                                        {/* <div className="form-group">
                                                            <label>Referral</label>
                                                            <input value={props.userInfoDetails.referral} name='referral' onChange={handleChange} type="text" className='form-control' />
                                                        </div> */}
                                                    </div>
                                                    <div className="col-md-3">
                                                    <div className="form-group">
                                                            <label>Email<span className="rf">*</span></label>
                                                            {/* <input id="userEmail" value={props.userInfoDetails.emailid} onChange={handleChange} disabled={props?.isEdit} onFocus={()=>{setUserInfoErrors({...userInfoErrors,emailid : null })}} name='emailid' type="text"  className={`form-control ${userInfoErrors?.emailid && 'invalid-input'}`} tabIndex={3}  /> */}
                                                            <input id="userEmail" value={props.userInfoDetails.emailid} onChange={handleChange} 
                                                            onFocus={()=>{setUserInfoErrors({...userInfoErrors,emailid : null })}} name='emailid' type="text"  className={`form-control ${userInfoErrors?.emailid && 'invalid-input'}`} tabIndex={3} 
                                                            disabled={props?.isLoggedIn}
                                                            style={{
                                                                'backgroundColor' : props?.isLoggedIn === true ? '#e9ecef' : '',
                                                            }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>State<span className="rf">*</span></label>
                                                            <select id="userState" name="state" value={props.userInfoDetails.state} onChange={handleChange} onFocus={()=>{setUserInfoErrors({...userInfoErrors,state : null })}} className={`form-control ${userInfoErrors?.state && 'invalid-input'}`} tabIndex={6}>
                                                                <option value=''>Select</option>
                                                                {states.map((key, index) => (<option value={key}>{key}</option>))}
                                                            </select>
                                                        </div>
                                                        
                                                        {props?.isEdit == false &&
                                                            <div className="form-group">
                                                                <label> &nbsp; </label>
                                                                <button id="generatePassword" type="button" onClick={generatePassword} className="generate_password fs_btn">Generate Password
                                                                    <span className="material-symbols-outlined icon"> vpn_key </span>
                                                                </button>
                                                            </div>
                                                        }
                                                        {props.isEdit &&<div className="form-group ">
                                                            <label> &nbsp; </label>
                                                            <button id="userSendPassword" type="button" onClick={handlePasswordMail} style={{'opacity': props?.finalData?.securityInfo?.status==='ACTIVE' ? '1' : '0.6',}} disabled={props?.finalData?.securityInfo?.status==='ACTIVE' ? false : true} className="fs_btn d-block">Send Password</button>
                                                            </div>
                                                        }
                                                        </div>
                                                        
                                                        

                                                </div>
                                                {/* <div className="uploads">
                                                    <div className="item">
                                                        <div className="left">
                                                            <img
                                                            src={props.imgData.img ? props.imgData.img : 'assets/images/icons/mode-portrait.svg'}
                                                            // src="assets/images/icons/mode-portrait.svg"
                                                            alt='img'
                                                                className="img" />
                                                            <div>
                                                                <h4>Profile Picture</h4>
                                                                <p>Upload clear Picture</p>

                                                            </div>
                                                        </div>
                                                        <label htmlFor="file-input" className="upload">
                                                    <span className="material-icons icon">file_upload</span>
                                                      Upload
                                                     </label>
                                                    <input type="file" accept="image/*" id="file-input" name={'photoid'}  onChange={handleImgUpload} style={{ display: 'none' }} />
                                                    </div>
                                                </div> */}
                                            </div>
                                             
                                                   
                                                    
                                                           
                                                         
                                                           
                                                  

                                                   
                                          
                                                <div className="col-md-12">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <h3 className="title mt_20">About Me</h3>
                                                            <div className="form-group">
                                                                <ReactQuill
                                                                    id="aboutMeUser"
                                                                    style={{ height: "200px" }}
                                                                    theme="snow"
                                                                    value={props?.quillValue || props.userInfoDetails?.aboutme}
                                                                    onChange={props.setQuillValue}
                                                                    modules={modules}
                                                                    formats={[
                                                                        'header',
                                                                        'bold',
                                                                        'italic',
                                                                        'underline',
                                                                        'strike',
                                                                        'blockquote',
                                                                        'list',
                                                                        'bullet',
                                                                        'indent',
                                                                        'link',
                                                                        'image',
                                                                        'clean'
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {/* <img className="w-100" src="assets/images/texteditor.jpg" alt="" /> */}
                                                            <h3 className="title mt_20">Email signature</h3>
                                                            <div className="form-group">
                                                                <ReactQuill
                                                                    id="userEmailSignature"
                                                                    style={{ height: "200px" }}
                                                                    theme="snow"
                                                                    value={props?.signaturetext || props.userInfoDetails?.signature}
                                                                    onChange={props.setSignatureText}
                                                                    modules={modules}
                                                                    formats={[
                                                                        'header',
                                                                        'bold',
                                                                        'italic',
                                                                        'underline',
                                                                        'strike',
                                                                        'blockquote',
                                                                        'list',
                                                                        'bullet',
                                                                        'indent',
                                                                        'link',
                                                                        'image',
                                                                        'clean'
                                                                    ]}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="btn-group mt_20 mb_10">
                                                        <button id="cancelUser" type="button" onClick={(e) => { props.handleBack() }} className="border_btn mr_10">Cancel</button>
                                                       {/* <button onClick={props.isEdit ? navNext : handleCreateUserInfo} className="fs_btn  mr_10">{props.isEdit ? 'Next' : 'create'}</button> */}
                                                        <button id="saveUser" type="button" onClick={handleCreateUserInfo} className="fs_btn">{props.isEdit ? 'Update' : 'Save'}</button>
                                                    </div>
                                                </div>
                                              
                                        </div>



                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default CreateUserUserInfo;