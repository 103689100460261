import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { loanUploadTemplateApi } from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector } from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import { formatCurrency } from '../utils/commonUtils.js';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission, hasEditPermission } from '../utils/permissionsCheck';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { saveAs } from 'file-saver';

const BulkUpload = (props) => {
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [uploadTrue, setUploadTrue] = useState('bulk');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [headers, setHeaders] = useState('');
    const [uploadName, setUploadName] = useState('');
    const [failedData, setFailedData] = useState('');
    const [statusValues, setStatusValues] = useState({});
    const configdata = useSelector(state => state.configdata);
    const handleBack = (e) => {
        e.preventDefault();
        if (uploadTrue === 'status') {
            setUploadTrue('upload')
        } else if (uploadTrue === 'upload') {
            setUploadTrue('bulk');
            setUploadName('');
        }
    };
    const handleUpload = (e, headers, name) => {
        e.preventDefault();
        setUploadTrue('upload');
        setHeaders(headers);
        setUploadName(name)
    };
    useEffect(() => {
        console.log(uploadTrue);
    }, [uploadTrue])



    const uploadFilesJson = [{
        heading: 'Loan Upload', name: 'Loan', downloadtemplate: configdata?.common?.resourcesCloudfront + 'bulkupload/sampleloan.xlsx',
        headers: ["loanid", "lendercaseid", "borrowerfirstname", "borrowerlastname", "borroweremail", "borrowerphone", "ssn", "dateofbirth", "creditscore", "ethnicity", "subethnicity", "otherethnicity", "race", "subrace", "otherrace", "gender", "borroweraddress", "borroweraddress2", "borrowercity", "borrowerstate", "borrowerzipcode", "entitytype", "legalname", "entityaddress", "entitycity", "entitystate", "zipcode", "propertyaddress", "propertyaddress2", "numberofunits", "propertycity", "propertystate", "propertyzipcode", "propertytype", "finishedsqft", "apn", "hazardinsuranceexpirydate", "hazardinsurance", "floodinsurencevalue", "loantype", "loanamount", "loanterm", "funds", "interestrate", "points", "purchaseprice", "initialequity", "cashoutAmount", "easystreetarv", "borrowerarv", "renovationcost", "rehabfunding", "constructioncompleteddate", "exitstrategy", "actualwire_funddate", "scheduledfirstpaymentdate", "proposedmaturitydate", "paidoffdate", "solddate", "referredBy", "companyNetProfit", "folder", "status", "categoriesforloan", "changeprocessorto", "changeopenerto", "changeunderwriterto", "changecloserto", "changefunderto", "changeaccountexceutiveto", "changeprocesser2to", "changeaccountingto", "changeservicerto", "changeqcloanto", "changedrawmanagementto"]
    }, {
        heading: 'Servicing Upload', name: 'Servicing', downloadtemplate: configdata?.common?.resourcesCloudfront + 'bulkupload/sampleservicingpayments.xlsx',
        headers: ["loanid", "lendercaseid", "paymentmethod","paymenttype", "reference", "duedate", "paymentdate", "amountfromborrower", "amountfromreserves", "principalamount", "interestamount", "latefee", "description", "finalpayment"]
    },
     {
        heading :  'Draw Upload' , name : 'Draw' , downloadtemplate : configdata?.common?.resourcesCloudfront + 'bulkupload/sampledraws.xlsx',
        headers : ["loanid","scopeofwork","contractor","completiondate","drawnumber","requestedamount","orderdate","scheduleddate","completeddate","contacts","notes","amountapproved","feeamount","paymentmethod","inspectedby","inspecteddate","itemcomplete"]
     },
    {
        heading: 'Pipeline Upload', name: 'Pipeline', downloadtemplate: configdata?.common?.resourcesCloudfront + 'bulkupload/samplepipelinenotes.xlsx',
        headers: ['loanid', 'whatcontactwasfor', 'notes', 'typeofcontact', 'communicationtype', 'communicationmedium']
    },];


    const handleFileImport = (e) => {
        const file = e.target.files[0];

        if (file) {
            const validExtensions = ['.xlsx', '.xls'];
            const fileExtension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();

            if (!validExtensions.includes(`.${fileExtension}`)) {
                dispatch(showLoader());
                setPopupOpen(true);
                setMessage("Please upload only .xlsx or .xls Excel files.");
                dispatch(hideLoader());
                return;
            }

            const reader = new FileReader();

            reader.onload = (evt) => {
                const arrayBuffer = evt.target.result;
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                console.log('worksheet', worksheet)
                // Expected headers
                const expectedHeaders = headers;
                console.log('expectedHeaders', expectedHeaders)
                // Get the actual headers from the first row of the worksheet
                let actualHeaders;
                // if(uploadName == 'Servicing'){
                //     actualHeaders = worksheet[1];
                // }else{
                    actualHeaders = worksheet[0]

                //const actualHeaders = worksheet[0] || worksheet[1];
                console.log('actualHeaders', actualHeaders)

                // Compare the headers
                const headersMatch = expectedHeaders.every((header, index) => header === actualHeaders[index]);
                console.log('headersMatch', headersMatch)
                if (!headersMatch) {
                    dispatch(showLoader());
                    setPopupOpen(true);
                    setMessage("Invalid file format. Please ensure the headers are correct.");
                    dispatch(hideLoader());
                    return; // Exit if headers don't match
                } else {
                    // Check if there are rows after the header
                    if (worksheet.length <= 1) {
                        dispatch(showLoader());
                        setPopupOpen(true);
                        setMessage("No data Found in the file. Please upload a file that contains data.");
                        dispatch(hideLoader());
                        return;
                    } else {
                        // let importedData = []
                        // setImportFileData(importedData);
                        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                            header: headers, // Dynamically set headers
                            range: 1, // Skip the first row (header row)
                            defval: "" // Set empty values to empty strings instead of undefined
                        });
                        setUploadTrue('status');
                        // Print extracted JSON data
                        console.log("Processed JSON data:", jsonData);
                        uploadTemplate(jsonData)

                    }
                }

                // Reset file input to allow re-importing the same file
                e.target.value = null;
            };

            reader.readAsArrayBuffer(file);
        }
    };
    const uploadTemplate = (data) => {
        let uploadType = '';
        if (uploadName == 'Loan') {
            uploadType = 'loan';
        } else if (uploadName == 'Servicing') {
            uploadType = 'payments';
        } else if (uploadName == 'Pipeline') {
            uploadType = 'pipeline';
        } else if (uploadName == 'Draw') {
            uploadType = 'draws';
        }
        dispatch(showLoader());
        loanUploadTemplateApi(data, uploadType).then(function (response) {
            console.log("response.result",response.result);
            setFailedData(response?.result)
            dispatch(hideLoader());
        })
            .catch((err) => {
                console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
    };

    const closePopup = () => {
        setPopupOpen(false);
    }
    console.log("failed",failedData)
    // const exportToExcel = () => {
    //     dispatch(showLoader())
    //     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //     const fileExtension = '.xlsx';
    //     // let status = filteredStage
    //     //const { sortBy, status, type, ...body } = { ...sortObj, ...advFormData, status: filteredStage };

    //     // let token = getTokenFromLocalStorage();
    //     // let userData = getUserDataFromLocalStorage();
    //     // let UserId = userData?.userId;
    //     if (failedData) {
    //         const loans = failedData



    //         // const formattedData = loans.map(item => {
    //         //     const rowData = {};
    //         //     columns.forEach(column => {
    //         //         // console.log('coloumnnnn', column)
    //         //         if (column.id === "loanid") {
    //         //             rowData[column.name] = item[column.id];
    //         //         } else if(column.id === "pipelinenotes"){
    //         //             rowData[column.name] = item['pipelinenotes'] > 0 ? item['pipelinenotes'] : '';
    //         //         }else  if(column.id === "drawinteresttype"){
    //         //             rowData[column.name] = item[column.id] == 'Interest on Full Balance' ? 'true' : 'false';
    //         //         }else {
    //         //             rowData[column.name] = column.selector(item);
    //         //         }
    //         //     });
    //         //     return rowData;
    //         // });

    //         const ws = XLSX.utils.json_to_sheet(loans);

    //         // Calculate column widths based on content
    //         const columnWidths = columns.map(column => {
    //             const maxContentLength = loans.reduce((max, row) => {
    //                 const cellContent = row[column.name] || '';
    //                 return Math.max(max, cellContent.toString().length);
    //             }, column.name.length); // Set initial width to column name length

    //             return { wch: maxContentLength + 2 }; // Add some padding
    //         });

    //         // Apply column widths
    //         ws['!cols'] = columnWidths;

    //         const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //         const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //         const fileData = new Blob([excelBuffer], { type: fileType });
    //         saveAs(fileData, 'Loan' + fileExtension);
    //         dispatch(hideLoader());
    //     }

    // };
// Utility functions
const formatCurrency = (value) => (value ? `$${parseFloat(value).toFixed(2)}` : '');
const formatNumericInputForExcel = (value) => (value ? parseFloat(value) : '');
const htmlToText = (html) => (html ? html.replace(/<[^>]+>/g, '') : '');

// Main export function
const handleExportToExcel = () => {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    if (!failedData?.failedLoans || failedData.failedLoans.length === 0) {
        alert("No data available to export.");
        return;
    }

    // Extract dynamic headers from the first object
    const headers = Object.keys(failedData.failedLoans[0] || {});

    // Format the data dynamically
    const formattedData = failedData.failedLoans.map(item => {
        const rowData = {};
        headers.forEach(key => {
            const value = item[key];

            if (typeof value === 'string' && value.includes('<')) {
                rowData[key] = htmlToText(value); // Convert HTML to plain text
            } else if (moment(value, moment.ISO_8601, true).isValid()) {
                rowData[key] = moment.utc(value).format("MM-DD-YYYY"); // Format dates
            } else if (!isNaN(value) && value !== null) {
                rowData[key] = formatNumericInputForExcel(value); // Format numbers
            } else {
                rowData[key] = value || ''; // Keep text as is
            }
        });
        return rowData;
    });

    const ws = XLSX.utils.json_to_sheet(formattedData);

    // Dynamically adjust column widths
    const columnWidths = headers.map(column => {
        const maxContentLength = formattedData.reduce((max, row) => {
            const cellContent = row[column] || '';
            return Math.max(max, cellContent.toString().length);
        }, column.length); // Set initial width to column name length

        return { wch: maxContentLength + 2 }; // Add padding
    });

    ws['!cols'] = columnWidths;

    const wb = { Sheets: { 'Failed Loans': ws }, SheetNames: ['Failed Loans'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    const fileData = new Blob([excelBuffer], { type: fileType });

    saveAs(fileData, 'Failed_Loans' + fileExtension);
};
    const handleDownload=()=>{
        handleExportToExcel();
    }
    return (
        <div id="layout-wrapper">
            {loading ? <Loader /> : null}
            {isPopupOpen &&
                <PopupMessage
                    type={(typeof message === 'string' && (
                        message === "Please either import the file or fill in the required details." ||
                        message === "Please upload only .xlsx or .xls Excel files." ||
                        message === "Invalid file format. Please ensure the headers are correct." ||
                        message === "No data Found in the file. Please upload a file that contains data." ||
                        message.startsWith("The following loan numbers do not lie between the respective years:") ||
                        message === "No rows have been selected."
                    )) ? 'warning' : 'success'}
                    message={message || ""}
                    onClose={closePopup}
                />
            }

            <MainHeader />
            <SideMenu />
            <div className="main-content">

                <div className="page-content user_management">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18">{uploadName || 'Bulk'} Upload</h4>
                                    {uploadTrue !== 'bulk' &&
                                        <a id="navigatePrivacy" className="back_btn" onClick={(e) => { handleBack(e) }} href="#"><span className="material-icons icon"> arrow_back
                                        </span>BACK</a>}
                                </div>

                                <div className="card" >
                                    <div className="card-body bulk_upload" >
                                        {uploadTrue === 'bulk' &&
                                            <div className='bulk_upload_items'>
                                                {uploadFilesJson?.map((item) => {
                                                    return (
                                                        <div className='item'>
                                                            <div>
                                                                <h3>{item.heading}</h3>
                                                                <a href={item.downloadtemplate} download={item.name} className='sample_btn'><span class="material-symbols-outlined icon">
                                                                    download
                                                                </span>Download sample template</a>
                                                            </div>
                                                            <a href='#' onClick={(e) => { handleUpload(e, item.headers, item.name) }} className='upload'>
                                                                <span class="material-symbols-outlined icon"> upload </span>
                                                            </a>
                                                        </div>
                                                    )
                                                })}

                                            </div>}

                                        {uploadTrue === 'upload' &&
                                            <div className='upload_block'>
                                                <div className="file-input">
                                                    <input type="file" name="file-input" onChange={handleFileImport} accept=".xlsx, .xls" id="file-input" className="file-input__input" />
                                                    <label className="file-input__label" for="file-input">
                                                        <span className="material-symbols-outlined icon"> upload </span>
                                                        <h4>Click the Icon to select the file </h4>
                                                        <p>Only Excel (.xls) Files Allowed </p>
                                                    </label >
                                                </div>
                                            </div>}
                                        {uploadTrue === 'status' &&
                                            <div className='import_content'>
                                                <div className='content_status'>
                                                    <h3>UPLOADED LOANS STATUS </h3>

                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            {/* <p><span className="material-symbols-outlined icon green"> check_circle </span>O Titles Imported Successfully </p> */}
                                                            <p className='mb-0'><span className="material-symbols-outlined icon green"> check_circle </span>{failedData?.successCount || 0} Loans updated successfully </p>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {/* <p><span className="material-symbols-outlined icon red"> warning </span>Duplicate Titles  </p> */}
                                                            <p className='mb-0'><span className="material-symbols-outlined icon red"> warning </span>{failedData?.failedCount || 0} Loans Failed to upload </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {uploadName == "Loan" && failedData?.failedLoans?.length > 0 &&
                                                <div className='import_table'>
                                                    <div className='hed'>
                                                        <h4>Failed Loans List </h4>
                                                        <button id="form98Import" class="create_btn " onClick={handleDownload} >Download</button>
                                                    </div>
                                                   
                                                    <table className="table table-bordered align-middle nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Loan ID</th>
                                                                <th scope="col" >Borrower Name</th>
                                                                <th scope="col">Loan Amount</th>
                                                                <th scope="col">Reason</th>
                                                                <th scope="col"> Created On</th>

                                                            </tr>
                                                        </thead>
                                                        {failedData?.failedLoans?.length > 0 ? failedData?.failedLoans?.map((option, index) => {
                                                            return (
                                                                <tbody key={index}>
                                                                    <tr>
                                                                        <td scope="row">{option && option.loanid ? option.loanid : ""}</td>
                                                                        <td>
                                                                        {option && option.borrowerfirstname ? option.borrowerfirstname + option.borrowerlastname  : ""}</td>
                                                                        <td>{option && option.loanamount ? option.loanamount : ""}</td>
                                                                        <td>{option && option.rejectedreason ? option.rejectedreason : ""}</td>
                                                                        <td>{option && option.created ? moment(option.created).format('MM-DD-YYYY') : ''}</td>
                                                                        
                                                                    </tr>

                                                                </tbody>)
                                                        }) : failedData?.length === 0 && <div className='item'>No Data found</div>}

                                                    </table>
                                                </div>
                                            }
                                            {uploadName == "Pipeline" && failedData?.failedLoans?.length > 0 &&
                                                <div className='import_table'>
                                                    <div className='hed'>
                                                        <h4>Failed Loans List </h4>
                                                        <button id="form98Import" class="create_btn " onClick={handleDownload} >Download</button>
                                                    </div>
                                                   
                                                    <table className="table table-bordered align-middle nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Loan ID</th>
                                                                <th scope="col">What Contact Was For</th>
                                                                <th scope="col">Notes</th>
                                                                <th scope="col">Communication Type</th>
                                                                <th scope="col">Communication Medium</th>
                                                                <th scope="col">Reason</th>
                                                                <th scope="col"> Created On</th>

                                                            </tr>
                                                        </thead>
                                                        {failedData?.failedLoans?.length > 0 ? failedData?.failedLoans?.map((option, index) => {
                                                            return (
                                                                <tbody key={index}>
                                                                    <tr>
                                                                        <td scope="row">{option && option.loanid ? option.loanid : ""}</td>
                                                                        <td>
                                                                        {option && option.reason ? option.reason : ""}</td>
                                                                        <td>{option && option.notes ? option.notes : ""}</td>
                                                                        <td>{option && option.communicationtype ? option.communicationtype : ""}</td>
                                                                        <td>{option && option.contact ? option.contact : ""}</td>
                                                                        <td>{option && option.rejectedreason ? option.rejectedreason : ""}</td>
                                                                        <td>{option && option.created ? moment(option.created).format('MM-DD-YYYY') : ''}</td>

                                                                    </tr>

                                                                </tbody>)
                                                        }) : failedData?.length === 0 && <div className='item'>No Data found</div>}

                                                    </table>
                                                </div>
                                            }
                                            {uploadName == "Servicing" && failedData?.failedLoans?.length > 0 &&
                                                <div className='import_table'>
                                                    <div className='hed'>
                                                        <h4>Failed Loans List </h4>
                                                        <button id="form98Import" class="create_btn" onClick={handleDownload} >Download</button>
                                                    </div>
                                                   
                                                    <table className="table table-bordered align-middle nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Loan ID</th>
                                                                <th scope="col">Reference</th>
                                                                <th scope="col">Amount From Borrower</th>
                                                                <th scope="col">Amount From Reserves</th>
                                                                <th scope="col">Prinicipal Amount</th>
                                                                <th scope="col">Reason</th>
                                                                <th scope="col"> Created On</th>

                                                            </tr>
                                                        </thead>
                                                        {failedData?.failedLoans?.length > 0 ? failedData?.failedLoans?.map((option, index) => {
                                                            return (
                                                                <tbody key={index}>
                                                                    <tr>
                                                                        <td scope="row">{option && option.loanid ? option.loanid : ""}</td>
                                                                        
                                                                        <td>{option && option.reference ? option.reference : ""}</td>
                                                                        <td>{option && option.amountfromborrower ? option.amountfromborrower : ""}</td>
                                                                        <td>
                                                                        {option && option.amountfromreserves ? option.amountfromreserves  : ""}</td>
                                                                        <td>{option && option.principalamount ? option.principalamount : ""}</td>
                                                                        <td>{option && option.rejectedreason ? option.rejectedreason : ""}</td>
                                                                        <td>{option && option.created ? moment(option.created).format('MM-DD-YYYY') : ''}</td>

                                                                    </tr>

                                                                </tbody>)
                                                        }) : failedData?.length === 0 && <div className='item'>No Data found</div>}

                                                    </table>
                                                </div>
                                            }
                                            {uploadName == "Draw" && failedData?.failedLoans?.length > 0 &&
                                                <div className='import_table'>
                                                    <div className='hed'>
                                                        <h4>Failed Loans List </h4>
                                                        <button id="form98Import" class="create_btn" onClick={handleDownload} >Download</button>
                                                    </div>
                                                   
                                                    <table className="table table-bordered align-middle nowrap">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Loan ID</th>
                                                                <th scope="col">Scope of Work</th>
                                                                <th scope="col">Contractor</th>
                                                                <th scope="col">Requested Amount</th>
                                                                <th scope="col">Reason</th>
                                                                <th scope="col"> Created On</th>

                                                            </tr>
                                                        </thead>
                                                        {failedData?.failedLoans?.length > 0 ? failedData?.failedLoans?.map((option, index) => {
                                                            return (
                                                                <tbody key={index}>
                                                                    <tr>
                                                                        <td scope="row">{option && option.loanid ? option.loanid : ""}</td>
                                                                        <td>
                                                                        {option && option.scopeofwork ? option.scopeofwork  : ""}</td>
                                                                        <td>{option && option.contractor ? option.contractor : ""}</td>
                                                                        <td>{option && option.requestedamount ? option.requestedamount : ""}</td>  
                                                                        <td>{option && option.rejectedreason ? option.rejectedreason : ""}</td>
                                                                        <td>{option && option.created ? moment(option.created).format('MM-DD-YYYY') : ''}</td>

                                                                    </tr>

                                                                </tbody>)
                                                        }) : failedData?.length === 0 && <div className='item'>No Data found</div>}

                                                    </table>
                                                </div>
                                            }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <HomeFooter />
            </div>



        </div>
    );
};

export default BulkUpload;