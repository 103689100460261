import React, { useEffect, useState } from 'react';
import data from "../../JsonData/data.json";
import SweetAlert from 'react-bootstrap-sweetalert'; 
const PipelinePermissions = (props) => {
    const [alert,setAlert] = useState(false);
    const [createUserData,setCreateUserData] = useState([]);
    const [renderState,setRenderState] =  useState(false);
    const sectiondata = props?.sectiondata;//total json
    const copiedJson = sectiondata;
   
    const toggleData = data?.roledata[0].toggles;//toggle data
    const roleVal = props?.roleName;//selected role name
    const [pipelinePermissions, setPipelinePermissions] = useState(props?.userData);

 

    
    // const sectionKeys = Object.keys(sectionData);
       
    const columns = []; 
    let column,column2;

    const trimString = (str) => {
      
        return str?.replace(/\s+/g, '').trim();
    };
   

    const renderElements = () => {
                for (let i = 0; i < props?.pipelineMenus?.length; i += 8) {
                    column = props?.pipelineMenus.slice(i, i + 8).map((element, index) => (
                        <div key={index}>
                            <div className="con">
                                {element.menu}
                            </div>
                            {/* Render Sections for Main Menu */}
                            {element?.sections && element?.sections.length > 0 && (
                                element.sections.map((section, sectionIndex) => (
                                    <div key={section?.name} className="con sub_sub">
                                        {section?.name}
                                    </div>
                                ))
                            )}
                        {/* {element.submenus && element.submenus.length > 0 && (
                            
                            element.submenus.map((submenu, submenuIndex) => (
                                 <div key={submenuIndex} className="con sub">
                                     {submenu.menu}
                                     
                                 </div>
                                 
                                
                            ))
                        
                        )} */}
                        {element.submenus && element.submenus.length > 0 && (
                            element.submenus.map((submenu, submenuIndex) => (
                                <React.Fragment key={submenuIndex}>
                                    {/* Submenu Name */}
                                    <div className="con sub">
                                        {submenu.menu}
                                    </div>
                                    

                                    {/* Sections Rendered Outside */}
                                    {submenu?.sections && submenu?.sections.length > 0 && (
                                        
                                        submenu?.sections?.map((section, sectionIndex) => (
                                            
                                            <div key={section?.name} className="con sub_sub">
                                                {section?.name}
                                            </div>
                                            
                                        ))
                                    )}
                                </React.Fragment>
                            ))
                        )}

                        </div>
                                    
                    ));
                
                    column2 = props?.pipelineMenus.slice(i, i + 8).map((element, index) => (
                     
                       <div key={index}><div className="con">
                            {roleVal !== "" &&
                                toggleData?.map((toggle,toggleIndex) => {
                                    const founduserDataPermissions = props.userData && props.userData.find(rp => rp.route === element.route);
                                    const checkBool = (founduserDataPermissions && toggle.name in founduserDataPermissions) ? founduserDataPermissions[toggle.name] :false;
                                   
                                    return(
                                        <div className="column" key={toggleIndex}> 
                                            {element[toggle.name] &&<label className="toggle" >
                                                <input id={`${trimString(element?.menu)}-${toggle.name}-${toggleIndex}`} className="toggle-checkbox"
                                                    type="checkbox" checked = {checkBool}  onChange={(e) => handleToggle(e, element.route)} name={toggle.name+","+element.menu+","+roleVal+","+checkBool}/>
                                                <div className="toggle-switch"></div>

                                                </label>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>   
                        {/* Render Sections for Main Menu */}
                        {element.sections && element.sections.length > 0 && (
                            element.sections.map((submenuData, submenuIndexNumber) => (
                                <div key={submenuIndexNumber} className="con">
                                    {roleVal !== "" &&
                                        toggleData?.map((toggle, toggleIndex) => {
                                            const founduserDataPermissions = props?.userData && props?.userData?.find(rp => rp.route === element.route);
                                            
                                            const founduserDatasubPermissionsValue = founduserDataPermissions && founduserDataPermissions?.sections && founduserDataPermissions?.sections?.find(rp => rp.name === submenuData.name);
                                            //console.log("founduserDatasubPermissionsValue",founduserDatasubPermissionsValue)
                                            const checkBool = (founduserDatasubPermissionsValue && toggle.name in founduserDatasubPermissionsValue) ? founduserDatasubPermissionsValue[toggle.name] : false;
                                            
                                            // const foundSection = submenu.sections.find(sec => sec.name === submenuData.name);
                                            // const checkBool = foundSection ? foundSection[toggle.name] : false;

                                            return (
                                                submenuData[toggle.name] && <div className="column" key={toggleIndex}>
                                                    <label className="toggle" key={toggleIndex}>
                                                        <input id= {`${trimString(submenuData?.menu)}-${toggle.name}-${toggleIndex}`} className="toggle-checkbox"
                                                            type="checkbox" checked={checkBool} onChange={(e) => handleMainSectionsToggle(e, element.route, element.menu, submenuData.name)} name={toggle.name + "," + submenuData.name + "," + roleVal + "," + checkBool} />
                                                        <div className="toggle-switch"></div>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>




                            ))
                        )}
                        
                        {element.submenus && element.submenus.length > 0 && (
                            element.submenus.map((submenu, submenuIndex) => (
                                <div key={submenuIndex} >
                                    <div key={submenuIndex} className="con">
                                        {roleVal !== "" &&
                                    toggleData?.map((toggle,toggleIndex) => {
                                        const foundUserDataPermissions = props?.userData && props?.userData?.find(rp => rp.route === element.route);
                                        //console.log("foundUserDataPermissions",foundUserDataPermissions);
                                        const foundUserDataSubPermissions = foundUserDataPermissions && foundUserDataPermissions?.submenus && foundUserDataPermissions?.submenus?.find(rp => rp.menu === submenu.menu);
                                        //console.log("foundUserDataSubPermissions",foundUserDataSubPermissions);
                                        const checkBool = (foundUserDataSubPermissions && toggle.name in foundUserDataSubPermissions) ? foundUserDataSubPermissions[toggle.name] : false;
                                        //console.log("checkBool",checkBool);
                                        return(
                                            submenu[toggle.name] && <div className="column" key={toggleIndex}> 
                                                <label className="toggle" key={toggleIndex}>
                                                <input id={`${trimString(submenu?.menu)}-${toggle.name}-${toggleIndex}`} className="toggle-checkbox"
                                                    type="checkbox" checked={checkBool} onChange={(e) => handlesubToggle(e, element.route, submenu.menu)} name={toggle.name+","+submenu.menu+","+roleVal+","+checkBool}/>
                                                <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                                    </div>
                                    {submenu.sections && submenu.sections.length > 0 && (
                                        submenu.sections.map((submenuData, submenuIndexNumber) => (
                                            <div key={submenuIndexNumber} className="con">
                                                {roleVal !== "" &&
                                                    toggleData?.map((toggle, toggleIndex) => {
                                                        const founduserDataPermissions = props?.userData && props?.userData?.find(rp => rp.route === element.route);
                                                        const founduserDatasubPermissions = founduserDataPermissions && founduserDataPermissions?.submenus && founduserDataPermissions?.submenus?.find(rp => rp.menu === submenu.menu);
                                                        const founduserDatasubPermissionsValue = founduserDatasubPermissions && founduserDatasubPermissions?.sections && founduserDatasubPermissions?.sections?.find(rp => rp.name === submenuData.name);
                                                        //console.log("founduserDatasubPermissionsValue",founduserDatasubPermissionsValue)
                                                        const checkBool = (founduserDatasubPermissionsValue && toggle.name in founduserDatasubPermissionsValue) ? founduserDatasubPermissionsValue[toggle.name] : false;
                                                        
                                                        // const foundSection = submenu.sections.find(sec => sec.name === submenuData.name);
                                                        // const checkBool = foundSection ? foundSection[toggle.name] : false;

                                                        return (
                                                            submenuData[toggle.name] && <div className="column" key={toggleIndex}>
                                                                <label className="toggle" key={toggleIndex}>
                                                                    <input id={`${trimString(submenuData?.menu)}-${toggle.name}-${toggleIndex}`} className="toggle-checkbox"
                                                                        type="checkbox" checked={checkBool} onChange={(e) => handleSectionsToggle(e, element.route, submenu.menu, submenuData.name)} name={toggle.name + "," + submenuData.name + "," + roleVal + "," + checkBool} />
                                                                    <div className="toggle-switch"></div>
                                                                </label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>




                                        ))
                                    )}

                                </div>
                            ))
                        )}
                        </div>       
                          
                    ));
                    
                    columns.push(
                        <div className="col-md-12" key={i}>
                            <div className="check_group">
                                <div className="left">
                                    <div className="hed"></div>
                                        {column}
                                        {/* <div className="con sub">Sub menu</div>
                                        <div className="con sub_sub">Sub sub menu</div> */}
                                    </div>
                                    <div className="right">
                                        <div className="hed">
                                            <div className="column">EDIT</div>
                                            <div className="column">VIEW</div>
                                            {/* <div className="column">DELETE</div> */}
                                        </div> 
                                        {column2}
                                    </div>
                                        
                                    
                                    
                
                                </div>
                            </div>
                            
                        
                    );
                
                }
               
        
        
   
        return columns
    };

    const handleToggle = (e, route) => {
        setRenderState(!renderState);
        const delimiter = ",";
        const str = e.target.name;
        const toggleVal = str.split(delimiter);
        const toggName = toggleVal[0];
        const toggPerm = toggleVal[1];
        const toggRole = toggleVal[2];
        const toggSec = toggleVal[3];
        const toggswitch = toggleVal[4];
        // console.log(typeof(toggswitch));
        let tempData = JSON.parse(JSON.stringify(props?.userData));
        tempData.map((data, dataIndex) => {
            if (data?.route === route) {
                let checkedValue = e.target.checked
                data[toggName] = e.target.checked;
                if(toggName == "view" && checkedValue == false){
                    data["edit"] = checkedValue  
                }
            }
        });
    console.log('tempData',tempData);
    setPipelinePermissions(tempData)
        props?.childUserData(tempData);
    }

    
    const handlesubToggle = (e, route, submenuId) => {
        setRenderState(!renderState);
        const delimiter = ",";
        const str = e.target.name;
        const toggleVal = str.split(delimiter);
        const toggName = toggleVal[0];
        const toggPerm = toggleVal[1];
        const toggRole = toggleVal[2];
        const toggSec = toggleVal[3];
        const toggswitch = toggleVal[4];
         let tempData = JSON.parse(JSON.stringify(props?.userData));
         tempData = tempData.map((data, dataIndex) => {
            if (data?.route === route) {
                if (data?.submenus) {
                    const submenuIndex = data.submenus.findIndex(rp => rp.menu === submenuId);
                    let checkedValue = e.target.checked
                    if (submenuIndex !== -1) { 
                        let updatedSubmenu = { ...data.submenus[submenuIndex], [toggName]: e.target.checked };
                        if(toggName == "view" && checkedValue == false){
                            updatedSubmenu["edit"] = checkedValue  
                        }
                        data.submenus[submenuIndex] = updatedSubmenu;
                    }
                }
            }
            return data; 
          });
          console.log('tempData2',tempData);
          setPipelinePermissions(tempData)
         props?.childUserData(tempData);
    }

    const handleMainSectionsToggle = (e, route, submenuId , sectionName) => {
        setRenderState(!renderState);

        console.log("main sections",route, submenuId , sectionName)
        const delimiter = ",";
        const str = e.target.name;
        const toggleVal = str.split(delimiter);
        const toggName = toggleVal[0];
        const toggPerm = toggleVal[1];
        const toggRole = toggleVal[2];
        const toggSec = toggleVal[3];
        const toggswitch = toggleVal[4];

        // if (toggName === "edit" && roleVal !== "admin" && roleVal !== "underwriter") {
        //     return; // Prevent changes for non-admin and non-underwriter roles
        // }


         let tempData = JSON.parse(JSON.stringify(props?.userData));
         tempData = tempData.map((data, dataIndex) => {
            if (data?.route === route) {
                if (data?.sections) {
                    const submenuIndex = data.sections.findIndex(rp => rp.name === sectionName);
                    let checkedValue = e.target.checked
                    if (submenuIndex !== -1) { 
                        let updatedSubmenu = { ...data.sections[submenuIndex], [toggName]: e.target.checked };
                        if(toggName == "view" && checkedValue == false){
                            updatedSubmenu["edit"] = checkedValue  
                        }
                        data.sections[submenuIndex] = updatedSubmenu;
                    }
                }
            }
            return data; 
          });
          console.log('tempData2',tempData);
          setPipelinePermissions(tempData)
         props?.childUserData(tempData);
    }
    
   
    
    const handleSectionsToggle = (e, menuid, submenuId, sectionName) => {
        console.log("handleSectionsToggle", menuid, submenuId, sectionName);

        
    
        const [toggName, section_name] = e.target.name.split(",");
        console.log("toggName",toggName);

        
        // if (toggName === "edit" && roleVal !== "admin" && roleVal !== "underwriter") {
        //     return; // Prevent changes for non-admin and non-underwriter roles
        // }
        

        const checkedValue = e.target.checked;
    
        let tempData = JSON.parse(JSON.stringify(props?.userData));
        console.log("initia temp",tempData);
    
        tempData = tempData.map((mainMenu) => {
            // if (mainMenu.route === menuid && mainMenu.submenus) {
            //     mainMenu.submenus.forEach((submenu) => {
            //         if (submenu.menu === submenuId && submenu.sections) {
            //             submenu.sections.forEach((section) => {
            //                 if (section.name === section_name) {
            //                     section[toggName] = checkedValue;
    
            //                     // If 'view' is unchecked, also uncheck 'edit'
            //                     if (toggName === "view" && !checkedValue) {
            //                         section["edit"] = false;
            //                     }
            //                 }
            //             });
            //         }
            //     });
            // }
            if(mainMenu?.route === menuid){
                //console.log("mainMenu?.route === menuid",mainMenu?.route,menuid)
                if(mainMenu?.submenus) {
                    //console.log("mainMenu?.submenus === submenus",mainMenu?.submenus)
                    mainMenu?.submenus?.forEach((submenu) => {
                        if (submenu?.menu === submenuId){
                            //console.log("submenu?.menu === submenuId",submenu?.menu,submenuId )
                            if(submenu?.sections) {
                                submenu?.sections?.map((section) => {
                                    if (section?.name == sectionName) {
                                        //console.log("section.name === sectionName",section.name,sectionName )
                                        section[toggName] = checkedValue;
                                        //console.log("section[toggName]",section[toggName] )
                                        // If 'view' is unchecked, also uncheck 'edit'
                                        if (toggName === "view" && !checkedValue) {
                                            section["edit"] = false;
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            } 
            return mainMenu; 
        });
    
        console.log("Updated tempData", tempData);
        setPipelinePermissions(tempData);
        props?.childUserData(tempData);
        setRenderState(!renderState);
    };


    // const handleSectionsToggle = (e, menuid, submenuId, sectionName) => {
    //     setRenderState(!renderState);
    
    //     const delimiter = ",";
    //     const str = e.target.name;
    //     console.log("str", str);
    
    //     const toggleVal = str.split(delimiter);
    //     console.log("toggleVal", toggleVal, e.target.checked);
    
    //     const toggName = toggleVal[0];
    //     const toggPerm = toggleVal[1]; 
    //     const toggRole = toggleVal[2]; 
    //     const toggSec = toggleVal[3];  
    //     const toggSwitch = toggleVal[4];
    
    //     let tempData = JSON.parse(JSON.stringify(props.userData));
    
    //     tempData = tempData.map((data, dataIndex) => {
    //         if (data?.route === menuid) {
    //             if (data?.submenus) {
    //                 const subMenu = data.submenus.find(rp => rp.menu === submenuId);
    //                 const subMenuIndex = data.submenus.findIndex(rp => rp.menu === submenuId);
                    
    //                 if (subMenu && subMenu?.sections) {
    //                     const sectionIndex = subMenu.sections.findIndex(sec => sec.name === sectionName);
    
    //                     if (sectionIndex !== -1) {
    //                         let checkedValue = e.target.checked;
    //                         let updatedSection = {
    //                             ...data.submenus[subMenuIndex].sections[sectionIndex],
    //                             [toggName]: checkedValue
    //                         };
    
    //                         if (toggName === "view" && !checkedValue) {
    //                             updatedSection["edit"] = false;
    //                         }
    
    //                         data.submenus[subMenuIndex].sections[sectionIndex] = updatedSection;
    //                     }
    //                 }
    //             }
    //         }
    //         return data;
    //     });
    
    //     console.log("Updated tempData", tempData);
    //     setPipelinePermissions(tempData);
    //     props?.childUserData(tempData);
    // };
    
    

    const handleToggRoleEmpty = (e) => {
        if(roleVal === ''){
            setAlert(!alert);
           
        }
    }

    const handleAlertClose = () => {
        setAlert(!alert);
    }


    useEffect(() => {
       
        renderElements()
                
    },[renderState])

    useEffect(() => {
        if(props?.userData){
            console.log("props ------- >userData",props?.userData);
        }
        
    },[props.userData])

    

    return(
        <div className="section">
           
            <div className='row'>
                <h3>Pipeline Menus</h3>
                {renderElements()}
            </div>
            {alert && <SweetAlert
            warning
            title="Select Role is Empty"
            onConfirm={handleAlertClose}
            >
            {/* Display your validation error message */}
            Please select role 
            </SweetAlert>}
        </div>
        
    );
};

export default PipelinePermissions;