import React, { useState, useEffect } from 'react';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import { useDispatch, useSelector } from 'react-redux';
import {createFeeManagement , getAllServiceFee,singleFeeGet,updateFeeManagement,deleteFeeManagement,getAllFundManagmentAdmin} from "../utils/reducer";
import {getUserDataFromLocalStorage } from "../utils/local.js";
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import DataTable from 'react-data-table-component';
import SweetAlert from 'react-bootstrap-sweetalert';  
import { useLoanInfo } from '../utils/loanInfoContext';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import Blockingtextmsg from './Blockingtext';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { formatCurrency } from '../utils/commonUtils.js';
import { useLocation } from 'react-router-dom';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';

const FeeManagement = () => {
    const blockingtext = 'Fee Management cannot be accessed until servicing setup gets completed';
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const loading = useSelector((state) => state.loaderVisible);
    const [showForm,setShowForm] = useState('Table');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('');
    const configdata = useSelector(state => state.configdata);
    let showServicingForms = configdata?.common?.showServicingForms || true;
    const [feeFormType,setFeeFormType] = useState('create');
    const [FundsArray,setFundsArray] = useState([]);
    const [fundsDropdown,setFundsDropdown] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [feeDataTable,setFeeDataTable] = useState([]);
    const [filterVariable,setFilterVariable] = useState('');
    const [defaultFund,setDeafaultFund] = useState('');
    let userData = getUserDataFromLocalStorage();
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );

    const columns = [
        {
          name: 'Fee',
          selector: (row) => row?.quickFee?.[0] ==='Other' ? row?.otherfeetitle : row?.quickFee,
        //   sortable: true,
          width: '29%',
        },
        {
            name: 'Effective Date',
            selector: (row) => row?.effectiveDate ?  moment.utc(row?.effectiveDate).format("MM-DD-YYYY") : '',
          //   sortable: true,
            width: '15%',
          },
        {
          name: 'AMOUNT (USD)',
          selector: (row) => row?.feeAmount ? formatCurrency(row?.feeAmount) : '$0.00',
        //   sortable: true,
          width: '20%',
        },
        // {
        //     name: 'Interest Rate',
        //     selector: (row) => row?.interestrate || '',
        //   //   sortable: true,
        //     width: '16%',
        //   },
        {
          name: 'Notes',
          selector: (row) => row?.notes || '',
        //   sortable: true,
          width: '30%',
        },
        
        
        {
          name: 'Actions',
          cell: (row, index) => (
            <div className="btn-group">
              <button
                id={`edit-${index}`}
                type="button"
                onClick={() => handleEdit(row)}
                className="btn btn-outline-secondary"
              >
                <span className="material-symbols-outlined icon tooltip-container">
                  <span className="tooltip">Edit</span>edit
                </span>
              </button>
              <button
                id={`delete-${index}`}
                type="button"
                onClick={() => handleDeleteClick(row)}
                className="btn btn-outline-secondary"
              >
                <span className="material-symbols-outlined icon tooltip-container">
                  <span className="tooltip">Delete</span>delete
                </span>
              </button>
            </div>
          ),
          width: '6%',
        },
    ];

    const [deleteConfirmation,setDeleteConfirmation] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [feeValue, setFeeValues] = useState({});
    const [feeValueCopy,seFeeValueCopy] = useState({});
    const [feeFormError,setFeeFormError] = useState({});
    const [funds,setFunds] = useState({});
    const [fundsCopy,setFundsCopy] = useState({});
    const [disableFields,setDisableFields] = useState(false);
    const [fundDeleteConfirmation,setFundDeleteConfirmation] = useState(false);
    const [fundDisburseConfirmation,setFundDisburseConfirmation] = useState(false);
    const [deleteFund,setDeleteFund] = useState({});
    const [disburseFund,setDisburseFund] = useState({});


    const initializer =()=>{
        setPage(1); setShowForm('Table');setFeeValues({});setPerPage(10);setTotalRows(0);setDeleteConfirmation(false);setmessage('');
        setFilterVariable('');seFeeValueCopy({});
    }
    useEffect(() => {
        if(loanid){
            initializer();
        }
      }, [loanid]);
    useEffect(() => {
        setLoanid(loanInfo?.loanid)
    }, [loanInfo?.loanid]);
    useEffect(() => {
        getFundsName()
    }, []);

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.pipelinePermissions
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

    const getFundsName =()=>{
        let type = 'fundDropdown'
        getAllFundManagmentAdmin('','',type,'')
        .then(function(response) {
            console.log('funds name',response)
            if (response?.result?.data) {
                setFundsDropdown(response.result?.data.map((fundoption) => ({
                    value: fundoption?.name,
                    label: fundoption?.name,
                    fundid : fundoption?.uuid,
                })));
                const defaultFund = response.result.data.find(fund => fund.default === true);
                setDeafaultFund(defaultFund?.name);
                setFunds({'fundname' : defaultFund });
            }else{
                setFundsDropdown([]);
            }
        })
        .catch((err) => {
            console.error("error", err); 
            dispatch(hideLoader());
        });
        
    }

    useEffect(() => {
       console.log('feevaleueee', feeValue)
    }, [feeValue]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFeeValues(prev => ({
                ...prev,
                [name]: checked,
            }));
        } else if (name === 'quickFee') {
            setFeeValues(prev => ({
                ...prev,
                'feeAmount' : value === 'NSF Fee' ? 25 : value === 'Attorney Fee' ? 1500 : '',
                [name]: [value], // Wrap in an array
            }));
        } else if (name === 'interestrate') {
            const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
            const updatedValue = isNaN(parsedValue) ? '' : parsedValue;
            console.log('interestrate---->',updatedValue);
            setFeeValues(prev => ({
                ...prev,
                [name]: updatedValue <= 100 ? updatedValue : prev[name],
            }));
        } else {
            setFeeValues(prev => ({
                ...prev,
                [name]: value,
            }));
        };
        setDisableFields(true);
    };


    const handleEdit =(data)=>{
        setFeeValues(data);
        seFeeValueCopy(data);
        setFundsArray(data?.fund);
        // if(data?.fund?.length>0){
        //     setDisableFields(true);
        // }else{
            setDisableFields(false);
        // }
        setShowForm('form');
        setFeeFormType('edit');
        setFunds({'fundname' : defaultFund });
        setFundsCopy({})
    }
    const handleDeleteClick =(data)=>{
        setFeeValues(data);
        setDeleteConfirmation(true);
    };
    const handleDelete =()=>{
        setDeleteConfirmation(false);
        deleteFee();
    }
    const deleteFee = (feeid)=>{
        dispatch(showLoader())
        deleteFeeManagement(loanInfo?.loanid,feeValue?.feeid).then(function (response) {
            if (response?.result) {
                console.log("get single fee api res",response?.result);
                setmessage('Deleted Successfully');
                setPopupOpen(true);
                setFeeValues({});
                getAllFeeApi();
            }
            dispatch(hideLoader());
            }).catch((err) => {
              dispatch(hideLoader());
              console.error.bind("get all  fee api res", err);
            })
    };
    const handleCreate= ()=>{
        setShowForm('form');
        setFeeValues({});
        setDisableFields(true);
        setFeeFormType('Create');
        setFundsArray([]);
        setFunds({'fundname' : defaultFund });
        setFundsCopy({})
        setFeeFormError({});
    }

    const handleBack =()=>{
        setShowForm('Table');
        setFeeValues({});
        seFeeValueCopy({});
        setFeeFormError({});
        getAllFeeApi();
        setFilterVariable('')
    }

     //get all fee API
     useEffect(() => {
        if(loanInfo?.loanid){
            getAllFeeApi();
        }
    },[loanInfo?.loanid]);

    const getAllFeeApi = ()=>{
        dispatch(showLoader());
        let pagename = "servicefee"
        getAllServiceFee(loanInfo?.loanid,pagename).then(function (response) {
            setFilterVariable('')
            if (response?.result) {
                console.log("get all fee api res",response?.result);
                setData(response?.result);
                setFeeDataTable(response?.result);
            }
            dispatch(hideLoader());
            }).catch((err) => {
              dispatch(hideLoader());
              console.error.bind("get all  fee api res", err);
            })
    };

    const singleFee = (feeid)=>{
        dispatch(showLoader())
        singleFeeGet(loanInfo?.loanid,feeid).then(function (response) {
            if (response?.result) {
                console.log("get single fee api res",response?.result);
                setFeeValues(response?.result?.[0]);
                seFeeValueCopy(response?.result?.[0]);
                setDisableFields(false);
            }
            dispatch(hideLoader());
            }).catch((err) => {
              dispatch(hideLoader());
              console.error.bind("get all  fee api res", err);
            })
    };

    const updateFeeandFund = (apiData)=>{
        dispatch(showLoader())
        updateFeeManagement(apiData,loanInfo?.loanid,feeValue?.feeid).then(function (response) {
            
            if(response?.result === 'Updated Successfully'){
                setPopupOpen(true);
                setDisableFields(false);
            }else if (response?.result) {
                console.log("update fee fund",response?.result);
                // if(apiData?.fund?.length>0){
                //    setDisableFields(true);
                setFunds({'fundname' : defaultFund });
                   setFundsCopy({});
                // }else if(apiData?.fund?.length === 0){
                //     setDisableFields(false);
                // }
                // else{
                   setDisableFields(false);
                // };
                console.log("apiData",apiData?.fund.length);
                setPopupOpen(true);
            }
            handleFundChange();
            dispatch(hideLoader());
            }).catch((err) => {
              dispatch(hideLoader());
              console.error.bind("get all  fee api res", err);
            })
    }
    


    const handleSave =()=>{
       
        let fieldsCheck = {
            quickFee: "quickFee",
            notes: "notes",
            feeAmount: "feeAmount",
            effectiveDate: "effectiveDate",
          };
          if(feeValue?.quickFee?.[0] === 'Other Fee' || feeValue?.quickFee ==='Other Fee'){
            fieldsCheck['otherfeetitle'] = 'otherfeetitle'
          }
          console.log(fieldsCheck,feeValue);
          const errors = {};
          for (const [fieldName, fieldLabel] of Object.entries(fieldsCheck)) {
            let value = feeValue[fieldName];
             if (typeof value === 'number') {
              }else{
                if (!feeValue[fieldName]) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
              }
          };
        setFeeFormError(errors);

        

        if (Object.keys(errors).length === 0) {
            //update flow
            if(feeValue?.feeid){
                let feeApiData = {
                    ...feeValue,
                }
                delete feeApiData.fund;
                let apiData = {
                    ...feeApiData,
                    feeAmount: Number(feeValue?.feeAmount) || 0,  
                    fund: FundsArray,
                };
                if(feeValue?.quickFee?.[0] !== 'Other Fee'){
                    delete apiData.otherfeetitle; 
                }
                setmessage('Fee Updated Successfully');
                updateFeeandFund(apiData);
                seFeeValueCopy(feeValue);
                return;
            }
           
//add flow
            let feeValueWithLoanId = { 
                ...feeValue, 
                loanid: loanid,
                feeAmount: Number(feeValue?.feeAmount) || 0,  // Convert to number, fallback to 0 if NaN
                // interestrate: Number(feeValue?.interestrate) || 0 
            }; 
            console.log("Fee management data",feeValueWithLoanId);
            delete feeValueWithLoanId['fund'];
            if(feeValue?.quickFee?.[0] !== 'Other Fee'){
                delete feeValueWithLoanId.otherfeetitle; 
            }
            dispatch(showLoader());
            createFeeManagement(feeValueWithLoanId).then(function (response) {
                if (response?.result) {
                    console.log("create fee api res",response?.result);
                    setmessage('Fee Created Successfully');
                    setPopupOpen(true);
                    singleFee(response?.result);
                    handleFundChange()
                }
                dispatch(hideLoader());
                }).catch((err) => {
                  dispatch(hideLoader());
                  console.error.bind("create fee api res", err);
                })
            
        }
    };

    const handleAddFund =()=>{
        if(!funds?.percent || !funds?.disbursedAmount || !funds.fundname){
            return;
        }
        if (funds?.fundid) {
            let userData = getUserDataFromLocalStorage();
            let updatedDate = new Date().toISOString();
            const updatedFund = {
                ...funds,
                disbursedAmount: parseInt(funds?.disbursedAmount),
                updateddate: updatedDate,
                updatedBy: userData?.username,
            };
            setFundsArray((prev) => {
                const updatedArray = prev.map((item) =>
                    item.fundid === updatedFund.fundid ? { ...item, ...updatedFund } : item
                );
                let apiData = {
                    fund: updatedArray,
                };
                updateFeeandFund(apiData);
        
                return updatedArray;
            });
            setmessage('Funds Updated Successfully');
        }
        else{
            let userData = getUserDataFromLocalStorage();
            let ObjectID = require("bson-objectid");
            let fundid = new ObjectID().toString(); 
            let createddate = new Date().toISOString();
            const newFund = {
                ...funds, 
                disbursedAmount : parseInt(funds?.disbursedAmount),
                fundid: fundid, 
                createddate :createddate ,
                createdBy : userData?.username,
              };
            console.log('fundid---->',fundid);
            console.log('funds---->',funds);
            setFundsArray((prev) => [...(prev || []), newFund]);
            setmessage('Funds Saved Successfully');
            let apiData = {
                fund: FundsArray?.length > 0 ? [...FundsArray, newFund] : [newFund],
            };            
            updateFeeandFund(apiData);
        }
       
        setFunds({'fundname' : defaultFund });
        setFundsCopy({})
    };

    const fundDeleteClick =(fund)=>{
        setDeleteFund(fund);
        setFundDeleteConfirmation(true);
    }
    const handleDeleteFund = () => {
    setFundDeleteConfirmation(false);
    setFundsArray((prev) => {
        const updatedFunds = prev.filter((fund) => fund.fundid !== deleteFund?.fundid);
        let apiData = {
            fund: updatedFunds.length > 0 ? updatedFunds : [],
        };
        updateFeeandFund(apiData);
        return updatedFunds;
    });
    setFunds({'fundname' : defaultFund });
    setFundsCopy({})
    setmessage('Fund Deleted Successfully');
};

    // const handleDeleteFund = ()=>{
    //     setFundDeleteConfirmation(false);
    //     setFundsArray((prev) => prev.filter((fund) => fund.fundid !== deleteFund?.fundid));
    //     setFunds({  });
    //     setmessage('Fund Deleted Successfully');
    //     let apiData = {
    //         fund: [],
    //     };
    //     updateFeeandFund(apiData);
    // }

    const handleFocus=(e)=>{
        let name = e.target.name
        setFeeFormError(prev => ({
            ...prev,
            [name]: null,
        }));
    }

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };

     //usefeect to set the current date in effectivedate by dfault
     
     useEffect(() => {
        if (!feeValue?.effectiveDate) {
            setFeeValues(prevState => ({
                ...prevState,
                effectiveDate: new Date().toISOString().split('T')[0]
            }));
        }
    }, [feeValue?.effectiveDate]);

    /* Funds */
    const handleFunds = (name,value) => {
        console.log(name,value)
        if(name==='percent'){
            const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
            const updatedValue = isNaN(parsedValue) ? '' : parsedValue;
            const newDisbursedAmount = ((feeValueCopy?.feeAmount || 0) * (value / 100)).toFixed(2);
            setFunds(prevFunds => ({
                ...prevFunds,
                disbursedAmount: updatedValue <= 100 ? newDisbursedAmount : prevFunds['disbursedAmount'],
                [name]: updatedValue <= 100 ? updatedValue : prevFunds[name],
            }));
        }else if(name === 'fundname'){
            // const selectedFund = fundsDropdown.find((fund) => fund.value === value);
            setFunds(prevFunds => ({
            ...prevFunds,
            [name]: value,
            // fundid: selectedFund ? selectedFund?.fundid : '',
        }));
        if (funds?.fundid) {
            const isSameAsOriginal = fundsCopy?.fundname === value;
            if (isSameAsOriginal) {
                setDisableFields(false);
            } else {
                const isDuplicate = FundsArray?.some(fund => 
                    fund?.fundname === value && fund?.fundid !== funds?.fundid
                );
                setDisableFields(isDuplicate);
            }
        } else {
            const isDuplicate = FundsArray?.some(fund => fund?.fundname === value);
            setDisableFields(isDuplicate);
        }
        }
        else{
            setFunds(prevFunds => ({
                ...prevFunds,
                [name]: value
            }));
        }
    };
    const handleFundChange = ()=>{
        setFunds({fundname : defaultFund,  percent : 100 , disbursedAmount : feeValue?.feeAmount});
    }
    const handleFundEdit =(fund)=>{
        setFunds(fund);
        setFundsCopy(fund);
        setDisableFields(false);
    };
    const handleDisburse =(fund)=>{
        setFundDisburseConfirmation(true);
        let userData = getUserDataFromLocalStorage();
        let updateddate = new Date().toISOString();
        let DisburseData = {
            ...fund,
            disburseddate : updateddate,
            disbursedby : userData?.username,
        };
        setDisburseFund(DisburseData)
    };
    const confirmDisburse = ()=>{
        setFundDisburseConfirmation(false)
            setFundsArray((prev) =>
            prev.map((item) =>
            item.fundid === disburseFund.fundid ? { ...item, ...disburseFund } : item
        )
        );
        let apiData = {
            fund : [disburseFund]
        };
        setmessage('Fund Disbursed Successfully');
        updateFeeandFund(apiData);
    };

    const handleFilter = (e)=>{
        setFilterVariable(e.target.value);
        const filterValue = e.target.value;
        const filteredData = feeDataTable.filter(item => {
            return item.quickFee?.[0] === filterValue;
        });
        if(!filterValue){
            setData(feeDataTable)
        }else{
            setData(filteredData);
        }
    }

    const closePopup =()=>{
        setDeleteConfirmation(false);
        setFundDeleteConfirmation(false);
        setFundDisburseConfirmation(false);
    }
    const closePopup2 =()=>{
        setPopupOpen(false);
        if(popupMessage === 'Funds Saved Successfully' || popupMessage === 'Funds Updated Successfully'){
            setFeeValues({});
            setShowForm('Table');
            getAllFeeApi();
        }
        
    }

    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader /> : null}
            {isPopupOpen && (
                <PopupMessage
                    type={popupMessage.includes('Successfully') ? 'success' : 'warning'}
                    message={popupMessage}
                    onClose={closePopup2}
                />
            )}
            {deleteConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDelete}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-confirm-yes',
                        className: 'btn btn-primary',
                        onClick: handleDelete,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-confirm-no',
                        className: 'btn btn-light',
                        onClick: closePopup,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                <p>Do you want to delete this Fee</p>
            </SweetAlert>}
            {fundDeleteConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDeleteFund}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-fund-yes',
                        className: 'btn btn-primary',
                        onClick: handleDeleteFund,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-fund-no',
                        className: 'btn btn-light',
                        onClick: closePopup,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                <p>Do you want to delete this Fund</p>
            </SweetAlert>}
            {fundDisburseConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={confirmDisburse}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-disburse-yes',
                        className: 'btn btn-primary',
                        onClick: confirmDisburse,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-disburse-no',
                        className: 'btn btn-light',
                        onClick: closePopup,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                <p>Do you want to disburse this Fund</p>
            </SweetAlert>}
             <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Servicing<span className="space">&#10095;</span>  <span className="sub">Fee Management</span> </h2>
                {!loanInfo?.servicingsetup?.isServiceSetupCompleted && !configdata?.common?.showServicingForms ? 
                <Blockingtextmsg  textmessage={blockingtext}/> : 
<div className="card user_management detailed_app fee_management">
                <div className="card-body my_account">
                <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#ENTITY" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">FEES</span>
                            </a>
                        </li>
                </ul>
                    <div className="tab-content pt-15 text-muted">
                        <div className="tab-pane active" id="ENTITY" role="tabpanel" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                              }}>
                        {showForm==='Table' && 
                         <>
                          <div className="search">
                                <div className="d-flex align-items-center">
                                <h4 className="mb-sm-0 font-size-18 me-4">fees</h4>
                                <div className="btn-group">
                                    <button type="button" onClick={handleCreate} className="fillbtn_new add_button">Create<span className="material-icons">add</span></button>
                                   </div>
                         </div>
                        <div className="d-flex align-items-center">
                                <label>Filter</label>
                                <select className="form-control" value={filterVariable} onChange={handleFilter}>
                                    <option value ='' selected="">Select</option>
                                        <option value="Extension Fee">Extension Fee</option>
                                        <option value='Late Fee'>Late Fee</option>
                                        <option value='NSF Fee'>NSF fee</option>
                                        <option value='Servicing Fee'>Servicing Fee</option>
                                        <option value='Other Fee'>Other Fee</option>
                                </select>
                                        
                              </div>
                         </div>
                         {data?.length > 0  && <div className="table-responsive">
                                <DataTable className='contacts'
                                // key={tableKey}
                                    columns={columns}
                                    data={data}
                                    pagination
                                    customStyles={customStyles}
                                    paginationTotalRows={totalRows}
                                    paginationServer
                                    paginationPerPage={perPage}
                                    paginationDefaultPage={page}
                                    paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                    paginationComponentOptions={{
                                        rowsPerPageText: 'Per Page',
                                    }}
                                />
                            </div>}
                            </>
                         }
                            {showForm !== 'form' &&  data?.length === 0  &&
                                <div className="inprogress_page">
                                     <span className="material-symbols-outlined icon"> content_paste_search </span>
                                    <p>No Data Found</p>
                                </div>
                            }
                               
                                
                                {showForm === 'form' && 
                                <div className="normal_page create_entity">
                       
                                <div className="search">
                                    <h4 className="mb-sm-0 font-size-18">{feeValue?.feeid ? 'Update fees' : 'Create fees' }</h4>
                                    <a href="#" onClick={(e) => {e.preventDefault();handleBack();}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
            
                                        </div>
                                    <div className="form_section">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Fee</label>
                                                    <select 
                                                    className={`form-control ${feeFormError?.quickFee && 'invalid-input'}`}
                                                    onFocus={handleFocus}
                                                    name='quickFee' value={feeValue?.quickFee && feeValue?.quickFee[0] || ''} onChange={handleChange}>
                                                        <option value ='' selected="">Select Quick Fee</option>
                                                        {/* <option value ='Attorney Fee'>Attorney Fee</option> */}
                                                        <option value="Extension Fee">Extension Fee</option>
                                                        <option value ='Late Fee'>Late Fee</option>
                                                        <option value ='NSF Fee'>NSF Fee</option>
                                                        <option value ='Servicing Fee'>Servicing Fee</option>
                                                        {/* <option value ='One-time points'>One-time points</option>
                                                        <option value ='One-time amount'>One-time amount</option>
                                                        <option value ='Monthly recurring amount'>Monthly recurring amount</option> */}
                                                        <option value ='Other Fee'>Other Fee</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Effective Date</label>
                                                    <input type="date"
                                                    className={`form-control ${feeFormError?.effectiveDate && 'invalid-input'}`}
                                                    onFocus={handleFocus}
                                                    min={loanInfo?.wireinstructions?.actualwire_funddate 
                                                        ? new Date(loanInfo?.wireinstructions?.actualwire_funddate).toISOString().split('T')[0] 
                                                        : ''}
                                                    value={feeValue.effectiveDate ? new Date(feeValue.effectiveDate ).toISOString().split('T')[0] : ''}
                                                    // value={feeValue?.effectiveDate || ''}
                                                     name='effectiveDate'  onChange={handleChange}/>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Fee Amount</label>
                                                    <CurrencyInput
                                                            name='feeAmount'
                                                            className={`form-control ${feeFormError?.feeAmount && 'invalid-input'}`}
                                                            onFocus={handleFocus}
                                                            placeholder='$0.00'
                                                            value={feeValue?.feeAmount}
                                                            decimalsLimit={2}
                                                            prefix="$"
                                                            onValueChange={(value, name, values) => {
                                                                setFeeValues((prevData) => ({
                                                                    ...prevData,
                                                                        'feeAmount': values.value,
                                                                }));
                                                                setDisableFields(true);
                                                            }}
                                                        />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Interest Rate</label>
                                                    <input type="number"
                                                    className={`form-control ${feeFormError?.interestrate && 'invalid-input'}`}
                                                    onFocus={handleFocus}
                                                    value={feeValue?.interestrate} name='interestrate' placeholder="0.00" onChange={handleChange}/>
                                                </div>
                                            </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Notes</label>
                                                    <input type="text" name='notes'
                                                    className={`form-control ${feeFormError?.notes && 'invalid-input'}`}
                                                    onFocus={handleFocus}
                                                    value={feeValue?.notes} onChange={handleChange}
                                                        placeholder="Enter Notes"/>
                                                </div>
                                            </div>
                                            {
                                                (feeValue?.quickFee === 'Other Fee' || feeValue?.quickFee?.[0] === 'Other Fee') &&
                                                <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Other Fee Title</label>
                                                    <input type="text"
                                                    className={`form-control ${feeFormError?.otherfeetitle && 'invalid-input'}`}
                                                    onFocus={handleFocus}
                                                    value={feeValue?.otherfeetitle || ''}
                                                    name='otherfeetitle' placeholder="Enter Other Fee" onChange={handleChange}/>
                                                </div>
                                            </div>
                                            }
                                            
                                           
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                        <label className="toggle toggle_center">
                                                                <span className="toggle-label">Deferred</span>
                                                                <input className="toggle-checkbox" name='deferred' checked={feeValue?.deferred} type="checkbox"onChange={handleChange} />
                                                                <div className="toggle-switch"></div>
                                                            </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                        <label className="toggle toggle_center">
                                                                <span className="toggle-label">Interest 1098 Reportable</span>
                                                                <input className="toggle-checkbox" name='interest1098reportable' checked={feeValue?.interest1098reportable} type="checkbox" onChange={handleChange}/>
                                                                <div className="toggle-switch"></div>
                                                            </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                        <label className="toggle toggle_center">
                                                                <span className="toggle-label">Principal 1098 Reportable</span>
                                                                <input className="toggle-checkbox" name='principal1098reportable' checked={feeValue?.principal1098reportable} type="checkbox" onChange={handleChange} />
                                                                <div className="toggle-switch"></div>
                                                            </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                        <label className="toggle toggle_center">
                                                                <span className="toggle-label">Monthly recurring</span>
                                                                <input className="toggle-checkbox" name='monthlyrecurring' checked={feeValue?.monthlyrecurring} type="checkbox" onChange={handleChange} />
                                                                <div className="toggle-switch"></div>
                                                            </label>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3"><div className="form-group">
                                                <button className="fs_btn" onClick={handleSave}> {feeValue?.feeid ? 'Update' : 'Save' } </button>
                                            </div></div>
                                            <div className="search mb-3">
                                                    <h4 className="font-size-18 co_borrower mt-2 ">Funds</h4>
                                                        </div>
                                          
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Funds</label>
                                                    <select  className="form-control" value={funds?.fundname} onChange={(e)=>{handleFunds('fundname',e.target.value)}} name="fundname">
                                                       <option value="">Select a Fund</option>
                                                       {fundsDropdown?.map((fundoption, index) => {
                                                                        return (
                                                                            <option value={fundoption?.value}>{fundoption?.label}</option>
                                                                        )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                                 <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Percent of Fee</label>
                                                    <input type="number"
                                                    className={`form-control ${feeFormError?.percent && 'invalid-input'}`}
                                                    onFocus={handleFocus}
                                                    value={funds?.percent || ''}
                                                    disabled={disableFields}
                                                    name='percent' placeholder="0.00" onChange={(e)=>{handleFunds('percent',e.target.value)}}/>
                                                </div>
                                            </div>

                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Total to Disbursed</label>
                                                            <CurrencyInput
                                                                    name='disbursedAmount'
                                                                    className={`form-control ${feeFormError?.feeamount && 'invalid-input'}`}
                                                                    onFocus={handleFocus}
                                                                    placeholder='$0.00'
                                                                    value={funds?.disbursedAmount}
                                                                    disabled={true}
                                                                    decimalsLimit={2}
                                                                    prefix="$"
                                                                    onValueChange={(value, name, values) => {
                                                                        handleFunds(name,value)
                                                                    }}
                                                                />
                                                        </div>
                                                    </div>
                                                {/* </>
                                            } */}
                                            <div className="col-md-1">
                                            <div className="form-group">
                                            <label className="text-white">add</label>
                                            <button className="fs_btn" disabled={disableFields} style={{'opacity': !disableFields ? '1' : '0.6',}}  onClick={handleAddFund}>{funds?.fundid ? 'Update' : 'Save'}  </button>
                                            </div>
                                            </div>
                                            <div className="table-responsive">
                                                {FundsArray?.length> 0 && 
                                                <table className="table db_table">
            
                                                <thead className="table-light">
                                                    <tr>
                                                        <th width="10%">Created Date</th>
                                                        <th width="10%">Created By</th>
                                                        <th width="9%">Fund</th>
                                                        <th width="5%">Percent</th>
                                                        <th width="8%">Disbursed Amount</th>
                                                        <th width="10%">Disbursed Date</th>
                                                        <th width="10%">Disbursed By</th>
                                                        <th width="4%">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {FundsArray.map((fund,index)=>
                                                {
                                                    return(
                                                        <tr key={index}>
                                                        <td> {fund?.createddate ?  moment.utc(fund?.createddate).format("MM-DD-YYYY") : ''}</td>
                                                        <td>{fund?.createdBy}</td>
                                                        <td>{fund?.fundname}</td>
                                                        <td>{fund?.percent ? fund?.percent + ' %' : ''}</td>
                                                        <td>{fund?.disbursedAmount ? formatCurrency(fund?.disbursedAmount) : ''}</td>
                                                        {/* <td>Lorem Ipsum</td> */}
                                                        <td>{feeFormType!=='Create' && fund?.disburseddate ? moment.utc(fund?.disburseddate).format("MM-DD-YYYY") : ''}</td>
                                                        <td>{feeFormType!=='Create' &&  fund?.disbursedby || ''}</td>
                                                        
                                                        <td><div className="btn-group">
                                                        {(feeFormType==='Create' || fund?.disburseddate) ? '' :  <button onClick={()=>{handleDisburse(fund)}} type="button" className="btn btn-outline-secondary"><span className="material-symbols-outlined icon tooltip-container"><span className="tooltip"> disburse</span>payments</span></button>}
                                                        
                                                                <button type="button" onClick={()=>{handleFundEdit(fund)}} className="btn btn-outline-secondary"><span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span>edit</span></button>
                                                                <button type="button" onClick={()=>{fundDeleteClick(fund)}} className="btn btn-outline-secondary"><span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span>delete</span></button>
                                                            </div></td>
                                                    </tr>
                                                    )
                                               
                                            })}
                                                </tbody>
                                            </table>
                                                }
                                                
                                            </div>
                                        </div>
                                            
                                    </div>   
                       </div>
                                }

                        </div>
                    </div>           
            </div>
        </div>
            }
            
            </div>
            <PipelineFooter />
        </div>
    );
};

export default FeeManagement;