import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate, useLocation } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import {
    getStates, getLoanDetails, getAllEntities, addEntity, getSingleEnytity, updateEntity, deleteEntity, getAllApplicants, getSingleApplicant, addApplicant, updateApplicant, deleteApplicant,
    getAllProperties, addProperty, updateProperty, deleteProperty, getSingleProperty, LoanDeal, genSingleLoanDeal, categoriesforloan, lookupsCallForPipeline
} from "./../utils/reducer";
import { useDispatch, useSelector } from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import { formatCurrency, formatPhoneNumber } from '../utils/commonUtils';
import { useLoanInfo } from '../utils/loanInfoContext';
import Select from 'react-select';
import LocationSearch from './LocationsSeachBox';
import CurrencyInput from 'react-currency-input-field';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { formatNumericInput } from './../utils/commonUtils';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission, hasEditPermission } from '../utils/permissionsCheck';
import ReactQuill from "react-quill";
import EditorEdit from './EditorEdit.js';

const DealDetailedApp = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const loan = location.state?.loan;
    const navigate = useNavigate();
    const loading = useSelector((state) => state.loaderVisible);
    const [states, setStates] = useState([]);
    const [loanTerm, setLoanTerm] = useState([]);
    const stateOptions = states?.map((state) => ({
        value: state,
        label: state,
    }));
    const configdata = useSelector(state => state.configdata);
    const mapsKey = configdata?.common?.googleMapsKey;
    // entity states
    const [entityType, setEntityType] = useState([]);
    const [loanPurposeType, setloanPurposeType] = useState([]);
    const [showEntityForm, setShowEntityForm] = useState(false);
    const [viewEntityForm, setViewEntityForm] = useState(false);
    const [allEntities, setAllEntities] = useState([]);
    const [entity, setEntity] = useState({});
    const [checkEntity, setCheckEntity] = useState({});
    const [isEntityChanged, setIsEntityChanged] = useState(false);
    const [entityBtn, setEntityBtn] = useState('add');
    const [entityErrors, setEntityErrors] = useState({});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [addressData, setAddressData] = useState({});
    //applicant states
    const [applicantPlace, setApplicantPlace] = useState(null);
    const [applicantAddress, setApplicantAddress] = useState({});
    const [showApplicantForm, setShowApplicantForm] = useState(false);
    const [viewApplicantForm, setViewApplicantForm] = useState(false);
    const [allApplicants, setAllApplicants] = useState([]);
    let userData = getUserDataFromLocalStorage();
    // console.log("userData",userData)
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState();
    const [applicant, setApplicant] = useState({
        "borrowerfirstname": "",
        "mi": "",
        "borrowerlastname": "",
        "suffix": "",
        "borrowerphone": "",
        "borroweremail": "",
        "ssn": "",
        "dateofbirth": "",
        "applicantisaguarantor": false,
        "applicantisasigner": false,
        "guarantorstitle": "",
        "signerstitle": "",
        "borroweraddress": "",
        "borrowercity": "",
        "borrowerstate": "",
        "borrowerzipcode": "",
        "borroweraddress2": "",
        "ethnicity": "",
        "subethnicity": "",
        "otherhispanic": "",
        "race": "",
        "subrace": "",
        "otherrace": "",
        "gender": "",
        "maritialstatus": "",
        "borrowercounty": ""
    });
    const [checkApplicant, setCheckApplicant] = useState({});
    const [isApplicantChanges, setIsApplicantChanged] = useState(false);
    const [applicantBtn, setApplicantBtn] = useState('add');
    const [applicantErrors, setApplicantErrors] = useState({});
    const [applicantPopup, setApplicantPopup] = useState(false);
    const [applicantConfirmation, setApplicantConfirmation] = useState(false);
    // property states
    const [propertyPlace, setPropertyPlace] = useState(null);
    const [propertyAddress, setPropertyAddress] = useState({});
    const [showPropertyForm, setShowPropertyForm] = useState(false);
    const [viewPropertyForm, setViewPropertyForm] = useState(false);
    const [allProperties, setAllProperties] = useState([]);
    const [property, setProperty] = useState({});
    const [checkProperty, setCheckProperty] = useState({});
    const [isPropertyChanges, setIsPropertyChanges] = useState(false);
    const [propertyBtn, setPropertyBtn] = useState('add');
    const [propertyErrors, setPropertyErrors] = useState({});
    const [propertyPopup, setPropertyPopup] = useState(false);
    const [propertyConfirmation, setPropertyConfirmation] = useState(false);
    const [legalDescription,setLegalDescription] = useState("");
    // loanDeal states
    let loanState = {
        "loanpurpose": "",
        "programtype": "",
        "loanamount": '',
        "purchaseprice": '',
        "interestrate": '',
        "loanterm": "",
        "constructioncompleteddate": "",
        // "declaredmaturity": "",
        // "custom" : false,
        // "paymentreserves": "",
        // "interestonly": false,
        "holdbackamount": '',
        "drawinteresttype": "",
        // "categoriesforloan" : []
    }

    const [LoanDataData, setLoanDataData] = useState(loanState);
    const [loanDataCheck, setLoanDataCheck] = useState(loanState);
    const [isLoanChange, setIsLoanChange] = useState(false);
    const [categoriesDropdown, setcategoriesDropdown] = useState([])
    const [loanPopup, setLoanPopup] = useState(false);
    const [loanDealErrors, setLoanDealErrors] = useState({});
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const [loanid, setLoanid] = useState(loanInfo?.loanid);
    const [activeTab, setActiveTab] = useState('entity');

    useEffect(() => {
        const isFormChanged =
            JSON.stringify(entity) !== JSON.stringify(checkEntity);
        setIsEntityChanged(isFormChanged);
        console.log(isFormChanged);
    }, [entity, checkEntity]);


    useEffect(() => {
        getstates();
        // categories();
        // loandropdown(10016);
        // loandropdown(10018);
        loandropdown(10010);
    }, []);

    useEffect(() => {
        if (loanid) {
            if (activeTab === 'entity') {
                console.log('i ran ', loanid)
                getEntities();
            } else if (activeTab === 'applicant') {
                getApplicants();
            } else if (activeTab === 'property') {
                getProperties();
            } else if (activeTab === 'loan') {
                getLoanDeals();
            }
            // getEntities();
            // getApplicants();
            // getProperties();
            // getLoanDeals();
        }
    }, [loanid]);
    useEffect(() => {
        setLoanid(loanInfo?.loanid)
    }, [loanInfo?.loanid]);

    useEffect(() => {
        if (location.pathname) {
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions

            let checkedPermission = hasViewPermission(cleanRoute, permission)
            console.log('viewPermission', location.pathname, checkedPermission);
            setPipelinePermissions(checkedPermission)
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
        }

        if (location.pathname) {
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions

            let editPermission = hasEditPermission(cleanRoute, permission)
            console.log('editPermission', location.pathname, editPermission);
            setEditPermissions(editPermission)
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
        }
        //setViewStatus()
    }, []);

    const setLoandetails = () => {
        getLoanDetails(loanid)
            .then(response => {
                console.log('getLoanDetails', response);
                if (response?.result[0]) {
                    setLoanInfo(response?.result[0]);
                    console.log(response);
                }
            })
            .catch(err => {
                console.error('my account error', err);
            });
    }


    const loandropdown = async (num) => {
        lookupsCallForPipeline(num).then(function (response) {
            if (response?.result) {
                console.log("categories from lookups --->", response?.result?.data[0]?.lookupOptions);
                let data = response?.result?.data[0]?.lookupOptions;
                if (num === 10018) {
                    console.log('datttttt 10018', data);
                    setLoanTerm(data);
                } else if (num === 10010) {
                    console.log('datttttt 10010', data);
                    setEntityType(data);
                } else if (num === 10016) {
                    setloanPurposeType(data)
                }
            }
        }).catch((err) => {
            console.error.bind("my account error", err);
        })
    };

    // entity functions
    useEffect(() => {
        console.log(entity)
    }, [entity]);

    const getEntities = () => {
        dispatch(showLoader());
        getAllEntities(loanid).then(function (response) {
            console.log('response--->', response);
            dispatch(hideLoader());
            setEntityErrors({})
            if (response?.result) {
                // setLoandetails();
                setAllEntities(response?.result);
                setShowEntityForm(false)
            }
        }).catch((err) => {
            console.error.bind("error", err);
            dispatch(hideLoader());
        })
    };
    const getstates = async () => {
        getStates().then(function (response) {
            if (response?.result) {
                setStates(response?.result)
            }
        }).catch((err) => {
            console.error.bind("my account error", err);
        })
    };
    const handleCreate = () => {
        setShowEntityForm(!showEntityForm);
        setEntity({});
        setEntityBtn('add');
        setSelectedPlace('');
        setAddressData({})
    }
    const handleEntityBack = () => {
        setEntityBtn('add');
        setShowEntityForm(false);
        setEntityErrors({});
        setSelectedPlace('');
        setAddressData({})
        setViewEntityForm(false)
    }
    const handleEntityAdd = () => {
        const fieldsToCheck = {
            entitytype: 'entitytype', legalname: 'legalname', entityaddress: 'entityaddress', entitycity: 'entitycity', entitystate: 'entitystate', zipcode: 'zipcode', entitystateformation: 'entitystateformation'
        };

        console.log('ran');
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = entity[fieldName];
            if (typeof value === 'number') {
            } else {
                if (!entity[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
            }
        };

        setEntityErrors(errors);
        console.log(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(showLoader());
            console.log('ready to api call -----> ', entity);
            let apiData = { ...entity, status: 'ACTIVE', loanid: loanid }
            if (allEntities.length === 0) {
                apiData['default'] = true;
            }
            addEntity(apiData).then(function (response) {
                if (response) {
                    console.log(response?.result);
                    dispatch(hideLoader());
                    getEntities();
                    setLoandetails();
                    setMessage('Entity Created');
                    setPopupOpen(true);
                }
            }).catch((err) => {
                console.error.bind("error", err);
                dispatch(hideLoader());
            })
        }
    }
    
    const handleEntityView = (data) => {
        // setEntity(data);
        // setCheckEntity(data);
        // setEntityBtn('update');
        // setShowEntityForm(!showEntityForm);
        dispatch(showLoader());
        getSingleEnytity(data, loanid).then(function (response) {
            console.log('response--->', response)
            if (response?.result) {
                console.log('response-----------------> ', response?.result);
                setEntity(response?.result[0]);
                setCheckEntity(response?.result[0]);
                setEntityBtn('update');
                setShowEntityForm(showEntityForm);
                setViewEntityForm(!viewEntityForm)
                dispatch(hideLoader());
            }
        }).catch((err) => {
            console.error.bind("error", err);
            dispatch(hideLoader());
        })
    }
    const handleEntityEdit = (data) => {
        // setEntity(data);
        // setCheckEntity(data);
        // setEntityBtn('update');
        // setShowEntityForm(!showEntityForm);
        dispatch(showLoader());
        getSingleEnytity(data, loanid).then(function (response) {
            console.log('response--->', response)
            if (response?.result) {
                console.log('response-----------------> ', response?.result);
                setEntity(response?.result[0]);
                setCheckEntity(response?.result[0]);
                setEntityBtn('update');
                setShowEntityForm(!showEntityForm);
                setViewEntityForm(false)
                dispatch(hideLoader());
            }
        }).catch((err) => {
            console.error.bind("error", err);
            dispatch(hideLoader());
        })
    }
    const handleUpdateEntity = () => {
        const fieldsToCheck = {
            entitytype: 'entitytype', legalname: 'legalname', entityaddress: 'entityaddress', entitycity: 'entitycity', entitystate: 'entitystate', zipcode: 'zipcode', entitystateformation: 'entitystateformation'
        };
        console.log('ran');
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = entity[fieldName];
            if (typeof value === 'number') {
            } else {
                if (!entity[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
            }
        };

        setEntityErrors(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(showLoader());
            console.log('ready to api call -----> ', entity);
            let apiData = { ...entity, loanid: loanid }
            updateEntity(apiData).then(function (response) {
                if (response) {
                    console.log(response?.result);
                    dispatch(hideLoader());
                    getEntities();
                    setLoandetails();
                    setMessage('Entity Updated');
                    setPopupOpen(true);
                }
            }).catch((err) => {
                console.error.bind("error", err);
                dispatch(hideLoader());
            })
        }
    }
    const deleteEntityClick = (data) => {
        setEntity(data);
        setShowConfirmation(true);
    }
    const handledeleteEntity = () => {
        setShowConfirmation(false)
        //delete api call then get the entities
        dispatch(showLoader());
        let apiData = { ...entity, loanid: loanid, status: 'ARCHIVE' }
        updateEntity(apiData).then(function (response) {
            if (response) {
                console.log('response?.result', response);
                dispatch(hideLoader());
                getEntities();
                setMessage('Deleted Successfully');
                setPopupOpen(true);
            }
        }).catch((err) => {
            console.error.bind("my account error", err);
            dispatch(hideLoader());
        })
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('Name:', name);
        if (name === 'zipcode') {
            let updatedValue = value;
            const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
            updatedValue = isNaN(parsedValue) ? '' : parsedValue;
            setEntity((prev) => ({
                ...prev,
                [name]: updatedValue,
            }));

        } else {
            setEntity((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const styles = {
        'opacity': isEntityChanged ? '1' : '0.6',
        'backgroundColor': '#7CC149',
        'color': '#fff'
    };
    const closePopup = () => {
        setPopupOpen(false);
        setShowConfirmation(false);
        setShowEntityForm(false);
    };
    useEffect(() => {
        if (selectedPlace) {
            let newStateValue = '';
            const stateExistsInDropdown = states.includes(addressData?.state);
            if (stateExistsInDropdown) {
                newStateValue = addressData.state;
            }
            setEntity({
                ...entity,
                'entitystate': newStateValue ?? '',
                'zipcode': addressData?.zipcode ?? '',
                'entitycity': addressData?.city ?? '',
                'entityaddress': (addressData?.address?.trim() || addressData?.fullAddress) ?? '',
            });
        } else {
            setEntity({
                ...entity,
                'entityaddress': '',
            });
        }
    }, [selectedPlace]);
    useEffect(() => {
        if (applicantPlace) {
            let newStateValue = '';
            const stateExistsInDropdown = states.includes(applicantAddress?.state);
            if (stateExistsInDropdown) {
                newStateValue = applicantAddress.state;
            }
            setApplicant({
                ...applicant,
                "borroweraddress": (applicantAddress?.address?.trim() || applicantAddress?.fullAddress) ?? '',
                "borrowercity": applicantAddress?.city ?? '',
                "borrowerstate": newStateValue ?? '',
                "borrowerzipcode": applicantAddress?.zipcode ?? '',
            });
        } else {
            setApplicant({
                ...applicant,
                "borroweraddress": '',
            });
        }
    }, [applicantPlace]);
    useEffect(() => {
        if (propertyPlace) {
            let newStateValue = '';
            const stateExistsInDropdown = states.includes(propertyAddress?.state);
            if (stateExistsInDropdown) {
                newStateValue = propertyAddress.state;
            }
            setProperty({
                ...property,
                "propertyaddress": (propertyAddress?.address?.trim() || propertyAddress?.fullAddress) ?? '',
                "propertycity": propertyAddress?.city ?? '',
                "propertystate": newStateValue ?? '',
                "propertyzipcode": propertyAddress?.zipcode ?? '',
                "propertyfulladdress": propertyAddress?.fullAddress ?? '',
                "propertyplaceimage": propertyAddress?.image ?? '',
                "propertygooglemaplink": propertyAddress?.url ?? '',
                "propertygeolocation": propertyAddress?.url ?? '',
                "propertycounty": propertyAddress?.county ?? '',

            });
        } else {
            setProperty({
                ...property,
                "propertyaddress": '',
            });
        }
    }, [propertyPlace, propertyAddress]);

    // applicant functions
    useEffect(() => {
        const isFormChanged =
            JSON.stringify(applicant) !== JSON.stringify(checkApplicant);
        setIsApplicantChanged(isFormChanged);
        console.log(isFormChanged);
    }, [applicant, checkApplicant]);

    const getApplicants = () => {
        dispatch(showLoader());
        getAllApplicants(loanid).then(function (response) {
            console.log('response--->', response);
            dispatch(hideLoader());
            setApplicantErrors({});
            if (response?.result) {
                console.log('response-----------------> ', response?.result);
                setAllApplicants(response?.result);
                setShowApplicantForm(false);
            }
        }).catch((err) => {
            console.error.bind("error", err);
            dispatch(hideLoader());
        })
    };
    const handleApplicantCreate = () => {
        setShowApplicantForm(!showApplicantForm);
        setApplicant({});
        setApplicantBtn('add');
        setApplicantAddress({});
        setApplicantPlace('');
    };
    const handleApplicantBack = () => {
        setApplicant({});
        setApplicantBtn('add');
        setShowApplicantForm(false);
        setApplicantErrors({});
        setApplicantAddress({});
        setApplicantPlace('');
        setViewApplicantForm(false);
    }
    const handleApplicantAdd = () => {
        const fieldsToCheck = {
            "borrowerfirstname": "borrowerfirstname",
            // "mi": "mi",
            "borrowerlastname": "borrowerlastname",
            // "suffix": "suffix",
            "borrowerphone": "borrowerphone",
            "borroweremail": "borroweremail",
            // "ssn": "ssn",
            // "dateofbirth": "dateofbirth",
            // "guarantorstitle": "guarantorstitle",
            // "signerstitle": "signerstitle",
            // "borroweraddress": "borroweraddress",
            // "borrowercity": "borrowercity",
            // "borrowerstate": "borrowerstate",
            // "borrowerzipcode": "borrowerzipcode",
            // "race" : "race",
            // "gender": "gender",
            // "ethnicity" : "ethnicity",
            // "maritialstatus" : "maritialstatus"
        };
        // const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;
        console.log('ran');
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = applicant[fieldName];
            if (typeof value === 'number') {

            } else {
                if (!applicant[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
            }
        };
        if (applicant?.applicantisaguarantor) {
            if (!applicant.guarantorstitle?.trim()) {
                errors['guarantorstitle'] = `Please Enter guarantorstitle`;
            }
        }
        if (applicant?.applicantisasigner) {
            if (!applicant.signerstitle?.trim()) {
                errors['signerstitle'] = `Please Enter signerstitle`;
            }
        }
        if (applicant?.ethnicity === 'Hispanic or Latino') {
            if (!applicant.subethnicity?.trim()) {
                errors['subethnicity'] = `Please Enter subethnicity`;
            }
        }
        if (applicant?.subethnicity === 'Other Hispanic or Latino') {
            if (!applicant.otherhispanic?.trim()) {
                errors['otherhispanic'] = `Please Enter otherhispanic`;
            }
        }
        if (applicant?.race === 'asian' || applicant?.race === 'Native Hawaiian or Other Pacific Islander') {
            if (!applicant.subrace?.trim()) {
                errors['subrace'] = `Please Enter subrace`;
            }
        }
        if (applicant?.subrace === 'Other Asian' || applicant?.subrace === 'Other Pacific Islander') {
            if (!applicant.otherrace?.trim()) {
                errors['otherrace'] = `Please Enter otherrace`;
            }
        }

        if (applicant.borroweremail) {
            if (!emailRegex.test(applicant.borroweremail)) {
                errors.borroweremail = 'Email Structure Error';
            }
        }

        setApplicantErrors(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(showLoader());
            console.log('ready to api call -----> ', applicant);
            let apiData = { ...applicant, status: 'ACTIVE', loanid: loanid };
            const numericInputs = ['creditscore']
            numericInputs.forEach(key => {
                if (apiData.hasOwnProperty(key)) {
                    apiData[key] = apiData[key] === '' ? '' : parseFloat(apiData[key]);
                }
            });
            addApplicant(apiData).then(function (response) {
                if (response) {
                    setLoandetails();
                    console.log(response?.result);
                    dispatch(hideLoader());
                    getApplicants();
                    setMessage('Applicant Created');
                    setApplicantPopup(true);
                }
            }).catch((err) => {
                console.error.bind("error", err);
                dispatch(hideLoader());
            })
        }
    };
    const handleApplicantEdit = (data) => {
        dispatch(showLoader());
        getSingleApplicant(data, loanid).then(function (response) {
            console.log('response--->', response)
            if (response?.result) {
                console.log('response-----------------> ', response?.result);
                setApplicant(response?.result[0]);
                setCheckApplicant(response?.result[0]);
                setApplicantBtn('update');
                setShowApplicantForm(!showApplicantForm);
                setViewApplicantForm(false);
                dispatch(hideLoader());
            }
        }).catch((err) => {
            console.error.bind("error", err);
            dispatch(hideLoader());
        })
    };
    const handleApplicantView = (data) => {
        dispatch(showLoader());
        getSingleApplicant(data, loanid).then(function (response) {
            console.log('response--->', response)
            if (response?.result) {
                console.log('response-----------------> ', response?.result);
                setApplicant(response?.result[0]);
                setCheckApplicant(response?.result[0]);
                setApplicantBtn('update');
                setShowApplicantForm(showApplicantForm);
                setViewApplicantForm(!viewApplicantForm);
                dispatch(hideLoader());
            }
        }).catch((err) => {
            console.error.bind("error", err);
            dispatch(hideLoader());
        })
    };
    
    const handleUpdateApplicant = () => {
        const fieldsToCheck = {
            "borrowerfirstname": "borrowerfirstname",
            // "mi": "mi",
            "borrowerlastname": "borrowerlastname",
            // "suffix": "suffix",
            "borrowerphone": "borrowerphone",
            "borroweremail": "borroweremail",
            // "ssn": "ssn",
            // "dateofbirth": "dateofbirth",
            // "guarantorstitle": "guarantorstitle",
            // "signerstitle": "signerstitle",
            // "borroweraddress": "borroweraddress",
            // "borrowercity": "borrowercity",
            // "borrowerstate": "borrowerstate",
            // "borrowerzipcode": "borrowerzipcode",
            // "race" : "race",
            // "gender": "gender",
            // "ethnicity" : "ethnicity",
            // "maritialstatus" : "maritialstatus"
        };
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        console.log('ran');
        const errors = {};

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = applicant[fieldName];
            if (typeof value === 'number') {

            } else {
                if (!applicant[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
            }
        };
        if (applicant?.applicantisaguarantor) {
            if (!applicant.guarantorstitle?.trim()) {
                errors['guarantorstitle'] = `Please Enter guarantorstitle`;
            }
        }
        if (applicant?.applicantisasigner) {
            if (!applicant.signerstitle?.trim()) {
                errors['signerstitle'] = `Please Enter signerstitle`;
            }
        }
        if (applicant?.ethnicity === 'Hispanic or Latino') {
            if (!applicant.subethnicity?.trim()) {
                errors['subethnicity'] = `Please Enter subethnicity`;
            }
        }
        if (applicant?.subethnicity === 'Other Hispanic or Latino') {
            if (!applicant.otherhispanic?.trim()) {
                errors['otherhispanic'] = `Please Enter otherhispanic`;
            }
        }
        if (applicant?.race === 'asian' || applicant?.race === 'Native Hawaiian or Other Pacific Islander') {
            if (!applicant.subrace?.trim()) {
                errors['subrace'] = `Please Enter subrace`;
            }
        }
        if (applicant?.subrace === 'Other Asian' || applicant?.subrace === 'Other Pacific Islander') {
            if (!applicant.otherrace?.trim()) {
                errors['otherrace'] = `Please Enter otherrace`;
            }
        }

        if (applicant.borroweremail) {
            if (!emailRegex.test(applicant.borroweremail)) {
                errors.borroweremail = 'Email Structure Error';
            }
        }

        setApplicantErrors(errors);
        if (Object.keys(errors).length === 0) {
            dispatch(showLoader());
            console.log('ready to api call -----> ', applicant);
            let apiData = { ...applicant, loanid: loanid };
            const numericInputs = ['creditscore']
            numericInputs.forEach(key => {
                if (apiData.hasOwnProperty(key)) {
                    apiData[key] = apiData[key] === '' ? '' : parseFloat(apiData[key]);
                }
            });
            updateApplicant(apiData).then(function (response) {
                if (response) {
                    setLoandetails();
                    console.log(response?.result);
                    dispatch(hideLoader());
                    getApplicants();
                    setMessage('Applicant Updated');
                    setApplicantPopup(true);
                }
            }).catch((err) => {
                console.error.bind("error", err);
                dispatch(hideLoader());
            })
        }
    }
    const deletApplicantClick = (data) => {
        setApplicant(data);
        setApplicantConfirmation(true);
    }
    const handleDeleteApplicant = () => {
        setApplicantConfirmation(false);
        dispatch(showLoader());
        let apiData = { ...applicant, loanid: loanid, status: 'ARCHIVE' }
        updateApplicant(apiData).then(function (response) {
            if (response) {
                console.log('response?.result', response);
                dispatch(hideLoader());
                getApplicants();
                setMessage('Deleted Successfully');
                setApplicantPopup(true);
            }
        }).catch((err) => {
            console.error.bind("my account error", err);
            dispatch(hideLoader());
        })
    }
    const handleCurrencyInputsApplicant = (name, value) => {
        console.log(value)
        let updatedValue = isNaN(value) ? '' : (value);
        setApplicant((prev) => ({
            ...prev,
            [name]: (updatedValue),
        }));
    }
    const handleApplicantChange = (e) => {
        const { name, value } = e.target;
        console.log('Name:', name);
        if (name === 'borrowerzipcode') {
            let updatedValue = value;
            const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
            updatedValue = isNaN(parsedValue) ? '' : parsedValue;
            setApplicant((prev) => ({
                ...prev,
                [name]: updatedValue,
            }));

        } else if (name === 'creditscore') {
            let updatedValue = value;
            // const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));

            updatedValue = isNaN(value) ? '' : value;
            const validatedValue = Math.min(parseFloat(updatedValue, 10), 850);
            setApplicant((prev) => ({
                ...prev,
                [name]: validatedValue,
            }));
        }
        else if (name === 'ssn') {
            const cleanedValue = value.replace(/\D/g, '');
            const formattedValue = cleanedValue.slice(0, 9);
            const formattedSSN = formattedValue.replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');
            setApplicant((prev) => ({
                ...prev,
                [name]: formattedSSN,
            }));
        } else {
            setApplicant((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const handleHmdaChange = (e) => {
        let { name, value } = e.target;
        if (name === 'ethnicity' && value !== 'Hispanic or Latino') {
            setApplicant((prev) => ({
                ...prev,
                [name]: value,
                "subethnicity": "",
                "otherhispanic": "",
            }));
        } else if (name === 'subethnicity' && value !== 'Other Hispanic or Latino') {
            setApplicant((prev) => ({
                ...prev,
                [name]: value,
                "otherhispanic": "",
            }));
        } else if (name === 'race' && (value !== 'asian' || value !== 'Native Hawaiian or Other Pacific Islander')) {
            setApplicant((prev) => ({
                ...prev,
                [name]: value,
                "subrace": "",
                "otherrace": "",
            }));
        } else if (name === 'subrace' && (value !== 'Other Asian' || value !== 'Other Pacific Islander')) {
            setApplicant((prev) => ({
                ...prev,
                [name]: value,
                "otherrace": "",
            }));
        }
        else {
            setApplicant((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    }

    const handleApplicantCheckbox = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        setApplicant(prev => ({
            ...prev,
            [name]: value,
            [name === 'applicantisaguarantor' ? 'guarantorstitle' : 'signerstitle']: value ? prev[name === 'applicantisaguarantor' ? 'guarantorstitle' : 'signerstitle'] : ''
        }));
        if (!e.target?.checked) {
            setApplicantErrors({ ...applicantErrors, [name === 'applicantisaguarantor' ? 'guarantorstitle' : 'signerstitle']: null })
        }

    };
    const closeApplicantPopup = () => {
        setApplicantPopup(false);
        setApplicantConfirmation(false);
        setShowApplicantForm(false);
    };

    //property Functions
    useEffect(() => {
        // Compare legalDescription separately
        const isLegalDescriptionChanged = property?.legaldescription !== legalDescription;

        // Compare the rest of the properties
        const isPropertyChanged =
            JSON.stringify({ ...property, legaldescription: undefined }) !==
            JSON.stringify({ ...checkProperty, legaldescription: undefined });
        // Combine both checks
        const isFormChanged = isLegalDescriptionChanged || isPropertyChanged;
    
        setIsPropertyChanges(isFormChanged);
        console.log(isFormChanged);
    }, [property, checkProperty,legalDescription]);
    

    const getProperties = () => {
        dispatch(showLoader());
        getAllProperties(loanid).then(function (response) {
            console.log('response--->', response);
            dispatch(hideLoader());
            setPropertyErrors({});
            if (response?.result) {
                console.log('response-----------------> ', response?.result);
                setAllProperties(response?.result);
                setShowPropertyForm(false);
            }
        }).catch((err) => {
            console.error.bind("error", err);
            dispatch(hideLoader());
        })
    };
    const handlePropertyCreate = () => {
        setShowPropertyForm(!showPropertyForm);
        setProperty({});
        setLegalDescription('')
        setPropertyBtn('add');
        setPropertyAddress({});
        setPropertyPlace('');
    };
    const handlePropertyBack = () => {
        setProperty({});
        setLegalDescription('')
        setPropertyBtn('add');
        setShowPropertyForm(false);
        setPropertyErrors({});
        setPropertyAddress({});
        setPropertyPlace('');
        setViewPropertyForm(false);
    }
    const handlePropertyAdd = () => {
        const fieldsToCheck = {
            "propertyaddress": 'propertyaddress',
            "propertystate": 'propertystate',
            "propertyzipcode": 'propertyzipcode',
            "propertycity": 'propertycity',
            // "hazardinsurance" : 'hazardinsurance',
            // "hazardinsuranceactivedate" : 'hazardinsuranceactivedate',
            // "hazardinsuranceexpirydate" : 'hazardinsuranceexpirydate',
            // "hazardannualpremium" : 'hazardannualpremium',
            // "dwellingcoverage" : 'dwellingcoverage',
            "finishedsqft": "finishedsqft",
            // "buildingsqft": "buildingsqft",
            "lotsqft": "lotsqft",
            "totalbedrooms": "totalbedrooms",
            "totalbathrooms": "totalbathrooms",
            "haspool": "haspool",
            // "vestingstatus" : "vestingstatus",
            // "apn" : "apn",
            // "currentvalue" : "currentvalue"
        };

        console.log('ran');
        const errors = {};
        if (property?.floodinsurance) {
            let fieldsToCheck = { "floodinsurancecoverage": "floodinsurancecoverage", "floodinsuranceannualpremium": "floodinsuranceannualpremium", "floodinsuranceexpirydate": "floodinsuranceexpirydate", "floodinsuranceactivedate": "floodinsuranceactivedate" }
            for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
                let value = property[fieldName];
                if (typeof value === 'number') {

                } else {
                    if (!property[fieldName]?.trim()) {
                        errors[fieldName] = `Please Enter ${fieldLabel} `;
                    }
                }
            };
        }
        if (property?.generalliability) {
            let fieldsToCheck = { "generalliabilitycoverage": "generalliabilitycoverage", "generalliabilityannualpremium": "generalliabilityannualpremium", "generalliabilityactivedate": "generalliabilityactivedate", "generalliabilityexpirydate": "generalliabilityexpirydate" }
            for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
                let value = property[fieldName];
                if (typeof value === 'number') {

                } else {
                    if (!property[fieldName]?.trim()) {
                        errors[fieldName] = `Please Enter ${fieldLabel} `;
                    }
                }
            };
        }

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = property[fieldName];
            if (typeof value === 'number') {

            } else {
                if (!property[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
            }
        };

        setPropertyErrors(errors);
        if (Object.keys(errors).length === 0) {
            //dispatch(showLoader());
            let propertydata = {...property , legaldescription : legalDescription};
            let apiData = { ...propertydata, status: 'ACTIVE', loanid: loanid };
            const numericInputs = ['dwellingcoverage', 'hazardannualpremium', 'generalliabilitycoverage', 'generalliabilityannualpremium', 'floodinsurancecoverage', 'floodinsuranceannualpremium', 'currentvalue'];
            numericInputs.forEach(key => {
                if (apiData.hasOwnProperty(key)) {
                    apiData[key] = apiData[key] === '' ? '' : parseFloat(apiData[key]);
                }
            });
            const formatNumberInputs = ['finishedsqft', 'buildingsqft', 'lotsqft'];
            formatNumberInputs.forEach(key => {
                if (apiData.hasOwnProperty(key)) {
                    const rawValue = apiData[key];
                    apiData[key] = rawValue === '' ? '' : parseInt(String(rawValue).replace(/,/g, ''), 10);
                }
            });
            console.log('apiData---->', apiData);
            addProperty(apiData).then(function (response) {
                // setLoandetails();
                if (response) {
                    console.log(response?.result);
                    dispatch(hideLoader());
                    setLoandetails()
                    getProperties();
                    setMessage('Property Created');
                    setPropertyPopup(true);
                    //    setLoandetails();
                }
            }).catch((err) => {
                console.error.bind("error", err);
                dispatch(hideLoader());
            })
        }
    };
    
    const handlePropertyView = (data) => {
        dispatch(showLoader());
        getSingleProperty(data, loanid).then(function (response) {
            console.log('response--->', response)
            if (response?.result) {
                console.log('response-----------------> ', response?.result);
                const propertyData = response?.result[0];
                //setProperty(response?.result[0]);
                // Apply formatNumericInput to specific fields
                const formattedPropertyData = {
                    ...propertyData,
                    finishedsqft: propertyData?.finishedsqft
                        ? formatNumericInput(propertyData.finishedsqft.toString())
                        : '',
                    lotsqft: propertyData?.lotsqft
                        ? formatNumericInput(propertyData.lotsqft.toString())
                        : '',
                    buildingsqft: propertyData?.buildingsqft
                        ? formatNumericInput(propertyData.buildingsqft.toString())
                        : '',
                };

                setProperty(formattedPropertyData);
                setLegalDescription(formattedPropertyData?.legaldescription || '');
                setCheckProperty(formattedPropertyData);
                setViewPropertyForm(!viewPropertyForm);
                setShowPropertyForm(showPropertyForm);
                dispatch(hideLoader());
            }
        }).catch((err) => {
            console.error.bind("error", err);
            dispatch(hideLoader());
        })
    };
    const handlePropertyEdit = (data) => {
        dispatch(showLoader());
        getSingleProperty(data, loanid).then(function (response) {
            console.log('response--->', response)
            if (response?.result) {
                console.log('response-----------------> ', response?.result);
                const propertyData = response?.result[0];
                //setProperty(response?.result[0]);
                // Apply formatNumericInput to specific fields
                const formattedPropertyData = {
                    ...propertyData,
                    finishedsqft: propertyData?.finishedsqft
                        ? formatNumericInput(propertyData.finishedsqft.toString())
                        : '',
                    lotsqft: propertyData?.lotsqft
                        ? formatNumericInput(propertyData.lotsqft.toString())
                        : '',
                    buildingsqft: propertyData?.buildingsqft
                        ? formatNumericInput(propertyData.buildingsqft.toString())
                        : '',
                };
                console.log('formattedPropertyData',formattedPropertyData)

                setProperty(formattedPropertyData);
                setLegalDescription(formattedPropertyData?.legaldescription || '');
                setCheckProperty(formattedPropertyData);
                setPropertyBtn('update');
                setShowPropertyForm(!showPropertyForm);
                setViewPropertyForm(false);
                dispatch(hideLoader());
            }
        }).catch((err) => {
            console.error.bind("error", err);
            dispatch(hideLoader());
        })
    };
    const handleUpdateProperty = () => {
        const fieldsToCheck = {
            "propertyaddress": 'propertyaddress',
            "propertystate": 'propertystate',
            "propertyzipcode": 'propertyzipcode',
            "propertycity": 'propertycity',
            // "hazardinsurance" : 'hazardinsurance',
            // "hazardinsuranceactivedate" : 'hazardinsuranceactivedate',
            // "hazardinsuranceexpirydate" : 'hazardinsuranceexpirydate',
            // "hazardannualpremium" : 'hazardannualpremium',
            // "dwellingcoverage" : 'dwellingcoverage',
            "finishedsqft": "finishedsqft",
            // "buildingsqft" : "buildingsqft",
            "lotsqft": "lotsqft",
            "totalbedrooms": "totalbedrooms",
            "totalbathrooms": "totalbathrooms",
            "haspool": "haspool",
            // "vestingstatus" : "vestingstatus",
            // "apn" : "apn",
            // "currentvalue" : "currentvalue"
        };

        console.log('ran');
        const errors = {};
        if (property?.floodinsurance) {
            let fieldsToCheck = { "floodinsurancecoverage": "floodinsurancecoverage", "floodinsuranceannualpremium": "floodinsuranceannualpremium", "floodinsuranceexpirydate": "floodinsuranceexpirydate", "floodinsuranceactivedate": "floodinsuranceactivedate" }
            for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
                let value = property[fieldName];
                if (typeof value === 'number') {

                } else {
                    if (!property[fieldName]?.trim()) {
                        errors[fieldName] = `Please Enter ${fieldLabel} `;
                    }
                }
            };
        }
        if (property?.generalliability) {
            let fieldsToCheck = { "generalliabilitycoverage": "generalliabilitycoverage", "generalliabilityannualpremium": "generalliabilityannualpremium", "generalliabilityactivedate": "generalliabilityactivedate", "generalliabilityexpirydate": "generalliabilityexpirydate" }
            for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
                let value = property[fieldName];
                if (typeof value === 'number') {

                } else {
                    if (!property[fieldName]?.trim()) {
                        errors[fieldName] = `Please Enter ${fieldLabel} `;
                    }
                }
            };
        }

        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            let value = property[fieldName];
            if (typeof value === 'number') {

            } else {
                if (!property[fieldName]?.trim()) {
                    errors[fieldName] = `Please Enter ${fieldLabel} `;
                }
            }
        };
        console.log(errors);
        setPropertyErrors(errors);
        if (Object.keys(errors).length === 0) {
            //dispatch(showLoader());
            let propertydata = {...property , legaldescription : legalDescription};
            let apiData = { ...propertydata, loanid: loanid }
            const numericInputs = ['dwellingcoverage', 'hazardannualpremium', 'generalliabilitycoverage', 'generalliabilityannualpremium', 'floodinsurancecoverage', 'floodinsuranceannualpremium', 'currentvalue'];
            numericInputs.forEach(key => {
                if (apiData.hasOwnProperty(key)) {
                    apiData[key] = apiData[key] === '' ? '' : parseFloat(apiData[key]);
                }
            });
            const formatNumberInputs = ['finishedsqft', 'buildingsqft', 'lotsqft'];
            formatNumberInputs.forEach(key => {
                if (apiData.hasOwnProperty(key)) {
                    const rawValue = apiData[key];
                    apiData[key] = rawValue === '' ? '' : parseInt(String(rawValue).replace(/,/g, ''), 10);
                }
            });
            console.log("update data", apiData);
            updateProperty(apiData).then(function (response) {
                if (response) {
                    // setLoandetails();
                    console.log(response?.result);
                    dispatch(hideLoader());
                    setLoandetails()
                    getProperties();
                    setMessage('Property Updated');
                    setPropertyPopup(true);
                }
            }).catch((err) => {
                console.error.bind("error", err);
                dispatch(hideLoader());
            })
        }
    };
    const deletePropertyClick = (data) => {
        console.log()
        setProperty(data);
        setPropertyConfirmation(true);
    }
    const handleDeleteProperty = () => {
        setPropertyConfirmation(false);
        dispatch(showLoader());
        let apiData = { ...property, loanid: loanid, status: 'ARCHIVE' }
        updateProperty(apiData).then(function (response) {
            if (response) {
                console.log('response?.result', response);
                dispatch(hideLoader());
                getProperties();
                setMessage('Deleted Successfully');
                setPropertyPopup(true);
            }
        }).catch((err) => {
            console.error.bind(" error", err);
            dispatch(hideLoader());
        })
    }
    const formatNumericInputForExcel = (value) => {
        if (!value) return ''; // Handle edge case for null, undefined, or empty input

        // Convert the value to a string
        value = String(value).replace(/[^0-9]/g, '');

        // Add commas to the numeric value
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const handleExportToExcel = () => {
        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        // Format table data for Excel
        const formattedData = allProperties.map(item => ({
            'ADDRESS': item.propertyinfo?.propertyaddress || '',
            'CITY': item?.propertyinfo?.propertycity || '',
            'STATE': item?.propertyinfo?.propertystate || '',
            'ZIPCODE': item?.propertyinfo?.propertyzipcode || '',
            'BEDROOMS': item?.propertyinfo?.totalbedrooms || '',
            'BATHROOMS': item?.propertyinfo?.totalbathrooms || '',
            'POOL': item.propertyinfo?.haspool || '',
            'LOT SQ FT': item?.propertyinfo?.lotsqft ? formatNumericInputForExcel(item?.propertyinfo?.lotsqft) : '',
            'FINISHED SQ FT': item?.propertyinfo?.finishedsqft ? formatNumericInputForExcel(item?.propertyinfo?.finishedsqft) : '',
            'BUILDING SQ FT': item?.propertyinfo?.buildingsqft ? formatNumericInputForExcel(item?.propertyinfo?.buildingsqft) : '',
            'HAZARD INSURANCE NUMBER': item?.propertyinfo?.hazardinsurance || '',
            'HAZARD INSURANCE ACTIVE DATE': item.propertyinfo?.hazardinsuranceactivedate ? moment.utc(item.propertyinfo?.hazardinsuranceactivedate).format("MM-DD-YYYY") : '',
            'HAZARD INSURANCE EXP DATE': item.propertyinfo?.hazardinsuranceexpirydate ? moment.utc(item.propertyinfo?.hazardinsuranceexpirydate).format("MM-DD-YYYY") : '',
            'HAZARD ANNUAL PREMIUM': item?.propertyinfo?.hazardannualpremium ? formatCurrency(item?.propertyinfo?.hazardannualpremium) : '',
            'DWELLING COVERAGE': item?.propertyinfo?.dwellingcoverage ? formatCurrency(item?.propertyinfo?.dwellingcoverage) : '',
            'VESTING STATUS': item?.propertyinfo?.vestingstatus || '',
            'APN NUMBER': item?.propertyinfo?.apn || '',
            'Borrower As Is Value': item?.propertyinfo?.currentvalue ? formatCurrency(item?.propertyinfo?.currentvalue) : '',
            'FLOOD INSURANCE ANNUAL PREMIUM': item?.propertyinfo?.floodinsuranceannualpremium ? formatCurrency(item?.propertyinfo?.floodinsuranceannualpremium) : '',
            'FLOOD INSURANCE COVERAGE AMOUNT': item?.propertyinfo?.floodinsurancecoverage ? formatCurrency(item?.propertyinfo?.floodinsurancecoverage) : '',
            'FLOOD INSURANCE ACTIVE DATE': item.propertyinfo?.floodinsuranceactivedate ? moment.utc(item.propertyinfo?.floodinsuranceactivedate).format("MM-DD-YYYY") : '',
            'FLOOD INSURANCE EXP DATE': item.propertyinfo?.floodinsuranceexpirydate ? moment.utc(item.propertyinfo?.floodinsuranceexpirydate).format("MM-DD-YYYY") : '',
            'GENERAL LIABILITY ANNUAL PREMIUM': item?.propertyinfo?.generalliabilityannualpremium ? formatCurrency(item?.propertyinfo?.generalliabilityannualpremium) : '',
            'GENERAL LIABILITY COVERAGE AMOUNT': item?.propertyinfo?.generalliabilitycoverage ? formatCurrency(item?.propertyinfo?.generalliabilitycoverage) : '',
            'GENERAL LIABILITY ACTVE DATE': item.propertyinfo?.generalliabilityactivedate ? moment.utc(item.propertyinfo?.generalliabilityactivedate).format('MM-DD-YYYY') : '',
            'GENERAL LIABILITY EXP DATE': item.propertyinfo?.generalliabilityexpirydate ? moment.utc(item.propertyinfo?.generalliabilityexpirydate).format('MM-DD-YYYY') : '',
            'LEGAL DESCRIPTION': item.propertyinfo.legaldescription ? htmlToText(item.propertyinfo.legaldescription) : '',
        }));
        const ws = XLSX.utils.json_to_sheet(formattedData);
        // Calculate column widths based on content
        const columnWidths = Object.keys(formattedData[0]).map(column => {
            const maxContentLength = formattedData.reduce((max, row) => {
                const cellContent = row[column] || '';
                return Math.max(max, cellContent.toString().length);
            }, column.length); // Set initial width to column name length

            return { wch: maxContentLength + 2 }; // Add some padding
        });

        ws['!cols'] = columnWidths;


        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const fileData = new Blob([excelBuffer], { type: fileType });

        saveAs(fileData, 'Property_excel' + fileExtension);
    };
    function htmlToText(htmlString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        return doc.body.textContent || '';
    }
    
    useEffect(() => {
        console.log('property----->', property);
    }, [property])
    const handleCurrencyInputsProperty = (name, value) => {
        console.log(value)
        let updatedValue = isNaN(value) ? '' : (value);
        setProperty((prev) => ({
            ...prev,
            [name]: (updatedValue),
        }));
    }
    const handlePropertyChange = (e) => {
        const { name, value } = e.target;
        console.log('Name:', name);
        const numericInputs = ['dwellingcoverage', 'hazardannualpremium', 'finishedsqft', 'buildingsqft', 'lotsqft', 'totalbedrooms', 'propertyzipcode', 'generalliabilitycoverage', 'generalliabilityannualpremium', 'floodinsurancecoverage', 'floodinsuranceannualpremium'];
        if (numericInputs.includes(name)) {
            let updatedValue = value;
            const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
            // Additional formatting for specific fields
            if (['finishedsqft', 'buildingsqft', 'lotsqft'].includes(name)) {
                updatedValue = formatNumericInput(updatedValue); // Apply the formatting function
            } else {

                updatedValue = isNaN(parsedValue) ? '' : parsedValue;
            }

            setProperty((prev) => ({
                ...prev,
                [name]: updatedValue,
            }));

        } else if (name === 'generalliability' || name === 'floodinsurance') {
            if (name === 'generalliability' && !e.target.checked) {
                setProperty((prev) => ({
                    ...prev,
                    [name]: e.target.checked,
                    "generalliabilitycoverage": '',
                    "generalliabilityannualpremium": '',
                    "generalliabilityactivedate": '',
                    "generalliabilityexpirydate": '',
                }))
            } else if (name === 'floodinsurance' && !e.target.checked) {
                setProperty((prev) => ({
                    ...prev,
                    [name]: e.target.checked,
                    "floodinsurancecoverage": '',
                    "floodinsuranceannualpremium": '',
                    "floodinsuranceexpirydate": '',
                    "floodinsuranceactivedate": '',
                }))
            } 
            else if (name === 'totalbathrooms') {
                let regex = /^\d*\.?\d*$/; // Allows only numbers with a single decimal
                if (regex.test(e.target.value)) {
                    setProperty((prev) => ({
                        ...prev,
                        [name]: e.target.value,
                    }));
                }
            }
            else {
                setProperty((prev) => ({
                    ...prev,
                    [name]: e.target.checked,
                }));
            }
        }
        else {
            setProperty((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    const closePropertyPopup = () => {
        setPropertyPopup(false);
        setPropertyConfirmation(false);
        setShowPropertyForm(false);
        setViewPropertyForm(false)
    };
    //  loan
    const getLoanDeals = () => {
        dispatch(showLoader())
        genSingleLoanDeal(loanid)
            .then(function (response) {
                if (response?.result?.[0]?.propertyinfo
                ) {
                    setLoanDataData((prev) => ({
                        ...loanState,
                        ...response?.result?.[0],
                        'interestrate': response?.result?.[0]?.interestrate || '',
                        'loanterm': response?.result?.[0]?.loanterm || '',
                        'constructioncompleteddate': response?.result?.[0]?.propertyinfo?.constructioncompleteddate,
                        'propertyid': response?.result?.[0]?.propertyinfo?.propertyid,
                        'purchaseprice': response?.result?.[0]?.propertyinfo?.purchaseprice,
                    }));
                    setLoanDataCheck((prev) => ({
                        ...loanState,
                        ...response?.result?.[0],
                        'interestrate': response?.result?.[0]?.interestrate || '',
                        'loanterm': response?.result?.[0]?.loanterm || '',
                        'constructioncompleteddate': response?.result?.[0]?.propertyinfo?.constructioncompleteddate,
                        'propertyid': response?.result?.[0]?.propertyinfo?.propertyid,
                        'purchaseprice': response?.result?.[0]?.propertyinfo?.purchaseprice,
                    }));
                }
                setLoanDealErrors({})
                dispatch(hideLoader());
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };

    const handleCurrencyInputsLoan = (name, value) => {
        console.log(value)
        let updatedValue = isNaN(value) ? '' : (value);
        setLoanDataData((prev) => ({
            ...prev,
            [name]: (updatedValue),
        }));
    }
    const handleLoanChange = (e) => {
        const { name, value } = e.target;
        let updatedValue = value;

        if (name === 'interestonly' || name === 'custom') {
            setLoanDataData((prev) => ({
                ...prev,
                [name]: e.target.checked,
            }));
        } else if (name === 'loanamount' || name === 'purchaseprice' || name === 'holdbackamount' || name === 'interestrate') {
            const parsedValue = parseFloat(value.replace(/[^.0-9]/g, ''));
            updatedValue = isNaN(parsedValue) ? '' : parsedValue;
            setLoanDataData((prev) => ({
                ...prev,
                [name]: updatedValue,
            }));
        } else {
            setLoanDataData((prev) => ({
                ...prev,
                [name]: updatedValue,
            }));
        }
    };

    useEffect(() => {
        console.log('loanDataData----->', LoanDataData)
    }, [LoanDataData]);


    const handleAddLoan = () => {
        let apiData = { ...LoanDataData, loanid: loanid }
        if (apiData['propertyinfo'] || apiData['_id']) {
            delete apiData['propertyinfo']
            delete apiData['_id']
        }
        if (!LoanDataData?.loanamount) {
            apiData['loanamount'] = 0
        }
        const numericInputs = ['loanamount', 'purchaseprice', 'holdbackamount', 'loanterm']
        numericInputs.forEach(key => {
            if (apiData.hasOwnProperty(key)) {
                apiData[key] = apiData[key] === '' ? '' : parseFloat(apiData[key]);
            }
        });
        let fieldsCheck = ['constructioncompleteddate', 'purchaseprice', 'loanamount', 'loanterm', 'interestrate'];
        fieldsCheck?.forEach(field => {
            if (loanDataCheck?.[field] === LoanDataData?.[field]) {
                delete apiData[field];
            }
        });
        console.log(apiData)
        dispatch(showLoader());
        LoanDeal(apiData).then(function (response) {
            if (response) {
                console.log('response?.result', response.result);
                getLoanDeals();
                setLoandetails();
                setLoanPopup(true);
                dispatch(hideLoader());
            }
        }).catch((err) => {
            console.error.bind("my account error", err);
            dispatch(hideLoader());
        })
    }

    const handleLoanAdd = () => {
        // const fieldsToCheck = {
        //     loanpurpose: "loanpurpose",
        //     programtype: "programtype",
        //     loanamount: "loanamount",
        //     purchaseprice: 'purchaseprice',
        //     interestrate: 'interestrate',
        //     loanterm: "loanterm",
        //     constructioncompleteddate: "constructioncompleteddate",
        //     holdbackamount: 'holdbackamount',
        //     drawinteresttype: "drawinteresttype",    

        // };
        console.log('ran');
        const errors = {};

        // for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
        //     const fieldValue = LoanDataData[fieldName];

        //     if (typeof fieldValue === 'string' && !fieldValue.trim()) {
        //         errors[fieldName] = `Please Enter ${fieldLabel} `;
        //     } else if (typeof fieldValue === 'borrowerphone') {
        //         const stringValue = fieldValue.toString();
        //         if (!stringValue.trim()) {
        //             errors[fieldName] = `Please Enter a valid ${fieldLabel} `;
        //         }
        //     }else if(fieldName ==='interestrate'){
        //         console.log(LoanDataData?.interestrate)
        //         if(LoanDataData?.interestrate == '0'){
        //             errors[fieldName] = `Please Enter a valid ${fieldLabel} `;
        //         }
        //     }
        // };

        // if(LoanDataData?.loanamount){
        //     if(LoanDataData?.loanamount == '0'){
        //         errors['loanamount'] = `Please Enter a valid ${'loanamount'} `;
        //     }
        // }
        // console.log(errors);
        // setLoanDealErrors(errors);
        // if (Object.keys(errors).length === 0) {
        handleAddLoan();
        // } 
    };
    useEffect(() => {
        const isFormChanged =
            JSON.stringify(LoanDataData) !== JSON.stringify(loanDataCheck);
        setIsLoanChange(isFormChanged);
        console.log(isFormChanged);
    }, [LoanDataData, loanDataCheck]);

    const closeLoanPopup = () => {
        setLoanPopup(false)
    }

    useEffect(() => {
        console.log('applicant', applicant);
    }, [applicant])
    const handleTabClick = (tabName) => {
        if (activeTab !== tabName) {
            if (tabName === 'entity') {
                getEntities();
            } else if (tabName === 'applicant') {
                getApplicants();
            } else if (tabName === 'property') {
                getProperties();
            } else if (tabName === 'loan') {
                getLoanDeals();
            }
        }
        setActiveTab(tabName);
    }

    const customFilter = (option, searchInput) => {
        if (!searchInput) return true; // Show all options if no input
        const { label } = option; // Extract label
        const input = searchInput.toLowerCase();
        const labelLowerCase = label.toLowerCase();
        if (input.length === 1) {
            return labelLowerCase.startsWith(input);
        }
        return labelLowerCase.indexOf(input) === 0;
    };


    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader /> : null}
            {loanPopup && !loading && (
                <PopupMessage
                    type={'success'}
                    message={'Saved Successfully'}
                    onClose={closeLoanPopup}
                />
            )}
            {isPopupOpen && !loading && (
                <PopupMessage
                    type={'success'}
                    message={message}
                    onClose={closePopup}
                />
            )}

            {showConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handledeleteEntity}
                onCancel={closePopup}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-entity-yes',
                        className: 'btn btn-primary',
                        onClick: handledeleteEntity,
                        style: { marginRight: '8px', fontSize: '16px' }
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-entity-no',
                        className: 'btn btn-light',
                        onClick: closePopup,
                        style: { fontSize: '16px' }
                    }, 'No')
                ]}

            >
                <p>Do you want to delete this Entity</p>
            </SweetAlert>}
            {applicantPopup && !loading && (
                <PopupMessage
                    type={'success'}
                    message={message}
                    onClose={closeApplicantPopup}
                />
            )}
            {applicantConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDeleteApplicant}
                onCancel={closeApplicantPopup}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-applicant-yes',
                        className: 'btn btn-primary',
                        onClick: handleDeleteApplicant,
                        style: { marginRight: '8px', fontSize: '16px' }
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-applicant-no',
                        className: 'btn btn-light',
                        onClick: closeApplicantPopup,
                        style: { fontSize: '16px' }
                    }, 'No')
                ]}
            >
                <p>Do you want to delete this Applicant</p>
            </SweetAlert>}

            {propertyPopup && !loading && (
                <PopupMessage
                    type={'success'}
                    message={message}
                    onClose={closePropertyPopup}
                />
            )}
            {propertyConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDeleteProperty}
                onCancel={closePropertyPopup}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-property-yes',
                        className: 'btn btn-primary',
                        onClick: handleDeleteProperty,
                        style: { marginRight: '8px', fontSize: '16px' }
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-property-no',
                        className: 'btn btn-light',
                        onClick: closePropertyPopup,
                        style: { fontSize: '16px' }
                    }, 'No')
                ]}
            >
                <p>Do you want to delete this Property</p>
            </SweetAlert>}
            <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Deal <span className="space">&#10095;</span>  <span className="sub">Loan Details</span> </h2>
                <div className="card user_management detailed_app loan_summary">
                    <div className="card-body my_account">
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a id="loan-entity-tab" onClick={(e) => { handleTabClick('entity') }} className="nav-link active" data-bs-toggle="tab" href="#ENTITY" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">ENTITY</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="loan-applicant-tab" onClick={(e) => { handleTabClick('applicant') }} className="nav-link " data-bs-toggle="tab" href="#APPLICANT" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">APPLICANT</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="loan-property-tab" onClick={(e) => { handleTabClick('property') }} className="nav-link " data-bs-toggle="tab" href="#PROPERTY" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">PROPERTY</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="loan-tab" onClick={(e) => { handleTabClick('loan') }} className="nav-link" data-bs-toggle="tab" href="#LOAN" role="tab">
                                    <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                    <span className="d-none d-sm-block">LOAN</span>
                                </a>
                            </li>

                        </ul>


                        <div className="tab-content pt-15 text-muted">
                            <div className="tab-pane active" id="ENTITY" role="tabpanel" >
                                <div className='search' >
                                    {!showEntityForm &&  !viewEntityForm && (allEntities?.length < 10) && <button id="addNewLoanEntity" type="button" onClick={(e) => { handleCreate() }} className="create_btn" style={{
                                    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                }}>Add New Entity</button>}

                                </div>
                                {!showEntityForm && !viewEntityForm && <div className="table-responsive">
                                    <table className="table db_table">

                                        <thead className="table-light">
                                            <tr>
                                                <th width="20%">TYPE</th>
                                                <th width="20%">lEGAL NAME</th>
                                                <th width="50%">Address</th>
                                                <th width="6%">Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {allEntities && allEntities.length > 0 ? allEntities?.map((data, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{data?.entity?.entitytype}</td>
                                                            <td>{data?.entity?.legalname}</td>
                                                            <td>{data?.entity?.entityaddress}</td>
                                                            <td>
                                                                <div className="btn-group">
                                                                    <button id={`edit-entity-${index}`} type="button" className="btn btn-outline-secondary" onClick={() => { handleEntityView(data?.entity); }} ><span
                                                                        className="material-symbols-outlined icon tooltip-container"  ><span className="tooltip">View</span>  visibility
                                                                    </span></button>
                                                                    <button id={`edit-entity-${index}`} type="button" className="btn btn-outline-secondary" onClick={() => { handleEntityEdit(data?.entity); }} style={{
                                                                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                                                    }}><span
                                                                        className="material-symbols-outlined icon tooltip-container"  ><span className="tooltip">Edit</span>  edit
                                                                        </span></button>
                                                                    {data?.entity?.default ? '' : <>
                                                                        <button id={`delete-entity-${index}`} type="button" className="btn btn-outline-secondary"
                                                                            onClick={() => { deleteEntityClick(data?.entity) }} style={{
                                                                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                                                            }}><span
                                                                                className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span> delete
                                                                            </span></button>
                                                                    </>}
                                                                </div>
                                                            </td>
                                                        </tr>


                                                    </>
                                                )
                                            }) :
                                                <tr >
                                                    <td colspan="4">  <div className="inprogress_page">
                                                        <span className="material-symbols-outlined icon"> content_paste_search </span><p>No Data Found</p></div></td>
                                                </tr>}

                                        </tbody>
                                    </table>
                                </div>}
                                {showEntityForm && <div className="create_entity">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18 title">{entityBtn === 'add' ? 'Create' : 'update'} entity</h4>
                                        <a id="navigateEntityBack" href="#" className="back_btn" onClick={(e) => { e.preventDefault(); handleEntityBack(); }}><span className="material-icons icon"> chevron_left
                                        </span>BACK</a>
                                    </div>
                                    <div className="form_section">
                                        <div className="row" style={{
                                            pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                        }}>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Type</label>
                                                    <select id='entityType' tabIndex={1} onChange={handleChange} name='entitytype' value={entity?.entitytype} className={`form-control ${entityErrors.entitytype && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entitytype: null }) }}>
                                                        <option selected="" value=''>Select</option>
                                                        {entityType?.map((data) => {
                                                            return (
                                                                <option key={data?.option} value={data?.option}>{data?.option}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Legal Name</label>
                                                    <input id='entityLegalName' tabIndex={2} type="text" value={entity?.legalname} placeholder="Enter Legal Name" onChange={handleChange} name='legalname' className={`form-control capitalize-text ${entityErrors.legalname && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, legalname: null }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <div className={`${entityErrors.entityaddress && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entityaddress: null }) }}> <LocationSearch simpleAddress={true} name={'entityaddress'} setAddressData={setAddressData} addressData={addressData} setSelectedPlace={setSelectedPlace} selectedPlace={entity?.entityaddress} className={`${entityErrors.entityaddress && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entityaddress: null }) }} /></div>
                                                    {/* <LocationSearch name={'entityaddress'} setAddressData={setAddressData} addressData={addressData} setSelectedPlace={setSelectedPlace} selectedPlace={entity?.entityaddress} className={`${entityErrors.entityaddress && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entityaddress: null }) }}/> */}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <input tabIndex={3} type="text" placeholder="Enter address" value={entity?.entityaddress} onChange={handleChange} name='entityaddress' className={`form-control ${entityErrors.entityaddress && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entityaddress: null }) }} />
                                            </div>
                                        </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input id='entityCity' tabIndex={4} type="text" placeholder="Enter City" value={entity?.entitycity} onChange={handleChange} name='entitycity' className={`form-control ${entityErrors.entitycity && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entitycity: null }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>State </label>
                                                    <Select
                                                        id='entityState'
                                                        options={stateOptions}
                                                        placeholder="Select"
                                                        value={entity?.entitystate ? stateOptions.find(option => option.value === entity?.entitystate) : ""}
                                                        className={`w-100 ${entityErrors.entitystate && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entitystate: null }) }}
                                                        onChange={(selectedOption) => {
                                                            setEntity({ ...entity, entitystate: selectedOption.value });
                                                        }}
                                                        filterOption={customFilter}
                                                        tabIndex={5}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>ZIP Code </label>
                                                    <input id='entityZipcode' tabIndex={6} type="text" placeholder="Enter Zip Code" value={entity?.zipcode} onInput={(e) => { e.target.value = e.target.value.slice(0, 6) }} onChange={handleChange} name='zipcode' className={`form-control ${entityErrors.zipcode && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, zipcode: null }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>State of Formation </label>
                                                    <Select
                                                        id='entityFormationState'
                                                        options={stateOptions}
                                                        placeholder="Select"
                                                        value={stateOptions.find(option => option.value === entity?.entitystateformation)}
                                                        className={`w-100 ${entityErrors.entitystateformation && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entitystateformation: null }) }}
                                                        onChange={(selectedOption) => {
                                                            setEntity({ ...entity, entitystateformation: selectedOption.value });
                                                        }}
                                                        tabIndex={7}
                                                        filterOption={customFilter}
                                                    />
                                                </div>
                                            </div>
                                           
                                            <div className="col-md-12" style={{
                                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                            }}>
                                                {entityBtn === 'add' ? <div className="form-group">
                                                    <button id='saveEntity' className="fs_btn" onClick={(e) => { handleEntityAdd(); }}>save</button>
                                                </div> : <div className="form-group">
                                                    <button id='updateEntity' className="fs_btn" disabled={!isEntityChanged} style={{ 'opacity': isEntityChanged ? '1' : '0.6', }} onClick={(e) => { handleUpdateEntity() }}>update</button>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {viewEntityForm && <div className="create_entity">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18 title">{entityBtn === 'add' ? 'Create' : 'update'} entity</h4>
                                        <a id="navigateEntityBack" href="#" className="back_btn" onClick={(e) => { e.preventDefault(); handleEntityBack(); }}><span className="material-icons icon"> chevron_left
                                        </span>BACK</a>
                                    </div>
                                    <div className="form_section">
                                        <div className="row" style={{
                                            pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                        }}>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Type</label>
                                                    <select id='entityType' tabIndex={1} onChange={handleChange} name='entitytype' value={entity?.entitytype} className={`form-control ${entityErrors.entitytype && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entitytype: null }) }}>
                                                        <option selected="" value=''>Select</option>
                                                        {entityType?.map((data) => {
                                                            return (
                                                                <option key={data?.option} value={data?.option}>{data?.option}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Legal Name</label>
                                                    <input id='entityLegalName' tabIndex={2} type="text" value={entity?.legalname} placeholder="Enter Legal Name" onChange={handleChange} name='legalname' className={`form-control capitalize-text ${entityErrors.legalname && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, legalname: null }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <div className={`${entityErrors.entityaddress && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entityaddress: null }) }}> <LocationSearch simpleAddress={true} name={'entityaddress'} setAddressData={setAddressData} addressData={addressData} setSelectedPlace={setSelectedPlace} selectedPlace={entity?.entityaddress} className={`${entityErrors.entityaddress && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entityaddress: null }) }} /></div>
                                                    {/* <LocationSearch name={'entityaddress'} setAddressData={setAddressData} addressData={addressData} setSelectedPlace={setSelectedPlace} selectedPlace={entity?.entityaddress} className={`${entityErrors.entityaddress && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entityaddress: null }) }}/> */}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <input tabIndex={3} type="text" placeholder="Enter address" value={entity?.entityaddress} onChange={handleChange} name='entityaddress' className={`form-control ${entityErrors.entityaddress && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entityaddress: null }) }} />
                                            </div>
                                        </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input id='entityCity' tabIndex={4} type="text" placeholder="Enter City" value={entity?.entitycity} onChange={handleChange} name='entitycity' className={`form-control ${entityErrors.entitycity && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entitycity: null }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>State </label>
                                                    <Select
                                                        id='entityState'
                                                        options={stateOptions}
                                                        placeholder="Select"
                                                        value={entity?.entitystate ? stateOptions.find(option => option.value === entity?.entitystate) : ""}
                                                        className={`w-100 ${entityErrors.entitystate && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entitystate: null }) }}
                                                        onChange={(selectedOption) => {
                                                            setEntity({ ...entity, entitystate: selectedOption.value });
                                                        }}
                                                        filterOption={customFilter}
                                                        tabIndex={5}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>ZIP Code </label>
                                                    <input id='entityZipcode' tabIndex={6} type="text" placeholder="Enter Zip Code" value={entity?.zipcode} onInput={(e) => { e.target.value = e.target.value.slice(0, 6) }} onChange={handleChange} name='zipcode' className={`form-control ${entityErrors.zipcode && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, zipcode: null }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>State of Formation </label>
                                                    <Select
                                                        id='entityFormationState'
                                                        options={stateOptions}
                                                        placeholder="Select"
                                                        value={stateOptions.find(option => option.value === entity?.entitystateformation)}
                                                        className={`w-100 ${entityErrors.entitystateformation && 'invalid-input'}`} onFocus={() => { setEntityErrors({ ...entityErrors, entitystateformation: null }) }}
                                                        onChange={(selectedOption) => {
                                                            setEntity({ ...entity, entitystateformation: selectedOption.value });
                                                        }}
                                                        tabIndex={7}
                                                        filterOption={customFilter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}


                            </div>
                            <div className="tab-pane" id="APPLICANT" role="tabpanel" >
                                <div className='search' >
                                    {!showApplicantForm && !viewApplicantForm &&<button id="addNewApplicant" type="button" onClick={(e) => { handleApplicantCreate() }} className="create_btn" style={{
                                    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                }}>Add New Applicant</button>}


                                </div>
                                {!showApplicantForm && !viewApplicantForm &&
                                    <div className="table-responsive">
                                        <table className="table db_table">

                                            <thead className="table-light">
                                                <tr>
                                                    <th width="20%">Name</th>
                                                    <th width="20%">Email</th>
                                                    <th width="50%">address</th>
                                                    <th width="20%">Phone no</th>
                                                    <th width="6%">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allApplicants.map((data, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    {data?.applicant?.borrowerfirstname
                                                                        ? data.applicant.borrowerfirstname.charAt(0).toUpperCase() + data.applicant.borrowerfirstname.slice(1)
                                                                        : ''}
                                                                    {data?.applicant?.borrowerlastname
                                                                        ? ' ' + data.applicant.borrowerlastname.charAt(0).toUpperCase() + data.applicant.borrowerlastname.slice(1)
                                                                        : ''}
                                                                </td>

                                                                <td>{data?.applicant?.borroweremail}</td>
                                                                <td>{data?.applicant?.borroweraddress || data?.applicant?.borrowerfulladdress || ''} </td>
                                                                <td>{data?.applicant?.borrowerphone}</td>
                                                                <td>
                                                                    <div className="btn-group">
                                                                        <button id={`editApplicant-${index}`} type="button" onClick={() => { handleApplicantView(data?.applicant); }} className="btn btn-outline-secondary" ><span
                                                                            className="material-symbols-outlined icon tooltip-container"><span className="tooltip">View</span> visibility
                                                                        </span></button>
                                                                        <button id={`editApplicant-${index}`} type="button" onClick={() => { handleApplicantEdit(data?.applicant); }} className="btn btn-outline-secondary" style={{
                                                                            pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                            userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                            opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                                                        }}><span
                                                                            className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span> edit
                                                                            </span></button>
                                                                        {data?.applicant?.default ? '' : <>
                                                                            <button id={`deleteApplicant-${index}`} type="button" className="btn btn-outline-secondary"
                                                                                onClick={() => { deletApplicantClick(data?.applicant) }} style={{
                                                                                    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                                    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                                    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                                                                }}><span
                                                                                    className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span> delete
                                                                                </span></button></>}
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        </>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                }

                                {showApplicantForm && <div className="create_entity">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <div className="d-sm-flex align-items-center justify-content-between">
                                            <p className="mb-sm-0 font-size-18">General</p>
                                            <h4 className="mb-sm-0 font-size-18 co_borrower">Join Co-Borrower</h4>
                                        </div>
                                        <a id="navigateApplicantBack" href="#" className="back_btn" onClick={(e) => { e.preventDefault(); handleApplicantBack(); }}><span className="material-icons icon"> chevron_left
                                        </span>BACK</a>
                                    </div>
                                    <div className="form_section">
                                        <div className="row" style={{
                                            pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                        }}>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input id="applicantFirstName" type="text" name='borrowerfirstname' value={applicant?.borrowerfirstname} onChange={handleApplicantChange} className={`form-control capitalize-text ${applicantErrors.borrowerfirstname && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowerfirstname: null }) }} placeholder="Enter First Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Middle initial</label>
                                                    <input id="applicantMiddleName" type="text" name='mi' value={applicant?.mi} onChange={handleApplicantChange} className={`form-control capitalize-text ${applicantErrors.mi && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, mi: null }) }} placeholder="Enter Middle initial" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input id="applicantLastName" type="text" name='borrowerlastname' value={applicant?.borrowerlastname} onChange={handleApplicantChange} className={`form-control capitalize-text ${applicantErrors.borrowerlastname && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowerlastname: null }) }} placeholder="Enter Last Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Suffix</label>
                                                    <select id="applicantSuffix" name='suffix' value={applicant?.suffix} onChange={handleApplicantChange} className={`form-control ${applicantErrors.suffix && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, suffix: null }) }} placeholder="Please Select">
                                                        <option value="">Please Select</option>
                                                        <option value="Jr">Jr</option>
                                                        <option value="Sr">Sr</option>
                                                        <option value="II">II</option>
                                                        <option value="III">III</option>
                                                        <option value="IV">IV</option>
                                                        <option value="V">V</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <input id="applicantPhone" type='tel' name='borrowerphone' onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)} value={applicant?.borrowerphone} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borrowerphone && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowerphone: null }) }}
                                                        placeholder="(xxx) xxx-xxxx" pattern="\(\d{3}\) \d{3}-\d{4}" title="Phone number must be in the format: (123) 456-7890" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input id="applicantEmail" type="text" name='borroweremail' value={applicant?.borroweremail} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borroweremail && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweremail: null }) }} placeholder="Enter Email" />
                                                    {(applicant?.borroweremail?.trim() && applicantErrors?.borroweremail) && <span class="error-message" style={{ color: "red" }}>Invalid Email Format!</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Date of Birth</label>
                                                    <input id="applicantBirthDate" type="date" name='dateofbirth' max={(new Date()).toISOString().split('T')[0]} value={applicant?.dateofbirth ? (new Date(applicant?.dateofbirth).toISOString().split('T')[0]) : ''} onChange={handleApplicantChange} className={`form-control ${applicantErrors.dateofbirth && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, dateofbirth: null }) }} placeholder="mm-dd-yyy"
                                                        onInput={(e) => {
                                                            const dateValue = e.target.value;
                                                            const year = dateValue.split('-')[0];
                                                            const selectedDate = new Date(dateValue);
                                                            const currentDate = new Date();
                                                            if (selectedDate > currentDate) {
                                                                e.target.value = dateValue.slice(0, 5);
                                                            }
                                                            if (year.length > 4) {
                                                                e.target.value = dateValue.slice(0, -1);
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Marital Status</label>
                                                    <select id="applicantMaritalStatus" name='maritialstatus' value={applicant?.maritialstatus} onChange={handleApplicantChange} className={`form-control ${applicantErrors.maritialstatus && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, maritialstatus: null }) }} placeholder="Please Select">
                                                        <option value="">Please Select</option>
                                                        <option value="single man">Single Man</option>
                                                        <option value="single woman">Single Woman</option>
                                                        <option value="married man">Married Man</option>
                                                        <option value="married woman">Married Woman</option>
                                                        <option value="unmarried man">Unmarried Man</option>
                                                        <option value="unmarried woman">Unmarried Woman</option>
                                                        <option value="widowed man">Widowed Man</option>
                                                        <option value="widowed woman">Widowed Woman</option>

                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label></label>
                                                    <label className="toggle mb-0">
                                                        <label className="toggle-label" onClick={(e) => e.stopPropagation()}>Applicant is a guarantor</label>
                                                        <input id="applicantGuarantor" className="toggle-checkbox" name='applicantisaguarantor' onChange={handleApplicantCheckbox} checked={applicant?.applicantisaguarantor ? true : false} type="checkbox" />
                                                        <div className="toggle-switch"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Guarantor’s Title</label>
                                                    <input id="applicantGuarantorTitle" type="text" name='guarantorstitle' value={applicant?.guarantorstitle} onChange={handleApplicantChange} disabled={!(applicant?.applicantisaguarantor ? true : false)} className={`form-control capitalize-text ${applicantErrors.guarantorstitle && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, guarantorstitle: null }) }}
                                                        placeholder="Enter guarantor’s Title" />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label></label>
                                                    <label className="toggle mb-0">
                                                        <label className="toggle-label">Applicant is a signer</label>
                                                        <input id="applicantSigner" className="toggle-checkbox" name='applicantisasigner' onChange={handleApplicantCheckbox} checked={applicant?.applicantisasigner ? true : false} type="checkbox" />
                                                        <div className="toggle-switch"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Signer’s Title</label>
                                                    <input id="applicantSignerTitle" type="text" name='signerstitle' value={applicant?.signerstitle} onChange={handleApplicantChange} disabled={!(applicant?.applicantisasigner ? true : false)} className={`form-control ${applicantErrors.signerstitle && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, signerstitle: null }) }}
                                                        placeholder="Enter signer’s Title" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <div className={` ${applicantErrors.borroweraddress && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweraddress: null }) }} >
                                                        {mapsKey &&


                                                            <LocationSearch simpleAddress={true} name={'borroweraddress'} setAddressData={setApplicantAddress} addressData={applicantAddress} setSelectedPlace={setApplicantPlace} selectedPlace={applicant?.borroweraddress} className={`form-control ${applicantErrors.borroweraddress && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweraddress: null }) }} />
                                                        } </div>
                                                    {/* <LocationSearch name={'borroweraddress'} setAddressData={setApplicantAddress} addressData={applicantAddress} setSelectedPlace={setApplicantPlace} selectedPlace={applicant?.borroweraddress } className={`form-control ${applicantErrors.borroweraddress && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweraddress: null }) }} /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Address 2</label>
                                                    <input id="borroweraddress2" type="text" name='borroweraddress2' value={applicant?.borroweraddress2} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borroweraddress2 && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweraddress2: null }) }} placeholder="Enter Address 2" />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <input type="text" name='borroweraddress' value={applicant?.borroweraddress} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borroweraddress && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweraddress: null }) }} placeholder="Enter address" />
                                            </div>
                                        </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input id="applicantCity" type="text" name='borrowercity' placeholder='Enter City' value={applicant?.borrowercity} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borrowercity && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowercity: null }) }} />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>County</label>
                                                <input id="applicantCounty" type="text" name='borrowercounty' placeholder='Enter County' value={applicant?.borrowercounty} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borrowercounty && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowercounty: null }) }} />
                                            </div>
                                        </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>State </label>
                                                    <Select
                                                        id="applicantState"
                                                        options={stateOptions}
                                                        placeholder="Select"
                                                        value={applicant?.borrowerstate ? stateOptions.find(option => option?.value === applicant?.borrowerstate) : ''}
                                                        className={`w-100 ${applicantErrors.borrowerstate && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowerstate: null }) }}
                                                        onChange={(selectedOption) => {
                                                            setApplicant({ ...applicant, borrowerstate: selectedOption.value });
                                                        }}
                                                        filterOption={customFilter}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>ZIP Code</label>
                                                    <input id="applicantZipCode" type="text" placeholder="Enter Zip Code" value={applicant?.borrowerzipcode} onChange={handleApplicantChange} onInput={(e) => { e.target.value = e.target.value.slice(0, 6) }} name='borrowerzipcode' className={`form-control ${applicantErrors.borrowerzipcode && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowerzipcode: null }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>SSN</label>
                                                    <input id="applicantSSN" type="text" name='ssn' value={applicant?.ssn} onChange={handleApplicantChange} pattern="\\d{4}\- \d{2}-\d{3}" className={`form-control ${applicantErrors.ssn && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, ssn: null }) }} placeholder="xxx-xx-xxxx" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <div className="d-sm-flex align-items-center justify-content-between">
                                                    <h3 className="title mt_20 mb-0">Credit Score</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Stated Credit Score</label>
                                                    <input id="applicantStatedCreditScore" type="number" className={`form-control`} name='statedcreditscore' value={applicant?.statedcreditscore}
                                                        disabled={true} placeholder="Stated Credit Score" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Credit Score</label>
                                                    <input id="applicantCreditScore" type="number" name='creditscore' className={`form-control`} value={applicant?.creditscore} onChange={handleApplicantChange} placeholder="Enter Credit Score" max="1000" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Credit Scoring Model</label>
                                                    <select id="applicantCreditScoringModel" className={`form-control ${applicantErrors.creditscoremodel && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, creditscoremodel: null }) }} name='creditscoremodel' placeholder="Please Select" value={applicant?.creditscoremodel} onChange={handleApplicantChange}>
                                                        <option selected value="">Select</option>
                                                        <option value="Equifax Beacon 5.0">Equifax Beacon 5.0</option>
                                                        <option value="Experian Fair Isaac">Experian Fair Isaac</option>
                                                        <option value="FICO Risk Score Classic 04">FICO Risk Score Classic 04</option>
                                                        <option value="FICO Risk Score Classic 98">FICO Risk Score Classic 98</option>
                                                        <option value="More than one credit scoring model">More than one credit scoring model</option>
                                                        <option value="No co-applicant">No co-applicant</option>
                                                        <option value="Not applicable">Not applicable</option>
                                                        <option value="Other credit scoring model">Other credit scoring model</option>
                                                        <option value="VantageScore 2.0">VantageScore 2.0</option>
                                                        <option value="VantageScore 3.0">VantageScore 3.0</option>


                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Pulled Date</label>
                                                    <input id="applicantPulledDate" type="date" name='pulleddate' max={new Date().toISOString().split('T')[0]} value={applicant?.pulleddate ? (new Date(applicant?.pulleddate).toISOString().split('T')[0]) : ''} onChange={handleApplicantChange} className={`form-control`} placeholder="mm-dd-yyy" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="d-sm-flex align-items-center justify-content-between">
                                                    <h3 className="title mt_20 mb-0">HMDA</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Ethnicity </label>
                                                    <select id="applicantEthnicity" onChange={handleHmdaChange} name='ethnicity' value={applicant?.ethnicity} className={`form-control ${applicantErrors.ethnicity && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, ethnicity: null }) }}>
                                                        <option selected value=''>Select</option>
                                                        <option value='Hispanic or Latino'>Hispanic or Latino</option>
                                                        <option value='Not Hispanic or Latino'>Not Hispanic or Latino</option>
                                                        <option value='I do not wish to provide this information'>I do not wish to provide this information</option>
                                                        {/* <option value='not applicable'>Not Applicable</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {applicant?.ethnicity === 'Hispanic or Latino' &&
                                                        (<> <label>Hispanic or Latino</label>
                                                            <select id="applicantHispanic" onChange={handleHmdaChange} name='subethnicity' value={applicant?.subethnicity} className={`form-control ${applicantErrors.subethnicity && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, subethnicity: null }) }}>
                                                                <option selected value=''>Select</option>
                                                                <option value='Mexican'>Mexican</option>
                                                                <option value='Puerto Rican'>Puerto Rican</option>
                                                                <option value='Cuban'>Cuban</option>
                                                                <option value='Other Hispanic or Latino'>Other Hispanic or Latino</option>
                                                            </select>
                                                        </>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {applicant?.subethnicity === 'Other Hispanic or Latino' &&
                                                        (<> <label>Other Hispanic or Latino</label>
                                                            <input id="applicantOtherHispanic" type="text" onChange={handleHmdaChange} value={applicant?.otherhispanic} placeholder="Please Specify" name='otherhispanic' className={`form-control ${applicantErrors.otherhispanic && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, otherhispanic: null }) }} />
                                                        </>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Race </label>
                                                    <select id="applicantRace" onChange={handleHmdaChange} name='race' value={applicant?.race} className={`form-control ${applicantErrors.race && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, race: null }) }}>
                                                        <option selected value=''>Select</option>
                                                        <option value='American Indian or Alaska Native'>American Indian or Alaska Native</option>
                                                        <option value='asian'>Asian</option>
                                                        <option value='black'>Black or African American</option>
                                                        <option value='Native Hawaiian or Other Pacific Islander'>Native Hawaiian or Other Pacific Islander</option>
                                                        <option value='white'>White</option>
                                                        <option value='I do not wish to provide this information'>I do not wish to provide this information</option>
                                                        {/* <option value='not applicable'>Not Applicable</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {applicant?.race === 'asian' &&
                                                        (<> <label>Asian</label>
                                                            <select id="applicantAsian" onChange={handleHmdaChange} name='subrace' value={applicant?.subrace} className={`form-control ${applicantErrors.subrace && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, subrace: null }) }} >
                                                                <option selected value=''>Select</option>
                                                                <option value='Asian Indian'>Asian Indian</option>
                                                                <option value='Chinese'>Chinese</option>
                                                                <option value='Filipino'>Filipino</option>
                                                                <option value='Japanese'> Japanese</option>
                                                                <option value='Korean'>Korean</option>
                                                                <option value='Vietnamese'> Vietnamese</option>
                                                                <option value='Other Asian'> Other Asian</option>
                                                            </select>
                                                        </>
                                                        )}
                                                    {applicant?.race === 'Native Hawaiian or Other Pacific Islander' &&
                                                        (<> <label>Native Hawaiian or Other Pacific Islander</label>
                                                            <select id="applicantNativeOther" onChange={handleHmdaChange} name='subrace' value={applicant?.subrace} className={`form-control ${applicantErrors.subrace && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, subrace: null }) }} >
                                                                <option selected value=''>Select</option>
                                                                <option value='Native Hawaiian'>Native Hawaiian</option>
                                                                <option value='Samoan'>Samoan</option>
                                                                <option value='Guamanian or Chamorro'> Guamanian or Chamorro</option>
                                                                <option value='Other Pacific Islander'>Other Pacific Islander</option>
                                                            </select>
                                                        </>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {(applicant?.subrace === 'Other Asian' || applicant?.subrace === 'Other Pacific Islander') && (
                                                        <>
                                                            <label>Other Please Specify</label>
                                                            <input id="applicantotherAsian" type="text" value={applicant?.otherrace} onChange={handleHmdaChange} placeholder="Please Specify" name='otherrace' className={`form-control ${applicantErrors.otherrace && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, otherrace: null }) }} />
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Gender </label>
                                                    <select id="applicantGender" onChange={handleHmdaChange} name='gender' value={applicant?.gender} className={`form-control ${applicantErrors.gender && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, gender: null }) }}>
                                                        <option selected value=''>Select</option>
                                                        <option value='female'>Female</option>
                                                        <option value='male'>Male</option>
                                                        <option value='I do not wish to provide this information'>I do not wish to provide this information</option>
                                                        {/* <option value='not applicable'>Not Applicable</option> */}
                                                    </select>
                                                </div>
                                            </div>

                                            {editPermissions == true &&
                                            <div className="col-md-12">
                                                {applicantBtn === 'add' ? <div className="form-group">
                                                    <a id="saveApplicant" href="#" className="fs_btn" onClick={(e) => { e.preventDefault(); handleApplicantAdd(); }}>save</a>
                                                </div> : <div className="form-group">
                                                    <button id="updateApplicant" className="fs_btn" disabled={!isApplicantChanges} style={{ 'opacity': isApplicantChanges ? 1 : 0.6 }} onClick={(e) => { e.preventDefault(); handleUpdateApplicant(); }}>update</button>
                                                </div>}
                                            </div>}

                                        </div>
                                    </div>
                                </div>}
                                {viewApplicantForm && <div className="create_entity">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <div className="d-sm-flex align-items-center justify-content-between">
                                            <p className="mb-sm-0 font-size-18">General</p>
                                            <h4 className="mb-sm-0 font-size-18 co_borrower">Join Co-Borrower</h4>
                                        </div>
                                        <a id="navigateApplicantBack" href="#" className="back_btn" onClick={(e) => { e.preventDefault(); handleApplicantBack(); }}><span className="material-icons icon"> chevron_left
                                        </span>BACK</a>
                                    </div>
                                    <div className="form_section">
                                        <div className="row" style={{
                                            pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                        }}>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input id="applicantFirstName" type="text" name='borrowerfirstname' value={applicant?.borrowerfirstname} onChange={handleApplicantChange} className={`form-control capitalize-text ${applicantErrors.borrowerfirstname && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowerfirstname: null }) }} placeholder="Enter First Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Middle initial</label>
                                                    <input id="applicantMiddleName" type="text" name='mi' value={applicant?.mi} onChange={handleApplicantChange} className={`form-control capitalize-text ${applicantErrors.mi && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, mi: null }) }} placeholder="Enter Middle initial" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input id="applicantLastName" type="text" name='borrowerlastname' value={applicant?.borrowerlastname} onChange={handleApplicantChange} className={`form-control capitalize-text ${applicantErrors.borrowerlastname && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowerlastname: null }) }} placeholder="Enter Last Name" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Suffix</label>
                                                    <select id="applicantSuffix" name='suffix' value={applicant?.suffix} onChange={handleApplicantChange} className={`form-control ${applicantErrors.suffix && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, suffix: null }) }} placeholder="Please Select">
                                                        <option value="">Please Select</option>
                                                        <option value="Jr">Jr</option>
                                                        <option value="Sr">Sr</option>
                                                        <option value="II">II</option>
                                                        <option value="III">III</option>
                                                        <option value="IV">IV</option>
                                                        <option value="V">V</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <input id="applicantPhone" type='tel' name='borrowerphone' onInput={(e) => e.target.value = formatPhoneNumber(e.target.value)} value={applicant?.borrowerphone} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borrowerphone && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowerphone: null }) }}
                                                        placeholder="(xxx) xxx-xxxx" pattern="\(\d{3}\) \d{3}-\d{4}" title="Phone number must be in the format: (123) 456-7890" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input id="applicantEmail" type="text" name='borroweremail' value={applicant?.borroweremail} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borroweremail && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweremail: null }) }} placeholder="Enter Email" />
                                                    {(applicant?.borroweremail?.trim() && applicantErrors?.borroweremail) && <span class="error-message" style={{ color: "red" }}>Invalid Email Format!</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Date of Birth</label>
                                                    <input id="applicantBirthDate" type="date" name='dateofbirth' max={(new Date()).toISOString().split('T')[0]} value={applicant?.dateofbirth ? (new Date(applicant?.dateofbirth).toISOString().split('T')[0]) : ''} onChange={handleApplicantChange} className={`form-control ${applicantErrors.dateofbirth && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, dateofbirth: null }) }} placeholder="mm-dd-yyy"
                                                        onInput={(e) => {
                                                            const dateValue = e.target.value;
                                                            const year = dateValue.split('-')[0];
                                                            const selectedDate = new Date(dateValue);
                                                            const currentDate = new Date();
                                                            if (selectedDate > currentDate) {
                                                                e.target.value = dateValue.slice(0, 5);
                                                            }
                                                            if (year.length > 4) {
                                                                e.target.value = dateValue.slice(0, -1);
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Marital Status</label>
                                                    <select id="applicantMaritalStatus" name='maritialstatus' value={applicant?.maritialstatus} onChange={handleApplicantChange} className={`form-control ${applicantErrors.maritialstatus && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, maritialstatus: null }) }} placeholder="Please Select">
                                                        <option value="">Please Select</option>
                                                        <option value="single man">Single Man</option>
                                                        <option value="single woman">Single Woman</option>
                                                        <option value="married man">Married Man</option>
                                                        <option value="married woman">Married Woman</option>
                                                        <option value="unmarried man">Unmarried Man</option>
                                                        <option value="unmarried woman">Unmarried Woman</option>
                                                        <option value="widowed man">Widowed Man</option>
                                                        <option value="widowed woman">Widowed Woman</option>

                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label></label>
                                                    <label className="toggle mb-0">
                                                        <label className="toggle-label" onClick={(e) => e.stopPropagation()}>Applicant is a guarantor</label>
                                                        <input id="applicantGuarantor" className="toggle-checkbox" name='applicantisaguarantor' onChange={handleApplicantCheckbox} checked={applicant?.applicantisaguarantor ? true : false} type="checkbox" />
                                                        <div className="toggle-switch"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Guarantor’s Title</label>
                                                    <input id="applicantGuarantorTitle" type="text" name='guarantorstitle' value={applicant?.guarantorstitle} onChange={handleApplicantChange} disabled={!(applicant?.applicantisaguarantor ? true : false)} className={`form-control capitalize-text ${applicantErrors.guarantorstitle && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, guarantorstitle: null }) }}
                                                        placeholder="Enter guarantor’s Title" />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label></label>
                                                    <label className="toggle mb-0">
                                                        <label className="toggle-label">Applicant is a signer</label>
                                                        <input id="applicantSigner" className="toggle-checkbox" name='applicantisasigner' onChange={handleApplicantCheckbox} checked={applicant?.applicantisasigner ? true : false} type="checkbox" />
                                                        <div className="toggle-switch"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Signer’s Title</label>
                                                    <input id="applicantSignerTitle" type="text" name='signerstitle' value={applicant?.signerstitle} onChange={handleApplicantChange} disabled={!(applicant?.applicantisasigner ? true : false)} className={`form-control ${applicantErrors.signerstitle && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, signerstitle: null }) }}
                                                        placeholder="Enter signer’s Title" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <div className={` ${applicantErrors.borroweraddress && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweraddress: null }) }} >
                                                        {mapsKey &&


                                                            <LocationSearch simpleAddress={true} name={'borroweraddress'} setAddressData={setApplicantAddress} addressData={applicantAddress} setSelectedPlace={setApplicantPlace} selectedPlace={applicant?.borroweraddress} className={`form-control ${applicantErrors.borroweraddress && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweraddress: null }) }} />
                                                        } </div>
                                                    {/* <LocationSearch name={'borroweraddress'} setAddressData={setApplicantAddress} addressData={applicantAddress} setSelectedPlace={setApplicantPlace} selectedPlace={applicant?.borroweraddress } className={`form-control ${applicantErrors.borroweraddress && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweraddress: null }) }} /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Address 2</label>
                                                    <input id="borroweraddress2" type="text" name='borroweraddress2' value={applicant?.borroweraddress2} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borroweraddress2 && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweraddress2: null }) }} placeholder="Enter Address 2" />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <input type="text" name='borroweraddress' value={applicant?.borroweraddress} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borroweraddress && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borroweraddress: null }) }} placeholder="Enter address" />
                                            </div>
                                        </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input id="applicantCity" type="text" name='borrowercity' placeholder='Enter City' value={applicant?.borrowercity} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borrowercity && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowercity: null }) }} />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>County</label>
                                                <input id="applicantCounty" type="text" name='borrowercounty' placeholder='Enter County' value={applicant?.borrowercounty} onChange={handleApplicantChange} className={`form-control ${applicantErrors.borrowercounty && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowercounty: null }) }} />
                                            </div>
                                        </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>State </label>
                                                    <Select
                                                        id="applicantState"
                                                        options={stateOptions}
                                                        placeholder="Select"
                                                        value={applicant?.borrowerstate ? stateOptions.find(option => option?.value === applicant?.borrowerstate) : ''}
                                                        className={`w-100 ${applicantErrors.borrowerstate && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowerstate: null }) }}
                                                        onChange={(selectedOption) => {
                                                            setApplicant({ ...applicant, borrowerstate: selectedOption.value });
                                                        }}
                                                        filterOption={customFilter}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>ZIP Code</label>
                                                    <input id="applicantZipCode" type="text" placeholder="Enter Zip Code" value={applicant?.borrowerzipcode} onChange={handleApplicantChange} onInput={(e) => { e.target.value = e.target.value.slice(0, 6) }} name='borrowerzipcode' className={`form-control ${applicantErrors.borrowerzipcode && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, borrowerzipcode: null }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>SSN</label>
                                                    <input id="applicantSSN" type="text" name='ssn' value={applicant?.ssn} onChange={handleApplicantChange} pattern="\\d{4}\- \d{2}-\d{3}" className={`form-control ${applicantErrors.ssn && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, ssn: null }) }} placeholder="xxx-xx-xxxx" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <div className="d-sm-flex align-items-center justify-content-between">
                                                    <h3 className="title mt_20 mb-0">Credit Score</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Stated Credit Score</label>
                                                    <input id="applicantStatedCreditScore" type="number" className={`form-control`} name='statedcreditscore' value={applicant?.statedcreditscore}
                                                        disabled={true} placeholder="Stated Credit Score" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Credit Score</label>
                                                    <input id="applicantCreditScore" type="number" name='creditscore' className={`form-control`} value={applicant?.creditscore} onChange={handleApplicantChange} placeholder="Enter Credit Score" max="1000" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Credit Scoring Model</label>
                                                    <select id="applicantCreditScoringModel" className={`form-control ${applicantErrors.creditscoremodel && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, creditscoremodel: null }) }} name='creditscoremodel' placeholder="Please Select" value={applicant?.creditscoremodel} onChange={handleApplicantChange}>
                                                        <option selected value="">Select</option>
                                                        <option value="Equifax Beacon 5.0">Equifax Beacon 5.0</option>
                                                        <option value="Experian Fair Isaac">Experian Fair Isaac</option>
                                                        <option value="FICO Risk Score Classic 04">FICO Risk Score Classic 04</option>
                                                        <option value="FICO Risk Score Classic 98">FICO Risk Score Classic 98</option>
                                                        <option value="VantageScore 2.0">VantageScore 2.0</option>
                                                        <option value="VantageScore 3.0">VantageScore 3.0</option>
                                                        <option value="More than one credit scoring model">More than one credit scoring model</option>
                                                        <option value="Other credit scoring model">Other credit scoring model</option>
                                                        <option value="Not applicable">Not applicable</option>
                                                        <option value="No co-applicant">No co-applicant</option>

                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Pulled Date</label>
                                                    <input id="applicantPulledDate" type="date" name='pulleddate' max={new Date().toISOString().split('T')[0]} value={applicant?.pulleddate ? (new Date(applicant?.pulleddate).toISOString().split('T')[0]) : ''} onChange={handleApplicantChange} className={`form-control`} placeholder="mm-dd-yyy" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="d-sm-flex align-items-center justify-content-between">
                                                    <h3 className="title mt_20 mb-0">HMDA</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Ethnicity </label>
                                                    <select id="applicantEthnicity" onChange={handleHmdaChange} name='ethnicity' value={applicant?.ethnicity} className={`form-control ${applicantErrors.ethnicity && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, ethnicity: null }) }}>
                                                        <option selected value=''>Select</option>
                                                        <option value='Hispanic or Latino'>Hispanic or Latino</option>
                                                        <option value='Not Hispanic or Latino'>Not Hispanic or Latino</option>
                                                        <option value='I do not wish to provide this information'>I do not wish to provide this information</option>
                                                        {/* <option value='not applicable'>Not Applicable</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {applicant?.ethnicity === 'Hispanic or Latino' &&
                                                        (<> <label>Hispanic or Latino</label>
                                                            <select id="applicantHispanic" onChange={handleHmdaChange} name='subethnicity' value={applicant?.subethnicity} className={`form-control ${applicantErrors.subethnicity && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, subethnicity: null }) }}>
                                                                <option selected value=''>Select</option>
                                                                <option value='Mexican'>Mexican</option>
                                                                <option value='Puerto Rican'>Puerto Rican</option>
                                                                <option value='Cuban'>Cuban</option>
                                                                <option value='Other Hispanic or Latino'>Other Hispanic or Latino</option>
                                                            </select>
                                                        </>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {applicant?.subethnicity === 'Other Hispanic or Latino' &&
                                                        (<> <label>Other Hispanic or Latino</label>
                                                            <input id="applicantOtherHispanic" type="text" onChange={handleHmdaChange} value={applicant?.otherhispanic} placeholder="Please Specify" name='otherhispanic' className={`form-control ${applicantErrors.otherhispanic && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, otherhispanic: null }) }} />
                                                        </>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Race </label>
                                                    <select id="applicantRace" onChange={handleHmdaChange} name='race' value={applicant?.race} className={`form-control ${applicantErrors.race && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, race: null }) }}>
                                                        <option selected value=''>Select</option>
                                                        <option value='American Indian or Alaska Native'>American Indian or Alaska Native</option>
                                                        <option value='asian'>Asian</option>
                                                        <option value='black'>Black or African American</option>
                                                        <option value='Native Hawaiian or Other Pacific Islander'>Native Hawaiian or Other Pacific Islander</option>
                                                        <option value='white'>White</option>
                                                        <option value='I do not wish to provide this information'>I do not wish to provide this information</option>
                                                        {/* <option value='not applicable'>Not Applicable</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {applicant?.race === 'asian' &&
                                                        (<> <label>Asian</label>
                                                            <select id="applicantAsian" onChange={handleHmdaChange} name='subrace' value={applicant?.subrace} className={`form-control ${applicantErrors.subrace && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, subrace: null }) }} >
                                                                <option selected value=''>Select</option>
                                                                <option value='Asian Indian'>Asian Indian</option>
                                                                <option value='Chinese'>Chinese</option>
                                                                <option value='Filipino'>Filipino</option>
                                                                <option value='Japanese'> Japanese</option>
                                                                <option value='Korean'>Korean</option>
                                                                <option value='Vietnamese'> Vietnamese</option>
                                                                <option value='Other Asian'> Other Asian</option>
                                                            </select>
                                                        </>
                                                        )}
                                                    {applicant?.race === 'Native Hawaiian or Other Pacific Islander' &&
                                                        (<> <label>Native Hawaiian or Other Pacific Islander</label>
                                                            <select id="applicantNativeOther" onChange={handleHmdaChange} name='subrace' value={applicant?.subrace} className={`form-control ${applicantErrors.subrace && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, subrace: null }) }} >
                                                                <option selected value=''>Select</option>
                                                                <option value='Native Hawaiian'>Native Hawaiian</option>
                                                                <option value='Samoan'>Samoan</option>
                                                                <option value='Guamanian or Chamorro'> Guamanian or Chamorro</option>
                                                                <option value='Other Pacific Islander'>Other Pacific Islander</option>
                                                            </select>
                                                        </>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {(applicant?.subrace === 'Other Asian' || applicant?.subrace === 'Other Pacific Islander') && (
                                                        <>
                                                            <label>Other Please Specify</label>
                                                            <input id="applicantotherAsian" type="text" value={applicant?.otherrace} onChange={handleHmdaChange} placeholder="Please Specify" name='otherrace' className={`form-control ${applicantErrors.otherrace && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, otherrace: null }) }} />
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Gender </label>
                                                    <select id="applicantGender" onChange={handleHmdaChange} name='gender' value={applicant?.gender} className={`form-control ${applicantErrors.gender && 'invalid-input'}`} onFocus={() => { setApplicantErrors({ ...applicantErrors, gender: null }) }}>
                                                        <option selected value=''>Select</option>
                                                        <option value='female'>Female</option>
                                                        <option value='male'>Male</option>
                                                        <option value='I do not wish to provide this information'>I do not wish to provide this information</option>
                                                        {/* <option value='not applicable'>Not Applicable</option> */}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            </div>

                            <div className="tab-pane property" id="PROPERTY" role="tabpanel" >
                                <div className='search' style={{
                                    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                }}>
                                    {!showPropertyForm && !viewPropertyForm &&<>
                                        <button id="AddNewProperty" type="button" onClick={(e) => { e.preventDefault(); handlePropertyCreate() }} className="create_btn">Add New Property</button>
                                        <div className='d-flex'>
                                            {/* <button type="button" className="create_btn"><span className="material-symbols-outlined tooltip-container"><span className="tooltip">Download</span>
                                            download_2 </span></button> */}
                                            <button id="exportExcelPropertyData" onClick={handleExportToExcel} type="button" className="create_btn"><img src="assets/images/icons/excel.svg"
                                                className="img" alt="" /></button>
                                        </div>
                                    </>}

                                </div>
                                {!showPropertyForm && !viewPropertyForm &&<div className="table-responsive">
                                    <table className="table db_table">

                                        <thead className="table-light">
                                            <tr>
                                                <th width="22%">Property address</th>
                                                <th width="14%">APN/ Assessor’s Parcel Number</th>
                                                <th width="12%">Lot SQ FT</th>
                                                <th width="12%">Finished SQ FT</th>
                                                <th width="12%">Flood Insurance Exp Date</th>
                                                <th width="12%">General Liability Exp Date</th>
                                                <th width="6%">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allProperties.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{data?.propertyinfo?.propertyfulladdress || data?.propertyinfo?.propertyaddress}</td>
                                                        <td>{data?.propertyinfo?.apn}</td>
                                                        <td>{formatNumericInput(data?.propertyinfo?.lotsqft?.toString())}</td>
                                                        <td>{formatNumericInput(data?.propertyinfo?.finishedsqft?.toString())}</td>
                                                        <td> {data?.propertyinfo?.floodinsuranceexpirydate && moment.utc(data?.propertyinfo?.floodinsuranceexpirydate).format("MM-DD-YYYY")}</td>
                                                        <td>{data?.propertyinfo?.generalliabilityexpirydate && moment.utc(data?.propertyinfo?.generalliabilityexpirydate).format("MM-DD-YYYY")}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                <button id={`edit-property-${index}`} type="button" className="btn btn-outline-secondary" onClick={() => { handlePropertyView(data?.propertyinfo); }} ><span
                                                                    className="material-symbols-outlined icon tooltip-container"><span className="tooltip">View</span> visibility
                                                                </span></button>
                                                                <button id={`edit-property-${index}`} type="button" className="btn btn-outline-secondary" onClick={() => { handlePropertyEdit(data?.propertyinfo); }} style={{
                                                                    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                                                }}><span
                                                                    className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span> edit
                                                                    </span></button>
                                                                {data?.propertyinfo?.default ? '' : <>
                                                                    <button id={`delete-property-${index}`} type="button" className="btn btn-outline-secondary"
                                                                        onClick={() => { deletePropertyClick(data?.propertyinfo) }} style={{
                                                                            pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                            userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                                            opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                                                        }}><span
                                                                            className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span> delete
                                                                        </span></button> </>}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </div>}

                                {showPropertyForm && <div className="create_entity">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0 font-size-18 title">{propertyBtn === 'add' ? 'Create' : 'Update'} Property</h4>
                                        <a id="navigatePropertyBack" href="#" className="back_btn" onClick={(e) => { e.preventDefault(); handlePropertyBack(); }}><span className="material-icons icon"> chevron_left
                                        </span>BACK</a>
                                    </div>
                                    <div className="form_section">
                                        <div className="row" style={{
                                            pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                        }}>

                                            {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Property address</label>
                                                <input type="text" onChange={handlePropertyChange} name='propertyaddress' value={property?.propertyaddress} className={`form-control ${propertyErrors.propertyaddress && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyaddress: null }) }}
                                                    placeholder="Enter Property address" />
                                            </div>
                                        </div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Property address</label>
                                                    <div className={` ${propertyErrors.propertyaddress && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyaddress: null }) }}>
                                                        {mapsKey && <LocationSearch simpleAddress={true} name={'propertyaddress'} setAddressData={setPropertyAddress} addressData={propertyAddress} setSelectedPlace={setPropertyPlace} selectedPlace={property?.propertyaddress} className={`form-control ${propertyErrors.propertyaddress && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyaddress: null }) }} />}
                                                    </div>
                                                    {/* <LocationSearch name={'propertyaddress'} setAddressData={setPropertyAddress} addressData={propertyAddress} setSelectedPlace={setPropertyPlace} selectedPlace={property?.propertyaddress} className={`form-control ${propertyErrors.propertyaddress && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyaddress: null }) }}/> */}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Property Address 2</label>
                                                    <input id="propertyaddress2" type="text" value={property?.propertyaddress2} name='propertyaddress2' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.propertyaddress2 && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyaddress2: null }) }} v placeholder="Enter Property Address 2" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input id="propertyCity" type="text" name='propertycity' placeholder='Enter City' value={property?.propertycity} onChange={handlePropertyChange} className={`form-control ${propertyErrors.propertycity && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertycity: null }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>County</label>
                                                    <input id="propertyCounty" type="text" name='propertycounty' placeholder='Enter County' value={property?.propertycounty} onChange={handlePropertyChange} className={`form-control ${propertyErrors.propertycounty && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertycounty: null }) }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>State </label>
                                                    <Select
                                                        inputId="propertyState"
                                                        options={stateOptions}
                                                        placeholder="Select"
                                                        value={property.propertystate ? stateOptions.find(option => option?.value === property.propertystate) : ''}
                                                        className={`w-100 ${propertyErrors.propertystate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertystate: null }) }}
                                                        onChange={(selectedOption) => {
                                                            setProperty({ ...property, propertystate: selectedOption.value });
                                                        }}
                                                        filterOption={customFilter}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3"></div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>ZIP Code</label>
                                                    <input id="propertyZipcode" type="text" placeholder="Enter Zip Code" onInput={(e) => { e.target.value = e.target.value.slice(0, 6) }} value={property?.propertyzipcode} onChange={handlePropertyChange} name='propertyzipcode' className={`form-control ${propertyErrors.propertyzipcode && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyzipcode: null }) }} />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Bedrooms</label>
                                                    <input id="propertyBedrooms" type="text" value={property?.totalbedrooms} name='totalbedrooms' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.totalbedrooms && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, totalbedrooms: null }) }} v placeholder="Enter No Of Bedrooms" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Bathrooms</label>
                                                    <input id="propertyBathrooms" type="text" value={property?.totalbathrooms} name='totalbathrooms' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.totalbathrooms && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, totalbathrooms: null }) }} v placeholder="Enter No Of Bathrooms" />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3"></div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Pool</label>
                                                    <select id="propertyPool" className={`form-control ${propertyErrors?.haspool && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, haspool: null }) }} v value={property?.haspool} name='haspool' onChange={handlePropertyChange}>
                                                        <option selected value=''>Select</option>
                                                        <option value='Yes'>Yes</option>
                                                        <option value='No'>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Lot SQ FT</label>
                                                    <input id="propertyLotSqft" type="text" value={property?.lotsqft} name='lotsqft' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.lotsqft && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, lotsqft: null }) }} v placeholder="Enter Lot SQ FT" />
                                                </div>
                                            </div>
                                            <div className="col-md-3" id="AboutTheDeal">
                                                <div className="form-group">
                                                    <label>Building SQ FT</label>
                                                    <input id="propertyBuildingSqft" type="text" value={property?.buildingsqft} name='buildingsqft' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.buildingsqft && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, buildingsqft: null }) }} placeholder="Enter Building SQ FT" />
                                                </div>
                                            </div>
                                            <div className="col-md-3" id="AboutTheDeal">
                                                <div className="form-group">
                                                    <label>Finished SQ FT</label>
                                                    <input id="propertyFinishedSqft" type="text" value={property?.finishedsqft} name='finishedsqft' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.finishedsqft && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, finishedsqft: null }) }} placeholder="Enter Finished SQ FT" />
                                                </div>
                                            </div>
                                            {/* <div className="col-md-3"></div> */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Hazard Insurance Number</label>
                                                    <input id="propertyHazardInsuranceNumber" type="text" onChange={handlePropertyChange} name='hazardinsurance' value={property?.hazardinsurance} className={`form-control ${propertyErrors.hazardinsurance && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, hazardinsurance: null }) }}
                                                        placeholder="Enter Hazard Insurance" />
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Hazard Insurance Active Date</label>
                                                    <input id="propertyHazardInsuranceActiveDate" type="date" onChange={handlePropertyChange} name='hazardinsuranceactivedate' value={property?.hazardinsuranceactivedate ? (new Date(property?.hazardinsuranceactivedate).toISOString().split('T')[0]) : ''} max={property.hazardinsuranceexpirydate || '9999-12-31'} className={`form-control ${propertyErrors.hazardinsuranceactivedate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, hazardinsuranceactivedate: null }) }} placeholder="mm-dd-yyy" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Hazard Insurance Exp Date</label>
                                                    <input id="propertyHazardInsuranceExpDate" type="date" onChange={handlePropertyChange} name='hazardinsuranceexpirydate' value={property?.hazardinsuranceexpirydate ? (new Date(property?.hazardinsuranceexpirydate).toISOString().split('T')[0]) : ''} min={property.hazardinsuranceactivedate || '1000-01-01'} className={`form-control ${propertyErrors.hazardinsuranceexpirydate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, hazardinsuranceexpirydate: null }) }} placeholder="mm-dd-yyy" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Hazard Annual Premium</label>
                                                    <CurrencyInput id="propertyHazardInsuranceAnnualPremium" name="hazardannualpremium"
                                                        //   defaultValue={1000} 
                                                        decimalsLimit={2}
                                                        className={`form-control ${propertyErrors.hazardannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, hazardannualpremium: null }) }} placeholder="$0.00"
                                                        prefix="$"
                                                        groupSeparator=","
                                                        value={property?.hazardannualpremium}
                                                        onValueChange={(value, name, values) => {
                                                            console.log(values)
                                                            handleCurrencyInputsProperty(name, value);
                                                        }}
                                                    />
                                                    {/* <input type="text" onChange={handlePropertyChange} name='hazardannualpremium' value={ property?.hazardannualpremium && '$'+property?.hazardannualpremium || ''} className={`form-control ${propertyErrors.hazardannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, hazardannualpremium: null }) }} placeholder="$0.00" /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Dwelling Coverage</label>
                                                    <CurrencyInput id="propertyDwellingCoverage" name="dwellingcoverage"
                                                        //   defaultValue={1000} 
                                                        decimalsLimit={2}
                                                        className={`form-control ${propertyErrors.dwellingcoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, dwellingcoverage: null }) }} placeholder="$0.00"
                                                        prefix="$"
                                                        groupSeparator=","
                                                        value={property?.dwellingcoverage}
                                                        onValueChange={(value, name, values) => {
                                                            console.log(values)
                                                            handleCurrencyInputsProperty(name, value);
                                                        }}
                                                    />
                                                    {/* <input type="text" onChange={handlePropertyChange} name='dwellingcoverage' value={property?.dwellingcoverage && '$'+property?.dwellingcoverage || ''} className={`form-control ${propertyErrors.dwellingcoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, dwellingcoverage: null }) }} placeholder="$0.00" /> */}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Vesting Status</label>
                                                    <select id="propertyVestingStatus" className={`form-control ${propertyErrors?.vestingstatus && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, vestingstatus: null }) }} v value={property?.vestingstatus} name='vestingstatus' onChange={handlePropertyChange}>
                                                        <option selected value=''>Select</option>
                                                        <option value='sole and separate property'>Sole And Separate Property</option>
                                                        <option value='community property'>Community Property</option>
                                                        <option value='community property with rights of survivorship'>Community Property With Rights Of Survivorship</option>
                                                        <option value='joint tenants'>Joint Tenants</option>
                                                        <option value='tenants in common'>Tenants In Common</option>
                                                        <option value='tenants by the entirety'>Tenants By The Entirety</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>APN/ Assessor’s Parcel Number</label>
                                                    <input id="propertyApnNumber" type="text" onChange={handlePropertyChange} name='apn' value={property?.apn} className={`form-control ${propertyErrors.apn && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, apn: null }) }} placeholder="Enter Assessor’s Parcel Number" />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Borrower As Is Value</label>
                                                    <CurrencyInput id="PropertyAsIsValue" name="currentvalue"
                                                        //   defaultValue={1000} 
                                                        decimalsLimit={2}
                                                        className={`form-control ${propertyErrors.currentvalue && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, currentvalue: null }) }} placeholder="Enter As Is Value"
                                                        prefix="$"
                                                        groupSeparator=","
                                                        value={property?.currentvalue}
                                                        onValueChange={(value, name, values) => {
                                                            console.log(values)
                                                            handleCurrencyInputsProperty(name, value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                            <div className="form-group">
                                            <label>Legal Description</label>
                                            <EditorEdit content={legalDescription} setContent={setLegalDescription}  name = "legaldescription" placeholder="Enter the message" hidePrint = {'no'}/>
              {/* <ReactQuill
                style={{ height: "225px" }}
                theme="snow"
                value={legalDescription}
                onChange={setLegalDescription}
                modules={{
                  // Override Quill's default behavior
                  clipboard: { matchVisual: false },
                  toolbar: {
                    container: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline", "strike"],
                      ["link", "image"],
                      ["blockquote", "code-block"],
                    ],
                  },
                }}
                // formats={["header"]}
                
                formats={["header", "bold", "italic", "underline", "strike", "link", "image", "blockquote", "code-block"]}
              /> */}
              {/* <MyEditor /> */}
            </div>
                                                {/* <div className="form-group">
                                                    <label>Legal Description</label>
                                                    <textarea id="w3review" rows="6" cols="10" name='legaldescription' value={property?.legaldescription || ''}
                                                        className="form-control" placeholder="Add Description" onChange={handlePropertyChange}
                                                        spellcheck="false"> </textarea>
                                                </div> */}
                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label></label>
                                                    <label className="toggle mb-0 mt-1">
                                                        <span className="toggle-label">Flood Insurance Policy</span>
                                                        <input id="propertyFloodInsurance" className="toggle-checkbox" type="checkbox" onChange={handlePropertyChange} checked={property?.floodinsurance} name="floodinsurance" />
                                                        <div className="toggle-switch"></div>
                                                    </label>
                                                </div>
                                            </div>


                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            {property?.floodinsurance && (
                                                <>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Flood Insurance Active Date</label>
                                                            <input id="propertyFloodInsuranceActiveDate" type="date" onChange={handlePropertyChange} name='floodinsuranceactivedate' value={property?.floodinsuranceactivedate ? (new Date(property?.floodinsuranceactivedate).toISOString().split('T')[0]) : ''} max={property.floodinsuranceexpirydate || '9999-12-31'} className={`form-control ${propertyErrors.floodinsuranceactivedate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsuranceactivedate: null }) }} placeholder="mm-dd-yyy" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Flood Insurance Exp Date</label>
                                                            <input id="propertyFloodInsuranceExpDate" type="date" onChange={handlePropertyChange} name='floodinsuranceexpirydate' value={property?.floodinsuranceexpirydate ? (new Date(property?.floodinsuranceexpirydate).toISOString().split('T')[0]) : ''} min={property.floodinsuranceactivedate || '1000-01-01'} className={`form-control ${propertyErrors.floodinsuranceexpirydate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsuranceexpirydate: null }) }} placeholder="mm-dd-yyy" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Flood insurance Annual Premium</label>
                                                            <CurrencyInput id="propertyFloodInsuranceAnnualPremium" name="floodinsuranceannualpremium"
                                                                //   defaultValue={1000} 
                                                                decimalsLimit={2}
                                                                className={`form-control ${propertyErrors.floodinsuranceannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsuranceannualpremium: null }) }} placeholder="$0.00"
                                                                prefix="$"
                                                                groupSeparator=","
                                                                value={property?.floodinsuranceannualpremium}
                                                                onValueChange={(value, name, values) => {
                                                                    console.log(values)
                                                                    handleCurrencyInputsProperty(name, value);
                                                                }}
                                                            />
                                                            {/* <input type="text" onChange={handlePropertyChange} name='floodinsuranceannualpremium' value={ property?.floodinsuranceannualpremium && '$'+property?.floodinsuranceannualpremium || ''} className={`form-control ${propertyErrors.floodinsuranceannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsuranceannualpremium: null }) }} placeholder="$0.00" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Flood insurance Coverage Amount</label>
                                                            <CurrencyInput id="propertyFloodInsuranceCoverageAmount" name="floodinsurancecoverage"
                                                                //   defaultValue={1000} 
                                                                decimalsLimit={2}
                                                                className={`form-control ${propertyErrors.floodinsurancecoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsurancecoverage: null }) }} placeholder="$0.00"
                                                                prefix="$"
                                                                groupSeparator=","
                                                                value={property?.floodinsurancecoverage}
                                                                onValueChange={(value, name, values) => {
                                                                    console.log(values)
                                                                    handleCurrencyInputsProperty(name, value);
                                                                }}
                                                            />
                                                            {/* <input type="text" onChange={handlePropertyChange} name='floodinsurancecoverage' value={property?.floodinsurancecoverage && '$'+property?.floodinsurancecoverage || ''} className={`form-control ${propertyErrors.floodinsurancecoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsurancecoverage: null }) }} placeholder="$0.00" /> */}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label></label>
                                                    <label className="toggle mb-0 mt-1">
                                                        <span className="toggle-label">General Liability Policy</span>
                                                        <input id="propertyGeneralLiabilityPolicy" className="toggle-checkbox" type="checkbox" onChange={handlePropertyChange} checked={property?.generalliability} name="generalliability" />
                                                        <div className="toggle-switch"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3"></div>
                                            {property?.generalliability && (
                                                <>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>General Liability Active Date</label>
                                                            <input id="propertyGeneralLiabilityActiveDate" type="date" onChange={handlePropertyChange} name='generalliabilityactivedate' value={property?.generalliabilityactivedate ? (new Date(property?.generalliabilityactivedate).toISOString().split('T')[0]) : ''} max={property.generalliabilityexpirydate || '9999-12-31'} className={`form-control ${propertyErrors.generalliabilityactivedate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilityactivedate: null }) }} placeholder="mm-dd-yyy" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>General Liability Exp Date</label>
                                                            <input id="propertyGeneralLiabilityExpDate" type="date" onChange={handlePropertyChange} name='generalliabilityexpirydate' value={property?.generalliabilityexpirydate ? (new Date(property?.generalliabilityexpirydate).toISOString().split('T')[0]) : ''} min={property.generalliabilityactivedate || '1000-01-01'} className={`form-control ${propertyErrors.generalliabilityexpirydate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilityexpirydate: null }) }} placeholder="mm-dd-yyy" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>General Liability Annual Premium</label>
                                                            <CurrencyInput id="propertyGeneralLiabilityAnnualPremium" name="generalliabilityannualpremium"
                                                                //   defaultValue={1000} 
                                                                decimalsLimit={2}
                                                                className={`form-control ${propertyErrors.generalliabilityannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilityannualpremium: null }) }} placeholder="$0.00"
                                                                prefix="$"
                                                                groupSeparator=","
                                                                value={property?.generalliabilityannualpremium}
                                                                onValueChange={(value, name, values) => {
                                                                    console.log(values)
                                                                    handleCurrencyInputsProperty(name, value);
                                                                }}
                                                            />
                                                            {/* <input type="text" onChange={handlePropertyChange} name='generalliabilityannualpremium' value={ property?.generalliabilityannualpremium && '$'+property?.generalliabilityannualpremium || ''} className={`form-control ${propertyErrors.generalliabilityannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilityannualpremium: null }) }} placeholder="$0.00" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>General Liability Coverage Amount</label>
                                                            <CurrencyInput id="propertyGeneralLiabilityCoverageAmount" name="generalliabilitycoverage"
                                                                //   defaultValue={1000} 
                                                                decimalsLimit={2}
                                                                className={`form-control ${propertyErrors.generalliabilitycoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilitycoverage: null }) }} placeholder="$0.00"
                                                                prefix="$"
                                                                groupSeparator=","
                                                                value={property?.generalliabilitycoverage}
                                                                onValueChange={(value, name, values) => {
                                                                    console.log(values)
                                                                    handleCurrencyInputsProperty(name, value);
                                                                }}
                                                            />
                                                            {/* <input type="text" onChange={handlePropertyChange} name='generalliabilitycoverage' value={property?.generalliabilitycoverage && '$'+property?.generalliabilitycoverage || ''} className={`form-control ${propertyErrors.generalliabilitycoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilitycoverage: null }) }} placeholder="$0.00" /> */}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {/* <div className="col-md-6"></div> */}
                                            
                                                <div className="col-md-12" style={{
                                                    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                                    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                                }}>
                                                    {propertyBtn === 'add' ? <div className="form-group">
                                                        <a id="saveProperty" href="#" className="fs_btn" onClick={(e) => { e.preventDefault(); handlePropertyAdd(); }}>save</a>
                                                    </div> : <div className="form-group">
                                                        <button id="updateProperty" className="fs_btn" disabled={!isPropertyChanges} style={{ 'opacity': isPropertyChanges ? 1 : 0.6 }} onClick={(e) => { e.preventDefault(); handleUpdateProperty(); }}>update</button>
                                                    </div>}
                                                </div>

                                        </div>

                                    </div>
                                </div>}
                                {viewPropertyForm &&
                                <div className="create_entity">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0 font-size-18 title">{propertyBtn === 'add' ? 'Create' : 'Update'} Property</h4>
                                    <a id="navigatePropertyBack" href="#" className="back_btn" onClick={(e) => { e.preventDefault(); handlePropertyBack(); }}><span className="material-icons icon"> chevron_left
                                    </span>BACK</a>
                                </div>
                                <div className="form_section">
                                    <div className="row" style={{
                                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                    }}>

                                        {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Property address</label>
                                            <input type="text" onChange={handlePropertyChange} name='propertyaddress' value={property?.propertyaddress} className={`form-control ${propertyErrors.propertyaddress && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyaddress: null }) }}
                                                placeholder="Enter Property address" />
                                        </div>
                                    </div> */}
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Property address</label>
                                                <div className={` ${propertyErrors.propertyaddress && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyaddress: null }) }}>
                                                    {mapsKey && <LocationSearch simpleAddress={true} name={'propertyaddress'} setAddressData={setPropertyAddress} addressData={propertyAddress} setSelectedPlace={setPropertyPlace} selectedPlace={property?.propertyaddress} className={`form-control ${propertyErrors.propertyaddress && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyaddress: null }) }} />}
                                                </div>
                                                {/* <LocationSearch name={'propertyaddress'} setAddressData={setPropertyAddress} addressData={propertyAddress} setSelectedPlace={setPropertyPlace} selectedPlace={property?.propertyaddress} className={`form-control ${propertyErrors.propertyaddress && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyaddress: null }) }}/> */}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Property Address 2</label>
                                                <input id="propertyaddress2" type="text" value={property?.propertyaddress2} name='propertyaddress2' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.propertyaddress2 && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyaddress2: null }) }} v placeholder="Enter Property Address 2" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>City</label>
                                                <input id="propertyCity" type="text" name='propertycity' placeholder='Enter City' value={property?.propertycity} onChange={handlePropertyChange} className={`form-control ${propertyErrors.propertycity && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertycity: null }) }} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>County</label>
                                                <input id="propertyCounty" type="text" name='propertycounty' placeholder='Enter County' value={property?.propertycounty} onChange={handlePropertyChange} className={`form-control ${propertyErrors.propertycounty && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertycounty: null }) }} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>State </label>
                                                <Select
                                                    inputId="propertyState"
                                                    options={stateOptions}
                                                    placeholder="Select"
                                                    value={property.propertystate ? stateOptions.find(option => option?.value === property.propertystate) : ''}
                                                    className={`w-100 ${propertyErrors.propertystate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertystate: null }) }}
                                                    onChange={(selectedOption) => {
                                                        setProperty({ ...property, propertystate: selectedOption.value });
                                                    }}
                                                    filterOption={customFilter}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3"></div> */}
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>ZIP Code</label>
                                                <input id="propertyZipcode" type="text" placeholder="Enter Zip Code" onInput={(e) => { e.target.value = e.target.value.slice(0, 6) }} value={property?.propertyzipcode} onChange={handlePropertyChange} name='propertyzipcode' className={`form-control ${propertyErrors.propertyzipcode && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, propertyzipcode: null }) }} />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Bedrooms</label>
                                                <input id="propertyBedrooms" type="text" value={property?.totalbedrooms} name='totalbedrooms' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.totalbedrooms && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, totalbedrooms: null }) }} v placeholder="Enter No Of Bedrooms" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Bathrooms</label>
                                                <input id="propertyBathrooms" type="text" value={property?.totalbathrooms} name='totalbathrooms' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.totalbathrooms && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, totalbathrooms: null }) }} v placeholder="Enter No Of Bathrooms" />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3"></div> */}
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Pool</label>
                                                <select id="propertyPool" className={`form-control ${propertyErrors?.haspool && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, haspool: null }) }} v value={property?.haspool} name='haspool' onChange={handlePropertyChange}>
                                                    <option selected value=''>Select</option>
                                                    <option value='Yes'>Yes</option>
                                                    <option value='No'>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Lot SQ FT</label>
                                                <input id="propertyLotSqft" type="text" value={property?.lotsqft} name='lotsqft' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.lotsqft && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, lotsqft: null }) }} v placeholder="Enter Lot SQ FT" />
                                            </div>
                                        </div>
                                        <div className="col-md-3" id="AboutTheDeal">
                                            <div className="form-group">
                                                <label>Building SQ FT</label>
                                                <input id="propertyBuildingSqft" type="text" value={property?.buildingsqft} name='buildingsqft' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.buildingsqft && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, buildingsqft: null }) }} placeholder="Enter Building SQ FT" />
                                            </div>
                                        </div>
                                        <div className="col-md-3" id="AboutTheDeal">
                                            <div className="form-group">
                                                <label>Finished SQ FT</label>
                                                <input id="propertyFinishedSqft" type="text" value={property?.finishedsqft} name='finishedsqft' onChange={handlePropertyChange} className={`form-control ${propertyErrors?.finishedsqft && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, finishedsqft: null }) }} placeholder="Enter Finished SQ FT" />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3"></div> */}
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Hazard Insurance Number</label>
                                                <input id="propertyHazardInsuranceNumber" type="text" onChange={handlePropertyChange} name='hazardinsurance' value={property?.hazardinsurance} className={`form-control ${propertyErrors.hazardinsurance && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, hazardinsurance: null }) }}
                                                    placeholder="Enter Hazard Insurance" />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Hazard Insurance Active Date</label>
                                                <input id="propertyHazardInsuranceActiveDate" type="date" onChange={handlePropertyChange} name='hazardinsuranceactivedate' value={property?.hazardinsuranceactivedate ? (new Date(property?.hazardinsuranceactivedate).toISOString().split('T')[0]) : ''} max={property.hazardinsuranceexpirydate || '9999-12-31'} className={`form-control ${propertyErrors.hazardinsuranceactivedate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, hazardinsuranceactivedate: null }) }} placeholder="mm-dd-yyy" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Hazard Insurance Exp Date</label>
                                                <input id="propertyHazardInsuranceExpDate" type="date" onChange={handlePropertyChange} name='hazardinsuranceexpirydate' value={property?.hazardinsuranceexpirydate ? (new Date(property?.hazardinsuranceexpirydate).toISOString().split('T')[0]) : ''} min={property.hazardinsuranceactivedate || '1000-01-01'} className={`form-control ${propertyErrors.hazardinsuranceexpirydate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, hazardinsuranceexpirydate: null }) }} placeholder="mm-dd-yyy" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Hazard Annual Premium</label>
                                                <CurrencyInput id="propertyHazardInsuranceAnnualPremium" name="hazardannualpremium"
                                                    //   defaultValue={1000} 
                                                    decimalsLimit={2}
                                                    className={`form-control ${propertyErrors.hazardannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, hazardannualpremium: null }) }} placeholder="$0.00"
                                                    prefix="$"
                                                    groupSeparator=","
                                                    value={property?.hazardannualpremium}
                                                    onValueChange={(value, name, values) => {
                                                        console.log(values)
                                                        handleCurrencyInputsProperty(name, value);
                                                    }}
                                                />
                                                {/* <input type="text" onChange={handlePropertyChange} name='hazardannualpremium' value={ property?.hazardannualpremium && '$'+property?.hazardannualpremium || ''} className={`form-control ${propertyErrors.hazardannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, hazardannualpremium: null }) }} placeholder="$0.00" /> */}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Dwelling Coverage</label>
                                                <CurrencyInput id="propertyDwellingCoverage" name="dwellingcoverage"
                                                    //   defaultValue={1000} 
                                                    decimalsLimit={2}
                                                    className={`form-control ${propertyErrors.dwellingcoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, dwellingcoverage: null }) }} placeholder="$0.00"
                                                    prefix="$"
                                                    groupSeparator=","
                                                    value={property?.dwellingcoverage}
                                                    onValueChange={(value, name, values) => {
                                                        console.log(values)
                                                        handleCurrencyInputsProperty(name, value);
                                                    }}
                                                />
                                                {/* <input type="text" onChange={handlePropertyChange} name='dwellingcoverage' value={property?.dwellingcoverage && '$'+property?.dwellingcoverage || ''} className={`form-control ${propertyErrors.dwellingcoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, dwellingcoverage: null }) }} placeholder="$0.00" /> */}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Vesting Status</label>
                                                <select id="propertyVestingStatus" className={`form-control ${propertyErrors?.vestingstatus && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, vestingstatus: null }) }} v value={property?.vestingstatus} name='vestingstatus' onChange={handlePropertyChange}>
                                                    <option selected value=''>Select</option>
                                                    <option value='sole and separate property'>Sole And Separate Property</option>
                                                    <option value='community property'>Community Property</option>
                                                    <option value='community property with rights of survivorship'>Community Property With Rights Of Survivorship</option>
                                                    <option value='joint tenants'>Joint Tenants</option>
                                                    <option value='tenants in common'>Tenants In Common</option>
                                                    <option value='tenants by the entirety'>Tenants By The Entirety</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>APN/ Assessor’s Parcel Number</label>
                                                <input id="propertyApnNumber" type="text" onChange={handlePropertyChange} name='apn' value={property?.apn} className={`form-control ${propertyErrors.apn && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, apn: null }) }} placeholder="Enter Assessor’s Parcel Number" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Borrower As Is Value</label>
                                                <CurrencyInput id="PropertyAsIsValue" name="currentvalue"
                                                    //   defaultValue={1000} 
                                                    decimalsLimit={2}
                                                    className={`form-control ${propertyErrors.currentvalue && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, currentvalue: null }) }} placeholder="Enter As Is Value"
                                                    prefix="$"
                                                    groupSeparator=","
                                                    value={property?.currentvalue}
                                                    onValueChange={(value, name, values) => {
                                                        console.log(values)
                                                        handleCurrencyInputsProperty(name, value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-9">

                                            <div className="form-group">
                                                <label>Legal Description</label>
                                                <EditorEdit content={legalDescription} setContent={setLegalDescription}  name = "legaldescription" placeholder="Enter the message" hidePrint = {'no'} ReadOnlyflag={true}/>
                                                {/* <textarea id="w3review" rows="6" cols="10" name='legaldescription' value={property?.legaldescription || ''}
                                                    className="form-control" placeholder="Add Description" onChange={handlePropertyChange}
                                                    spellcheck="false"> </textarea> */}
                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label></label>
                                                <label className="toggle mb-0 mt-1">
                                                    <span className="toggle-label">Flood Insurance Policy</span>
                                                    <input id="propertyFloodInsurance" className="toggle-checkbox" type="checkbox" onChange={handlePropertyChange} checked={property?.floodinsurance} name="floodinsurance" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>


                                        <div className="col-md-3"></div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3"></div>
                                        {property?.floodinsurance && (
                                            <>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Flood Insurance Active Date</label>
                                                        <input id="propertyFloodInsuranceActiveDate" type="date" onChange={handlePropertyChange} name='floodinsuranceactivedate' value={property?.floodinsuranceactivedate ? (new Date(property?.floodinsuranceactivedate).toISOString().split('T')[0]) : ''} max={property.floodinsuranceexpirydate || '9999-12-31'} className={`form-control ${propertyErrors.floodinsuranceactivedate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsuranceactivedate: null }) }} placeholder="mm-dd-yyy" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Flood Insurance Exp Date</label>
                                                        <input id="propertyFloodInsuranceExpDate" type="date" onChange={handlePropertyChange} name='floodinsuranceexpirydate' value={property?.floodinsuranceexpirydate ? (new Date(property?.floodinsuranceexpirydate).toISOString().split('T')[0]) : ''} min={property.floodinsuranceactivedate || '1000-01-01'} className={`form-control ${propertyErrors.floodinsuranceexpirydate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsuranceexpirydate: null }) }} placeholder="mm-dd-yyy" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Flood insurance Annual Premium</label>
                                                        <CurrencyInput id="propertyFloodInsuranceAnnualPremium" name="floodinsuranceannualpremium"
                                                            //   defaultValue={1000} 
                                                            decimalsLimit={2}
                                                            className={`form-control ${propertyErrors.floodinsuranceannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsuranceannualpremium: null }) }} placeholder="$0.00"
                                                            prefix="$"
                                                            groupSeparator=","
                                                            value={property?.floodinsuranceannualpremium}
                                                            onValueChange={(value, name, values) => {
                                                                console.log(values)
                                                                handleCurrencyInputsProperty(name, value);
                                                            }}
                                                        />
                                                        {/* <input type="text" onChange={handlePropertyChange} name='floodinsuranceannualpremium' value={ property?.floodinsuranceannualpremium && '$'+property?.floodinsuranceannualpremium || ''} className={`form-control ${propertyErrors.floodinsuranceannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsuranceannualpremium: null }) }} placeholder="$0.00" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Flood insurance Coverage Amount</label>
                                                        <CurrencyInput id="propertyFloodInsuranceCoverageAmount" name="floodinsurancecoverage"
                                                            //   defaultValue={1000} 
                                                            decimalsLimit={2}
                                                            className={`form-control ${propertyErrors.floodinsurancecoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsurancecoverage: null }) }} placeholder="$0.00"
                                                            prefix="$"
                                                            groupSeparator=","
                                                            value={property?.floodinsurancecoverage}
                                                            onValueChange={(value, name, values) => {
                                                                console.log(values)
                                                                handleCurrencyInputsProperty(name, value);
                                                            }}
                                                        />
                                                        {/* <input type="text" onChange={handlePropertyChange} name='floodinsurancecoverage' value={property?.floodinsurancecoverage && '$'+property?.floodinsurancecoverage || ''} className={`form-control ${propertyErrors.floodinsurancecoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, floodinsurancecoverage: null }) }} placeholder="$0.00" /> */}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label></label>
                                                <label className="toggle mb-0 mt-1">
                                                    <span className="toggle-label">General Liability Policy</span>
                                                    <input id="propertyGeneralLiabilityPolicy" className="toggle-checkbox" type="checkbox" onChange={handlePropertyChange} checked={property?.generalliability} name="generalliability" />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3"></div>
                                        {property?.generalliability && (
                                            <>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>General Liability Active Date</label>
                                                        <input id="propertyGeneralLiabilityActiveDate" type="date" onChange={handlePropertyChange} name='generalliabilityactivedate' value={property?.generalliabilityactivedate ? (new Date(property?.generalliabilityactivedate).toISOString().split('T')[0]) : ''} max={property.generalliabilityexpirydate || '9999-12-31'} className={`form-control ${propertyErrors.generalliabilityactivedate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilityactivedate: null }) }} placeholder="mm-dd-yyy" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>General Liability Exp Date</label>
                                                        <input id="propertyGeneralLiabilityExpDate" type="date" onChange={handlePropertyChange} name='generalliabilityexpirydate' value={property?.generalliabilityexpirydate ? (new Date(property?.generalliabilityexpirydate).toISOString().split('T')[0]) : ''} min={property.generalliabilityactivedate || '1000-01-01'} className={`form-control ${propertyErrors.generalliabilityexpirydate && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilityexpirydate: null }) }} placeholder="mm-dd-yyy" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>General Liability Annual Premium</label>
                                                        <CurrencyInput id="propertyGeneralLiabilityAnnualPremium" name="generalliabilityannualpremium"
                                                            //   defaultValue={1000} 
                                                            decimalsLimit={2}
                                                            className={`form-control ${propertyErrors.generalliabilityannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilityannualpremium: null }) }} placeholder="$0.00"
                                                            prefix="$"
                                                            groupSeparator=","
                                                            value={property?.generalliabilityannualpremium}
                                                            onValueChange={(value, name, values) => {
                                                                console.log(values)
                                                                handleCurrencyInputsProperty(name, value);
                                                            }}
                                                        />
                                                        {/* <input type="text" onChange={handlePropertyChange} name='generalliabilityannualpremium' value={ property?.generalliabilityannualpremium && '$'+property?.generalliabilityannualpremium || ''} className={`form-control ${propertyErrors.generalliabilityannualpremium && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilityannualpremium: null }) }} placeholder="$0.00" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>General Liability Coverage Amount</label>
                                                        <CurrencyInput id="propertyGeneralLiabilityCoverageAmount" name="generalliabilitycoverage"
                                                            //   defaultValue={1000} 
                                                            decimalsLimit={2}
                                                            className={`form-control ${propertyErrors.generalliabilitycoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilitycoverage: null }) }} placeholder="$0.00"
                                                            prefix="$"
                                                            groupSeparator=","
                                                            value={property?.generalliabilitycoverage}
                                                            onValueChange={(value, name, values) => {
                                                                console.log(values)
                                                                handleCurrencyInputsProperty(name, value);
                                                            }}
                                                        />
                                                        {/* <input type="text" onChange={handlePropertyChange} name='generalliabilitycoverage' value={property?.generalliabilitycoverage && '$'+property?.generalliabilitycoverage || ''} className={`form-control ${propertyErrors.generalliabilitycoverage && 'invalid-input'}`} onFocus={() => { setPropertyErrors({ ...propertyErrors, generalliabilitycoverage: null }) }} placeholder="$0.00" /> */}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>

                                </div>
                            </div>
                                }

                            </div>
                            <div className="tab-pane" id="LOAN" role="tabpanel" style={{
                                pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                            }}>

                                <div className="form_section">
                                    <div className="main_details more_details">
                                        <div className="detail_block">
                                            <p>Loan Type</p>
                                            <h4>{LoanDataData?.loantype || 'N/A'}</h4>
                                        </div>
                                        <div className="detail_block">
                                            <p>Program Type</p>
                                            <h4>{LoanDataData?.programtype || 'N/A'}</h4>
                                        </div>
                                        <div className="detail_block">
                                            <p>Loan Amount</p>
                                            <h4>{formatCurrency(LoanDataData?.loanamount) || 'N/A'}</h4>
                                        </div>
                                        <div className="detail_block">
                                            <p>Purchase Price</p>
                                            <h4>{formatCurrency(LoanDataData?.purchaseprice) || 'N/A'}</h4>
                                        </div>
                                    </div>
                                    <div className="main_details more_details">
                                        <div className="detail_block">
                                            <p>Interest Rate(%)</p>
                                            <h4>{LoanDataData?.interestrate || 'N/A'}</h4>
                                        </div>
                                        <div className="detail_block">
                                            <p>Loan Term</p>
                                            <h4>{LoanDataData?.loanterm ? LoanDataData?.loanterm + ' months' : 'N/A'}</h4>
                                        </div>
                                        <div className="detail_block">
                                            <p>Requested Closing Date</p>
                                            <h4> {LoanDataData?.constructioncompleteddate ? moment.utc(LoanDataData?.constructioncompleteddate).format("MM-DD-YYYY") : 'N/A'} </h4>
                                        </div>
                                        <div className="detail_block">
                                            <p>Hold-back Amount</p>
                                            <h4>{formatCurrency(LoanDataData?.rehabfunding) || 'N/A'}</h4>
                                        </div>
                                    </div>
                                    <div className="main_details more_details">
                                        <div className="detail_block">
                                            <p>Draw Interest Type</p>
                                            <h4>{LoanDataData?.drawinteresttype || 'N/A'}</h4>
                                        </div>
                                        <div className="detail_block">
                                            <p>Interest Reserves</p>
                                            <h4>{LoanDataData?.interestreserves || 'N/A'}</h4>
                                        </div>
                                        <div className="detail_block">
                                            <p>Interest Reserves Amount</p>
                                            <h4>{formatCurrency(LoanDataData?.interestreserveamount) || 0}</h4>
                                        </div>
                                        <div className="detail_block" >
                                            <p>Maturity Date</p>
                                            <h4>{LoanDataData?.cleartoclose?.declaredmaturitydate ? moment.utc(LoanDataData?.cleartoclose?.declaredmaturitydate).format("MM-DD-YYYY") : 'N/A'}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="loan">
                                    <div className="form_section">
                                        <div className="row">
                                            {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Loan Purpose</label>
                                                <select id="loanPurpose" disabled onChange={handleLoanChange} value={LoanDataData?.loanpurpose} name="loanpurpose" className={`form-control ${loanDealErrors.loanpurpose && 'invalid-input'}`} onFocus={() => { setLoanDealErrors({ ...loanDealErrors, loanpurpose: null }) }}>
                                                <option value={''} selected>Please Select</option>
                                                {loanPurposeType?.map((data) => {
                                                            return (
                                                                <option key={data?.option} value={data?.option}>{data?.option}</option>
                                                            );
                                                        })}
                                                  
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Program Type</label>
                                                <select  id="loanProgramType" disabled onChange={handleLoanChange} value={LoanDataData?.programtype} name="programtype" className={`form-control ${loanDealErrors.programtype && 'invalid-input'}`} onFocus={() => { setLoanDealErrors({ ...loanDealErrors, programtype: null }) }}>
                                                    <option value="" selected>Please Select</option>
                                                    <option value="EASY FIX" >Easy Fix</option>
                                                    <option value="EASY BUILD" >Easy Build</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Loan Amount</label>
                                                <CurrencyInput 
                                                id="loanAmount" 
                                                name="loanamount"
                                                disabled
                                                //   defaultValue={1000} 
                                                decimalsLimit={2}
                                                className={`form-control ${loanDealErrors.loanamount && 'invalid-input'}`} onFocus={() => { setLoanDealErrors({ ...loanDealErrors, loanamount: null }) }} placeholder="$0.00"
                                                prefix="$"
                                                groupSeparator="," 
                                                value={LoanDataData?.loanamount}
                                                onValueChange={(value, name, values) => {
                                                    console.log(values)
                                                    handleCurrencyInputsLoan(name,value);
                                                }}
                                                readOnly={true}
                                                />
                                             </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Purchase Price</label>
                                                <CurrencyInput 
                                                id="loanPurchasePrice" 
                                                name="purchaseprice"
                                                disabled
                                                //   defaultValue={1000} 
                                                decimalsLimit={2}
                                                className={`form-control ${loanDealErrors.purchaseprice && 'invalid-input'}`} onFocus={() => { setLoanDealErrors({ ...loanDealErrors, purchaseprice: null }) }} placeholder="$0.00"
                                                prefix="$"
                                                groupSeparator="," 
                                                value={LoanDataData?.purchaseprice}
                                                onValueChange={(value, name, values) => {
                                                    console.log(values)
                                                    handleCurrencyInputsLoan(name,value);
                                                }}
                                                />
                                            </div>
                                        </div> */}

                                            {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Interest Rate(%)</label>
                                                <input id="loanInterestRate" disabled type="number" className={`form-control ${loanDealErrors?.interestrate && 'invalid-input'}`} onFocus={() => { setLoanDealErrors({ ...loanDealErrors, interestrate: null }) }} onChange={handleLoanChange} value={LoanDataData?.interestrate} placeholder="0" name="interestrate" />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Loan Term</label>
                                                <select id="loanTerm" disabled className={`form-control ${loanDealErrors?.loanterm && 'invalid-input'}`} onFocus={() => { setLoanDealErrors({ ...loanDealErrors, loanterm: null }) }} onChange={handleLoanChange} value={LoanDataData?.loanterm} name="loanterm">
                                                    <option value={''} selected>Please Select</option>
                                                    {loanTerm?.map((data) => {
                                                       const numericValue = parseInt(data?.option); 
                                                            return (
                                                                <option key={numericValue} value={numericValue}>{data?.option}</option>
                                                            );
                                                        })}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Requested Closing Date</label>
                                                <input id="loanClosingDate" type="date" style={{ cursor: 'default' }}  disabled className={`form-control ${loanDealErrors?.constructioncompleteddate && 'invalid-input'}`} onFocus={() => { setLoanDealErrors({ ...loanDealErrors, constructioncompleteddate: null }) }} placeholder="mm-dd-yyyy" value={LoanDataData?.constructioncompleteddate ? (new Date(LoanDataData?.constructioncompleteddate).toISOString().split('T')[0]) : ''} onChange={handleLoanChange} name="constructioncompleteddate" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Hold-back Amount</label>
                                                <CurrencyInput 
                                                readOnly={true}
                                                id="loanHoldBackAmount" 
                                                name="holdbackamount"
                                                disabled
                                                //   defaultValue={1000} 
                                                decimalsLimit={2}
                                                className={`form-control ${loanDealErrors.holdbackamount && 'invalid-input'}`} onFocus={() => { setLoanDealErrors({ ...loanDealErrors, holdbackamount: null }) }} placeholder="$0.00"
                                                prefix="$"
                                                groupSeparator="," 
                                                value={LoanDataData?.rehabfunding}
                                                onValueChange={(value, name, values) => {
                                                    console.log(values)
                                                    handleCurrencyInputsLoan(name,value);
                                                }}
                                                />
                                            </div>
                                        </div> */}

                                            {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Draw Interest Type</label>
                                                <select id="loanDrawInterestType" disabled className={`form-control ${loanDealErrors?.drawinteresttype && 'invalid-input'}`} onFocus={() => { setLoanDealErrors({ ...loanDealErrors, drawinteresttype: null }) }} onChange={handleLoanChange} value={LoanDataData?.drawinteresttype} name="drawinteresttype">
                                                    <option value="" selected>Please Select</option>
                                                  
                                                    <option value="Interest as Disbursed">Interest as Disbursed</option>
                                                    <option selected="selected" value="Interest on Full Balance">Interest on Full Balance</option>

                                                </select>
                                            </div>
                                        </div> */}

                                            {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Categories for loan</label>
                                                <Select
                                                options={categoriesDropdown}
                                                isMulti
                                                value={LoanDataData?.categoriesforloan?.map((value) => ({
                                                    value,
                                                      label: value
                                                      })) || []}
                                                className={`w-100 ${loanDealErrors?.categoriesforloan && 'invalid-input'}`} onFocus={() => { setLoanDealErrors({ ...loanDealErrors, categoriesforloan: null }) }}
                                                      onChange={(selectedOptions) => {
                                                        setLoanDataData({
                                                            ...LoanDataData,
                                                            categoriesforloan: selectedOptions.map(option => option.value)
                                                        });
                                                    }}
                                            />
                                            </div>
                                        </div> */}

                                            {/* <div className="col-md-12">
                                            <div className="form-group">
                                            <button id="saveLoanDeal" className="fs_btn" disabled={!isLoanChange} style={{'opacity' : isLoanChange ? 1 : 0.6}}  onClick={(e) => {e.preventDefault(); handleLoanAdd();}}>save</button>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <PipelineFooter />

        </div>
    );
};

export default DealDetailedApp;