import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import { useLoanInfo } from '../utils/loanInfoContext';
import PipelineHeader from './PipelineHeader';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import JSZip from 'jszip';
import { Draggable } from "react-drag-reorder";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Appname, APIUrl, submitNew } from '../utils/reducer';
import { PDFDocument, PDFPage } from 'pdf-lib';

import { useDispatch, useSelector } from 'react-redux';
import { getAllDealFilesApi, getLoanGroupNames, addGroupNameApi, deleteDealFileApi, updatedDealFileApi, addLoanDocumentApi } from '../utils/reducer';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import Modal from 'react-modal';
import Select from 'react-select';
import PopupMessage from './PopupMessage';
import FileViewer from './DocViewer';
import Loader from './Loader';
import { hideLoader, showLoader } from '../redux/reducers/Actions';
import Creatable from 'react-select/creatable';
import { useLocation } from "react-router-dom";
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';

const DealFiles = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isDealFiles = location.pathname.includes("underwritingfiles");
    // 
    const dispatch = useDispatch();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const configdata = useSelector(state => state.configdata);
    const [type, setType] = useState(false);
    const [playContent, setPlayContent] = useState({});
    const [fileDocName, setFileDocName] = useState("");
    const loading = useSelector((state) => state.loaderVisible);

    const [allDealFilesData, setAllDealFilesData] = useState([]);
    const [groupNamesList, setGroupNamesList] = useState([]);
    const [activeToggle, setActiveToggle] = useState("");
    const [formvalues, setFormvalues] = useState({});
    const { loanInfo } = useLoanInfo();
    const [unique_id, setunique_id] = useState(uuidv4());
    const [errors, setErrors] = useState({});
    const [documentName, setDocumentName] = useState("");
    const [docNameErr, setdocNameErr] = useState("");
    const [groupName, setGroupName] = useState('');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [groupPopup, setGroupPopup] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [activeItem, setActiveItem] = useState({});
    const [showDoc, setshowDoc] = useState(false);
    const [multiFormValues, setMultiFormValues] = useState([]);

    const [activeDeleteFlag, setActiveDeleteFlag] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);

    const [unselectedColumns, setUnselectedColumns] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    let userData = getUserDataFromLocalStorage();
    // console.log("userData",userData)
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );


    

    const [groupDropDownOptions, setgroupDropDownOptions] = useState([
        { value: 'Loan Officer', label: 'Loan Officer' },
        { value: 'Processor', label: 'Processor' },
        { value: 'Underwriter', label: 'Underwriter' },
        { value: 'UW Int', label: 'UW Int' },
        { value: 'Funding', label: 'Funding' },
        { value: 'Closing', label: 'Closing' },
        { value: 'Investor', label: 'Investor' },
        { value: 'Title Company', label: 'Title Company' },

    ]);
 
    const [defaultOptions, setDefaultOptions] = useState([
        { value: 'Bank statement', label: 'Bank statement' },
        { value: 'Borrower experience track record form', label: 'Borrower experience track record form' },
        { value: 'Credit authorization format', label: 'Credit authorization format' },
        { value: 'Executed contract', label: 'Executed contract' },
        { value: 'Item construction budget', label: 'Item construction budget' },
        { value: 'Photo id', label: 'Photo id' },
        { value: 'Signed termsheet', label: 'Signed termsheet' },
        { value: 'Ach', label: 'Ach' },
        { value: 'Form W 9', label: 'Form W 9' },

    ]);

    // console.log('selectedColumns',selectedColumns)
    useEffect(() => {
        if (loanInfo?.loanid) {
            getDealFiles();
            setActiveToggle("list")
            getloanGroupList();
        }
    }, [loanInfo?.loanid]);

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.pipelinePermissions
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

    const getDealFiles = () => {
        setDocumentName("")
        setUnselectedColumns([]);
        // console.log('loaniiddddd',loanInfo?.loanid)
        dispatch(showLoader());
        getAllDealFilesApi(loanInfo?.loanid)
            .then(function (response) {
                if (response) {
                    // console.log('response', response);
                    // setAllDealFilesData((prev) => ({
                    //     ...prev,
                    //     ...response.result,
                    // }));
                    let data = response?.result
                    let filterdata = data?.filter((item => item.status === "ACTIVE"))
                    setAllDealFilesData(filterdata)
                    const filterdata2 = data?.filter((item => item.fileextension === "pdf" && item.status === "ACTIVE"))
                    filterdata2.forEach(obj => {
                        // Add the 'label' key with the value from the 'filename' key
                        obj['label'] = obj['filename'];
                        // Add the 'checked' key with the desired state value
                        obj['checked'] = false;
                    });


                    setSelectedColumns(filterdata2)
                    dispatch(hideLoader());
                }
            })
            .catch((err) => {
                // console.error("error", err);
                dispatch(hideLoader());
            });
    };
    const handleGroupToggle = () => {
        setGroupPopup(!groupPopup)
        setGroupName("")
        setErrors({ ...errors, groupname: null })
    }
    // const [mergedPDFUrl, setMergedPDFUrl] = useState('');

    const mergePDFs = async () => {

        let newErrors = "";
        if (typeof documentName === 'string' && documentName.trim() === "") {
            newErrors = `Enter a document name`;
        }
        setdocNameErr(newErrors);
        if (newErrors.length === 0) {
            dispatch(showLoader());
            const mergedPdf = await PDFDocument.create();

            for (const pdfFile of unselectedColumns) {
                let source = configdata?.common?.resourcesCloudfront + pdfFile.filepath;
                const pdfBytes = await fetch(source).then(res => res.arrayBuffer());
                const pdfDoc = await PDFDocument.load(pdfBytes);
                const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                copiedPages.forEach((page) => mergedPdf.addPage(page));
            }

            const mergedPdfBytes = await mergedPdf.save();
            const mergedPdfBlob = new Blob([mergedPdfBytes], {
                type: 'application/pdf', lastModified: Date.now(),
                lastModifiedDate: new Date(),
                name: `${documentName}.zip`,
                size: mergedPdfBytes.size
            });

            // setMergedPDFUrl(mergedPdfBlob);
            console.log('mergedPdfBlob', mergedPdfBlob)
            handleFileChange2(mergedPdfBlob, "merge");
        }
    };
    // console.log('unselectedColumns', unselectedColumns);
    const handleSaveZip = async () => {


        let newErrors = "";
        if (typeof documentName === 'string' && documentName.trim() === "") {
            newErrors = `Enter a document name`;
        }
        setdocNameErr(newErrors);
        if (newErrors.length === 0) {

            console.log('file pathssss', unselectedColumns)
            const zip = new JSZip();
            const promises = [];


            unselectedColumns.forEach(file => {
                let source;
                let type = file.filetype.split("/")
                if (type[0] === "image") {
                    source = configdata?.common?.awsImgix + file.filepath;
                } else {
                    source = configdata?.common?.resourcesCloudfront + file.filepath;
                }
                console.log('source', source)
                const promise = fetch(source)
                    .then(response => response.blob())
                    .then(blob => {
                        zip.file(file.filename, blob);
                    })
                    .catch(error => console.error("Error adding file to zip:", error));
                promises.push(promise);
            });

            Promise.all(promises).then(() => {
                zip.generateAsync({ type: 'blob' }).then(content => {
                    const zipBlob = new Blob([content], {
                        type: 'application/zip',
                        lastModified: Date.now(),
                        lastModifiedDate: new Date(),
                        name: `${documentName}.zip`,
                        size: content.size
                    });
                    console.log('zippppppppblobbb', zipBlob);
                    handleFileChange2(zipBlob, "merge"); // Sending zip blob to handleFileChange function
                });
            });
        }
    };
    const handleDownloadClick = async () => {
        const downloadList = allDealFilesData.filter(item => multiFormValues.includes(item.documentid));

        console.log('file pathssss', downloadList)
        if (downloadList.length === 0) {
            alert("No files selected!");
            return;
        }

        const zip = new JSZip();
        const promises = [];


        downloadList.forEach(file => {
            let source;
            let type = file.filetype.split("/")
            if (type[0] === "image") {
                source = configdata?.common?.awsImgix + file.filepath;
            } else {
                source = configdata?.common?.resourcesCloudfront + file.filepath;
            }
            console.log('source', source)
            let fileNameWithExtension = `${file?.filename}.${file?.fileextension}`
            const promise = fetch(source)
                .then(response => response.blob())
                .then(blob => {
                    zip.file(fileNameWithExtension, blob);
                })
                .catch(error => console.error("Error adding file to zip:", error));
            promises.push(promise);
        });

        Promise.all(promises).then(() => {
            zip.generateAsync({ type: 'blob' }).then(content => {
                const zipFile = window.URL.createObjectURL(content);
                console.log('zip file', content)
                const link = document.createElement('a');
                link.href = zipFile;
                link.download = 'merged_files.zip';
                link.click();
                window.URL.revokeObjectURL(zipFile);
            });
        });
    };

    const getloanGroupList = () => {
        dispatch(showLoader());
        getLoanGroupNames(loanInfo?.loanid)
            .then(function (response) {
                if (response) {

                    setGroupNamesList(response?.result?.[0]?.group)
                    dispatch(hideLoader());
                }
            })
            .catch((err) => {
                // console.error("error", err);
                dispatch(hideLoader());
            });
    };
    // console.log('formvalues',formvalues);
    const handleChange = (e,param="") => {
        if(param === "tag"){
           console.log(e,'eeeeee')
            setFormvalues((prevValues) => ({
                ...prevValues,
                ['type']: e ? e.value : '',
            }));
        }else{

        
        const { name, value } = e.target;

        let formdata = { ...formvalues }
        if (name === 'approved') {
            setFormvalues({ ...formdata, [name]: e.target.checked })
        }else {
            setFormvalues({ ...formdata, [name]: value })
        }
    }
    }

    const handleChangeCheckItem = (e, item) => {
        if (e.target.checked === true) {

            setMultiFormValues(prevArray => [...prevArray, item.documentid]);

        } else {

            let resultArray = multiFormValues.filter((documentid) => documentid !== item.documentid)
            setMultiFormValues(resultArray)
        }
    }

    const DraggableRendermain = ({ columns, onPosChange, type }) => {
        return (
            <Draggable onPosChange={onPosChange}>
                {columns.map((item, index) => (
                    <li key={index}>
                        <label className="check_box">
                            {item.label}
                            <input id={`draggableFile-${index}`} type="checkbox" checked={item.checked} onChange={() => handleCheckboxToggle(index, type)} /> {/* Assuming item.columnType identifies the type */}
                            <span className="checkmark"></span>
                        </label>
                    </li>
                ))}
            </Draggable>
        );
    };


    const handleMoveToSelected = () => {
        const checkedItems = unselectedColumns.filter(item => item.checked);
        if (selectedColumns.length + checkedItems.length > 20) {
            alert("You can't select more than 20 columns.");
            return;
        }
        setUnselectedColumns(prevUnselected => prevUnselected.filter(item => !item.checked));
        setSelectedColumns(prevSelected => [...prevSelected, ...checkedItems.map(item => ({ ...item, checked: false }))]);
    };

    const handleMoveToUnselected = () => {
        const checkedItems = selectedColumns.filter(item => item.checked);
        setSelectedColumns(prevSelected => prevSelected.filter(item => !item.checked));
        setUnselectedColumns(prevUnselected => [...prevUnselected, ...checkedItems.map(item => ({ ...item, checked: false }))]);
    };

    const handleCheckboxToggle = (index, listType) => {
        if (listType === 'selected') {
            const checkedCount = selectedColumns.filter(item => item.checked).length;
            if (!selectedColumns[index].checked && checkedCount >= 20) {
                return; // Limit reached, do not allow more selections
            }
            setSelectedColumns(prevSelected =>
                prevSelected.map((item, idx) => idx === index ? { ...item, checked: !item.checked } : item)
            );
        } else if (listType === 'unselected') {
            setUnselectedColumns(prevUnselected =>
                prevUnselected.map((item, idx) => idx === index ? { ...item, checked: !item.checked } : item)
            );
        }
    };

    const handleSelectAll = (listType) => {
        if (listType === 'selected') {
            const allSelected = selectedColumns.every(item => item.checked);
            const updatedSelected = selectedColumns.map(item => ({ ...item, checked: !allSelected }));
            setSelectedColumns(updatedSelected);
        } else if (listType === 'unselected') {
            const allSelected = unselectedColumns.every(item => item.checked);
            const updatedUnselected = unselectedColumns.map(item => ({ ...item, checked: !allSelected }));
            setUnselectedColumns(updatedUnselected);
        }
    };

    const selectedCount = selectedColumns.filter(item => item.checked).length;
    const unselectedCount = unselectedColumns.filter(item => item.checked).length;
    const getChangedPos = (currentPos, newPos) => {
        setSelectedColumns(prevColumns => {
            const reorderedColumns = Array.from(prevColumns);
            const [removed] = reorderedColumns.splice(currentPos, 1);
            reorderedColumns.splice(newPos, 0, removed);
            return reorderedColumns;
        });
    };
    const getChangedPosunSelected = (currentPos, newPos) => {
        setUnselectedColumns(prevColumns => {
            const reorderedColumns = Array.from(prevColumns);
            const [removed] = reorderedColumns.splice(currentPos, 1);
            reorderedColumns.splice(newPos, 0, removed);
            return reorderedColumns;
        });
    };


    useEffect(() => {
        console.log("selectedColumns", selectedColumns);

    }, [selectedColumns]);

    const handleFileChange2 = (zipBlob, key) => {
       
        const timestamp = Date.now();
        const bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
        var ObjectID2 = require("bson-objectid");

        const uniqueId = new ObjectID2().toString();
        const uploadFilePath = Appname + "/dealfiles/" + uniqueId + "/" + timestamp + ".pdf";

        const data = { source_bucket: bucket, sourcepath: uploadFilePath };
        var ObjectID = require("bson-objectid");
        var fileid = new ObjectID().toString();
        axios.post(APIUrl + 'uploadURL?appname=' + Appname, data, { type: 'application/json' })
            .then((response) => {
                if (response?.data && response?.data?.result) {
                    const url = response?.data?.result;

                    axios.put(url, zipBlob, {
                        "headers": {
                            "Content-Type": "multipart/form-data",
                            "Accept": "/",
                            "Cache-Control": "no-cache",
                            "Accept-Encoding": "gzip, deflate",
                            "Connection": "keep-alive",
                            "cache-control": "no-cache"
                        }
                    })
                        .then((response) => {
                            if (response && response?.status === 200) {
                                const newDocument = {
                                    filename: `${documentName}.pdf`,
                                    filepath: uploadFilePath,
                                    documentid: fileid,
                                    fileextension: "pdf",
                                    filetype: 'pdf',
                                    filedescription: '',
                                    type:"",
                                    loanid: loanInfo?.loanid,
                                };

                                dispatch(showLoader());
                                addLoanDocumentApi(newDocument)
                                    .then(function (response) {
                                        getDealFiles();
                                        setPopupOpen('fileadded');
                                        setMultiFormValues([]);
                                        setUnselectedColumns([])
                                        setModalIsOpen(false);
                                        dispatch(hideLoader());
                                        setDocumentName("")
                                    })
                                    .catch((err) => {
                                        dispatch(hideLoader());
                                    });
                            }
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                            dispatch(hideLoader());
                        });
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                dispatch(hideLoader());
            });
    };
    const handleSelectAllItems = (e) => {
        if (e.target.checked === true) {

            const documentids = allDealFilesData.map(obj => obj.documentid);
            setMultiFormValues(documentids)
        } else {
            setMultiFormValues([])
        }

    }

    const handleFileChange = (e, key) => {
        // Handle file selection here
        e.preventDefault();
        dispatch(showLoader())
        let file;
        if (key === "drag") {
            file = e.dataTransfer.files[0];
        } else {
            file = e.target.files[0];
        }

        var bucket;
        console.log('file', file)
        const timestamp = Date.now();
        let format = file?.name?.split(".");
        var fileformat = format[format.length - 1]
        bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
        // console.log('filefilefilebucket',bucket,);
        let category = e.target.name;
        // console.log('datadacategory',category);
        var ObjectID2 = require("bson-objectid");
        var uniqueId = new ObjectID2().toString();
        let uploadFilePath = Appname + "/dealfiles/" + uniqueId + "/" + timestamp + "." + fileformat;
        // console.log('datadauploadFilePath',uploadFilePath);
        var data = { source_bucket: bucket, sourcepath: uploadFilePath }
        // console.log('datadatadata',data);
        // var ObjectID = require("bson-objectid");
        // var fileid = new ObjectID().toString(); 
        axios.post(APIUrl + 'uploadURL?appname=' + Appname, data, { type: 'application/json' })
            .then((response) => {
                // console.log('ressss',response);
                if (response?.data && response?.data?.result) {
                    // console.log('ressss2',response.data);
                    var url = response?.data?.result;
                    // console.log('ressss3',response.data.result);

                    // console.log("url", url);
                    axios.put(url, file, {
                        "headers": {
                            "Content-Type": "multipart/form-data",
                            "Accept": "/",
                            "Cache-Control": "no-cache",
                            "Accept-Encoding": "gzip, deflate",
                            "Connection": "keep-alive",
                            "cache-control": "no-cache"
                        }
                    })
                        .then((response) => {
                            if (response && response?.status === 200) {
                                const newDocument = {
                                    filename: format[0],
                                    filepath: uploadFilePath,
                                    documentid: uniqueId,
                                    fileextension: fileformat,
                                    filetype: file.type,
                                    filedescription: '',
                                    type:selectedOption.value,
                                    loanid: loanInfo?.loanid,
                                }
                                dispatch(showLoader());
                                addLoanDocumentApi(newDocument).then(function (response) {
                                    getDealFiles();
                                    setPopupOpen('fileadded');
                                    setMultiFormValues([])
                                    setModalIsOpen(false);
                                    setSelectedOption(null)
                                    dispatch(hideLoader());
                                }).catch((err) => {
                                    dispatch(hideLoader());
                                })
                            }
                        });
                }


            });
    };
    const handleActiveToggle = (value, item) => {
        setActiveToggle(value)
        setFormvalues(item)
        setMultiFormValues([]);
        if(value==="edit"||value==="view"){
            setFileDocName(item.filename)
        }

    }
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedOption(null)
    };
    const handleClosedeletePopup = () => {
        setActiveItem({})
        setDeletePopup(false)
        setActiveDeleteFlag("")
    }

    const handleSelectChange = (selectedOptions, key) => {
        let form = { ...formvalues };
        setFormvalues({ ...form, [key]: selectedOptions });
    };
    const handleUpdateFile = (e) => {
        dispatch(showLoader());
        let { _id, ...payload } = { ...formvalues }
        updatedDealFileApi(payload).then(function (response) {
            if (response) {
                // console.log('response?.result', response.result);
                getDealFiles();
                setActiveToggle("list")

                setPopupOpen('added');
                dispatch(hideLoader());

            }
        }).catch((err) => {
            // console.error.bind("my account error", err);
            dispatch(hideLoader());
        })
    }

    const handledeleteFile = () => {

        dispatch(showLoader());
        // let payload = {'documentid': activeItem?.documentid,'loanid':loanInfo?.loanid}
        let payload = {}
        if (multiFormValues.length > 0 && activeDeleteFlag === "multi") {
            payload = { 'documentid': multiFormValues, 'loanid': loanInfo?.loanid }
        } else if (activeItem?.documentid && activeDeleteFlag === "single") {
            payload = { 'documentid': [activeItem?.documentid], 'loanid': loanInfo?.loanid }
        }
        deleteDealFileApi(payload).then(function (response) {
            if (response) {
                // console.log('response?.result', response.result);
                getDealFiles();
                setActiveToggle("list")
                setActiveItem({})
                setPopupOpen('delete');
                setDeletePopup(false)
                setMultiFormValues([])
                dispatch(hideLoader());

            }
        }).catch((err) => {
            // console.error.bind("my account error", err);
            dispatch(hideLoader());
        })
    }
    const handleViewOpen = (e, content,valueState) => {

        let source;
        let type = content.filetype.split("/")
        if (type[0] === "image") {
            source = configdata?.common?.awsImgix + content.filepath;
        } else {
            source = configdata?.common?.resourcesCloudfront + content.filepath;
        }
        // const k = formvalues.filter(item=>item.name ==)
        if(valueState === "out"){
            setFileDocName(content?.filename)
        }
      
        setPlayContent(source);
        setshowDoc(true);
        setType(content.filetype);
        setMultiFormValues([]);
        // console.log("playercontent==========>", source);
    }

    const handleAddGroupName = (e) => {
        // console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
        const newErrors = {}
        if (typeof groupName === 'string' && groupName.trim() === "") {
            newErrors['groupname'] = `Enter a group name`;
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            let apiData = { 'groupname': groupName, loanid: loanInfo?.loanid };


            dispatch(showLoader());
            addGroupNameApi(apiData).then(function (response) {
                if (response) {
                    // console.log('response?.result', response.result);


                    getloanGroupList();
                    dispatch(hideLoader());
                    setPopupOpen('group');
                    document.getElementById("close1").click()
                    setGroupPopup(false)

                    setGroupName("")
                }
            }).catch((err) => {
                // console.error.bind("my account error", err);
                dispatch(hideLoader());
            })
        } else {
            // console.log('errors ----> ',newErrors)
        }
    }
    const closePopup = () => {
        setPopupOpen("");

    };
    const handleChange1 = (newValue) => {
       
        setSelectedOption(newValue);
      };
      const handleClick = () => {
        const newErrors = {}
        if (!selectedOption) {
            newErrors['selectedTag'] = `Enter or select tag`;
        
        setErrors(newErrors);
        }
        else{

        
        document.getElementById('fileInput').click();
        }
    };
    // console.log('groupNamesList',groupNamesList)
    // console.log('selectedOption',selectedOption)
    const getFileExtension = (url) => {
        const match = url.match(/\.\w+$/); // Matches the last dot and following word characters
        return match ? match[0] : ''; // Return the extension or an empty string if not found
      };

    const DownaloadFile = async (e,fileName,docURL) => {
        e.preventDefault();
        try {
            console.log('configdatadocURL',docURL, configdata?.common?.resourcesCloudfront);
          const response = await fetch(configdata?.common?.resourcesCloudfront+docURL);
          if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
          }
          let fileType = getFileExtension(docURL);
          console.log('fileType',fileType);
    
          const blob = await response.blob();
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    
          URL.revokeObjectURL(link.href);
          console.log('File downloaded successfully');
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      };

    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader /> : null}
            {isPopupOpen === "group" && (
                <PopupMessage
                    type={'success'}
                    message={'Added Successfully'}
                    onClose={closePopup}
                />

            )}
            {isPopupOpen === "added" && (
                <PopupMessage
                    type={'success'}
                    message={'Saved Successfully'}
                    onClose={closePopup}
                />

            )}
            {isPopupOpen === "fileadded" && (
                <PopupMessage
                    type={'success'}
                    message={'File Uploaded Successfully'}
                    onClose={closePopup}
                />

            )}
            {isPopupOpen === "delete" && (
                <PopupMessage
                    type={'success'}
                    message={'Deleted Successfully'}
                    onClose={closePopup}
                />

            )}
            {showDoc && <FileViewer source={playContent} type={type} close={setshowDoc} name={fileDocName} />}
            <PipelineHeader />
            <Modal className='file_upload_popup'
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="File Upload Modal"
                >
                <div>
                    <h2>Upload File</h2>
                    <button id="uploadClose" className='close_btn' onClick={closeModal}><span className="material-symbols-outlined icon"> close </span></button>
                  <div className='row mb_15'>
                    <div className='col-md-6'>
                     {/* <label><b>Tag</b></label>                          */}
                    <Creatable
                        isClearable
                        onChange={handleChange1}
                        // className={`w-100`}
                        className={`w-100 ${errors['selectedTag'] && 'invalid-input'}`}
                        options={defaultOptions}
                        value={selectedOption}
                        onFocus={() => { setErrors({ ...errors, selectedTag: null }) }}
                        placeholder="Select tag or type to create..."
                        id='selectType'
                       
                    />
                  
                    </div>
                    <div className='col-md-6'></div>
                  </div>
                
                    <div style={{opacity:!selectedOption ? '0.5':'', pointerEvents:!selectedOption ? 'none':''}}
                        // className="dropzone disable"
                        className={`dropzone ${errors['selectedTag'] && 'disable'}`}
                        onDrop={(e) => handleFileChange(e, 'drag')}
                        onDragOver={(e) => e.preventDefault()}
                        // disabled={!selectedOption} 
                        onClick={handleClick}
                        id = 'chooseFile'
                    >
                        <p><b>Drop here or click to choose</b></p>
                        <p> <span className='choose_file'>Choose File</span> </p>
                        {/* <p><b>Drag & Drop Files Here</b></p> */}
                        {files.map((file, index) => (
                            <div key={index}>
                                <p>{file.name}</p>
                            </div>
                        ))}
                    </div>
                    {/* <label for="fileInput" className=" fillbtn_new add_button text-center" style={{backgroundColor:!selectedOption ? 'grey':''}}>Choose File</label> */}
                    <input type="file" id="fileInput" 
                    // disabled={!selectedOption} 
                    style={{ display: 'none' }} multiple accept=".pdf, .xls, .xlsx, .doc, .docx, .txt, .zip, .png, jpeg.,.heic" onChange={(e) => handleFileChange(e, 'input')} ></input>
            

                 
                </div>
            </Modal>
            <div className="dashboard_block" >
                <PipelineStatus />
                <h2 className="form_title">{isDealFiles ?  'Underwriting' : 'Processing'} <span className="space">&#10095;</span> <span className="sub"> Files</span> </h2>
                <div className="card user_management detailed_app" >
                    <div className="card-body my_account">
                        {activeToggle === "list" && <>
                            <div className="table-responsive">
                                <div className="search">
                                    <div className="d-flex align-items-center">
                                        <h3 className="title mb-0 me-4">FILES</h3>
                                        <div className="btn-group" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                                            <div className="upload-btn-wrapper">
                                                <button id="fileUpload" className="fillbtn_new add_button" onClick={openModal}> Upload <span className="material-icons icon"> file_upload
                                                </span></button>
                                                {/* <input type="file" name="myfile" /> */}

                                            </div>
                                            {allDealFilesData && allDealFilesData.length > 0 &&
                                                <button id="consolidatePdfs" type="button" className="fillbtn_new" onClick={(e) => handleActiveToggle('consolidate')} >Consolidate PDFs<span
                                                    className="material-symbols-outlined">cell_merge</span></button>
                                            }

                                        </div>
                                    </div>
                                    {allDealFilesData && allDealFilesData.length > 0 &&
                                        <div className="btn-group" style={{
                                            pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                            opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                          }}>
                                            {multiFormValues.length >= 1 && <button id="downloadFileData" type="button" className="fillbtn_new"
                                                onClick={handleDownloadClick}
                                            >Download<span className="material-symbols-outlined icon">download</span>
                                            </button>
                                            }

                                            <button id="deleteFile" type="button" className="fillbtn_new" disabled={multiFormValues.length <= 0} onClick={(e) => { setDeletePopup(true); setActiveDeleteFlag("multi"); }}>Delete<span
                                                className="material-symbols-outlined">delete</span></button>

                                            <button id="mailFile" type="button" disabled={true} className="fillbtn_new">Email<span
                                                className="material-symbols-outlined">mail</span></button>

                                        </div>
                                    }
                                </div>
                                {/* <div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No Data Found</p></div> */}
                                {allDealFilesData && allDealFilesData.length > 0 ?
                                    <table className="table db_table file_table">

                                        <thead className="table-light">
                                            <tr>
                                                <th width="1%">
                                                    <div className="font-size-16 checkbox_ht">
                                                        <input className="form-check-input" type="checkbox" id="checkAll"
                                                            checked={multiFormValues.length === allDealFilesData.length} onChange={(e) => handleSelectAllItems(e)} />
                                                        <label className="form-check-label" htmlFor="checkAll"></label>
                                                    </div>
                                                </th>
                                                <th width="16%">File Name</th>
                                                {/* <th width="3%">Type</th> */}
                                                <th width="10%">Uploaded On</th>
                                                <th width="12%" className="text-center">Verified/approved</th>
                                                <th width="16%">Description</th>
                                                <th width="19%">Tag</th>
                                                <th width="11%">GROUP</th>
                                                <th width="10%">Expiry date</th>
                                                <th width="6%">Actions</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allDealFilesData && allDealFilesData?.map((eachItem,index) => {

                                                const filterList = multiFormValues.filter((each) => each === eachItem.documentid);
                                                const isCheck = Boolean(filterList[0]);
                                           

                                                return (
                                                    <tr>
                                                        <td>
                                                            <div className="font-size-16 checkbox_ht" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                                                                <input className="form-check-input" type="checkbox"

                                                                    id={eachItem.documentid} checked={isCheck} onChange={(e) => handleChangeCheckItem(e, eachItem)}
                                                                />
                                                                <label className="form-check-label" htmlFor="checkAll"></label>
                                                            </div>
                                                        </td>
                                                        <td> <div className="width_wrp" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                                                           {eachItem?.type == 'Individual Forms' || 'loan package' ? 
                                                           
                                                           <a id={`downloadFile-${index}`} href={'#'} onClick={(e)=>{DownaloadFile(e,eachItem.filename,eachItem.filepath)}} type="button" title='download' download={eachItem.filename} className="btn download_pdf"><span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Download</span>download</span></a> : 
                                                           <a id={`downloadFile-${index}`} href={configdata?.common?.resourcesCloudfront + eachItem.filepath} type="button" title='download' download={eachItem.filename} className="btn download_pdf"><span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Download</span>download</span></a>}
                                                         
                                                            {eachItem.filename}
                                                            </div>
                                                        </td>
                                                        {/* <td>{eachItem.fileextension}</td> */}


                                                        {/* <td>{eachItem.created  ? ( new Date(eachItem.created).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })) : ''}</td> */}
                                                        <td>{new Date(new Date(eachItem.created).getTime() + new Date(eachItem.created).getTimezoneOffset() * 60000).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                                        <td className="text-center" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}><label className="toggle mb-0">
                                                            <input id={`approvedFile-${index}`} style={{ cursor: 'default' }} className="toggle-checkbox" type="checkbox" disabled checked={eachItem?.approved} />
                                                            <div className="toggle-switch"></div>

                                                        </label></td>
                                                        <td><div className='max_text'>{eachItem?.filedescription}</div> </td>
                                                        <td>{eachItem?.type}</td>
                                                        <td>{eachItem?.groupname}</td>
                                                        <td>{eachItem?.expirydate ? new Date(new Date(eachItem.expirydate).getTime() + new Date(eachItem.expirydate).getTimezoneOffset() * 60000).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }): ''}</td>
                                                        {/* <td>{new Date(dateString).toLocaleDateString('en-US')}</td> */}

                                                        <td>
                                                            <div className="btn-group">
                                                                <button id={`viewFile-${index}`} type="button" className="btn btn-outline-secondary"
                                                                    onClick={(e) => handleViewOpen(e, eachItem,"out")}
                                                                // data-bs-toggle="modal" 

                                                                // data-bs-target="#pdfdoc"
                                                                // data-bs-whatever="@mdo"
                                                                ><span
                                                                    className="material-symbols-outlined icon tooltip-container"><span className="tooltip">View</span>
                                                                        visibility </span></button>
                                                                <button id={`editFile-${index}`} type="button" onClick={(e) => handleActiveToggle('edit', eachItem)} className="btn btn-outline-secondary" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}><span
                                                                    className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Edit</span>
                                                                    edit </span></button>

                                                                <button id={`deleteFile-${index}`} type="button" onClick={(e) => { setDeletePopup(true); setActiveItem(eachItem); setActiveDeleteFlag("single"); setMultiFormValues([]); }} className="btn btn-outline-secondary" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}><span
                                                                    className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span>
                                                                    delete </span></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            {/* <tr>
                                        <td>
                                            <div className="font-size-16 checkbox_ht">
                                                <input className="form-check-input" type="checkbox" id="checkAll"
                                                    checked="" />
                                                <label className="form-check-label" htmlFor="checkAll"></label>
                                            </div>
                                        </td>
                                        <td><button type="button" className="btn download_pdf"><span
                                            className="material-symbols-outlined icon">download</span></button>BookStatement.pdf
                                        </td>
                                        <td>4th March, 2024</td>
                                        <td className="text-center"><label className="toggle mb-0">
                                            <input className="toggle-checkbox" type="checkbox" />
                                            <div className="toggle-switch"></div>

                                        </label></td>
                                        <td>Escrow Docs</td>
                                        <td>Escrow Docs</td>
                                        <td>2nd Mar, 2024</td>

                                        <td>
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-outline-secondary"
                                                    data-bs-toggle="modal" data-bs-target="#pdfdoc"
                                                    data-bs-whatever="@mdo"><span
                                                        className="material-symbols-outlined icon">
                                                        visibility </span></button>
                                                <button type="button" className="btn btn-outline-secondary"><span
                                                    className="material-symbols-outlined icon">
                                                    edit </span></button>

                                                <button type="button" className="btn btn-outline-secondary"><span
                                                    className="material-symbols-outlined icon">
                                                    delete </span></button>
                                            </div>
                                        </td>
                                    </tr> */}




                                        </tbody>
                                    </table>
                                    : <div className="inprogress_page"><span className="material-symbols-outlined icon"> content_paste_search </span><p>No Data Found</p></div>
                                }
                            </div></>
                        }

                        {activeToggle === "edit" &&
                            <div className="normal_page create_entity">
                                <div className="search">
                                    <div className="d-flex align-items-center">
                                        <h3 className="title mb-0 mr_10">EDIT FILE</h3>
                                        <div className="btn-group">
                                            <button id="fileView" type="button" className="fillbtn_new"

                                                // data-bs-toggle="modal"  

                                                onClick={(e) => handleViewOpen(e, formvalues,'in')}
                                            // data-bs-target="#pdfdoc" data-bs-whatever="@mdo"

                                            >File View<span
                                                className="material-symbols-outlined">visibility</span></button>
                                        </div>

                                    </div>
                                    <a id="navigateFileViewBack" style={{ cursor: 'pointer' }} onClick={(e) => handleActiveToggle('list')} className="back_btn" ><span className="material-icons icon"> chevron_left
                                    </span>BACK</a>
                                </div>
                                <div className="form_section">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>File Name</label>
                                                <input id="fileName" type="text" className={`form-control`} name='filename' placeholder="Enter File Name" onChange={(e) => handleChange(e)} value={formvalues.filename} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <div className="label_block">
                                                    <label>Group</label>
                                                    <button id="addGroup" data-bs-toggle="modal"
                                                        data-bs-target="#send_ach"
                                                        onClick={(e) => handleGroupToggle()}
                                                        data-bs-whatever="@mdo">Add<span
                                                            className="material-symbols-outlined">add</span></button>
                                                </div>
                                                <select id="fileGroup" className={`form-control`} name="groupname" value={formvalues?.groupname} onChange={(e) => handleChange(e)}>
                                                    <option selected="">Select</option>
                                                    {groupNamesList && groupNamesList.map((item) => {

                                                        return (
                                                            <option value={item.groupname}>{item.groupname}</option>
                                                        )
                                                    })}


                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Expiry Date</label>
                               
                                                <input id="fileExpiryDate" type="date" name='expirydate' className={`form-control`} onChange={(e) => handleChange(e)} value={formvalues.expirydate} placeholder="mm-dd-yyy" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Tag</label>
                                         
                                                {/* <input type="text" name='type' className={`form-control`} onChange={(e) => handleChange(e)} value={formvalues.type} placeholder="Enter Tag" /> */}

                                                <Creatable
                                                    isClearable
                                                    onChange={(e)=>handleChange(e,"tag")}
                                                    // className={`w-100`}
                                                    className={`w-100`}
                                                    options={defaultOptions}
                                                    value={{ value: formvalues.type, label: formvalues.type }}
                                                    // value={defaultOptions.find(option => option.value === formvalues.type) || null}
                                                    placeholder="Select or type to create..."
                                                    id='fileTag'

                                                />
                                            </div>
                                        </div>
                                          
                                        

                                        {/* <div className="col-md-3"></div> */}
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Visibility</label>
                                                {/* <img src="assets/images/tem/tem.png" className="w-100" alt="" /> */}
                                                <Select
                                                    options={groupDropDownOptions}
                                                    isMulti
                                                    onChange={(e) => handleSelectChange(e, 'visibility')}
                                                    value={formvalues?.visibility}
                                                   
                                                    className={`w-100`}
                                                    id="visibility"
                                                    rows="4" cols="50"
                                                // onChange={handleSelectChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="toggle mt_40">
                                                    <span className="toggle-label">Verified/approved</span>
                                                    <input id="fileApproved" className="toggle-checkbox" type="checkbox" name='approved' checked={formvalues.approved} value={formvalues.approved} onClick={(e) => handleChange(e)} />
                                                    <div className="toggle-switch"></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <textarea id="description" name='filedescription' className={`form-control`} onChange={(e) => handleChange(e)} value={formvalues.filedescription} rows="4" cols="50"
                                                    spellcheck="false"> </textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <button id="saveFiles" type="button" onClick={(e) => handleUpdateFile()} className="add_button fillbtn_new d-inline-block">save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        {activeToggle === "consolidate" &&
                            <div className="normal_page create_entity columns consolidate_pdf" >
                                <div className="search">
                                    <div className="d-flex align-items-center">
                                        <h4 className="mb-sm-0 font-size-18">Consolidate PDFs</h4>
                                        <span className="material-symbols-outlined">cell_merge</span>
                                        {/* <span className="question_mark">?</span> */}
                                    </div>
                                    <a id="navigateConsolidateBack" onClick={(e) => {handleActiveToggle('list');setUnselectedColumns([]);getDealFiles()}} style={{ cursor: 'pointer' }} className="back_btn"><span className="material-icons icon"> chevron_left
                                    </span>BACK</a>
                                </div>
                                <div className="form_section">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Document Name</label>
                                                <input id="consolidateDocname" type="text" className={`form-control ${docNameErr.length > 0 && 'invalid-input'}`} onFocus={() => { setdocNameErr("") }} name='documentname' onChange={(e) => { setDocumentName(e.target.value) }} value={documentName} placeholder="Enter Document Name" />
                                            </div>
                                        </div>
                                        <div className="columns_block">

                                            <div className="con">
                                                <div className="column_box">
                                                    <div className="clm_hed">
                                                        <label className="check_box"> <input id="availablePdfCheckBox" type="checkbox" checked={selectedColumns.every(item => item.checked) && selectedColumns.length !== 0} onChange={() => handleSelectAll('selected')} /> <span
                                                            className="checkmark"></span> </label>
                                                        <div className="right">
                                                            <h3>Available PDFs</h3>
                                                            <p>{selectedCount}/{selectedColumns.length} </p>
                                                        </div>
                                                    </div>
                                                    <div className="clm_con">
                                                        <ul className="loan_list">
                                                            <DraggableRendermain columns={selectedColumns.length>0 ?selectedColumns:[]} onPosChange={getChangedPos} type={'selected'} />



                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="middle">
                                                    <button id="moveRight" type="button" className="border_btn"
                                                        onClick={handleMoveToUnselected}
                                                    ><span
                                                        className="material-symbols-outlined icon"> chevron_right
                                                        </span></button>
                                                    <button id="moveLeft" type="button" className="border_btn"><span
                                                        className="material-symbols-outlined icon"
                                                        onClick={handleMoveToSelected}
                                                    > chevron_left
                                                    </span></button>
                                                    <button id="consolidateSave" className="fs_btn" disabled={unselectedColumns.length === 0} style={{ cursor: 'pointer' }} type='button'
                                                        // onClick={handleSaveZip}
                                                        onClick={mergePDFs}

                                                    >save</button>
                                                </div>
                                                <div className="column_box">
                                                    <div className="clm_hed">
                                                        <label className="check_box"> <input id="consolidateSelectedPdf" type="checkbox" checked={unselectedColumns.every(item => item.checked) && unselectedColumns.length !== 0} onChange={() => handleSelectAll('unselected')} /> <span
                                                            className="checkmark"></span> </label>
                                                        <div className="right">
                                                            <h3>Selected PDFs</h3>
                                                            <p>{unselectedCount}/{unselectedColumns.length} Selected</p>

                                                        </div>
                                                    </div>
                                                    <div className="clm_con">
                                                        <ul className="loan_list">
                                                            <DraggableRendermain columns={unselectedColumns.length > 0 ? unselectedColumns:[]} onPosChange={getChangedPosunSelected} type={'unselected'} />

                                                            {/* <li>
                                                            <label className="check_box">Loan Statement<input type="checkbox" />
                                                                <span className="checkmark"></span>
                                                            </label>

                                                        </li> */}

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    {deletePopup &&
                        <div className="modal message_popup popup conditional_popup deletepop_new fade show" id="messagepopup" tabindex="-1"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-body">
                                        <button type="button" className="close_button" data-bs-dismiss="modal"
                                            aria-label="Close"><span className="material-symbols-outlined" onClick={() => handleClosedeletePopup()}>close</span></button>
                                        <img src="assets/images/icons/delete.png" alt="" />
                                        <h3>Delete</h3>
                                        <p>Are you sure you want to delete this file?
                                        </p>
                                        <button id="deleteFile" type="button" className ="fs_btn pop_btn" onClick={() => handledeleteFile()} >Yes, delete</button>
                                    </div>

                                </div>
                            </div>
                        </div>}
                    {/* {groupPopup  &&  */}
                    {/* <div className="modal popup new_popup fade create_group shoq show" id="send_ach" tabindex="-1" aria-labelledby="send_achLabel" style={{display: 'block'}} aria-modal="true" role="dialog"> */}
                    <div className="modal popup new_popup fade create_group" id="send_ach" tabindex="-1"
                        aria-labelledby="send_achLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">CREATE GROUP</h5>
                                    <button type="button" className="pop_close" data-bs-dismiss="modal" id="close1" aria-label="Close"><span
                                        className="material-symbols-outlined" onClick={(e) => handleGroupToggle()} >close</span></button>
                                </div>
                                <div className="modal-body">

                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Group Name</label>
                                                <div className="d-flex align-items-center">
                                                    <input id="groupName" type="text" className={`form-control ${errors?.groupname && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, groupname: null }) }} name='groupname' placeholder="Enter Group Name" onChange={(e) => { setGroupName(e.target.value) }} value={groupName} />
                                                    {/* <select className="form-control">
                                                        <option selected="">Select</option>
                                                        <option>NA</option>
                                                    </select> */}
                                                    <button id="addGroup" type="button" onClick={(e) => handleAddGroupName(e)} className="fs_btn ms-3">ADD</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* } */}
                    <div className="modal popup new_popup fade pdf_doc" id="pdfdoc" tabindex="-1"
                        aria-labelledby="pdfdoc_achLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">BOOK STATEMENT - DOCUMENT</h5>
                                    <button id="pdfClose" type="button" className="pop_close" data-bs-dismiss="modal" aria-label="Close"><span
                                        className="material-symbols-outlined">close</span></button>
                                </div>
                                <div className="modal-body">
                                    <img src="assets/images/book-statement.jpg" alt="" height="30" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default DealFiles;