import React, { useRef, useEffect } from 'react';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PDFPreview = ({ fileUrl }) => {
    // Use a ref to ensure Viewer is not re-initialized unnecessarily
    const viewerRef = useRef(null);
    console.log('fileUrl',fileUrl)

    useEffect(() => {
        if (!fileUrl) {
            console.warn('No file URL provided.');
        }
    }, [fileUrl]); // Only runs when fileUrl changes

    if (!fileUrl) {
        return <div>Loading PDF...</div>; // Show loading indicator or error message
    }

    return (
        <div
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                maxHeight: '750px',
                maxWidth: '100%',
                overflow: 'auto',
            }}
        >
                        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"> */}
                <Viewer
                    fileUrl={fileUrl}
                    //scale={1.5}
                    theme={{
                        theme: 'auto',
                    }}
                    // defaultScale={SpecialZoomLevel.PageFit}
                    defaultScale={2.0}
                    ref={viewerRef} // Reference to the Viewer component
                />
            {/* </Worker> */}
        </div>
    );
};

export default React.memo(PDFPreview);
