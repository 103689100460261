import React,{useState, useCallback, useEffect} from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';
const libraries = ["places"];
const WarmAppLocationSearch = (props) => {
    const [searchBox, setSearchBox] = useState(null);
    const [inputValue, setInputValue] = useState(props.selectedPlace ||'');
    const configdata = useSelector(state => state.configdata);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: configdata?.common?.googleMapsKey,
        libraries: libraries,
      });
      const onSearchBoxLoad = useCallback((ref) => {
        setSearchBox(ref);
      }, []);

    useEffect(() => {
        if(props?.selectedPlace){
            setInputValue(props?.selectedPlace)
        }
    }, [props?.selectedPlace])

    useEffect(() => {
        if(props?.coBorrowerSelectedPlace){
            setInputValue(props?.coBorrowerSelectedPlace)
        }
    }, [props?.coBorrowerSelectedPlace])
      function fetchAndStorePlaceImage(place, callback) {
        // Define the Street View Service
        const streetViewService = new window.google.maps.StreetViewService();
    
        // Request Street View data for the place's location
        streetViewService.getPanorama({ location: place.geometry.location }, (data, status) => {
            if (status === 'OK') {
                // Construct the URL of the Street View image
                const imageUrl = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${place.geometry.location.lat()},${place.geometry.location.lng()}&key=AIzaSyCbt0tJTo-ltu5B5xTGurz5GLRCZCEVkF4`;
    
                // Call the callback function with the image URL
                callback(imageUrl);
            } else {
                console.error('Could not retrieve Street View image for the place:', status);
                // Call the callback function with null if image retrieval fails
                callback(null);
            }
        });
    }
      const onPlacesChanged = () => {
        const inputValue = document.querySelector('.searchcontroll').value;
        console.log('inputValue', inputValue);
    
        if (inputValue === '') {
            // Search has been cleared
            console.log('Search cleared');
            // Handle the cleared search as needed (e.g., reset selected place)
            if(props?.name == 'coborroweraddress' || props?.name == 'bankaddress' || props?.name == "propertyinfoplace"){
                props?.setCoBorrowerSelectedPlace(null);
            }else{
              props?.setSelectedPlace('');
            }
            //props?.setSelectedPlace('');
            

            return; // Exit early if the input is empty
        }
    
        const places = searchBox.getPlaces();
        const addressData = {}; // Initialize address data object
    
        // Process address components from the Places API
        places?.[0]?.address_components?.forEach(place => {
            place.types.forEach(type => {
                switch (type) {
                    case 'street_number':
                        addressData.street_number = place.long_name;
                        break;
                    case 'route':
                        addressData.route = place.long_name;
                        break;
                    case 'locality':
                        addressData.locality = place.long_name;
                        break;
                    case 'postal_code':
                        addressData.postal_code = place.long_name;
                        break;
                    // Add additional cases as needed
                    default:
                        break;
                }
            });
        });
    
        // Use Geocoder to fetch more detailed address information
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: places[0]?.geometry.location }, (results, status) => {
            if (status === 'OK' && results[0]) {
                fetchAndStorePlaceImage(results[0], (imageUrl) => {
                    // Store the image URL in addressData
                    addressData.image = imageUrl;
                    console.log('Image URL:', imageUrl);
                    props?.setAddressData(prevData => ({
                        ...prevData,
                        image: imageUrl
                    }));
                });
                const addressComponents = results[0].address_components;
                console.log('addressComponentsaddressData',addressData);
                addressComponents.forEach(component => {
                    const types = component.types;
                    if (types.includes('locality')) {
                        addressData.city = component.long_name;
                    } else if (types.includes('administrative_area_level_1')) {
                        addressData.state = component.long_name;
                        addressData.stateShortName = component.short_name;
                    } else if (types.includes('postal_code')) {
                        addressData.postal_code = component.long_name;
                    } else if (types.includes('sublocality_level_1')) {
                        addressData.area = component.long_name;
                    }else if (types.includes('administrative_area_level_2')) {
                        addressData.county = component.long_name; // Add the county
                    }
                });
            } else {
                console.error('Geocoder failed due to: ', status);
            }
    
            // Log the processed address data
            console.log('addressDatahere',addressData.image);
            const zipcodeFromAddress = places?.[0]?.formatted_address?.match(/\b\d{5}(?:-\d{4})?\b/)
            // Update props with the processed address data

            if(props?.name == 'coborroweraddress' || props?.name == 'bankaddress' || props?.name == "propertyinfoplace"){
                props?.setCoBorrowerAddressData(prevData => ({
                    ...prevData,
                    url: places[0]?.url,
                    geolocation: places[0]?.geometry,
                    zipcode: (addressData.postal_code ? addressData.postal_code : (zipcodeFromAddress ? zipcodeFromAddress : '') ),
                    state: addressData.state || addressData.city || '',
                    stateShortName: addressData.stateShortName || '',
                    address: `${addressData.street_number || ''} ${addressData.route || ''} ${addressData.area || ''}`,
                    city: addressData.city || '',
                    area: addressData.area || '',
                    fullAddress: places[0]?.formatted_address || '',
                    image: addressData.image,
                    county : addressData?.county?.includes(" County")
                    ? addressData?.county?.replace(" County", "")
                    : addressData?.county,
                }));
            }else{
                props?.setAddressData(prevData => ({
                    ...prevData,
                    url: places[0]?.url,
                    geolocation: places[0]?.geometry,
                    zipcode: (addressData.postal_code ? addressData.postal_code : (zipcodeFromAddress ? zipcodeFromAddress : '') ),
                    state: addressData.state || addressData.city || '',
                    stateShortName: addressData.stateShortName || '',
                    address: `${addressData.street_number || ''} ${addressData.route || ''} ${addressData.area || ''}`,
                    city: addressData.city || '',
                    area: addressData.area || '',
                    fullAddress: places[0]?.formatted_address || '',
                    image: addressData.image,
                     county : addressData?.county?.includes(" County")
  ? addressData?.county?.replace(" County", "")
  : addressData?.county,
                }));
            }
           
            setInputValue(`${addressData.street_number || ''} ${addressData.route || ''} ${addressData.area || ''}`?.trim() || places?.[0]?.formatted_address );
            // Set the selected place if formatted address is available
            if (places[0].formatted_address) {
                console.log("place address",places[0].formatted_address);
                if(props?.name == 'coborroweraddress' || props?.name == 'bankaddress' || props?.name == "propertyinfoplace"){
                    props?.setCoBorrowerSelectedPlace(places[0].formatted_address);
                }else{
                    props?.setSelectedPlace(places[0].formatted_address);
                }
                
                if(props?.placeIndex){
                    props?.setAddressIndex({...props?.addressIndex,[props?.placeIndex]:props?.placeIndex});// comes from deal under wrtiting termsheet
                }
                
            }
        });
      };
      const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        console.log('valueee----->',value)
        
        // Check if the input is empty
        if (value === '') {
          console.log('Input cleared');
          if(props?.name == 'coborroweraddress' || props?.name == 'bankaddress' || props?.name == "propertyinfoplace"){
            props?.setCoBorrowerSelectedPlace(null);
          }else{
            props?.setSelectedPlace('');
          }
          //props?.setSelectedPlace('');
          
          // Handle the input clear case here (e.g., resetting state or triggering a search)
        }
      };

      
  return (
    isLoaded &&<StandaloneSearchBox onLoad={onSearchBoxLoad} onPlacesChanged={onPlacesChanged} >
    {props?.name === 'coborroweraddress' || props?.name == 'bankaddress' || props?.name == 'propertyinfoplace'? 
        <input onChange={handleInputChange} type="text" placeholder="Search City and Zip Code" 
        className="form-control searchcontroll" 
            
            defaultValue={props?.coBorrowerSelectedPlace ? (props?.simpleAddress ? props?.coBorrowerSelectedPlace?.split(',')[0] : props?.coBorrowerSelectedPlace ): ''} value={props?.simpleAddress && inputValue} name={props?.name ? props?.name :'locationsearch'} />
        : <input onChange={handleInputChange} type="text" placeholder="Search City and Zip Code" 
        className="form-control searchcontroll" 
           
            defaultValue={props?.selectedPlace ? (props?.simpleAddress ? props?.selectedPlace?.split(',')[0] : props?.selectedPlace ): ''} value={props?.simpleAddress && inputValue} name={props?.name ? props?.name :'locationsearch'} />}
    
    </StandaloneSearchBox>
  );
};

export default WarmAppLocationSearch;