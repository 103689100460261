import React, { useState, useEffect ,useRef} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate, useLocation } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { getFormPackage, getIndividualLoansContentApi, getSingleFormTemplate ,getStates,getIndividualLoansContentApiData, addLoanDocumentApi, getAllloanpackage, pdfToWordConverter,getDealFilesApi,updatedDealFileApi,pdfToWordConverterV2} from "../utils/reducer";
import { getFormTemplates } from "../utils/reducer";
import Select from 'react-select';
import EditorEdit from './EditorEdit.js';
import PDFPreview from './PdfViewer.js'
import jsPDF from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import { Worker, Viewer ,SpecialZoomLevel} from '@react-pdf-viewer/core';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { useLoanInfo } from '../utils/loanInfoContext';

import { hideLoader, showLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './Loader.js';
import { Appname, APIUrl } from '../utils/reducer';
import axios from "axios";
import PopupMessage from './PopupMessage';
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';
import { Document, Page } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const ProcessingForms = () => {
    const configdata = useSelector(state => state.configdata);
console.log('configdata',configdata);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);

    const [content, setContent] = useState(location?.state?.data?.description);
    const [contentFooter, setcontentFooter] = useState('');
    const [content2, setContent2] = useState("");
    const [allFormPackages, setAllFormPackages] = useState([]);
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [documentsToSelect, setDocumentsToSelect] = useState([])
    const [documentsToSelectPacakages, setDocumentsToSelectPacakages] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateId, setTemplateId] = useState(null);
    const [activeForm, setActiveForm] = useState({});
    const [errors, setErrors] = useState({});
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [editorValidate, setEditorValidate] = useState(false);
    const [allFormTemplates, setAllFormTemplates] = useState([])
    const [fileType, setFileType] = useState('pdf');
    const [fileType2, setFileType2] = useState('pdf');
    const [packagevalue,setPackageValue] = useState('')
    const editorRef = useRef(null);
    const { loanInfo, setLoanInfo} = useLoanInfo();
    const [loanid,setLoanid] = useState(loanInfo?.loanid);
    const [states, setStates] = useState([]);
    const stateOptions = states?.map((state) => ({
        value: state,
        label: state,
    }));
    const [formData,setFormData] = useState({});
    const [formData2,setFormData2] = useState({});
    const [showFields,setShowFields] = useState(true)
    const [showFields2,setShowFields2] = useState(true)
    const [downloadDisable,setDownloadDisable] = useState(true);
    const [downloadDisablePackage,setDownloadDisablePackage] = useState(true);
    const [formErrors,setFormErrors] = useState({});
    const [formErrors2,setFormErrors2] = useState({});
    const [templateName,setTemplateName] = useState(null);
    let userData = getUserDataFromLocalStorage();
    // console.log("userData",userData)
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    const [docURL,setDocURL] = useState('https://d18d1bjuqqrg2z.cloudfront.net/formDownload/300288/template_671b45cdbb6ca6eb31ae0564_1729840589797.pdf')
    const FieldsArray = [
        'ECOAAPPARISALREPORTDISCLOURSE', 'ARBITRATIONAGREEMENT' , 'BALLOONPAYMENTDISCLOUSRE','BUSINESSPURPOSE',
        'CERTIFICATIONOFNONOWNEROCCUPANCY','COMPLIANCEAGREEMENTCA','CONDITIONALLOANAPPROVAL','HAZARDINSURANCEDISCLOSURE'
        ,'LOANANDSECURITYAGREEMENT', 'SECUREDNOTE','BORROWERBROKERSTATEMENT' , 'LENDERCLOSINGINSTRUCTIONS'
    ];
    const [licenceNumber , setLicenceNumber] = useState('');

    // url preserve method states.
    const [individualPdfLink, setIndividualPdfLink] = useState('');
    const [individualWordLink, setIndividualWordLink] = useState('');

    //url preserve method for loan package.
    const [packagePdfLink, setPackagePdfLink] = useState('');
    const [packageWordLink, setPackageWordLink] = useState('');
    const [formvalues,setFormValues] = useState([]);

    const [formvalues2,setFormValues2] = useState([])

    const handleSelectChange = (event) => {
       emptyFileUrlsPackage();
       setDownloadDisablePackage(true);
        
        const selectedPackage = event.target.value;
        setPackageValue(event.target.value)
        const selectedPackageObject = allFormPackages.find(packages => packages.packagename === selectedPackage);
        const statesToAdd = selectedPackageObject ? selectedPackageObject?.statesaddtopackage : '';
        console.log('statesToAddstatesToAddstatesToAdd',statesToAdd)
        const filteredDocuments = documentsToSelectPacakages.filter(document => statesToAdd.includes(document.value));
        console.log('filteredDocumentsfilteredDocuments',filteredDocuments)
        const affidavitDocuments = filteredDocuments?.filter(doc => doc.label === "Affidavit");
        const leninDocuments = filteredDocuments?.filter(doc => doc.label === "Lien Note");
        const borrowerInformation = filteredDocuments?.filter(doc => doc.label === "Borrower Information");
        const lienandgap = filteredDocuments?.filter(doc => doc.label === "FL-Lien and Gap Affidavit");
        const businessPurpose = filteredDocuments?.filter(doc => doc.label === "FL - Business Purpose Rider");
        const loanandsecurity = filteredDocuments?.filter(doc => doc.label === "Loan and Security Agreements-CA");
        const llcAffidavit = filteredDocuments?.filter(doc => doc.label === "FL - LLC Affidavit");
        const assignmentofleases = filteredDocuments?.filter(doc => doc.label === "FL-Assignments of Leases and Rents");
        const anticoercion = filteredDocuments?.filter(doc => doc.label === " FL - Anti Coercion");
        const compliance = filteredDocuments?.filter(doc => doc.label === "FL - Compliance Agreement");
        const noticeAssignment = filteredDocuments?.filter(doc => doc.label === "Notice of Final Agreement");
        const environmental = filteredDocuments?.filter(doc => doc.label === "Environmental Certificate");
        const collateral = filteredDocuments?.filter(doc => doc.label === "Collateral Protection Insurance Disclosure");
        const disclosure = filteredDocuments?.filter(doc => doc.label === "Disclosure for Balloon Mortgages");
        const taxes = filteredDocuments?.filter(doc => doc.label === "Taxes & Insurance");
        const errorsandomissions = filteredDocuments?.filter(doc => doc.label === "Errors and Omissions");
        const homestead = filteredDocuments?.filter(doc => doc.label === "Non Homestead Affidavit");
        const constructionfundescrowagreement = filteredDocuments?.filter(doc => doc.label === "Construction Fund Escrow Agreement");

        const balloonpayment = filteredDocuments?.filter(doc => doc.label === "Balloon Payment Disclousre-CA");
        const arbitrationagreement = filteredDocuments?.filter(doc => doc.label === "Arbitration Agreement-CA");
        const ecoaapparaisal = filteredDocuments?.filter(doc => doc.label === "ECOA Apparisal Report Disclourse");
        const borrowerbroker = filteredDocuments?.filter(doc => doc.label === "Borrower Broker Statement-CA");
        const deedoftrust = filteredDocuments?.filter(doc => doc.label === "Deed of Trust- CA");
        const securednote = filteredDocuments?.filter(doc => doc.label === "Secured Note- CA");

        const hazardinsurance = filteredDocuments?.filter(doc => doc.label === "Hazard Insurance Disclosure-CA");
        const businesspurposeca = filteredDocuments?.filter(doc => doc.label === "Business Purpose-CA");
        const nonowneroccupancy = filteredDocuments?.filter(doc => doc.label === "Certification of non-Owner Occupancy-CA");
        const complianceagrementca = filteredDocuments?.filter(doc => doc.label === "Compliance Agreement-CA");
        const loanapprovalca = filteredDocuments?.filter(doc => doc.label === "Conditional Loan Approval-CA");
        const environmentalca = filteredDocuments?.filter(doc => doc.label === "Environmental Indemnity-CA");

        const guarentary = filteredDocuments?.filter(doc => doc.label === "Guaranty");
        const closingAffidavit = filteredDocuments?.filter(doc => doc.label === "Closing Certificate and Affidavit");

        const tnstate = filteredDocuments?.filter(doc => doc.label === "Deed of Trust -TN State");
        const txstate = filteredDocuments?.filter(doc => doc.label === "Deed of Trust - TX State");
        const ncstate = filteredDocuments?.filter(doc => doc.label === "Deed of Trust - NC State");
        const gastate = filteredDocuments?.filter(doc => doc.label === "Deed to Secure Debt - GA State");
        const mortgage = filteredDocuments?.filter(doc => doc.label === "Mortgage");
        const constructionloan = filteredDocuments?.filter(doc => doc.label === "Construction Loan Agreement");
        
        // setFormData2({});
        setFormErrors2({})
        if (affidavitDocuments?.length > 0 || leninDocuments?.length > 0 || constructionfundescrowagreement?.length > 0 || noticeAssignment?.length > 0
            || environmental?.length > 0 || collateral?.length > 0 || disclosure?.length > 0 || taxes?.length > 0 || homestead?.length > 0 
            || borrowerInformation?.length > 0 || businessPurpose?.length > 0 || lienandgap?.length > 0 || llcAffidavit?.length > 0 || assignmentofleases?.length > 0
           || anticoercion?.length > 0 || compliance?.length > 0 || errorsandomissions?.length >0 || loanandsecurity?.length > 0 ||
           balloonpayment?.length >0 || arbitrationagreement?.length >0 || ecoaapparaisal?.length >0 || borrowerbroker?.length >0 || deedoftrust?.length >0 || securednote?.length >0 
          || hazardinsurance?.length >0 || businesspurposeca?.length >0 || nonowneroccupancy?.length >0 || complianceagrementca?.length >0  || loanapprovalca?.length >0 || environmentalca.length > 0
          || guarentary?.length > 0 || closingAffidavit?.length > 0
        || tnstate?.length > 0 || txstate?.length > 0 || ncstate?.length > 0 || gastate?.length > 0 || mortgage?.length > 0 || constructionloan?.length > 0
            ) {
            // setShowFields2(true);
        }else{
            // setShowFields2(false);
        }

        let packageName = event.target.value
        const selectedDocsName = filteredDocuments.map(document => document.templatename);
        console.log('selectedDocsName',packageName,loanInfo?.propertyinfo?.[0]?.lenderfundedrehab, selectedDocsName, filteredDocuments);
        let lenderRehab = loanInfo?.propertyinfo?.[0]?.lenderfundedrehab === 'Yes' ? false : true;
        let entityTrue = loanInfo && loanInfo.entity && loanInfo.entity.filter(entity => entity.status === "ACTIVE") ? false : true;
        const docsMapping = {
            lenderRehabOnly: ["CONSTRUCTIONLOANAGREEMENT", "CONSTRUCTIONFUNDESCROWAGREEMENT"],
            activeEntitiesOnly: ["LLCAFFIDAVIT"],
            both: ["CONSTRUCTIONLOANAGREEMENT", "CONSTRUCTIONFUNDESCROWAGREEMENT", "LLCAFFIDAVIT"],
            none: [],
        };
        console.log(lenderRehab,entityTrue)
        let docsToRemove = docsMapping.none;
        if (lenderRehab && entityTrue) {
            docsToRemove = docsMapping.both;
        } else if (lenderRehab) {
            docsToRemove = docsMapping.lenderRehabOnly;
        } else if (entityTrue) {
            docsToRemove = docsMapping.activeEntitiesOnly;
        }
        const templateNamesToRemove = docsToRemove;

            const updatedFilteredDocs = filteredDocuments.filter(document =>
                !templateNamesToRemove.includes(document.templatename)
            );
            
            const updateFormat = orderSelectedDocsByPackage(updatedFilteredDocs,allFormTemplates,packageName)
            setSelectedDocs(updateFormat);
            const updatedSelectedValues = updatedFilteredDocs.map(document => document.value);
            setSelectedDocuments(updatedSelectedValues);
            console.log("updatedSelectedValues",updatedFilteredDocs,updatedSelectedValues)
        
    };
    function orderSelectedDocsByPackage(selectedDocs, allTemplates, packageName) {
        // Create a map of templatename to orderid for the specified package
        const orderMap = {};
    
        allTemplates.forEach((template) => {
            // Ensure the package property exists and is an array
            if (Array.isArray(template.package)) {
                const packageDetails = template.package.find(pkg => pkg.name === packageName);
                if (packageDetails) {
                    orderMap[template.templatename] = packageDetails.orderid;
                }
            }
        });
    
        console.log("Order Map:", orderMap);
    
        // Filter and sort selectedDocs based on the orderMap
        const orderedDocs = [...selectedDocs]
            .filter(doc => orderMap.hasOwnProperty(doc.templatename)) // Ensure only selectedDocs present in the orderMap
            .sort((a, b) => {
                const orderA = orderMap[a.templatename] ?? Number.MAX_SAFE_INTEGER;
                const orderB = orderMap[b.templatename] ?? Number.MAX_SAFE_INTEGER;
                return orderA - orderB;
            });
    
        console.log("Ordered Docs:", orderedDocs);
    
        return orderedDocs;
    }
    

    const initialize = ()=>{
        setContent2("");setFormData({});setFormData2({});setShowFields(true);setShowFields2(true);setDownloadDisable(true);setFormErrors({});setFormErrors2({})
        setFileType('pdf');
        setFileType2('pdf');
        setSelectedDocs([]);
        setEditorValidate(false);
        setSelectedDocuments([]);setPackageValue('');
        setErrors({});setTemplateName(null);
        setActiveForm({});setTemplateId(null);setSelectedTemplate(null);setDocumentsToSelectPacakages([]);setDocumentsToSelect([]);
        setIndividualWordLink('');setIndividualPdfLink('');emptyFileUrlsPackage();setDownloadDisablePackage(true);
    }

console.log('traafasdfasdf',allFormPackages)
    useEffect(() => {
        getstates();
        getFormPackage().then(function (response) {
            if (response?.result) {
                console.log('response?.result--->', response?.result)
                setAllFormPackages(response?.result);
                let result = response?.result
                const transformedResult2 = result?.map(item => ({
                    value: item.templateid,
                    label: item.name,

                }));

            }
        }).catch((err) => {
            console.error.bind("my account error", err);
        })
    }, []);
    useEffect(() => {
        if(loanid){
            getForms()
        }
      }, [loanid]);
      useEffect(() => {
        setLoanid(loanInfo?.loanid)
      }, [loanInfo?.loanid]);

      useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.pipelinePermissions
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

    const getSingleFormTemplateFun = (templateName,templateId,formData) => {
        dispatch(showLoader());
        let k = location?.search?.split("=");
        const loanid = parseInt(k?.[1]) ?? "";
        let licencenumber = ''
        if((formData?.lendername === 'Easy Street Capital CA, LLC' || formData?.lendername ===  'National Loan Funding LLC') && FieldsArray?.includes(templateName)){
            licencenumber = licenceNumber
        }
        getIndividualLoansContentApi(templateName,loanid, templateId,formData,licencenumber,fileType).then(function (response) {

            if (response?.result) {
                let fileId = response?.result?.id;
                if (fileId) {
                    pollForFilePathIndividual(fileId);
                }
                // setIndividualPdfLink(response?.result?.link);
                // setIndividualWordLink('');
                // setDocURL(response?.result?.link)
               
                // setContent(response?.result?.template);
                // setcontentFooter(response?.result?.footerTemplate)
                // setActiveForm({...activeForm,content:result?.data?.[0]?.template});
                // setDownloadDisable(false);
                // dispatch(hideLoader());
                console.log('response?.result--->', response?.result)
            }else{
                dispatch(hideLoader());
            }
        }).catch((err) => {
            dispatch(hideLoader());
            console.error.bind("my account error", err);
        })
    }
    console.log('documentsToSelect', documentsToSelect)
    const getstates = async () => {
        getStates().then(function (response) {
            if (response?.result) {
               setStates(response?.result)
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };
  const fetchContent = async () => {
    setContent2("");
    if(showFields2){
        let formerrors = {}
            const fieldsToCheck = {
                // 'packagename' : 'packagename',
                "lendername" : 'lendername',
                "stateofformation" : 'stateofformation',
                "entity" : 'entity',
             };
             for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
                let value = formData2[fieldName];
                 if (typeof value === 'number') {
                    
                  }else{
                    if (!formData2[fieldName]?.trim()) {
                        formerrors[fieldName] = `Please Enter ${fieldLabel} `;
                    }
                  }
            };
            if(packagevalue?.length == 0){
                formerrors['packagename'] = 'please enter package name'
            }
            console.log(formerrors);
            setFormErrors2(formerrors)
            if (Object.keys(formerrors).length === 0) {
                // getSingleFormTemplateFun(templateName,templateId,formData);
            }else{
                return;
            }
    }
    let matchedTemplateNames = allFormTemplates?.filter(item => selectedDocuments?.includes(item.templateid)).map(item => item.templatename);        // Map to get `templatename` for each match
    const orderedTemplates = reorderTemplatesByPackage(matchedTemplateNames, allFormTemplates, packagevalue);
    console.log("Ordered Templates:", orderedTemplates);

    dispatch(showLoader());
    const response = await getAllloanpackage(orderedTemplates,loanid,formData2?.lendername,formData2?.stateofformation, formData2?.entity, licenceNumber,packagevalue,fileType2);
      if (response?.result) {
        console.log('getAllloanpackageresponse?.result', response?.result);
        let fileId = response?.result?.id;
        if(fileId){
            pollForFilePath(fileId);
        }
        // setPackagePdfLink(response?.result?.pdflink);
        // setPackageWordLink('');
        // setDownloadDisablePackage(false);
        // setDocURL(response?.result?.pdflink);
        // const pageBreak = '<!-- pagebreak-->';
        // const combinedContent = response?.result?.template.reduce((acc, result, index) => {
        //     return acc + (index > 0 ? pageBreak : '') + result.template;
        //   }, '');
        //   setContent2(prevContent => prevContent + combinedContent);
        //   dispatch(hideLoader());
      }else{
        dispatch(hideLoader());
      }
    //   dispatch(hideLoader());
    

  };
  const pollForFilePath = (fileId) => {
    const intervalId = setInterval(async () => {
        try {
            const response = await getDealFilesApi(loanid,fileId);
            console.log('response generated --->',response);
            let filePath = response?.result?.[0]?.filepath
            if (filePath) {
                setPackagePdfLink(filePath);
                setPackageWordLink('');
                setDownloadDisablePackage(false);
                setDocURL(filePath);
                setFormValues(response?.result?.[0]);
                console.log("File path found:", filePath);
                clearInterval(intervalId); // Stop polling
                // Handle the file path (e.g., set it in state or trigger download)
                dispatch(hideLoader());
            }
        } catch (error) {
            console.error("Error fetching file data:", error);
        }
    }, 5000); // Poll every 5 seconds
};

const pollForFilePathIndividual = (fileId) => {
    const intervalId = setInterval(async () => {
        try {
            const response = await getDealFilesApi(loanid,fileId);
            console.log('response generated --->',response);
            let filePath = response?.result?.[0]?.filepath
            if (filePath) {
                setIndividualPdfLink(filePath);
                setIndividualWordLink('');
                setDownloadDisable(false);
                setDocURL(filePath);
                setFormValues2(response?.result?.[0]);
                console.log("File path found:", filePath);
                clearInterval(intervalId); // Stop polling
                // Handle the file path (e.g., set it in state or trigger download)
                dispatch(hideLoader());
            }
        } catch (error) {
            console.error("Error fetching file data:", error);
        }
    }, 5000); // Poll every 5 seconds
};

const handleUpdateFile = (formdata) => {
    dispatch(showLoader());
    let payload  = { ...formdata, status : 'ACTIVE', isdownload : true };
    delete payload._id;
    updatedDealFileApi(payload).then(function (response) {
        if (response) {
            setPopupOpen('fileadded');
        }
        dispatch(hideLoader());
    }).catch((err) => {
        // console.error.bind("my account error", err);
        dispatch(hideLoader());
    })
}


const reorderTemplatesByPackage = (selectedTemplates, allTemplates, packagename) => {
    const templatesWithOrder = selectedTemplates.map(templateName => {
        const template = allTemplates.find(t => t.templatename === templateName);
        const orderObj = template?.package?.find(pkg => pkg.name === packagename);
        const orderid = orderObj ? orderObj.orderid : null;

        return {
            templatename: templateName,
            orderid: orderid
        };
    });
    console.log('templatesWithOrder',templatesWithOrder)

    templatesWithOrder.sort((a, b) => {
        if (a.orderid === null && b.orderid === null) return 0;
        if (a.orderid === null) return 1;
        if (b.orderid === null) return -1;
        return a.orderid - b.orderid;
    });

    return templatesWithOrder.map(template => template.templatename);
};
  const getSingleFormTemplatePackagesFun = async (id) => {
    let licencenumber = ''
    console.log('all templates and id',allFormTemplates,id);
    const templateid = id;
    const matchingTemplate = allFormTemplates.find(template => template.templateid === id);
    console.log(matchingTemplate);
    const templateName = matchingTemplate?.templatename;
    let formData = 
    (templateName === "AFFIDAVIT" || templateName === "LIENNOTE" || templateName === "CONSTRUCTIONFUNDESCROWAGREEMENT"||
    templateName === "NOTICEOFFINALAGRREMENT" || templateName === "ENVIRONMENTALCERTIFICATE" 
     || templateName === "COLLATERALPROTECTIONINSURANCEDISCLOSURE" || templateName === "DISCLOSUREFORBALLOONMORTGAGES"
     || templateName === "TAXESANDINSURANCE" || templateName ==="NONHOMESTEADAFFIDAVIT" || templateName === "BORROWERINFORMATION"
     || templateName === "LIENANDGAPAFFIDAVIT" || templateName === "BUSINESSPURPOSERIDER" || templateName === "LLCAFFIDAVIT" || templateName ==="ASSIGNMENTSOFLEASESANDRENTS"
     || templateName === "ANTICOERCION" || templateName === "COMPLIANCEAGREEMENT" || templateName === "ERRORSANDOMISSIONS" || templateName === "LOANANDSECURITYAGREEMENT"
     || templateName === "SECUREDNOTE" || templateName === 'DEEDOFTRUSTCA' || templateName === 'BORROWERBROKERSTATEMENT' || templateName === 'ECOAAPPARISALREPORTDISCLOURSE'
     || templateName === "ARBITRATIONAGREEMENT" || templateName === "BALLOONPAYMENTDISCLOUSRE" 
     || templateName === "HAZARDINSURANCEDISCLOSURE" || templateName === "BUSINESSPURPOSE" || templateName === "CERTIFICATIONOFNONOWNEROCCUPANCY" || templateName === "COMPLIANCEAGREEMENTCA" || templateName === "CONDITIONALLOANAPPROVAL"
     || templateName === "ENVIRONMENTALINDEMNITY" || templateName === 'GUARANTY' || templateName === 'CLOSINGCERTIFICATEANDAFFIDAVIT'
|| templateName === 'DEEDOFTRUSTTN' || templateName === 'DEEDOFTRUSTTX' || templateName === 'DEEDOFTRUSTNC' || templateName === 'DEEDTOSECUREDEBTTX' || templateName === 'DEEDTOSECUREDEBTTX' || templateName === 'CONSTRUCTIONLOANAGREEMENT'
     )
     && showFields2 ? formData2 : {};
     formData = formData2;
     if(FieldsArray?.includes(templateName)){
        licencenumber = licenceNumber;
     }else{
        licencenumber = '';
     }
    dispatch(showLoader());
    try {
      const response = await getIndividualLoansContentApiData(templateName,loanid,templateid,formData,licencenumber);
      if (response?.result) {
        let result = response?.result;
        const pageBreak = '<!-- pagebreak-->';
        setContent2(prevContent => prevContent + (prevContent ? pageBreak : '') + result?.template);
        dispatch(hideLoader());
        console.log('response?.result--->', response?.result);
      }
    } catch (err) {
      dispatch(hideLoader());
      console.error("my account error", err);
    }
  };

//   const downloadFile = async () => {
//     // if (fileType === 'pdf') {
//     //   const pdfContent = htmlToPdfmake(content2);
//     //   const documentDefinition = { content: pdfContent };
//     //   pdfMake.createPdf(documentDefinition).download('document.pdf');
//     // } else if (fileType === 'word') {
//     //   const converted = htmlDocx.asBlob(content2);
//     //   saveAs(converted, 'document.docx');
//     // }
//     try {
//     if (fileType === 'pdf') {
//         html2canvas(content2)
//         .then((canvas) => {
//           const pdf = new jsPDF('p', 'mm', 'a4');
//           pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 210, 297); // A4 size: 210 x 297 mm
//           pdf.save('download.pdf');
//         });
//       } else if (fileType === 'word') {
//         const converted = htmlDocx.asBlob(content2);
//         saveAs(converted, 'document.docx');
//       }
//        } catch (error) {
//       console.error('Error generating or downloading file:', error);
//       // Handle or display the error as needed
//     }
//   };



    // const saveAsPDF = () => {
    //     // Check if content2 exists
    //     console.log('content2',content2);
    //     // console.log('editorRef',editorRef.current);
    //     if (!content2) {
    //         return;
    //     }


    //     // Show loader if needed
    //     dispatch(showLoader());

    //     // Create new jsPDF instance
    //     const doc = new jsPDF({
    //         orientation: 'portrait',
    //         unit: 'px',
    //         format: 'a4'
    //     });

    //     // Convert content to canvas
    //     html2canvas(content2, {
    //         scale: 0.565, // Adjust scale to fit content within the page
    //     }).then((canvas) => {
    //         // Add canvas image to PDF
    //         const imgData = canvas.toDataURL('image/png');
    //         doc.addImage(imgData, 'PNG', 20, 20, 555, 740); // Adjust positioning and dimensions as needed
    //         // Save the PDF
    //         doc.save('loan_package.pdf');
    //         // Hide loader when PDF is saved
    //         dispatch(hideLoader());
    //     });
    // };
//   const SaveFile3 = () => {
   
//     //    selectedTemplate.value
//     dispatch(showLoader());
  
//         if (content2) {
           
//             // Create new jsPDF instance with custom width
//             const doc = new jsPDF({
//                 orientation: 'portrait',
//                 unit: 'px', // Units are in pixels
//                 format: 'a4'  // Custom width and height
//             });

//             // Convert HTML content to PDF
//             doc.html(content2, {
//                 x: 10, // Horizontal position (in pixels)
//                 y: 10, // Vertical position (in pixels)
//                 html2canvas: {
//                     scale: 1, // Adjust scale to fit content within the page
//                     useCORS: true // Enable CORS support for images if needed
//                 },

//                 callback: () => {
                   
//                     let fileName = 'loan_package.pdf';
//                     doc.save(fileName);
//                     dispatch(hideLoader());
//                 }
//             });
//         }else{

//         }
   
   
// }
// const SaveFile5 = () => { 
//     try {
  
//         if (fileType2 === 'pdf') {
//             const replacedHTML = content2;
//             let replacedContent = replacedHTML;

//             const doc = new jsPDF({
//                 orientation: 'portrait',
//                 unit: 'px', // Units are in pixels
//                 format: 'a4',  // Custom width and height
//                 autoPrint: true
//             }); 
//             let contentSections = replacedContent.split('<!-- pagebreak-->');
//             doc.html(replacedContent, {
       
//                 margin: [20, 30, 20, 30],
//                 html2canvas: {
//                     scale: 0.545 // Adjust scale to fit content within the page
//                 },

//                 callback: (docme) => {
//                     console.log('docme',docme,doc.internal.getNumberOfPages(),doc.internal.getc);
                   

//                     let fileName = `loan_package.pdf`;
//                     doc.save(fileName);
//                     dispatch(hideLoader());
//                 }
//             });
        
//     } else if (fileType2 === 'word') {
//         console.log('fileTypefileTypecontent',content2);
//         const converted = htmlDocx.asBlob(content2, { margins: {
//             top: 650, // 1 inch
//             right: 1060, // 1.5 inches
//             bottom: 650, // 1 inch
//             left:800, // 1 inch
//             header: 720, // 0.5 inch
//             footer: 720, // 0.5 inch
//             gutter: 0
//           } });
//         console.log('converted',converted);
//         saveAs(converted,`loan_package.docx`);
//     }
//     } catch (error) {
//     console.error('Error generating or downloading file:', error);
//     // Handle or display the error as needed
//   }
// }
const handleSaveFiletoS3 = (file, type) => {
    dispatch(showLoader())
    

    var bucket;
    console.log('file', file)
    const timestamp = Date.now();
    var fileformat = type;
    let name = packagevalue
    bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
    var ObjectID2 = require("bson-objectid");
    var uniqueId = new ObjectID2().toString();
    let uploadFilePath = Appname + "/dealfiles/" + uniqueId + "/" + timestamp + "." + fileformat;
    var data = { source_bucket: bucket, sourcepath: uploadFilePath }
    axios.post(APIUrl + 'uploadURL?appname=' + Appname, data, { type: 'application/json' })
        .then((response) => {
            if (response?.data && response?.data?.result) {
                var url = response?.data?.result;
                axios.put(url, file, {
                    "headers": {
                        "Content-Type": "multipart/form-data",
                        "Accept": "/",
                        "Cache-Control": "no-cache",
                        "Accept-Encoding": "gzip, deflate",
                        "Connection": "keep-alive",
                        "cache-control": "no-cache"
                    }
                })
                    .then((response) => {
                        if (response && response?.status === 200) {
                            const newDocument = {
                                filename: name,
                                filepath: uploadFilePath,
                                documentid: uniqueId,
                                fileextension: type,
                                filetype: type,
                                filedescription: '',
                                type:'Package',
                                loanid: loanInfo?.loanid,
                            }
                            dispatch(showLoader());
                            addLoanDocumentApi(newDocument).then(function (response) {
                                setPopupOpen('fileadded');
                                dispatch(hideLoader());
                            }).catch((err) => {
                                dispatch(hideLoader());
                            })
                        }
                    });
            }


        }).catch((err) => {
            dispatch(hideLoader());
        })
};
const SaveFile4 = async () => {
    try {
      if (fileType2 === 'pdf') {
        DownaloadFile('package','pdf',packagePdfLink);
        return
        dispatch(showLoader())
        const replacedContent = content2;
        let contentSections = replacedContent.split('<!-- pagebreak-->');
  
        const generatePDFs = async () => {
          const pdfDocs = [];
          for (let i = 0; i < contentSections.length; i++) {
            let doc = new jsPDF({
              orientation: 'portrait',
              unit: 'px',
              format: 'a4',
              autoPrint: true
            });
  
            await new Promise((resolve) => {
              doc.html(contentSections[i], {
                margin: [20, 30, 20, 30],
                html2canvas: {
                  scale: 0.545
                },
                callback: () => {
                  console.log(`Generated PDF ${i + 1}/${contentSections.length}`);
                  pdfDocs.push(doc.output('arraybuffer'));
                  resolve();
                }
              });
            });
          }
          return pdfDocs;
        };
  
        const mergePDFs = async (pdfDocs) => {
          const mergedPdf = await PDFDocument.create();
          for (const pdfBytes of pdfDocs) {
            const pdf = await PDFDocument.load(pdfBytes);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => mergedPdf.addPage(page));
          }
          return await mergedPdf.save();
        };
  
        const pdfDocs = await generatePDFs();
        const mergedPdfBytes = await mergePDFs(pdfDocs);
  
        const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'loan_package.pdf';
        link.click();
        handleSaveFiletoS3(blob,'pdf')
  
        console.log('All PDFs generated, merged, and saved.');
  
      }else if (fileType2 === 'word') {
        if(packageWordLink){
            DownaloadFile('package','docx',packageWordLink)
        }else if(packagePdfLink){
            wordConverterForPackage(packagePdfLink,'package')
            // wordConverter(packagePdfLink,'package');
        }
        return
        dispatch(showLoader());
        const replacedContent = content2;
        let contentSections = replacedContent.split('<!-- pagebreak-->');
        
        const generateWordDocument = async () => {
          let fullContent = '';
          
          for (let i = 0; i < contentSections.length; i++) {
            if (i > 0) {
              fullContent += '<br clear="all" style="page-break-before:always" />';
            }
            fullContent += contentSections[i];
          }
          
          const converted = await htmlDocx.asBlob(fullContent, { margins: {
            top: 650, // 1 inch
            right: 1060, // 1.5 inches
            bottom: 650, // 1 inch
            left:800, // 1 inch
            header: 720, // 0.5 inch
            footer: 720, // 0.5 inch
            gutter: 0
          } });
          
          return converted;
        };
        
        const wordBlob = await generateWordDocument();
        let propertyfulladdress = loanInfo?.propertyinfo?.[0]?.propertyfulladdress;
          let streetAddress = propertyfulladdress.split(',')[0].trim();
          let dateDrafted = new Date().toLocaleDateString("en-US"); 
          let filename = `${streetAddress || ''} – ${dateDrafted}.${'docx'}` 
        saveAs(wordBlob, filename);
        // saveAs(wordBlob, 'loan_package.docx');
        handleSaveFiletoS3(wordBlob,'docx')
        console.log('Word document generated and saved.');
        dispatch(hideLoader());
      }
    } catch (error) {
      console.error('Error generating or downloading file:', error);
      dispatch(hideLoader());
    }
  };


    const previewPDF = async () => {
        dispatch(showLoader());
        try {
          console.log('configdatadocURL', configdata?.common?.resourcesCloudfront+docURL);
      
          const response = await fetch(configdata?.common?.resourcesCloudfront+docURL);
          if (!response.ok) {
            dispatch(hideLoader());
            throw new Error(`Failed to fetch file: ${response.statusText}`);
          }
          
          const blob = await response.blob();
          const blobURL = URL.createObjectURL(blob);
        //   dispatch(hideLoader());
          // Open the PDF in a new tab
          window.open(blobURL, '_blank');
          dispatch(hideLoader());
          // Revoke the blob URL after a reasonable timeout
          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 5000); // Adjust timeout as needed
        } catch (error) {
          console.error('Error previewing PDF:', error);
        }
      };
      const getFileExtension = (url) => {
        const match = url.match(/\.\w+$/); // Matches the last dot and following word characters
        return match ? match[0] : ''; // Return the extension or an empty string if not found
      };
      
    const DownaloadFile = async (tabName,fileExtension,docURL) => {
        try {
            dispatch(showLoader());
            console.log('configdatadocURL',docURL, configdata?.common?.resourcesCloudfront);
          const response = await fetch(configdata?.common?.resourcesCloudfront+docURL);
          if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
          }
          let fileType = getFileExtension(docURL);
          console.log('fileType',fileType);
    
          const blob = await response.blob();
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          let propertyfulladdress = loanInfo?.propertyinfo?.[0]?.propertyfulladdress;
          let streetAddress = propertyfulladdress.split(',')[0].trim();
          let dateDrafted = new Date().toLocaleDateString("en-US"); 
          var ObjectID2 = require("bson-objectid");
          var uniqueId = new ObjectID2().toString();
          if(tabName === 'package'){
            if(fileExtension === 'pdf'){
                handleUpdateFile(formvalues);
            }
            link.download =   `${streetAddress || ''} – ${dateDrafted}.${fileType || fileExtension}` 
            dispatch(hideLoader());
          }else{
          let fileName = streetAddress ? `${streetAddress || ''} – ${selectedTemplate?.label || ''} – ${dateDrafted || ''}.${fileType || fileExtension}` : (selectedTemplate?.label && dateDrafted) ? `${selectedTemplate.label || ''} – ${dateDrafted}.${fileType || fileExtension}` : `${loanid || ''}.${fileType || fileExtension}`; // You can change this to any filename you prefer
          let fileTypeForUpload = fileExtension === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          const newDocument = {
            filename: fileName,
            filepath: docURL,
            documentid: uniqueId,
            fileextension:  fileExtension,
            filetype: fileTypeForUpload,
            filedescription: '',
            type:'Individual Forms',
            loanid: loanInfo?.loanid,
        }
        //   UplodToFiles(newDocument);
        if(fileExtension === 'pdf'){
            handleUpdateFile(formvalues2);
        }

            link.download = fileName;
            dispatch(hideLoader());
            // UplodToFiles(newDocument);

         
          }
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
    
          URL.revokeObjectURL(link.href);
          console.log('File downloaded successfully');
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      };

      const UplodToFiles = (newDocument)=>{
        
        dispatch(showLoader());
        addLoanDocumentApi(newDocument).then(function (response) {
            setPopupOpen('fileadded');
            dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
        })
      }
    const SaveFile = async () => {
        try {
            if (!templateId || !selectedTemplate) {
                const newErrors = { formtemplate: "Select form template" };
                setErrors(newErrors);
                return;
            }
    
            const k = location?.search?.split("=");
            const loanid = parseInt(k?.[1]) ?? "";
            const replacedContent = content
            const contentSections = replacedContent.split('<!-- pagebreak-->');  // Handle page breaks
            if (fileType === 'pdf') {
                DownaloadFile('individual','pdf',individualPdfLink)
                return
                dispatch(showLoader());
    
                const generatePDFs = async () => {
                    const pdfDocs = [];
                    let currentPageNumber = 1;
                    for (let i = 0; i < contentSections.length; i++) {
                        const doc = new jsPDF({
                            orientation: 'portrait',
                            unit: 'px',
                            format: 'a4',
                            autoPrint: true
                        });
                        await new Promise((resolve) => {
                             if(templateName === "SECUREDNOTE" || templateName === "LOANANDSECURITYAGREEMENT" || templateName ==='DEEDOFTRUSTCA' || templateName === 'EXTENSIONAGREEMENT' || templateName === 'EXTENSIONAGREEMENTACKNOWLDGEMENT'){
                                doc.html(contentSections[i], {
                                    margin: [20, 30, 60, 30],
                                    html2canvas: { scale: 0.545 },
                                    callback: () => {
                                      console.log("templateName",templateName)
                                      if(templateName === "SECUREDNOTE" || templateName === "LOANANDSECURITYAGREEMENT" || templateName === 'DEEDOFTRUSTCA' || templateName === 'EXTENSIONAGREEMENT' || templateName === 'EXTENSIONAGREEMENTACKNOWLDGEMENT'){
                                        const totalPages = doc.internal.getNumberOfPages();
                                        for (let page = 1; page <= totalPages; page++) {
                                            doc.setPage(page);
                                            // Get page dimensions
                                            const pageHeight = doc.internal.pageSize.height;
                                            const pageWidth = doc.internal.pageSize.width;
                                            // Define text position (above footer, in the right corner)
                                            const textYPosition = pageHeight - 50;  // 60px above the bottom
                                            const textXPosition = pageWidth - 130;  // Position towards the right corner
                                            // Add text to every page
                                            doc.setFontSize(10);  // Adjust font size as needed
                                            doc.text("borrower’s initials: __________", textXPosition, textYPosition);
                                        }
                                    }
                                        if (contentFooter) {
                                            currentPageNumber = addFooter(doc, currentPageNumber);
                                        }
                                        pdfDocs.push(doc.output('arraybuffer'));
                                        resolve();
                                    }
                                });
                            }else{
                            doc.html(contentSections[i], {
                                margin: [20, 30, 48, 30],
                                html2canvas: { scale: 0.545 },
                                callback: () => {
                                    if (contentFooter) {
                                        currentPageNumber = addFooter(doc, currentPageNumber);
                                    }
                                    pdfDocs.push(doc.output('arraybuffer'));
                                    resolve();
                                }
                            });
                        }
                        });
                    }
                    return pdfDocs;
                };
                
    
                const mergePDFs = async (pdfDocs) => {
                    const mergedPdf = await PDFDocument.create();
                    for (const pdfBytes of pdfDocs) {
                        const pdf = await PDFDocument.load(pdfBytes);
                        const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                        copiedPages.forEach((page) => mergedPdf.addPage(page));
                    }
                    return await mergedPdf.save();
                };
                const addFooter = (doc, startingPageNumber) => {
                    const pageCount = doc.internal.getNumberOfPages();
                    for (let i = 1; i <= pageCount; i++) {
                        const currentPageNumber = startingPageNumber + i - 1; // Calculate the current page number
                        doc.setPage(i);
                        doc.setFontSize(8);
                        doc.setLineWidth(1); // Set line width
                        doc.setDrawColor(0); // Set color to black (RGB: 0, 0, 0)
                        var lineHeight = 5; // Adjust this value to control the distance between the line and text
                        const pageWidth = doc.internal.pageSize.getWidth();
                        const footerYPosition = doc.internal.pageSize.getHeight() - 30;
                        doc.line(
                            30,
                            doc.internal.pageSize.getHeight() - 35 - lineHeight,
                            doc.internal.pageSize.getWidth() - 30,
                            doc.internal.pageSize.getHeight() - 35 - lineHeight
                        ); // Draw line closer to text
                        let footerText = contentFooter;
                        let footerText1 = contentFooter[0];
                        let footerText2 = contentFooter[1];
                        let footerText3 = contentFooter[2]; // Assume this is the right-aligned text

                        if (footerText2.includes("Page #")) {
                            footerText2 = footerText2.replace("Page #", `Page ${currentPageNumber}`);
                        }
                        // doc.text(
                        //     footerText,
                        //     30,
                        //     doc.internal.pageSize.getHeight() - 30
                        // );
                         // Left-aligned text
                         if(footerText1){
                            doc.text(
                                footerText1,
                                30,
                                footerYPosition
                            );
                        }
                        if(footerText2){
                        // Center-aligned text
                        const centerTextWidth = doc.getTextWidth(footerText2);
                        doc.text(
                            footerText2,
                            (pageWidth - centerTextWidth) / 2,
                            footerYPosition
                        );
                        }
                        if(footerText3){
                        // Right-aligned text
                        const rightTextWidth = doc.getTextWidth(footerText3);
                        doc.text(
                            footerText3,
                            pageWidth - 30 - rightTextWidth,
                            footerYPosition
                        );
                        }

                    }
                    return startingPageNumber + pageCount; // Return the new starting page number
                };
                
                const pdfDocs = await generatePDFs();
                const mergedPdfBytes = await mergePDFs(pdfDocs);
                const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${templateId}-${loanid}.pdf`;
                link.click();
    
                dispatch(hideLoader());
            } else if (fileType === 'word') {
                console.log('individualWordLink   ',individualWordLink,fileType)
                if(individualWordLink){
                    DownaloadFile('individual','docx',individualWordLink)
                }else if(individualPdfLink){
                    wordConverterForPackage(individualPdfLink,'individual')
                    // wordConverter(individualPdfLink,'individual');
                }
              
                return;
                dispatch(showLoader());
                const replacedContent = content;
          
                  console.log('replacedContent 11',replacedContent);

                
                console.log('replacedContent 22',replacedContent);
                let contentSections = replacedContent.split('<!-- pagebreak-->');
                
                const generateWordDocument = async () => {
                  let fullContent = '';
                  
                  for (let i = 0; i < contentSections.length; i++) {
                    if (i > 0) {
                      fullContent += '<br clear="all" style="page-break-before:always" />';
                    }
                    fullContent += contentSections[i];
            
                  }
                  
                  const converted = await htmlDocx.asBlob(fullContent, 
                    
                   
                        { margins: {
                    top: 650, // 1 inch
                    right: 1060, // 1.5 inches
                    bottom: 650, // 1 inch
                    left:800, // 1 inch
                    header: 720, // 0.5 inch
                    footer: 720, // 0.5 inch
                    gutter: 0
                  },
                }
            );
             

                  return converted;
                };
                
                const wordBlob = await generateWordDocument();
                let propertyfulladdress = loanInfo?.propertyinfo?.[0]?.propertyfulladdress;
          let streetAddress = propertyfulladdress.split(',')[0].trim();
          let dateDrafted = new Date().toLocaleDateString("en-US"); 
          let filename = `${streetAddress || ''} – ${dateDrafted}.${'docx'}` 
          saveAs(wordBlob, filename);
                console.log('Word document generated and saved.');
                dispatch(hideLoader());
            // const  template = content
            // const  footerTemplate = contentFooter
            // const generateWordDocument = async () => {
            //     try {
            //       // Parse the template and footer
            //       const template = content;
            //       const footerTemplate = contentFooter;
              
            //       // Function to split content into pages based on available height
            //       const splitContentIntoPages = (content, maxHeight) => {
            //         const tempDiv = document.createElement("div");
            //         tempDiv.style.position = "absolute";
            //         tempDiv.style.visibility = "hidden";
            //         tempDiv.style.width = "calc(100% - 2in)"; // Account for left and right margins
            //         tempDiv.style.lineHeight = "1.5"; // Match Word's default line height
            //         tempDiv.style.fontSize = "14px"; // Match document font size
            //         tempDiv.style.padding = "0 1in"; // Match horizontal padding for Word margins
            //         document.body.appendChild(tempDiv);
              
            //         const pages = [];
            //         let currentPage = "";
              
            //         tempDiv.innerHTML = ""; // Reset temp div
            //         content.split(/<br\s*\/?>|<\/p>/gi).forEach((line) => {
            //           tempDiv.innerHTML += line;
              
            //           if (tempDiv.offsetHeight > maxHeight) {
            //             pages.push(currentPage);
            //             currentPage = line; // Start a new page
            //             tempDiv.innerHTML = line;
            //           } else {
            //             currentPage += line;
            //           }
            //         });
              
            //         if (currentPage) pages.push(currentPage);
              
            //         document.body.removeChild(tempDiv); // Clean up
            //         return pages;
            //       };
              
            //       // Convert measurements to pixels (1 inch = 96 pixels)
            //       const inchesToPixels = (inches) => inches * 96;
            //       const pageHeight = inchesToPixels(11.69); // A4 height in inches
            //       const footerHeight = inchesToPixels(0.5); // Footer height in inches
            //       const topMargin = inchesToPixels(1); // Top margin in inches
            //       const bottomMargin = inchesToPixels(1); // Bottom margin in inches
            //       const availableHeight = pageHeight - topMargin - bottomMargin - footerHeight;
              
            //       // Split content into pages
            //       const contentSections = splitContentIntoPages(template, availableHeight);
              
            //       // Process content with footer
            //       const processedContent = contentSections.map((section, index) => {
            //         const processedFooter = footerTemplate.replace(
            //           '<span class="pageNumber" style="font-weight: bold;">Page # </span>',
            //           `<span class="pageNumber" style="font-weight: bold;">Page ${index + 1} of ${contentSections.length}</span>`
            //         );
              
            //         return `
            //           <div style="position: relative; height: ${pageHeight}px; display: flex; flex-direction: column; page-break-after: always;">
            //             <div style="flex-grow: 1; padding: ${topMargin}px 1in;">
            //               ${section}
            //             </div>
            //             <div style="
            //               position: absolute; 
            //               bottom: ${bottomMargin}px; 
            //               left: 0; 
            //               right: 0; 
            //               height: ${footerHeight}px;
            //               text-align: center;
            //               padding: 10px;
            //               background: #f9f9f9;
            //             ">
            //               ${processedFooter}
            //             </div>
            //           </div>
            //         `;
            //       }).join('');
              
            //       // Convert to Word document
            //       const converted = await htmlDocx.asBlob(processedContent, {
            //         margins: {
            //           top: 720,     // 1 inch
            //           right: 720,   // 1 inch
            //           bottom: 720,  // 1 inch
            //           left: 720,    // 1 inch
            //           header: 360,  // 0.5 inch
            //           footer: 360,  // 0.5 inch
            //           gutter: 0
            //         },
            //         orientation: 'portrait'
            //       });
              
            //       return converted;
            //     } catch (error) {
            //       console.error("Document Generation Error:", error);
            //       return null;
            //     }
            //   };
              
              
              
              
                  
            //       // Execution function
            //       const executeDocumentGeneration = async () => {
            //         try {
            //           dispatch(showLoader());
                      
            //           const wordBlob = await generateWordDocument();
                      
            //           if (wordBlob) {
            //             saveAs(wordBlob, `${templateId}-${loanid}.docx`);
            //             console.log('Word document generated successfully.');
            //           } else {
            //             console.error('Failed to generate Word document');
            //           }
            //         } catch (error) {
            //           console.error('Execution Failure:', error);
            //         } finally {
            //           dispatch(hideLoader());
            //         }
            //       };
                  
            //       // Trigger the generation
            //       executeDocumentGeneration();
              }
        } catch (error) {
            console.error('Error generating or downloading file:', error);
            dispatch(hideLoader());
        }
    };

    const handleFormTempChange = (selectedOption) => {
        console.log('Selected option', selectedOption);
        setContent('');
        setcontentFooter('');
        if(selectedOption?.label === 'Affidavit' || selectedOption?.label === 'Lien Note' || selectedOption?.label === "Construction Fund Escrow Agreement" || selectedOption?.label === "Notice of Final Agreement"
        || selectedOption?.label ===  'Environmental Certificate' || selectedOption?.label ===  'Collateral Protection Insurance Disclosure' || selectedOption?.label ===  'Disclosure for Balloon Mortgages' ||  selectedOption?.label === 'Taxes & Insurance'
        || selectedOption?.label ===  'Non Homestead Affidavit' || 
         selectedOption?.label === 'Borrower Information' || selectedOption?.label === 'FL - Business Purpose Rider' ||  selectedOption?.label === 'FL-Lien and Gap Affidavit' || selectedOption?.label ===  'FL - LLC Affidavit' || selectedOption?.label === 'FL-Assignments of Leases and Rents'
         || selectedOption?.label === 'FL - Anti Coercion' || selectedOption?.label ===  'FL - Compliance Agreement' ||  selectedOption?.label === 'Errors and Omissions' || selectedOption?.label === 'Loan and Security Agreements-CA' ||
          selectedOption?.label === 'Balloon Payment Disclousre-CA' || selectedOption?.label === 'Arbitration Agreement-CA' || selectedOption?.label === 'ECOA Apparisal Report Disclourse' || selectedOption?.label === 'Borrower Broker Statement-CA' || selectedOption?.label === 'Deed of Trust- CA' || selectedOption?.label === 'Secured Note- CA' 
          ||selectedOption?.label === 'Hazard Insurance Disclosure-CA' ||selectedOption?.label === 'Business Purpose-CA' ||selectedOption?.label === 'Certification of non-Owner Occupancy-CA' ||selectedOption?.label === 'Compliance Agreement-CA' ||selectedOption?.label === 'Conditional Loan Approval-CA' || selectedOption?.label === 'Environmental Indemnity-CA'
          || selectedOption?.label === 'Guaranty' || selectedOption?.label === 'Closing Certificate and Affidavit'
    || selectedOption?.label === 'Deed of Trust -TN State' || selectedOption?.label === 'Deed of Trust - TX State' || selectedOption?.label === 'Deed of Trust - NC State' || selectedOption?.label === 'Deed to Secure Debt - GA State' || selectedOption?.label === 'Mortgage' || selectedOption?.label === 'Construction Loan Agreement'
         ){
            // setShowFields(true);
            setFormErrors({});
        }else{
            // setFormData({});
            // setShowFields(false);
            setFormErrors({});
        };
        setDownloadDisable(true);
        setIndividualPdfLink('');
        setIndividualWordLink('');
        // setShowFields(true);
        const filteredDocuments = allFormTemplates.filter(document => document._id === selectedOption.value);
        console.log('filteredDocuments', filteredDocuments);
        setTemplateId(filteredDocuments[0]?.templateid ?? '');
        setTemplateName(filteredDocuments[0]?.templatename ?? '')
        setSelectedTemplate(selectedOption); 
    };
    const handleInndividualForm =()=>{
        if (!templateId || !selectedTemplate) {
            const newErrors = {}
            newErrors['formtemplate'] = `select form template`;
            setErrors(newErrors);
            return;
        };

        if(showFields){
            let formerrors = {}
            const fieldsToCheck = {
                "lendername" : 'lendername',
                "stateofformation" : 'stateofformation',
                "entity" : 'entity',
             };
             for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
                let value = formData[fieldName];
                 if (typeof value === 'number') {
                    
                  }else{
                    if (!formData[fieldName]?.trim()) {
                        formerrors[fieldName] = `Please Enter ${fieldLabel} `;
                    }
                  }
            };
            console.log("formerrors",formerrors,formData);
            setFormErrors(formerrors)
            if (Object.keys(formerrors).length === 0) {
                getSingleFormTemplateFun(templateName,templateId,formData);
            }else{
                return;
            }
        }else{
            let formData = {};
            getSingleFormTemplateFun(templateName,templateId,formData);
        }
        // setDownloadDisable(false);
    };
   
    const handleChange = (e) => {
        let { name, value } = e.target;
        setDownloadDisable(true);
        emptyFileUrlsIndivi();
        if(name ==='lendername'){
            if(value === 'Easy Street Capital, LLC'){
                setFormData((prev) => ({
                    ...prev,
                    'stateofformation' : 'Delaware',
                    'entity' : 'Limited Liability Company',
                    [name]: value,
                  }));
            }else if(value === 'Easy Street Capital CA, LLC'){
                setFormData((prev) => ({
                    ...prev,
                    'stateofformation' : 'Texas',
                    'entity' : 'Limited Liability Company',
                    [name]: value,
                  }));
                  setLicenceNumber('60DBO-97686');
            }else if(value === 'National Loan Funding LLC'){
                setFormData((prev) => ({
                    ...prev,
                    'stateofformation' : 'Delaware',
                    'entity' : 'Limited Liability Company',
                    [name]: value,
                  }));
                  setLicenceNumber('60DBO-188660')
            }else{
                setFormData((prev) => ({
                    ...prev,
                    'stateofformation' : '',
                    'entity' : '',
                    "lendername": '',
                  }));
                  setLicenceNumber('');
            }
            setFormErrors({});
        }else{
            setFormData((prev) => ({
                ...prev,
                [name]: value,
              }));
        }
      };

      const handleChange2 = (e) => {
        let { name, value } = e.target;
        setDownloadDisablePackage(true);
        emptyFileUrlsPackage();
        if(name ==='lendername'){
            if(value === 'Easy Street Capital, LLC'){
                setFormData2((prev) => ({
                    ...prev,
                    'stateofformation' : 'Delaware',
                    'entity' : 'Limited Liability Company',
                    [name]: value,
                  }));
            }else if(value === 'Easy Street Capital CA, LLC'){
                setFormData2((prev) => ({
                    ...prev,
                    'stateofformation' : 'Texas',
                    'entity' : 'Limited Liability Company',
                    [name]: value,
                  }));
                  setLicenceNumber('60DBO-97686');
            }else if(value === 'National Loan Funding LLC'){
                setFormData2((prev) => ({
                    ...prev,
                    'stateofformation' : 'Delaware',
                    'entity' : 'Limited Liability Company',
                    [name]: value,
                  }));
                  setLicenceNumber('60DBO-188660');
            }else{
                setFormData2((prev) => ({
                    ...prev,
                    'stateofformation' : '',
                    'entity' : '',
                    "lendername": '',
                  }));
                  setLicenceNumber('');
            }
            setFormErrors2({});
        }else{
            setFormData2((prev) => ({
                ...prev,
                [name]: value,
              }));
        }
      };

    useEffect(() => {
        const filteredDocuments = allFormTemplates.filter(document => selectedDocuments.includes(document._id));

        const concatenatedHTML = filteredDocuments.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.formContent;
        }, '');
        // console.log('filteredDocuments2',concatenatedHTML);
        setContent(concatenatedHTML);
    }, [selectedDocuments]);

    const getForms = () => {
        initialize();
        getFormTemplates('','','').then(function (response) {
            if (response?.result) {
                console.log("form temp",response?.result);
                setAllFormTemplates(response?.result?.data)
                let transformedResult = response?.result?.data.map(item => ({
                    value: item._id,
                    label: item.name,

                }));
                let transformedResult2 = response?.result?.data.map(item => ({
                    value: item.templateid,
                    label: item.name,
                    templatename: item.templatename
                }));
                const filteredArray = transformedResult.filter(item => {
                    if (loanInfo?.rehabfunding >= 0) {
                        return true; // Keep all items if rehaamount > 0
                    } else {
                        // Keep only items that are not related to construction
                        return item.label !== 'Construction Fund Escrow Agreement' && item.label !== 'Construction Loan Agreement';
                    }
                });
                const filteredArray2 = transformedResult2.filter(item => {
                    if (loanInfo?.rehabfunding >= 0) {
                        return true; // Keep all items if rehaamount > 0
                    } else {
                        // Keep only items that are not related to construction
                        return item.label !== 'Construction Fund Escrow Agreement' && item.label !== 'Construction Loan Agreement';
                    }
                });
                
                // console.log(filteredArray);
                // console.log("transformedResult2",loanInfo,transformedResult)
                setDocumentsToSelectPacakages(filteredArray2)
                setDocumentsToSelect(filteredArray)
            }
        }).catch((err) => {
            console.error.bind("my account error", err);
        })
    }

    const handleTempChange = (selectedOptions) => {
        setPackagePdfLink('');
        setPackageWordLink('');
        setDownloadDisablePackage(true);
        const selectedValues = selectedOptions.map(option => option.value);
        const affidavitDocuments = selectedOptions?.filter(doc => doc.label === "Affidavit");
        const leninnoteDocmunets = selectedOptions?.filter(doc => doc.label === "Lien Note");
        const borrowerInformation = selectedOptions?.filter(doc => doc.label === "Borrower Information");
        const compliance = selectedOptions?.filter(doc => doc.label === "FL - Compliance Agreement");
        const lienandgap = selectedOptions?.filter(doc => doc.label === "FL-Lien and Gap Affidavit");
        const businessPurpose = selectedOptions?.filter(doc => doc.label === "FL - Business Purpose Rider");
        const loanandsecurity = selectedOptions?.filter(doc => doc.label === "Loan and Security Agreements-CA");
        const llcAffidavit = selectedOptions?.filter(doc => doc.label === "FL - LLC Affidavit");
        const assignmentofleases = selectedOptions?.filter(doc => doc.label === "FL-Assignments of Leases and Rents");
        const anticoercion = selectedOptions?.filter(doc => doc.label === " FL - Anti Coercion");
        const noticeAssignment = selectedOptions?.filter(doc => doc.label === "Notice of Final Agreement");
        const environmental = selectedOptions?.filter(doc => doc.label === "Environmental Certificate");
        const collateral = selectedOptions?.filter(doc => doc.label === "Collateral Protection Insurance Disclosure");
        const disclosure = selectedOptions?.filter(doc => doc.label === "Disclosure for Balloon Mortgages");
        const taxes = selectedOptions?.filter(doc => doc.label === "Taxes & Insurance");
        const errorsandomissions = selectedOptions?.filter(doc => doc.label === "Errors and Omissions");
        const homestead = selectedOptions?.filter(doc => doc.label === "Non Homestead Affidavit");
        const constructionfundescrowagreement = selectedOptions?.filter(doc => doc.label === "Construction Fund Escrow Agreement");
        const balloonpayment = selectedOptions?.filter(doc => doc.label === "Balloon Payment Disclousre-CA");
        const arbitrationagreement = selectedOptions?.filter(doc => doc.label === "Arbitration Agreement-CA");
        const ecoaapparaisal = selectedOptions?.filter(doc => doc.label === "ECOA Apparisal Report Disclourse");
        const borrowerbroker = selectedOptions?.filter(doc => doc.label === "Borrower Broker Statement-CA");
        const deedoftrust = selectedOptions?.filter(doc => doc.label === "Deed of Trust- CA");
        const securednote = selectedOptions?.filter(doc => doc.label === "Secured Note- CA");
        const hazardinsurance = selectedOptions?.filter(doc => doc.label === "Hazard Insurance Disclosure-CA");
        const businesspurposeca = selectedOptions?.filter(doc => doc.label === "Business Purpose-CA");
        const nonowneroccupancy = selectedOptions?.filter(doc => doc.label === "Certification of non-Owner Occupancy-CA");
        const complianceagrementca = selectedOptions?.filter(doc => doc.label === "Compliance Agreement-CA");
        const loanapprovalca = selectedOptions?.filter(doc => doc.label === "Conditional Loan Approval-CA");
        const environmentalca = selectedOptions?.filter(doc => doc.label === "Environmental Indemnity-CA");
        const Guaranty = selectedOptions?.filter(doc => doc.label === "Guaranty");
        const closingAffidavit = selectedOptions?.filter(doc => doc.label === "Closing Certificate and Affidavit");

        const tnstate = selectedOptions?.filter(doc => doc.label === "Deed of Trust -TN State");
        const txstate = selectedOptions?.filter(doc => doc.label === "Deed of Trust - TX State");
        const ncstate = selectedOptions?.filter(doc => doc.label === "Deed of Trust - NC State");
        const gastate = selectedOptions?.filter(doc => doc.label === "Deed to Secure Debt - GA State");
        const mortgage = selectedOptions?.filter(doc => doc.label === "Mortgage");
        const constructionloan = selectedOptions?.filter(doc => doc.label === "Construction Loan Agreement");

        console.log('closingAffidavit',closingAffidavit);
        if (affidavitDocuments?.length > 0 || leninnoteDocmunets?.length > 0 || constructionfundescrowagreement?.length > 0 || noticeAssignment.length  > 0
            || environmental?.length > 0 || collateral?.length > 0 || disclosure?.length > 0 || taxes?.length > 0 || homestead?.length > 0 || 
            borrowerInformation?.length > 0 || businessPurpose?.length > 0 || lienandgap.length > 0 || llcAffidavit?.length > 0 || assignmentofleases?.length > 0
            || anticoercion?.length > 0 || compliance?.length > 0 || errorsandomissions?.length > 0 || loanandsecurity?.length > 0 ||
            balloonpayment?.length >0 || arbitrationagreement?.length >0 || ecoaapparaisal?.length >0 || borrowerbroker?.length >0 || deedoftrust?.length >0 || securednote?.length >0 
            || hazardinsurance?.length >0 || businesspurposeca?.length >0 || nonowneroccupancy?.length >0 || complianceagrementca?.length >0  || loanapprovalca?.length >0 || environmentalca?.length > 0
            || Guaranty?.length > 0 || closingAffidavit?.length > 0 
             || tnstate?.length > 0 || txstate?.length > 0 || ncstate?.length > 0 || gastate?.length > 0 || mortgage?.length > 0 || constructionloan?.length > 0
            ) {
            // setShowFields2(true);
        }else{
            // setShowFields2(false);
            setFormErrors2({});
        }
        setSelectedDocuments(selectedValues);
    };
    console.log('selectedDocs',selectedDocs)
    console.log('selectedDocuments',selectedDocuments)
    console.log('content2',content2)

    // const SaveFile2 = () => {
    //     if (content2) {
    //         dispatch(showLoader());
    //         const doc = new jsPDF({
    //             orientation: 'portrait',
    //             unit: 'px', // Units are in pixels
    //             format: 'a4'   // Custom width and height
    //         });

    //         // Convert HTML content to PDF
    //         doc.html(content2, {
              
    //             x: 20, // Horizontal position (in pixels)
    //             y: 0, // Vertical position (in pixels)
    //             html2canvas: {
    //                 scale: 0.565 // Adjust scale to fit content within the page
    //             },
    //             callback: () => {
    //                 // Save PDF file after rendering is complete
    //                 doc.save('generated-pdf.pdf');
    //                 dispatch(hideLoader());
    //             }
    //         });
    //     }
        
         
    // }

    const closePopup = () => {
        setPopupOpen("");

    };
    // const customStyles = {
    //     control: (provided) => ({
    //       ...provided,
    //       minHeight: "50px", // Minimum height for the control
    //       height: "auto", // Adjust height dynamically
    //       flexWrap: "wrap", // Wrap selected options
    //     }),
    //     menu: (provided) => ({
    //       ...provided,
    //       zIndex: 999, // Ensure dropdown appears above other elements
    //     }),
    //     multiValue: (provided) => ({
    //       ...provided,
    //       margin: "2px",
    //     }),
    //     multiValueLabel: (provided) => ({
    //       ...provided,
    //       whiteSpace: "normal",
    //     }),
    //   };

      const handleFormContentChange = (e)=>{
        // setDownloadDisable(true);
        setFileType(e.target.value)
      };
        // url preserve functions individual
      const emptyFileUrlsIndivi =()=>{
        setIndividualWordLink('');
        setIndividualPdfLink('');
      }

    //   const wordConverter = (pdfUrl,tabName) => {
    //     dispatch(showLoader());
    //     pdfToWordConverter(loanid,pdfUrl,tabName).then(function (response) {
    //         if (response?.result) {
    //             if(tabName === 'individual'){
    //                 DownaloadFile('individual','docx',response?.result?.link)
    //                 setIndividualWordLink(response?.result?.link);
    //             }else if(tabName === 'package'){
    //                 DownaloadFile('package','docx',response?.result?.link)
    //                 setPackageWordLink(response?.result?.link);
    //             }
    //             dispatch(hideLoader());
    //         }
    //     }).catch((err) => {
    //         console.error.bind("my account error", err);
    //     })
    // }

    const wordConverterForPackage = (pdfUrl, tabName) => {
        dispatch(showLoader());
        pdfToWordConverterV2(loanid, pdfUrl, tabName).then(function (response) {
            if (response?.result) {
                let fileId = response?.result?.id;
                pollForFilePath2(fileId,tabName);
                // DownaloadFile('package','docx',response?.result?.link)
                // setPackageWordLink(response?.result?.link);
                // dispatch(hideLoader());
            }
        }).catch((err) => {
            console.error.bind("my account error", err);
            dispatch(hideLoader());
        })
    }
    const pollForFilePath2 = (fileId,tabName) => {
    const intervalId = setInterval(async () => {
        try {
            const response = await getDealFilesApi(loanid,fileId);
            console.log('response generated --->',response);
            let filePath = response?.result?.[0]?.filepath
            if (filePath) {
                if(tabName == 'package'){
                    setPackageWordLink(filePath);
                    DownaloadFile('package','docx',filePath)
                }else{
                    setIndividualWordLink(filePath);
                    DownaloadFile('individual','docx',filePath)
                }
                
                dispatch(hideLoader());
                clearInterval(intervalId);
            }
        } catch (error) {
            console.error("Error fetching file data:", error);
        }
    }, 5000); // Poll every 5 seconds
};
  // url preserve functions individual
  const emptyFileUrlsPackage =()=>{
    setPackagePdfLink('');
    setPackageWordLink('');
  }
//   const PDFPreview = ({ fileUrl, width = '100%', height = '600px' }) => {
//     return (
//       <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflow: 'hidden' }}>
//         {fileUrl ? (
//           <iframe
//             src={fileUrl}
//             title="PDF Preview"
//             style={{ width, height, border: 'none' }}
//           ></iframe>
//         ) : (
//           <div style={{ padding: '20px', textAlign: 'center', color: '#999' }}>
//             No PDF file provided
//           </div>
//         )}
//       </div>
//     );
//   };
// const PDFPreview = ({ fileUrl }) => {
//     console.log('fileUrl',fileUrl)
//     if (!fileUrl) {
//       return <div>Loading PDF...</div>; // Show a loading indicator or error message.
//     }
  
//     return (
//     //   <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
//         <div
//            style={{
//             border: '1px solid rgba(0, 0, 0, 0.3)',
//             maxHeight: '750px',
//             maxWidth: '100%', // Optional: Adjust as needed
//             overflow: 'auto', // Enable scrolling
//           }}
//         >
//           <Viewer fileUrl={fileUrl} theme={{
//         theme: 'auto',
//     }}  defaultScale={SpecialZoomLevel.PageFit}/>
//         </div>
//     //   </Worker>
//     );
//   };




//   const PDFPreview = React.memo(({ fileUrl }) => {
//     if (!fileUrl) {
//       return <div>Loading PDF...</div>;
//     }
  
//     return (
//         <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"></Worker>
//       <div
//         style={{
//           border: '1px solid rgba(0, 0, 0, 0.3)',
//           maxHeight: '750px',
//           overflow: 'auto',
//         }}
//       >
//         <Viewer fileUrl={fileUrl}  theme={{
//         theme: 'auto',
//     }}  defaultScale={SpecialZoomLevel.PageFit} />
//       </div>
//       </Worker>
//     );
//   });
  
  
  
  
// const PDFPreview = ({ fileUrl }) => {
//     const [numPages, setNumPages] = useState(null);
//     const [pageNumber, setPageNumber] = useState(1);
  
//     const onDocumentLoadSuccess = ({ numPages }) => {
//       setNumPages(numPages);
//       setPageNumber(1); // Reset to the first page on load
//     };
  
//     const goToNextPage = () => {
//       if (pageNumber < numPages) setPageNumber(pageNumber + 1);
//     };
  
//     const goToPreviousPage = () => {
//       if (pageNumber > 1) setPageNumber(pageNumber - 1);
//     };
  
//     return (
//       <div style={{ textAlign: 'center', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
//         {fileUrl ? (
//           <>
//             <Document
//               file={fileUrl}
//               onLoadSuccess={onDocumentLoadSuccess}
//               onLoadError={(error) => console.error('Error loading PDF:', error)}
//             >
//               <Page pageNumber={pageNumber} width={600} />
//             </Document>
//             <div style={{ marginTop: '10px' }}>
//               <button
//                 onClick={goToPreviousPage}
//                 disabled={pageNumber <= 1}
//                 style={{
//                   margin: '0 5px',
//                   padding: '10px 15px',
//                   background: '#007bff',
//                   color: '#fff',
//                   border: 'none',
//                   borderRadius: '5px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 Previous
//               </button>
//               <span style={{ margin: '0 15px' }}>
//                 Page {pageNumber} of {numPages}
//               </span>
//               <button
//                 onClick={goToNextPage}
//                 disabled={pageNumber >= numPages}
//                 style={{
//                   margin: '0 5px',
//                   padding: '10px 15px',
//                   background: '#007bff',
//                   color: '#fff',
//                   border: 'none',
//                   borderRadius: '5px',
//                   cursor: 'pointer',
//                 }}
//               >
//                 Next
//               </button>
//             </div>
//           </>
//         ) : (
//           <div style={{ padding: '20px', color: '#999' }}>No PDF file provided</div>
//         )}
//       </div>
//     );
//   };
 
  
    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader /> : null}
            {isPopupOpen === "fileadded" && (
                <PopupMessage
                    type={'success'}
                    message={'File Uploaded successfully'}
                    onClose={closePopup}
                />

            )}
            <PipelineHeader />
            <div className="dashboard_block">
                <PipelineStatus />
                <h2 className="form_title">Processing <span className="space">&#10095;</span>  <span className="sub">Forms</span> </h2>
                <div className="card user_management detailed_app">
                    <div className="card-body my_account">
                        <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                            <li className="nav-item">
                                <a id="loanPackagesTab" className="nav-link active" data-bs-toggle="tab" href="#LOANPACKAGES" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">LOAN PACKAGES</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a id="individualFormsTab" className="nav-link " data-bs-toggle="tab" href="#INDIVIDUALFORMS" role="tab">
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">INDIVIDUAL FORMS</span>
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content pt-15 text-muted">
                            <div className="tab-pane active" id="LOANPACKAGES" role="tabpanel" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>


                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    {/* <h3 className="title">LOAN PACKAGES</h3> */}

                                </div>
                                <div className="form_section">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Select a Package</label>
                                                <select id="selectPackage" name='packagename'   value={packagevalue} onChange={handleSelectChange} className={`form-control ${formErrors2.packagename && 'invalid-input'}`} onFocus={() => { setFormErrors2({ ...formErrors2, packagename: null }) }}>
                                                    <option value='' >Please Select</option>
                                                    {allFormPackages.map((data, index) => {
                                                        return (
                                                            <option value={data.packagename} key={index}>{data.displayname}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-7">
                                            <div className="form-group">
                                                <label>Select Docs From Package</label>
                                                <Select
                                                    options={selectedDocs}
                                                    isMulti
                                                    value={selectedDocs.filter(option => selectedDocuments.includes(option.value))}
                                                    className={`w-100`}
                                                    //  id="w3review"
                                                    onChange={handleTempChange}
                                                    name='statesaddtopackage'
                                                    inputId='selectDocsPackage'
                                                />
                                            </div>
                                        </div> */}
                                        
                                  
                                        {showFields2 &&
                                        <>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Lender Name</label>
                                                <select id="lenderName" onChange={(e)=>handleChange2(e)} className={`form-control ${formErrors2.lendername && 'invalid-input'}`} onFocus={() => { setFormErrors2({ ...formErrors2, lendername: null }) }} 
                                                value={formData2?.lendername || ''} name='lendername' >
                                                    <option selected="">Please Select</option>
                                                    <option value="Easy Street Capital, LLC">Easy Street Capital, LLC</option>
                                                    <option value="Easy Street Capital CA, LLC">Easy Street Capital CA, LLC</option>
                                                    <option value="National Loan Funding LLC">National Loan Funding LLC</option>
                                                </select>
                                            </div>
                                        </div>
                                            {((formData2?.lendername === 'Easy Street Capital CA, LLC' || formData2?.lendername === 'National Loan Funding LLC') &&
                                                FieldsArray.some(field =>
                                                    selectedDocuments
                                                        .map(id => allFormTemplates.find(template => template.templateid === id))
                                                        .some(template => template?.templatename === field)
                                                )) &&
                                                <div className="col-md-3">
                                                    <div className='form'>
                                                        <div className="form-group ">
                                                            <label>Licence Number</label>
                                                            <input id="licencenumber" onChange={(e) => handleChange(e)} className={`form-control ${formErrors2.licencenumber && 'invalid-input'}`} onFocus={() => { setFormErrors2({ ...formErrors2, licencenumber: null }) }} value={licenceNumber || ''} type="text" name="licencenumber" disabled={true} placeholder='Licence Number' />
                                                        </div>
                                                    </div>
                                                </div>}
                                        <div className="col-md-3">
                                            
                                           <div className="form-group ">
                                           <label>State of Formation</label>
                                           <Select
                                            options={stateOptions}
                                            placeholder="Select"
                                            value={formData2?.stateofformation ? stateOptions.find(option => option?.value === formData2?.stateofformation) : '' }
                                            className={`w-100 ${formErrors2.stateofformation && 'invalid-input'}`} onFocus={() => { setFormErrors2({ ...formErrors2, stateofformation: null }) }}
                                            onChange={(selectedOption) => {
                                                setFormData2({...formData2, stateofformation : selectedOption?.value});
                                                setDownloadDisablePackage(true);
                                                emptyFileUrlsPackage();
                                            }}
                                            inputId='formationState'
                                        />
                                                       </div>
                                        </div>
                                        <div className="col-md-3">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Entity</label>
                                                           <input id="loanPackagesEntity" onChange={(e)=>handleChange2(e)} className={`form-control ${formErrors2.entity && 'invalid-input'}`} onFocus={() => { setFormErrors2({ ...formErrors2, entity: null }) }} value={formData2?.entity  || ''} type="text" name="entity"  placeholder='Entity'/>
                                                       </div>
                                                   </div>
                                               </div>
                                        </>
                                        
                                        }
                                        <div className="col-md-12">
                                            <div className="form-group sdf">
                                                <label>Select Docs From Package</label>
                                                <Select
                                                    options={selectedDocs}
                                                    isMulti
                                                    value={selectedDocs.filter((option) =>
                                                        selectedDocuments.includes(option.value)
                                                    )}
                                                    className="w-100"
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            height: "auto",
                                                            flexWrap: "wrap",
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 1050, // Ensures the dropdown appears above other elements
                                                        }),
                                                    }}
                                                    menuPlacement="bottom" // Forces dropdown to always open below
                                                    menuPosition="absolute" // Prevents it from being positioned relative to overflow-hidden containers
                                                    onChange={handleTempChange}
                                                    name="statesaddtopackage"
                                                    inputId="selectDocsPackage"
                                                />

                                            </div>
                                        </div>
                                       
                                        <div className="col-md-6 form_content">
                                        <div className="d-flex">
                                        <div className="form-group d-flex flex-column">
                                        <label className="white_label">
                                            Form Content
                                            </label>
                                       <button id="populateBtn" onClick={(e) => { fetchContent() }} className="fs_btn">Generate</button>
                                         </div>
                                            <div className="form-group" >
                                            <label className="white_label">
                                            Form Content
                                            </label>
                                                {/* <select className="form-control">
                                                    <option selected="">Please Select</option>
                                                    <option value="pdf">Save as PDF</option>
                                                    <option value="word">Save as Word Doc</option>
                                                </select> */}
                                                <select id="formContent" className="form-control" value={fileType2} onChange={(e) => setFileType2(e.target.value)}>
                                                {/* <option selected="">Please Select</option> */}
                                                    <option value="pdf">Generate as PDF</option>
                                                    <option value="word">Generate as Word Doc</option>
                                                </select>
                                            </div>
                                            <div className="form-group" >
                                            <label className="white_label">Form Content</label>
                                        <div className='dsp_flx'>
                                        {/* <button id="populateBtn" disabled={downloadDisablePackage} style={{'opacity' : !downloadDisable ? 1 : 0.6}} onClick={(e) => { fetchContent() }} className="fs_btn">Generate</button> */}
                                            <div className={`form-group  ${downloadDisablePackage? 'disable': ''}`} >
                                               {/* {fileType2 !== "word" &&     <button id="previewPDFPackages"  onClick={(e) => {
                                                        // SaveFile2();
                                                        previewPDF();
                                                        // saveAsPDF();
                                                        // downloadFile(); 

                                                    }} className="border_btn" style={{ marginRight: '10px' }}>Preview</button>
                                                    
                                                    } */}

                                                <button id="downloadLoanPackages" onClick={(e) => { 
                                                    // SaveFile2();
                                                    SaveFile4();
                                                    // saveAsPDF();
                                                    // downloadFile(); 
                                                    
                                                    }} className="border_btn">Download</button>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                {packagePdfLink && 
                                            //      <object 
                                            //      data={configdata?.common?.resourcesCloudfront+packagePdfLink+'#toolbar=0'}
                                            //      type="application/pdf" 
                                            //      width="100%" 
                                            //      height="550px">
                                            //      <p>Link to Document
                                            //          <a target='_blank' rel="noopener noreferrer" href={configdata?.common?.resourcesCloudfront+packagePdfLink}>to the PDF!</a>
                                            //      </p>
                                            //  </object>
                                            <div style={{ padding: '20px' }}>
                                            {/* <h1>Document Preview</h1> */}
                                                        {/* <div style={{ textAlign: 'center', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                                                            
                                                                <>
                                                                    <DocViewer
                                                                        style={{ width: '100%', height: '100%' }}
                                                                        pluginRenderers={DocViewerRenderers}
                                                                    config={{
                                                                        header: { disableHeader: true },
                                                                    }}
                                                                        documents={[
                                                                            { uri:configdata?.common?.resourcesCloudfront+packagePdfLink ,fileType: 'pdf',
                                                                                // fileName: props.name
                                                                             }  // Local File
                                                                          ]}
                                                                    />
                                                                </>
                                                          
                                                            
                                                        </div> */}
                                            <PDFPreview fileUrl={configdata?.common?.resourcesCloudfront+packagePdfLink} />
                                          </div>
                                                }
                                               
                                           {/* <iframe src={configdata?.common?.resourcesCloudfront+packagePdfLink} title="W3Schools Free Online Web Tutorials"></iframe> */}

                                                {/* <EditorEdit ref={editorRef} content={content2} setContent={setContent2} editorValidate={editorValidate} setEditorValidate={setEditorValidate} ReadOnlyflag={true}/> */}
                                            </div>

                                        </div>
                                        {/* <div className="col-md-12">
                                            <div className="form-group">
                                                <a href="#" className="fs_btn">Populate Package</a>
                                            </div>
                                            <img src="assets/images/texteditor.jpg" />
                                        </div> */}
                                    </div>
                                </div>


                            </div>
                            <div className="tab-pane" id="INDIVIDUALFORMS" role="tabpanel" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>


                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    {/* <h3 className="title">INDIVIDUAL FORMS</h3> */}

                                </div>
                                <div className="form_section">
                                    <div className="row">
                                        {/* <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Choose Form Group</label>
                                                    <select className="form-control">
                                                        <option selected="">Please Select</option>
                                                        <option>NA</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                        {/* <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Forms in Progress</label>
                                                    <select className="form-control">
                                                        <option selected="">Please Select</option>
                                                        <option>NA</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Choose a Form Template</label>
                                                {/* <select className="form-control" onChange={handleFormTempChange}>
                                                        <option selected="">Please Select</option>
                                                        {allFormTemplates.map((data,index)=>{
                                                        return(
                                                    <option key={index} value={data?._id}>{data.name}</option>
                                                        )})}
                                                    </select> */}
                                                <Select
                                                    // className={`w-100`} 
                                                    className={`w-100 ${errors?.formtemplate && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, formtemplate: null }) }}
                                                    id='individualFormTemplate'
                                                    value={selectedTemplate}
                                                    onChange={handleFormTempChange}
                                                    options={documentsToSelect}
                                                    placeholder="Please Select"
                                                    name="formtemplate"
                                                    classNamePrefix="select"
                                                />

                                            </div>
                                        </div>
                                        {showFields &&
                                        <>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Lender Name</label>
                                                <select id="individualLenderName" onChange={(e)=>handleChange(e)} className={`form-control ${formErrors.lendername && 'invalid-input'}`} onFocus={() => { setFormErrors({ ...formErrors, lendername: null }) }} value={formData?.lendername || ''} name='lendername' >
                                                    <option selected="">Please Select</option>
                                                    <option value="Easy Street Capital, LLC">Easy Street Capital, LLC</option>
                                                    <option value="Easy Street Capital CA, LLC">Easy Street Capital CA, LLC</option>
                                                    <option value="National Loan Funding LLC">National Loan Funding LLC</option>
                                                </select>
                                            </div>
                                        </div>
                                        {((formData?.lendername === 'Easy Street Capital CA, LLC' || formData?.lendername === 'National Loan Funding LLC')&& FieldsArray?.includes(templateName) ) &&
                                         <div className="col-md-3">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Licence Number</label>
                                                           <input id="licencenumber-individual" onChange={(e)=>handleChange(e)} className={`form-control ${formErrors.entity && 'invalid-input'}`} onFocus={() => { setFormErrors({ ...formErrors, entity: null }) }} value={licenceNumber || ''} type="text" name="licencenumber" disabled={true} placeholder='Licence Number'/>
                                                       </div>
                                                   </div>
                                               </div>}
                                        <div className="col-md-3">
                                            
                                           <div className="form-group ">
                                           <label>State of Formation</label>
                                           <Select
                                            options={stateOptions}
                                            placeholder="Select"
                                            value={formData?.stateofformation ? stateOptions.find(option => option?.value === formData?.stateofformation) : '' }
                                            className={`w-100 ${formErrors.stateofformation && 'invalid-input'}`} onFocus={() => { setFormErrors({ ...formErrors, stateofformation: null }) }}
                                            onChange={(selectedOption) => {
                                                setFormData({...formData, stateofformation : selectedOption?.value});
                                                setDownloadDisable(true);
                                                emptyFileUrlsIndivi();
                                            }}
                                            inputId='individualFormationState'
                                        />
                                                       </div>
                                        </div>
                                        <div className="col-md-3">
                                                   <div className='form'>
                                                       <div className="form-group ">
                                                           <label>Entity</label>
                                                           <input id="individualEntity" onChange={(e)=>handleChange(e)} className={`form-control ${formErrors.entity && 'invalid-input'}`} onFocus={() => { setFormErrors({ ...formErrors, entity: null }) }} value={formData?.entity || ''} type="text" name="entity"  placeholder='Entity'/>
                                                       </div>
                                                   </div>
                                               </div>
                                        </>
                                        
                                        }
                                         {/* <button id="populateIndividualform" onClick={(e) => { handleInndividualForm() }} className="fs_btn">Generate</button> */}
                                         <div className="col-md-6 form_content">
                                        <div className="d-flex">
                                        <div className="form-group d-flex flex-column">
                                        <label className="white_label">Form Content</label>
                                        <button id="populateIndividualform" onClick={(e) => { handleInndividualForm() }} className="fs_btn">Generate</button>
                                         </div>
                                         <div className="form-group" onChange={(e) => { setActiveForm({ ...activeForm, formcontent: e.target.value }) }}>
                                         <label className="white_label">Form Content</label>
                                                <select id="individualFormContent" className="form-control" value={fileType} onChange={(e) => handleFormContentChange(e)}>
                                                    {/* <option selected="">Please Select</option> */}
                                                    <option value="pdf">Generate as PDF</option>
                                                    <option value="word">Generate as Word Doc</option>
                                                </select>
                                            </div>
                                            <div className="form-group" >
                                            <label className="white_label">Form Content</label>
                                        <div className='dsp_flx'>
                                        {/* <button id="populateBtn" disabled={downloadDisablePackage} style={{'opacity' : !downloadDisable ? 1 : 0.6}} onClick={(e) => { fetchContent() }} className="fs_btn">Generate</button> */}
                                            <div className={`form-group  ${downloadDisable? 'disable': ''}`}  >
                                            {/* {fileType !== "word" &&   <button id="downloadIndividualform" onClick={(e) => { previewPDF() }} disabled={downloadDisable} style={{marginRight: '10px'}}  className="border_btn">Preview</button>} */}
                                            <button id="downloadIndividualform" onClick={(e) => { SaveFile() }} disabled={downloadDisable} className="border_btn">Download</button>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                        </div>


                                       
                                        {/* <div className="col-md-3">
                                        <label className='mb_5'>&nbsp;</label>
                                            <div className="form-group">
                                                <button href="#" onClick={(e) => { e.preventDefault(); SaveFile() }} disabled={downloadDisable} style={{'opacity' : !downloadDisable ? 1 : 0.6}} className="fs_btn">Download</button>
                                            </div>
                                        </div> */}
                                        <hr className="hr" />
                                        {/* <h3 className="title">SAVE FORMS</h3>
                                    
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Form Content</label>
                                                    <select className="form-control">
                                                        <option selected="">Please Select</option>
                                    <option value="1">Save in Progress</option>
                                    <option value="2" className="showDueDate">Save As Need To Sign</option>
                                    <option value="3" className="showDueDate">Save As Need To Sign - Internal</option>
                                    <option value="4">Save as PDF</option>
                                    <option value="5">Save as Word Doc</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <a href="#" className="fs_btn">Save</a>
                                                </div>
                                              
                                            </div> */}
                                        <div className="col-md-8">

                                            {/* <EditorEdit content={content} setContent={setContent} editorValidate={editorValidate} setEditorValidate={setEditorValidate} ReadOnlyflag={true}/> */}

                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                {individualPdfLink ?
                                            //      <object 
                                            //      data={configdata?.common?.resourcesCloudfront+packagePdfLink+'#toolbar=0'}
                                            //      type="application/pdf" 
                                            //      width="100%" 
                                            //      height="550px">
                                            //      <p>Link to Document
                                            //          <a target='_blank' rel="noopener noreferrer" href={configdata?.common?.resourcesCloudfront+packagePdfLink}>to the PDF!</a>
                                            //      </p>
                                            //  </object>
                                            <div style={{ padding: '20px' }}>
                                            {/* <h1>Document Preview</h1> */}
                                                        {/* <div style={{ textAlign: 'center', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                                                            
                                                                <>
                                                                    <DocViewer
                                                                        style={{ width: '100%', height: '100%' }}
                                                                        pluginRenderers={DocViewerRenderers}
                                                                    config={{
                                                                        header: { disableHeader: true },
                                                                    }}
                                                                        documents={[
                                                                            { uri:configdata?.common?.resourcesCloudfront+packagePdfLink ,fileType: 'pdf',
                                                                                // fileName: props.name
                                                                             }  // Local File
                                                                          ]}
                                                                    />
                                                                </>
                                                          
                                                            
                                                        </div> */}
                                            <PDFPreview fileUrl={configdata?.common?.resourcesCloudfront+individualPdfLink} />
                                          </div> : ''
                                                }
                                               
                                           {/* <iframe src={configdata?.common?.resourcesCloudfront+packagePdfLink} title="W3Schools Free Online Web Tutorials"></iframe> */}

                                                {/* <EditorEdit ref={editorRef} content={content2} setContent={setContent2} editorValidate={editorValidate} setEditorValidate={setEditorValidate} ReadOnlyflag={true}/> */}
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default ProcessingForms;