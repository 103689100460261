import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import { useLoanInfo } from '../utils/loanInfoContext';
import { servicingMenus, dealMenus, processingMenus, underwritingMenus, closingMenus, drawsMenus } from '../JsonData/MenusData';
import { hasViewPermission } from '../utils/permissionsCheck';
import { getUserDataFromLocalStorage } from '../utils/local';
import { useDispatch, useSelector } from 'react-redux';
import { getLoansData } from '../redux/reducers/Actions';
import { usePipeline } from '../utils/PipelineContext';
const PipelineHeader = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const loansData = useSelector(state => state.loansdata);
  const dispatch = useDispatch();
  const [loans, setLoans] = useState(loansData);
  const { loanInfo, setLoanInfo } = useLoanInfo();
  let userData = getUserDataFromLocalStorage();
  const [pipelinePermissions, setPipelinePermissions] = useState( userData?.securityInfo?.pipelinePermissions);
  const {CheckStatusAPi,setCheckStatusApi ,initializeStatus} = usePipeline(); 
  const params = new URLSearchParams(location.search);
        const loanId = params.get('loanid');
  const [loanIdParam,setLoanIdParam] = useState(loanId);
 

//   const servicingMenus = [
//     { name: "Loan Summary", route: "/servicingloansummary", icon: "summarize" },
//     { name: "Servicing Setup", route: "/servicingservicingsetup", icon: "settings" },
//     { name: "Recurring Billing", route: "/piplineinprogress", icon: "repeat" },
//     { name: "Fee Management", route: "/feemanagement", icon: "attach_money" },
//     { name: "Payments", route: "/servicespayments", icon: "payment" },
//     { name: "Payoff", route: "/servicespayoff", icon: "paid" },
//     { name: "Servicing History", route: "/servicinghistory", icon: "history" },
//     { name: "Form 1098", route: "/servicesform1098", icon: "description" },
//   ];

//   const dealMenus = [
//     { name: 'Loan Details', route: '/dealdetailedapp', icon: 'space_dashboard' },
//     { name: 'Contacts', route: '/dealcontacts', icon: 'contacts' },
//     { name: 'HMDA', route: '/dealhmda', icon: 'demography' },
//     { name: 'Lead', route: '/deallead', icon: 'social_leaderboard' },
//     { name: 'Underwriting Term Sheet', route: '/dealunderwritingtermsheet', icon: 'border_color' },
// ];

// const processingMenus = [
//     { name: 'Forms', route: '/processingforms', icon: 'assignment' },
//     { name: 'Credit', route: '/dealcredit', icon: 'payments' },
//     { name: 'Files', route: '/dealfiles', icon: 'folder_copy' },
//     { name: 'Tools And Workflow', route: '/dealtoolsworkflow', icon: 'service_toolbox' },
// ];

// const underwritingMenus = [
//     { name: 'Borrower Exposure', route: '/borrowerexposure', icon: 'business_center' },
//     { name: 'Underwriting Term Sheet', route: '/underwritingtermsheet', icon: 'assignment' }
// ];

// const closingMenus = [
//     { name: 'Clear To Close', route: '/cleartoclose', icon: 'assignment' },
//     { name: 'Fees', route: '/dealfees', icon: 'paid' },
//     { name: 'Rate And Points', route: '/dealratepoints', icon: 'monitoring' },
//     { name: 'Wire Instructions', route: '/instructions', icon: 'import_export' }
// ];
    
// const drawsMenus = [
//     { name: 'Loan Summary', route: '/drawloansummary', icon: 'assignment' },
//     { name: 'Order Draw Request', route: '/orderdrawrequest', icon: 'inventory' },
//     { name: 'Supporting Docs', route: '/supportingdocs', icon: 'description' },
//     { name: 'Draw Requests Status', route: '/drawrequestsstatus', icon: 'insert_drive_file' },
//     { name: 'Draw History', route: '/orderdrawhistory', icon: 'history' }
// ];

      useEffect(() => {
        // Check if token is present in local storage
        const token = localStorage.getItem('accessToken');
  
        if (!token) {
          // Redirect to the login page
          window.location.href = '/login'; // Replace with your login page route
        }
        const params = new URLSearchParams(location.search);
        const loanId = params.get('loanid');
        setLoanIdParam(loanId)
      }, []); 

      const isActive = (path) => {
        // Check if path is an array
        if (Array.isArray(path)) {
          // Return true if any path in the array matches the current location's pathname
          return path.some(singlePath => location.pathname === singlePath);
        }
        // Otherwise, treat path as a single string and compare directly
        return location.pathname === path;
      };

      const handleNavigation = (path, key) => {
        //setLoanInfo(key);
         //addToRecent(key)
         //navigate(path+"/"+key.loanid);
         navigate(path+"?loanid="+loanIdParam || loanInfo.loanid);
     };

     const trimString = (str) => {
      
        return str?.replace(/[()]/g, '').replace(/&/g, '').replace(/-/g, '').replace(/\s+/g, '').trim();
      };
      const refreshLoan = () => {
        setLoanInfo({
          propertyinfo: {}, 
          strategyinfo: {},
          borrowerinfo: {},
          dealinfo: {},
          requestor:''
        });
        initializeStatus()
        // setCheckStatusApi(true);
        dispatch(getLoansData([]));  // Dispatch loan data to state

    }


  
    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let checkedPermission =  hasViewPermission(cleanRoute, pipelinePermissions) 
              console.log('checkedPermission',location.pathname,checkedPermission);  
              if(!checkedPermission){
                navigate('/pipeline')
              }
            }
      }, []);

  return (
    <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">

                        <div className="navbar-brand-box">
                            <a className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src="assets/images/brand/logo-light.png" alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src="assets/images/brand/logo-light.png" alt="" height="17" />
                                </span>
                            </a>

                            <a className="logo logo-light">
                                <span className="logo-sm">
                                    <img src="assets/images/brand/logo-light.png" alt="" height="7" />
                                </span>
                                <span className="logo-lg">
                                    <img src="assets/images/brand/logo-light.png" alt="" height="30" />
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="menu">
                        {hasViewPermission('dashboard', pipelinePermissions) && <div className="dropdown d-inline-block">
                            <button id="dashboardMenu" type="button" onClick={(e) => {e.preventDefault(); handleNavigation('/dashboard');}} className={`btn header-item ${isActive('/dashboard') ? 'active' : ''}`} 
                                aria-haspopup="true" aria-expanded="false"> <span
                                    className="material-symbols-outlined icon">space_dashboard </span> Dashboard </button>
                        </div>}
                        {hasViewPermission('deal', pipelinePermissions) && <div className="dropdown d-inline-block">
                            <button id="dealMenu" type="button" className={`btn header-item ${isActive(['/deallead', '/dealdetailedapp', '/dealcontacts', '/dealhmda', '/dealunderwritingtermsheet']) ? 'active' : ''}`} data-bs-toggle="dropdown">
                                <span className="material-symbols-outlined icon">handshake </span>
                                Deal
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                             {dealMenus.map((item, index) => hasViewPermission(item?.route, pipelinePermissions) &&(
                              <a id={`deal-${trimString(item.name)}`} key={index} className="dropdown-item" href={item.route+"?loanid="+loanIdParam || loanInfo.loanid} onClick={(e) => {
                                  e.preventDefault();
                                  handleNavigation(item.route);
                                  const parentDropdownMenu = e.currentTarget.closest('.dropdown-menu');
                                  if (parentDropdownMenu) {
                                      parentDropdownMenu.classList.remove('show');
                                  }
                              }}>
                                  <span className="material-symbols-outlined icon">
                                      {item.icon}
                                  </span>
                                  {item.name}
                              </a>
                          ))}
                            </div>
                        </div>}
                        {hasViewPermission('underwriting', pipelinePermissions) && <div className="dropdown d-inline-block">
                            <button id="underwritingMenu" type="button" className={`btn header-item ${isActive(['/borrowerexposure', '/underwritingtermsheet', '/underwritingfiles','/dealtoolsworkflow']) ? 'active' : ''}`} data-bs-toggle="dropdown">
                                <span className="material-symbols-outlined icon">signature </span>
                                Underwriting
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                          {underwritingMenus.map((item, index) => hasViewPermission(item?.route, pipelinePermissions) &&(
                              <a id={`underwriting-${trimString(item.name)}`} key={index} className="dropdown-item" href={item.route+"?loanid="+loanIdParam || loanInfo.loanid} onClick={(e) => {
                                  e.preventDefault();
                                  handleNavigation(item.route);
                                  const parentDropdownMenu = e.currentTarget.closest('.dropdown-menu');
                                  if (parentDropdownMenu) {
                                      parentDropdownMenu.classList.remove('show');
                                  }
                              }}>
                                  <span className="material-symbols-outlined icon">
                                      {item.icon}
                                  </span>
                                  {item.name}
                              </a>
                          ))}
                                
                            </div>

                        </div>}
                        {hasViewPermission('processing', pipelinePermissions) && <div className="dropdown d-inline-block">
                            <button id="processingMenu" type="button" className={`btn header-item ${isActive(['/processingforms', '/dealcredit', '/dealfiles', ]) ? 'active' : ''}`} data-bs-toggle="dropdown">
                                <span className="material-symbols-outlined icon">article_shortcut </span>
                                Processing
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                            {processingMenus.map((item, index) => hasViewPermission(item?.route, pipelinePermissions) && (
                              <a id={`processing-${trimString(item.name)}`} key={index} className="dropdown-item" href={item.route+"?loanid="+loanIdParam || loanInfo.loanid} onClick={(e) => {
                                  e.preventDefault();
                                  handleNavigation(item.route);
                                  const parentDropdownMenu = e.currentTarget.closest('.dropdown-menu');
                                  if (parentDropdownMenu) {
                                      parentDropdownMenu.classList.remove('show');
                                  }
                              }}>
                                  <span className="material-symbols-outlined icon">
                                      {item.icon}
                                  </span>
                                  {item.name}
                              </a>
                          ))}
                            </div>

                        </div>}
                        {hasViewPermission('closingandfunding', pipelinePermissions) && <div className="dropdown d-inline-block">
                            <button id="closingFundingMenu" type="button" className={`btn header-item ${isActive(['/cleartoclose', '/dealfees', '/dealratepoints', '/instructions']) ? 'active' : ''}`} data-bs-toggle="dropdown">
                                <span className="material-symbols-outlined icon">task </span>
                                Closing and Funding
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                              <div className="dropdown-menu dropdown-menu-end">
                          {closingMenus.map((item, index) => hasViewPermission(item?.route, pipelinePermissions) && (
                              <a id={`closingFunding-${trimString(item.name)}`} key={index} className="dropdown-item" href={item.route+"?loanid="+ loanIdParam || loanInfo.loanid} onClick={(e) => {
                                  e.preventDefault();
                                  handleNavigation(item.route);
                              }}>
                                  <span className="material-symbols-outlined icon">
                                      {item.icon}
                                  </span>
                                  {item.name}
                              </a>
                          ))}
                                
                            </div>
                           
                        </div>}
                        {/* <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item" data-bs-toggle="dropdown">
                                <span className="material-symbols-outlined icon">payments </span>
                                Funding
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                          {fundingMenus.map((item, index) => (
                              <a key={index} className="dropdown-item" href="#" onClick={(e) => {
                                  e.preventDefault();
                                  navigate(item.route);
                              }}>
                                  <span className="material-symbols-outlined icon">
                                      {item.icon}
                                  </span>
                                  {item.name}
                              </a>
                          ))}
                                
                            </div>
                        </div> */}
                        {hasViewPermission('servicing', pipelinePermissions) &&<div className="dropdown d-inline-block">
                            <button id="servicingMenu" type="button" className={`btn header-item ${isActive(['/servicingtoolsworkflow','/servicingcontacts','/servicingloansummary', '/servicingservicingsetup', '/feemanagement', '/servicespayments','/servicesloanmodification', '/servicespayoff', '/servicinghistory', '/servicesform1098','/servicesrecurringbills']) ? 'active' : ''}`} data-bs-toggle="dropdown">
                                <span className="material-symbols-outlined icon">style </span>
                                Servicing
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                          {servicingMenus.map((item, index) => hasViewPermission(item?.route, pipelinePermissions) &&(
                              <a id={`servicing-${trimString(item.name)}`} key={index} className="dropdown-item" href={item.route+"?loanid="+loanIdParam || loanInfo.loanid} onClick={(e) => {
                                  e.preventDefault();
                                  handleNavigation(item.route);
                                  const parentDropdownMenu = e.currentTarget.closest('.dropdown-menu');
                                  if (parentDropdownMenu) {
                                      parentDropdownMenu.classList.remove('show');
                                  }
                              }}>
                                  <span className="material-symbols-outlined icon">
                                      {item.icon}
                                  </span>
                                  {item.name}
                              </a>
                          ))}
                                
                            </div>

                        </div>}
                        
                        {/* <div className="dropdown d-inline-block">
                            <button type="button" className="btn header-item" data-bs-toggle="dropdown">
                                <span className="material-symbols-outlined icon">import_contacts </span>
                                Pricing
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                            {pricingMenus.map((item, index) => (
                              <a key={index} className="dropdown-item" href="#" onClick={(e) => {
                                  e.preventDefault();
                                  navigate(item.route);
                              }}>
                                  <span className="material-symbols-outlined icon">
                                      {item.icon}
                                  </span>
                                  {item.name}
                              </a>
                          ))}
                          </div>
                        </div> */}
                        {hasViewPermission('draws', pipelinePermissions) &&<div className="dropdown d-inline-block">
                            <button id="drawsMenu" type="button" className={`btn header-item ${isActive(['/drawloansummary','/orderdrawrequest', '/supportingdocs', '/drawrequestsstatus', '/orderdrawhistory']) ? 'active' : ''}`} data-bs-toggle="dropdown">
                                <span className="material-symbols-outlined icon">import_contacts </span>
                                Draws
                                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                            {drawsMenus.map((item, index) => hasViewPermission(item?.route, pipelinePermissions) &&(
                              <a id={`draws-${trimString(item.name)}`} key={index} className="dropdown-item" href={item.route+"?loanid="+loanIdParam || loanInfo.loanid} onClick={(e) => {
                                  e.preventDefault();
                                  handleNavigation(item.route);
                                  const parentDropdownMenu = e.currentTarget.closest('.dropdown-menu');
                                  if (parentDropdownMenu) {
                                      parentDropdownMenu.classList.remove('show');
                                  }
                              }}>
                                  <span className="material-symbols-outlined icon">
                                      {item.icon}
                                  </span>
                                  {item.name}
                              </a>
                          ))}
                          </div>
                        </div>}
                    </div>
                    <div className='hed_btns'>
                    <button id="searchLoan" type='btn' className='hed_btn'> <span className="material-symbols-outlined icon" data-bs-toggle="modal"
                                        data-bs-target="#searchpopup" data-bs-whatever="@mdo"> search </span> </button>
                                        <div className='apps_menu'>
                    <button className="hed_btn dropdown-toggle" type="button" id="defaultDropdown" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                        <span className="material-symbols-outlined icon"> apps </span>
                    </button>
                        <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                   
                          <li> <button id="appsDescription" onClick={() => { navigate('/notes?loanid=' + loanIdParam || loanInfo.loanid); }} type="button" className="dsb_btn">Notes <span
                              className="material-symbols-outlined icon"> description </span></button></li>
                          <li> <button id="appsTasks" type="button" onClick={() => { navigate('/tasks?loanid='+ loanIdParam || loanInfo.loanid); }} className="dsb_btn">Tasks <span className="material-icons icon"> checklist
                          </span></button></li>
                          <li>  <button id="appsNeeds" type="button" onClick={() => { navigate('/needs?loanid='+ loanIdParam ||loanInfo.loanid); }} className="dsb_btn mb-0">Needs <span className="material-icons icon">
                              format_list_bulleted </span></button></li>
                        </ul>
              
                                                </div>
                    </div>
       
                    <button id="closePipelineBtn" className="close_btn" onClick={(e) => {e.preventDefault(); refreshLoan(); navigate('/pipeline');}}><span className="material-icons icon"> close </span></button>
                </div>
            </header>
  );
};

export default PipelineHeader;