import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate,useLocation } from 'react-router-dom';
import { addDefaultNeedGroup} from "../utils/reducer";
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
const AddDefaultNeedGroup = () => {
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const loading = useSelector((state) => state.loaderVisible);
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.type;
    const defaultNeed = location.state?.needGroups;
    const mode = location?.state?.mode;
    const [needGroup , setNeedGroup] = useState( defaultNeed ? { ...defaultNeed, type: 'group', mode:'update' } : { type: 'group', mode:'create'});
   const [errors , setErrors] = useState({});
   const [popupMessage, setmessage] = useState( 'Form successfully submitted.');
    const [needBool , setNeedBool] = useState(false);
    const [needGroupOld,setNeedGroupOld] = useState(location.state?.needGroups);
    const getNeedGroupsData = location?.state?.needGroupsData;
    console.log('getNeedGroupsData' , getNeedGroupsData);

    const stagesOptions = [
        { value: '', label: 'Please Select' },
        { value: 'Account Executive Review', label: 'Account Executive Review' },
        { value: 'Underwriting', label: 'Underwriting' },
        { value: 'Term Sheet Issued', label: 'Term Sheet Issued' },
        { value: 'Processing', label: 'Processing' },
        { value: 'Reviewed/Requested', label: 'Reviewed/Requested' },
        { value: 'On Hold', label: 'On Hold' },
        { value: 'Docs Out', label: 'Docs Out' },
        { value: 'Funded', label: 'Funded' },
        { value: 'Closed', label: 'Closed' },
        { value: 'Cancelled', label: 'Cancelled' },
        { value: 'Terms Not Issued', label: 'Terms Not Issued' },
        { value: 'Servicing Setup', label: 'Servicing Setup' },
        { value: 'Servicing', label: 'Servicing' },
      ];
    const foldersOptions = [
        { value: '', label: 'Please Select' },
        { value: 'Active Balance Sheet', label: 'Active Balance Sheet' },
        { value: 'Active Balance Sheet - Foreclosure', label: 'Active Balance Sheet - Foreclosure' },
        { value: 'Active Sold - 1Sharpe/FCI', label: 'Active Sold - 1Sharpe/FCI' },
        { value: 'Active Sold - Churchill/FCI', label: 'Active Sold - Churchill/FCI' },
        { value: 'Active Sold - EF/BSI', label: 'Active Sold - EF/BSI' },
        { value: 'Active Sold - Fidelis/FCI', label: 'Active Sold - Fidelis/FCI' },
        { value: 'Active Sold - Reigo/BSI', label: 'Active Sold - Reigo/BSI' },
        { value: 'Cancelled', label: 'Cancelled' },
        { value: 'EF REO', label: 'EF REO' },
        { value: 'NLF - Active Balance Sheet', label: 'NLF - Active Balance Sheet' },
        { value: 'NLF - Active Sold - EF/BSI', label: 'NLF - Active Sold - EF/BSI' },
        { value: 'NLF - Active Sold - Fidelis/FCI', label: 'NLF - Active Sold - Fidelis/FCI' },
        { value: 'NLF - Paid Balance Sheet', label: 'NLF - Paid Balance Sheet' },
        { value: 'NLF - Paid Sold - Fidelis/FCI', label: 'NLF - Paid Sold - Fidelis/FCI' },
        { value: 'NLF - Paid Sold EF/BSI', label: 'NLF - Paid Sold EF/BSI' },
        { value: 'Paid Balance Sheet', label: 'Paid Balance Sheet' },
        { value: 'Paid Sold - 1Sharpe/FCI', label: 'Paid Sold - 1Sharpe/FCI' },
        { value: 'Paid Sold - EF REO', label: 'Paid Sold - EF REO' },
        { value: 'Paid Sold - Fidelis/FCI', label: 'Paid Sold - Fidelis/FCI' },
        { value: 'Paid Sold - Peer Street', label: 'Paid Sold - Peer Street' },
        { value: 'Paid Sold - Reigo/BSI', label: 'Paid Sold - Reigo/BSI' },
        { value: 'Paid Sold Churchill/FCI', label: 'Paid Sold - Churchill/FCI' },
        { value: 'Paid Sold EF/BSI', label: 'Paid Sold - EF/BSI' },
        { value: 'Pipeline Campaign', label: 'Pipeline Campaign' },
        { value: 'Pre-Close', label: 'Pre-Close' },
        { value: 'REO', label: 'REO' },
        { value: 'Sold Released - Palladius', label: 'Sold Released - Palladius' },
        { value: 'Sold Released - Trinity', label: 'Sold Released - Trinity' },
        { value: 'Sold REO', label: 'Sold REO' },
      ];
    const milestonesOptions = [
        { value: '', label: 'Please Select' },
        { value: 'Critical', label: 'Critical' },
        { value: 'Major', label: 'Major' },
        { value: 'Minor', label: 'Minor' },
        { value: 'Normal', label: 'Normal' },
      ];
    const phasesOptions = [
        { value: '', label: 'Please Select' },
        { value: 'Lead', label: 'Lead' },
        { value: 'Pre-Approval', label: 'Pre-Approval' },
        { value: 'Credit Pulled', label: 'Credit Pulled' },
        { value: 'Initial Submission', label: 'Initial Submission' },
        { value: 'Lock Requested', label: 'Lock Requested' },
        { value: 'Manual Lock Requested', label: 'Manual Lock Requested' },
        { value: 'Locked', label: 'Locked' },
        { value: 'Change Lock Requested', label: 'Change Lock Requested' },
        { value: 'Lock Withdrawn', label: 'Lock Withdrawn' },
        { value: 'Set Up', label: 'Set Up' },
        { value: 'Conditions', label: 'Conditions' },
        { value: 'Underwriting', label: 'Underwriting' },
        { value: 'Closing', label: 'Closing' },
        { value: 'Closed', label: 'Closed' },
        { value: 'Funded', label: 'Funded' },
        { value: 'Committed to Hedge', label: 'Committed to Hedge' },
        { value: 'Closed - Denied', label: 'Closed - Denied' },
        { value: 'Sold', label: 'Sold' },
        { value: 'Sold - Funds Received', label: 'Sold - Funds Received' },
        { value: 'Clear to Close', label: 'Clear to Close' },
        { value: 'Suspended', label: 'Suspended' },
        { value: 'Cancelled', label: 'Cancelled' },
        { value: 'Withdrawn', label: 'Withdrawn' },
        { value: 'Credit App', label: 'Credit App' },
        { value: 'Credit App Denied', label: 'Credit App Denied' },
        { value: 'Credit App Withdrawn', label: 'Credit App Withdrawn' },
        { value: 'Acquired', label: 'Acquired' },
        { value: 'Use Service Link', label: 'Use Service Link' },
        { value: 'Closed Update REO and Experience', label: 'Closed Update REO and Experience' },
        { value: 'Use TitleEQ', label: 'Use TitleEQ' },
      ];
    const programTypeOptions = [
        { value: '', label: 'Please Select' },
        { value: 'Easy Fix', label: 'Easy Fix' },
        { value: 'Easy Build', label: 'Easy Build' },
        { value: 'Refinance', label: 'Refinance' },
        { value: 'Easy Rent', label: 'Easy Rent' },
        { value: 'MFR/Commercial - Construction or Bridge', label: 'MFR/Commercial - Construction or Bridge' },
      ];
    const loanPurposeOptions = [
        { value: '', label: 'Please Select' },
        { value: 'Acquisition', label: 'Acquisition' },
        { value: 'Purchase', label: 'Purchase' },
        { value: 'Purchase+Rehab', label: 'Purchase+Rehab' },
        { value: 'Refinance+Rehab', label: 'Refinance+Rehab' },
        { value: 'Refinance-Cash Out', label: 'Refinance-Cash Out' },
        { value: 'Refinance-No Cash Out', label: 'Refinance-No Cash Out' },
        { value: 'Buy & Rent', label: 'Buy & Rent' },
        { value: 'Refi 2 Rent', label: 'Refi 2 Rent' },
      ];
    const channelOptions = [
        { value: '', label: 'Please Select' },
        { value: 'TPO – EasyFund', label: 'TPO – EasyFund' },
        { value: 'TPO – Signature Broker', label: 'TPO – Signature Broker' },
        { value: 'Wholesale', label: 'Wholesale' },
        { value: 'Retail', label: 'Retail' },
      ];
      
    const handleChange = (e) => {
        setNeedGroup((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    function compareNeedGroups(){
        const allowedKeys = ['groupName','stage','programType'];
        if (needGroup && needGroupOld) {
            return allowedKeys.every(key => needGroup[key] === needGroupOld[key]);
        }
    }

    useEffect(() => {
        console.log("need group old" , needGroupOld);
        console.log("need group new" , needGroup);
        const validNeedGroup =  compareNeedGroups();
        // console.log("valid need group",validNeedGroup);
        setNeedBool(validNeedGroup);
    },[needGroup])

    const handleSubmit =()=>{
        console.log(needGroup);
        const error = {};
        // const fieldsToCheck =   {'groupName' : 'groupName','stage': 'stage','folder':'folder','milestones': 'milestones','phase': 'phase'}
        const fieldsToCheck =   {'groupName' : 'groupName','stage': 'stage'}
        for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
            if (!needGroup[fieldName]?.trim()) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
            }
            if (fieldName === "groupName" && needGroup?.[fieldName] && Array.isArray(getNeedGroupsData)) {
                const normalizedNeedName = needGroup[fieldName].toLowerCase().replace(/\s+/g, '');
                let nameExists = false;
            
                // Check for duplicates only if adding or if the name differs from the old name
                if (mode && mode === "create") {
                    nameExists = getNeedGroupsData?.some(group => {
                        const normalizedExistingName = typeof group?.groupName === 'string'
                            ? group.groupName.toLowerCase().replace(/\s+/g, '')
                            : '';
                        return normalizedExistingName === normalizedNeedName;
                    });
                }
            
                if (nameExists) {
                    error[fieldName] = "Name Already Exists!";
                }
            }
            
        };

        setErrors(error);
        console.log(error);
        if (Object.keys(error).length === 0) {
            if(data ==='add'){
                console.log('ready to add api call -----> ', needGroup);
            }else if(data === 'update'){
                console.log('ready to update api call -----> ', needGroup);
            }
            
        }else{
            return
        }

        dispatch(showLoader());
        addDefaultNeedGroup(needGroup).then(function (response) {
            dispatch(hideLoader());
            if (response?.result) {
                  setPopupOpen(true)
            }
            }).catch((err) => {
              dispatch(hideLoader());
              console.error.bind("my account error", err);
            })
    }

    const closePopup = () => {
        setPopupOpen(false);
        navigate('/needslist', { state: { from: 'adddefaultneedGroup' } })
    };
      
    return (
        <div id="layout-wrapper">
{loading ? <Loader/> : null} 
        {isPopupOpen && (
            <PopupMessage
             type={popupMessage ==  'Please try with new reset link' ? 'warning' : 'success'}
             message = { mode === "edit" ? "Need Group Updated" : "Need Group Added" }
            //  message={popupMessage}
             onClose={closePopup}
            />
        )}

           <MainHeader />


            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management add_default">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">{ mode === "edit" ? "Update" : "Add" } Default Need Group</h4>
                                <a href="#" onClick={(e) => {e.preventDefault(); navigate('/needslist', { state: { from: 'adddefaultneedGroup' } });}} className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Group Name</label>
                                                    <input name='groupName' value={needGroup?.groupName} onChange={handleChange} type="text" placeholder="Items Needed From Borrower"
                                                        className={`form-control ${errors.groupName && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, groupName: null }) }} />
                                                    {errors?.groupName && errors?.groupName === "Name Already Exists!" && <p style={{color : 'red'}}>{errors?.groupName}</p>}
                                                </div>
                                            </div>
                                            

                                        </div>
                                        <div className="row">
                                        <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Stage</label>
                                                    <select name='stage' value={needGroup?.stage} onChange={handleChange} className={`form-control ${errors.stage && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, stage: null }) }}   placeholder="Please Select Option" inputId="selectNeedGroupStage" >
                                                        {stagesOptions.map((data,index)=>(
                                                            <option value={data.value}>{data.label}</option>
                                                        ))}
                                                       
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                       
                                        {/* <hr className="hr" /> */}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>Program Type (Optional)</label>
                                                    <select name='programType' value={needGroup?.programType} onChange={handleChange} className="form-control" placeholder="Please Select Option" inputId="selectNeedProgramType">
                                                    {programTypeOptions.map((data,index)=>(
                                                            <option value={data.value}>{data.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                        <div className="btn-group mt_10">
                                            {}
                                            <button onClick={(e)=>{e.preventDefault();handleSubmit()}} className="fs_btn" style = {{'opacity' : (needBool === true) ? '0.6' : '1'}} disabled = {needBool} >{ mode === "edit" ? "Update" : "Submit" }</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal popup fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Checklist</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div className="modal-body">

                                    <div className="form-group">
                                        <label>Line Item name</label>
                                        <div className="dsp_flx">
                                            <input type="text" placeholder="Enter" className="form-control" />
                                            <button type="button" className="fs_btn ml_20">Add</button>
                                        </div>
                                    </div>

                                    <p><b>Checklist</b> <br />
                                        Begin creating your checklist by typing the <span className="color">Line item name
                                        </span> and clicking<span className="color"> Add</span> button.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal message_popup popup fade" id="messagepopup" tabindex="-1"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">

                                <div className="modal-body">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                    <span className="material-symbols-outlined icon"> delete </span>
                                    <h3>Delete</h3>
                                    <p>This action cannot be undone. <br />
                                        Do you want to continue?</p>
                                    <button type="button" className="fs_btn">Yes</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default AddDefaultNeedGroup;