import React, { useState ,useRef ,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoanInfo } from '../utils/loanInfoContext';
import CurrencyInput from 'react-currency-input-field';
import LocationSearch from './LocationsSeachBox';
import { useSelector} from 'react-redux';
import { formatNumericInput} from '../utils/commonUtils';
import WarmAppLocationSearch from './WarmAppLocationSearch';

let formElementsJsoneasybuild = [
  {
    "type": "text",
    "label": "What was the purchase price?",
    "placeholder": "Enter the price",
    "name": "purchaseprice",
    "id" : "purchasePriceRefinanceBuild",
    "icon" : "payments"
  },
  {
    "type": "locationsearch",
    "label": "In what area are you looking?",
    "placeholder": "Search City and Zip Code",
    "name": "propertyinfoplace",
    "id" : "locationBorrower"
  },
  {
    "type": "date",

    "label": "When did you purchase the property?",
    "placeholder": "mm/dd/yyyy",
    "name": "purchasedate",
    "id" : "purchaseDateRefinanceBuild"
  },
  {
    "type": "text",
    "label": "Total building square footage",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaRefinanceBuild",
    "icon" : "crop"
  },
  {
    "type": "text",
    "label": "Total lot square footage",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaRefinanceBuild",
    "icon" : "crop"
  },
  {
    "type": "number",
    "label": "Number of bedrooms",
    "name": "totalbedrooms",
    "id" : "totalBedroomsRefinanceBuild",
    //"icon" : "bed"
  },
  {
    "type": "number",
    "label": "Number of bathrooms",
    "name": "totalbathrooms",
    "id" : "totalBathroomsRefinanceBuild",
    //"icon" : "bathtub"
  },
  {
    "type": "dropdown",
    "label": "Pool?",
    "options": [
      { "value": "", "label": "Select pool" },
      { "value": "Yes", "label": "Yes" ,"id" : "poolYesRefinanceBuild"},
      { "value": "No", "label": "No" ,"id" : "poolNoRefinanceBuild"},
    ],
    "name": "haspool"
  },
  
  {
    "type": "dropdown",
    "label": "Did you finish building the property?",
    "options": [
      { "value": "", "label": "Select" },
      { "value": "Yes", "label": "Yes" ,"id" : "finishedBuildingYesRefinanceBuild"},
      { "value": "No", "label": "No" ,"id" : "finishedBuildingNoRefinanceBuild"},
    
    ],
    "name": "finishedbuilding",
    
  },
  {
    "type": "dropdown",
    "label": "Need new refinance loan for construction funding?",
    "options": [
      { "value": "", "label": "Select" },
      { "value": "Yes", "label": "Yes" ,"id" : "constructionFundingYesRefinanceBuild"},
      { "value": "No", "label": "No" ,"id" : "constructionFundingNoRefinanceBuild"},

    ],
    "name": "constructionfunding",
   
  },
  {
    "type": "text",
    "label": "How much is the existing loan?",
    "placeholder": "Enter price",
    "name": "existingloan",
    "id" : "existingLoanRefinanceBuild"
  },
  {
    "type": "text",
    "label": "What is the current value?",
    "placeholder": "Enter price",
    "name": "currentvalue",
    "id" : "currentValueRefinanceBuild"
  },
  {
    "type": "text",
    "label": "What is the completed value?",
    "placeholder": "Enter price",
    "name": "completedvalue",
    "id" : "completedRefinanceBuild",
    "icon" : "payments"
  },
  {
    "type": "date",
    "label": "What is the target closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionCompletedRefinanceBuild"
  },
  {
    "type" : "dropdown",
    "label" : "Occupancy status",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ],
    
  },
  {
    "type": "buttonGroup",
    "label": "Add property as collateral",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "collateralpropertyYesRefinanceBuild"},
      { "value": "No", "label": "No" ,"id" : "collateralpropertyNoRefinanceBuild"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]
let formElementsJsoneasyfix = [
  {
    "type": "chooseOption",
    "label": "What property type would this be?",
    // "options": [
    //   { "value": "Single Family Residence (SFR - 1 Unit)", "label": "Single Family Residence (SFR - 1 Unit)","img":'assets/images/icons/easy-fix1.png' , "id" : "singleRefinancingFix" },
    //   { "value": "Duplex", "label": "Duplex", "img":'assets/images/icons/easy-fix2.png', "id" : "duplexRefinancingFix" },
    //   { "value": "Triplex", "label": "Triplex", "img":'assets/images/icons/easy-fix3.png' , "id" : "triplexRefinancingFix" },
    //   { "value": "Fourplex", "label": "Fourplex", "img":'assets/images/icons/easy-fix4.png' , "id" : "fourplexRefinancingFix"},
    //   { "value": "5 Units+", "label": "5 Units+", "img":'assets/images/icons/easy-fix5.png' , "id" : "unitsRefinancingFix"},
    //   { "value": "Townhouse or Condo", "label": "Townhouse or Condo", "img":'assets/images/icons/easy-fix6.png' , "id" : "townRefinancingFix"},
    // ],
    "options": [
    {
        "value": "Single Family Residence (SFR - 1 Unit)",
        "label": "Single Family Residence",
        "img": "assets/images/new-deal/single-family-residence.svg",
        "active_img": "assets/images/Property Info/single_family_residence_active.svg",
        "id": "singleRefinancingFix"
    },
    {
        "value": "Duplex",
        "label": "Duplex",
        "img": "assets/images/new-deal/Duplex.svg",
        "active_img": "assets/images/Property Info/Duplex_active.svg",
        "id": "duplexRefinancingFix"
    },
    {
        "value": "Triplex",
        "label": "Triplex",
        "img": "assets/images/new-deal/Triplex.svg",
        "active_img": "assets/images/Property Info/Triplex_active.svg",
        "id": "triplexRefinancingFix"
    },
    {
        "value": "Fourplex",
        "label": "Fourplex",
        "img": "assets/images/new-deal/Fourplex.svg",
        "active_img": "assets/images/Property Info/Fourplex_active.svg",
        "id": "fourplexRefinancingFix"
    },
    {
        "value": "5 Units+",
        "label": "5 Units+",
        "img": "assets/images/new-deal/5_units.svg",
        "active_img": "assets/images/Property Info/5_units_active.svg",
        "id": "unitsRefinancingFix"
    },
    {
        "value": "Townhouse or Condo",
        "label": "Townhouse or Condo",
        "img": "assets/images/new-deal/Townhouse.svg",
        "active_img": "assets/images/Property Info/Town_house_active.svg",
        "id": "townRefinancingFix"
    }
    ],

    "name": "propertytype",
   
  },
  {
    "type": "locationsearch",
    "label": "In what area are you looking?",
    "placeholder": "Search City and Zip Code",
    "name": "propertyinfoplace",
    "id" : "locationBorrower"
  },
  {
    "type": "text",
    "label": "What was the purchase price?",
    "placeholder": "Enter the price",
    "name": "purchaseprice",
    "id" : "purchasePriceRefinanceFix",
    "icon" : "payments"
  },
  {
    "type": "date",
    "label": "When did you purchase the property?",
    "placeholder": "mm/dd/yyyy",
    "name": "purchasedate",
    "id" : "purchaseDateRefinanceFix"
  },
  {
    "type": "number",
   
    "label": "Year built",
    "name": "yearbuilt",
    "id" : "yearBuiltRefinanceFix"
  },
  {
    "type": "text",
    "label": "Total building square footage",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaRefinanceFix",
    "icon" : "crop"
  },
  {
    "type": "text",
    "label": "Total lot square footage",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaRefinanceFix",
    "icon" : "crop"
  },
  {
    "type": "number",
    "label": "Number of bedrooms",
    "name": "totalbedrooms",
    "id" : "totalBedroomsRefinanceFix",
    //"icon" : "bed"
  },
  {
    "type": "number",
    "label": "Number of bathrooms",
    "name": "totalbathrooms",
    "id" : "totalBathroomsRefinanceFix",
    //"icon" : "bathtub"
  },
  
  {
    "type": "dropdown",
    "label": "Pool?",
    "options": [
      { "value": "", "label": "Select pool" },
      { "value": "Yes", "label": "Yes" ,"id" : "poolYesRefinanceFix"},
      { "value": "No", "label": "No" ,"id" : "poolNoRefinanceFix"},
     
    ],
    "name": "haspool"
  },
  
  {
    "type": "dropdown",
    "label": "Did you renovate the property?",
    "options": [
      { "value": "", "label": "Select" },
         { "value": "Yes", "label": "Yes" ,"id" : "renovatedYesRefinanceFix"},
      { "value": "No", "label": "No" ,"id" : "renovatedNoRefinanceFix"},
    ],
    "name": "renovatedproperty"
  },
  {
    "type": "text",
    "label": "What was the cost of the renovation?",
    "placeholder": "Renovation cost",
    "name": "renovationcost",
    "id" : "renovationCostRefinanceFix"
  },
  {
    "type": "dropdown",
    "label": "Need rehab loan funding?",
    "options": [
      { "value": "", "label": "Select" },
      { "value": "Yes", "label": "Yes" , "id" : "rehabFundingYesRefinanceFix"},
      { "value": "No", "label": "No" , "id" : "rehabFundingNoRefinanceFix"},
     
    ],
    "name": "refinanceloanrehabfunding" 
  },
  {
    "type": "text",
    "label": "How much is the remaining rehab budget?",
    "placeholder": "Remaining rehab budget",
    "name": "remainingrehabbudget",
    "id" : "rehabBudgetRefinanceFix"
  },
  {
    "type": "text",
    "label": "How much is the existing loan?",
    "placeholder": "Enter price",
    "name": "existingloan",
    "id" : "existingLoanRefinanceFix"
  },
  {
    "type": "text",
    "label": "What is the current value?",
    "placeholder": "Enter price",
    "name": "currentvalue",
    "id" : "currentValueRefinanceFix"
  },
  {
    "type": "text",
    "label": "What is the completed value?",
    "placeholder": "Enter price",
    "name": "completedvalue",
    "id" : "completedRefinanceFix",
    "icon" : "payments"
  },
  {
    "type": "date",
    "label": "What is the target closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionCompletedRefinanceFix"
  },
  {
    "type" : "dropdown",
    "label" : "Occupancy status",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ]
  },
  {
    "type": "buttonGroup",
    "label": "Add property as collateral",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "collateralpropertyYesRefinanceFix"},
      { "value": "No", "label": "No" ,"id" : "collateralpropertyNoRefinanceFix"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]
let formElementsJsoneasybuildpurchase = [
  {
    "type": "chooseOption",
    "label": "What property type would this be?",
    // "options": [
    //   { "value": "Single Family Residence (SFR - 1 Unit)", "label": "Single Family Residence (SFR - 1 Unit)","img":'assets/images/Property Info/single_family_residence.svg' , "id" : "singlePurchasingBuild" },
    //   { "value": "Duplex", "label": "Duplex", "img":'assets/images/Property Info/Duplex.svg' , "id" : "duplexPurchasingBuild"},
    //   { "value": "Triplex", "label": "Triplex", "img":'assets/images/Property Info/Triplex.svg'  , "id" : "triplexPurchasingBuild" },
    //   { "value": "Fourplex", "label": "Fourplex", "img":'assets/images/Property Info/Fourplix.svg' , "id" : "fourplexPurchasingBuild"},
    //   { "value": "5 Units+", "label": "5 Units+", "img":'assets/images/Property Info/5_units.svg' , "id" : "unitsPurchasingBuild"},
    //   { "value": "Townhouse or Condo", "label": "Townhouse or Condo", "img":'assets/images/Property Info/Town_house.svg' , "id" : "townPurchasingBuild"},
    // ],
    "options": [
        {
            "value": "Single Family Residence (SFR - 1 Unit)",
            "label": "Single Family Residence",
            "img": "assets/images/new-deal/single-family-residence.svg",
            "active_img": "assets/images/Property Info/single_family_residence_active.svg",
            "id": "singlePurchasingBuild"
        },
        {
            "value": "Duplex",
            "label": "Duplex",
            "img": "assets/images/new-deal/Duplex.svg",
            "active_img": "assets/images/Property Info/Duplex_active.svg",
            "id": "duplexPurchasingBuild"
        },
        {
            "value": "Triplex",
            "label": "Triplex",
            "img": "assets/images/new-deal/Triplex.svg",
            "active_img": "assets/images/Property Info/Triplex_active.svg",
            "id": "triplexPurchasingBuild"
        },
        {
            "value": "Fourplex",
            "label": "Fourplex",
            "img": "assets/images/new-deal/Fourplex.svg",
            "active_img": "assets/images/Property Info/Fourplix_active.svg",
            "id": "fourplexPurchasingBuild"
        },
        {
            "value": "5 Units+",
            "label": "5 Units+",
            "img": "assets/images/new-deal/5_units.svg",
            "active_img": "assets/images/Property Info/5_units_active.svg",
            "id": "unitsPurchasingBuild"
        },
        {
            "value": "Townhouse or Condo",
            "label": "Townhouse or Condo",
            "img": "assets/images/new-deal/Townhouse.svg",
            "active_img": "assets/images/Property Info/Town_house_active.svg",
            "id": "townPurchasingBuild"
        }
    ],
    "name": "propertytype"
  },
  {
    "type": "locationsearch",
    "label": "In what area are you looking?",
    "placeholder": "Search City and Zip Code",
    "name": "propertyinfoplace",
    "id" : "locationBorrower"
  },
  {
    "type": "text",
    "label": "What was the purchase price?",
    "placeholder": "Enter price",
    "name": "purchaseprice",
    "id" : "purchasePricePurchaseBuild",
    "icon" : "payments"
  },
  {
    "type": "text",
    "label": "What is the construction budget?",
    "placeholder": "Enter price",
    "name": "constructionbudget",
    "id" : "constructionBudgetPurchaseBuild",
    "icon" : "payments"
  },
  
  {
    "type": "text",
    "label": "Total building square footage",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaPurchaseBuild",
    "icon" : "crop"
  },
  {
    "type": "text",
    "label": "Total lot square footage",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaPurchaseBuild",
    "icon" : "crop"

  },
  {
    "type": "number",
    "label": "Number of bedrooms",
    "name": "totalbedrooms",
    "id" : "totalBedroomsPurchaseBuild",
    //"icon" : "bed"
  },
  {
    "type": "number",
    "label": "Number of bathrooms",
    "name": "totalbathrooms",
    "id" : "totalBathroomsPurchaseBuild",
    //"icon" : "bathtub"
  },
  {
    "type": "dropdown",
    "label": "Pool?",
    "options": [
      { "value": "", "label": "Select" },
      { "value": "Yes", "label": "Yes" ,"id" : "poolYesPurchaseBuild"},
      { "value": "No", "label": "No" ,"id" : "poolNoPurchaseBuild"},
      
    ],
    "name": "haspool"
  },
  
  {
    "type": "text",
    "label": "What is the completed value?",
    "placeholder": "Enter price",
    "name": "completedvalue",
    "id" : "completedPurchaseBuild",
    "icon" : "payments"
  },
  {
    "type": "date",
    "label": "What is the target closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionPurchaseBuild"
  },
  {
    "type" : "dropdown",
    "label" : "Occupancy status",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ]
  },
  {
    "type": "buttonGroup",
    "label": "Add property as collateral",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "collateralpropertyYesPurchaseBuild"},
      { "value": "No", "label": "No" ,"id" : "collateralpropertyNoPurchaseBuild"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]
let formElementsJsoneasyfixpurchase = [
  {
    "type": "chooseOption",
    "label": "What property type would this be?",
    // "options": [
    //   { "value": "Single Family Residence (SFR - 1 Unit)", "label": "Single Family Residence (SFR - 1 Unit)","img":'assets/images/icons/easy-fix1.png', "id" : "singlePurchasingFix"  },
    //   { "value": "Duplex", "label": "Duplex", "img":'assets/images/icons/easy-fix2.png' , "id" : "duplexPurchasingFix"},
    //   { "value": "Triplex", "label": "Triplex", "img":'assets/images/icons/easy-fix3.png' , "id" : "triplexPurchasingFix"},
    //   { "value": "Fourplex", "label": "Fourplex", "img":'assets/images/icons/easy-fix4.png' , "id" : "fourplexPurchasingFix"},
    //   { "value": "5 Units+", "label": "5 Units+", "img":'assets/images/icons/easy-fix5.png' , "id" : "unitsPurchasingFix"},
    //   { "value": "Townhouse or Condo", "label": "Townhouse or Condo", "img":'assets/images/icons/easy-fix6.png' , "id" : "townPurchasingFix"},
    // ],
    "options": [
    {
        "value": "Single Family Residence (SFR - 1 Unit)",
        "label": "Single Family Residence",
        "img": "assets/images/new-deal/single-family-residence.svg",
        "active_img": "assets/images/Property Info/single_family_residence_active.svg",
        "id": "singlePurchasingFix"
    },
    {
        "value": "Duplex",
        "label": "Duplex",
        "img": "assets/images/new-deal/Duplex.svg",
            "active_img": "assets/images/Property Info/Duplex_active.svg",
        "id": "duplexPurchasingFix"
    },
    {
        "value": "Triplex",
        "label": "Triplex",
        "img": "assets/images/new-deal/Triplex.svg",
        "active_img": "assets/images/Property Info/Triplex_active.svg",
        "id": "triplexPurchasingFix"
    },
    {
        "value": "Fourplex",
        "label": "Fourplex",
        "img": "assets/images/new-deal/Fourplex.svg",
        "active_img": "assets/images/Property Info/Fourplix_active.svg",
        "id": "fourplexPurchasingFix"
    },
    {
        "value": "5 Units+",
        "label": "5 Units+",
        "img": "assets/images/new-deal/5_units.svg",
        "active_img": "assets/images/Property Info/5_units_active.svg",
        "id": "unitsPurchasingFix"
    },
    {
        "value": "Townhouse or Condo",
        "label": "Townhouse or Condo",
        "img": "assets/images/new-deal/Townhouse.svg",
        "active_img": "assets/images/Property Info/Town_house_active.svg",
        "id": "townPurchasingFix"
    }
    ],

    "name": "propertytype"
  },
  {
    "type": "locationsearch",
    "label": "In what area are you looking?",
    "placeholder": "Search City and Zip Code",
    "name": "propertyinfoplace",
    "id" : "locationBorrower"
  },
  {
    "type": "text",
    "label": "What was the purchase price?",
    "placeholder": "Enter price",
    "name": "purchaseprice",
    "id" : "purchasePricePurchaseFix",
    "icon" : "payments"
  },
  {
    "type": "text",
    "label": "What is the rehab budget?",
    "placeholder": "Enter price",
    "name": "construction_rehab_budget",
    "id" : "rehabBudgetPurchaseFix",
      "icon" : "payments"
  },
  {
    "type": "dropdown",
    "label": "Need rehab loan funding?",
    "options": [
      { "value": "", "label": "Select" },
      { "value": "Yes", "label": "Yes" ,"id" : "rehabFundYesPurchaseBuild"},
      { "value": "No", "label": "No" ,"id" : "rehabFundNoPurchaseBuild"},

    ],
    "name": "refinanceloanrehabfunding"
  },
  {
    "type": "text",
    "label": "Total building square footage",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "propertyAreaPurchaseFix",
    "icon" : "crop"
  },
  {
    "type": "text",
    "label": "Total lot square footage",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "lotAreaPurchaseFix",
    "icon" : "crop"
  },
  {
    "type": "number",
    "label": "Year built",
    "name": "yearbuilt",
    "id" : "yearBuiltPurchaseFix"
  },
  {
    "type": "number",
    "label": "Number of bedrooms",
    "name": "totalbedrooms",
    "id" : "totalBedroomsPurchaseFix",
    //"icon" : "bed"
  },
  {
    "type": "number",
    "label": "Number of bathrooms",
    "name": "totalbathrooms",
    "id" : "totalBathroomsPurchaseFix",
    //"icon" : "bathtub"
  },
  
  {
    "type": "dropdown",
    "label": "Pool?",
    "options": [
      { "value": "", "label": "Select pool" },
      { "value": "Yes", "label": "Yes" ,"id" : "poolYesPurchaseFix"},
      { "value": "No", "label": "No" ,"id" : "poolNoPurchaseFix"},
    
    ],
    "name": "haspool"
  },
  
  {
    "type": "text",
    "label": "What is the after-renovated value?",
    "placeholder": "Enter price",
    "name": "renovationarv",
    "id" : "renovationArvPurchaseFix",
    "icon" : 'payments'
  },
  {
    "type": "date",
    "label": "What is the target closing date?",
    "placeholder": "mm/dd/yyyy",
    "name": "constructioncompleteddate",
    "id" : "constructionPurchaseFix"
  },
  {
    "type" : "dropdown",
    "label" : "Occupancy status",
    "name" : "occupancy",
    "id" : "occupancyStatus",
    "options" : [
      { "value": "", "label": "Select" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ]
  },
  {
    "type": "buttonGroup",
    "label": "Add property as collateral",
    "options": [
      
      { "value": "Yes", "label": "Yes" ,"id" : "collateralpropertyYesPurchaseFix"},
      { "value": "No", "label": "No" ,"id" : "collateralpropertyNoPurchaseFix"}
    ],
    "name": "collateraladditionalproperty",
   
  },
]


let collateralJSON = [
  {
    "type": "buttonGroup",
    "label": "Is there a current loan on the property?",
    "options": [
      { "value": "Yes", "label": "Yes" ,"id" : "collateralCurrentLoanYes"},
      { "value": "No", "label": "No" ,"id" : "collateralCurrentLoanNo"},
      
    ],
    "name": "currentloan"
  },
  {
    "type": "text",
    "label": "What is the existing loan amount?",
    "placeholder": "Enter the price",
    "name": "existingloan",
    "id" : "collateralExistingLoan",
    "icon" : "payments"
  },
  {
    "type" : "dropdown",
    "label" : "What is the proposed lien position?",
    "name" : "proposedlienposition",
    "id" : "collatrealProposedLienPosition",
    "options" : [
      { "value": "", "label": "Select" },
      { "value": "1st Position", "label": "1st Position" },
      { "value": "2nd Position", "label": "2nd Position" },
    ]
  },
  {
    "type": "number",
    "label": "Year built",
    "name": "collateralyearbuilt",
    "id" : "collateralYearBuilt"
  },
 
  {
    "type": "number",
    "label": "Number of bedrooms",
    "name": "totalbedrooms",
    "id" : "collateralTotalBedrooms",
    //"icon" : "bed"
  },
  {
    "type": "number",
    "label": "Number of bathrooms",
    "name": "totalbathrooms",
    "id" : "collateralTotalBathrooms",
    //"icon" : "bathtub"
  },

  {
    "type": "dropdown",
    "label": "Pool?",
    "options": [
      { "value": "", "label": "Select pool" },
      { "value": "Yes", "label": "Yes" ,"id" : "collateralPoolYes"},
      { "value": "No", "label": "No" ,"id" : "collateralpoolNo"},
  
    ],
    "name": "haspool"
  },
   {
    "type": "text",
    "label": "Total building square footage",
    "placeholder": "Sq. Ft",
    "name": "propertyarea",
    "id" : "collateralPropertyArea",
    "icon" : "crop"
  },
  {
    "type": "text",
    "label": "Total lot square footage",
    "placeholder": "Sq. Ft",
    "name": "lotarea",
    "id" : "collateralLotArea",
    "icon" : "crop"
  },
 
  {
    "type" : "dropdown",
    "label" : "Occupancy status",
    "name" : "occupancy",
    "id" : "collateralOccupancyStatus",
    "options" : [
      { "value": "", "label": "Select" },
      { "value": "Delivered Vacant", "label": "Delivered Vacant" },
      { "value": "COE + 7 days", "label": "COE + 7 days" },
      { "value": "COE + 30 Days", "label": "COE + 30 Days" },
      { "value": "Tenant Occupied", "label": "Tenant Occupied" },
      { "value": "Unknown", "label": "Unknown" },
    ],
    
  },
  {
    "type": "text",
    "label": "What is the estimated value?",
    "placeholder": "0.00",
    "name": "estimatedvalue",
    "id" : "collateralEstimatedValue",
    "icon" : "payments"
  },
  {
    "type": "address",
    "label": "What area are you looking?",
    "placeholder": "Enter address",
    "name": "collateralpropertyPlace",
    "id" : "collateralCoborrowerAddress"
  },
  
]


const RefinancingBuild = () => {
    const navigate = useNavigate();
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const [dealInfo, setDealInfo] = useState(loanInfo?.dealinfo ? loanInfo?.dealinfo : {});
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    const [formJSON, setformjson] = useState((loanInfo?.propertyinfo?.loanfor == "Refinance" && loanInfo?.propertyinfo?.programtype == 'EASY BUILD') ? formElementsJsoneasybuild : (loanInfo?.propertyinfo?.loanfor == "Refinance" && loanInfo?.propertyinfo?.programtype == 'EASY FIX') ? formElementsJsoneasyfix : (loanInfo?.propertyinfo?.loanfor == "Purchase" && loanInfo?.propertyinfo?.programtype == 'EASY BUILD') ? formElementsJsoneasybuildpurchase :formElementsJsoneasyfixpurchase);
    const dateInputRef = useRef(null);
    const [showCollateral, setShowCollateral] = useState(false);
    const [collateralData, setCollateralData] = useState(loanInfo?.collateralproperty || {});
    const [collateralFlag, setCollateralFlag] = useState(loanInfo?.collateralFlag || false);

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [addressData,setAddressData] = useState({ });

    const [coBorrowerSelectedPlace, setCoBorrowerSelectedPlace] = useState(null);
    const [coBorrowerAddressData, setCoBorrowerAddressData] = useState({});
  
    const configdata = useSelector(state => state.configdata);
    const mapsKey = configdata?.common?.googleMapsKey;
    let floatKeys  = [
      'purchaseprice',
      'existingloan',
      'currentvalue',
      'completedvalue',
      'renovationcost',
      'remainingrehabbudget',
      'constructionbudget',
      'construction_rehab_budget',
      'renovationarv'
    ]

    const handleCurrencyInputs = (name,value)=>{
        
        console.log(value)
        floatKeys.forEach(key => {
          if(key === name){
            let updatedValue = isNaN(value) ? '' : value;
            setDealInfo((prevData) => {
              // Create a copy of the previous data
              let updatedData = { ...prevData };
              // Update the copied data with the new key-value pair
              updatedData = {
                ...updatedData,
                [key]: updatedValue,
              };
          
              console.log('valuevaluevalue',loanInfo);
              return updatedData;
            }); 
          }
        })
        
      
    }
    
    const handleInputChange = (e) => {
        e.preventDefault();
        // Update the formData with the new key-value pair
       let key = e.target.name;
       let value = e.target.value;
       console.log(value);
       const allowedKeys = ['purchaseprice', 'existingloan', 'completedvalue', 'currentvalue', 'renovationcost', 'remainingrehabbudget','construction_rehab_budget','constructionbudget', 'renovationarv'];
     
      //  let numberTypeRegex = /^[1-9][0-9]*$/
      //  if (allowedKeys.includes(key) && value !== '') {
      //   // Remove non-numeric characters and prepend '$'
      //   let numericValue = value.replace(/[^0-9]/g, '');
      //   let formattedValue = `$${Number(numericValue).toLocaleString()}`;
  
      //   let finalformatVal = (formattedValue === "$0") ? '' : formattedValue;
      //   value = finalformatVal;
      //  }
      const numberTypeRegex = /^[0-9]*\.?[0-9]*$/;
      if (allowedKeys.includes(key) && value !== '') {
        // Remove non-numeric characters and prepend '$'
        // if(value.startsWith('$')){
        //   let replace = value.replace(/\$/g,'');
        //   // let replace = value;
        //   value = (numberTypeRegex.test(replace)) && (value !== "0")? '$' + formatPrice(replace) : '';
        // }else{
        //   value = (numberTypeRegex.test(value)) && (value !== "0")? '$' + formatPrice(value) : '';
        // }
        let formattedAmount = formatPrice(value);
        value = formattedAmount;
        // if(formattedAmount.startsWith('$')){
        //   let replace = formattedAmount.replace(/\$/g,'');
        //   // let replace = value;
        //   value = (formattedAmount !== "0" )? '$' + replace : '';
        // }else{
        //   value = (formattedAmount !== "0")? '$' + formattedAmount : '';
        // }
      }

       const allowedNumberInputKeys = ['lotarea','propertyarea'];
    
       const numRegex = /^(?!.*(0{2,}|[^0]\b0\b))\d*$/;
       if (allowedNumberInputKeys.includes(key) && value !== '') {
        // Remove non-numeric characters and prepend '$'
        console.log('valuevalue111',value);
        // let numValue =  (value === "0") ? '' : value;
      
        value = formatNumericInput(value);;
       }
       console.log('valuevalue',value);

          //date validation
          const allowedDateInputKeys = ['purchasedate','constructioncompleteddate','yearbuilt'];
          // const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
          const yearRegex = /^[0-9]{4}$/;
          const currentYear = new Date().getFullYear(); 
          // console.log(currentYear); // Output: 2022
          if(key === "constructioncompleteddate" && allowedDateInputKeys.includes(key) && value !== ''){
            // console.log("date ",value);
              // let yearSplit = value.split("-");
              // console.log("date",value.split("-"));
              // console.log(yearRegex.test(yearSplit[0]));
              // let dateValue = !(yearRegex.test(yearSplit[0]) === true) || yearSplit[0] < currentYear ? '' : value;
              // value = dateValue;
              // Check if year and month values are less than or equal to 4 digits
              // Extract year from the input date, ignoring leading zeros
              
              const [year,month,day] = value.split('-');
                // console.log("split date",year?.length);
              if (year?.length > 4) {
                // console.log("Year or month value exceeds 4 digits.");
                value = '';
              }
              
                 
              

              value = value;
          }else if(key === "purchasedate" && allowedDateInputKeys.includes(key) && value !== ''){
            let yearSplit = value.split("-");
            // console.log("date",value.split("-"));
            console.log(yearRegex.test(yearSplit[0]));
            let dateValue = !(yearRegex.test(yearSplit[0]) === true) || yearSplit[0] > currentYear ? '' : value;
            value = dateValue;
          }else if(key === "yearbuilt" && allowedDateInputKeys.includes(key) && value !== ''){
              // const isValidYear = /^\d{4}$/.test(value);
              if (value > currentYear) {
                value = '';
              } else {
                value = value;
              }
            
            
          }

          //type numbervalidation
          // const allowedNumberTypeInputs = ['totalbathrooms','totalbedrooms'];
          // const singleDigitRegex = /^[0-9]$/;
          // if(allowedNumberTypeInputs.includes(key) && value !== ''){
          //     let numVal = !(singleDigitRegex.test(value) === true) ? '' : value;
          //     value = numVal;
          // }


       if (parseFloat(value) < 0) {
        // If it's negative, set the input value to an empty string or handle it as needed
        value = '';
      }
        setDealInfo((prevData) => {
          // Create a copy of the previous data
          let updatedData = { ...prevData };
          // Update the copied data with the new key-value pair
          updatedData = {
            ...updatedData,
            [key]: value,
          };
      
          console.log('valuevaluevalue',loanInfo);
          return updatedData;
        });
    };

    // const handleButtonChange = (key, value) => {

      
    //     // Update the formData with the new key-value pair
    //     setDealInfo((prevData) => {
    //       // Create a copy of the previous data
    //       let updatedData = { ...prevData };
    //       // Update the copied data with the new key-value pair
    //       updatedData = {
    //         ...updatedData,
    //         [key]: value,
    //       };
    //       console.log('updatedDatahere',updatedData);
    //       setLoanInfo((prevDealInfo) => ({
    //         ...prevDealInfo,
    //         dealinfo: updatedData,
    //       }));
      
    //       return updatedData;
    //     });

        

         
        
    //};
    const valueUnits = {
      "Single Family Residence (SFR - 1 Unit)": 1,
      "Duplex": 2,
      "Triplex": 3,
      "Fourplex": 4,
      "5 Units+": 5,
      "Townhouse or Condo": 1,
      "Lot/New Construction": 1
  };
  const handleButtonChange = (key, value) => {
    console.log('key', key, 'value', value)
    setDealInfo((prevData) => {
      // Create a copy of the previous data
      let updatedData = { ...prevData };
      if (key === 'propertytype') {
        console.log(valueUnits[value])
        updatedData = {
          ...updatedData,
          [key]: value,
          numberofunits: valueUnits[value] || ''
        };
      } else {
        updatedData = {
          ...updatedData,
          [key]: value,
        };
      }




      setLoanInfo((prevDealInfo) => ({
        ...prevDealInfo,
        dealinfo: updatedData,
        iscollateralflag: collateralFlag,
        collateralproperty: collateralData
      }));
      return updatedData;
    });
  };
    
    //dropdown inputs
    const handleDropdownChange = (e) =>{

      e.preventDefault();
        // Update the formData with the new key-value pair
       let key = e.target.name;
       let value = e.target.value;
       console.log(value);
      setDealInfo((prevData) => {
        // Create a copy of the previous data
        let updatedData = { ...prevData };
        // Update the copied data with the new key-value pair
        updatedData = {
          ...updatedData,
          [key]: value,
        };
    
        console.log('valuevaluevalue',loanInfo);
        return updatedData;
      });
    }

    const handleDealData = ()=>{
      setLoanInfo((prevDealInfo) => ({
        ...prevDealInfo,
        dealinfo: dealInfo,
        iscollateralflag:collateralFlag,
        collateralproperty : collateralData
      }));
    }

   
    // const validateInputs = () => {
    //   const requiredInputs = document.querySelectorAll('.select_box input');
    //   const buttonGroups = document.querySelectorAll('.btn-group');
    //   const chooseBoxes = document.querySelectorAll('.rf_boxs');
    //   const chooseDropdowns = document.querySelectorAll('.select_box select');
    //   const searchWrpElement = document.querySelector(".search_wrp");
    
    //   let isValid = true;
    
    //   // Function to remove error styles for an input
    //   const removeErrorStyles = (input) => {
    //     input.classList.remove('invalid-input');
    //   };
    
    //   // Function to remove error styles for a button group
    //   const removeGroupErrorStyles = (group) => {
    //     group.classList.remove('invalid-group');
    //   };

    //     // Function to remove error styles for a choose option boxes group
    //     const removeBoxErrorStyles = (box) => {
    //       box.classList.remove('invalid-box');
    //     };
    
    //   // Add event listeners to remove error styles on input focus/change
    //   requiredInputs.forEach((input) => {
    //     input.addEventListener('input', () => removeErrorStyles(input));
    //   });
    
    //   // Add event listeners to remove error styles on button click
    //   buttonGroups.forEach((group) => {
    //     const buttons = group.querySelectorAll('.select-btn');
    //     buttons.forEach((button) => {
    //       button.addEventListener('click', () => removeGroupErrorStyles(group));
    //     });
    //   });

    //     // Add event listeners to remove error styles on box click
    //     chooseBoxes.forEach((rfbox) => {
    //       const boxes = rfbox.querySelectorAll('.rf_boxs');
    //       boxes.forEach((bx) => {
    //         bx.addEventListener('click', () => removeBoxErrorStyles(rfbox));
    //       });
    //   });

    //   // Add event listeners to remove error styles on dropdown
    //   chooseDropdowns.forEach((selectDropdown) => {
        
    //     selectDropdown.addEventListener('change', () => removeErrorStyles(selectDropdown));
    //   });
    
    //   // Validate inputs and button groups
    //   const allowedKeys = ['constructioncompleteddate','purchaseprice', 'existingloan', 'completedvalue', 
    //   'currentvalue', 'renovationcost', 'remainingrehabbudget' , 'purchasedate' ,'collateralyearbuilt' ,'yearbuilt'];
    //   // const dateInputs = ['constructioncompleteddate'];

    //   for (const input of requiredInputs) {
    //     const inputValue = input.value.trim();

    //     if (input?.name !== "constructioncompleteddate" && input?.name !== "purchasedate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && !inputValue) {
    //       // Handle empty input case
    //       input.classList.add('invalid-input');
    //       isValid = false;
    //     } else if (input.name !== "constructioncompleteddate" && input?.name !== "purchasedate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && allowedKeys.includes(input.name) && inputValue === '$0') {
    //       // Handle the case where the value is $0 for allowed keys
    //       input.classList.add('invalid-input');
    //       isValid = false;
    //     }else if(input?.name === "constructioncompleteddate" && input?.name !== "purchasedate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && (!input?.value || input?.value < currentDate)){
    //       // console.log("date input",input?.value);
    //       input.classList.add('invalid-input');
    //       isValid = false;
    //     }else if(input?.name === "purchasedate" && input?.name !== "constructioncompleteddate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && (!input?.value || input?.value > currentDate)){
    //       // console.log("date input",input?.value);
    //       input.classList.add('invalid-input');
    //       isValid = false;
    //     }else if((input?.name === "collateralyearbuilt" || input?.name === "yearbuilt")&& (!input?.value || inputValue.length < 4)){
    //       // console.log("date input",input?.value);
    //       input.classList.add('invalid-input');
    //       isValid = false;
    //     }
      
      
       
    //   }

     
    
    //   for (const group of buttonGroups) {
    //     const buttons = group.querySelectorAll('.cp_btn');
    //     const selectedButtons = Array.from(buttons).filter(btn => btn.style.background !== '');
    
    //     if (selectedButtons.length === 0) {
    //       group.classList.add('invalid-group');
    //       isValid = false;
    //     }
    //   }

    //     for (const box of chooseBoxes) {
    //       const boxes = box.querySelectorAll('.box');
    //       const selectedBoxes = Array.from(boxes).filter(bx => bx.style.background !== '');
      
    //       if (selectedBoxes.length === 0) {
    //         box.classList.add('invalid-box');
    //       isValid = false;
    //     }
    //   }

    //   for (const selectDropdown of chooseDropdowns) {
    //     if (!selectDropdown.value) {
    //       selectDropdown.classList.add('invalid-input');
    //       isValid = false;
    //     }
    //   }

     
    //   if (!collateralData["propertyaddress"] || selectedPlace?.trim() === "") {
       
    //     const searchWrpElement = document.querySelector(".search_wrp");
    
    //     if (searchWrpElement) {
    //       isValid = false;
    //       searchWrpElement.classList.add("invalid-input");
    //     }
    //   }
      
    //   return isValid;
    // };

    const validateInputs = () => {
      const requiredInputs = document.querySelectorAll('.form-control'); // Changed to match your input class
      //const buttonGroups = document.querySelectorAll('.radio-box');
      const chooseBoxes = document.querySelectorAll('.radios-wrapper'); // Updated to match the radio box class
      const chooseDropdowns = document.querySelectorAll('.form-control'); // This might need to be differentiated
      //const searchWrpElement = document.querySelector(".search_wrp");
      //const searchWrpElement = document.getElementsByName("propertyinfoplace")[0]; // Select by name
      const inputElement  = document.getElementsByName("collateralAddress")[0]; // Select by name
      const inputElement2  = document.getElementsByName("propertyinfoplace")[0]; // Select by name
      let isValid = true;
  
      // Function to remove error styles for an input
      const removeErrorStyles = (input) => {
          input.classList.remove('invalid-input');
      };
  
      // Function to remove error styles for a button group
      const removeGroupErrorStyles = (group) => {
          group.classList.remove('invalid-group');
      };
  
      // Function to remove error styles for a choose option box
      const removeBoxErrorStyles = (box) => {
          box.classList.remove('invalid-box');
      };
  
      // Add event listeners to remove error styles on input focus/change
      requiredInputs.forEach((input) => {
          input.addEventListener('input', () => removeErrorStyles(input));
      });
  
      // Add event listeners to remove error styles on button click
      // buttonGroups.forEach((group) => {
      //     const buttons = group.querySelectorAll('.radio-box'); // Updated to match your button class
      //     buttons.forEach((button) => {
      //         button.addEventListener('click', () => removeGroupErrorStyles(group));
      //     });
      // });

      // Add event listeners to remove error styles on box click
      chooseBoxes.forEach((rfbox) => {
          const boxes = rfbox.querySelectorAll('.radios-wrapper');
          boxes.forEach((bx) => {
            bx.addEventListener('click', () => removeBoxErrorStyles(rfbox));
          });
      });
  
      // Add event listeners to remove error styles on dropdown change
      chooseDropdowns.forEach((selectDropdown) => {
          selectDropdown.addEventListener('change', () => removeErrorStyles(selectDropdown));
      });

      
  
      // Validate inputs
      const allowedKeys = ['constructioncompleteddate', 'purchaseprice', 'existingloan', 'completedvalue',
          'currentvalue', 'renovationcost', 'remainingrehabbudget', 'purchasedate', 'collateralyearbuilt', 'yearbuilt'];
  
      for (const input of requiredInputs) {
          const inputValue = input.value.trim();
  
          if (input?.name !== "constructioncompleteddate" && input?.name !== "purchasedate" && input?.name !== "collateralyearbuilt" && input?.name !== "yearbuilt" && !inputValue) {
              // Handle empty input case
              input.classList.add('invalid-input');
              isValid = false;
          } else if (allowedKeys.includes(input.name) && inputValue === '$0') {
              // Handle the case where the value is $0 for allowed keys
              input.classList.add('invalid-input');
              isValid = false;
          } else if (input?.name === "constructioncompleteddate" && (!input?.value || input?.value < currentDate)) {
              input.classList.add('invalid-input');
              isValid = false;
          } else if (input?.name === "purchasedate" && (!input?.value || input?.value > currentDate)) {
              input.classList.add('invalid-input');
              isValid = false;
          } else if ((input?.name === "collateralyearbuilt" || input?.name === "yearbuilt") && (!input?.value || inputValue.length < 4)) {
              input.classList.add('invalid-input');
              isValid = false;
          }
      }
  
      // for (const group of buttonGroups) {
      //     const buttons = group.querySelectorAll('.radio-box'); // Updated to match your button class
      //     const selectedButtons = Array.from(buttons).filter(btn => btn.classList.contains('active')); // Check for active class
  
      //     if (selectedButtons.length === 0) {
      //         group.classList.add('invalid-group');
      //         isValid = false;
      //     }
      // }
  
      for (const box of chooseBoxes) {
          const boxes = box.querySelectorAll('.block_radiobtn');
          const selectedBoxes = Array.from(boxes).filter(bx => bx.classList.contains('active'));
      
          if (selectedBoxes.length === 0) {
            box.classList.add('invalid-box');
          isValid = false;
        }
      }

      for (const selectDropdown of chooseDropdowns) {
          if (!selectDropdown.value) {
              selectDropdown.classList.add('invalid-input');
              isValid = false;
          }
      }
  
      // if ((!collateralData["propertyaddress"] || selectedPlace?.trim() === "")) {
      //     if (searchWrpElement) {
      //         isValid = false;
      //         searchWrpElement.classList.add("invalid-input");
      //     }
      // }

      if (inputElement) {
        const searchWrpElement = inputElement.closest(".search_wrp"); // Find the closest parent with class "search_wrp"
        
        if (!collateralData["propertyaddress"] || selectedPlace?.trim() === "") {
            if (searchWrpElement) {
                isValid = false;
                searchWrpElement.classList.add("invalid-input"); // Add class to search_wrp
            }
        }
      }

      if (inputElement2) {
        const searchWrpElement2 = inputElement2.closest(".search_wrp"); // Find the closest parent with class "search_wrp"
        
        if (!dealInfo["coborroweraddress"] || coBorrowerSelectedPlace?.trim() === "") {
            if (searchWrpElement2) {
                isValid = false;
                searchWrpElement2.classList.add("invalid-input"); // Add class to search_wrp
            }
        }
      }
  
      return isValid;
  };
  
    

    const submitDealInfo = () => {
      const isValid = validateInputs()
      
      console.log("dealInfohere", isValid, loanInfo);
      console.log('collateral data',collateralData);
      if (isValid) {
        // setLoanInfo((prevDealInfo) => ({
        //   ...prevDealInfo,
        //   dealinfo: dealInfo,
        // }));

        // let updatedSearchData = {
        //   ...loanInfo?.propertyinfo, // Retains existing fields
        //   propertyaddress: loanInfo?.propertyinfo?.propertyaddress?.trim() || loanInfo?.propertyinfo?.propertyfulladdress,
        //   propertyfulladdress: loanInfo?.propertyinfo?.propertyfulladdress,
        //   propertystate: loanInfo?.propertyinfo?.propertystate,
        //   propertyzipcode: loanInfo?.propertyinfo?.propertyzipcode,
        //   propertygeolocation: loanInfo?.propertyinfo?.propertygeolocation,
        //   propertygooglemaplink: loanInfo?.propertyinfo?.propertygooglemaplink,
        //   propertyarea: loanInfo?.propertyinfo?.propertyareaaddress,
        //   propertycity: loanInfo?.propertyinfo?.propertycity,
        //   propertystateshort: loanInfo?.propertyinfo?.propertystateshort,
        //   propertycounty: loanInfo?.propertyinfo?.propertycounty,
        // };
        
        
        // setLoanInfo((prevDealInfo) => {
        //   const newState = {
        //     ...prevDealInfo,
        //     dealinfo: { ...prevDealInfo.dealinfo, ...dealInfo },
        //     iscollateralflag: collateralFlag,
        //     collateralproperty: collateralData,
        //     propertyinfo: updatedSearchData,
        //   };
        //   console.log("Updated LoanInfo:", newState);
        //   return newState;
        // });
        
        

        setLoanInfo((prevDealInfo) => ({
          ...prevDealInfo,
          dealinfo: dealInfo,
          iscollateralflag:collateralFlag,
          collateralproperty : collateralData,
         
        }));
        navigate('/StrategyInfo');
        console.log("valuevaluevalue", loanInfo);
      }
    };
    const getTodayDate = () => {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    };

    // Function to format the price with commas
    const formatPrice = (value) => {
      const valueRep = value.replace(/\D/g, '');
      // return valueRep.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // Format the number with commas
      // Format the number with commas and add a dollar sign
      const formattedValue = `$${Number(valueRep).toLocaleString()}`;

      return formattedValue;
    };


    /* collateral functionality */

    const handleCollateralButton = (key, value) => {

      //const buttonId = id; // Construct button ID if needed
      //removeError(buttonId); // Remove the error class from the button group
      
      const newValue = (value === 'Yes');
  
      // Clear collateralData if the specific condition is met
      if (key === 'collateraladditionalproperty') {
        if (value === 'No') {
            // Clear all entries except collateraladditionalproperty, set its value to 'No'
            setCollateralData(prevCollateralData => ({
                collateraladditionalproperty: 'No'
            }));
            // setShowCollateral(newValue);
            // setCollateralFlag(newValue);
        } else {
            // Update collateraladditionalproperty with the new value
            const updatedCollateralData = { ...collateralData, [key]: value };
            setCollateralData(updatedCollateralData);
            // setShowCollateral(newValue);
            // setCollateralFlag(newValue);
        }
        setShowCollateral(newValue);
        setCollateralFlag(newValue);
      } else {
          // Update collateralData with the new value for other keys
          const updatedCollateralData = { ...collateralData, [key]: value };
          setCollateralData(updatedCollateralData);
      }
  
      //removeError(key);
  
      // Update loanInfo if needed with the new collateral data
      // setLoanInfo((prevLoanInfo) => ({
      //     ...prevLoanInfo,
      //     collateralproperty: collateralData // Make sure to merge correctly
      // }));
    };
  

    const handleCollateralDropdown = (e)=> {
      const {name , value} = e.target;
      // Update the collateralData with the new value
      const updatedCollateralData = { ...collateralData, [name]: value };
  
      setCollateralData(updatedCollateralData); // Update state with new value
  
      // Now pass the updated object to setLoanInfo
      // setLoanInfo((prevLoanInfo) => ({
      //     ...prevLoanInfo,
      //     collateralproperty: updatedCollateralData // Make sure to merge correctly
      // }));
      
      
    }

   
    const handleCollateralChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      //const { name, value } = e.target;
      
      // Log to confirm input handling
      console.log(`Changed ${name}: ${value}`);

      if(name == "collateralyearbuilt"){
        const currentYear = new Date().getFullYear();
        if(value  > currentYear){
            value = '';
        }
      }

      const allowedNumberInputKeys = ['lotarea','propertyarea'];
    
       if (allowedNumberInputKeys.includes(name) && value !== '') {
        // Remove non-numeric characters and prepend '$'
        console.log('valuevalue111',value);
        // let numValue =  (value === "0") ? '' : value;
      
        value = formatNumericInput(value);;
       }
      
      const updatedCollateralData = { ...collateralData, [name]: value };
    
      setCollateralData(updatedCollateralData); // Update state
      
      // Update the loan information state accordingly
      // setLoanInfo((prevLoanInfo) => ({
      //   ...prevLoanInfo,
      //   collateralproperty: updatedCollateralData 
      // }));
    };
    
    const handleCollateralCurrencyInputs = (name,value)=>{
        
      console.log(value)
      //floatKeys.forEach(key => {
        //if(key === name){
          let updatedValue = isNaN(value) ? '' : value;
          // setDealInfo((prevData) => {
          //   // Create a copy of the previous data
          //   let updatedData = { ...prevData };
          //   // Update the copied data with the new key-value pair
          //   updatedData = {
          //     ...updatedData,
          //     [key]: updatedValue,
          //   };
        
          //   console.log('valuevaluevalue',loanInfo);
          //   return updatedData;
          // }); 

          // if (name == "existingloan" && value == "No") {
           
          //   setCollateralData(prevCollateralData => ({
          //     ...prevCollateralData, // Preserve existing data
          //     existingloan: '',
          //   }));
          
          // }
          const updatedCollateralData = { ...collateralData, [name]: updatedValue };
    
          setCollateralData(updatedCollateralData);
          
        //}
      //})
      
    
  }

  const removeError = (buttonId) => {
    // Find the button element using the buttonId
    const button = document.getElementById(buttonId);

    if (button) {
        // Find the parent .btn-group container
        const group = button.closest('.radio-box');

        if (group) {
            group.classList.remove('invalid-group');
        } else {
            console.warn('Button group not found for buttonId:', buttonId);
        }
    } else {
        console.warn('Button not found for buttonId:', buttonId);
    }
  };


  useEffect(() => {
    if(!collateralData?.collateraladditionalproperty){
      setCollateralData(prevCollateralData => ({
        ...prevCollateralData, // Preserve existing data
        collateraladditionalproperty: 'No',
      }));
    }
  },[])

  // useEffect(() => {
  //   if (collateralData?.collateraladditionalproperty === "Yes") {
  //     if(!collateralData?.currentloan){
  //       setCollateralData(prevCollateralData => ({
  //         ...prevCollateralData, // Preserve existing data
  //         existingloan: '',
  //       }));
  //     }
  //   }
    
  // },[collateralData])
    
    
   

  useEffect(() => {
      if (collateralData?.collateraladditionalproperty === "Yes") {
          if(!collateralData?.currentloan){
            setCollateralData(prevCollateralData => ({
              ...prevCollateralData, // Preserve existing data
              currentloan: 'No',
            }));
          }
          
      }else if(collateralData?.collateraladditionalproperty === "No"){
          if(collateralData["propertyaddress"] || selectedPlace?.trim() !== ""){
            setSelectedPlace('');
            // setCollateralData((prevData) => {
            //   // Create a copy of the previous data
            //   let updatedData = { ...prevData };
            //   // Update the copied data with the new key-value pair
            //   let selectedAddress = {
            //     'propertyaddress': addressData?.address?.trim() || addressData?.fullAddress,
            //     'propertystate': addressData?.state,
            //     'propertyzipcode': addressData?.zipcode,
            //     'propertygeolocation': addressData?.geolocation,
            //     'propertygooglemaplink': addressData?.url,
            //     'propertyareaaddress': addressData?.area,
            //     'propertycity': addressData?.city,
            //     'propertyfulladdress': addressData?.fullAddress,
            //     'propertystateshort': addressData?.stateShortName,
            //     'propertycounty': addressData?.county,
            //   };
            //   updatedData = {
            //     ...updatedData,
            //     ...selectedAddress,
            //   };
    
            //   return updatedData;
            // });
          }

          
      }
  }, [collateralData?.collateraladditionalproperty]);

  
  
 

    //collateral address
    useEffect(() => {

      if (collateralData["propertyaddress"] || selectedPlace?.trim() !== "") {
          //const searchWrpElement = document.querySelector(".search_wrp");
          const inputElement = document.getElementsByName("collateralAddress")[0];
          if (inputElement) {
            const searchWrpElement = inputElement.closest(".search_wrp"); 
            if (searchWrpElement) {
              searchWrpElement.classList.remove("invalid-input");
            }
          }
          
      }

      if (selectedPlace) {
        setCollateralData((prevData) => {
          // Create a copy of the previous data
          let updatedData = { ...prevData };
          // Update the copied data with the new key-value pair
          let selectedAddress = {
            'propertyaddress': addressData?.address?.trim() || addressData?.fullAddress,
            'propertystate': addressData?.state,
            'propertyzipcode': addressData?.zipcode,
            'propertygeolocation': addressData?.geolocation,
            'propertygooglemaplink': addressData?.url,
            'propertyareaaddress': addressData?.area,
            'propertycity': addressData?.city,
            'propertyfulladdress': addressData?.fullAddress,
            'propertystateshort': addressData?.stateShortName,
            'propertycounty': addressData?.county,
          };
          updatedData = {
            ...updatedData,
            ...selectedAddress,
          };

          return updatedData;
        });
      }

    }, [selectedPlace]);

    //form json address
    useEffect(() => {

      const inputElement2 = document.getElementsByName("propertyinfoplace")[0];
      if (inputElement2) {
        const searchWrpElement2 = inputElement2.closest(".search_wrp"); 
        if (searchWrpElement2) {
          searchWrpElement2.classList.remove("invalid-input");
        }
      }

      if (!coBorrowerSelectedPlace || coBorrowerSelectedPlace?.trim() === "") {

        if(loanInfo?.propertyinfo?.propertyfulladdress){
          setDealInfo((prevData) => ({
            ...prevData,
            coborroweraddress: loanInfo?.propertyinfo?.propertyaddress?.trim() || loanInfo?.propertyinfo?.propertyfulladdress,
            coborrowerfulladdress: loanInfo?.propertyinfo?.propertyfulladdress,
            coborrowerstate: loanInfo?.propertyinfo?.propertystate,
            coborrowerzipcode: loanInfo?.propertyinfo?.propertyzipcode,
            coborrowergeolocation: loanInfo?.propertyinfo?.propertygeolocation,
            coborrowergooglemaplink: loanInfo?.propertyinfo?.propertygooglemaplink,
            coborrowerarea: loanInfo?.propertyinfo?.propertyareaaddress,
            coborrowercity: loanInfo?.propertyinfo?.propertycity,
            coborrowerstateshort: loanInfo?.propertyinfo?.propertystateshort,
            coborrowercounty: loanInfo?.propertyinfo?.propertycounty,
          }));
          
        }
       
      }
      else{

        
        setDealInfo((prevData) => ({
          ...prevData?.collateralproperty,
          coborroweraddress: coBorrowerAddressData?.address?.trim() || coBorrowerAddressData?.fullAddress,
          coborrowerstate: coBorrowerAddressData?.state,
          coborrowerzipcode: coBorrowerAddressData?.zipcode,
          coborrowergeolocation: coBorrowerAddressData?.geolocation,
          coborrowergooglemaplink: coBorrowerAddressData?.url,
          coborrowerarea: coBorrowerAddressData?.area,
          coborrowercity: coBorrowerAddressData?.city,
          coborrowerfulladdress: coBorrowerAddressData?.fullAddress,
          coborrowerstateshort: coBorrowerAddressData?.stateShortName,
          coborrowercounty: coBorrowerAddressData?.county,
        }));
          
      }
      
      
    }, [coBorrowerSelectedPlace]);

    
  

    return (
      

        <div className="wizard-canvas">
            {/* <a href="#" onClick={(e) => {e.preventDefault(); navigate('/loanProgram');handleDealData()}} className="back material-icons"> keyboard_backspace </a> */}
            <div className="wizard-canvas-header">
                
                {/* <h3> <span>{loanInfo?.propertyinfo
                    ?.programtype?.split(' ')[0]} <span  className="sub">{loanInfo?.propertyinfo
                        ?.programtype?.split(' ').slice(1).join(' ')}</span></span> Property Info
                </h3> */}
                <h3>Property Info</h3>
            </div>
            <div className="wizard-canvas-body wizard-overflow warmapp-propertyinfo">
                {formJSON?.some((element) => element.type === "chooseOption") && (
                  <div className="question_block no-toppad">
                    {formJSON?.map((element, index) => (
                      <div key={index}>
                        {element.type === "chooseOption" && (
                          <>
                            <label className="label">{element.label}</label>
                            <div className="radios-wrapper mt20">
                              {element.options?.map((option) => (
                                <button
                                  type="button"
                                  className={`block_radiobtn ${dealInfo[element.name] === option.value ? "active" : ""}`}
                                  key={option.value}
                                  onClick={() => handleButtonChange(element.name, option.value)}
                                  id={option.id}
                                >
                                  <div className="icon-check">
                                    <img src="assets/images/new-deal/check_circle.svg" className="checked" alt="Check icon" />
                                  </div>
                                  <img src={option?.img} className="default_img" alt="Default" />
                                  {/* <img src={option?.active_img} className="active_img" alt="Active" /> */}
                                  {option.label}
                                </button>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {/* Location Search Section */}
                {formJSON?.some((element) => element.type === "locationsearch") && (
                  <div className="warmappform_box colateral_address mt20">
                    {formJSON
                      ?.filter((element) => element.type === "locationsearch")
                      .map((locationElement, index) => (
                        <div key={index}>
                          <label className="label">What is the address?</label>
                          <div className="ques_rightBlock">
                            <div className="search_wrp">
                              <span className="material-symbols-outlined">location_on</span>
                              <div>
                                {mapsKey && (
                                  <WarmAppLocationSearch
                                    className="wizard-search-input"
                                    name={locationElement.name}
                                    placeholder="Search City and Zip Code"
                                    setCoBorrowerAddressData={setCoBorrowerAddressData}
                                    coBorrowerAddressData={coBorrowerAddressData}
                                    setCoBorrowerSelectedPlace={setCoBorrowerSelectedPlace}
                                    coBorrowerSelectedPlace={dealInfo.coborrowerfulladdress || coBorrowerSelectedPlace}
                                    simpleAddress={true}
                                  />
                                )}
                              </div>
                              <span className="material-symbols-outlined icon-style">search</span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )}

                
              

                {/* Form Fields Inside Question Block */}
                <div className="question_block">
                  <div className="form_wrapper">
                  {formJSON?.map((element, index) => {
                      if (!element.type || element.type === "chooseOption" || 
                        element.name == "collateraladditionalproperty" || element.type === "locationsearch") return null; // Avoid rendering empty divs

                      // Conditionally skip rendering specific fields
                      if (
                        (element.name === "remainingrehabbudget" && dealInfo?.refinanceloanrehabfunding !== "Yes") ||
                        (element.name === "renovationcost" && dealInfo?.renovatedproperty !== "Yes")
                      ) {
                        return null;
                      }

                      return (
                        <div className="warmappform_box" key={index}>
                          {/* Dropdown Handling */}
                          {element.type === "dropdown" ? (
                            <>
                              <label className="label">{element.label}</label>
                              <div className='propertyinfo_select'>
                              <select
                                id={element.id}
                                name={element.name}
                                value={dealInfo[element.name] || ""}
                                className="form-control"
                                onChange={handleDropdownChange}
                              >
                                
                                {element.options?.map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <span className="material-symbols-outlined"> stat_minus_1 </span>
                              </div>
                            </>
                          ) : floatKeys.includes(element.name) ? (
                            <>
                              <label className="label">{element.label}</label>
                              <CurrencyInput
                                id={element.id}
                                name={element.name}
                                placeholder="$0.00"
                                decimalsLimit={2}
                                className="form-control"
                                prefix="$"
                                groupSeparator=","
                                value={dealInfo[element.name] || ""}
                                onValueChange={(value, name, values) => {
                                  handleCurrencyInputs(name, value);
                                }}
                              />
                              {element.icon && <span className="material-icons">{element.icon}</span>}
                            </>
                          ) : (
                            <>
                              {/* Handling Different Input Types */}
                              <label className="label">{element.label}</label>
                              <input
                                id={element.id}
                                type={element.type}
                                placeholder={element.placeholder || ""}
                                className="form-control"
                                name={element.name}
                                value={dealInfo[element.name] || ""}
                                onChange={handleInputChange}
                                {...(element.type === "date" && element.name === "purchasedate"
                                  ? { max: getTodayDate() }
                                  : element.type === "date" && element.name === "constructioncompleteddate"
                                  ? { min: getTodayDate() }
                                  : {})}
                              />
                              {element.icon && <span className="material-icons">{element.icon}</span>}
                            </>
                          )}
                        </div>
                      );
                        
                     
                      
                  })}

                  </div>

                 
                  <div className="add_property">
                    {formJSON?.map((element) => {
                      if (element.type !== "buttonGroup" || element.name !== "collateraladditionalproperty") return null;

                      const noOption = element.options.find((option) => option.value === "No");
                      const yesOption = element.options.find((option) => option.value === "Yes");

                      return (
                        <React.Fragment key={element.name}>
                          <div className="no">
                            <h3>{element.label}</h3>
                            {noOption && (
                              <button
                                type="button"
                                className={`radio-box ${
                                  collateralData[element.name] === "No" ? "active" : ""
                                }`}
                                onClick={() => handleCollateralButton(element.name, "No", noOption.id)}
                                id={noOption.id}
                              >
                                <span className="material-symbols-outlined">radio_button_checked</span>
                                {noOption.label}
                              </button>
                            )}
                          </div>

                          {yesOption && (
                            <button
                              type="button"
                              className={`radio-box ${
                                collateralData[element.name] === "Yes" ? "active" : ""
                              }`}
                              onClick={() => handleCollateralButton(element.name, "Yes", yesOption.id)}
                              id={yesOption.id}
                            >
                              <span className="material-symbols-outlined">radio_button_checked</span>
                              {yesOption.label}
                            </button>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>

                    
                  <div className='form_wrapper'>
                    {collateralData?.collateraladditionalproperty === "Yes" &&
                      collateralJSON.map((collateralKey, index) => (
                          
                         <>
                          {collateralKey.type === "buttonGroup" && (
                            <div className='warmappform_box'>
                              <label className="label">{collateralKey.label}</label>
                              <div className="propertyinfo_select">
                                {/* {collateralKey.options?.map((option) => (
                                  <> */}
                                 
                                  <select
                                    className="form-control"
                                    name={collateralKey.name}
                                    value={collateralData[collateralKey.name]}
                                    //key={option.value} 
                                    type="dropdown"
                                    // className={`radio-box ${
                                    //   collateralData[collateralKey.name] === option.value ? "active" : ""
                                    // }`}
                                    onChange={(e) =>
                                      handleCollateralButton(collateralKey.name,e.target.value)
                                    }
                                    //id={option.id}
                                    // style={{marginLeft : (option.value == "No") ? '8px' : undefined}}
                                  >
                                    {/* <span className="material-symbols-outlined">radio_button_checked</span>
                                    {option.label} */}
                                      
                                      <option value="">Select</option> 
                                      <option value="Yes">Yes</option>    
                                      <option value="No">No</option>  
                                      
                                  </select>
                                   <span className="material-symbols-outlined"> stat_minus_1</span>
                                  {/* </>
                                  
                                ))} */}
                              </div>
                             
                            </div>
                          )}
</>
                      ))}
              {collateralData?.collateraladditionalproperty === "Yes" && (
                <div className="warmappform_box colateral_address">
                  <label className="label" style={{marginBottom : '7px'}}>What is the property address?</label>
                  <div className="ques_rightBlock">
                    <div className="search_wrp">
                      <span className="material-symbols-outlined">location_on</span>
                      <div>
                        {mapsKey && (
                          <WarmAppLocationSearch
                            className="form-field"
                            name="collateralAddress"
                            setAddressData={setAddressData}
                            addressData={addressData}
                            setSelectedPlace={setSelectedPlace}
                            selectedPlace={collateralData.propertyfulladdress || selectedPlace}
                          />
                        )}
                      </div>
                      <span className="material-symbols-outlined icon-style">search</span>
                    </div>
                  </div>
                </div>
              )}
                  </div>
                
                </div>
                {/* <div className="question_block border-bottom-0">
                  <div className="form_wrapper">
                    {collateralData?.collateraladditionalproperty === 'Yes' &&
                      collateralJSON?.filter(element => element.type === "dropdown" || element.type === "text" || element.type === "number")
                        .map((element, index) => (
                          <>
                           
                            {element.type === "dropdown" ? (
                              <div className="warmappform_box" key={index}>
                                <label className="label">{element.label}</label>
                                <select
                                  id={element.id}
                                  name={element.name}
                                  value={collateralData[element.name] || ""}
                                  className="form-control"
                                  onChange={handleCollateralDropdown}
                                >
                                
                                  {element.options?.map((option) => (
                              
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                             
                                element.name !== "existingloan" || collateralData["currentloan"] === "Yes" ? (
                                  <div className="warmappform_box" key={index}>
                                    <label className="label">{element.label}</label>
                                    <input
                                      id={element.id}
                                      type={element.type}
                                      placeholder={element.placeholder || ""}
                                      className="form-control"
                                      name={element.name}
                                      value={collateralData[element.name] || ""}
                                      onChange={handleCollateralChange}
                                      
                                    />
                                     {element.icon && <span className="material-icons">{element.icon}</span>}
                                  </div>
                                ) : null
                            

                            )}
                          </>
                        ))}
                  </div>
                </div> */}
                <div className="question_block border-bottom-0">
                  <div className="form_wrapper">
                    {collateralData?.collateraladditionalproperty === "Yes" &&
                      collateralJSON
                        ?.filter((element) => element.type === "dropdown" || element.type === "text" || element.type === "number")
                        .map((element, index) => (
                          <React.Fragment key={index}>
                            {/* Dropdown Handling */}
                            {element.type === "dropdown" ? (
                              <div className="warmappform_box">
                                <label className="label">{element.label}</label>
                                <div className='propertyinfo_select'>
                                <select
                                  id={element.id}
                                  name={element.name}
                                  value={collateralData[element.name] || ""}
                                  className="form-control"
                                  onChange={handleCollateralDropdown}
                                >
                                  {element.options?.map((option) => (
                                    <option key={option.value} value={option.value}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                <span className="material-symbols-outlined"> stat_minus_1 </span>
                              </div>
                               </div>
                            ) : element.name === "existingloan" && collateralData["currentloan"] == "Yes" ? (
                              // Currency Input for Existing Loan
                              <div className="warmappform_box">
                                <label className="label">{element.label}</label>
                                <span className="material-symbols-outlined">{element.icon} </span>
                                <CurrencyInput
                                  id={element.id}
                                  name={element.name}
                                  placeholder="$0.00"
                                  decimalsLimit={2}
                                  className="form-control"
                                  prefix="$"
                                  groupSeparator=","
                                  value={collateralData[element.name] || ""}
                                  onValueChange={(value) => handleCollateralCurrencyInputs(element.name, value)}
                                />
                              </div>
                            ) : element.name === "estimatedvalue" ? (
                              // Currency Input for Estimated Value
                              <div className="warmappform_box">
                                <label className="label">{element.label}</label>
                                <span className="material-symbols-outlined">{element.icon} </span>
                                <CurrencyInput
                                  id={element.id}
                                  name={element.name}
                                  placeholder="$0.00"
                                  decimalsLimit={2}
                                  className="form-control"
                                  prefix="$"
                                  groupSeparator=","
                                  value={collateralData[element.name] || ""}
                                  onValueChange={(value) => handleCollateralCurrencyInputs(element.name, value)}
                                />
                              </div>
                            ) : element.name !== "existingloan" && element.name !== "estimatedvalue" ? (
                              // General Text and Number Inputs
                              <div className="warmappform_box">
                                <label className="label">{element.label}</label>
                                <input
                                  id={element.id}
                                  type={element.type}
                                  placeholder={element.placeholder || ""}
                                  className="form-control"
                                  name={element.name}
                                  value={collateralData[element.name] || ""}
                                  onChange={handleCollateralChange}
                                />
                                {element.icon && <span className="material-icons">{element.icon}</span>}
                              </div>
                            ) : null}
                          </React.Fragment>
                        ))}
                  </div>
                </div>
                {/* Collateral Search Section */}
                {/* {collateralData?.collateraladditionalproperty === "Yes" && (
                  <div className="question_block horz_questions collateral-address-block no-toppadd">
                    <label className="label">What is the property address?</label>
                    <div className="ques_rightBlock">
                      <div className="search_wrp">
                        <span className="material-symbols-outlined">location_on</span>
                        <div>
                          {mapsKey && (
                            <WarmAppLocationSearch
                              className="form-field"
                              name="collateralAddress"
                              setAddressData={setAddressData}
                              addressData={addressData}
                              setSelectedPlace={setSelectedPlace}
                              selectedPlace={collateralData.propertyfulladdress || selectedPlace}
                            />
                          )}
                        </div>
                        <span className="material-symbols-outlined icon-style">search</span>
                      </div>
                    </div>
                  </div>
                )} */}



            </div>
            <div className="wizard-canvas-footer">
                <button id="dealInfoNext" type="button" onClick={submitDealInfo} className="save_next">
                    SAVE & NEXT<span class="material-icons"> chevron_right </span>
                </button>
              </div>
            
        </div>
            
                
    );
};

export default RefinancingBuild;