import React from "react";

const PageBreakButton = (props) => {

    const handleClick = () => {
        //let name = e.target.name;
        //let value = e.target.value
        if(props){
            props?.setPageBreakCount(props?.pageBreakCount + 1);
        }
    }
    return (
        <button
        type="button"
        className="fs_btn"
        onClick={handleClick}
        name = "pagebreak"
        
        >
            Add Page Break
        </button>
    );
};

export default PageBreakButton;
