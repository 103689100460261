import React, {useState, useEffect} from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import {addEmailManager ,getSingleEmailTemplateAdmin , getValuesDropdown , getallUsers} from "../utils/reducer";
import EditorEdit from './EditorEdit.js';
import { useLocation, useNavigate} from 'react-router-dom';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import HomeFooter from './HomeFooter';
import Select from 'react-select';
import MultiLevelSelect from 'react-select-multi-level';
//import formValuesKeys from '../JsonData/formValuesKeys.json';
import {transformData} from '../utils/commonUtils.js';
import ValuesDropdown from './ValuesDropdown.js';

const CreateEmailTemplate = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedEmail = location?.state?.data ;
    
    const mode = location?.state?.mode;
    // console.log('selectedEmail---->',selectedEmail);
    const loading = useSelector((state) => state.loaderVisible);
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [type , setType] = useState((selectedEmail?.templateid) ? 'update' : 'create');
    const [content, setContent] = useState('');
    const [message , setMessage] = useState('Updated Successfully');
    const [emailOldData,setEmailOldData] = useState(
        { 
            name: '' ,
            template : ''
        }
    );
    const [domainOptions,setDomainOptions] = useState([]);

    const [emailData, setEmailData] = useState(
        { 
            name: '',
            template : '',
            loanid : false,
            address : false,
            
        }
    );
    const [editorValidate,setEditorValidate] = useState(false);
    const [isEqual,setIsEqual] = useState(false);

    const currentPageFromEmailManager = location?.state?.currentPage; 
    const [domainName , setDomainName] = useState('');
    
    const [errors, setErrors] = useState({});
    // const [formVariable,setFormVariable] = useState([]);
    const [formValuesOption,setFormValuesOption] = useState(null);
    const [valueDependency,setValuesDependency] = useState(0);
    const configdata = useSelector(state => state.configdata);

    const [userEmails,setUserEmails] = useState([]);
    
    const [stagesToMatch, setStagesToMatch] = useState(
        (configdata?.admin?.loanStatus || [
            "Account Executive Review",
            "Underwriting",
            "Term Sheet Issued",
            "Processing",
            "Reviewed/Requested",
            "On Hold",
            "Docs Out",
            "Funded",
            "Closed",
            "Cancelled",
            "Terms Not Issued",
            "Servicing Setup",
            "Servicing"
        ]).filter(stage => stage !== "ALL")
    );

    let updateWorkflowDataJson = [
        // {
        //     "type": "dropdown",
        //     "placeholder" : "Select Phase",
        //     "label" : "Phase",
        //     "name" : "phase",
        //     "options": [
        //         { value: "", label: "Select Phase" },
        //         { value: "Lead", label: "Lead" },
        //         { value: "Pre-Approval", label: "Pre-Approval" },
        //         { value: "Credit Pulled", label: "Credit Pulled" },
        //         { value: "Initial Submission", label: "Initial Submission" },
        //         { value: "Lock Requested", label: "Lock Requested" },
        //         { value: "Manual Lock Requested", label: "Manual Lock Requested" },
        //         { value: "Locked", label: "Locked" },
        //         { value: "Change Lock Requested", label: "Change Lock Requested" },
        //         { value: "Lock Withdrawn", label: "Lock Withdrawn" },
        //         { value: "Set Up", label: "Set Up" },
        //         { value: "Conditions", label: "Conditions" },
        //         { value: "Underwriting", label: "Underwriting" },
        //         { value: "Closing", label: "Closing" },
        //         { value: "Closed", label: "Closed" },
        //         { value: "Funded", label: "Funded" },
        //         { value: "Committed to Hedge", label: "Committed to Hedge" },
        //         { value: "Closed - Denied", label: "Closed - Denied" },
        //         { value: "Sold", label: "Sold" },
        //         { value: "Sold - Funds Received", label: "Sold - Funds Received" },
        //         { value: "Clear to Close", label: "Clear to Close" },
        //         { value: "Suspended", label: "Suspended" },
        //         { value: "Cancelled", label: "Cancelled" },
        //         { value: "Withdrawn", label: "Withdrawn" },
        //         { value: "Acquired", label: "Acquired" },
        //         { value: "Use Service Link", label: "Use Service Link" },
        //         { value: "Closed Update REO and Experience", label: "Closed Update REO and Experience" },
        //         { value: "Use TitleEQ", label: "Use TitleEQ" }
        //     ]

        // },
        {
            "type": "dropdown",
            "placeholder" : "Select folder",
            "label" : "Email Folder Options",
            "name" : "folder",
            "options": [
                { value: "", label: "Select Folder" },
                { "value": "Active Balance Sheet", "label": "Active Balance Sheet" },
                { "value": "Active Balance Sheet - Foreclosure", "label": "Active Balance Sheet - Foreclosure" },
                { "value": "Active Sold - 1Sharpe/FCI", "label": "Active Sold - 1Sharpe/FCI" },
                { "value": "Active Sold - Churchill/FCI", "label": "Active Sold - Churchill/FCI" },
                { "value": "Active Sold - EF/BSI", "label": "Active Sold - EF/BSI" },
                { "value": "Active Sold - Fidelis/FCI", "label": "Active Sold - Fidelis/FCI" },
                { "value": "Active Sold - Reigo/BSI", "label": "Active Sold - Reigo/BSI" },
                { "value": "Cancelled", "label": "Cancelled" },
                { "value": "EF REO", "label": "EF REO" },
                { "value": "NLF - Active Balance Sheet", "label": "NLF - Active Balance Sheet" },
                { "value": "NLF - Active Sold - EF/BSI", "label": "NLF - Active Sold - EF/BSI" },
                { "value": "NLF - Active Sold - Fidelis/FCI", "label": "NLF - Active Sold - Fidelis/FCI" },
                { "value": "NLF - Paid Balance Sheet", "label": "NLF - Paid Balance Sheet" },
                { "value": "NLF - Paid Sold - Fidelis/FCI", "label": "NLF - Paid Sold - Fidelis/FCI" },
                { "value": "NLF - Paid Sold EF/BSI", "label": "NLF - Paid Sold EF/BSI" },
                { "value": "Paid Balance Sheet", "label": "Paid Balance Sheet" },
                { "value": "Paid Sold - 1Sharpe/FCI", "label": "Paid Sold - 1Sharpe/FCI" },
                { "value": "Paid Sold - EF REO", "label": "Paid Sold - EF REO" },
                { "value": "Paid Sold - Fidelis/FCI", "label": "Paid Sold - Fidelis/FCI" },
                { "value": "Paid Sold - Peer Street", "label": "Paid Sold - Peer Street" },
                { "value": "Paid Sold - Reigo/BSI", "label": "Paid Sold - Reigo/BSI" },
                { "value": "Paid Sold Churchill/FCI", "label": "Paid Sold Churchill/FCI" },
                { "value": "Paid Sold EF/BSI", "label": "Paid Sold EF/BSI" },
                { "value": "Pipeline Campaign", "label": "Pipeline Campaign" },
                { "value": "Pre-Close", "label": "Pre-Close" },
                { "value": "REO", "label": "REO" },
                { "value": "Sold Released - Palladius", "label": "Sold Released - Palladius" },
                { "value": "Sold Released - Trinity", "label": "Sold Released - Trinity" },
                { "value": "Sold REO", "label": "Sold REO" }
            ]

        },
       
        
        // {
        //     "type": "dropdown",
        //     "placeholder": "Select Stage",
        //     "label": "Stage",
        //     "name": "stage",
        //     "options": [
        //         { "value": "", "label": "Select Stage" },
        //         { "value": "L01. Account Executive Review", "label": "L01. Account Executive Review" },
        //         { "value": "L05. Underwriting", "label": "L05. Underwriting" },
        //         { "value": "L10. Term Sheet Issued", "label": "L10. Term Sheet Issued" },
        //         { "value": "L15. Processing", "label": "L15. Processing" },
        //         { "value": "L16. Reviewed/Requested", "label": "L16. Reviewed/Requested" },
        //         { "value": "L20. On Hold", "label": "L20. On Hold" },
        //         { "value": "L25. Docs Out", "label": "L25. Docs Out" },
        //         { "value": "L30. Funded", "label": "L30. Funded" },
        //         { "value": "L35. Closed", "label": "L35. Closed" },
        //         { "value": "L40. Cancelled", "label": "L40. Cancelled" },
        //         { "value": "L41. Terms Not Issued", "label": "L41. Terms Not Issued" },
        //         { "value": "S05. Servicing Setup", "label": "S05. Servicing Setup" },
        //         { "value": "S10. Servicing", "label": "S10. Servicing" }
        //     ]
        // }, 
    ]
    

    // const sendEmailTo = [
    //     "Account Executive",
    //     "Borrower",
    //     "Borrower and Broker",
    //     "Broker",
    //     "Processor",
    //     "Servicing",
    //     "Super Admin/Executive",
    //     "Underwriter"
    // ];

    // const sendEmailTo = [
    //     { name: 'adminto', label: 'Admin To', type: 'select', options: users },
    //     { name: 'accountexecutive', label: 'Change Account Executive Officer To', type: 'select', options: accountexecutiveDropdown },
    //     { name: 'changeopenerto', label: 'Change Opener/Setup To', type: 'select', options: users },
    //     { name: 'changeunderwriterto', label: 'Change Underwriter To', type: 'select', options: users },
    //     { name: 'changecloserto', label: 'Change Closer To', type: 'select', options: users },
    //     { name: 'changefunderto', label: 'Change Funder To', type: 'select', options: users },
    //     // { name: 'changearchiverto', label: 'Change Archiver To', type: 'select', options: users },
    //     { name: 'changeaccountexceutiveto', label: 'Change Account Executive Assistant To', type: 'select', options: users },
    //     { name: 'changeaccountexceutiveto2', label: 'Change Account Executive Assistant 2 To', type: 'select', options: users },
    //     { name: 'changeprocessorto', label: 'Change Processor To', type: 'select', options: users },
    //     { name: 'changeprocesser2to', label: 'Change Processor 2 To', type: 'select', options: users },
    //     { name: 'changeaccountingto', label: 'Change Accounting To', type: 'select', options: users },
    //     { name: 'changeservicerto', label: 'Change Servicer To', type: 'select', options: users },
    //     { name: 'changeservicer2to', label: 'Change Servicer 2 To', type: 'select', options: users },
    //     { name: 'changedrawmanagementto', label: 'Change Draw Management To', type: 'select', options: users },
    //     { name: 'changeqcloanto', label: 'Change QC To', type: 'select', options: users }
    //     // Add more input field configurations here
    // ];

    const sendEmailTo = [
        //"adminto",
        // "accountexecutive",
        // "changeopenerto",
        // "changeunderwriterto",
        // "changecloserto",
        // "changefunderto",
        // "changeaccountexceutiveto",
        // "changeaccountexceutiveto2",
        // "changeprocessorto",
        // "changeprocesser2to",
        // "changeaccountingto",
        // "changeservicerto",
        // "changeservicer2to",
        // "changedrawmanagementto",
        // "changeqcloanto",
        // "borrower",
        // "broker"

        
        { value: 'adminto', label: 'Admin' },
        { value: 'accountexecutive', label: 'Account Executive Officer' },
        { value: 'changeopenerto', label: 'Opener/Setup' },
        { value: 'changeunderwriterto', label: 'Underwriter' },
        { value: 'changecloserto', label: 'Closer' },
        { value: 'changefunderto', label: 'Funder' },
        { value: 'changeaccountexceutiveto', label: 'Account Executive Assistant' },
        { value: 'changeaccountexceutiveto2', label: 'Account Executive Assistant 2' },
        { value: 'changeprocessorto', label: 'Processor' },
        { value: 'changeprocesser2to', label: 'Processor 2 To' },
        { value: 'changeaccountingto', label: 'Accounting' },
        { value: 'changeservicerto', label: 'Servicer' },
        { value: 'changeservicer2to', label: 'Servicer 2' },
        { value: 'changedrawmanagementto', label: 'Draw Management' },
        { value: 'changeqcloanto', label: 'QC'},
        {value : 'borrower', label : 'Borrower'},
        {value : 'broker', label : 'Broker'}
    ];
    
    

    const sendEmailFrom = [
        // "custom",
        // "adminto",
        // "accountexecutive",
        // "changeopenerto",
        // "changeunderwriterto",
        // "changecloserto",
        // "changefunderto",
        // "changeaccountexceutiveto",
        // "changeaccountexceutiveto2",
        // "changeprocessorto",
        // "changeprocesser2to",
        // "changeaccountingto",
        // "changeservicerto",
        // "changeservicer2to",
        // "changedrawmanagementto",
        // "changeqcloanto"
        { value : 'custom', label : 'Custom'},
        { value: 'adminto', label: 'Admin' },
        { value: 'accountexecutive', label: 'Account Executive Officer' },
        { value: 'changeopenerto', label: 'Opener/Setup' },
        { value: 'changeunderwriterto', label: 'Underwriter' },
        { value: 'changecloserto', label: 'Closer' },
        { value: 'changefunderto', label: 'Funder' },
        { value: 'changeaccountexceutiveto', label: 'Account Executive Assistant' },
        { value: 'changeaccountexceutiveto2', label: 'Account Executive Assistant 2' },
        { value: 'changeprocessorto', label: 'Processor' },
        { value: 'changeprocesser2to', label: 'Processor 2 To' },
        { value: 'changeaccountingto', label: 'Accounting' },
        { value: 'changeservicerto', label: 'Servicer' },
        { value: 'changeservicer2to', label: 'Servicer 2' },
        { value: 'changedrawmanagementto', label: 'Draw Management' },
        {value : 'changeqcloanto', label : 'QC'}
    ];
    
    

    const [ruleTypeOptions, setRuleTypeOptions] = useState(['Stage', 'Date Rules','Folder']);
    const [dateOptions, setDateOptions] = useState([
        '45 days prior to maturity date', 
        '30 days prior to maturity date', 
        '30 days after funding date', 
        '45 days after funding date', 
        '60 days after funding date', 
        'Loan is Paid Off'
    ]);


    const getSingleTemplate =(templateId)=>{
        dispatch(showLoader());
        getSingleEmailTemplateAdmin(templateId).then(function (response) {
            if (response?.result?.data?.[0]) {
                const result = response?.result?.data?.[0];
                // Check if sendEmailFrom has @easystreetcap.com
                if (result?.sendEmailFrom?.includes(domainName)) {
                    setEmailData({
                        ...result,
                        sendEmailFrom: 'custom',  // Set sendEmailFrom to "custom"
                        //customemail: result?.sendEmailFrom?.replace(domainName,""),  // Set customemail to "@easystreetcap.com"
                        customemail: result?.sendEmailFrom?.replace(/@[^@]+$/, '')

                    });
                    setEmailOldData({
                        ...result,
                        sendEmailFrom: 'custom',  // Set sendEmailFrom to "custom"
                        //customemail: result?.sendEmailFrom?.replace(domainName,""),  // Set customemail to "@easystreetcap.com"
                        customemail: result?.sendEmailFrom?.replace(/@[^@]+$/, '')

                    });
                } else {
                    setEmailData(result);  // Otherwise, just set the emailData as it is
                    setEmailOldData(result);
                }

                
                
                //setEmailOldData(result);
                //setEmailData(result);
                setContent(result?.content || result?.template)
                // setEmailData()
            }
            dispatch(hideLoader())
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader())
            })
    }
    

    useEffect(()=>{
        if(selectedEmail?.templateid){
            getSingleTemplate(selectedEmail?.templateid);
        }
    },[mode]);
   
   
    // const jsdom = require("jsdom");
    // const { JSDOM } = jsdom;
 

        // useEffect(() => {
        //     // dispatch(showLoader());
        //     getTermSheets().then(function (response) {
        //         if (response?.result) {
        //             setPastData(response?.result);
        //             // console.log('past data---->',response?.result);
        //         }
        //         // dispatch(hideLoader());
        //     }).catch((err) => {
        //         // dispatch(hideLoader());
        //         console.error.bind("my account error", err);
        //     })
        // },[])

        // console.log("selected email",selectedEmail);

        useEffect(() => {
            console.log('quillValuequillValue',emailData)
            
            setEmailData((prev) => ({
                ...prev,
                ['template']: content,
            }));
        }, [content]);
    
    const normalizeHtml = (html) => {
        // Remove extra whitespaces and normalize HTML formatting
        // return html.replace(/<[^>]*>/g, '').replace(/\s+/g, ' ').trim();

        // Remove HTML tags but preserve all spaces and line breaks
        return html?.replace(/<\/?[^>]+(>|$)/g, '');
    };

    const areObjectsEqual = () => {
        //console.log("editor old",emailOldData?.template);
        //console.log("editor new",emailData?.template);
        const keys1 = Object.keys(emailOldData);
        const keys2 = Object.keys(emailData);

        // If the number of keys is different, objects are not equal
        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys2) {

            // If values are not equal, objects are not equal
            if (key === "name" && (emailOldData[key] !== emailData[key])) {
                return false;
            }else if(key === "template"){
                const normalizedEditor1Content = normalizeHtml(emailOldData[key]);
                const normalizedEditor2Content = normalizeHtml(emailData[key]);
                if(normalizedEditor1Content !== normalizedEditor2Content){
                    return false;
                }
            }else if (emailOldData[key] !== emailData[key]) {
                return false;
            }
            

            
        }
         // If all keys and values match, objects are equal
        return true;
        //return Object.keys(emailOldData).some(key => emailOldData[key] === emailData[key]);
    };

    useEffect(() => {
        if(type === "update"){
            const dataEqual = areObjectsEqual();
            console.log("Is equal",dataEqual);
            setIsEqual(dataEqual);
        }
       

    },[emailData])

    const handleChange = (e) => {
        //const { name , value} = e.target;
        let name = e.target.name;
        let value = e.target.value;
        // let newValue = "";
        // if(name === "name"){
        //     const regex = /^[a-zA-Z\s]+$/;
           
        //     for(let i = 0; i< value?.length ; i++){
        //         if(!regex.test(value[i])){
        //             newValue += "";
        //         }else{
        //             newValue += value[i];
        //         }
        //     }
        //     value = newValue;
           
        // }
        // if(name !== "template"){
           // setEmailData({...emailData,[name] : value});

        if (name === "reminder") {
            value = value.replace(/\s+/g, ''); // Removes all spaces
        }
        
            setEmailData((prevEmailData) => ({
                ...prevEmailData,
                [name]: value,
            }));
        // }
         
    };

    
    const handleCustomEmail = (e) => {
        let name = e.target.name;
        let value = e.target.value;

       // Regular expression to match and remove any domain part (starting with '@')
        // and disallow spaces in the input
        const emailNameRegex = /^[^\s@]+/;

        // Prevent any string containing a '.' indicating a domain
        // if (domainRegex.test(value)) {
        //     value = value.replace(domainRegex, ''); // Remove the invalid domain part
        // }
         // Extract only the email name part
        const match = value.match(emailNameRegex);
        value = match ? match[0] : '';
        
        setEmailData((prevEmailData) => ({
            ...prevEmailData,
            [name]: value, // Append the domain
        }));
    }

     const handleDomainChange = (e) => {
        setDomainName(e.target.value);
    };

    const handleToggles = (e) => {
        let {name, value , checked} = e.target;
       
        setEmailData((prevEmailData) => ({
            ...prevEmailData,
               [name] : checked
        }));
        
        
    }

    // useEffect(() => {
    //     if(emailData?.daterules && emailData?.daterules !== "Folder"){
    //         // setEmailData((prevEmailData) => ({
    //         //     ...prevEmailData,
    //         //     loanid: false,
    //         //     address : false
    //         // }));
    //         setEmailData((prevEmailData) => {
    //             const updatedData = { ...prevEmailData };
    //             //updatedData?.folder = "";
                
    //             //delete updatedData?.loanid; // Remove loanid key
    //             //delete updatedData?.address; // Remove address key
    //             //delete updatedData?.folder;
    //             updatedData.loanid = false; // Remove loanid key
    //             updatedData.address = false; // Remove address key
    //             return updatedData;

    //         });
    //     }
        
    //     // if(emailData?.sendEmailFrom && emailData?.sendEmailFrom !== "custom"){
    //     //     setEmailData((prevEmailData) => {
    //     //         const { customemail, ...rest } = prevEmailData; // Destructure to remove 'customemail'
    //     //         return rest;
    //     //     });
            
    //     // }
    // },[emailData?.daterules])

    const updateEmailData = (newData) => {
        setEmailData(prevState => ({
            ...prevState,
            ...newData
        }));
    };

    useEffect(() => {
        if(selectedEmail?.templateid){
            updateEmailData({templateid: (location?.state?.data?.templateid)});
        }
    },[])

   
    const addErrorClass = (fieldName) => {
        const inputElement = document.querySelector(`.${fieldName}`);
        if (inputElement) {
          inputElement.classList.add('invalid-input');
          console.log("Error class added to element:", inputElement);
        }
      };
    
      // Remove error classes on all inputs
    const removeErrorClass = (fieldName) => {
        
        const searchWrpElement = document.querySelector(`.${fieldName}`);
        if (searchWrpElement) {
                searchWrpElement.classList.remove("invalid-input");
        }
    }

    useEffect(() => {
        const inputElement = document.querySelector(`.jodit-react-container`);
        if(inputElement.classList.contains('invalid-input')){
            removeErrorClass("jodit-react-container");
        }
    },[emailData?.template])
   

    const handleSave = ()=>{
        // console.log("existing data",termSheetsData);
        // const fieldsToCheck = {
        //      sheetName : 'sheetName', programType: 'programType'
        // };
        console.log(emailData?.template);
        console.log(content);
        let error = {};
        const emailInputs = ['name', 'template', 'sendEmailOnLoanStatusChange', 'sendEmailFrom', 'sendEmailTo', 'emailSubjectLine','daterules'];
        const isWhitespace = /^<p>(&nbsp;|\s)*<br>\s*<\/p>$/; 

        // for (const input of emailInputs) {
        //     if (input === "template" && (isWhitespace?.test(emailData[input]) || !emailData[input] || emailData[input] === "" || emailData[input] === "<p><br></p>" || emailData[input] === undefined)) {
        //         //error[input] = 'required';
        //         addErrorClass("jodit-react-container");
        //     }else if (input === "sendEmailTo" && Array.isArray(emailData?.[input]) && emailData?.[input].length === 0) {
        //         error[input] = 'required';
        //     }else if ((!emailData[input] || emailData[input] === "" || emailData[input] === undefined)) {
        //         error[input] = 'required';
        //     }
        // }

        for (const input of emailInputs) {
            // Check for 'template' field
            if (input === "template" && (isWhitespace?.test(emailData[input]) || !emailData[input] || emailData[input] === "" || emailData[input] === "<p><br></p>" || emailData[input] === undefined)) {
                addErrorClass("jodit-react-container");
            }
            // Check for 'sendEmailTo' field
            else if (input === "sendEmailTo" && Array.isArray(emailData?.[input]) && emailData?.[input].length === 0) {
                error[input] = 'required';
            }
            // Check for 'type' field
            else if (input === "daterules" && (!emailData[input] || emailData[input] === "")) {
                error[input] = 'required';
            }
            // Check for other required fields
            else if (input !== "sendEmailOnLoanStatusChange" && input !== "reminder" && (!emailData[input] || emailData[input] === "" || emailData[input] === undefined)) {
                error[input] = 'required';
            }
            // Check for conditional requirements based on 'type'
            else if (emailData['daterules'] === "Stage" && (emailData['sendEmailOnLoanStatusChange'] === "" || emailData['sendEmailOnLoanStatusChange'] === undefined)) {
                error['sendEmailOnLoanStatusChange'] = 'required'; // Make this field mandatory if type is "Stage"
            }
            else if (emailData['daterules'] === "Date Rules" && (emailData['reminder'] === "" || emailData['reminder'] === undefined)) {
                error['reminder'] = 'required'; // Make this field mandatory if type is "Date Rules"
            }
            else if (emailData['daterules'] === "Folder" && (emailData['folder'] === "" || emailData['folder'] === undefined)) {
                error['folder'] = 'required'; // Make this field mandatory if type is "Date Rules"
            }
            
        }

        if (emailData['sendEmailFrom'] && emailData['sendEmailTo']?.some((to) => to === emailData['sendEmailFrom'])) {
            error['sendEmailFrom'] = 'Send Email From and Send Email To cannot be the same!';
            error['sendEmailTo'] = 'Send Email From and Send Email To cannot be the same!';
        }

        if(emailData['sendEmailFrom'] && emailData['sendEmailFrom'] === "custom" && !emailData['customemail']){
            error['customemail'] = 'required';
        }

        // if(emailData['sendEmailFrom'] && emailData['sendEmailFrom'] === "custom" && emailData['customemail']){
        //     if (emailData['sendEmailTo']?.includes('adminto') && userEmails.includes(emailData['customemail'])) {
        //         error['customemail'] = 'required';
        //         error['sendEmailTo'] = 'You cannot send an email to an admin. Please choose a different recipient.';
        //     }
            
            
        // }


        console.log("errors", error);
        setErrors(error);
        const inputElement = document.querySelector(`.jodit-react-container`);
        if (Object.keys(error).length === 0 && !inputElement.classList.contains('invalid-input')) {

            // Append @easystreetcap.com to customemail if not already present
            let updatedEmailData = { ...emailData };

                // Handle sendEmailFrom logic
                if (emailData?.sendEmailFrom === "custom" && emailData?.customemail) {
                    updatedEmailData.sendEmailFrom = emailData.customemail.includes(domainName)
                        ? emailData.customemail
                        : `${emailData.customemail}${domainName}`;
                    delete updatedEmailData.customemail;
                } else {
                    updatedEmailData.customemail = '';
                    delete updatedEmailData.customemail;
                }

                // Handle daterules logic
                // if (emailData?.daterules && emailData.daterules !== "Folder") {
                //     if (emailData.hasOwnProperty('folder')) {
                //         delete updatedEmailData.folder;
                //     }
                //     if (emailData.hasOwnProperty('loanid')) {
                //         delete updatedEmailData.loanid;
                //     }
                //     if (emailData.hasOwnProperty('address')) {
                //         delete updatedEmailData.address;
                //     }
                // }

                if (emailData?.daterules && emailData.daterules !== "Stage") {
                    if (emailData.hasOwnProperty('sendEmailOnLoanStatusChange')) {
                        delete updatedEmailData.sendEmailOnLoanStatusChange;
                    }
                }

                if (emailData?.daterules && emailData.daterules !== "Date Rules") {
                   
                    if (emailData.hasOwnProperty('reminder')) {
                        delete updatedEmailData.reminder;
                    }
                    
                }

                console.log("Updated Email Data:", updatedEmailData);

            

            
            
                dispatch(showLoader());
                addEmailManager(updatedEmailData).then(function (response) {
                    console.log('responseresponse',response);
                    setPopupOpen(true);
                    if(response?.result !== "Template Name Already Exists"){
                       
                        let msg = type === "create" ? 'Template created successfully' : 'Template updated successfully';
                        setMessage(msg);
                        
                    }else{
                        setMessage("Template Name Already Exists!");
                    }
    
                    dispatch(hideLoader());
                }).catch((err) => {
                    dispatch(hideLoader());
                    console.error.bind("Login error", err);
                })
            
            
        }
    }
    

    const closePopup = () => {
        setPopupOpen(false);
        // navigate('/emailmanager');
        navigate('/emailmanager',{ state : { currentPage : currentPageFromEmailManager }});
    };



    

    const handleChangeValues = (value) => {
        const selectedValue = value;

    // setFormVariables((prevFormVariables) => {
    //     if (!prevFormVariables.includes(selectedValue)) {
    //         return [...prevFormVariables, selectedValue];
    //     }
    //     return prevFormVariables; 
    // });

        setFormValuesOption(selectedValue);
        setValuesDependency(valueDependency + 1);
    };

    // useEffect(() => {
    //     dispatch(showLoader());
    //     getValuesDropdown().then(function (response) {
    //         if (response?.result) {
    //             const result = response?.result;
    //             console.log("get values dropdown",result);
    //             setFormValuesData(result);
    //         }
    //         dispatch(hideLoader())
    //     }).catch((err) => {
    //           console.error.bind("get values dropdown  error", err);
    //           dispatch(hideLoader())
    //     })
    // },[])

    // Toggle sub-options on category click
    // const toggleSubOptions = (index) => {
    //     setOpenCategoryIndex(openCategoryIndex === index ? null : index);
    // };


    // useEffect(() => {
    //     if (formValuesData && formValuesData.length > 0) {
    //         console.log("form values data",formValuesData)
    //         const transformedData = transformData(formValuesData);
    //         console.log("Formatted Dropdown Data", transformedData);
    //         setFormValues(transformedData); // Set array, not stringified data
    //     }
    // }, [formValuesData])
    const [sendEmailOptions,setSendEmailOptions] = useState([]);
    useEffect(() => {
        const transformedResult = sendEmailTo?.map(item => ({
            value: item.value,
            label: item.label
          }));
          setSendEmailOptions(transformedResult)
    }, [])
    const [selectedEmails, setSelectedEmails] = useState([]);

    const handleSelectChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        console.log("selectedOptions",selectedOptions)
        //const selectedValues = selectedOptions.map(option => option);
        setSelectedEmails(selectedValues);
    };

    
    useEffect(() => {
     
            updateEmailData({sendEmailTo: selectedEmails});
      
    }, [selectedEmails])
    useEffect(() => {
     
        console.log('emailData',emailData);
        if (emailData['sendEmailFrom'] && !emailData['sendEmailTo']?.some((to) => to === emailData['sendEmailFrom'])) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                sendEmailFrom : null,
                sendEmailTo : null
            }))
           
        }

        if (emailData['customemail'] && (
            (userEmails?.includes(emailData['customemail']) && !emailData['sendEmailTo']?.includes("admin")) 
            || (!userEmails?.includes(emailData['customemail']) && emailData['sendEmailTo']?.includes("admin")))) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                customemail : null,
                sendEmailTo : null
            }))
           
        }
  
    }, [emailData])

    useEffect(() => {
        console.log("configdata",configdata);
        if(configdata?.admin?.defaultSendEmailDomain){
            setDomainOptions(configdata?.admin?.defaultSendEmailDomain);
        }
    },[configdata])

     useEffect(() => {
        dispatch(showLoader());
        getallUsers().then(function (response) {
            // console.log('privacymenunewwegetallUsers', response.result);
            if (response?.result?.data) {
                console.log("user data",response?.result?.data);
                // Extract only emailid from each userInfo object
                //const emailIds = response?.result?.data.map(user => user.userInfo.emailid);

                // Extract only the part before the "@" in the emailid
                const emailNames = response?.result?.data?.map(user => user.userInfo.emailid.split('@')[0]);
                setUserEmails(emailNames);//array
              
              dispatch(hideLoader());
            }
          })
          .catch((err) => {
            console.error.bind("my account error", err);
            dispatch(hideLoader());
          })
      }, []);

      useEffect(() => {
            if(domainOptions){
                if(!domainName){
                    setDomainName(domainOptions[0]);
                }
                
            }
      },[domainOptions])
    
    return (
        <div id="layout-wrapper">
        {loading ? <Loader/> : null} 

        {isPopupOpen && (
        <PopupMessage
          type={message === "Sheet Name or Program Type already Exists." || message === "Template Name Already Exists!"? 'warning' : 'success'}
          message={message}
          onClose={closePopup}
        />
      )}

           <MainHeader />
            <SideMenu />

            <div className="main-content">

            <div className="page-content user_management email-template">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">{type === 'update' ? 'Update Email Template' : 'Create Email Template'} </h4>
                                <a id="navigateEmailManager" href="#" onClick={(e) => {
                                    e.preventDefault(); 
                                    //navigate(-1);
                                    navigate('/emailmanager' , { state: { currentPage: currentPageFromEmailManager } });
                
                                    }} 
                                    className="back_btn"><span className="material-icons icon"> arrow_back
                                    </span>BACK</a>
                            </div>
                            <div className="card">
                                <div className="card-body ">
                                    <div className="form_section">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Select Term Sheet</label>
                                                            <select name='termSheet' onChange={handleChange} className={`form-control ${errors?.termSheet && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, termSheet: null }) }}>
                                                                {termSheet.map((data,index)=>{
                                                                    return(
                                                                        <option value={data.value}>{data.label}</option>
                                                                    )
                                                                })}
                                                               
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Template Name</label>
                                                            <input id="emailTemplateName" name='name' value={emailData?.name} onChange={handleChange} type="text" className={`form-control ${errors?.name && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, name: null }) }} placeholder="Enter Template Name" />
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                        
                                                    {!emailData?.deafult && 
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Rule Type</label>
                                                                <select 
                                                                    id="ruleType" 
                                                                    name="daterules" 
                                                                    className={`form-control ${errors?.daterules && 'invalid-input'}`}
                                                                    onFocus={() => { setErrors({ ...errors, daterules: null }) }}
                                                                    //tabIndex="1" 
                                                                    onChange={handleChange} 
                                                                    value={emailData?.daterules}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {ruleTypeOptions.map((option, index) => (
                                                                        <option key={index} value={option}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }
                                                    {emailData?.daterules?.trim() === 'Date Rules' && 
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Email Reminder Dates</label>
                                                                <select 
                                                                    id="emailReminder" 
                                                                    name="reminder" 
                                                                    className={`form-control ${errors?.reminder && 'invalid-input'}`}
                                                                    onFocus={() => { setErrors({ ...errors, reminder: null }) }}
                                                                    //tabIndex="1" 
                                                                    onChange={handleChange} 
                                                                    value={emailData?.reminder}
                                                                    // disabled = {emailData?.daterules?.trim() !== 'Date Rules'}
                                                                    style={{ background: (emailData?.daterules?.trim() !== 'Date Rules') ? 'rgb(233, 236, 239)' : ''}}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {dateOptions.map((option, index) => (
                                                                        <option key={index} value={option.replace(/\s+/g, '')}>
                                                                            {option}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }
                                                {/* </div> */}

                                                {/* <div className="row">
                                                {dynamicDropdowns?.map((input,index) => (
                                                        <div className="col-md-4" key={index}>
                                                            <div className="form-group">
                                                                <label>{input?.label}</label>
                                                            <MultiLevelSelect
                                                                options={input?.options}
                                                                onChange={(e)=>{handleSelectChange(e)}}
                                                                placeholder={input?.placeholder}
                                                            />
                                                            </div>
                                                        </div>
                                                    ))}
                                                    
                                                </div> */}
                                                {/* <div className="row"> */}
                                                    {( (!emailData?.deafult) && (emailData?.daterules === 'Stage')) && 
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>Send email when loan goes into</label>
                                                                <select id = "sendEmailOnLoanStatusChange" onChange={handleChange} value={emailData?.sendEmailOnLoanStatusChange}  name='sendEmailOnLoanStatusChange' 
                                                                className={`form-control ${errors?.sendEmailOnLoanStatusChange && 'invalid-input'}`} 
                                                                onFocus={() => { setErrors({ ...errors, sendEmailOnLoanStatusChange: null }) }}
                                                                // disabled = {emailData?.daterules !== 'Stage'}
                                                                style={{ background: (emailData?.daterules !== 'Stage') ? 'rgb(233, 236, 239)' : ''}}
                                                                >
                                                                <option value={''}>Select</option>
                                                                    {stagesToMatch.map((data,index)=>(
                                                                        <option value={data}>{data}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }
                                                    {emailData?.daterules === 'Folder' && 
                                                        updateWorkflowDataJson.map((updatework,index) => (
                                                            (updatework?.type === "dropdown") ?
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label>{updatework?.label}</label>
                                                                        <select id={`${updatework?.label}UpdateWorkFlow`} className={`form-control ${errors[updatework?.name] && 'invalid-input'}`} name={updatework?.name} placeholder={updatework?.placeholder} onChange={handleChange} value={emailData[updatework?.name] || ''} onFocus={() => { setErrors({ ...errors, [updatework?.name]: null }) }}>
                                                                            {updatework?.options.map((options,optionIndex) => (
                                                                                <option value={options?.value}>{options?.label}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            :   ""
                                                        ))
                                                    }
                                                    {/* <div className="col-md-3"></div> */}

                                                
                                                </div>

                                                <div className='row'>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                    <label>Send Email From</label>
                                                                    {/* <input id="sendEmailFrom" name='sendEmailFrom' value={emailData?.sendEmailFrom} onChange={handleChange} type="text" className={`form-control ${errors?.sendEmailFrom && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, sendEmailFrom: null }) }} placeholder="Enter Send Email From" /> */}
                                                                    <select id = "sendEmailFrom" onChange={handleChange} value={emailData?.sendEmailFrom}  name='sendEmailFrom' className={`form-control ${errors?.sendEmailFrom && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, sendEmailFrom: null }) }}>
                                                                    <option value="">Select</option>
                                                                    {sendEmailFrom.map((data,index)=>(
                                                                        <option value={data.value} key={index}>
                                                                            {/* {data === "custom" ? data : data.replace(/change|to/gi, '').trim()} */}
                                                                            {data.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                </div>  
                                                        </div>

                                                        

                                                        
                                                        <div className="col-md-3">
                                                            {/* <div className="form-group">
                                                                <label>Send Email To</label>
                                                            
                                                                <Select
                                                            
                                                        
                                                                options={sendEmailOptions}
                                                                isMulti
                                                                value={sendEmailOptions?.filter(option => emailData?.sendEmailTo?.includes(option.value))}
                                                                className={`w-100 ${errors.sendEmailTo && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, sendEmailTo: null }) }}
                                                                inputId='sendEmailTo'
                                                                onChange={handleSelectChange}
                                                                name='sendEmailTo'
                                                            
                                                                />
                                                            </div> */}
                                                            <div className="form-group">
                                                                <label>Send Email To</label>
                                                                {/* <Select
                                                                options={sendEmailTo.map(option => ({
                                                                    value: option, // Keep the original value for storing
                                                                    label: option.replace(/change|to/gi, '').trim(), // Format label for display
                                                                }))}
                                                                isMulti
                                                                value={emailData?.sendEmailTo?.map(selectedValue => ({
                                                                    value: selectedValue, // Store original value in the state
                                                                    label: selectedValue.replace(/change|to/gi, '').trim(), // Format label for display
                                                                })) || [
                                                                    { value: 'changefunderto', label: 'Change Funder To' },
                                                                    { value: 'changecloserto', label: 'Change Closer To' }
                                                                ]}
                                                                className={`w-100 ${errors.sendEmailTo && 'invalid-input'}`}
                                                                onFocus={() => {
                                                                    setErrors({ ...errors, sendEmailTo: null });
                                                                }}
                                                                inputId="sendEmailTo"
                                                                onChange={handleSelectChange}
                                                                name="sendEmailTo"
                                                                /> */}
                                                                <Select
                                                            
                                                        
                                                                    options={sendEmailOptions}
                                                                    isMulti
                                                                    value={sendEmailOptions?.filter(option => emailData?.sendEmailTo?.includes(option.value))}
                                                                    className={`w-100 ${errors.sendEmailTo && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, sendEmailTo: null }) }}
                                                                    inputId='sendEmailTo'
                                                                    onChange={handleSelectChange}
                                                                    name='sendEmailTo'
                                                        
                                                                />


                                                            </div>

                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                        <label>Email Subject Line</label>
                                                                        <input id="emailSubjectLine" name='emailSubjectLine' value={emailData?.emailSubjectLine} onChange={handleChange} type="text" className={`form-control ${errors?.emailSubjectLine && 'invalid-input'}`} onFocus={() => { setErrors({ ...errors, emailSubjectLine: null }) }} placeholder="Enter Email Subject Line" />
                                                            </div>  
                                                        </div>
                                                        {/* {emailData?.daterules === 'Folder' &&  */}
                                                            <div className="col-md-3">
                                                                <div className="form-group mt-4">
                                                                    
                                                                    <label className="toggle mb-0 mt-1">
                                                                        <span className="toggle-label">Loan ID</span>
                                                                        <input id="loanIdEmail" className="toggle-checkbox" name="loanid" type="checkbox" checked={emailData?.loanid} onChange={(e) => handleToggles(e)}/>
                                                                        <div className="toggle-switch ms-2"></div>
                                                                    </label>
                                                                    <label className="toggle mb-0 mt-1 ms-4">
                                                                        <span className="toggle-label">Address</span>
                                                                        <input id="AddressEmail" className="toggle-checkbox" name="address" type="checkbox" checked={emailData?.address} onChange={(e) => handleToggles(e)}/>
                                                                        <div className="toggle-switch ms-2"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        {/* } */}

                                                    </div>


                                                {errors?.sendEmailFrom && errors?.sendEmailFrom === "Send Email From and Send Email To cannot be the same!" && <p style={{color : 'red'}}>{errors?.sendEmailFrom}</p>}
                                                {errors?.sendEmailTo && errors?.sendEmailTo === 'You cannot send an email to an admin. Please choose a different recipient.' && <p style={{color : 'red'}}>{errors?.sendEmailTo}</p>}
                                                
                                                

                                                {emailData?.sendEmailFrom && emailData?.sendEmailFrom === "custom" &&
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Custom Email</label>
                                                            <div className="input-group">
                                                                
                                                                <input id="customEmail" name='customemail' 
                                                                    value={emailData?.customemail} 
                                                                    onChange={handleCustomEmail} 
                                                                    type="text" 
                                                                    className={`form-control ${errors?.customemail && 'invalid-input'}`} 
                                                                    onFocus={() => { setErrors({ ...errors, customemail: null }) }} 
                                                                    placeholder = "Sender's Email" 
                                                                    
                                                                />
                                                                {/* <span class="input-group-text" id="basic-addon2" style={{ background: 'rgb(233, 236, 239)'}}>{domainName}</span> */}
                                                                <select
                                                                    className="form-control"
                                                                    style={{ background: 'rgb(233, 236, 239)' }}
                                                                    value={emailData.domain}
                                                                    onChange={handleDomainChange}
                                                                >
                                                                    {domainOptions?.map((domain, index) => (
                                                                        <option key={index} value={domain}>
                                                                            {domain}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>   
                                                        </div>  
                                                    </div>
                                                }
                                                {/* {emailData?.daterules && emailData?.daterules === "Folder" && <div className='row'> */}
                                                {/* <div className='row'>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label></label>
                                                            <label className="toggle mb-0 mt-1">
                                                                <span className="toggle-label">Loan Id</span>
                                                                <input id="loanIdEmail" className="toggle-checkbox" name="loanid" type="checkbox" checked={emailData?.loanid} onChange={(e) => handleToggles(e)}/>
                                                                <div className="toggle-switch ms-2"></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label></label>
                                                            <label className="toggle mb-0 mt-1">
                                                                <span className="toggle-label">Address</span>
                                                                <input id="AddressEmail" className="toggle-checkbox" name="address" type="checkbox" checked={emailData?.address} onChange={(e) => handleToggles(e)}/>
                                                                <div className="toggle-switch ms-2"></div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div> */}
                                                <div className="row mt-2">
                                                    <div className="col-md-8">
                                                        <div>
                                                            <ValuesDropdown onChange={handleChangeValues}/>
                                                            <EditorEdit content={content} setContent={setContent}  type={type} name = "template" formValuesOption={formValuesOption}  valueDependency={valueDependency}/>
                                                        </div>
                                                        {/* <label className="toggle">
                                                            <input className="toggle-checkbox" onChange={(e)=>handleCheckBox(e)} checked={termSheetsData?.whiteLabel} type="checkbox" />
                                                            <div className="toggle-switch"></div>
                                                            <span className="toggle-label">White Label</span>
                                                        </label> */}
                                                    </div>
                                                </div>
                                                <div className="btn-group mt_10">
                                                 
                                                {/* {type === 'update' && <a href="#" onClick={(e) => {e.preventDefault();  handleSave()}} className="fs_btn mr_10" disabled = {termBool} style={{'opacity' : (termBool === true) ? '0.6' : '1'}}>Update</a>} */}
                                                    {<button id="submitEmail" type = "button" style = {{'opacity' : (type === "update" && isEqual === true) ? '0.6' : '1' }} disabled = {(type === "update" && isEqual === true) && 'true' } onClick={(e) => {e.preventDefault();  handleSave()}} className="fs_btn mr_10" > {mode === 'edit' ? 'Update' : 'Create'}</button>}
                                                    {/* <a href="#" onClick={(e) => {e.preventDefault(); handleDelete()}} className="border_btn">Delete</a> */}
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

               <HomeFooter />
            </div>

        </div>
    );
};

export default CreateEmailTemplate