import React, { useState, useEffect, useId, useLayoutEffect } from 'react';
import SideMenu from './SideMenu';
import MainHeader from './MainHeader';
import { useNavigate } from 'react-router-dom';
import PipelineHeader from './PipelineHeader';
import PipelineFooter from './PipelineFooter';
import PipelineStatus from './PipelineStatus';
import { useLoanInfo } from '../utils/loanInfoContext';
import {getallUsersDropdown,updateWorkflow, LoanDeal, genSingleLoanDeal ,getLoanassignment,addloanassignment,
        addToolWorkflowAlerts , getToolWorkflowAlerts ,deleteToolWorkflowAlert,
        categoriesforloan, getloanHistory,
        getAllEmails , sendEmail , getEmailsHistory ,getSingleEmailHistory , getLoanDetails ,getSingleEmailTemplateData,getAllFundManagmentAdmin
    } from '../utils/reducer';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import Select from 'react-select';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';   
import EditorEdit from './EditorEdit.js';
import DataTable from 'react-data-table-component';
import { formatCurrency } from '../utils/commonUtils.js';
import { useLocation } from "react-router-dom";
import { getUserDataFromLocalStorage } from '../utils/local';
import { hasViewPermission,hasEditPermission } from '../utils/permissionsCheck';

const DealToolsWorkflow = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isDealWorkFlow = location.pathname.includes("dealtoolsworkflow");
    const { loanInfo ,setLoanInfo} = useLoanInfo();
    const [toolsError,setToolsError] = useState({});
    const[users,setUsers]=useState([]);
    const [accountexecutiveDropdown,setAccountExecutiveDropdown] = useState([])
    const[loanid,setLoanid]=useState(loanInfo?.loanid);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loaderVisible);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('Submitted successfully');
    const [workflowType , setWorkFlowType] = useState("create");
    const [emailType,setEmailType] =useState("create");
    const [dealId ,setDealId] = useState(null);
    const [tableWorkFlowData,setTableWorkFlowData] = useState([]);
    const [alertsErrors,setAlertsErrors] = useState({});
    const [toolBool,setToolBool] = useState(false);
    const [alertBool,setAlertBool] = useState(false);
    const [alertDeleteConfirmation,setAlertDeleteConfirmation] = useState(false);
    const [alertDeleteId,setAlertDeleteId] = useState(false);
    const [loanData,setLoanData] = useState({});
    const [updateWorkflowData,setUpdateWorkflowData ] = useState({
        // "phase" : "",
        // "stage" : "",
        "folder": ""
    });
console.log(updateWorkflowData);
    
    const [manualAlertsData,setManualAlertsData] = useState({
        "section" : "",
        "reasonfordenial" : ""
    });
    //CATEGORIES FOR LOAN
    const [categoriesDropdown , setcategoriesDropdown] = useState([]);
    const [categoriesForloanData,setCategoriesforloanData] = useState({
        categoriesforloan : []
    });
    const [categoryError,setCategoryError] = useState(false);
    const [catgoryPopUp,setCatgoryPopUp] = useState(false);
    const [previousCategories, setPreviousCategories] = useState([]);

    let errors = {};
    let alertserrors = {};

    /* emails tab */ 
    const [content, setContent] = useState();
    const [allEmailsData,setAllEmailsData] = useState([]);
    const [emailsHistoryData,setEmailsHistoryData] = useState([]);
    //const [singleEmail,setSingleEmail] = useState([]);
    const [manualEmailData,setManualEmailData] = useState({
        "from" : "",
        "to" : "",
        "name" : "",
        "subject" : "",
        "template" : content,
    });
    const [singleOldEmailData,setSingleOldEmailData] = useState({
        "from" : "",
        "to" : "",
        "name" : "",
        "subject" : "",
        "template" : "",
    });
    const [emailErrors,setEmailErrors] = useState({});
    const [sectionDisplay,setSectionDisplay] = useState(true);
    const [type , setType] = useState('create');
    const [uuidEmail,setEmailUuid] = useState('');
    const [emailBool,setEmailBool] = useState(false);
    const [hidePrint,setHidePrint] = useState('no');
    const [emailSent,setEmailSent] = useState(false);
    const [fundsDropdown,setFundsDropdown] = useState([]);
    let userData = getUserDataFromLocalStorage();
    // console.log("userData",userData)
    const [pipelinePermissions, setPipelinePermissions] = useState();
    const [editPermissions, setEditPermissions] = useState( );
    
    let emailKeyErrors = {};
  
    let updateWorkflowDataJson = [
        // {
        //     "type": "dropdown",
        //     "placeholder" : "Select Phase",
        //     "label" : "Phase",
        //     "name" : "phase",
        //     "options": [
        //         { value: "", label: "Select Phase" },
        //         { value: "Lead", label: "Lead" },
        //         { value: "Pre-Approval", label: "Pre-Approval" },
        //         { value: "Credit Pulled", label: "Credit Pulled" },
        //         { value: "Initial Submission", label: "Initial Submission" },
        //         { value: "Lock Requested", label: "Lock Requested" },
        //         { value: "Manual Lock Requested", label: "Manual Lock Requested" },
        //         { value: "Locked", label: "Locked" },
        //         { value: "Change Lock Requested", label: "Change Lock Requested" },
        //         { value: "Lock Withdrawn", label: "Lock Withdrawn" },
        //         { value: "Set Up", label: "Set Up" },
        //         { value: "Conditions", label: "Conditions" },
        //         { value: "Underwriting", label: "Underwriting" },
        //         { value: "Closing", label: "Closing" },
        //         { value: "Closed", label: "Closed" },
        //         { value: "Funded", label: "Funded" },
        //         { value: "Committed to Hedge", label: "Committed to Hedge" },
        //         { value: "Closed - Denied", label: "Closed - Denied" },
        //         { value: "Sold", label: "Sold" },
        //         { value: "Sold - Funds Received", label: "Sold - Funds Received" },
        //         { value: "Clear to Close", label: "Clear to Close" },
        //         { value: "Suspended", label: "Suspended" },
        //         { value: "Cancelled", label: "Cancelled" },
        //         { value: "Withdrawn", label: "Withdrawn" },
        //         { value: "Acquired", label: "Acquired" },
        //         { value: "Use Service Link", label: "Use Service Link" },
        //         { value: "Closed Update REO and Experience", label: "Closed Update REO and Experience" },
        //         { value: "Use TitleEQ", label: "Use TitleEQ" }
        //     ]

        // },
        {
            "type": "dropdown",
            "placeholder" : "Select folder",
            "label" : "Folder",
            "name" : "folder",
            "options": [
                { value: "", label: "Select Folder" },
                { "value": "Active Balance Sheet", "label": "Active Balance Sheet" },
                { "value": "Active Balance Sheet - Foreclosure", "label": "Active Balance Sheet - Foreclosure" },
                { "value": "Active Sold - 1Sharpe/FCI", "label": "Active Sold - 1Sharpe/FCI" },
                { "value": "Active Sold - Churchill/FCI", "label": "Active Sold - Churchill/FCI" },
                { "value": "Active Sold - EF/BSI", "label": "Active Sold - EF/BSI" },
                { "value": "Active Sold - Fidelis/FCI", "label": "Active Sold - Fidelis/FCI" },
                { "value": "Active Sold - Reigo/BSI", "label": "Active Sold - Reigo/BSI" },
                { "value": "Cancelled", "label": "Cancelled" },
                { "value": "EF REO", "label": "EF REO" },
                { "value": "NLF - Active Balance Sheet", "label": "NLF - Active Balance Sheet" },
                { "value": "NLF - Active Sold - EF/BSI", "label": "NLF - Active Sold - EF/BSI" },
                { "value": "NLF - Active Sold - Fidelis/FCI", "label": "NLF - Active Sold - Fidelis/FCI" },
                { "value": "NLF - Paid Balance Sheet", "label": "NLF - Paid Balance Sheet" },
                { "value": "NLF - Paid Sold - Fidelis/FCI", "label": "NLF - Paid Sold - Fidelis/FCI" },
                { "value": "NLF - Paid Sold EF/BSI", "label": "NLF - Paid Sold EF/BSI" },
                { "value": "Paid Balance Sheet", "label": "Paid Balance Sheet" },
                { "value": "Paid Sold - 1Sharpe/FCI", "label": "Paid Sold - 1Sharpe/FCI" },
                { "value": "Paid Sold - EF REO", "label": "Paid Sold - EF REO" },
                { "value": "Paid Sold - Fidelis/FCI", "label": "Paid Sold - Fidelis/FCI" },
                { "value": "Paid Sold - Peer Street", "label": "Paid Sold - Peer Street" },
                { "value": "Paid Sold - Reigo/BSI", "label": "Paid Sold - Reigo/BSI" },
                { "value": "Paid Sold Churchill/FCI", "label": "Paid Sold Churchill/FCI" },
                { "value": "Paid Sold EF/BSI", "label": "Paid Sold EF/BSI" },
                { "value": "Pipeline Campaign", "label": "Pipeline Campaign" },
                { "value": "Pre-Close", "label": "Pre-Close" },
                { "value": "REO", "label": "REO" },
                { "value": "Sold Released - Palladius", "label": "Sold Released - Palladius" },
                { "value": "Sold Released - Trinity", "label": "Sold Released - Trinity" },
                { "value": "Sold REO", "label": "Sold REO" }
            ]

        },
        {
            "type": "dropdown",
            "placeholder" : "Select Fund",
            "label" : "Fund",
            "name" : "funds",
            "options":[ { value: "", label: "Select Fund" },...fundsDropdown] ,

        },
        
        // {
        //     "type": "dropdown",
        //     "placeholder": "Select Stage",
        //     "label": "Stage",
        //     "name": "stage",
        //     "options": [
        //         { "value": "", "label": "Select Stage" },
        //         { "value": "L01. Account Executive Review", "label": "L01. Account Executive Review" },
        //         { "value": "L05. Underwriting", "label": "L05. Underwriting" },
        //         { "value": "L10. Term Sheet Issued", "label": "L10. Term Sheet Issued" },
        //         { "value": "L15. Processing", "label": "L15. Processing" },
        //         { "value": "L16. Reviewed/Requested", "label": "L16. Reviewed/Requested" },
        //         { "value": "L20. On Hold", "label": "L20. On Hold" },
        //         { "value": "L25. Docs Out", "label": "L25. Docs Out" },
        //         { "value": "L30. Funded", "label": "L30. Funded" },
        //         { "value": "L35. Closed", "label": "L35. Closed" },
        //         { "value": "L40. Cancelled", "label": "L40. Cancelled" },
        //         { "value": "L41. Terms Not Issued", "label": "L41. Terms Not Issued" },
        //         { "value": "S05. Servicing Setup", "label": "S05. Servicing Setup" },
        //         { "value": "S10. Servicing", "label": "S10. Servicing" }
        //     ]
        // }, 
    ]
   

    // let manualEmailJson = [
    //     {
    //         "type": "dropdown",
    //         "placeholder": "Select Email Template",
    //         "label": "Email Template",
    //         "name": "emailtemplate",
    //         "options": [
                
    //         ]
    //     }
        
    // ];

    let manageAlertsJson = [
        {
            "type": "dropdown",
            "placeholder": "Select Section", 
            "label": "Select Section",
            "name": "section",
            "options": [
                { "value": "", "label": "Select Section" },
                // dealMenus
                { "label": "Loan Details", "value": "dealdetailedapp" },
                { "label": "Contacts", "value": "dealcontacts" },
                // { "label": "HMDA", "value": "dealhmda" },
                { "label": "Lead", "value": "deallead" },
                // { "label": "Underwriting Term Sheet", "value": "dealunderwritingtermsheet" },
            
                // processingMenus
                { "label": "Forms", "value": "processingforms" },
                { "label": "Credit", "value": "dealcredit" },
                { "label": "Files", "value": "dealfiles" },
                { "label": "Tools And Workflow", "value": "dealtoolsworkflow" },
            
                // underwritingMenus
                { "label": "Borrower Exposure", "value": "borrowerexposure" },
                { "label": "Underwriting Term Sheet", "value": "underwritingtermsheet" },
            
                // closingMenus
                { "label": "Clear To Close", "value": "cleartoclose" },
                { "label": "Fees", "value": "dealfees" },
                // { "label": "Rate And Points", "value": "dealratepoints" },
                { "label": "Wire Instructions", "value": "instructions" },
            
                // servicingMenus
                { "label": "Loan Summary", "value": "servicingloansummary" },
                { "label": "Servicing Setup", "value": "servicingservicingsetup" },
                { "label": "Recurring Billing", "value": "servicesrecurringbills" },
                { "label": "Fee Management", "value": "feemanagement" },
                { "label": "Payments", "value": "servicespayments" },
                { "label": "Payoff", "value": "servicespayoff" },
                { "label": "Servicing History", "value": "servicinghistory" },
                { "label": "Form 1098", "value": "servicesform1098" },
            
                // drawsMenus
                { "label": "Loan Summary", "value": "drawloansummary" },
                { "label": "Order Draw Request", "value": "orderdrawrequest" },
                // { "label": "Supporting Docs", "value": "supportingdocs" },
                // { "label": "Draw Requests Status", "value": "drawrequestsstatus" },
                // { "label": "Draw History", "value": "orderdrawhistory" }
            ]
        },
        {
            "type" : "textarea",
            "placeholder" : "Enter message",
            "name" : "reasonfordenial",
            "label" : "Message"
        }
    ];

    
    //loan assesments states
    const [loanassignment,setLoanassignment] = useState({});
    const [checkLoanAssignment,setCheckLoanAssignment] = useState({});
    const [isLoanAssignmentChange,setIsLoanAssignmentChange] = useState(false);
    const [loanError,setLoanError]=useState({});
    const [loanPopup,setLoanPopup] = useState(false);
    const user_data = JSON.parse(localStorage.getItem("userdata"));
    const user_id = user_data?.userId;

    //loan audit states
    const [LoanStatusHistory, setLoanStatusHistory] = useState([]);
    const [dropdownValue,setDropdownValue] = useState('');
    const [ActiveTab,setActiveTab] = useState('LoanHistory');
    const loanHistoryDropdown = [
        { value: 'stage', label: 'Stage' },
        // { value: 'phase', label: 'Phase' },
        { value: 'folder', label: 'Folder' }
    ];
    const LoanReassignmentsDropdown = [
        { value: 'adminto', label: 'Admin To' },
        { value: 'accountexecutive', label: 'Change Account Executive Officer To' },
        { value: 'changeopenerto', label: 'Change Opener/Setup To' },
        { value: 'changeunderwriterto', label: 'Change Underwriter To' },
        { value: 'changecloserto', label: 'Change Closer To' },
        { value: 'changefunderto', label: 'Change Funder To' },
        { value: 'changeaccountexceutiveto', label: 'Change Account Executive Assistant To' },
        { value: 'changeaccountexceutiveto2', label: 'Account Executive Assistant 2' },
        { value: 'changeprocessorto', label: 'Change Processor To' },
        { value: 'changeprocesser2to', label: 'Change Processor 2 To' },
        { value: 'changeaccountingto', label: 'Change Accounting To' },
        { value: 'changeservicerto', label: 'Change Servicer To' },
        { value: 'changeservicer2to', label: 'Change Servicer 2 To' },
        { value: 'changedrawmanagementto', label: 'Change Draw Management To' },
        {value : 'changeqcloanto', label : 'Change QC To'}
    ]
    
    const loanDetailsDropdown = [
        { value: 'Draw Interest Type', label: 'Draw Interest Type' },
        { value: 'Interest Reserves', label: 'Interest Reserves' },
        { value: 'Interest Rate', label: 'Interest Rate' },
        { value: 'Loan Term', label: 'Loan Term' },
        { value: 'Max LTV', label: 'Max LTV' },
        { value: 'Max LTC', label: 'Max LTC' },
        { value: 'Easystreet ARV', label: 'Easystreet ARV' },
        { value: 'Purchase Price', label: 'Purchase Price' },
        { value: 'Renovation Cost', label: 'Renovation Cost' },
        { value: 'Loan Type', label: 'Loan Type' },
        { value: 'Program Type', label: 'Program Type' },
        { value: 'Property Address', label: 'Property Address' },
        { value: 'Exitstrategy', label: 'Exitstrategy' },
        { value: 'Applicant Name', label: 'Applicant Name' },
        { value: 'Entity Name', label: 'Entity Name' },
        { value: 'Scheduled Closed Date', label: 'Scheduled Closed Date' },
        { value: 'Actual Closed Date', label: 'Actual Closed Date' }
    ];
    
    const [dropdownOptionLoanAudit,setDropdownOptionLoanAudit] = useState(loanHistoryDropdown);
    const [coloumnsData,setColoumnsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage,setPerPage] = useState(5);

    useEffect(() => {
        if(loanInfo?.loanid){
            setLoanid(loanInfo?.loanid)
            
        }
    }, [loanInfo?.loanid])

    useEffect(() => {
        if(loanid){
            
            getloanassignmentApi();
            categories();
            categoriesGet();
            handleLoanAuditinitialize()
            getAlerts();
            getCompleteLoanData();
            getFundsName();
        }
    }, [loanid]);

    useEffect(() => {
        if(location.pathname){
            const route = location.pathname;
            const cleanRoute = route.slice(1);
            let permission = userData?.securityInfo?.pipelinePermissions
           
            let checkedPermission =  hasViewPermission(cleanRoute, permission) 
              console.log('viewPermission',location.pathname,checkedPermission); 
              setPipelinePermissions(checkedPermission) 
            //   if(!checkedPermission){
            //     navigate('/pipeline')
            //   }
            }

            if(location.pathname){
                const route = location.pathname;
                const cleanRoute = route.slice(1);
                let permission = userData?.securityInfo?.pipelinePermissions
               
                let editPermission =  hasEditPermission(cleanRoute, permission) 
                  console.log('editPermission',location.pathname,editPermission); 
                  setEditPermissions(editPermission) 
                //   if(!checkedPermission){
                //     navigate('/pipeline')
                //   }
                }
            //setViewStatus()
      }, []);

    const getFundsName = () => {
        let type = 'fundDropdown'
        getAllFundManagmentAdmin('','',type,'')
            .then(function(response) {
                console.log('funds name', response);
                if (response?.result) {
                    setFundsDropdown(response.result?.data.map((fundoption) => ({
                        value: fundoption?.name,
                        label: fundoption?.name,
                        fundid : fundoption?.uuid,
                    })));
                } else {
                    setFundsDropdown([]);
                }
            })
            .catch((err) => {
                console.error("error", err);
                dispatch(hideLoader());
            });
    };
    

    useEffect(() => {
        console.log("loan id changed",loanInfo?.loanid);
        setEmailUuid(''); 
        setContent('');
        let updatedObj = {};
        for(const key in manualEmailData){
            updatedObj[key] = "";
        }
        setManualEmailData(updatedObj);
    }, [loanInfo?.loanid])
   

    const getCompleteLoanData = () => {  
        dispatch(showLoader());
        getLoanDetails(loanid).then(function (response) {
            if (response?.result) {
                
                console.log('get loan details API called response---->',response?.result);
                setLoanData(response?.result[0]);
                setLoanInfo(response?.result[0]);
              }
              dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            console.error.bind("get loan details API error", err);
        })
    }

    useEffect(() => {
        if(loanData && loanData?.updateworkflow){
            // Extracting updateworkflow data from the provided object
            const { updateworkflow } = loanData;

            // Setting the updateWorkflowData state with extracted data
            setUpdateWorkflowData(updateworkflow);
            setWorkFlowType("update"); 
        }
         
    },[loanData])

    /* Emails Tab */

   

    //calling get api to get admin templates list
    useEffect(() => {
        getEmails();
    },[])

    const normalizeHtml = (html) => {
       
         // Remove HTML tags but preserve all spaces and line breaks
        return html?.replace(/<\/?[^>]+(>|$)/g, '');
        // .replace(/\s+/g, ' ') // Replace multiple whitespace characters with a single space
        // .trim(); // Remove leading and trailing whitespace
    };

    function compareStates(state1, state2) {
    // Check if the number of keys in both states is the same
        const keys1 = Object.keys(state1);
        const keys2 = Object.keys(state2);
        if (keys1.length !== keys2.length) {
            return false;
        }

        // Check if the values of each key are the same
        for (let key of keys1) {
            if (key !== "template" && (state1[key] !== state2[key])) {
                return false;
            }
            if(key === "template"){
                const normalizedEditor1Content = normalizeHtml(singleOldEmailData[key]);
                const normalizedEditor2Content = normalizeHtml(manualEmailData[key]);
                if(normalizedEditor1Content !== normalizedEditor2Content){
                    return false; 
                }
            }
        }

        // If all key-value pairs match, return true
        return true;
    }

    //compare emails
    useEffect(() => {
        //if(uuidEmail){
            const isEqual = compareStates(manualEmailData, singleOldEmailData);
            setEmailBool(isEqual);
        //}
       
    },[uuidEmail,manualEmailData?.to,manualEmailData?.subject,manualEmailData?.template]);

    //set the content to editor when dropdown gets selected
    useEffect(() => {
        if(manualEmailData?.name){
            const templateExists = allEmailsData?.find(item => item.name == manualEmailData?.name);
            if(templateExists){
                console.log('template exists',templateExists);
                getSingleTemplate(templateExists?.templateid);
                //setContent(templateExists?.template);
                //setManualEmailData({...manualEmailData,["template"] : templateExists?.template});
            }
        }else{
            setContent("");
            //setManualEmailData({...manualEmailData,["template"] : ""}); 
        }
        
    },[manualEmailData?.name])

    useEffect(() => {
        setManualEmailData((prev) => ({
            ...prev,
            ['template']: content,
        }));
    },[content])

    //clearing data after email has been sent
    useEffect(() => {
        
        setContent("");
        setManualEmailData({...manualEmailData,
            from  : "",
            to : "",
            name : "",
            subject : ""
        });
        
    },[emailSent])

    //get single template data from API 
    const getSingleTemplate = (templateid) => { 
        dispatch(showLoader());
        getSingleEmailTemplateData(templateid,loanid).then(function (response) {
            if (response?.result) {
                
                console.log('get single template email response---->',response?.result);
                setContent(response?.result?.template);
                //setAllEmailsData(response?.result); 
              }
              dispatch(hideLoader());
        }).catch((err) => {
            dispatch(hideLoader());
            console.error.bind("get emails error", err);
        })
    }
    
    //get emails api
    const getEmails = () => { 
        //dispatch(showLoader());
        getAllEmails().then(function (response) {
            if (response?.result) {
                
                console.log('get emails response---->',response?.result);
                const sortedEmails = response.result.data.sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    if (nameA > nameB) return 1;
                    if (nameA < nameB) return -1;
                    return 0;
                });

                setAllEmailsData(sortedEmails);
                // setAllEmailsData(response?.result?.data);
              }
              //dispatch(hideLoader());
        }).catch((err) => {
            
            console.error.bind("get emails error", err);
        })
    }

    const handleChangeManualEmail = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        //if(name !== "template"){
            setManualEmailData({...manualEmailData,[name] : value}); 
        //}
        
    }

    const handleEmailNav = () => {
      
        setSectionDisplay(false);
        emailsHistory();
    }   

    const handleEmailCancel = () => {
        if(Object.keys(emailErrors)?.length !== 0){
            emailKeyErrors = {};
            setEmailErrors(emailKeyErrors);
        }
        setSectionDisplay(true);
        setEmailUuid(''); 
        setContent('');
        let updatedObj = {};
        for(const key in manualEmailData){
            updatedObj[key] = "";
        }
        setManualEmailData(updatedObj);
        
    }

    const handleBack = () => {
        if(Object.keys(emailErrors)?.length !== 0){
            emailKeyErrors = {};
            setEmailErrors(emailKeyErrors);
        }
        setSectionDisplay(true);
        if(uuidEmail){
            setEmailUuid(''); 
           
        }
            setContent('');
            let updatedObj = {};
            for(const key in manualEmailData){
                updatedObj[key] = "";
            }
            setManualEmailData(updatedObj);
        
    }

    const addErrorClass = (fieldName) => {
        const inputElement = document.querySelector(`.${fieldName}`);
        if (inputElement) {
          inputElement.classList.add('invalid-input');
          console.log("Error class added to element:", inputElement);
        }
      };
    
      // Remove error classes on all inputs
    const removeErrorClass = (fieldName) => {
        
        const searchWrpElement = document.querySelector(`.${fieldName}`);
        if (searchWrpElement) {
                searchWrpElement.classList.remove("invalid-input");
        }
    }

    useEffect(() => {
        const inputElement = document.querySelector(`.jodit-react-container`);
        if(inputElement && inputElement.classList.contains('invalid-input')){
            removeErrorClass("jodit-react-container");
        }
    },[manualEmailData?.template])

    const validateEmailFields = () => {
        //const emailDataKeys = Object.keys(manualEmailData);
        emailKeyErrors = {};
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isWhitespace = /^<p>(&nbsp;|\s)*<br>\s*<\/p>$/; 
        for(let key in manualEmailData){
            if((key !== "template")&& (!manualEmailData[key] || manualEmailData[key] === undefined)){
                emailKeyErrors[key] = "required";
            }
            if(key === "template" && (isWhitespace?.test(manualEmailData[key]) || manualEmailData[key] === "" || manualEmailData[key] === "<p><br></p>" || !manualEmailData[key] || manualEmailData[key] === undefined)){
                //emailKeyErrors[key] = "required";
                addErrorClass("jodit-react-container");
            }
            if((key === "from" || key === "to") && (!manualEmailData[key] || manualEmailData[key] === undefined || !isValidEmail?.test(manualEmailData[key]))){
                
                emailKeyErrors[key] = "not valid";
            }
        }
        console.log("email errors",emailKeyErrors);
        setEmailErrors(emailKeyErrors);  
    }

    const handleSendEmail = () => {
        validateEmailFields();
        const updatedEmailData = {...manualEmailData , loanid : loanid};
        if(Object.keys(emailKeyErrors)?.length === 0){
            console.log("Send email data",updatedEmailData);
            // calling api
            dispatch(showLoader());
            sendEmail(updatedEmailData).then(function (response) {
                if (response?.result == "Email Sent successfully") {
                    console.log("Send email response",response?.result);
                    setPopupOpen(true);
                    setmessage(response?.result);
                    setEmailSent(!emailSent);
                }
                dispatch(hideLoader());
            }).catch((err) => {
                dispatch(hideLoader());
                console.error.bind("my account error", err);
            }) 
        }
    }

    const emailsHistory = () => { 
        dispatch(showLoader());
        getEmailsHistory(loanid,'pipeline').then(function (response) {
            if (response?.result) {
                
                console.log('get emails history response---->',response?.result);
                setEmailsHistoryData(response?.result); 
              }
              dispatch(hideLoader());
        }).catch((err) => {
            
            console.error.bind("get emails history error", err);
            dispatch(hideLoader());
        })
    }

    const handleEmailView = (uuid) => {
        if(Object.keys(emailErrors)?.length !== 0){
            emailKeyErrors = {};
            setEmailErrors(emailKeyErrors);
        }
        setSectionDisplay(true);
        dispatch(showLoader());
        getSingleEmailHistory(uuid).then(function (response) {
            if (response?.result) {
                console.log('get single emails history response---->',response?.result);
                const responseData = response?.result[0];
                setContent(responseData?.emailTemplate);
                setSingleOldEmailData({
                    "from" : responseData?.from,
                    "to": responseData?.toEmailid[0], // Assuming you want only the first email
                    "name": responseData?.templateName, // Set as required
                    "subject": responseData?.subject, // Set as required
                    "template": responseData?.emailTemplate,
                })
                setManualEmailData({
                    "from" : responseData?.from,
                    "to": responseData?.toEmailid[0], // Assuming you want only the first email
                    "name": responseData?.templateName, // Set as required
                    "subject": responseData?.subject, // Set as required
                    "template": responseData?.emailTemplate,
                }); 
                setEmailUuid(responseData?.uuid);
            }
              dispatch(hideLoader());
        }).catch((err) => {
            
            console.error.bind("get single emails history error", err);
            dispatch(hideLoader());
        })
    }

    const getOrdinalDay = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
    };

    const convertDateFormat = (dateString) => {
        const createdDate = new Date(dateString);
        const month = createdDate.toLocaleString('en-US', { month: 'long' });
        const day = createdDate.getDate();
        const year = createdDate.getFullYear();
        const ordinalDay = getOrdinalDay(day);
        return `${month} ${day}${ordinalDay} , ${year}`;
    };

    useEffect(() => {
        dispatch(showLoader());
        getallUsersDropdown().then(function (response) {
            console.log('privacymenunewwegetallUsers', response.result);
            if (response?.result?.data) {
                const activeUsers = response?.result?.data.filter(user => user.securityInfo && user.securityInfo.status === "ACTIVE");
                // const mappedUsernames = response?.result?.data.map(item => item.userInfo.username);
                const options = activeUsers.map(user => ({
                    value: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
                    label: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
                    userid : user?.userid
                  }));
              setUsers(options);
              dispatch(hideLoader());
            }
          })
          .catch((err) => {
            console.error.bind("my account error", err);
            dispatch(hideLoader());
          })
          getallUsersDropdown('loselectable').then(function (response) {
            console.log('privacymenunewwegetallUsers', response.result);
            if (response?.result?.data) {
                const activeUsers = response?.result?.data.filter(user => user.securityInfo && user.securityInfo.status === "ACTIVE");
                const options = activeUsers.map(user => ({
                    value: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
                    label: `${user?.userInfo?.firstname} ${user?.userInfo?.lastname}`,
                    accountexecutiveid : user?.userid,
                  }));
              setAccountExecutiveDropdown(options);
              dispatch(hideLoader());
            }
          })
          .catch((err) => {
            console.error.bind("my account error", err);
            dispatch(hideLoader());
          })
    }, []);
    //categories
    const categories = async () => {
        categoriesforloan().then(function (response) {
            if (response?.result) {
                console.log("categories from lookups --->",response?.result?.data[0]?.lookupOptions);
                let data = response?.result?.data[0]?.lookupOptions

                data.sort(function (a, b) {
                    if (a.option < b.option) {
                      return -1;
                    }
                    if (a.option > b.option) {
                      return 1;
                    }
                    return 0;
                  });

                const dropdownOptions = data.map(item => ({
                    value: item.option, 
                    label: item.option
                  }));
                setcategoriesDropdown(dropdownOptions);
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    };
    const categoriesGet = ()=>{
        // change api to get loans data
        dispatch(showLoader());
        genSingleLoanDeal(loanid).then(function (response) {
            if (response?.result) {
                console.log("categories for categoriesforloan --->",response?.result?.[0]?.categoriesforloan);
                if(response?.result?.[0]?.categoriesforloan){
                    setCategoriesforloanData({
                        categoriesforloan : response?.result?.[0]?.categoriesforloan,
                        propertyid : response?.result?.[0]?.propertyinfo?.propertyid
                    });
                    if(previousCategories?.length === 0){
                        setPreviousCategories(response?.result?.[0]?.categoriesforloan);
                    }
                    
                }else{
                    setCategoriesforloanData({
                        categoriesforloan : [],
                    });
                }
                dispatch(hideLoader());
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
            })
    }
    const categoriesAdd = ()=>{
        dispatch(showLoader());
        let apiData = {...categoriesForloanData,loanid : loanid};
        //change api to add
        LoanDeal(apiData).then(function (response) {
            if (response?.result) {
                dispatch(hideLoader());
                setCatgoryPopUp(true);
                setPreviousCategories(categoriesForloanData.categoriesforloan);
                categoriesGet();

                
                isSelectionChanged();
            }
            }).catch((err) => {
              console.error.bind("my account error", err);
              dispatch(hideLoader());
            })
    }
    const handleSaveCatgory = ()=>{
        let error = false
        if(categoriesForloanData?.categoriesforloan?.length===0){
           error = true;
        };
        setCategoryError(error);
        if(!error){
            categoriesAdd();
        }
    }
    
    //loan assesments
    const loanInputFields = [
        { name: 'adminto', label: 'Admin To', type: 'select', options: users },
        { name: 'accountexecutive', label: 'Change Account Executive Officer To', type: 'select', options: accountexecutiveDropdown },
        { name: 'changeopenerto', label: 'Change Opener/Setup To', type: 'select', options: users },
        { name: 'changeunderwriterto', label: 'Change Underwriter To', type: 'select', options: users },
        { name: 'changecloserto', label: 'Change Closer To', type: 'select', options: users },
        { name: 'changefunderto', label: 'Change Funder To', type: 'select', options: users },
        // { name: 'changearchiverto', label: 'Change Archiver To', type: 'select', options: users },
        { name: 'changeaccountexceutiveto', label: 'Change Account Executive Assistant To', type: 'select', options: users },
        { name: 'changeaccountexceutiveto2', label: 'Change Account Executive Assistant 2 To', type: 'select', options: users },
        { name: 'changeprocessorto', label: 'Change Processor To', type: 'select', options: users },
        { name: 'changeprocesser2to', label: 'Change Processor 2 To', type: 'select', options: users },
        { name: 'changeaccountingto', label: 'Change Accounting To', type: 'select', options: users },
        { name: 'changeservicerto', label: 'Change Servicer To', type: 'select', options: users },
        { name: 'changeservicer2to', label: 'Change Servicer 2 To', type: 'select', options: users },
        { name: 'changedrawmanagementto', label: 'Change Draw Management To', type: 'select', options: users },
        { name: 'changeqcloanto', label: 'Change QC To', type: 'select', options: users }
        // Add more input field configurations here
    ];
    const getloanassignmentApi = ()=>{
        dispatch(showLoader());
        getLoanassignment(loanid).then(function (response) {
            console.log('response--->',response)
        if (response?.result) {
            console.log('response-----------------> ',response?.result?.[0]?.loanassignment );
            if(response?.result?.[0]?.applicant?.[0]?.accountexecutive && response?.result?.[0]?.loanassignment ){
                setLoanassignment({loanassignment : {...response?.result?.[0]?.loanassignment , accountexecutive : response?.result?.[0]?.applicant?.[0]?.accountexecutive,
                    accountexecutiveid : response?.result?.[0]?.applicant?.[0]?.accountexecutiveid,
                } ,});
            setCheckLoanAssignment({loanassignment : {...response?.result?.[0]?.loanassignment , accountexecutive : response?.result?.[0]?.applicant?.[0]?.accountexecutive,
                accountexecutiveid : response?.result?.[0]?.applicant?.[0]?.accountexecutiveid,
            } ,});
            }else if (response?.result?.[0]?.applicant?.[0]?.accountexecutive){
                setLoanassignment({loanassignment : {accountexecutive : response?.result?.[0]?.applicant?.[0]?.accountexecutive,
                    accountexecutiveid : response?.result?.[0]?.applicant?.[0]?.accountexecutiveid,
                    } ,});
                    setCheckLoanAssignment({loanassignment : {accountexecutive : response?.result?.[0]?.applicant?.[0]?.accountexecutive,
                        accountexecutiveid : response?.result?.[0]?.applicant?.[0]?.accountexecutiveid,
                    } ,});
            }
            else if(response?.result?.[0]?.loanassignment){
                setLoanassignment({loanassignment : {...response?.result?.[0]?.loanassignment 
                } ,});
                setCheckLoanAssignment({loanassignment : {...response?.result?.[0]?.loanassignment 
                } ,});
            }
            else{
                setLoanassignment({loanassignment : {...response?.result?.[0]?.loanassignment} ,});
                setCheckLoanAssignment({loanassignment : {...response?.result?.[0]?.loanassignment} ,});
            }
           dispatch(hideLoader());
        }else{
            setLoanassignment({});
            setCheckLoanAssignment({});
        }
        }).catch((err) => {
          console.error.bind("error", err);
          dispatch(hideLoader());
        })

};
useEffect(() => {
    const isFormChanged =
        JSON.stringify(loanassignment) !== JSON.stringify(checkLoanAssignment);
         setIsLoanAssignmentChange(isFormChanged);
    console.log(isFormChanged);
}, [loanassignment, checkLoanAssignment]);
    const handleAssigmentAdd =()=>{
        let errors = {};
    //     let fieldsToCheck = { "adminto" : "adminto","changeloanoffto" : "changeloanoffto",
    //     "changeopenerto":"changeopenerto", "changeunderwriterto":"changeunderwriterto",
    //     "changecloserto":"changecloserto","changefunderto":"changefunderto",
    //    "changeloanofficerassistantto":"changeloanofficerassistantto",
    //     "changeprocesser2to":"changeprocesser2to","changeaccountingto":"changeaccountingto",
    //     "changeprocessorto": "changeprocessorto","changeservicerto" : "changeservicerto","changeservicer2to" : "changeservicer2to","changedrawmanagementto": "changedrawmanagementto"
    // };
        // for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
        //     if (!loanassignment?.loanassignment?.[fieldName]?.trim()) {
        //         errors[fieldName] = `Please Enter ${fieldLabel} `;
        //     }
        // };
        setLoanError(errors);

        if (Object.keys(errors).length === 0) {
            // let data = {...loanassignment}
            let finalAssignment = { ...loanassignment };
            let accountexecutive = loanassignment?.loanassignment?.accountexecutive;
            if (finalAssignment.loanassignment) {
                finalAssignment.loanassignment = { ...finalAssignment.loanassignment };
                delete finalAssignment.loanassignment.accountexecutive;
            }
            let accountexecutiveid = loanassignment?.loanassignment?.accountexecutiveid;
            if (finalAssignment.loanassignment) {
                finalAssignment.loanassignment = { ...finalAssignment.loanassignment };
                delete finalAssignment.loanassignment.accountexecutiveid;
            }
            let apiData = {
                accountexecutive: accountexecutive,
                accountexecutiveid : accountexecutiveid,
                ...finalAssignment,
                loanid: loanInfo?.loanid
            };
            console.log('data to api---->',apiData);
            dispatch(showLoader()); 
            addloanassignment(apiData,loanid).then(function (response) {
                console.log('response--->',response)
            if (response?.result) {
                console.log('response-----------------> ',response?.result);
                setLoanPopup(true);
                getloanassignmentApi();
                dispatch(hideLoader());
            }
            }).catch((err) => {
              console.error.bind("error", err);
              dispatch(hideLoader());
            })
        }
    };

    const handleLoanChange = (e) => {
        const { name, value } = e.target;
        const selectedUser = users.find(user => user.value === value);
        if(name ==='accountexecutive'){
            const matchedUser = accountexecutiveDropdown?.find(user => user.value === value);
            console.log(matchedUser,accountexecutiveDropdown);
            setLoanassignment((prev) => ({
                ...prev,
                loanassignment : {
                    ...prev?.loanassignment,
                    'accountexecutive' : value,
                    'accountexecutiveid' : matchedUser?.accountexecutiveid ? matchedUser?.accountexecutiveid : '',
                }
            }));
        }else{
            setLoanassignment((prev) => ({
                ...prev,
                loanassignment : {
                    ...prev?.loanassignment,
                    [name] : {
                        name : value,
                        userid :selectedUser?.userid
                    }
                }
            }));
        }
        
    };

    useEffect(() => {
        console.log(loanassignment);
    }, [loanassignment]);
    const handleAlerts = (e) => {
        let {name,value} =  e.target;
        setManualAlertsData({...manualAlertsData,[name] : value});
    }

    const handleAlertFocus = (e) => {
        setAlertsErrors({...alertsErrors,[e.target.name] : ""});
    }

    const alertValidation = ()=> {
        for(const key in manualAlertsData){
            if(manualAlertsData[key] === "" || manualAlertsData[key] === undefined){
                alertserrors[key] = "required";
            }
            setAlertsErrors(alertserrors);
        }
    }



    useEffect(() => {
        console.log("tablework flow data",tableWorkFlowData);
    },[tableWorkFlowData])

    const getAlerts = () => {
        dispatch(showLoader());
        getToolWorkflowAlerts(loanid, true).then(function(response) {
            const res = response?.result;
            if(response?.statusCode === 200 && res?.length > 0) {
                console.log('get alert api tools work flow response', res);
                setTableWorkFlowData(res); 
            }else{
                setTableWorkFlowData([]); 
            }
            dispatch(hideLoader());
        })
        .catch((err) => {
            console.error("get alert api tools work flow error ", err); 
            dispatch(hideLoader());
        });
    }

    const handleAlertId = (alert_id)=>{
        console.log(alert_id);
        setAlertDeleteId(alert_id);
        setAlertDeleteConfirmation(true);
    }

   const handleDeleteAlert = () => {
        // console.log(alert_id);
        setAlertDeleteConfirmation(false);
        dispatch(showLoader());
        deleteToolWorkflowAlert(alertDeleteId).then(function(response) {
            if(response) {
                console.log('delete alert api tools work flow response', response);
                if(response?.statusCode === 200 && response?.result?.trim() === "deleted successfully"){
                    setPopupOpen(true);
                    setmessage("Alert Message Deleted Successfully");
                    getAlerts();
                    // setAlertBool(!alertBool); 
                    
                }else if (response?.result ==='Multiple alerts cannot be created'){
                    setPopupOpen(true);
                    setmessage(response?.result);
                }
                dispatch(hideLoader());
            }
        })
        .catch((err) => {
            console.error("delete alert api tools work flow error ", err); 
            dispatch(hideLoader());
        });
   }

    const handleAlertsSubmit = () => {
        alertValidation();
        console.log(loanid);
        if(Object.keys(alertserrors)?.length === 0){
            dispatch(showLoader());
            addToolWorkflowAlerts(manualAlertsData,loanid).then(function(response) {
                if(response) {
                    // console.log('add alert api tools work flow response', response);
                    if(response?.statusCode === 200 && response?.result?.trim() === 'alert added successfully'){
                       
                        setPopupOpen(true);
                        setmessage("Alert Message Added Successfully");
                        setManualAlertsData({
                            "section" : "",
                            "reasonfordenial" : ""
                        });
                        getAlerts();
                        // setAlertBool(!alertBool); 
                        
                    }else if (response?.result ==='Multiple alerts cannot be created'){
                        setPopupOpen(true);
                        setmessage(response?.result);
                    }
                    dispatch(hideLoader());
                }
            })
            .catch((err) => {
                console.error("add alert api tools work flow error ", err); 
                dispatch(hideLoader());
            });
        }
    }

    const callGetToolsApi = () => {
        dispatch(showLoader());
        genSingleLoanDeal(loanid).then(function(response){
            const res = response?.result;
            console.log("tools and workflow get api response",res);
            if(res?.length !== 0){
                setDealId(res[0]?.dealid);//deal id
                if(res[0].updateworkflow){
                    setUpdateWorkflowData(res[0].updateworkflow);
                    setWorkFlowType("update");
                }
            }
            dispatch(hideLoader());
        }).catch((error) => {
                dispatch(hideLoader());
                console.log("tools and workflow get api error" ,error);
        })
    }
    
    useEffect(() => {
        if(loanid && toolBool){
            //callGetToolsApi();
        }
    },[toolBool, loanid])

    const validateTools = (toolData) => {
        // const checkIsNull = Object.keys(toolData).every(key => toolData[key] === '');
        // return checkIsNull;
        // console.log(toolData);
        const keyElements = Object.keys(toolData);
        keyElements.map(key => {
            if(toolData[key] === ""){
                errors[key] = "required";
            }
        });
        console.log("errors tools and work flow --->",toolsError);
        setToolsError(errors);
    }

    const handleFocus = (e) =>{
        const {name,value} = e.target;
        setToolsError({...toolsError,[name] : ''});
    }

    const handleChangeWorkFlow = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const currentDateTime = new Date().toISOString();
        console.log(name,currentDateTime)
        if(name === 'phase'){
            setUpdateWorkflowData({...updateWorkflowData,
                [name] : value,
                ['phasedate'] : currentDateTime
            });
        }else if(name === 'folder'){
            setUpdateWorkflowData({...updateWorkflowData,
                [name] : value,
                ['folderdate'] : currentDateTime
            });
        }else if(name === 'funds'){
            const selectedFund = fundsDropdown.find((fund) => fund.value === value);
            setUpdateWorkflowData({...updateWorkflowData,
                [name] : value,
                fundid: selectedFund ? selectedFund?.fundid : '',
            });
        }else{
            setUpdateWorkflowData({...updateWorkflowData,[name] : value});
        }
    }

    const loanDealCallApi = (tabVal, toolsPayload) => {
        let msg = '';
        dispatch(showLoader());
        updateWorkflow(toolsPayload).then(function(response){
            const res = response?.result;
            console.log("tools and workflow response",res);
            if(response?.statusCode === 200 && res === "loan data updated successfully"){
                if(tabVal === "workflow"){
                    msg = (workflowType === "create") ? "Workflow Submitted Successfully" : "Workflow Updated Successfully";
                    setPopupOpen(true);
                    setmessage(msg);
                    getCompleteLoanData();
                    //setToolBool(!toolBool);
                }
                
            }
            dispatch(hideLoader());
        }).catch((error) => {
                dispatch(hideLoader());
                console.log(tabVal + "error" ,error);
        })
    }

    //submit or update
    const handleWorkFlow  = (tabName,workType) => {
        validateTools(updateWorkflowData);
       
        if(Object.keys(errors).length === 0){
            console.log("update work flow data",updateWorkflowData);
            let toolsPayload = {
             "loanid" : loanid
            }
            if(workType === "create"){
                toolsPayload.updateworkflow = updateWorkflowData;
                loanDealCallApi(tabName, toolsPayload);
            }else{
                toolsPayload.dealid = dealId;
                toolsPayload.updateworkflow = updateWorkflowData;
                loanDealCallApi(tabName, toolsPayload);
            }
        }
    }

    //submit or update
    const handleManualEmailTemplate  = () => {
        validateTools(manualEmailData);
        if(Object.keys(errors).length === 0){
            console.log("manual email  data",manualEmailData);
        }
    }
 
    const closePopup = () => {
        setPopupOpen(false);
        setCatgoryPopUp(false);
    };
    const closePopup2 =()=>{
        setLoanPopup(false);
    }
    const closePopup3 =()=>{
        setAlertDeleteConfirmation(false);
    }
    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                backgroundColor: '#E9F0E6',

            },
        },
        table: {
            style: {
                border: '1px solid #CCD8F6',
            },
        },
    };
       //loan audit functions
    const columns = [
        {
            name: 'SECTION',
            selector: (row) => {const matchedOption = manageAlertsJson[0]?.options.find(option => option.value === row?.section)
            return matchedOption?.label},
            minWidth: '15%',
            maxWidth: '15%',
        },
        {
            name: 'DATE',
            selector: (row) => row?.created &&  moment.utc(row?.created).format("MM-DD-YYYY"),
            sortable: true,
            minWidth: '15%',
            maxWidth: '15%',
        },
        {
            name: 'MESSAGE',
            selector: (row) => row?.reasonfordenial || '',
            minWidth: '22%',
            maxWidth: '22%',
        },
        {
            name: 'CREATED BY',
            selector: (row) => row?.createdby?.username,
            minWidth: '10%',
            maxWidth: '10%',
        },
        {
            name: 'CANCELLED BY',
            selector: (row) => row?.cancelledby?.username,
            minWidth: '15%',
            maxWidth: '15%',
        },
        {
            name: 'CANCELLED DATE',
            selector: (row) => row?.cancelledon ? moment.utc(row?.cancelledon).format("MM-DD-YYYY") : '',
            minWidth: '15%',
            maxWidth: '15%',
        },
        {
            name: 'ACTIONS',
            cell: (row,index) => (
                <><div className="btn-group">
                <button id={`deleteAlert-${index}`} type="button" disabled={row?.status ==='CANCELLED'} className="btn btn-outline-secondary" onClick = {() => handleAlertId(row?.alertid)} style={{
                                    pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                    userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                                    opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                                  }}>
                    <span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span>delete </span>
                </button>
            </div>
                </>
            ),
            minWidth: '8%',
            maxWidth: '8%',
        },
    ];

 
    const handleLoanAuditinitialize = ()=>{
        setCurrentPage(1);
        setPerPage(5)
        setColoumnsData(loanhistoryColumns)
        let type = 'loanhistory';
        let workflow = '';
        let assignment = '';
        let field = '';
        setDropdownOptionLoanAudit(loanHistoryDropdown);
        getloanStatusHistory(assignment,workflow,type,field);
        setActiveTab('LoanHistory');
        setDropdownValue('');
    }
    const handleTabChange =(tab)=>{
        setCurrentPage(1);
        setPerPage(5)
        setActiveTab(tab);
        setDropdownValue('');
        let workflow = '';
        let assignment = '';
        let field= ''
        if(tab==='LoanHistory'){
            let type = 'loanhistory';
            getloanStatusHistory(assignment,workflow,type,field);
            setColoumnsData(loanhistoryColumns)
            setDropdownOptionLoanAudit(loanHistoryDropdown);
        }else if(tab==='LoanDetails'){
            let type = '';
            getloanStatusHistory(assignment,workflow,type,field);
            setColoumnsData(loandetailsColumns)
            setDropdownOptionLoanAudit(loanDetailsDropdown);
        }else if(tab==='LoanReassignments'){
            let type = 'loanreassignments'
            getloanStatusHistory(assignment,workflow,type,field);
            setColoumnsData(loanreassignmentColumns)
            setDropdownOptionLoanAudit(LoanReassignmentsDropdown);
        }else if(tab==='emaillog'){
            getloanStatusHistoryNotes();
            setColoumnsData(loannotesColumns)
           
        }
    }
    const getloanStatusHistory = (assignment,workflow,type,field) => {
        dispatch(showLoader());
        getloanHistory(loanid,assignment,workflow,type,field).then(function (response) {
          if(response?.result && Array.isArray(response?.result) && response?.result.length > 0) {
            setLoanStatusHistory(response?.result);
          }else{
            setLoanStatusHistory([]);
          }
          dispatch(hideLoader())
          }).catch((err) => {
            console.error.bind("updateStage error", err);
            dispatch(hideLoader())
          })
      };
      const getloanStatusHistoryNotes = () => { 
        dispatch(showLoader());
        getEmailsHistory(loanid,'notes').then(function (response) {
            if(response?.result && Array.isArray(response?.result) && response?.result.length > 0) {
                setLoanStatusHistory(response?.result);
              }else{
                setLoanStatusHistory([]);
              }
              dispatch(hideLoader());
        }).catch((err) => {
            
            console.error.bind("get emails history error", err);
            dispatch(hideLoader());
        })
    }
      const handlePageChange = page => {
        setCurrentPage(page);
    };
    const handleRowsPerPageChange = newPerPage => {
        setPerPage(newPerPage);
        setCurrentPage(1); 
    };

    const handleLoanauditFilter=(e)=>{
        setCurrentPage(1);
        setPerPage(5)
        if(ActiveTab==='LoanHistory'){
            let workflow = e.target.value;
            let assignment = ''
            setDropdownValue(e.target.value)
            let type='loanhistory'
            let field = ''
            getloanStatusHistory(assignment,workflow,type,field)
        }else if(ActiveTab === 'LoanDetails'){
            let workflow = '';
            let assignment = ''
            let type=''
            setDropdownValue(e.target.value)
            let field=e.target.value;
            getloanStatusHistory(assignment,workflow,type,field)
        }else if(ActiveTab === 'LoanReassignments'){
            let workflow = '';
            let field = ''
            let assignment = e.target.value;
            setDropdownValue(e.target.value)
            let type = 'loanreassignments'
            getloanStatusHistory(assignment,workflow,type,field)
        }else if(ActiveTab === 'emaillog'){
            let workflow = '';
            let field = ''
            let assignment = e.target.value;
            setDropdownValue(e.target.value)
            let type = 'loanreassignments'
            getloanStatusHistory(assignment,workflow,type,field)
        }
        
    }
    const loanhistoryColumns = [
        {
            name: 'WORKFLOW',
            selector: row => {
                const match = loanHistoryDropdown?.find(item => item.value === row?.workflow);
                return match ? match?.label : row?.workflow;
            },
            width: '18%',
        },
        {
            name: 'FROM',
            selector: row => row.previousstatus || '',
            width: '19%',
        },
        {
            name: 'TO',
            selector: row => row.currentstatus,
            width: '19%',
        },
        {
            name: 'FROM USER',
            selector: row => row.previousstatuschangedby || '',
            width: '19%',
        },
        {
            name: 'TO USER',
            selector: row => row.changedby,
            width: '16%',
        },
        {
            name: 'DATE CHANGED',
            selector: row => row.changedon ? new Date(row.changedon).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "",
            width: '9%',
        },];
        const loanreassignmentColumns = [
            {
                name: 'Assignment',
                selector: row => {
                    const match = LoanReassignmentsDropdown?.find(item => item.value === row?.assignment);
                    return match ? match?.label : row?.assignment;
                },
                width: '23%',
            },
            {
                name: 'CHANGED FROM',
                selector: row => row?.changedfrom || '',
                width: '23%',
            },
            {
                name: 'CHANGED TO',
                selector: row => row?.changedto || '',
                width: '23%',
            },
            {
                name: 'CHANGED BY',
                selector: row => row.changedby || '',
                width: '23%',
            },
            {
                name: 'DATE CHANGED',
                selector: row => row.changedon ? new Date(row.changedon).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "",
                width: '8%',
            },];
            
            const formatValueType = (format,value)=>{
                let currencyFormatValues = ["Purchase Price","purchase price","Renovation Cost","Easystreet ARV"];
                let dateFormatValues = ["Scheduled Closed Date","Actual Closed Date"];
                let percentageFormatValues = ["Max LTC","Max LTV","Interest Rate"];
                let loantermMonth = ["Loan Term"];
                const date = new Date(value);
                if (value !== undefined && value !== null && value.length !== 0) {
                    if(currencyFormatValues?.includes(format)){
                       return formatCurrency(value);
                    }else if(dateFormatValues?.includes(format)){
                        return date?.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
                    }else if(percentageFormatValues?.includes(format)){
                        return value ? value + '%' : '';
                    }else if(loantermMonth?.includes(format)){
                        return value ? value + ' months' : '';
                    }else{
                        return value;
                    }
                }else{
                    return '';
                }
            };
            
            const loandetailsColumns = [
                {
                    name: 'FIELD',
                    selector: row => {
                        const match = loanDetailsDropdown?.find(item => item.value === row?.field);
                        return match ? match?.label : row?.field;
                    },
                    width: '23%',
                },
                {
                    name: 'CHANGED FROM',
                    selector: row => row?.changedfrom ? (formatValueType(row?.field,row?.changedfrom)) : '',
                    width: '23%',
                },
                {
                    name: 'CHANGED TO',
                    selector: row => row?.changedto ? (formatValueType(row?.field,row?.changedto))  : '',
                    width: '23%',
                },
                {
                    name: 'CHANGED BY',
                    selector: row => row.changedby || '',
                    width: '23%',
                },
                {
                    name: 'DATE CHANGED',
                    selector: row => row.changedon ? new Date(row.changedon).toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "",
                    width: '8%',
                },
            ];
            const loannotesColumns = [
                {
                    name: 'DATE',
                    selector: row => row?.created ? convertDateFormat(row?.created) : '',
                    width: '25%',
                },
                {
                    name: 'FROM',
                    selector: row => row?.from || '',
                    width: '25%',
                },
                {
                    name: 'TO',
                    selector: row => row?.toEmailid || '',
                    width: '25%',
                },
                {
                    name: 'Subject',
                    selector: row => row.subject || '',
                    width: '25%',
                },
            ];

            // Function to check if the new selection is different from the previous one
            // const isSelectionChanged = () => {

            //     if(categoriesForloanData.categoriesforloan?.length === 0){
            //         return false;
            //     }else{
            //         return (
            //             previousCategories?.length === categoriesForloanData.categoriesforloan?.length
                        
            //         );
            //     }
                
            //     // if (categoriesForloanData.categoriesforloan?.length === 0 && categoriesForloanData.categoriesforloan?.length === 0) {
            //     //     return false;  // Button will be enabled
            //     // }else if (previousCategories?.length === 0 && categoriesForloanData.categoriesforloan?.length > 0) {
            //     //     return false;  // Button will be enabled
            //     // }else{
            //     //     // Otherwise, check if the lengths are different to detect changes
            //     //     return previousCategories?.length === categoriesForloanData.categoriesforloan?.length;
            //     // }
                
                
            
            // };

            const isSelectionChanged = () => {
                // console.log("previous", previousCategories);
                // console.log("categoriesForloanData", categoriesForloanData);
            
                const { categoriesforloan } = categoriesForloanData;
            
                // Check if elements are identical (ignoring order)
                    const areEqual =
                    previousCategories.every((item) => categoriesforloan.includes(item)) &&
                    categoriesforloan.every((item) => previousCategories.includes(item));

                    return !areEqual; // Selection changed if arrays are not equal
            };
            
            
            useEffect(() => {
                // Update previousCategories every time the selected categories change
                //if(previousCategories?.length === 0){
                    //setPreviousCategories(categoriesForloanData.categoriesforloan);
                //}
                isSelectionChanged();
                
            }, [categoriesForloanData.categoriesforloan]);
            

    return (
        <div id="layout-wrapper" className="dashboard">
            {loading ? <Loader/> : null} 
            {alertDeleteConfirmation && <SweetAlert
                warning
                showCancel
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                title="Are you sure?"
                onConfirm={handleDeleteAlert}
                onCancel={closePopup3}
                focusCancel={true}
                customButtons={[
                    React.createElement('button', {
                        id: 'delete-alert-yes',
                        className: 'btn btn-primary',
                        onClick: handleDeleteAlert,
                        style: { marginRight: '8px' ,fontSize: '16px'}
                    }, 'Yes'),
                    React.createElement('button', {
                        id: 'delete-alert-no',
                        className: 'btn btn-light',
                        onClick: closePopup3,
                        style: { fontSize: '16px'}
                    }, 'No')
                ]}
            >
                    <p>Do you really want to delete this Alert?</p>
            </SweetAlert>}
           
            {isPopupOpen && (
                <PopupMessage
                type={popupMessage === "Alert Message Deleted Successfully" || !popupMessage ? 'warning' : 'success'}
                message={popupMessage}
                onClose={closePopup}
                />
            )}

{catgoryPopUp && (
                <PopupMessage
                type={'success'}
                message={"Saved Successfully"}
                onClose={closePopup}
                />
            )}
            {loanPopup && 
            (
                <PopupMessage
                type={'success'}
                message={"Saved Successfully"}
                onClose={closePopup2}
                />
            )
            }
            <PipelineHeader />
            <div className="dashboard_block">
            <PipelineStatus />
                <h2 className="form_title">{isDealWorkFlow ? 'Underwriting' : 'Servicing'} <span className="space">&#10095;</span> <span className="sub">Tools And Workflow</span>
            </h2>
            <div className="card user_management detailed_app">
                <div className="card-body my_account">
                    <ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
                        <li className="nav-item">
                            <a id="updateWorkFlowTab" className="nav-link active" data-bs-toggle="tab" href="#ENTITY" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">Update Workflow</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a id="manualEmailTab" className="nav-link " data-bs-toggle="tab" href="#APPLICANT" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">Manual EMAIL</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a id="manageAlertsTab" className="nav-link" data-bs-toggle="tab" href="#HMDA" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">MANAGE ALERTS</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a id="manageCategoriesTab" className="nav-link " data-bs-toggle="tab" href="#PROPERTY" role="tab">
                                <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                <span className="d-none d-sm-block">MANAGE CATEGORIES</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a id="loanAssignmentTab" className="nav-link" data-bs-toggle="tab" href="#LOAN" role="tab">
                                <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                <span className="d-none d-sm-block">loan assignment</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a id="loanAuditTab" className="nav-link" data-bs-toggle="tab" onClick={handleLoanAuditinitialize} href="#REGULATION" role="tab">
                                <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                                <span className="d-none d-sm-block">LOAN AUDIT</span>
                            </a>
                        </li>


                    </ul>


                    <div className="tab-content pt-15 text-muted">
                        <div className="tab-pane active" id="ENTITY" role="tabpanel" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>



                            <div className="page-title-box d-sm-flex align-items-center justify-content-between pb-0">

                            </div>
                            <div className="form_section">
                                <div className="row">
                                    {updateWorkflowDataJson.map((updatework,index) => (
                                        (updatework?.type === "dropdown") ?
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>{updatework?.label}</label>
                                                    <select id={`${updatework?.label}UpdateWorkFlow`} className={`form-control ${toolsError[updatework?.name] && 'invalid-input'}`} name={updatework?.name} placeholder={updatework?.placeholder} onChange={handleChangeWorkFlow} value={updateWorkflowData[updatework?.name] || ''} onFocus={handleFocus}>
                                                        {updatework?.options.map((options,optionIndex) => (
                                                            <option value={options?.value}>{options?.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        :   ""
                                    ))}

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <button id="saveUpdateWorkFlow" className="fs_btn" onClick={(e) => {handleWorkFlow("workflow",workflowType)}}> { workflowType === "create" ? "save" : "update"  } </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="tab-pane" id="APPLICANT" role="tabpanel" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                            <div className="form_section send_email">
                                        

                                                <div className="search mt-10">    
                                                    {sectionDisplay && 
                                                        <>
                                                            <h3 className="title ">Send Email</h3>
                                                            <button id="emailHistoryBtn" type="button" className="create_btn" onClick={handleEmailNav}>Email History</button>
                                                            
                                                        </>
                                                        
                                                    }
                                                </div>
                                                {sectionDisplay && 
                                                    <>
                                                        <div className='row'>
                                                            <div className="col-md-3">
                                                                <div className="form-group">

                                                                    
                                                                        <label>{uuidEmail ? "Email Template" : "Select Email Template"}</label>
                                                                        {!uuidEmail && <select id="selectEmailTemplate" name="name" className={`form-control ${emailErrors?.name && 'invalid-input'}`}  
                                                                                onFocus={() => { setEmailErrors({ ...emailErrors, name: null }) }} placeholder="Select Template" 
                                                                                onChange={handleChangeManualEmail} value={manualEmailData?.name}>
                                                                            <option value = "">Select Template</option>
                                                                            {allEmailsData?.length > 0 && allEmailsData?.map((tempArr, tempIndex) => (
                                                                                <option value={tempArr?.name}>{tempArr?.name}</option>
                                                                            ))} 
                                                                        </select>}
                                                                        {uuidEmail && 
                                                                            <input id="emailTemplateName" style= {{ 'backgroundColor' : (uuidEmail) && "rgb(233, 236, 239"}} tabindex="4" type="text" name="name" className="form-control" placeholder="Template Name" value={manualEmailData?.name} disabled/>
                                                                        }
                                                                    

                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group"><label>From</label>
                                                                    <input id="emailFrom" tabindex="4" type="text" name="from" className={`form-control ${emailErrors?.from && 'invalid-input'}`}  
                                                                        onFocus={() => { setEmailErrors({ ...emailErrors, from: null }) }} placeholder="example@gmail.com" 
                                                                        onChange={handleChangeManualEmail} value={manualEmailData?.from} disabled = {uuidEmail ? true : false }
                                                                        style= {{ 'backgroundColor' : (uuidEmail) && "rgb(233, 236, 239"}}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group"><label>To</label>
                                                                    <input id="emailTo" tabindex="4" type="text" name="to" className={`form-control ${emailErrors?.to && 'invalid-input'}`}  onFocus={() => { setEmailErrors({ ...emailErrors, to: null }) }} placeholder="recipient@example.com" onChange={handleChangeManualEmail} value={manualEmailData?.to}/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="form-group"><label>Subject</label>
                                                                    <input id="emailSubject" tabindex="4" type="text" name="subject" className={`form-control ${emailErrors?.subject && 'invalid-input'}`}  onFocus={() => { setEmailErrors({ ...emailErrors, subject: null }) }} placeholder="Enter Subject" onChange={handleChangeManualEmail} value={manualEmailData?.subject}/>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label>Message</label>
                                                                    {/* <textarea className="form-control" rows="4"></textarea> */}

                                                                    <EditorEdit content={content} setContent={setContent}  type={type} name = "template" placeholder="Enter the message" hidePrint = {hidePrint}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button id="sendEmailBtn" type="button" style = {{'opacity' : (uuidEmail && emailBool === true) ? "0.6" : "1"}} disabled={(uuidEmail !== '' && emailBool === true) && "true" } className="fs_btn" onClick={handleSendEmail}>Send</button>
                                                        {uuidEmail && <button id="emailcancelBtn" type="button" className="fs_btn ms-3" onClick={handleEmailCancel}>Cancel</button>}
                                                    </>
                                                }
                            </div>
                                        {!sectionDisplay && <div className="form_section">
                                            <div className="search mt-10">    
                                                <h5 className="title">Email History</h5>
                                                <a id="navigateEmailBack" href="#" className="back_btn" onClick={(e) => {e.preventDefault(); handleBack()}}>
                                                    <span className="material-icons icon"> arrow_back</span>BACK</a>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table db_table">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th width="9%" >Date</th>
                                                            <th width="15%" >Email Template</th>
                                                            <th width="9%" >From</th>
                                                            <th width="9%" >To</th>
                                                            <th width="15%" >Subject</th>
                                                            {/* <th width="30%" >Message</th> */}
                                                            <th width="13%" className='text-left'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {emailsHistoryData && emailsHistoryData?.map((history,index) => (
                                                            <tr key={index}>
                                                                <td>{convertDateFormat(history?.created)}</td>
                                                                <td>{history?.templateName}</td>
                                                                <td>{history?.from}</td>
                                                                <td>{history?.toEmailid}</td>
                                                                <td>{history?.subject}</td>
                                                                

                                                                <td>
                                                                    <div className="btn-group">
                                                                        <button id={`viewAndResend-${index}`} type="button" className="btn icon_btn btn-outline-secondary" onClick={() => handleEmailView(history?.uuid)}>
                                                                            <span className="material-symbols-outlined icon">visibility </span>View and Resend
                                                                        </button>
                                                                        {/* <button type="button" className="btn btn-outline-secondary icon_btn">
                                                                            <span className="material-symbols-outlined icon">send </span> Resend
                                                                        </button> */}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>}
                                     
                        </div>
                        <div className="tab-pane" id="HMDA" role="tabpanel" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                                <h3 className="title mt-10">MANAGE ALERTS</h3>
                                <div className="sl_block form_section ">
                                    <div className="left">
                                        <div className="cont">
                                            {manageAlertsJson?.map((alert,index) => (
                                                (alert?.type === "dropdown") ?
                                                    <div className="form-group">
                                                        <label>{alert?.label}</label>
                                                        <select id="alertSelectSection" className={`form-control ${alertsErrors?.[alert?.name] && 'invalid-input'}`} name={alert?.name} onChange={ handleAlerts} 
                                                            value={manualAlertsData?.[alert?.name]} onFocus={handleAlertFocus}>
                                                            {alert?.options?.map((option,optionIndex) => (
                                                                <option value={option?.value}>{option?.label}</option>
                                                            ))}
                                                        </select>
                                                    </div> :
                                                   
                                                    <div className="form-group">
                                                        <label>{alert?.label}</label>
                                                        <textarea id="alertMessage" className={`form-control ${alertsErrors?.[alert?.name] && 'invalid-input'}`} name={alert?.name} rows="4" cols="50" 
                                                            placeholder={alert?.placeholder} onChange={ handleAlerts} value={manualAlertsData?.[alert?.name]} onFocus={handleAlertFocus}> 
                                                        </textarea>
                                                    </div>

                                                    
                                                
                                            ))}
                                           
                                           
                                            <div className="form-group">
                                                <button id="addAlert" className="fs_btn" onClick={(e) => {handleAlertsSubmit();}}>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        {tableWorkFlowData?.length > 0 && 
                                         <DataTable className='table-responsive'
                                         columns={columns}
                                         data={tableWorkFlowData}
                                         pagination
                                         customStyles={customStyles}
                                         paginationPerPage={5}
                                         paginationRowsPerPageOptions={[5,10, 20, 30, 40]}
                                         paginationComponentOptions={{
                                             rowsPerPageText: 'Per Page',
                                         }}
                                     />
                                        }
                                   
                                        {/* <div className="table-responsive">
                                            <table className="table db_table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th width="15%" >Section</th>
                                                        <th width="10%" >Date</th>
                                                        <th width="40%" >MESSAGE</th>
                                                        <th width="10%" >Create by</th>
                                                        <th width="12%" >Cancelled by</th>
                                                        <th width="13%" >Cancelled Date</th>
                                                        <th width="10%" className='text-left'>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableWorkFlowData?.map((tableRow,index) => {
                                                        const date = new Date(tableRow?.created);
                                                        const cancelleddate = moment(tableRow?.cancelledon).format("MM-DD-YYYY");
                                                        const options = { year: 'numeric', month: 'long' };
                                                        const formattedDate = date.toLocaleDateString('en-GB', options);
                                                        
                                                        const day = date.getDate();
                                                        const suffix = (day === 1 || day === 21 || day === 31) ? 'st' : (day === 2 || day === 22) ? 'nd' : (day === 3 || day === 23) ? 'rd' : 'th';
                                                        const formattedDay = day + suffix;
                                                        
                                                        const finalFormattedDate = `${formattedDay} ${formattedDate}`;
                                                        const matchedOption = manageAlertsJson[0]?.options.find(option => option.value === tableRow?.section);
                                                        console.log('matchedOptionmatchedOption',matchedOption);
                                                        return (
                                                        
                                                        <tr key = {tableRow?._id}>
                                                            <td>{matchedOption ? matchedOption.label : ""}</td>
                                                            <td>{tableRow?.created &&  moment(tableRow?.created).format("MM-DD-YYYY")}</td>
                                                            
                                                            <td>{tableRow?.reasonfordenial}</td>
                                                            <td>{tableRow?.createdby?.username}</td>
                                                            <td>{tableRow?.cancelledby?.username}</td>
                                                            <td>{tableRow?.cancelledon ? cancelleddate : ''}</td>
                                                            <td>
                                                                <div className="btn-group">
                                                                   
                                                                    <button type="button" disabled={tableRow?.status ==='CANCELLED'} className="btn btn-outline-secondary" onClick = {() => handleAlertId(tableRow?.alertid)}>
                                                                        <span className="material-symbols-outlined icon tooltip-container"><span className="tooltip">Delete</span>delete </span>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>);
                           
                                                    })}
                                                                             
                                                </tbody>
                                            </table>
                                        </div> */}
                                    </div>
                                </div>




                            </div>
                        <div className="tab-pane property" id="PROPERTY" role="tabpanel" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                        <div className="page-title-box">
                                <h4 className="title mb-0">Categories for loan</h4>
                            </div>
                        <div className="col-md-6">
                                            <div className="form-group">
                                               
                                                <Select
                                                inputId='loanCategories'
                                                options={categoriesDropdown}
                                                isMulti
                                                value={categoriesForloanData?.categoriesforloan?.map((value) => ({
                                                    value,
                                                      label: value
                                                      })) || []}
                                                className={`w-100 ${categoryError && 'invalid-input'}`} onFocus={() => { setCategoryError(false) }}
                                                      onChange={(selectedOptions) => {
                                                        setCategoriesforloanData({
                                                            ...categoriesForloanData,
                                                            categoriesforloan : selectedOptions.map(option => option.value)
                                                        });
                                                    }}
                                            />
                                            </div>
                                        </div>
                            
                            <div className="form-group mt_20">
                                    <button id="saveCategoriesLoan" className="fs_btn" 
                                        disabled={!isSelectionChanged()}
                                        style={{
                                            opacity: !isSelectionChanged() ? '0.6' : '1',
                                            //cursor: isSelectionChanged() ? 'not-allowed' : 'pointer',
                                        }}
                                        onClick={(e) => {handleSaveCatgory()}}>SAVE</button>
                                </div>

                        </div>
                        <div className="tab-pane" id="LOAN" role="tabpanel" style={{
                        pointerEvents: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        userSelect: pipelinePermissions && !editPermissions ? "none" : "auto" ? pipelinePermissions && editPermissions ? "auto" : "none" : "",
                        opacity: pipelinePermissions && !editPermissions ? 0.5 : 1 ? pipelinePermissions && editPermissions ? 1 : 0.5 : "",
                      }}>
                            <div className="page-title-box">
                                <h4 className="title">ASSIGN loan to another user</h4>
                                <p className="mb-sm-0 font-size-18">Choose a user to assign this loan to. the user you
                                    assign the loan to will have access to this loan</p>
                            </div>
                            <div className="form_section">
                                <div className="row">
                                    {loanInputFields.map((field, index) => (
                                        <div key={index} className="col-md-3">
                                            <div className="form-group">
                                                <label>{field.label}</label>
                                                {field.type === 'select' ? (
                                                    <select
                                                        id={field.name}
                                                        className={`form-control ${loanError[field.name] && 'invalid-input'}`}
                                                        value={
                                                            field.name === 'accountexecutive' ? (loanassignment?.loanassignment?.[field.name] || ''):  (loanassignment?.loanassignment?.[field.name]?.name || '')}
                                                        onChange={handleLoanChange}
                                                        onFocus={() => setLoanError(prev => ({ ...prev, [field.name]: null }))}
                                                        name={field.name}
                                                    >
                                                        {field.name ==='accountexecutive' ?
                                                      <><option value="" selected>Select</option> <option value="Not working with anyone currently" selected>Not working with anyone currently</option></> 
                                                        :<> <option value="" selected>Select</option> </> } 
                                                        {field.options.map((option, index) => (
                                                                <option key={index} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                    </select>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        className={`form-control ${loanError[field.name] && 'invalid-input'}`}
                                                        placeholder={field.placeholder || 'Enter'}
                                                        value={loanassignment?.loanassignment?.[field.name] || ''}
                                                        onChange={handleLoanChange}
                                                        onFocus={() => setLoanError(prev => ({ ...prev, [field.name]: null }))}
                                                        name={field.name}
                                                        id={field.name}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    ))} 

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <button id="saveLoanAssignment" onClick={(e)=>{handleAssigmentAdd();}} disabled={!isLoanAssignmentChange} style={{'opacity': isLoanAssignmentChange ? '1' : '0.6',}} className="fs_btn">SAVE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane property loan_audit" id="REGULATION" role="tabpanel" >
                            <div className="form_section">
                                <div className="btn-group">
                                    <button id="loanHistoryTabChange" type="button" onClick={()=>{handleTabChange('LoanHistory')}} className={`create_btn justify-content-center ${ActiveTab ==='LoanHistory' && 'active'}`} >Loan History</button>
                                    <button id="loanDetailsTabChange" type="button" onClick={()=>{handleTabChange('LoanDetails')}} className={`create_btn justify-content-center ${ActiveTab ==='LoanDetails' && 'active'}`}>Loan Details</button>
                                    <button id="loanReassignmentsTabChange" type="button" onClick={()=>{handleTabChange('LoanReassignments')}} className={`create_btn justify-content-center ${ActiveTab ==='LoanReassignments' && 'active'}`}>Loan Reassignments</button>
                                    <button id="emaillogTabChange" type="button" onClick={()=>{handleTabChange('emaillog')}} className={`create_btn justify-content-center ${ActiveTab ==='emaillog' && 'active'}`}>Emails Log</button>
                                </div>
                                <div className="row" >
                                    {((LoanStatusHistory?.length === 0 && !dropdownValue) || ActiveTab ==='emaillog') ? '' : 
                                    <div className="col-md-2">
                                    <select id="allDropdown" className="form-control" value={dropdownValue} placeholder="Please Select Option" onChange={handleLoanauditFilter}>
                                        <option value=''>All</option>
                                        {dropdownOptionLoanAudit.map((option, index) => (
                                            <option key={index} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                        {/* <option value='stage'>Stage</option>
                                        <option value='phase'>Phase</option>
                                        <option value='folder'>Folder</option> */}
                                    </select>
                                    </div>                           
                                    }
                                    <div className="col-md-10"></div>

                                </div>
                                {LoanStatusHistory?.length !== 0 && <div >
                                 <DataTable className='table-responsive'
                                 columns={coloumnsData}
                                 data={LoanStatusHistory}
                                 pagination
                                 customStyles={customStyles}
                                 paginationPerPage={perPage}
                                 paginationRowsPerPageOptions={[5,10, 20, 30, 40]}
                                 paginationComponentOptions={{
                                     rowsPerPageText: 'Per Page',
                                 }}
                                 onChangePage={handlePageChange}
                                 paginationDefaultPage={currentPage}
                                 onChangeRowsPerPage={handleRowsPerPageChange}
                                 
                             />
                             </div>
                                }
                               {(LoanStatusHistory?.length === 0 ) &&<div className="inprogress_page">
                   <span className="material-symbols-outlined icon"> content_paste_search </span><p>No Data Found</p></div>}
                            </div>

                        </div>

                    </div>

                </div>
            </div>
            </div>
            <PipelineFooter />
        </div>
    );
};

export default DealToolsWorkflow;