import React,{useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import InfoBar from './InfoBar';
import {Appname, APIUrl, submitNew, getFileTemplates, footerSiteUrl} from '../utils/reducer';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { useLoanInfo } from '../utils/loanInfoContext';
import PopupMessage from './PopupMessage';
import Loader from './Loader';
import { showLoader, hideLoader } from '../redux/reducers/Actions.js';
import { useDispatch, useSelector} from 'react-redux';
import saveAs from 'file-saver';
import Footer from './Footer.js';
import WarmAppFooter from './WarmAppFooter';
import Header from './Header.js';
const SubmitApp = () => {
    const dispatch = useDispatch();
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const navigate = useNavigate();
    const configdata = useSelector(state => state.configdata);
    const replaceEasyStreetCapital = configdata?.admin?.rights?.replace(/Easy Street Capital LLC/g,"easystreetcap.com");
    const [unique_id, setunique_id] = useState(uuidv4());
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [popupMessage, setmessage] = useState('Form successfully submitted.');
    const loading = useSelector((state) => state.loaderVisible);
    const siteUrl = footerSiteUrl
    const [updateLoanInfo,setUpdateLoanInfo] = useState({});    
    const [filesArray, setFilesArray] = useState([
        {
            "_id": "6646035e5610b010f8adb358",
            "filename": "Blank - ACH Authorization 2024",
            "filepath": "https://d18d1bjuqqrg2z.cloudfront.net/easystreetcapitaldev/loandocuments/Blank - ACH Authorization 2024.pdf",
            "fileid": "6645ab7837a3e1a5165306f0",
            "status": "ACTIVE",
            "appname": "easystreetcapitaldev"
        },
        {
            "_id": "6646035e5610b010f8adb359",
            "filename": "Easy Street - Borrower Track Record Template",
            "filepath": "https://d18d1bjuqqrg2z.cloudfront.net/easystreetcapitaldev/loandocuments/Easy Street - Borrower Track Record Template.xlsx",
            "fileid": "6645ab7837a3e1a5165306f1",
            "status": "ACTIVE",
            "appname": "easystreetcapitaldev"
        },
        {
            "_id": "6646035e5610b010f8adb35a",
            "filename": "Easy Street - Credit Authorization and HMDA Form",
            "filepath": "https://d18d1bjuqqrg2z.cloudfront.net/easystreetcapitaldev/loandocuments/Easy Street - Credit Authorization and HMDA Form.pdf",
            "fileid": "6645ab7837a3e1a5165306f2",
            "status": "ACTIVE",
            "appname": "easystreetcapitaldev"
        },
        {
            "_id": "6646035e5610b010f8adb35b",
            "filename": "ESC-Scope of work-2024 V2",
            "filepath": "https://d18d1bjuqqrg2z.cloudfront.net/easystreetcapitaldev/loandocuments/ESC-Scope of work-2024 V2.xlsx",
            "fileid": "6645ab7837a3e1a5165306f3",
            "status": "ACTIVE",
            "appname": "easystreetcapitaldev"
        },
        {
            "_id": "6646035e5610b010f8adb35c",
            "filename": "W-9 Fillable PDF",
            "filepath": "https://d18d1bjuqqrg2z.cloudfront.net/easystreetcapitaldev/loandocuments/W-9 Fillable PDF.pdf",
            
            "fileid": "6645ab7837a3e1a5165306f4",
            "status": "ACTIVE",
            "appname": "easystreetcapitaldev"
        }
    ]);
    
    
    const [uploadedDocuments, setUploadedDocuments] = useState({});
    const transformDocumentKeys = (documents) => {
        const newDocuments = {};
        for (let key in documents) {
            if (documents.hasOwnProperty(key)) {
                const newKey = key.trim().toLowerCase().replace(/\s+/g, '');
                newDocuments[newKey] = documents[key];
            }
        }
        return newDocuments;
    };
    useEffect(() => {
        if (updateLoanInfo?.documents) {
            const transformedDocuments = transformDocumentKeys(updateLoanInfo.documents);
            console.log("transformedDocuments",transformedDocuments);
            setUploadedDocuments(transformedDocuments );
        }
    },[updateLoanInfo])
    useEffect(() => {
        console.log('loanInfo',loanInfo);
    setLoanInfo((prevLoanInfo) => ({
        ...prevLoanInfo,
        id: unique_id,
      }));
      getFileTemplates().then(function (response) {
            console.log('responseresponse',response);
            if(response?.result){
                setFilesArray(response?.result)
            }
        }).catch((err) => {
          console.error.bind("getFileTemplates error", err);
        })
    }, []);
    // console.log('bankstatement','borrowerexperiencetrackrecordform','creditauthorizationformat','executedcontract','itemconstructionbudget','photoid','signedtermsheet')
    useEffect(() => {
        const isEmpty = Object.keys(loanInfo).some(key => {
            if (key !== 'dealinfo') {
                return Object.keys(loanInfo[key]).length === 0;
            }
            return false; // Skip checking dealinfo
        });
        const isBorrowerinfoEmpty = Object.keys(loanInfo.borrowerinfo).length === 0;  
       


        // if(isBorrowerinfoEmpty){
        //     navigate('/borrowerinfo');
        // }
      }, [loanInfo]);
      console.log('loaninfoooo',loanInfo)
      console.log('updateLoanInfo',updateLoanInfo)
      function handleUploadValidation(){
        let requiredKeys2 = ['Bank statement', 'Borrower experience track record form', 'Credit authorization format', 'Executed contract', 'Item construction budget', 'Photo id', 'Signed term sheet','Form W 9','Ach'];
        let valid;
        valid = false;
        for (let docName of requiredKeys2) {
            if (updateLoanInfo?.documents?.hasOwnProperty(docName)) {
                valid = true;
                break; // Exit loop early if any required document is found
            }
        }
        return valid;
    }
    const submitapp = (param) => {
        const uploadValid = handleUploadValidation();
        console.log("uploadValid",uploadValid);
        if(param == "upload" && !uploadValid){
            
            
                dispatch(showLoader());
                setPopupOpen(true);
                setmessage("Please Upload The Documents");
                dispatch(hideLoader());
                
            
        }else{
            let requiredKeys = ['bankstatement', 'borrowerexperiencetrackrecordform', 'creditauthorizationformat', 'executedcontract', 'itemconstructionbudget', 'photoid', 'signedtermsheet', 'ach', 'formw9'];
            let requiredKeys2 = ['Bank statement', 'Borrower experience track record form', 'Credit authorization format', 'Executed contract', 'Item construction budget', 'Photo id', 'Signed term sheet'];
            let data = {...loanInfo}
            //   data.documents = {};
              for (let key of requiredKeys) {
                  if (key in data) {
                    //   data.documents[key] = data[key];
                      delete data[key];
                  }
              }
              if(data?.coBorrower?.coBorrowerExists == "yes"){
                console.log("final data---->here",data.coBorrower);
                let coborrower = data.coBorrower;
                let mappedcoBorrower = {
                    borrowerfirstname: coborrower.coborrowerfirstname,
                    borrowerlastname: coborrower.coborrowerlastname,
                    borroweremail: coborrower.coborroweremail,
                    borrowerphone: coborrower.coborrowerphone,
                    borroweroccupation: coborrower.coborroweroccupation,
                    investmentproperties: coborrower.coborroweinvestmentproperties,
                    statedcreditscore: coborrower.coborrowercreditscore,
                    liquidcashreserve: coborrower.coborrowerliquidcashreserve,
                    borroweraddress: coborrower.coborroweraddress,
                    borrowerstate: coborrower.coborrowerstate,
                    borrowergeolocation: coborrower.coborrowergeolocation,
                    borrowergooglemaplink: coborrower.coborrowergooglemaplink,
                    borrowerarea: coborrower.coborrowerarea,
                    borrowercity: coborrower.coborrowercity,
                    borrowerfulladdress: coborrower.coborrowerfulladdress,
                    borrowerstateshort: coborrower.coborrowerstateshort,
                    isborroweranentity: coborrower.iscoborroweranentity,
                    coBorrowerExists: coborrower.coBorrowerExists,
                    borrowerzipcode: coborrower.coborrowerzipcode, 
                    borrowercounty: coborrower.coborrowercounty,
                    legalname: coborrower.coborrowerentityname,
                };
                data.coBorrower = mappedcoBorrower;
              }

              // Set iscollateralflag based on collateraladditionalproperty
                if (data?.collateralproperty?.collateraladditionalproperty == 'Yes') {
                    data.iscollateralflag = true;
                } else {
                    data.iscollateralflag = false;
                }

                 // Change collateralyearbuilt to yearbuilt
                if (data?.collateralproperty) {
                    data.collateralproperty.yearbuilt = data.collateralproperty.collateralyearbuilt;
                    delete data.collateralproperty.collateralyearbuilt; // Remove the old property if necessary
                }

                //property info place
                if(data?.dealinfo?.coborrowerfulladdress){
                    // console.log("final data---->here",data.coBorrower);
                    let propertyinfoplace = data.dealinfo;
                    let mappedPropertyInfoPlace = {
                        ...propertyinfoplace, // This retains all existing fields
                        propertyinfoplaceaddress: propertyinfoplace.coborroweraddress,
                        propertyinfoplacestate: propertyinfoplace.coborrowerstate,
                        propertyinfoplacegeolocation: propertyinfoplace.coborrowergeolocation,
                        propertyinfoplacegooglemaplink: propertyinfoplace.coborrowergooglemaplink,
                        propertyinfoplacearea: propertyinfoplace.coborrowerarea,
                        propertyinfoplacecity: propertyinfoplace.coborrowercity,
                        propertyinfoplacefulladdress: propertyinfoplace.coborrowerfulladdress,
                        propertyinfoplacestateshort: propertyinfoplace.coborrowerstateshort,
                        propertyinfoplacezipcode: propertyinfoplace.coborrowerzipcode, 
                        propertyinfoplacecounty: propertyinfoplace.coborrowercounty,
                       
                    };

                     // Delete coborrower fields after mapping
                    delete mappedPropertyInfoPlace.coborroweraddress;
                    delete mappedPropertyInfoPlace.coborrowercity;
                    delete mappedPropertyInfoPlace.coborrowerarea;
                    delete mappedPropertyInfoPlace.coborrowerfulladdress;
                    delete mappedPropertyInfoPlace.coborrowerstate;
                    delete mappedPropertyInfoPlace.coborrowerzipcode;
                    delete mappedPropertyInfoPlace.coborrowergeolocation;
                    delete mappedPropertyInfoPlace.coborrowergooglemaplink;
                    delete mappedPropertyInfoPlace.coborrowerstateshort;
                    delete mappedPropertyInfoPlace.coborrowercounty;
                    data.dealinfo = mappedPropertyInfoPlace;
                }

               
              console.log("final data----> unmodified",data);
              const finalData = {
                ...data,
                borrowerinfo: {
                    ...data.borrowerinfo,
                    accountexecutive: data.propertyinfo?.accountexecutive || "",
                    accountexecutiveid : data.propertyinfo?.accountexecutiveid || "",
                    referredBy: data.propertyinfo?.referredBy || "",
                }
            };
            console.log('finalData--> modified',finalData)
              dispatch(showLoader());
              submitNew(finalData).then(function (response) {
                  console.log('responseresponse',response);
               
                    const getUrlId = localStorage.getItem("urlId");
                    if (getUrlId) {
                        localStorage.removeItem("urlId");
                        console.log("urlId removed from local storage");
                    }
                    setPopupOpen(true);
                    setmessage('Form successfully submitted.');

                    dispatch(hideLoader());
                  console.log('responseresponse',response);
                }).catch((err) => {
                    dispatch(hideLoader());
                    setPopupOpen(true);
                    setmessage('An error occurred while creating the loan. Please try again.');
                    console.error('Loan creation error:', err);
                })
        }
       
    };
    useEffect(() => {
        setUpdateLoanInfo(loanInfo);
    },[loanInfo])
    useEffect(() => {
        // Function to convert string fields to numeric values
        // const convertFieldsToNumbers = () => {
            const numericFields = [
                "propertyzipcode",
                "brokerpoints",
                "purchaseprice",
                "propertyarea",
                "totalbedrooms",
                "totalbathrooms",
                "yearbuilt",
                "lotarea",
                "existingloan",
                "currentvalue",
                "completedvalue",
                "borrowerzipcode",
                "creditscore",
                "investmentproperties",
                "liquidcashreserve",
                "renovationcost",
                "remainingrehabbudget",
                "construction_rehab_budget",
                "renovationarv",
                "experience",
                "constructionbudget",
                "brokerprocessingfee",
                "coborroweinvestmentproperties",
                "coborrowercreditscore",
                "coborrowerliquidcashreserve",
                "coborrowerzipcode",
                "collateralyearbuilt",
                "estimatedvalue"
                
                // "propertyzipcode",
                
                // Add more fields here if needed
            ];
            // Create a new object to store the converted values
            const updatedInfo = { ...loanInfo };
            // Loop through each field and convert its value to a number
            numericFields.forEach(field => {
                // Check if the value is a string and contains a '$' or comma
                // console.log("type",typeof "$777.88");
                // typeof updatedInfo?.dealinfo?.[field] !== undefined && !isNaN(updatedInfo?.borrowerinfo?.[field])
                if (typeof updatedInfo?.dealinfo?.[field] === 'string') {
                    let rep = updatedInfo?.dealinfo[field]?.replace(/[\$,]/g, '');
                    updatedInfo.dealinfo[field] = parseFloat(rep);
                }
                if (typeof updatedInfo?.borrowerinfo?.[field] === 'string' ) {
                    let rep2 = updatedInfo?.borrowerinfo?.[field]?.replace(/[\$,]/g, '');
                    updatedInfo.borrowerinfo[field] = parseFloat(rep2);
                }
                if (typeof updatedInfo?.propertyinfo?.[field] === 'string') {
                    let rep3 = updatedInfo?.propertyinfo?.[field]?.replace(/[\$,]/g, '');
                    updatedInfo.propertyinfo[field] = parseFloat(rep3);
                }
                if (typeof updatedInfo?.collateralproperty?.[field] === 'string') {
                    let rep3 = updatedInfo?.collateralproperty?.[field]?.replace(/[\$,]/g, '');
                    updatedInfo.collateralproperty[field] = parseFloat(rep3);
                }
                if (typeof updatedInfo?.coBorrower?.[field] === 'string') {
                    let rep3 = updatedInfo?.coBorrower?.[field]?.replace(/[\$,]/g, '');
                    updatedInfo.coBorrower[field] = parseFloat(rep3);
                }
            });
            // Convert loanfor and requestor to uppercase
            // updatedInfo.propertyinfo.loanfor = updatedInfo.propertyinfo.loanfor?.toUpperCase();
            updatedInfo.requestor = updatedInfo.requestor?.toUpperCase();
            // Update the state with the converted values
            console.log("modified loan info", updatedInfo);
            setLoanInfo(updatedInfo);
        // };
    },[])
    
    
    const closePopup = () => {
        setPopupOpen(false);
        const token = localStorage.getItem('accessToken');
        
        if(popupMessage == "Please Upload The Documents"){
            return false;
        }else{
            if (!token) {
                navigate('/submit')
                //alert("submit");
            }else{
                window.location.href = '/pipeline';
                //alert("pipeline");
            }
        }
        
    };
    const handleFileChange = (e) => {
        // Handle file selection here
        console.log('responseherererer1',JSON.stringify(loanInfo));
     
        const file = e.target.files[0];
    
        if (!file) {
            console.log('No file selected');
            return;
        }
        dispatch(showLoader())
        var bucket;
        const timestamp = Date.now();
        let format = file?.name?.split(".");
        var fileformat = format[format.length - 1]
        bucket = configdata?.common?.resourceBucket || 'easystreetcapital-dev-resources';
        console.log('filefilefilebucket',bucket,);
        let category = e.target.name;
        console.log('datadacategory',category);
        var  ObjectID2= require("bson-objectid");
        var uniqueId = new ObjectID2().toString(); 
        let uploadFilePath = Appname + "/dealfiles/"  + uniqueId + "/" + timestamp + "." + fileformat;
        console.log('datadauploadFilePath',uploadFilePath);
        var data = { source_bucket: bucket, sourcepath: uploadFilePath }
        console.log('datadatadata',data);
        let requiredKeys = {'bankstatement': 'Bank statement','borrowerexperiencetrackrecordform': 'Borrower experience track record form',
         'creditauthorizationformat' : 'Credit authorization format',
         'executedcontract': 'Executed contract',
         'itemconstructionbudget' :  'Item construction budget',
          'photoid':  'Photo id',
          'signedtermsheet':  'Signed term sheet',
          'ach' : 'Ach',
          'formw9' : 'Form W 9'
         };
        
        category = requiredKeys[category]
         console.log('categorycategorycategorycategory',category)
        axios.post(APIUrl + 'uploadURL?appname=' + Appname, data, { type: 'application/json' })
        .then((response) => {
            console.log('ressss',response);
            if (response?.data && response?.data?.result) {
                console.log('ressss2',response.data);
                var url = response?.data?.result;
                console.log('ressss3',response.data.result);
                console.log("url", url);
                axios.put(url, file, {
                    "headers": {
                        "Content-Type": "multipart/form-data",
                        "Accept": "/",
                        "Cache-Control": "no-cache",
                        "Accept-Encoding": "gzip, deflate",
                        "Connection": "keep-alive",
                        "cache-control": "no-cache"
                    }
                })
                    .then((response) => {
                        if (response && response?.status === 200) {
                            console.log('responseherererer',uploadFilePath);
                            loanInfo[category] = uploadFilePath;
                              const newDocument = {
                                filename: format[0],
                                filetype: file.type,
                                type:category,
                                fileextension:fileformat,
                                filepath: uploadFilePath, // assuming you have this information
                                filedescription: "",
                              };
                          
                              // Update the documents array based on category
                              setLoanInfo(prevData => ({
                                ...prevData,
                                documents: {
                                  ...prevData.documents,
                                  [category]: [...(prevData.documents?.[category] || []), newDocument]
                                }
                              }));
                              setUpdateLoanInfo(prevData => ({
                                ...prevData,
                                documents: {
                                  ...prevData.documents,
                                  [category]: [...(prevData.documents?.[category] || []), newDocument]
                                }
                              }));
                           
                            // setUpdateLoanInfo({...updateLoanInfo,[category]:uploadFilePath,'filetype':format});
                            console.log('responseherererer',loanInfo);
                            dispatch(hideLoader())
                        }
                    });
                }
            
        
        });
      };

    // const handleDownload = async(fileName) => {
    //     const file = filesArray.find(file => file.filename === fileName);
        
    //     if (file) {
    //         console.log(configdata);
    //         console.log("file --->",file)
            
    //       fetch(file.filepath)
    //         .then(response => response.blob())
    //         .then(blob => saveAs(blob, file.filename))
    //         .catch(error => console.error('Error downloading file:', error));
    //     } 
    //     // if (file) {
    //     //     console.log("configdata:", configdata);
    //     //     console.log("file --->", file);
        
    //     //     // Define the old base URL to check for
    //     //     const oldBaseURL = "https://d18d1bjuqqrg2z.cloudfront.net/";
        
    //     //     // Replace the old base URL with the new resourcesCloudfront URL if found
    //     //     let path = file.filepath.includes(oldBaseURL)
    //     //         ? file.filepath.replace(oldBaseURL, configdata?.common?.resourcesCloudfront)
    //     //         : `${configdata?.common?.resourcesCloudfront}${file.filepath}`;
        
    //     //     console.log("Downloading from path:", path);
        
    //     //     // Handle specific file download logic for "W-9 Fillable PDF"
    //     //     if (file.filename === "W-9 Fillable PDF") {
    //     //         try {
    //     //             const response = await fetch(path); // Fetch the file
    //     //             if (!response.ok) throw new Error("Network response was not ok");
        
    //     //             const blob = await response.blob();
    //     //             const url = window.URL.createObjectURL(blob);
        
    //     //             // Create an anchor element for download
    //     //             const link = document.createElement("a");
    //     //             link.href = url;
        
    //     //             // Set the download attribute to specify the file name
    //     //             link.download = `${file.filename}.pdf`; // Ensure proper filename and extension
        
    //     //             // Append to the document and trigger a click event
    //     //             document.body.appendChild(link);
    //     //             link.click();
        
    //     //             // Clean up
    //     //             document.body.removeChild(link);
    //     //             window.URL.revokeObjectURL(url);
    //     //         } catch (error) {
    //     //             console.error("Download failed:", error);
    //     //         }
    //     //     } else {
    //     //         // General file download logic for other files
    //     //         fetch(path)
    //     //             .then((response) => {
    //     //                 if (!response.ok) {
    //     //                     throw new Error(`HTTP error! status: ${response.status}`);
    //     //                 }
    //     //                 return response.blob();
    //     //             })
    //     //             .then((blob) => {
    //     //                 // Save the file using FileSaver.js or similar
    //     //                 saveAs(blob, `${file.filename}.xlsx`); // Ensure the correct file extension
    //     //             })
    //     //             .catch((error) => console.error("Error downloading file:", error));
    //     //     }
    //     // } else {
    //     //     console.log("File not found");
    //     // }
        
        
        
    // };

    const handleDownload = async (fileName) => {
        const file = filesArray.find((file) => file.filename === fileName);

        if (file) {
            console.log("Config data:", configdata);
            console.log("File details:", file);

            const path = file.filepath;
            console.log("Downloading from path:", path);

            try {
                const response = await fetch(path);

                // Check if the response is valid
                if (!response.ok) {
                    throw new Error(`Failed to fetch file: ${response.statusText}`);
                }

                const blob = await response.blob();

                // Determine file extension and set the filename dynamically
                let fileExtension = path.split(".").pop();
                let downloadFilename = `${file.filename}.${fileExtension}`;

                // Download the file based on type
                if (fileExtension === "pdf") {
                    // For PDF files
                    const url = window.URL.createObjectURL(blob);

                    // Create an anchor tag to trigger download
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = downloadFilename;
                    document.body.appendChild(link);
                    link.click();

                    // Clean up
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                } else {
                    // For other file types like Excel
                    saveAs(blob, downloadFilename);
                }

                console.log("File downloaded successfully:", downloadFilename);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        } else {
            console.log("File not found");
        }
    };

    const handleExit = () => {
       
        //setPopupOpen(false);
        const token = localStorage.getItem('accessToken');
        
        
        if (!token) {
            navigate('/submit')
            //alert("submit");
        }else{
            window.location.href = '/pipeline';
            //alert("pipeline");
        }
        
            
        
    }
    
    return (
        <div className='warmapp_body'>
           {loading ? <Loader/> : null} 
     {isPopupOpen && (
        <PopupMessage
          type={popupMessage == 'Form successfully submitted.' ? 'success' : 'warning'}
          message={popupMessage}
          onClose={closePopup}
        />
      )}
        <>
        

        <Header/>
      
                <div class="wizard-body create_process">
                    <div class="wizard-container full-height">
                        <div class="wizard-group">
                            <InfoBar progress={'100%'} infoProgress={'100%'} borroweProgress={'100%'} dealProgress={'100%'} strategyProgress={'100%'} currentStage={'submitapp'} />
                            <div class="wizard-canvas">
                                <div class="wizard-canvas-header">
                                    <h3>Submit App</h3>
                                </div>
                                <div class="wizard-canvas-body wizard-overflow">
                                    {/* <div class="choose_loan"> */}
                                        {/* <div className="content_blk"> */}
                                            {/* <div className="hed">
                        <a id="navigateSubmitBack" href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); navigate(-1); }}> keyboard_backspace </a>
                    </div> */}
                                            <div className="s_app">
                                                <div className="uploads no-toppad">
                                                    <div className="item">
                                                        <div className="left">
                                                            <img src="assets/images/icons/mode-portrait.svg" className="img" alt="Portrait Mode Icon" />
                                                            <div>
                                                                <h4>Photo ID</h4>
                                                                <p>Upload a clear picture.</p>
                                                            </div>
                                                        </div>
                                                        <label id="uploadPhotoId" htmlFor="file-input" className="upload">
                                                            <span className={updateLoanInfo?.photoid ? "material-symbols-outlined" : "material-icons icon"}>{updateLoanInfo?.photoid ? "check" : "file_upload"}</span>
                                                            {uploadedDocuments?.photoid ? "Reupload" : "Upload"}
                                                        </label>
                                                        <input type="file" accept="image/*,.heic" id="file-input" name={'photoid'} onChange={handleFileChange} style={{ display: 'none' }} />

                                                    </div>
                                                    <div className="item">
                                                        <div className="left">
                                                            <img src="assets/images/icons/note.svg" className="img" />
                                                            <div>
                                                                <h4>Credit Authorization and HMDA Form</h4>
                                                                <p>Please complete in full.</p>
                                                                <a id="downloadCreditAuthorozation" href="#" onClick={(e) => { e.preventDefault(); handleDownload('Easy Street - Credit Authorization and HMDA Form') }} className="download"><span className="material-icons-outlined"> file_download </span>DOWNLOAD
                                                                    TEMPLATE</a>
                                                            </div>
                                                        </div>
                                                        <label id="uploadCreditAuthorization" htmlFor={'creditauthorizationformat'} className="upload">
                                                            <span className={updateLoanInfo?.creditauthorizationformat ? "material-symbols-outlined" : "material-icons icon"}>{updateLoanInfo?.creditauthorizationformat ? "check" : "file_upload"}</span>
                                                            {uploadedDocuments?.creditauthorizationformat ? "Reupload" : "Upload"}
                                                        </label>
                                                        <input type="file" accept=".pdf, .xls, .xlsx, .doc, .docx, .txt" id={'creditauthorizationformat'} name={'creditauthorizationformat'} onChange={handleFileChange} style={{ display: 'none' }} />
                                                    </div>
                                                    <div className="item">
                                                        <div className="left">
                                                            <img src="assets/images/icons/note.svg" className="img" />
                                                            <div>
                                                                <h4>Borrower experience track record form</h4>
                                                                <p>Please attach an updated track record template and HUDs (if available).</p>
                                                                <a id="downloadBorrowerTracKForm" href="#" onClick={(e) => { e.preventDefault(); handleDownload('Easy Street - Borrower Track Record Template') }} className="download"><span className="material-icons-outlined"> file_download </span>DOWNLOAD
                                                                    TEMPLATE</a>
                                                            </div>
                                                        </div>
                                                        <label id="uploadBorrowerTrackForm" htmlFor={'borrowerexperiencetrackrecordform'} className="upload">
                                                            <span className={updateLoanInfo?.borrowerexperiencetrackrecordform ? "material-symbols-outlined" : "material-icons icon"}>{updateLoanInfo?.borrowerexperiencetrackrecordform ? "check" : "file_upload"}</span>
                                                            {uploadedDocuments?.borrowerexperiencetrackrecordform ? "Reupload" : "Upload"}
                                                        </label>
                                                        <input type="file" id={'borrowerexperiencetrackrecordform'} accept=".pdf, .xls, .xlsx, .doc, .docx, .txt" name={'borrowerexperiencetrackrecordform'} onChange={handleFileChange} style={{ display: 'none' }} />
                                                    </div>
                                                    <div className="item">
                                                        <div className="left">
                                                            <img src="assets/images/icons/bankStatemnt.svg" className="img" />
                                                            <div>
                                                                <h4>Bank Statement</h4>
                                                                <p>Most recent 2 bank statements, unredacted, all pages.</p>

                                                            </div>
                                                        </div>
                                                        <label id="uploadBankStatement" htmlFor={'bankstatement'} className="upload">
                                                            <span className={updateLoanInfo?.bankstatement ? "material-symbols-outlined" : "material-icons icon"}>{updateLoanInfo?.bankstatement ? "check" : "file_upload"}</span>
                                                            {uploadedDocuments?.bankstatement ? "Reupload" : "Upload"}
                                                        </label>
                                                        <input type="file" id={'bankstatement'} accept=".pdf, .xls, .xlsx, .doc, .docx, .txt" name={'bankstatement'} onChange={handleFileChange} style={{ display: 'none' }} />
                                                    </div>
                                                    <div className="item">
                                                        <div className="left">
                                                            <img src="assets/images/icons/file-excel.svg" className="img" />
                                                            <div>
                                                                <h4>Itemized construction budget</h4>
                                                                <p>Show the full amount of the budget (regardless of the Easy Street funded amount).</p>
                                                                <a id="downloadConstructionBudget" href="#" onClick={(e) => { e.preventDefault(); handleDownload('ESC-Scope of work-2024 V2') }} className="download" name={'itemizedconstructionbudget'}><span className="material-icons-outlined"> file_download </span>DOWNLOAD
                                                                    TEMPLATE</a>
                                                            </div>
                                                        </div>
                                                        <label id="uploadConstructionBudget" htmlFor="itemconstructionbudget" className="upload">
                                                            <span className={updateLoanInfo?.itemconstructionbudget ? "material-symbols-outlined" : "material-icons icon"}>{updateLoanInfo?.itemconstructionbudget ? "check" : "file_upload"}</span>
                                                            {uploadedDocuments?.itemconstructionbudget ? "Reupload" : "Upload"}
                                                        </label>
                                                        <input type="file" id={'itemconstructionbudget'} accept=".pdf, .xls, .xlsx, .doc, .docx, .txt" name={'itemconstructionbudget'} onChange={handleFileChange} style={{ display: 'none' }} />
                                                    </div>
                                                    <div className="item">
                                                        <div className="left">
                                                            <img src="assets/images/icons/note.svg" className="img" />
                                                            <div>
                                                                <h4>Signed Term Sheet</h4>
                                                                {/* <p>Upload</p> */}

                                                            </div>
                                                        </div>
                                                        <label id="uploadSignedTermSheet" htmlFor={'signedtermsheet'} className="upload">
                                                            <span className={updateLoanInfo?.signedtermsheet ? "material-symbols-outlined" : "material-icons icon"}>{updateLoanInfo?.signedtermsheet ? "check" : "file_upload"}</span>
                                                            {uploadedDocuments?.signedtermsheet ? "Reupload" : "Upload"}
                                                        </label>
                                                        <input type="file" id={'signedtermsheet'} accept=".pdf, .xls, .xlsx, .doc, .docx, .txt" name={'signedtermsheet'} onChange={handleFileChange} style={{ display: 'none' }} />
                                                    </div>
                                                    <div className="item">
                                                        <div className="left">
                                                            <img src="assets/images/icons/note.svg" className="img" />
                                                            <div>
                                                                <h4>Executed contract with any assignments/ amendments</h4>
                                                                {/* <p>Upload</p> */}

                                                            </div>
                                                        </div>
                                                        <label id="uploadExecutiveContract" htmlFor={'executedcontract'} className="upload">
                                                            <span className={updateLoanInfo?.executedcontract ? "material-symbols-outlined" : "material-icons icon"}>{updateLoanInfo?.executedcontract ? "check" : "file_upload"}</span>
                                                            {uploadedDocuments?.executedcontract ? "Reupload" : "Upload"}
                                                        </label>
                                                        <input type="file" id={'executedcontract'} accept=".pdf, .xls, .xlsx, .doc, .docx, .txt" name={'executedcontract'} onChange={handleFileChange} style={{ display: 'none' }} />
                                                    </div>
                                                    <div className="item">
                                                        <div className="left">
                                                            <img src="assets/images/icons/note.svg" className="img" />
                                                            <div>
                                                                <h4>Form W-9</h4>
                                                                <p>Please complete for individual or entity holding title.</p>
                                                                <a id="downloadFormW9" href="#" onClick={(e) => { e.preventDefault(); handleDownload('W-9 Fillable PDF') }} className="download"><span className="material-icons-outlined"> file_download </span>DOWNLOAD
                                                                    TEMPLATE</a>
                                                            </div>
                                                        </div>
                                                        <label id="uploadFormW9" htmlFor={'formw9'} className="upload">
                                                            <span className={updateLoanInfo?.formw9 ? "material-symbols-outlined" : "material-icons icon"}>{updateLoanInfo?.formw9 ? "check" : "file_upload"}</span>
                                                            {uploadedDocuments?.formw9 ? "Reupload" : "Upload"}
                                                        </label>
                                                        <input type="file" accept=".pdf, .xls, .xlsx, .doc, .docx, .txt" id={'formw9'} name={'formw9'} onChange={handleFileChange} style={{ display: 'none' }} />
                                                    </div>
                                                    <div className="item">
                                                        <div className="left">
                                                            <img src="assets/images/icons/note.svg" className="img" />
                                                            <div>
                                                                <h4>Automated Payments (ACH).</h4>
                                                                {/* <p>Blank - ACH Authorization 2024</p> */}
                                                                <a id="downloadACH" href="#" onClick={(e) => { e.preventDefault(); handleDownload('Blank - ACH Authorization 2024') }} className="download"><span className="material-icons-outlined"> file_download </span>DOWNLOAD
                                                                    TEMPLATE</a>
                                                            </div>
                                                        </div>
                                                        <label id="uploadACH" htmlFor={'ach'} className="upload">
                                                            <span className={updateLoanInfo?.ach ? "material-symbols-outlined" : "material-icons icon"}>{updateLoanInfo?.ach ? "check" : "file_upload"}</span>
                                                            {uploadedDocuments?.ach ? "Reupload" : "Upload"}
                                                        </label>
                                                        <input type="file" accept=".pdf, .xls, .xlsx, .doc, .docx, .txt" id={'ach'} name={'ach'} onChange={handleFileChange} style={{ display: 'none' }} />
                                                    </div>
                                                </div>
                                                {/* <div className='btn-group'>
                                                    <a href="#" id="skipAndSubmitButton" className="cp_btn mt-15" onClick={(e) => { e.preventDefault(); submitapp("upload") }}>Submit application with files</a>
                                                    <a href="#" id="uploadSubmit" onClick={(e) => { e.preventDefault(); submitapp() }} className="cp_skip">Submit application and provide files later</a>
                                                </div> */}

                                            </div>

                                        {/* </div> */}


                                    {/* </div> */}

                                </div>
                               
                                <div className='wizard-canvas-footer d-flex'>

                                    <button id="skipAndSubmitButton" type="button" className="save_next mr10" onClick={(e) => { submitapp("upload") }}>
                                        Submit application with files<span class="material-icons"> chevron_right </span>
                                    </button>
                                    <button id="uploadSubmit" type="button" className="save_next save_nextPlain " onClick={(e) => { submitapp() }}>
                                        Submit application and provide files later<span class="material-icons"> chevron_right </span>
                                    </button>
                                </div>

                            </div>
                        </div>
                      
                    </div>
                   
                </div>
      
                <WarmAppFooter />
              </>
        </div>
    );
};
export default SubmitApp;
