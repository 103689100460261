import React,{useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';
import InfoBar from '../InfoBar';
import Footer from '../Footer';
import StrategyInfo1 from './StrategyInfo1';
import StrategyInfo4 from './StrategyInfo4';
import { useLoanInfo } from '../../utils/loanInfoContext';
import { useUserType } from '../../utils/UserTypeContext';
import WarmAppFooter from '../WarmAppFooter';
import CurrencyInput from 'react-currency-input-field';


const StrategyInfo = () => {
    const { useType, setUseType } = useUserType();
    console.log('useType',useType);
    let formElementsbrockerJson = [
        {
          "type": "buttonGroup",
          "label": "What is your source of down payment?",
          "options": [
            { "value": "Borrower Cash", "label": "Borrower Cash" ,"id" : "downPaymentBroker"},
            { "value": "2nd Loan on Subject Project", "label": "2nd Loan on Subject Project" ,"id" : "subjectProjectBroker"},
            { "value": "Outside Investors", "label": "Outside Investors" ,"id" : "outsideInvestorBroker"},
            { "value": "Other", "label": "Other" ,"id" : "otherPaymentBroker"}
          ],
          "name": "downpaymentsource"
        },

        {
            "type": "buttonGroup",
            "label": "What is your plan for paying off the loan?",
            "options": [
              { "value": "Sale", "label": "Sale" ,"id" : "saleBroker"},
              { "value": "Refinance", "label": "Refinance" ,"id" : "refinanceBroker"},
              { "value": "Other", "label": "Other" ,"id" : "otherLoanBroker"}
            ],
            "name": "payoffloan"
          },
          {
            "type": "buttonGroup",
            "label": "Does the borrower have any applicable licenses?",
            "options": [
              { "value": "General Contractor", "label": "General Contractor" ,"id" : "generalContractorBroker"},
              { "value": "Real Estate Broker/Agent", "label": "Real Estate Broker/Agent" ,"id" : "realEstateBroker"},
              { "value": "Not Applicable", "label": "Not Applicable" ,"id" : "notApplicableBroker" },
              { "value": "Other", "label": "Other" ,"id" : "otherLicenseBroker"}
            ],
            "name": "applicationlicense"
          },
          {
            "type": "textarea",
            "label": "What is the borrower's execution strategy?",
            "name": "executionstrategysummary",
            "description": "*REHAB/CONSTRUCTION PLAN, STRENGTHS/WEAKNESSES OF THE DEAL, BORROWER EXPERIENCE LEVEL/CREDIT, AND MISCELLANEOUS INFO* (PLEASE PROVIDE AS MUCH DETAIL AS POSSIBLE)",
            "id" : "executionStrategySummaryBroker"
          },
        
      ]
      let formElementsborrowerJson = [
        {
          "type": "buttonGroup",
          "label": "What is your source of down payment?",
          "options": [
            { "value": "Borrower Cash", "label": "Borrower Cash" ,"id" : "downPaymentBorrower"},
            { "value": "2nd Loan on Subject Project", "label": "2nd Loan on Subject Project" ,"id" : "subjectProjectBorrower"},
            { "value": "Outside Investors", "label": "Outside Investors" ,"id" : "outsideInvestorBorrower"},
            { "value": "Other", "label": "Other" ,"id" : "otherPaymentBorrower"}
          ],
          "name": "downpaymentsource"
        },
        {
            "type": "buttonGroup",
            "label": "What is your plan for paying off the loan?",
            "options": [
              { "value": "Sale", "label": "Sale" ,"id" : "saleBorrower"},
              { "value": "Refinance", "label": "Refinance" ,"id" : "refinanceBorrower"},
              { "value": "Other", "label": "Other" ,"id" : "otherLoanBorrower"}
            ],
            "name": "payoffloan"
          },
          {
            "type": "buttonGroup",
            "label": "Do you have any applicable licenses?",
            "options": [
              { "value": "General Contractor", "label": "General Contractor" ,"id" : "generalContractorBorrower" },
              { "value": "Real Estate Broker/Agent", "label": "Real Estate Broker/Agent" ,"id" : "realEstateBorrower"},
              { "value": "Not Applicable", "label": "Not Applicable" ,"id" : "notApplicableBorrower" },
              { "value": "Other", "label": "Other" ,"id" : "otherLicenseBorrower"}
            ],
            "name": "applicationlicense"
          },
          {
            "type": "textarea",
            "label": "Summary of your specific execution strategy?",
            "name": "executionstrategysummary",
            "description": "*Rehab/ Construction Plan, Strengths/ Weakness of the deal, Borrower Experience Level/ Credit, and Miscellaneous Info* (Please Provide as much detail as possible)",
            "id" : "executionStrategySummaryBorrower" 
          }
        
      ]
    const [formJSON, setformjson] = useState(useType == 'broker' ? formElementsbrockerJson : formElementsborrowerJson);
    const { loanInfo, setLoanInfo } = useLoanInfo();
    const [strategyInfo , setStrategyInfo] = useState(loanInfo?.strategyinfo);
    const [currentState, setCurrentState] = useState('StrategyInfo1');
    const navigate = useNavigate();


  useEffect(() => {
    const isLoanProgramEmpty = !loanInfo.propertyinfo.programtype;
    if (isLoanProgramEmpty) {
      navigate('/loanprogram');
    } else if (loanInfo?.propertyinfo?.loanfor == "Refinance") {
      const isDealinfoEmpty = Object.keys(loanInfo.dealinfo).length === 0;
      if (isDealinfoEmpty) {
        navigate('/dealinfo');
      }
    }
  }, [loanInfo]);

  const [errors,setErrors] = useState({});
useEffect(() => {
    if (strategyInfo?.hasOwnProperty('executionstrategysummary')) {
        setCurrentState('StrategyInfo4');
    } 
  }, []);
    useEffect(() => {
        // console.log('currentState----->',currentState);
    }, [currentState]);

    useEffect(() => {
      console.log('strategyInfo',loanInfo,strategyInfo);
    }, [strategyInfo]);

    // const updateStrategyInfo = (newData) => {
    //     console.log('newData',newData);
    //     setStrategyInfo((prevStrategyInfo) => ({
    //       ...prevStrategyInfo,
    //       ...newData,
    //     }));
        
    //   };

    // const handleBack =()=>{
    //     if(currentState === 'StrategyInfo1'){
    //         if(loanInfo?.propertyinfo?.loanfor == "Refinance" || (loanInfo?.propertyinfo?.stepIntheProcess != "I'm doing research" && loanInfo?.propertyinfo?.stepIntheProcess != "Doing research for borrower")){
    //             navigate('/dealinfo');
    //         }else{
    //             navigate('/loanProgram');
    //         }
    //     }
    //     else if(currentState === "StrategyInfo2"){
    //         setCurrentState('StrategyInfo1')
    //     }
    //     else if(currentState === "StrategyInfo3"){
    //         setCurrentState('StrategyInfo2')
    //     }
    //     else if(currentState === "StrategyInfo4"){
    //         setCurrentState('StrategyInfo3')
    //     }
    // }

    // updated variable assigning functions start
    const updateStrategyInfo = (key, value) => {
      setStrategyInfo((prevStrategy) => {
        const updatedStrategy = { ...prevStrategy, [key]: value };
    
        setLoanInfo((prevLoanInfo) => ({
          ...prevLoanInfo,
          strategyinfo: updatedStrategy,
        }));
        return updatedStrategy;
      });
    };
    
    const handleClick = updateStrategyInfo;
    const handleOther = updateStrategyInfo;
    
    const handleTextArea = (e) => {
      const { name, value } = e.target;
      updateStrategyInfo(name, value);
    };
    
    const handleCompletedValue = (value) => {
      updateStrategyInfo("completedvalue", value || "");
    };

    const handleFocus = (e) => {
      let { name } = e.target
      setErrors({
          ...errors,
          [name]: '',
      })
  };
    // updated variable assigning functions end

    const handleNext=()=>{
      console.log('strategyInfo',strategyInfo);
      handleSaveValidations();
      // navigate('/BorrowerInfo');
    };
    
    const handleSaveValidations = () => {
      console.log('strategyInfo',strategyInfo);
      const fieldsToCheck = { 
         "downpaymentsource" : "downpaymentsource","payoffloan" : "payoffloan","applicationlicense" : "applicationlicense",
      "executionstrategysummary" : "executionstrategysummary"
      // ,"completedvalue" : "completedvalue"
      };
      let error = {};
      
      for (const [fieldName, fieldLabel] of Object.entries(fieldsToCheck)) {
          let value = strategyInfo[fieldName];
          if (typeof value === 'number') {
          }else{
            if (!strategyInfo[fieldName]?.trim()) {
                error[fieldName] = `Please Enter ${fieldLabel} `;
            }
          }
      };

      // other fields validations start
      const otherFields = {
        downpaymentsource: "downpaymentsourceother",
        payoffloan: "payoffloanother",
        applicationlicense: "applicationlicenseother",
      };
    
      Object.entries(otherFields).forEach(([mainField, otherField]) => {
        if (strategyInfo?.[mainField] === "Other" && !strategyInfo?.[otherField]?.trim()) {
          error[otherField] = `Please enter other`;
        }
      });
       // other fields validations end
      setErrors(error);
      console.log('errors',error)
      if (Object.keys(error).length === 0) {
        navigate('/BorrowerInfo');
      }
  };
  // console.log('loanInfo',loanInfo)

    return (
      <div className='warmapp_body'>
      {/* <div className="create_process">
            <Header />
            <div className="content_wrp">
            <InfoBar currentStage={'strategyinfo'} progress={'100%'} dealProgress={'100%'} infoProgress={(currentState === 'StrategyInfo1') ? '0%': (currentState === 'StrategyInfo2') ? '25%' :(currentState === 'StrategyInfo3') ? '50%' :(currentState === 'StrategyInfo4') ? '75%' : '0%'} />

                <div className="content_blk">
                    <div className="hed">
                    <a href="#" className="back material-icons" onClick={(e) => {e.preventDefault(); handleBack();}}> keyboard_backspace </a>
                        <h2 className="title"> <span className="title2">{loanInfo?.propertyinfo
                            ?.programtype?.split(' ')[0]} <span className="sub">{loanInfo?.propertyinfo
                                ?.programtype?.split(' ').slice(1).join(' ')}</span></span>Strategy Info</h2>
                    </div>
                    {currentState === "StrategyInfo1" ? (<StrategyInfo1 formJSON={formJSON[0]} strategyInfo={strategyInfo} updateStrategyInfo={updateStrategyInfo} setCurrentState={() =>setCurrentState('StrategyInfo2')} handleBack={handleBack}/>) : null}
                    {currentState === "StrategyInfo2" ? (<StrategyInfo1 formJSON={formJSON[1]} strategyInfo={strategyInfo} updateStrategyInfo={updateStrategyInfo} setCurrentState={() =>setCurrentState('StrategyInfo3')} handleBack={handleBack} />) : null}
                    {currentState === "StrategyInfo3" ? (<StrategyInfo1 formJSON={formJSON[2]} strategyInfo={strategyInfo} updateStrategyInfo={updateStrategyInfo} setCurrentState={() =>setCurrentState('StrategyInfo4')} handleBack={handleBack}/>) : null}
                    {currentState === "StrategyInfo4" ? (<StrategyInfo4 formJSON={formJSON[3]} strategyInfo={strategyInfo} updateStrategyInfo={updateStrategyInfo} setCurrentState={setCurrentState} handleBack={handleBack}/>) : null}

                </div>
            </div>
            <Footer/>
        </div> */}
      <Header/>

      <div class="wizard-body">
        <div class="wizard-container full-height">
        <div class="wizard-group">
        <InfoBar currentStage={'strategyinfo'} progress={'100%'} dealProgress={'100%'} infoProgress={(currentState === 'StrategyInfo1') ? '0%': (currentState === 'StrategyInfo2') ? '25%' :(currentState === 'StrategyInfo3') ? '50%' :(currentState === 'StrategyInfo4') ? '75%' : '0%'} />
          <div class="wizard-canvas">
          <div class="wizard-canvas-header">
            <div class="strategy_title">
              <h3>Strategy</h3>
              <h2>{loanInfo?.propertyinfo?.programtype?.split(' ')[0]}<span>{loanInfo?.propertyinfo?.programtype?.split(' ').slice(1).join(' ')}</span></h2>
            </div>
            </div>
            <div class="wizard-canvas-body wizard-overflow">

              <div class="question_block horz_questions no-toppad">
                <label class="label">What is your source of down payment? </label>
                          <div className="ques_rightBlock">
                            <div className="radio-group">
                              {["Borrower Cash", "2nd Loan on Subject Property", "Outside Investors", "Other"].map(
                                (option, index) => (
                                  <button
                                    key={index}
                                    className={`radio-box ${strategyInfo?.downpaymentsource === option ? "active" : ""} ${errors?.downpaymentsource && 'invalid-input'}`}
                                    onClick={() => handleClick("downpaymentsource", option)}
                                    onFocus={()=>setErrors({...errors,downpaymentsource : ''})}
                                  >
                                    <span className="material-symbols-outlined">
                                      {strategyInfo?.downpaymentsource === option
                                        ? "radio_button_checked"
                                        : "radio_button_unchecked"}
                                    </span>
                                    {option}
                                  </button>
                                )
                              )}

                              
                            </div>
                            {strategyInfo?.downpaymentsource === "Other" && (
                                <div className="form-block mt-10">
                                  <input name='downpaymentsourceother' 
                                  className={`form-field ${errors.downpaymentsourceother && 'invalid-input'}`} 
                                  onFocus={handleFocus} placeholder="Other" value={strategyInfo?.downpaymentsourceother || ''} onChange={(e)=>{handleOther('downpaymentsourceother',e.target.value)}}/>
                                </div>
                              )}
                          </div>

              </div>

              <div class="question_block horz_questions">
                <label class="label">What is your plan for paying off the loan? </label>
                <div className="ques_rightBlock">
                  <div className="radio-group">
                    {["Sale", "Refinance", "Other"].map((option, index) => (
                      <button
                        key={index}
                        className={`radio-box ${strategyInfo?.payoffloan === option ? "active" : ""} ${errors?.payoffloan && 'invalid-input'}`}
                        onClick={() => handleClick("payoffloan", option)}
                        onFocus={()=>setErrors({...errors,payoffloan : ''})}
                      >
                        <span className="material-symbols-outlined">
                          {strategyInfo?.payoffloan === option ? "radio_button_checked" : "radio_button_unchecked"}
                        </span>
                        {option}
                      </button>
                    ))}

                    
                  </div>
                  {strategyInfo?.payoffloan === "Other" && (
                      <div className="form-block mt-10">
                        <input name='payoffloanother' className={`form-field ${errors.payoffloanother && 'invalid-input'}`} onFocus={handleFocus} placeholder="Other" value={strategyInfo?.payoffloanother || ''} onChange={(e)=>{handleOther('payoffloanother',e.target.value)}}/>
                      </div>
                    )}
              </div>

              </div>
              <div class="question_block horz_questions">
                  <label class="label">Do you have any applicable licenses? </label>
                  <div className="ques_rightBlock">
                    <div className="radio-group">
                      {["General Contractor", "Real Estate Broker/Agent", "Not Applicable", "Other"].map((option, index) => (
                        <button
                          key={index}
                          className={`radio-box ${strategyInfo?.applicationlicense === option ? "active" : ""} ${errors?.applicationlicense && 'invalid-input'}`}
                          onFocus={()=>setErrors({...errors,applicationlicense : ''})}
                          onClick={() => handleClick("applicationlicense", option)}
                        >
                          <span className="material-symbols-outlined">
                            {strategyInfo?.applicationlicense === option ? "radio_button_checked" : "radio_button_unchecked"}
                          </span>
                          {option}
                        </button>
                      ))}

                     
                    </div>
                    {strategyInfo?.applicationlicense === "Other" && (
                        <div className="form-block mt-10">
                          <input name='applicationlicenseother' className={`form-field ${errors.applicationlicenseother && 'invalid-input'}`} onFocus={handleFocus} placeholder="Other" value={strategyInfo?.applicationlicenseother || ''} onChange={(e)=>{handleOther('applicationlicenseother',e.target.value)}}/>
                        </div>
                      )}
                  </div>

              </div>

              {/* <div class="question_block horz_questions">
                <label class="label">What is the completed value? </label>
                <div class="ques_rightBlock">
                  <div class="form-block max-350">
                    <CurrencyInput
                          name='completedvalue'
                          className={`form-field ${errors.completedvalue && 'invalid-input'} ${errors?.completedvalue && 'invalid-input'}`}
                          placeholder='$0.00'
                          value={strategyInfo?.completedvalue || ''}
                          decimalsLimit={2}
                          prefix="$"
                        //  disabled={formDisable}
                          onFocus={()=>setErrors({...errors,completedvalue : ''})} 
                          onValueChange={(value, name, values) => {
                          handleCompletedValue(value);
                              
                          }}
                                                  />

                  </div>
                </div>
              </div> */}

              <div class="question_block horz_questions">
                <div class="label-group">
                  <label class="label">Summary of your specific execution strategy: </label>
                  <p class="tagline mt5">*Rehab/ Construction plan. Strengths/ Weaknesses of the deal. Borrower experience level/ Credit, and miscellaneous info*<br/>
                    (Please provide as much details as possible)</p>
                </div>
                <div class="ques_rightBlock">
                  <div class="form-block">
                    <textarea className={`form-area ${errors.executionstrategysummary && 'invalid-input'}`} onFocus={()=>setErrors({...errors,executionstrategysummary : ''})}  value={strategyInfo?.executionstrategysummary || ''} name='executionstrategysummary' id='executionstrategysummary' placeholder="Enter strategy here" onChange={(e)=>{handleTextArea(e)}} rows="4" cols="50"></textarea>
                  </div>
                </div>
              </div>

            </div>
            <div class="wizard-canvas-footer">
              <button type="button" class="save_next"  onClick={handleNext} id="saveandnext">SAVE & NEXT<span class="material-icons"> chevron_right </span></button>
            </div>


          </div>
        </div>
        </div>

      </div>

        <WarmAppFooter/>
      </div>
        
    );
};

export default StrategyInfo;