
import React, { useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from './components/Login';
import Pipeline from './components/Pipeline';
import Landing from './components/Landing';
import PropertyInfo from './components/PropertyInfo/PropertyInfo';
import LoanProgram from './components/LoanProgram';

import BorrowerInfo from './components/BorrowerInfo/BorrowerInfo';

import SubmitApp from './components/SubmitApp';
import Submit from './components/Submit';
import MyAccount from './components/MyAccount/MyAccount';
import RefinancingBuild from './components/RefinancingBuild';
import RefinancingFix from './components/RefinancingFix';
import {ConfigProvider} from "./utils/configContext";
import {LoanInfoProvider} from "./utils/loanInfoContext";
import { UserTypeProvider } from './utils/UserTypeContext';
import { getConfig } from './redux/reducers/reducer';
import { useDispatch, connect } from 'react-redux';
import DealInfo from './components/DealInfo/DealInfo';
import Createpassword from './components/Createpassword';
import StrategyInfo from './components/StrategyInfo/StrategyInfo';
import UserManagement from './components/UserManagement';
import CreateUserPage from './components/CreateUser/CreateUserPage';
import PrivacyAdmin from './components/PrivacyAdmin';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import TermsAdmin from './components/TermsAdmin';
import LoanProgramsEasyFix from './components/LoanProgramsEasyFix';
import LoanProgramsEasyBuild from './components/LoanProgramsEasyBuild';
import LoanProgramsEasyRent from './components/LoanProgramsEasyRent';
import Organization from './components/Organization';
import PageNotFound from './components/PageNotFound';
import ComingUp from './components/ComingUp';
import ComingSoon from './components/ComingSoon';
import TermSheetRefills from './components/TermSheetRefills';
import CreateTermSheets from './components/CreateTermSheets';
import LoanProgramsAdmin from './components/LoanProgramsAdmin';
import TermSheetManager from './components/TermSheetManager';
import CustomQuill from './components/CustomQuill';
import DropdownManagement from './components/DropdownManagement';
import ProgramTypeManager from './components/ProgramTypeManager';
import UnderwritingTermsheetStates from './components/UnderwritingTermsheetStates';
import FormTemplates from './components/FormTemplates';
import CreateFormTemplates from './components/CreateFormTemplates';
import NeedsConditionlist from './components/NeedsConditionlist';
import AddDefaultNeed from './components/AddDefaultNeed';
import AddDefaultNeedGroup from './components/AddDefaultNeedGroup';
import LosFees from './components/LosFees';
import Createfee from './components/Createfee';
import StatesCities from './components/StatesCities';
import PipelineDashboard from './components/PipelineDashboard';
import DealDetailedApp from './components/DealDetailedApp';
import Closing from './components/Closing';
import Funding from './components/Funding';
import DealContacts from './components/DealContacts';
import DealRatePoints from './components/DealRatePoints';
import DealHMDA from './components/DealHMDA';
import DealToolsWorkflow from './components/DealToolsWorkflow';
import DealLead from './components/DealLead';
import DealUnderwritingTermsheet from './components/DealUnderwritingTermsheet';
import ServicingLoanSummary from './components/ServicingLoanSummary';
import ServicesRecurringBills from './components/ServicesRecurringBills';
import ServicingServicingSetup from './components/ServicingServicingSetup';
import ServicingHistory from './components/ServicingHistory';
import ServicesForm1098 from './components/ServicesForm1098';
import ServicesPayoff from './components/ServicesPayoff';
import FormPackages from './components/FormPackages';
import CreatePackage from './components/CreatePackage';
import ServicesPayments from './components/ServicesPayments';
import Editor from './components/Editor';
import Tasks from './components/Tasks';
import Needs from './components/Needs';
import DealFees from './components/DealFees';
import CreateTermSheetManager from './components/CreateTermSheetManager';
import PiplineInprogress from './components/PiplineInprogress';
import Notes from './components/Notes';
import DrawRequestsStatus from './components/DrawRequestsStatus';
import PricingRateLock from './components/PricingRateLock';
import SupportingDocs from './components/SupportingDocs';
import ProcessingForms from './components/ProcessingForms';
import BorrowerExposure from './components/BorrowerExposure';
import OrderDrawHistory from './components/OrderDrawHistory';
import SearchOptions from './components/SearchOptions';
import OrderDrawRequest from './components/OrderDrawRequest';
import FeeManagement from './components/FeeManagement';
import DealCredit from './components/DealCredit';
import DealFiles from './components/DealFiles';
import { UserProvider } from './utils/UserContext';
import EmailManager from './components/EmailManager';
import CreateEmailTemplate from './components/CreateEmailTemplate';
import NewLoan from './components/NewLoan';
import Calculator from './components/Calculator';
import UserRolesAdmin from './components/UserRolesAdmin';
import RolesAdmin from './components/RolesAdmin';
import LoanModifications from './components/ServicingLoanModifications';
import Adminformform1098 from './components/Adminformform1098';
import AdminFundManagement from './components/AdminFundManagement';
import { PipelineProvider } from './utils/PipelineContext';
import CreateFundManagement from './components/CreateFundManagement';
import AdminFundSummary from './components/AdminFundSummary';
import AdminInvestorStatements from './components/AdminInvestorStatements';
import AdminDetailedReport from './components/AdminDetailedReport';
import AdminFundDistribution from './components/AdminFundDistribution';
import AdminInvestors from './components/AdminInvestors';
import CreateInvestor from './components/CreateInvestors';
import TransactionSummary from './components/TransactionSummary';
import AdminAch from './components/AdminAch';
import AdminReportsHmda from './components/AdminReportsHmda';
import EmailLogs from './components/EmailLogs';
import InvestorAch from './components/InvestorAch';
import AdminEmaillogs from './components/AdminEmaillogs';
import BulkUpload from './components/BulkUpload';


function App(props) {
  
  const dispatch = useDispatch();
  const [Config, setConfig] = useState({});

  useEffect(() => {
    dispatch(getConfig())
  }, []);
  
  useEffect(() => {
    setConfig(props.configdata);
    if(props.configdata?.common?.zendesk?.enable){

      const scriptId = 'ze-snippet';
      // Avoid adding the script multiple times
      if (document.getElementById(scriptId)) return;

      const script = document.createElement('script');
      script.id = scriptId;
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${props.configdata.common?.zendesk?.key}`;
      script.async = true;
      document.body.appendChild(script);

      return () => {
        // Optional cleanup if needed
        document.getElementById(scriptId)?.remove();
      };
    }
}, [props.configdata]);
  
  useEffect(() => {
    console.log('inset', Config);
    if(Config?.admin?.microsoftClarityKey){
      if(props?.configdata?.appname === 'easystreetcapitaldev'){
        clarityScreenRecording(window, document, "clarity", "script", Config?.admin?.microsoftClarityKey);
      }else if(props?.configdata?.appname === 'easystreetcapitalstaging'){
        clarityScreenRecording(window, document, "clarity", "script", Config?.admin?.microsoftClarityKey);
      }else{
        clarityScreenRecording(window, document, "clarity", "script", Config?.admin?.microsoftClarityKey);
      }
    }
  }, [Config]);
    async function clarityScreenRecording(c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    }
console.log('window.location.hostname',window.location.hostname);
    // const rootComponent = window.location.hostname === "fusion.easystreetcap.com" 
    const pathname = window.location.pathname;
    const rootComponent = window.location.hostname === "fusion.easystreetcap.com"
    ? <Landing /> 
    : <Landing />;

  return (
    <ConfigProvider value={Config}>
        <LoanInfoProvider>
        <UserTypeProvider>
        <UserProvider>
          <PipelineProvider>
    <Router>
      <Routes>
        <Route path="/" exact element={rootComponent} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/pipeline" exact element={<Pipeline />} />
        <Route path="/propertyinfo" exact element={<PropertyInfo />} />
        <Route path="/propertyinfo/:usertype" exact element={<PropertyInfo />} />
        <Route path="/BorrowerInfo" exact element={<BorrowerInfo />} />
        <Route path="/loanprogram" exact element={<LoanProgram />} />
        <Route path="/strategyinfo" exact element={<StrategyInfo />} />
        <Route path="/submitapp" exact element={<SubmitApp />} />
        <Route path="/submit" exact element={<Submit />} />
        <Route path="/myaccount" exact element={<MyAccount />} />
        <Route path="/refinancingbuild" exact element={<RefinancingBuild />} />
        <Route path="/dealinfo" exact element={<DealInfo />} />
        <Route path="/refinancingfix" exact element={<RefinancingFix />} />
        <Route path="/createpassword" element={<Createpassword />} />
        <Route path="/createpassword/:id" element={<Createpassword />} />
        <Route path="/usermanagement" exact element={<UserManagement />} />
        <Route path="/user" exact element={<CreateUserPage />} />
        <Route path="/privacyadmin" exact element={<PrivacyAdmin />} />
        <Route path="/privacy" exact element={<Privacy />} />
        <Route path="/terms" exact element={<Terms />} />
        <Route path="/termsadmin" exact element={<TermsAdmin />} />
        <Route path="/loanprogramseasyfix" exact element={<LoanProgramsEasyFix />} />
        <Route path="/loanprogramseasybuild" exact element={<LoanProgramsEasyBuild />} />
        <Route path="/loanprogramseasyrent" exact element={<LoanProgramsEasyRent />} />
        <Route path="/organization" exact element={<Organization />} />
        <Route path="/pagenotfound" exact element={<PageNotFound />} />
        <Route path="/comingup" exact element={<ComingUp />} />
        <Route path="/underwritingtermsheetprefills" exact element={<TermSheetRefills />} />
        <Route path="/createtermsheets" exact element={<CreateTermSheets />} />
        <Route path="/loanprograms" exact element={<LoanProgramsAdmin />} />
        <Route path="/underwritingtermsheetmanager" exact element={<TermSheetManager />} />
        <Route path="/termsheet" exact element={<CreateTermSheetManager />} />
        <Route path="/dropdownmanagement" exact element={<DropdownManagement />} />
        <Route path="/emaillogs" exact element={<AdminEmaillogs />} />   
        <Route path="/programtypes" exact element={<ProgramTypeManager />} />
        <Route path="/statesandcities" exact element={<StatesCities />} />
        <Route path="/underwritingtermsstates" exact element={<UnderwritingTermsheetStates />} />
        <Route path="/formmanager" exact element={<FormTemplates />} />
        <Route path="/createformtemplates" exact element={<CreateFormTemplates />} />
        <Route path="/needslist" exact element={<NeedsConditionlist />} />
        <Route path="/adddefaultneed" exact element={<AddDefaultNeed />} />
        <Route path="/adddefaultneedGroup" exact element={<AddDefaultNeedGroup />} />
        <Route path="/losfees" exact element={<LosFees />} />
        <Route path="/createfee" exact element={<Createfee />} />
        <Route path="/customquill" exact element={<CustomQuill />} />
        <Route path="/dashboard" exact element={<PipelineDashboard />} />
        <Route path="/dealdetailedapp" exact element={<DealDetailedApp />} />
        <Route path="/cleartoclose" exact element={<Closing />} />
        <Route path="/instructions" exact element={<Funding />} />
        <Route path="/dealratepoints" exact element={<DealRatePoints />} />
        <Route path="/dealcontacts" exact element={<DealContacts />} />
        <Route path="/dealhmda" exact element={<DealHMDA />} />
        <Route path="/dealtoolsworkflow" exact element={<DealToolsWorkflow />} />
        <Route path="/servicingtoolsworkflow" exact element={<DealToolsWorkflow />} />
        <Route path="/deallead" exact element={<DealLead />} />
        <Route path="/dealunderwritingtermsheet" exact element={<DealUnderwritingTermsheet />} />
        <Route path="/underwritingtermsheet" exact element={<DealUnderwritingTermsheet />} />
        <Route path="/servicingloansummary" exact element={<ServicingLoanSummary />} />
        <Route path="/drawloansummary" exact element={<ServicingLoanSummary />} />
        <Route path="/servicingservicingsetup" exact element={<ServicingServicingSetup />} />
        <Route path="/servicingcontacts" exact element={<DealContacts />} />
        <Route path="/servicinghistory" exact element={<ServicingHistory />} />
        <Route path="/servicesform1098" exact element={<ServicesForm1098 />} />
        <Route path="/servicesrecurringbills" exact element={<ServicesRecurringBills />} />
        <Route path="/servicespayoff" exact element={<ServicesPayoff />} />
        <Route path="/servicesloanmodification" exact element={<LoanModifications />} />
        <Route path="/formpackages" exact element={<FormPackages />} />
        <Route path="/createpackage" exact element={<CreatePackage />} />
        <Route path="/servicespayments" exact element={<ServicesPayments />} />
        <Route path="/piplineinprogress" exact element={<PiplineInprogress />} />
        <Route path="/tasks" exact element={<Tasks />} />
        <Route path="/needs" exact element={<Needs />} />
        <Route path="/dealfees" exact element={<DealFees />} />
        <Route path="/hmda" exact element={<AdminReportsHmda />} />
        <Route path="/investortransactions" exact element={<TransactionSummary />} />
        <Route path="/monthlystatement" exact element={<ComingUp />} />
        <Route path="/trustaccounttransactions" exact element={<ComingUp />} />
        <Route path="/bulkupload" exact element={<BulkUpload />} />
        <Route path="/Editor" exact element={<Editor />} />
        <Route path="/notes" exact element={<Notes />} />
        <Route path="/drawrequestsstatus" exact element={<DrawRequestsStatus />} />
        <Route path="/pricingratelock" exact element={<PricingRateLock />} />
        <Route path="/supportingdocs" exact element={<SupportingDocs />} />
        <Route path="/processingforms" exact element={<ProcessingForms />} />
        <Route path="/borrowerexposure" exact element={<BorrowerExposure />} />
        <Route path="/orderdrawhistory" exact element={<OrderDrawHistory />} />
        <Route path="/searchoptions" exact element={<SearchOptions />} />
        <Route path="/orderdrawrequest" exact element={<OrderDrawRequest />} />
        <Route path="/feemanagement" exact element={<FeeManagement />} />
        <Route path="/dealcredit" exact element={<DealCredit />} />
        <Route path="/dealfiles" exact element={<DealFiles />} />
        <Route path="/underwritingfiles" exact element={<DealFiles />} />
        <Route path="/emailmanager" exact element = {<EmailManager />} />
        <Route path="/userroles" exact element = {<RolesAdmin />} />
        <Route path="/userrolesadmin" exact element = {<UserRolesAdmin />} />
        <Route path="/emailtemplate" exact element = {<CreateEmailTemplate />} />
        <Route path="/newloan" exact element = {<NewLoan />} />
        <Route path="/calculator" exact element = {<Calculator />} />  
        <Route path="/form1098" exact element = {<Adminformform1098 />} />   
        <Route path="/fundmanagement" exact element={<AdminFundManagement />} />     
        <Route path="/createfundmanagement" exact element={<CreateFundManagement />} />
        <Route path="/fundsummary" exact element={<AdminFundSummary />} />  
        <Route path="/investorstatements" exact element={<AdminInvestorStatements />} />   
        <Route path="/investors" exact element={<AdminInvestors />} />   
        <Route path="/detailedreport" exact element={<AdminDetailedReport />} />  
        <Route path="/funddistribution" exact element={<AdminFundDistribution />} />   
        <Route path="/createinvestor" exact element={<CreateInvestor />} />   
        <Route path="/ach" exact element={<AdminAch />} />   
        <Route path="/investorach" exact element={<InvestorAch />} />   
        <Route path="*" element={<PageNotFound />} />
        <Route path="/newloan/:id" exact element = {<NewLoan />} />
        <Route path="/:id" exact element={rootComponent} />
      </Routes>
    </Router>
    </PipelineProvider>
    </UserProvider>
    </UserTypeProvider>
    </LoanInfoProvider>
    </ConfigProvider>
  );
}


const mapStateToProps = state => {
  return {
    configdata: state.configdata
  };
};
const mapDispatch = { getConfig };
export default connect(mapStateToProps, mapDispatch)(App);